/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type AbortDeploymentData = ResponseWrapperDeploymentResponse

export type AbortDeploymentError =
  | PostWrappersWrapperIdDeploymentsDeploymentIdAbort400ErrorResponse
  | PostWrappersWrapperIdDeploymentsDeploymentIdAbort403ErrorResponse
  | PostWrappersWrapperIdDeploymentsDeploymentIdAbort404ErrorResponse

export type ActivatePointsTransactionsData = Void

export type ActivatePointsTransactionsError =
  GetSeasonsStudioMetricsActivatePointsTransactions403ErrorResponse

export interface ActivatePointsTransactionsParams {
  /** @format date */
  scoringPeriod: string
}

export type AddClaimPeriodData = ResponseGameItemClaimPeriodResponse

export type AddClaimPeriodError =
  | PostGamesGameIdItemsItemIdClaimPeriods400ErrorResponse
  | PostGamesGameIdItemsItemIdClaimPeriods403ErrorResponse
  | PostGamesGameIdItemsItemIdClaimPeriods404ErrorResponse

export type AddCommentData = ResponseDisputeResponse

export type AddCommentError =
  | PostPaymentsPaymentIdDisputesDisputeIdComment400ErrorResponse
  | PostPaymentsPaymentIdDisputesDisputeIdComment403ErrorResponse
  | PostPaymentsPaymentIdDisputesDisputeIdComment404ErrorResponse

export type AddDisputeData = ResponseDisputeResponse

export type AddDisputeError =
  | PostPaymentsPaymentIdDisputes400ErrorResponse
  | PostPaymentsPaymentIdDisputes403ErrorResponse
  | PostPaymentsPaymentIdDisputes404ErrorResponse

/** AddFeedEntryAttributeRequest */
export interface AddFeedEntryAttributeRequest {
  /** Attribute value if `type` is `"ANY"` */
  anyValue?: object | null
  /** Attribute asset variants profile key if `type` is `"ASSET"` */
  assetVariantsProfileKey?:
    | 'AUDIO_MOD_CONTENT'
    | 'BIO_LINK_IMAGE'
    | 'CUSTOM'
    | 'GAME_BANNER'
    | 'GAME_BANNER_GIF'
    | 'IMAGE_CHALLENGE_COVER_16_9'
    | 'IMAGE_CHALLENGE_COVER_4_3'
    | 'IMAGE_EXTENSION_COVER_16_9'
    | 'IMAGE_EXTENSION_COVER_16_9_GIF'
    | 'IMAGE_EXTENSION_GRAPHIC'
    | 'IMAGE_EXTENSION_GRAPHIC_GIF'
    | 'IMAGE_GAME_COVER_16_9'
    | 'IMAGE_GAME_COVER_16_9_GIF'
    | 'IMAGE_GAME_COVER_4_3'
    | 'IMAGE_GAME_COVER_4_3_GIF'
    | 'IMAGE_GAME_GRAPHIC'
    | 'IMAGE_GAME_GRAPHIC_GIF'
    | 'IMAGE_MOD_COVER_16_9'
    | 'IMAGE_MOD_COVER_16_9_GIF'
    | 'IMAGE_MOD_GRAPHIC'
    | 'IMAGE_MOD_GRAPHIC_GIF'
    | 'IMAGE_MOD_MOBILE_WALLPAPER'
    | 'IMAGE_MOD_WALLPAPER'
    | 'IMAGE_QUEST_COVER'
    | 'IMAGE_QUEST_COVER_GIF'
    | 'IMAGE_STICKER_COVER'
    | 'IMAGE_STUDIO_COVER_4_1'
    | 'USER_AVATAR'
    | 'VIDEO_EXTENSION_COVER_16_9'
    | 'VIDEO_EXTENSION_GRAPHIC'
    | 'VIDEO_GAME_COVER_16_9'
    | 'VIDEO_GAME_COVER_4_3'
    | 'VIDEO_GAME_GRAPHIC'
    | 'VIDEO_MOD_COVER_16_9'
    | 'VIDEO_MOD_GRAPHIC'
    | 'VIDEO_MOD_WALLPAPER'
    | null
  /** Attribute value (string value in default locale) if `type` is `"LOCALIZABLE_STRING"` */
  localizableStringValue?: string | null
  /** Attribute name */
  name: string
  /** Attribute type */
  type: 'ANY' | 'ASSET' | 'LOCALIZABLE_STRING'
}

export type AddFeedEntryData = ResponseFeedEntryDetailsResponse

export type AddFeedEntryError =
  | PostFeedsFeedIdEntries400ErrorResponse
  | PostFeedsFeedIdEntries403ErrorResponse
  | PostFeedsFeedIdEntries404ErrorResponse

/** AddFeedEntryRequest */
export interface AddFeedEntryRequest {
  attributes?: AddFeedEntryAttributeRequest[] | null
  /**
   * Notes: *Must be `null` if any other id field is not `null`*
   * @example "52153a4f-079b-490d-b21b-f64e838eab84"
   */
  challengeId?: string | null
  /**
   * Notes: *Must be `null` if any other id field is not `null`*
   * @example "upcoming-event"
   */
  customContentItemType?: string | null
  /**
   * Notes: *Optional parameter. If the value is the index of an existing entry in the feed, the new entry is inserted in front of the existing one. If the value is omitted or `-1`, the entry is added at the end of the feed.*
   * @format int32
   * @example 5
   */
  displayOrder?: number | null
  /**
   * Notes: *Must be `null` if any other id field is not `null`*
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  gameId?: string | null
  /**
   * Notes: *Must be `null` if any other id field is not `null`*
   * @example "52153a4f-079b-490d-b21b-f64e838eab84"
   */
  modId?: string | null
  /**
   * Notes: *Must be `null` if any other id field is not `null`*
   * @example "52153a4f-079b-490d-b21b-f64e838eab84"
   */
  tagId?: string | null
}

export type AddGameData = ResponseVoid

export type AddGameError =
  | PutTagsTagIdGamesGameId400ErrorResponse
  | PutTagsTagIdGamesGameId403ErrorResponse
  | PutTagsTagIdGamesGameId404ErrorResponse

export type AddGameItemV2Data = ResponseGameItemResponse

export type AddGameItemV2Error =
  | PutV2GamesGameIdItems400ErrorResponse
  | PutV2GamesGameIdItems403ErrorResponse
  | PutV2GamesGameIdItems404ErrorResponse
  | PutV2GamesGameIdItems500ErrorResponse

export type AddModData = ResponseModDetailsResponse

export type AddModError =
  | PutTagsTagIdModsModId400ErrorResponse
  | PutTagsTagIdModsModId403ErrorResponse
  | PutTagsTagIdModsModId404ErrorResponse

export type AddNewPaymentFieldValuesData = ResponsePaymentsIdsResponse

export type AddNewPaymentFieldValuesError =
  | PostPaymentsAddNewFieldValues400ErrorResponse
  | PostPaymentsAddNewFieldValues403ErrorResponse

export interface AddNewPaymentFieldValuesParams {
  /**
   * The page to return. The response will contain payment field values in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of payment field values to include in the response. Fewer payment field values may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddNewPurchaseFieldValuesData = ResponseGamePurchasesDetailedResponse

export type AddNewPurchaseFieldValuesError =
  | PostPurchasesAddNewFieldValues400ErrorResponse
  | PostPurchasesAddNewFieldValues403ErrorResponse

export interface AddNewPurchaseFieldValuesParams {
  /**
   * The page to return. The response will contain purchase field values in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of purchase field values to include in the response. Fewer purchase field values may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddNewRefundFieldValuesData = ResponseRefundsIdsResponse

export type AddNewRefundFieldValuesError =
  | PostRefundsAddNewFieldValues400ErrorResponse
  | PostRefundsAddNewFieldValues403ErrorResponse

export interface AddNewRefundFieldValuesParams {
  /**
   * The page to return. The response will contain refund field values in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of refund field values to include in the response. Fewer refund field values may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddProfileConfigurationData = ResponseAssetVariantConfigurationResponse

export type AddProfileConfigurationError =
  | PostAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse
  | PostAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse
  | PostAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse

export type AddReleasableAccessToGamesData = ResponsePaginatedMigrationResponse

export type AddReleasableAccessToGamesError =
  | PostMigrationGamesAddReleasableAccessEntities400ErrorResponse
  | PostMigrationGamesAddReleasableAccessEntities403ErrorResponse

export interface AddReleasableAccessToGamesParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddReleasableAssetToGamesData = ResponsePaginatedMigrationResponse

export type AddReleasableAssetToGamesError =
  | PostMigrationGamesAddReleasableAssetEntities400ErrorResponse
  | PostMigrationGamesAddReleasableAssetEntities403ErrorResponse

export interface AddReleasableAssetToGamesParams {
  /**
   * The page to return. The response will contain game assets in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game assets to include in the response. Fewer game assets may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddReleasableCoverToGamesData = ResponsePaginatedMigrationResponse

export type AddReleasableCoverToGamesError =
  | PostMigrationGamesAddReleasableCoverEntities400ErrorResponse
  | PostMigrationGamesAddReleasableCoverEntities403ErrorResponse

export interface AddReleasableCoverToGamesParams {
  /**
   * The page to return. The response will contain game covers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game covers to include in the response. Fewer game covers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddReleasableToGamesData = ResponsePaginatedMigrationResponse

export type AddReleasableToGamesError =
  | PostMigrationGamesAddReleasableEntities400ErrorResponse
  | PostMigrationGamesAddReleasableEntities403ErrorResponse

export interface AddReleasableToGamesParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type AddReleaseDatesData = ResponseGameReleaseDateUpdateResponse

export type AddReleaseDatesError = PatchGamesReleaseDates403ErrorResponse

export type AdjustPointsData = ResponsePointsTransactionResponse

export type AdjustPointsError =
  | PostSeasonsSeasonIdPointsTransactions400ErrorResponse
  | PostSeasonsSeasonIdPointsTransactions403ErrorResponse
  | PostSeasonsSeasonIdPointsTransactions404ErrorResponse

/** ApiClientResponse */
export interface ApiClientResponse {
  /** Unique API client alias. */
  alias?: string | null
  /** List of API namespaces the client is allowed to make (CORS) requests to. */
  allowedApiNamespaces?:
    | (
        | 'BO'
        | 'BONUS'
        | 'CONFIG'
        | 'DC'
        | 'DEV'
        | 'DISCORD'
        | 'EXT_PUB'
        | 'EXT_STORE'
        | 'GAME_DEV'
        | 'GG'
        | 'GGS'
        | 'GMS'
        | 'GX'
        | 'GXC'
        | 'JUGGLE'
        | 'ME'
        | 'OFFERS'
        | 'PARTNERS'
        | 'PAYMENT_PROVIDERS'
        | 'PLATFORMS'
        | 'PROFILE'
        | 'PUB'
        | 'SESSION'
        | 'STORE'
        | 'VERSION'
      )[]
    | null
  /** List of patterns matching origins the API client is allowed to make (CORS) requests from. */
  allowedOriginPatterns?: string[] | null
  /** List of origins the API client is allowed to make (CORS) requests from. */
  allowedOrigins?: string[] | null
  /**
   * API client id (UUID4)
   * @example "37e478cd-1aeb-4be3-9297-268912730a94"
   */
  clientId: string
  /** Primary origin, for API clients representing a front-end, and null otherwise. */
  primaryOrigin?: string | null
  /**
   * Site identifier, for API clients representing a front-end that supports sign-ins, and null otherwise.
   *
   * Notes: *This is the value to use for the `site` query parameter to the `GET /session/login` endpoint when signin in.*
   */
  siteIdentifier?: string | null
}

/** ApiClientsResponse */
export interface ApiClientsResponse {
  apiClients: ApiClientResponse[]
  pagination: PaginationResponse
}

export type ApplyDefaultStorageTagsToModWrappersData = ResponseReprocessedWrappersResponse

export type ApplyDefaultStorageTagsToModWrappersError =
  | PostWrappersApplyDefaultStorageTagsToModWrappers400ErrorResponse
  | PostWrappersApplyDefaultStorageTagsToModWrappers403ErrorResponse

export interface ApplyDefaultStorageTagsToModWrappersParams {
  /**
   * The page to return. The response will contain game wrappers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game wrappers to include in the response. Fewer game wrappers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export type ApplyDefaultStorageTagsToPublishingFilesData = ResponseReprocessedGamesResponse

export type ApplyDefaultStorageTagsToPublishingFilesError =
  | PostGamesApplyDefaultStorageTagsToPublishingFiles400ErrorResponse
  | PostGamesApplyDefaultStorageTagsToPublishingFiles403ErrorResponse

export type ApplyDefaultStorageTagsToPublishingFilesFail =
  | PostModsApplyDefaultStorageTagsToPublishingFiles400ErrorResponse
  | PostModsApplyDefaultStorageTagsToPublishingFiles403ErrorResponse

export interface ApplyDefaultStorageTagsToPublishingFilesParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export interface ApplyDefaultStorageTagsToPublishingFilesParams2 {
  /**
   * The page to return. The response will contain mods in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mods to include in the response. Fewer mods may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export type ApplyDefaultStorageTagsToPublishingFilesResult = ResponseReprocessedModsResponse

export type ApplyDefaultStorageTagsToReleaseFilesData = ResponseReprocessedGameReleasesResponse

export type ApplyDefaultStorageTagsToReleaseFilesError =
  | PostGamesApplyDefaultStorageTagsToReleaseFiles400ErrorResponse
  | PostGamesApplyDefaultStorageTagsToReleaseFiles403ErrorResponse

export type ApplyDefaultStorageTagsToReleaseFilesFail =
  | PostModsApplyDefaultStorageTagsToReleaseFiles400ErrorResponse
  | PostModsApplyDefaultStorageTagsToReleaseFiles403ErrorResponse

export interface ApplyDefaultStorageTagsToReleaseFilesParams {
  /**
   * The page to return. The response will contain game releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game releases to include in the response. Fewer game releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export interface ApplyDefaultStorageTagsToReleaseFilesParams2 {
  /**
   * The page to return. The response will contain mod releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod releases to include in the response. Fewer mod releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export type ApplyDefaultStorageTagsToReleaseFilesResult = ResponseReprocessedModReleasesResponse

export type ApplyPendingChangesData = ResponseManagedFeedDetailsResponse

export type ApplyPendingChangesError =
  | PostFeedsFeedIdApplyPendingChanges400ErrorResponse
  | PostFeedsFeedIdApplyPendingChanges403ErrorResponse
  | PostFeedsFeedIdApplyPendingChanges404ErrorResponse

export type ArchiveDynamicConfigClientData = ResponseDynamicConfigClientDetailsResponse

export type ArchiveDynamicConfigClientError =
  | PatchDynamicConfigClientsClientAliasArchive403ErrorResponse
  | PatchDynamicConfigClientsClientAliasArchive404ErrorResponse

/** AssetVariantConfigurationResponse */
export interface AssetVariantConfigurationResponse {
  /** Target aspect ratio. */
  aspectRatio?: string | null
  /** Unique configuration id. */
  configurationId: string
  /** True if the configuration is in the process of being deleted. */
  deletePending?: boolean | null
  /** Description of what the configuration is used for */
  description?: string | null
  dimensions?: AssetVariantDimensionsResponse | null
  /** Opaque string passed to the conversion tool to select target format. */
  format?: string | null
  /** MIME type of the output asset variant. */
  mimeType?: string | null
  /**
   * Number of asset variants requested (Lambda function invoked).
   * @format int32
   */
  numberRequested?: number | null
  /**
   * Number of asset variants requested but not uploaded in a long while (Lambda function failed?).
   * @format int32
   */
  numberStuck?: number | null
  /**
   * Number of asset variants uploaded (Lambda function finished).
   * @format int32
   */
  numberUploaded?: number | null
  /** Options passed to the conversion tool. */
  options?: MapStringObject | null
  /**
   * Total number of asset variants created from this configuration.
   * @format int32
   */
  totalCount?: number | null
  /** Variant key. */
  variantKey?: string | null
}

/** AssetVariantConfigurationsResponse */
export interface AssetVariantConfigurationsResponse {
  configurations: AssetVariantConfigurationResponse[]
  pagination: PaginationResponse
}

/** AssetVariantDimensionsRequest */
export interface AssetVariantDimensionsRequest {
  /**
   * Height of the generated variant, in pixels. If `width` is omitted or null, the width is calculated to preserve the aspect-ratio.
   * @format int32
   */
  height?: number | null
  /**
   * Width of the generated variant, in pixels. If `height` is omitted or null, the height is calculated to preserve the aspect-ratio.
   * @format int32
   */
  width?: number | null
}

/** AssetVariantDimensionsResponse */
export interface AssetVariantDimensionsResponse {
  /** @format int32 */
  height?: number | null
  /** @format int32 */
  width?: number | null
}

/** AssetVariantsProfileResponse */
export interface AssetVariantsProfileResponse {
  profileKey?:
    | 'AUDIO_MOD_CONTENT'
    | 'BIO_LINK_IMAGE'
    | 'CUSTOM'
    | 'GAME_BANNER'
    | 'GAME_BANNER_GIF'
    | 'IMAGE_CHALLENGE_COVER_16_9'
    | 'IMAGE_CHALLENGE_COVER_4_3'
    | 'IMAGE_EXTENSION_COVER_16_9'
    | 'IMAGE_EXTENSION_COVER_16_9_GIF'
    | 'IMAGE_EXTENSION_GRAPHIC'
    | 'IMAGE_EXTENSION_GRAPHIC_GIF'
    | 'IMAGE_GAME_COVER_16_9'
    | 'IMAGE_GAME_COVER_16_9_GIF'
    | 'IMAGE_GAME_COVER_4_3'
    | 'IMAGE_GAME_COVER_4_3_GIF'
    | 'IMAGE_GAME_GRAPHIC'
    | 'IMAGE_GAME_GRAPHIC_GIF'
    | 'IMAGE_MOD_COVER_16_9'
    | 'IMAGE_MOD_COVER_16_9_GIF'
    | 'IMAGE_MOD_GRAPHIC'
    | 'IMAGE_MOD_GRAPHIC_GIF'
    | 'IMAGE_MOD_MOBILE_WALLPAPER'
    | 'IMAGE_MOD_WALLPAPER'
    | 'IMAGE_QUEST_COVER'
    | 'IMAGE_QUEST_COVER_GIF'
    | 'IMAGE_STICKER_COVER'
    | 'IMAGE_STUDIO_COVER_4_1'
    | 'USER_AVATAR'
    | 'VIDEO_EXTENSION_COVER_16_9'
    | 'VIDEO_EXTENSION_GRAPHIC'
    | 'VIDEO_GAME_COVER_16_9'
    | 'VIDEO_GAME_COVER_4_3'
    | 'VIDEO_GAME_GRAPHIC'
    | 'VIDEO_MOD_COVER_16_9'
    | 'VIDEO_MOD_GRAPHIC'
    | 'VIDEO_MOD_WALLPAPER'
    | null
}

/** AssetVariantsProfilesResponse */
export interface AssetVariantsProfilesResponse {
  pagination: PaginationResponse
  profiles: AssetVariantsProfileResponse[]
}

export type AssignToModData = ResponseModOdkResponse

export type AssignToModError =
  | PutOdksKeyIdModsModId400ErrorResponse
  | PutOdksKeyIdModsModId403ErrorResponse
  | PutOdksKeyIdModsModId404ErrorResponse

export type AssignToStudioData = ResponseStudioOdkResponse

export type AssignToStudioError =
  | PutOdksKeyIdStudiosStudioId400ErrorResponse
  | PutOdksKeyIdStudiosStudioId403ErrorResponse
  | PutOdksKeyIdStudiosStudioId404ErrorResponse

export interface AvailableLegacyStudioSettingsResponse {
  empty?: boolean | null
  first?: LegacyStudioSettingResponse | null
  last?: LegacyStudioSettingResponse | null
}

/** BackOfficeUserResponse */
export interface BackOfficeUserResponse {
  /** @example true */
  active?: boolean | null
  allowedDynamicConfigClients?: DynamicConfigClientResponse[] | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  boUserId?: string | null
  /** @example "stevejobs@example.org" */
  email?: string | null
  /** @example "Steve Jobs" */
  name?: string | null
  roles?:
    | (
        | 'ADMIN'
        | 'CONTENT_MANAGER'
        | 'DYNAMIC_CONFIG_MANAGER'
        | 'GM_OPS'
        | 'OFFER_MANAGER'
        | 'VIEWER'
      )[]
    | null
}

/** BackOfficeUsersResponse */
export interface BackOfficeUsersResponse {
  pagination: PaginationResponse
  users: BackOfficeUserResponse[]
}

/** BackendHostResponse */
export interface BackendHostResponse {
  alias?: string | null
  allowedApiNamespaces?:
    | (
        | 'BO'
        | 'BONUS'
        | 'CONFIG'
        | 'DC'
        | 'DEV'
        | 'DISCORD'
        | 'EXT_PUB'
        | 'EXT_STORE'
        | 'GAME_DEV'
        | 'GG'
        | 'GGS'
        | 'GMS'
        | 'GX'
        | 'GXC'
        | 'JUGGLE'
        | 'ME'
        | 'OFFERS'
        | 'PARTNERS'
        | 'PAYMENT_PROVIDERS'
        | 'PLATFORMS'
        | 'PROFILE'
        | 'PUB'
        | 'SESSION'
        | 'STORE'
        | 'VERSION'
      )[]
    | null
  cloudflareZone?: CloudflareZoneResponse | null
  hostId: string
  hostname?: string | null
}

/** BackendHostsResponse */
export interface BackendHostsResponse {
  backendHosts: BackendHostResponse[]
  pagination: PaginationResponse
}

/** BO Stripe Connect Account details response */
export interface BackofficeStripeConnectAccountDetailsResponse {
  /**
   * Stripe Connect Account Id
   * @example "acct_a93a8eb6df584551"
   */
  accountId: string
  /**
   * Stripe Connect Account balance after subtracting the amount of purchases under dispute, if any exist. The balance may be negative. In USD.
   * @format double
   */
  availableBalance?: number | null
  /**
   * Stripe Connect Account balance available for payout, in USD. This equals the available balance if the balance is not less than the minimum payout amount, and if the payout is either the first one or at least the minimum number of days required between payouts has passed since the previous payout. Only positive balances are considered.
   * @format double
   */
  availableBalanceForPayout?: number | null
  /**
   * Days since the previous payout, if applicable. If not, then the number of days since the oldest payment. If not, return an empty value.
   * @format int64
   */
  daysFromLastPayoutOrOldestPayment?: number | null
  /**
   * Days since the previous payout, if applicable. If not, return an empty value.
   * @format int64
   */
  daysFromPreviousPayout?: number | null
  /**
   * Setting specifying the number of days that must pass after a previous payout before a new one can be made.
   * @format int32
   */
  minimalAgeFromPreviousPayoutDays?: number | null
  /**
   * Setting for the minimum payout amount, in USD.
   * @format int32
   */
  minimalAmountForPayout?: number | null
  /**
   * Total balance of purchases related to the current Stripe Connect Account that are under dispute, in USD.
   * @format double
   */
  purchasesUnderDisputeBalance?: number | null
  /**
   * Number of purchases related to the current Stripe Connect Account that are under dispute.
   * @format int32
   */
  purchasesUnderDisputes?: number | null
  /**
   * Subtract the purchase amount under disputes from the Stripe Connect Account balance if any exist, in USD.
   * @format double
   */
  totalBalance?: number | null
}

/** BankAccountRequest */
export interface BankAccountRequest {
  /**
   * Unique account id (UUID4).
   *
   * Notes: *Exactly one of `accountId` and `accountNumber` must be present and non-null.*
   */
  accountId?: string | null
  /**
   * Unique account number.
   *
   * Notes: *Exactly one of `accountId` and `accountNumber` must be present and non-null.*
   */
  accountNumber?: string | null
}

/** BankAccountResponse */
export interface BankAccountResponse {
  /** Unique account id (UUID4). */
  accountId: string
  /** Unique account number. */
  accountNumber?: string | null
  /** True if the account has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the account, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /**
   * Current account balance. Never negative.
   * @format int64
   */
  balance?: number | null
  /**
   * When the account was created.
   * @format date-time
   */
  createdAt?: string | null
  /** The BO user that created the account, or null if the account was not created manually by a BO user. */
  createdBy?: BackOfficeUserResponse | null
  currency?: BankCurrencyResponse | null
  user?: UserResponse | null
}

/** BankAccountsResponse */
export interface BankAccountsResponse {
  accounts: BankAccountResponse[]
  pagination: PaginationResponse
}

/** BankCurrencyResponse */
export interface BankCurrencyResponse {
  alias?: string | null
  title?: BankCurrencyTitleResponse | null
}

/** BankCurrencyTitleResponse */
export interface BankCurrencyTitleResponse {
  value?: string | null
}

/** BankTransactionResponse */
export interface BankTransactionResponse {
  account?: BankAccountResponse | null
  /**
   * Transaction amount.
   *
   * Notes: *The amount is always greater than zero. The transaction type must be used to determine whether the amount was added to or subtracted from the account balance.*
   * @format int64
   */
  amount?: number | null
  /** Transaction category. */
  category?: 'PIXELS_TRANSFERRED' | 'QUEST_COMPLETED' | 'RESPECT_ACTION' | null
  /** The BO user that created the transaction, or null if the transaction was not created manually by a BO user. */
  createdBy?: BackOfficeUserResponse | null
  currency?: BankCurrencyResponse | null
  /** Note provided when creating the transaction, or null if no note was provided. */
  note?: string | null
  /**
   * Transaction id of related transaction, or null if there is no related transaction.
   *
   * Notes: *For a `DEBIT` transaction, the related transaction is the corresponding `CREDIT` transaction, and vice versa. For `DEPOSIT` transactions, there is no related transaction.*
   */
  relatedTransactionId?: string | null
  /**
   * When the transaction was created.
   * @format date-time
   */
  transactionDate?: string | null
  /** Unique transaction id (UUID4). */
  transactionId: string
  /** Transaction type. */
  transactionType?: 'CREDIT' | 'DEBIT' | 'DEPOSIT' | null
}

/** BankTransactionsResponse */
export interface BankTransactionsResponse {
  pagination: PaginationResponse
  transactions: BankTransactionResponse[]
}

/** BankTransferRequest */
export interface BankTransferRequest {
  /** Account whose balance the transferred amount is added to. */
  credit: BankAccountRequest
  /** Account whose balance the transferred amount is subtracted from. */
  debit: BankAccountRequest
}

export type BeginPendingChangesData = ResponseManagedFeedDetailsResponse

export type BeginPendingChangesError =
  | PostFeedsFeedIdBeginPendingChanges400ErrorResponse
  | PostFeedsFeedIdBeginPendingChanges403ErrorResponse
  | PostFeedsFeedIdBeginPendingChanges404ErrorResponse

/** BioLinkResponse */
export interface BioLinkResponse {
  /** Whether the link is active or not */
  active?: boolean | null
  /**
   * When the link was created.
   * @format date-time
   */
  createdAt?: string | null
  /**
   * Describes order in which links are displayed in public API
   * @format int32
   */
  displayOrder?: number | null
  /** Link icon url */
  iconUrl?: string | null
  /**
   * When the link was updated last time.
   * @format date-time
   */
  lastModified?: string | null
  /** @example "89fb9376-6a15-4cf3-80fc-066f945e81d9" */
  linkId: string
  /** Link title */
  title?: string | null
  /** Link url */
  url?: string | null
}

/** BioLinksResponse */
export interface BioLinksResponse {
  bioLinks?: BioLinkResponse[] | null
}

export type BlockGameData = ResponseSeasonBlockedGameResponse

export type BlockGameError =
  | PutSeasonsSeasonIdBlockedGamesGameId400ErrorResponse
  | PutSeasonsSeasonIdBlockedGamesGameId403ErrorResponse
  | PutSeasonsSeasonIdBlockedGamesGameId404ErrorResponse

export type BlockStudioData = ResponseSeasonBlockedStudioResponse

export type BlockStudioError =
  | PutSeasonsSeasonIdBlockedStudiosStudioId400ErrorResponse
  | PutSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse
  | PutSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse

export type BulkUpdateCodesData = ResponseOfferCodesBulkUpdateResponse

export type BulkUpdateCodesError =
  | PatchOffersOfferIdCodes400ErrorResponse
  | PatchOffersOfferIdCodes403ErrorResponse
  | PatchOffersOfferIdCodes404ErrorResponse

export interface BulkUpdateCodesParams {
  /**
   * Only include codes in given active state
   * @default "ANY"
   */
  activeState?: 'ANY' | 'ACTIVE' | 'NOT_ACTIVE'
  /**
   * Only include codes in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /** Only include codes imported as part of the given batch */
  batchAlias?: string
  /**
   * Only include codes in given claimed state
   * @default "ANY"
   */
  claimedState?: 'ANY' | 'CLAIMED' | 'NOT_CLAIMED'
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
  /**
   * Only include codes in given redeemed state
   * @default "ANY"
   */
  redeemedState?: 'ANY' | 'REDEEMED' | 'NOT_REDEEMED'
}

export type CancelPendingChangesData = ResponseManagedFeedDetailsResponse

export type CancelPendingChangesError =
  | PostFeedsFeedIdCancelPendingChanges400ErrorResponse
  | PostFeedsFeedIdCancelPendingChanges403ErrorResponse
  | PostFeedsFeedIdCancelPendingChanges404ErrorResponse

/** CdnHostingResponse */
export interface CdnHostingResponse {
  apiClient?: ApiClientResponse | null
  backendHost?: BackendHostResponse | null
  cdnHostingId: string
  title?: string | null
}

/** CdnHostingsResponse */
export interface CdnHostingsResponse {
  cdnHostings: CdnHostingResponse[]
  pagination: PaginationResponse
}

/** ChallengeResponse */
export interface ChallengeResponse {
  /** The challenge is active. For timed challenges, this means current time is between the start and end dates. For other challenges, this is always true. */
  active?: boolean | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  challengeId: string
  /** @example "a93t1d" */
  challengeShortId?: string | null
  /**
   * Challenge entity version
   *
   * Notes: *Will be an empty string if the challenge's version has not been initialized.*
   * @example "1"
   */
  challengeVersion?: string | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-08-22T10:57:17.031Z"
   */
  creationDate?: string | null
  /**
   * Allowed values: `highest_wins`, `lowest_wins`
   * @example "lowest_wins"
   */
  criteria?: 'highest_wins' | 'lowest_wins' | null
  /** True if this challenge has been deleted, in which case all fields except `gameId` and `name` will be null, false or empty arrays. */
  deleted?: boolean | null
  /**
   * Null if not a timed challenge
   * @format date-time
   * @example "2021-08-22T10:57:17.031Z"
   */
  endsAt?: string | null
  /** @example "In order to **win this challenge** you need to ++beat the final level++ in as _little_ time as possible!" */
  longDescription?: string | null
  /** @example "gotta-go-fast" */
  mangledName?: string | null
  /** @example "Gotta go fast" */
  name?: string | null
  /**
   * Unique id of promotion of this challenge, or null if not promoted.
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  promotedChallengeId?: string | null
  settings?: ChallengeSettingResponse[] | null
  /** @example "Beat the final level in as little time as possible." */
  shortDescription?: string | null
  /**
   * Null if not a timed challenge
   * @format date-time
   * @example "2021-08-22T10:57:17.031Z"
   */
  startsAt?: string | null
  timedChallenge?: boolean | null
  /**
   * Allowed values: `points`, `duration`
   * @example "duration"
   */
  type?: 'points' | 'duration' | null
}

/** ChallengeSettingResponse */
export interface ChallengeSettingResponse {
  published?: boolean | null
  /**
   * @format date-time
   * @example "2021-08-02T12:34:56.123Z"
   */
  publishedAt?: string | null
  releaseTrack?: GameReleaseTrackResponse | null
}

/** ChallengeShortIDsGenerationResponse */
export interface ChallengeShortIDsGenerationResponse {
  /** @format int64 */
  challengeShortIdsGenerated?: number | null
  /** @format int64 */
  challengeShortIdsGenerationErrors?: number | null
}

/** ChallengesResponse */
export interface ChallengesResponse {
  challenges: GameChallengeResponse[]
  pagination: PaginationResponse
}

/** ChangerResponse */
export interface ChangerResponse {
  /** The back-office user or `null` if `changerType` is not `BO_USER` */
  backOfficeUser?: BackOfficeUserResponse | null
  /** Changer id */
  changerId: string
  /** Changer type */
  changerType?: 'USER' | 'BO_USER' | 'WRAPPER_DEPLOYMENT' | 'AUTOMATIC_PROCESS' | null
  /** True if the recorded changer id is no longer valid */
  changerUnavailable?: boolean | null
  /** The user or `null` if `changerType` is not `USER` */
  user?: UserSummaryResponse | null
  /** The wrapper deployment or `null` if `changerType` is not `WRAPPER_DEPLOYMENT` */
  wrapperDeployment?: WrapperDeploymentResponse | null
}

export type ClaimCode2Data = ResponseOfferCodeUnclaimResponse

export type ClaimCode2Error =
  | PostOffersOfferIdCodesCodeIdUnclaim403ErrorResponse
  | PostOffersOfferIdCodesCodeIdUnclaim404ErrorResponse

export type ClaimCodeData = ResponseOfferCodeClaimResponse

export type ClaimCodeError =
  | PostOffersOfferIdCodesCodeIdClaim400ErrorResponse
  | PostOffersOfferIdCodesCodeIdClaim403ErrorResponse
  | PostOffersOfferIdCodesCodeIdClaim404ErrorResponse
  | PostOffersOfferIdCodesCodeIdClaim500ErrorResponse

/** ClaimOfferCodeRequest */
export interface ClaimOfferCodeRequest {
  /** The user id for which to claim the code. Note: This is an opaque string, and is not expected to be a GX.games user id. */
  claimerUserId: string
  /** Optional client alias. Resolves to default defined in offer settings if empty. */
  clientAlias?: string | null
}

/** CloudflareZoneRequest */
export interface CloudflareZoneRequest {
  apiKey: string
  zoneId: string
}

/** CloudflareZoneResponse */
export interface CloudflareZoneResponse {
  apiKey?: string | null
  zoneId: string
}

/** ClusterStatusResponse */
export interface ClusterStatusResponse {
  synchronizationTimedOut?: boolean | null
  tasks?: ClusterTaskStatusResponse[] | null
}

/** ClusterTaskStatusFragmentResponse */
export interface ClusterTaskStatusFragmentResponse {
  /**
   * Fragment data
   *
   * Notes: *The shape depends on the type of the fragment. Treat as an arbitrary JSON value.*
   */
  data?: object | null
  /**
   * Fragment type
   *
   * Notes: *The name of the status fragment's class.*
   */
  fragmentType?: string | null
}

/** ClusterTaskStatusResponse */
export interface ClusterTaskStatusResponse {
  fragments?: ClusterTaskStatusFragmentResponse[] | null
  /**
   * Task id
   *
   * Notes: *Random UUID4 generated by BE application during startup.*
   */
  taskId: string
}

/** CmsConfigurationResponse */
export interface CmsConfigurationResponse {
  /**
   * Configuration alias
   * @example "home"
   */
  alias?: string | null
  /**
   * Configuration id
   * @example "fde58242-54fb-4647-b8d6-b8517a549394"
   */
  configurationId: string
  /**
   * Configuration entity version
   *
   * Notes: *Will be an empty string if the configuration's version has not been initialized.*
   * @example "1"
   */
  configurationVersion?: string | null
  /**
   * Configuration description
   * @example "GX.games home page"
   */
  description?: string | null
  /**
   * Configuration name
   *
   * Notes: *The `name` property has been deprecated in favor of the `alias` property.*
   * @example "home"
   */
  name?: string | null
  sectionIds?: string[] | null
}

/** CmsConfigurationsResponse */
export interface CmsConfigurationsResponse {
  configurations: CmsConfigurationResponse[]
  pagination: PaginationResponse
}

/** CmsSectionResponse */
export interface CmsSectionResponse {
  /**
   * When section stops being available
   * @format date-time
   * @example "2022-11-11T08:00:00.000Z"
   */
  availabilityEndsAt?: string | null
  /**
   * When section becomes available
   * @format date-time
   * @example "2022-11-11T08:00:00.000Z"
   */
  availabilityStartsAt?: string | null
  description?: StringResponse | null
  /**
   * Section feed alias
   * @example "most-played-games"
   */
  feedAlias?: string | null
  /**
   * Section feed id
   * @example "8050cabf-61bf-4ae3-9964-ac096d5be8bf"
   */
  feedId?: string | null
  feedTitle?: StringResponse | null
  /**
   * Feed entity version
   *
   * Notes: *Will be an empty string if the feed's version has not been initialized.*
   * @example "1"
   */
  feedVersion?: string | null
  name?: StringResponse | null
  /**
   * Section id
   * @example "df3ee074-dbc8-48dd-9a66-5e20e6a99d9c"
   */
  sectionId: string
  /**
   * Section type
   * @example "list-of-games"
   */
  sectionType?: string | null
  /**
   * Section entity version
   *
   * Notes: *Will be an empty string if the section's version has not been initialized.*
   * @example "1"
   */
  sectionVersion?: string | null
  /**
   * Section visibility group
   * @example "ALL_USERS"
   */
  visibleFor?: 'ALL_USERS' | 'GUESTS' | 'SIGNED_IN_USERS' | null
}

/** CmsSectionsResponse */
export interface CmsSectionsResponse {
  pagination: PaginationResponse
  sections: CmsSectionResponse[]
}

/** CodeRedemptionImportResponse */
export interface CodeRedemptionImportResponse {
  /**
   * The number of codes parsed from the input CSV file.
   * @format int64
   */
  numberOfParsedCodes?: number | null
}

/** CoverResponse */
export interface CoverResponse {
  /** @example "16:9" */
  aspectRatio?: string | null
  /**
   * ID of the cover: not to be mistaken for the ID of the image asset, which is not returned.
   *
   * *Deprecated in favor of `coverId`*
   * @deprecated
   * @example "f26d9a15-4f65-4412-8590-5a69b46009d1"
   */
  assetId?: string | null
  /** @example "f26d9a15-4f65-4412-8590-5a69b46009d1" */
  coverId: string
  /** @example "IMAGE" */
  type?: 'IMAGE' | 'VIDEO' | null
  /** @example "https://example.com/123456.png" */
  url?: string | null
}

export type CreateAccountData = ResponseBankAccountResponse

export type CreateAccountError =
  | PostBankAccounts400ErrorResponse
  | PostBankAccounts403ErrorResponse
  | PostBankAccounts404ErrorResponse

export type CreateApiClientData = ResponseApiClientResponse

export type CreateApiClientError = PostApiclients400ErrorResponse | PostApiclients403ErrorResponse

/** CreateApiClientRequest */
export interface CreateApiClientRequest {
  /** Unique API client alias. */
  alias: string
  /** List of API namespaces the client should be allowed to make (CORS) requests to. */
  allowedApiNamespaces: (
    | 'BO'
    | 'BONUS'
    | 'CONFIG'
    | 'DC'
    | 'DEV'
    | 'DISCORD'
    | 'EXT_PUB'
    | 'EXT_STORE'
    | 'GAME_DEV'
    | 'GG'
    | 'GGS'
    | 'GMS'
    | 'GX'
    | 'GXC'
    | 'JUGGLE'
    | 'ME'
    | 'OFFERS'
    | 'PARTNERS'
    | 'PAYMENT_PROVIDERS'
    | 'PLATFORMS'
    | 'PROFILE'
    | 'PUB'
    | 'SESSION'
    | 'STORE'
    | 'VERSION'
  )[]
  /** List of patterns matching origins the API client should be allowed to make (CORS) requests from. Patterns can have wild-cards (`*`) at the beginning of the hostname (`https://*.example.org`) and/or in place of the port number (`http://localhost:*`). */
  allowedOriginPatterns: string[]
  /** List of origins the API client should be allowed to make (CORS) requests from. */
  allowedOrigins: string[]
  /** Optional primary origin, for API clients representing a front-end. */
  primaryOrigin?: string | null
  /**
   * Optional site identifier, for API clients representing a front-end that supports sign-ins.
   *
   * Notes: *This is the value to use for the `site` query parameter to the `GET /session/login` endpoint when signin in.*
   */
  siteIdentifier?: string | null
}

/** CreateAssetVariantConfigurationRequest */
export interface CreateAssetVariantConfigurationRequest {
  /** Target aspect ratio for the conversion tool. */
  aspectRatio?: string | null
  /**
   * Description
   *
   * Notes: *Description string. Must be provided for all new configurations*
   */
  description: string
  dimensions?: AssetVariantDimensionsRequest | null
  /** Opaque string passed to the conversion tool to select target format. */
  format: string
  /** MIME type of the output asset variant. */
  mimeType: string
  /** Options passed to the conversion tool. */
  options?: MapStringObject | null
  /**
   * Variant key.
   *
   * Notes: *Used in S3 object keys, and can only contain ASCII digits, letters, or dashes (`-`).*
   */
  variantKey: string
}

export type CreateAssetVariantsData = ResponseVoid

export type CreateAssetVariantsError =
  | PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403ErrorResponse
  | PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404ErrorResponse

export interface CreateAssetVariantsParams {
  /**
   * Only generate variants for assets associated with this challenge.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  challengeId?: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  configurationId: string
  /**
   * Only generate variants for assets associated with this game.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId?: string
  /**
   * Only generate variants for assets associated with this mod.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId?: string
  /**
   * Only generate variants for assets associated with this mod release.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modReleaseId?: string
  /**
   * Only generate variants for assets associated with this quest.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  questId?: string
  /**
   * Only generate variants for assets associated with this sticker.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  stickerId?: string
  /**
   * Only generate variants for assets associated with this studio.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  studioId?: string
  /**
   * Only generate variants for this user's avatar asset.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId?: string
}

export type CreateBackendHostData = ResponseBackendHostResponse

export type CreateBackendHostError =
  | PostBackendhosts400ErrorResponse
  | PostBackendhosts403ErrorResponse

/** CreateBackendHostRequest */
export interface CreateBackendHostRequest {
  alias: string
  allowedApiNamespaces: (
    | 'BO'
    | 'BONUS'
    | 'CONFIG'
    | 'DC'
    | 'DEV'
    | 'DISCORD'
    | 'EXT_PUB'
    | 'EXT_STORE'
    | 'GAME_DEV'
    | 'GG'
    | 'GGS'
    | 'GMS'
    | 'GX'
    | 'GXC'
    | 'JUGGLE'
    | 'ME'
    | 'OFFERS'
    | 'PARTNERS'
    | 'PAYMENT_PROVIDERS'
    | 'PLATFORMS'
    | 'PROFILE'
    | 'PUB'
    | 'SESSION'
    | 'STORE'
    | 'VERSION'
  )[]
  cloudflareZone?: CloudflareZoneRequest | null
  hostname: string
}

/** CreateBankAccountRequest */
export interface CreateBankAccountRequest {
  /** Alias of account currency */
  currencyAlias: string
  /**
   * User id of account owner
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId: string
}

/** CreateBankTransactionRequest */
export interface CreateBankTransactionRequest {
  /**
   * Transaction amount (to deposit or transfer).
   * @format int64
   */
  amount: number
  /**
   * Account to deposit into.
   *
   * Notes: *Exactly one of `deposit` and `transfer` must be present and non-null.*
   */
  deposit?: BankAccountRequest | null
  /** Optional note. */
  note?: string | null
  /**
   * Transfer details
   *
   * Notes: *Exactly one of `deposit` and `transfer` must be present and non-null.*
   */
  transfer?: BankTransferRequest | null
}

export type CreateBioLinkData = ResponseBioLinkResponse

export type CreateBioLinkError =
  | PostUserUserIdBioLinks400ErrorResponse
  | PostUserUserIdBioLinks403ErrorResponse
  | PostUserUserIdBioLinks404ErrorResponse

/** CreateBioLinkRequest */
export interface CreateBioLinkRequest {
  /**
   * @format int32
   * @example 3
   */
  displayOrder?: number | null
  /** @example "Twitter" */
  title?: string | null
  /** Not empty url */
  url: string
}

/** CreateCmsConfigurationRequest */
export interface CreateCmsConfigurationRequest {
  /**
   * Configuration alias
   *
   * Notes: *Will become mandatory. Until then, defaults to `name` lower-cased. At least one of `name` and `alias` must be included.*
   */
  alias?: string | null
  /**
   * Configuration name
   *
   * Notes: *Deprecated in favor of `alias`. Defaults to same as `alias` if omitted. At least one of `name` and `alias` must be included.*
   */
  name?: string | null
}

/** CreateCmsSectionRequest */
export interface CreateCmsSectionRequest {
  /**
   * When section stops being available
   * @format date-time
   */
  availabilityEndsAt?: string | null
  /**
   * When section becomes available
   * @format date-time
   */
  availabilityStartsAt?: string | null
  /**
   * Section feed id.
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  feedId: string
  /** Section name in default locale. */
  name: string
  /** Section type. */
  sectionType: string
  /** Section visibility user group, defaults to ALL_USERS. */
  visibleFor?: 'ALL_USERS' | 'GUESTS' | 'SIGNED_IN_USERS' | null
}

export type CreateConfigurationData = ResponseCmsConfigurationResponse

export type CreateConfigurationError =
  | PostCmsConfigurations400ErrorResponse
  | PostCmsConfigurations403ErrorResponse

export type CreateData = ResponseGameWrapperResponse

export type CreateDeploymentData = ResponseWrapperDeploymentResponse

export type CreateDeploymentError =
  | PostWrappersWrapperIdDeployments400ErrorResponse
  | PostWrappersWrapperIdDeployments403ErrorResponse
  | PostWrappersWrapperIdDeployments404ErrorResponse

export type CreateDynamicConfigClientData = ResponseDynamicConfigClientDetailsResponse

export type CreateDynamicConfigClientError =
  | PostDynamicConfigClients400ErrorResponse
  | PostDynamicConfigClients403ErrorResponse

export type CreateDynamicConfigV2Data = ResponseDynamicConfigResponse

export type CreateDynamicConfigV2Error =
  | PostV2DynamicConfig400ErrorResponse
  | PostV2DynamicConfig403ErrorResponse

export type CreateError =
  | PostGameWrappers400ErrorResponse
  | PostGameWrappers403ErrorResponse
  | PostGameWrappers404ErrorResponse
  | PostGameWrappers500ErrorResponse

export type CreateFail =
  | PostOffersOfferIdErrorPages400ErrorResponse
  | PostOffersOfferIdErrorPages403ErrorResponse
  | PostOffersOfferIdErrorPages404ErrorResponse

export type CreateFails = PostWrappers400ErrorResponse | PostWrappers403ErrorResponse

export type CreateFeedData = ResponseManagedFeedDetailsResponse

export type CreateFeedError = PostFeeds400ErrorResponse | PostFeeds403ErrorResponse

export type CreateGameControlData = ResponseGameControlResponse

export type CreateGameControlError =
  | PostGamesGameIdControlSubgroupsControlSubgroupIdControls400ErrorResponse
  | PostGamesGameIdControlSubgroupsControlSubgroupIdControls403ErrorResponse
  | PostGamesGameIdControlSubgroupsControlSubgroupIdControls404ErrorResponse

export type CreateGameControlGroupData = ResponseGameControlGroupResponse

export type CreateGameControlGroupError =
  | PostGamesGameIdControlGroups400ErrorResponse
  | PostGamesGameIdControlGroups403ErrorResponse
  | PostGamesGameIdControlGroups404ErrorResponse

/** CreateGameControlGroupRequest */
export interface CreateGameControlGroupRequest {
  /**
   * Name of the control group
   * @example "Main controls"
   */
  name: string
}

/** CreateGameControlRequest */
export interface CreateGameControlRequest {
  /**
   * Action for the control
   * @example "Move"
   */
  action: string
  /**
   * Input for the control
   * @example "W,A,S,D"
   */
  input: string
}

export type CreateGameControlSubgroupData = ResponseGameControlSubgroupResponse

export type CreateGameControlSubgroupError =
  | PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400ErrorResponse
  | PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403ErrorResponse
  | PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404ErrorResponse

/** CreateGameControlSubgroupRequest */
export interface CreateGameControlSubgroupRequest {
  /**
   * Optional name of the subgroup. If not present, or set to null, the name willbe an empty string.
   * @example "Main controls"
   */
  name?: string | null
}

export type CreateGameEngineData = ResponseGameEngineDetailsResponse

export type CreateGameEngineError = PostEngines400ErrorResponse | PostEngines403ErrorResponse

/** CreateGameEngineRequest */
export interface CreateGameEngineRequest {
  /**
   * Alias for the new game engine.
   * @example "game-engine"
   */
  alias?: string | null
  /** Title of game engine in default locale. */
  title: string
  /** Optional url. */
  url?: string | null
}

/** CreateGameWrapperRequest */
export interface CreateGameWrapperRequest {
  /** AWS bucket to assign to this game wrapper. If empty, the game wrapper will default to using `GX`. */
  bucketIdentifier?: 'GX' | 'BONUS' | 'STORE' | null
  /**
   * Game engine to assign to this game wrapper. If empty, the game engine will default to current default engine.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameEngineId?: string | null
  /**
   * Name of game wrapper
   * @example "wrapper-1.0.0"
   */
  name: string
}

/** CreateManagedFeedRequest */
export interface CreateManagedFeedRequest {
  /** Url friendly alias of the feed */
  alias: string
  /** Localizable description of the feed */
  description?: string | null
  /** Localizable title of the feed */
  title?: string | null
  /** Type of feed that will be created. It's only required for special feeds like e.g. game jams. */
  type?: 'CUSTOM_FEED' | 'GAME_JAM' | 'USER_JAM' | 'VOTING' | 'FILTERED_FEED' | null
}

export type CreateModPlatformTagsData = ResponseVoid

export type CreateModPlatformTagsError = PostTagsCreateModPlatformTags403ErrorResponse

export type CreateModTypeTagsData = ResponseVoid

export type CreateModTypeTagsError = PostTagsCreateModTypeTags403ErrorResponse

export type CreateModWallpaperTypeTagsData = ResponseVoid

export type CreateModWallpaperTypeTagsError = PostTagsCreateModWallpaperTypeTags403ErrorResponse

export type CreateOfferData = ResponseOfferResponse

export type CreateOfferError =
  | PostOffers400ErrorResponse
  | PostOffers403ErrorResponse
  | PostOffers404ErrorResponse

/** CreateOfferErrorPageRequest */
export interface CreateOfferErrorPageRequest {
  /**
   * Client alias. Must be unique among non-archived error pages for the offer.
   *
   * Notes: *If omitted or null, an error page for the default client alias is created.*
   */
  clientAlias?: string | null
}

/** CreateOfferRequest */
export interface CreateOfferRequest {
  /** Offer alias. Must be unique among non-archived offers. */
  alias: string
  /**
   * Partner id.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  partnerId: string
  /** Offer title. */
  title: string
}

export type CreateOfficialDistributionKeyData = ResponseOdkDetailsResponse

export type CreateOfficialDistributionKeyError = PostOdks400ErrorResponse | PostOdks403ErrorResponse

/** CreateOfficialDistributionKeyRequest */
export interface CreateOfficialDistributionKeyRequest {
  /** Alias for the new official distribution key. */
  alias: string
  /** Title for the new official distribution key in default locale. */
  title: string
}

export type CreateOutput = ResponseWrapperResponse

export type CreatePartnerData = ResponsePartnerResponse

export type CreatePartnerError =
  | PostOfferPartners400ErrorResponse
  | PostOfferPartners403ErrorResponse

export type CreatePartnerFail = PostPartners400ErrorResponse | PostPartners403ErrorResponse

/** CreatePartnerRequest */
export interface CreatePartnerRequest {
  /** Partner alias. Must be unique among non-archived partners. */
  alias: string
  /** Partner title. */
  title: string
}

export type CreatePartnerResult = ResponsePartnerResponse

export type CreatePromotedChallengeData = ResponsePromotedChallengeResponse

export type CreatePromotedChallengeError =
  | PostPromotedChallenges400ErrorResponse
  | PostPromotedChallenges403ErrorResponse

/** CreatePromotedChallengeRequest */
export interface CreatePromotedChallengeRequest {
  /** id of challenge to promote */
  challengeId: string
}

export type CreatePublisherData = ResponsePublisherResponse

export type CreatePublisherError = PostPublishers400ErrorResponse | PostPublishers403ErrorResponse

/** CreatePublisherRequest */
export interface CreatePublisherRequest {
  /** Title of the publisher in default locale */
  title: string
  /** Optional URL of the publisher */
  url?: string | null
}

export type CreateQuestCollectionData = ResponseQuestCollectionResponse

export type CreateQuestCollectionError =
  | PostQuestCollections400ErrorResponse
  | PostQuestCollections403ErrorResponse

/** CreateQuestCollectionRequest */
export interface CreateQuestCollectionRequest {
  alias: string
  collectionType: 'DAILY_QUESTS' | 'PERSONAL_QUESTS'
}

export type CreateQuestData = ResponseQuestResponse

export type CreateQuestError =
  | PostQuests400ErrorResponse
  | PostQuests403ErrorResponse
  | PostQuests404ErrorResponse

/** CreateQuestRequest */
export interface CreateQuestRequest {
  /**
   * Unique quest alias
   * @example "do-the-groceries"
   */
  alias: string
  /**
   * Call-to-action title in default language
   * @example "It's morphin' time!"
   */
  callToAction?: string | null
  /**
   * URL to the call-to-action
   * @example "https://gx.opera.com/put-sth-here"
   */
  callToActionUrl?: string | null
  /**
   * Description in default language
   * @example "Go to the local grocery store and purchase tomatoes, carrots and minced meat."
   */
  description: string
  /**
   * Offer code reward details
   *
   * Notes: *Exactly one of `pixelsReward` and `offerCodeReward` must be non-`null`.*
   */
  offerCodeReward?: QuestOfferCodeRewardRequest | null
  /** Parameters: may be required based on quest type */
  parameters?: MapStringObject | null
  /**
   * Pixels reward details
   *
   * Notes: *Exactly one of `pixelsReward` and `offerCodeReward` must be non-`null`.*
   */
  pixelsReward?: QuestPixelsRewardRequest | null
  /**
   * Alias of the quest collection this instance will be part of
   * @example "default"
   */
  questCollection?: string | null
  /**
   * Title in default language
   * @example "Do the groceries!"
   */
  title: string
  /** Quest type */
  type:
    | 'ADD_GAMES_TO_FAVORITES'
    | 'ADD_NEW_FRIENDS'
    | 'BUY_GAMES'
    | 'PARTICIPATE_IN_CHALLENGES'
    | 'PARTNER_QUEST'
    | 'PAY_RESPECT_TO_GAMES'
    | 'PAY_RESPECT_TO_STUDIOS'
    | 'PLAY_GAMES'
    | 'SETUP_CUSTOM_AVATAR'
    | 'SETUP_PROFILE_DESCRIPTION'
    | 'SETUP_BIO_LINKS'
    | 'SPEND_VIRTUAL_CURRENCY'
}

export type CreateQuestV2Data = ResponseQuestResponse

export type CreateQuestV2Error =
  | PostV2Quests400ErrorResponse
  | PostV2Quests403ErrorResponse
  | PostV2Quests404ErrorResponse

export type CreateReleaseData = ResponseModDetailsResponse

export type CreateReleaseError =
  | PostModsModIdTracksTrackIdCreateRelease400ErrorResponse
  | PostModsModIdTracksTrackIdCreateRelease403ErrorResponse
  | PostModsModIdTracksTrackIdCreateRelease404ErrorResponse
  | PostModsModIdTracksTrackIdCreateRelease500ErrorResponse

export type CreateResult = ResponseOfferErrorPageResponse

/** CreateReviewRequest */
export interface CreateReviewRequest {
  decision: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION'
  /**
   * Message to the reviewer and/or other back-office users.
   *
   * Notes: *Must not be blank when blocking or unblocking.*
   * @example "This thing is so bad!"
   */
  internalComment?: string | null
  /**
   * Message to the user requested the review.
   *
   * Notes: *Currently stored but never displayed to the user that requested the review.*
   * @example "Thank you for your report!"
   */
  messageToRequester?: string | null
  /**
   * Message to the game / mod developer(s).
   *
   * Notes: *Must not be blank when blocking or unblocking.*
   * @example "Your thing is kaput!"
   */
  messageToSubject?: string | null
  /**
   * If true, resolve all current pending review requests for the game / mod.
   *
   * Notes: *When true, `reviewRequestIds` must be omitted.*
   */
  resolveAllPendingRequests?: boolean | null
  reviewRequestIds?: string[] | null
}

/** CreateSeasonRequest */
export interface CreateSeasonRequest {
  /**
   * Season end time
   * @format date-time
   * @example "2022-03-31T23:59:59.000+01:00"
   */
  endsAt?: string | null
  /**
   * Unique season identifier
   * @example "March 2022"
   */
  identifier?: string | null
  /** True to publish season; false to unpublish season */
  published?: boolean | null
  /** Season type */
  seasonType: 'PLAYER' | 'STUDIO'
  /**
   * Season start time
   * @format date-time
   * @example "2022-03-01T00:00:00.000+01:00"
   */
  startsAt?: string | null
}

export type CreateSeasonV2Data = ResponseSeasonResponse

export type CreateSeasonV2Error = PostV2Seasons400ErrorResponse | PostV2Seasons403ErrorResponse

export type CreateSectionData = ResponseCmsSectionResponse

export type CreateSectionError =
  | PostCmsConfigurationsConfigurationIdSections400ErrorResponse
  | PostCmsConfigurationsConfigurationIdSections403ErrorResponse
  | PostCmsConfigurationsConfigurationIdSections404ErrorResponse

export type CreateSettingOverrideTransactionData = ResponseSettingOverrideTransactionResponse

export type CreateSettingOverrideTransactionError =
  | PostSettingsTransactions400ErrorResponse
  | PostSettingsTransactions403ErrorResponse
  | PostSettingsTransactions404ErrorResponse

/** CreateSettingOverrideTransactionRequest */
export interface CreateSettingOverrideTransactionRequest {
  changes: SettingOverrideChangeRequest[]
  comment?: string | null
  forEntity?: TypedEntityRequest | null
}

export type CreateStickerData = ResponseStickerResponse

export type CreateStickerError = PostStickers400ErrorResponse | PostStickers403ErrorResponse

/** CreateStickerRequest */
export interface CreateStickerRequest {
  /**
   * The alias of the sticker
   *
   * Notes: *Has to fit the regex `[a-z0-9\-]*`.*
   * @example "sticker-alias"
   */
  alias?: string | null
  /**
   * The amount of the sticker
   * @format int32
   */
  amount: number
  /** The currency of the sticker */
  currency: 'RPX' | 'GPX' | 'BPX' | 'USD'
  /**
   * Optional display order of the sticker
   * @format int32
   */
  displayOrder?: number | null
  /** An optional note for the sticker */
  note?: string | null
  /**
   * The title of the sticker
   *
   * Notes: *The sticker title is a localizable string with this value as the default*
   */
  title: string
}

export type CreateStudioData = ResponseStudioResponse

export type CreateStudioError =
  | PostStudios400ErrorResponse
  | PostStudios403ErrorResponse
  | PostStudios404ErrorResponse

/** CreateStudioRequest */
export interface CreateStudioRequest {
  /** @example "Lagom Bra Games studio" */
  name: string
  /**
   * Notes: *The user id of the owner*
   * @example "f71779bc-6d7b-4dfc-9c9c-5a3691c24c47"
   */
  ownerId: string
}

/** CreateTagRequest */
export interface CreateTagRequest {
  /** Tag alias */
  alias: string
  /** Tag category */
  category:
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'MOD_PLATFORM'
    | 'MOD_SIGNING_KEY'
    | 'MOD_TYPE'
    | 'MOD_WALLPAPER_TYPE'
  /** Optional parent tag id. */
  parentTagId?: string | null
  /** Tag title in default locale. */
  title: string
}

export type CreateTagV2Data = ResponseTagDetailsResponse

export type CreateTagV2Error = PostV2Tags400ErrorResponse | PostV2Tags403ErrorResponse

export type CreateTransactionData = ResponseBankTransactionsResponse

export type CreateTransactionError =
  | PostBankTransactions400ErrorResponse
  | PostBankTransactions403ErrorResponse
  | PostBankTransactions404ErrorResponse

/** CreateWrapperRequest */
export interface CreateWrapperRequest {
  /** AWS bucket to assign to this game wrapper. If empty, the game wrapper will default to using `GX`. */
  bucketIdentifier?: 'GX' | 'BONUS' | 'STORE' | null
  /**
   * Name of the wrapper
   * @example "wrapper-1.0.0"
   */
  name: string
  /**
   * Type of the wrapper
   * @example "GAME"
   */
  wrapperType: 'GAME' | 'MOD'
}

/** CurrenciesResponse */
export interface CurrenciesResponse {
  currencies?: string[] | null
}

/** BO paginated list of users with marketing consent converted to default topic consent */
export interface DefaultTopicConvertedEntriesResponse {
  pagination: PaginationResponse
  users: UserSummaryResponse[]
}

export type DeleteApiClientData = ResponseVoid

export type DeleteApiClientError =
  | DeleteApiclientsClientId403ErrorResponse
  | DeleteApiclientsClientId404ErrorResponse

/** DeleteApiclientsClientId403Error */
export interface DeleteApiclientsClientId403Error {
  code: 'sign_in_required'
}

/** DeleteApiclientsClientId403ErrorResponse */
export interface DeleteApiclientsClientId403ErrorResponse {
  data: null
  errors: DeleteApiclientsClientId403Error[]
}

/** DeleteApiclientsClientId404Error */
export interface DeleteApiclientsClientId404Error {
  code: 'api_client_not_found'
}

/** DeleteApiclientsClientId404ErrorResponse */
export interface DeleteApiclientsClientId404ErrorResponse {
  data: null
  errors: DeleteApiclientsClientId404Error[]
}

/** DeleteAssetVariantsConfigurationsConfigurationId403Error */
export interface DeleteAssetVariantsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** DeleteAssetVariantsConfigurationsConfigurationId403ErrorResponse */
export interface DeleteAssetVariantsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: DeleteAssetVariantsConfigurationsConfigurationId403Error[]
}

/** DeleteAssetVariantsConfigurationsConfigurationId404Error */
export interface DeleteAssetVariantsConfigurationsConfigurationId404Error {
  code: 'asset_variant_configuration_not_found'
}

/** DeleteAssetVariantsConfigurationsConfigurationId404ErrorResponse */
export interface DeleteAssetVariantsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: DeleteAssetVariantsConfigurationsConfigurationId404Error[]
}

/** DeleteAssetVariantsIncorrectVideoGameCovers403Error */
export interface DeleteAssetVariantsIncorrectVideoGameCovers403Error {
  code: 'sign_in_required'
}

/** DeleteAssetVariantsIncorrectVideoGameCovers403ErrorResponse */
export interface DeleteAssetVariantsIncorrectVideoGameCovers403ErrorResponse {
  data: null
  errors: DeleteAssetVariantsIncorrectVideoGameCovers403Error[]
}

export type DeleteBackendHostData = ResponseVoid

export type DeleteBackendHostError =
  | DeleteBackendhostsHostId403ErrorResponse
  | DeleteBackendhostsHostId404ErrorResponse

/** DeleteBackendhostsHostId403Error */
export interface DeleteBackendhostsHostId403Error {
  code: 'sign_in_required'
}

/** DeleteBackendhostsHostId403ErrorResponse */
export interface DeleteBackendhostsHostId403ErrorResponse {
  data: null
  errors: DeleteBackendhostsHostId403Error[]
}

/** DeleteBackendhostsHostId404Error */
export interface DeleteBackendhostsHostId404Error {
  code: 'backend_host_not_found'
}

/** DeleteBackendhostsHostId404ErrorResponse */
export interface DeleteBackendhostsHostId404ErrorResponse {
  data: null
  errors: DeleteBackendhostsHostId404Error[]
}

export type DeleteBioLinkData = ResponseVoid

export type DeleteBioLinkError =
  | DeleteUserUserIdBioLinksLinkId403ErrorResponse
  | DeleteUserUserIdBioLinksLinkId404ErrorResponse

export type DeleteChallengeCoverData = ResponseVoid

export type DeleteChallengeCoverError =
  | DeleteChallengesChallengeIdCoverCoverId400ErrorResponse
  | DeleteChallengesChallengeIdCoverCoverId403ErrorResponse
  | DeleteChallengesChallengeIdCoverCoverId404ErrorResponse
  | DeleteChallengesChallengeIdCoverCoverId500ErrorResponse

/** DeleteChallengesChallengeIdCoverCoverId400Error */
export interface DeleteChallengesChallengeIdCoverCoverId400Error {
  code: 'challenge_access_denied' | 'challenge_is_live'
}

/** DeleteChallengesChallengeIdCoverCoverId400ErrorResponse */
export interface DeleteChallengesChallengeIdCoverCoverId400ErrorResponse {
  data: null
  errors: DeleteChallengesChallengeIdCoverCoverId400Error[]
}

/** DeleteChallengesChallengeIdCoverCoverId403Error */
export interface DeleteChallengesChallengeIdCoverCoverId403Error {
  code: 'sign_in_required'
}

/** DeleteChallengesChallengeIdCoverCoverId403ErrorResponse */
export interface DeleteChallengesChallengeIdCoverCoverId403ErrorResponse {
  data: null
  errors: DeleteChallengesChallengeIdCoverCoverId403Error[]
}

/** DeleteChallengesChallengeIdCoverCoverId404Error */
export interface DeleteChallengesChallengeIdCoverCoverId404Error {
  code: 'challenge_not_found' | 'cover_not_found'
}

/** DeleteChallengesChallengeIdCoverCoverId404ErrorResponse */
export interface DeleteChallengesChallengeIdCoverCoverId404ErrorResponse {
  data: null
  errors: DeleteChallengesChallengeIdCoverCoverId404Error[]
}

/** DeleteChallengesChallengeIdCoverCoverId500Error */
export interface DeleteChallengesChallengeIdCoverCoverId500Error {
  code: 'internal_server_error'
}

/** DeleteChallengesChallengeIdCoverCoverId500ErrorResponse */
export interface DeleteChallengesChallengeIdCoverCoverId500ErrorResponse {
  data: null
  errors: DeleteChallengesChallengeIdCoverCoverId500Error[]
}

/** DeleteChallengesRankings403Error */
export interface DeleteChallengesRankings403Error {
  code: 'sign_in_required'
}

/** DeleteChallengesRankings403ErrorResponse */
export interface DeleteChallengesRankings403ErrorResponse {
  data: null
  errors: DeleteChallengesRankings403Error[]
}

export type DeleteClaimPeriodData = ResponseVoid

export type DeleteClaimPeriodError =
  | DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse
  | DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse
  | DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse

/** DeleteCmsConfigurationsConfigurationId403Error */
export interface DeleteCmsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** DeleteCmsConfigurationsConfigurationId403ErrorResponse */
export interface DeleteCmsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: DeleteCmsConfigurationsConfigurationId403Error[]
}

/** DeleteCmsConfigurationsConfigurationId404Error */
export interface DeleteCmsConfigurationsConfigurationId404Error {
  code: 'configuration_not_found'
}

/** DeleteCmsConfigurationsConfigurationId404ErrorResponse */
export interface DeleteCmsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: DeleteCmsConfigurationsConfigurationId404Error[]
}

/** DeleteCmsConfigurationsConfigurationIdSectionsSectionId403Error */
export interface DeleteCmsConfigurationsConfigurationIdSectionsSectionId403Error {
  code: 'sign_in_required'
}

/** DeleteCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse */
export interface DeleteCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse {
  data: null
  errors: DeleteCmsConfigurationsConfigurationIdSectionsSectionId403Error[]
}

/** DeleteCmsConfigurationsConfigurationIdSectionsSectionId404Error */
export interface DeleteCmsConfigurationsConfigurationIdSectionsSectionId404Error {
  code: 'configuration_not_found' | 'configuration_section_not_found'
}

/** DeleteCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse */
export interface DeleteCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse {
  data: null
  errors: DeleteCmsConfigurationsConfigurationIdSectionsSectionId404Error[]
}

export type DeleteConfigurationData = ResponseAssetVariantConfigurationResponse

export type DeleteConfigurationError =
  | DeleteAssetVariantsConfigurationsConfigurationId403ErrorResponse
  | DeleteAssetVariantsConfigurationsConfigurationId404ErrorResponse

export type DeleteConfigurationFail =
  | DeleteCmsConfigurationsConfigurationId403ErrorResponse
  | DeleteCmsConfigurationsConfigurationId404ErrorResponse

export type DeleteConfigurationResult = ResponseVoid

export type DeleteData = ResponseVoid

/** DeleteDynamicConfigConfigId403Error */
export interface DeleteDynamicConfigConfigId403Error {
  code: 'sign_in_required'
}

/** DeleteDynamicConfigConfigId403ErrorResponse */
export interface DeleteDynamicConfigConfigId403ErrorResponse {
  data: null
  errors: DeleteDynamicConfigConfigId403Error[]
}

/** DeleteDynamicConfigConfigId404Error */
export interface DeleteDynamicConfigConfigId404Error {
  code: 'dynamic_config_not_found'
}

/** DeleteDynamicConfigConfigId404ErrorResponse */
export interface DeleteDynamicConfigConfigId404ErrorResponse {
  data: null
  errors: DeleteDynamicConfigConfigId404Error[]
}

export type DeleteDynamicConfigData = ResponseVoid

export type DeleteDynamicConfigError =
  | DeleteDynamicConfigConfigId403ErrorResponse
  | DeleteDynamicConfigConfigId404ErrorResponse

/** DeleteEnginesEngineId400Error */
export interface DeleteEnginesEngineId400Error {
  code: 'game_engine_is_default' | 'game_engine_used_by_game' | 'game_engine_used_by_game_wrapper'
}

/** DeleteEnginesEngineId400ErrorResponse */
export interface DeleteEnginesEngineId400ErrorResponse {
  data: null
  errors: DeleteEnginesEngineId400Error[]
}

/** DeleteEnginesEngineId403Error */
export interface DeleteEnginesEngineId403Error {
  code: 'sign_in_required'
}

/** DeleteEnginesEngineId403ErrorResponse */
export interface DeleteEnginesEngineId403ErrorResponse {
  data: null
  errors: DeleteEnginesEngineId403Error[]
}

/** DeleteEnginesEngineId404Error */
export interface DeleteEnginesEngineId404Error {
  code: 'game_engine_not_found'
}

/** DeleteEnginesEngineId404ErrorResponse */
export interface DeleteEnginesEngineId404ErrorResponse {
  data: null
  errors: DeleteEnginesEngineId404Error[]
}

export type DeleteError =
  | DeleteGameWrappersGameWrapperId400ErrorResponse
  | DeleteGameWrappersGameWrapperId403ErrorResponse
  | DeleteGameWrappersGameWrapperId404ErrorResponse

export type DeleteFail =
  | DeleteWrappersWrapperId400ErrorResponse
  | DeleteWrappersWrapperId403ErrorResponse
  | DeleteWrappersWrapperId404ErrorResponse

export type DeleteFeedData = ResponseVoid

export type DeleteFeedError =
  | DeleteFeedsFeedId400ErrorResponse
  | DeleteFeedsFeedId403ErrorResponse
  | DeleteFeedsFeedId404ErrorResponse

/** DeleteFeedsFeedId400Error */
export interface DeleteFeedsFeedId400Error {
  code: 'feed_referenced_by_cms_section'
}

/** DeleteFeedsFeedId400ErrorResponse */
export interface DeleteFeedsFeedId400ErrorResponse {
  data: null
  errors: DeleteFeedsFeedId400Error[]
}

/** DeleteFeedsFeedId403Error */
export interface DeleteFeedsFeedId403Error {
  code: 'sign_in_required'
}

/** DeleteFeedsFeedId403ErrorResponse */
export interface DeleteFeedsFeedId403ErrorResponse {
  data: null
  errors: DeleteFeedsFeedId403Error[]
}

/** DeleteFeedsFeedId404Error */
export interface DeleteFeedsFeedId404Error {
  code: 'feed_not_found'
}

/** DeleteFeedsFeedId404ErrorResponse */
export interface DeleteFeedsFeedId404ErrorResponse {
  data: null
  errors: DeleteFeedsFeedId404Error[]
}

/** DeleteFeedsFeedIdEntriesEntryId403Error */
export interface DeleteFeedsFeedIdEntriesEntryId403Error {
  code: 'sign_in_required'
}

/** DeleteFeedsFeedIdEntriesEntryId403ErrorResponse */
export interface DeleteFeedsFeedIdEntriesEntryId403ErrorResponse {
  data: null
  errors: DeleteFeedsFeedIdEntriesEntryId403Error[]
}

/** DeleteFeedsFeedIdEntriesEntryId404Error */
export interface DeleteFeedsFeedIdEntriesEntryId404Error {
  code: 'entry_not_found' | 'feed_not_found'
}

/** DeleteFeedsFeedIdEntriesEntryId404ErrorResponse */
export interface DeleteFeedsFeedIdEntriesEntryId404ErrorResponse {
  data: null
  errors: DeleteFeedsFeedIdEntriesEntryId404Error[]
}

export type DeleteFlavorForAllReleasesData = ResponseDeleteModFlavorsResponse

export type DeleteFlavorForAllReleasesError =
  | PostModsFlavorsDeleteFlavorForAllReleases400ErrorResponse
  | PostModsFlavorsDeleteFlavorForAllReleases403ErrorResponse

export interface DeleteFlavorForAllReleasesParams {
  /** If empty, delete all base and custom flavors. If not empty, delete only custom flavors with a matching feature set. */
  features?: string[]
  /**
   * The page to return. The response will contain mod flavors in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod flavors to include in the response. Fewer mod flavors may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** DeleteFundingWaitListSubscriberId403Error */
export interface DeleteFundingWaitListSubscriberId403Error {
  code: 'sign_in_required'
}

/** DeleteFundingWaitListSubscriberId403ErrorResponse */
export interface DeleteFundingWaitListSubscriberId403ErrorResponse {
  data: null
  errors: DeleteFundingWaitListSubscriberId403Error[]
}

/** DeleteFundingWaitListSubscriberId404Error */
export interface DeleteFundingWaitListSubscriberId404Error {
  code: 'waitlist_subscriber_not_found'
}

/** DeleteFundingWaitListSubscriberId404ErrorResponse */
export interface DeleteFundingWaitListSubscriberId404ErrorResponse {
  data: null
  errors: DeleteFundingWaitListSubscriberId404Error[]
}

export type DeleteGameBannerData = ResponseVoid

export type DeleteGameBannerError =
  | DeleteGamesGameIdBannersBannerId400ErrorResponse
  | DeleteGamesGameIdBannersBannerId403ErrorResponse
  | DeleteGamesGameIdBannersBannerId404ErrorResponse

export type DeleteGameControlData = ResponseVoid

export type DeleteGameControlError =
  | DeleteGamesGameIdControlsGameControlId400ErrorResponse
  | DeleteGamesGameIdControlsGameControlId403ErrorResponse
  | DeleteGamesGameIdControlsGameControlId404ErrorResponse

export type DeleteGameControlGroupData = ResponseVoid

export type DeleteGameControlGroupError =
  | DeleteGamesGameIdControlGroupsControlGroupId400ErrorResponse
  | DeleteGamesGameIdControlGroupsControlGroupId403ErrorResponse
  | DeleteGamesGameIdControlGroupsControlGroupId404ErrorResponse

export type DeleteGameControlSubgroupData = ResponseVoid

export type DeleteGameControlSubgroupError =
  | DeleteGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse
  | DeleteGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse
  | DeleteGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse

export type DeleteGameCoverData = ResponseVoid

export type DeleteGameCoverError =
  | DeleteGamesGameIdCoverCoverId400ErrorResponse
  | DeleteGamesGameIdCoverCoverId403ErrorResponse
  | DeleteGamesGameIdCoverCoverId404ErrorResponse

export type DeleteGameEngineData = ResponseVoid

export type DeleteGameEngineError =
  | DeleteEnginesEngineId400ErrorResponse
  | DeleteEnginesEngineId403ErrorResponse
  | DeleteEnginesEngineId404ErrorResponse

export type DeleteGameItemData = ResponseVoid

export type DeleteGameItemError =
  | DeleteGamesGameIdItemsItemId400ErrorResponse
  | DeleteGamesGameIdItemsItemId403ErrorResponse
  | DeleteGamesGameIdItemsItemId404ErrorResponse
  | DeleteGamesGameIdItemsItemId500ErrorResponse

/** DeleteGameWrappersGameWrapperId400Error */
export interface DeleteGameWrappersGameWrapperId400Error {
  code: 'game_wrapper_is_active' | 'game_wrapper_is_default'
}

/** DeleteGameWrappersGameWrapperId400ErrorResponse */
export interface DeleteGameWrappersGameWrapperId400ErrorResponse {
  data: null
  errors: DeleteGameWrappersGameWrapperId400Error[]
}

/** DeleteGameWrappersGameWrapperId403Error */
export interface DeleteGameWrappersGameWrapperId403Error {
  code: 'sign_in_required'
}

/** DeleteGameWrappersGameWrapperId403ErrorResponse */
export interface DeleteGameWrappersGameWrapperId403ErrorResponse {
  data: null
  errors: DeleteGameWrappersGameWrapperId403Error[]
}

/** DeleteGameWrappersGameWrapperId404Error */
export interface DeleteGameWrappersGameWrapperId404Error {
  code: 'game_wrapper_not_found'
}

/** DeleteGameWrappersGameWrapperId404ErrorResponse */
export interface DeleteGameWrappersGameWrapperId404ErrorResponse {
  data: null
  errors: DeleteGameWrappersGameWrapperId404Error[]
}

/** DeleteGamesGameIdBannersBannerId400Error */
export interface DeleteGamesGameIdBannersBannerId400Error {
  code: 'game_access_denied'
}

/** DeleteGamesGameIdBannersBannerId400ErrorResponse */
export interface DeleteGamesGameIdBannersBannerId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdBannersBannerId400Error[]
}

/** DeleteGamesGameIdBannersBannerId403Error */
export interface DeleteGamesGameIdBannersBannerId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdBannersBannerId403ErrorResponse */
export interface DeleteGamesGameIdBannersBannerId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdBannersBannerId403Error[]
}

/** DeleteGamesGameIdBannersBannerId404Error */
export interface DeleteGamesGameIdBannersBannerId404Error {
  code: 'game_banner_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdBannersBannerId404ErrorResponse */
export interface DeleteGamesGameIdBannersBannerId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdBannersBannerId404Error[]
}

/** DeleteGamesGameIdControlGroupsControlGroupId400Error */
export interface DeleteGamesGameIdControlGroupsControlGroupId400Error {
  code: 'game_access_denied'
}

/** DeleteGamesGameIdControlGroupsControlGroupId400ErrorResponse */
export interface DeleteGamesGameIdControlGroupsControlGroupId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlGroupsControlGroupId400Error[]
}

/** DeleteGamesGameIdControlGroupsControlGroupId403Error */
export interface DeleteGamesGameIdControlGroupsControlGroupId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdControlGroupsControlGroupId403ErrorResponse */
export interface DeleteGamesGameIdControlGroupsControlGroupId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlGroupsControlGroupId403Error[]
}

/** DeleteGamesGameIdControlGroupsControlGroupId404Error */
export interface DeleteGamesGameIdControlGroupsControlGroupId404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdControlGroupsControlGroupId404ErrorResponse */
export interface DeleteGamesGameIdControlGroupsControlGroupId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlGroupsControlGroupId404Error[]
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId400Error */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId400Error {
  code: 'game_access_denied'
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlSubgroupsControlSubgroupId400Error[]
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId403Error */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlSubgroupsControlSubgroupId403Error[]
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId404Error */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse */
export interface DeleteGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlSubgroupsControlSubgroupId404Error[]
}

/** DeleteGamesGameIdControlsGameControlId400Error */
export interface DeleteGamesGameIdControlsGameControlId400Error {
  code: 'game_access_denied'
}

/** DeleteGamesGameIdControlsGameControlId400ErrorResponse */
export interface DeleteGamesGameIdControlsGameControlId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlsGameControlId400Error[]
}

/** DeleteGamesGameIdControlsGameControlId403Error */
export interface DeleteGamesGameIdControlsGameControlId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdControlsGameControlId403ErrorResponse */
export interface DeleteGamesGameIdControlsGameControlId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlsGameControlId403Error[]
}

/** DeleteGamesGameIdControlsGameControlId404Error */
export interface DeleteGamesGameIdControlsGameControlId404Error {
  code: 'game_control_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdControlsGameControlId404ErrorResponse */
export interface DeleteGamesGameIdControlsGameControlId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdControlsGameControlId404Error[]
}

/** DeleteGamesGameIdCoverCoverId400Error */
export interface DeleteGamesGameIdCoverCoverId400Error {
  code: 'breaking_published_game' | 'game_access_denied'
}

/** DeleteGamesGameIdCoverCoverId400ErrorResponse */
export interface DeleteGamesGameIdCoverCoverId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdCoverCoverId400Error[]
}

/** DeleteGamesGameIdCoverCoverId403Error */
export interface DeleteGamesGameIdCoverCoverId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdCoverCoverId403ErrorResponse */
export interface DeleteGamesGameIdCoverCoverId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdCoverCoverId403Error[]
}

/** DeleteGamesGameIdCoverCoverId404Error */
export interface DeleteGamesGameIdCoverCoverId404Error {
  code: 'game_cover_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdCoverCoverId404ErrorResponse */
export interface DeleteGamesGameIdCoverCoverId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdCoverCoverId404Error[]
}

/** DeleteGamesGameIdItemsItemId400Error */
export interface DeleteGamesGameIdItemsItemId400Error {
  code: 'game_access_denied'
}

/** DeleteGamesGameIdItemsItemId400ErrorResponse */
export interface DeleteGamesGameIdItemsItemId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemId400Error[]
}

/** DeleteGamesGameIdItemsItemId403Error */
export interface DeleteGamesGameIdItemsItemId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdItemsItemId403ErrorResponse */
export interface DeleteGamesGameIdItemsItemId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemId403Error[]
}

/** DeleteGamesGameIdItemsItemId404Error */
export interface DeleteGamesGameIdItemsItemId404Error {
  code: 'game_item_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdItemsItemId404ErrorResponse */
export interface DeleteGamesGameIdItemsItemId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemId404Error[]
}

/** DeleteGamesGameIdItemsItemId500Error */
export interface DeleteGamesGameIdItemsItemId500Error {
  code: 'internal_server_error'
}

/** DeleteGamesGameIdItemsItemId500ErrorResponse */
export interface DeleteGamesGameIdItemsItemId500ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemId500Error[]
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error {
  code: 'game_item_claim_period_was_started'
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error[]
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error {
  code: 'sign_in_required'
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error[]
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error {
  code: 'game_item_claim_period_not_found' | 'game_item_not_found' | 'game_not_found'
}

/** DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse */
export interface DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse {
  data: null
  errors: DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error[]
}

/** DeleteGmsRunners400Error */
export interface DeleteGmsRunners400Error {
  code: 'gms_runner_version_in_use' | 'invalid_request_payload'
}

/** DeleteGmsRunners400ErrorResponse */
export interface DeleteGmsRunners400ErrorResponse {
  data: null
  errors: DeleteGmsRunners400Error[]
}

/** DeleteGmsRunners403Error */
export interface DeleteGmsRunners403Error {
  code: 'sign_in_required'
}

/** DeleteGmsRunners403ErrorResponse */
export interface DeleteGmsRunners403ErrorResponse {
  data: null
  errors: DeleteGmsRunners403Error[]
}

/** DeleteGmsRunners404Error */
export interface DeleteGmsRunners404Error {
  code: 'gms_runner_version_not_found'
}

/** DeleteGmsRunners404ErrorResponse */
export interface DeleteGmsRunners404ErrorResponse {
  data: null
  errors: DeleteGmsRunners404Error[]
}

/** DeleteGmsRunners500Error */
export interface DeleteGmsRunners500Error {
  code: 'internal_server_error'
}

/** DeleteGmsRunners500ErrorResponse */
export interface DeleteGmsRunners500ErrorResponse {
  data: null
  errors: DeleteGmsRunners500Error[]
}

/** DeleteGmsRunnersRunnerId400Error */
export interface DeleteGmsRunnersRunnerId400Error {
  code: 'gms_runner_version_in_use'
}

/** DeleteGmsRunnersRunnerId400ErrorResponse */
export interface DeleteGmsRunnersRunnerId400ErrorResponse {
  data: null
  errors: DeleteGmsRunnersRunnerId400Error[]
}

/** DeleteGmsRunnersRunnerId403Error */
export interface DeleteGmsRunnersRunnerId403Error {
  code: 'sign_in_required'
}

/** DeleteGmsRunnersRunnerId403ErrorResponse */
export interface DeleteGmsRunnersRunnerId403ErrorResponse {
  data: null
  errors: DeleteGmsRunnersRunnerId403Error[]
}

/** DeleteGmsRunnersRunnerId404Error */
export interface DeleteGmsRunnersRunnerId404Error {
  code: 'gms_runner_version_not_found'
}

/** DeleteGmsRunnersRunnerId404ErrorResponse */
export interface DeleteGmsRunnersRunnerId404ErrorResponse {
  data: null
  errors: DeleteGmsRunnersRunnerId404Error[]
}

/** DeleteGmsRunnersRunnerId500Error */
export interface DeleteGmsRunnersRunnerId500Error {
  code: 'internal_server_error'
}

/** DeleteGmsRunnersRunnerId500ErrorResponse */
export interface DeleteGmsRunnersRunnerId500ErrorResponse {
  data: null
  errors: DeleteGmsRunnersRunnerId500Error[]
}

export type DeleteIconData = ResponseVoid

export type DeleteIconError =
  | DeleteTagsTagIdIcon403ErrorResponse
  | DeleteTagsTagIdIcon404ErrorResponse

export type DeleteIconFail =
  | DeleteUserUserIdBioLinksLinkIdIcon403ErrorResponse
  | DeleteUserUserIdBioLinksLinkIdIcon404ErrorResponse
  | DeleteUserUserIdBioLinksLinkIdIcon500ErrorResponse

export type DeleteIconResult = ResponseVoid

export type DeleteIncorrectVideoGameCoverVariantsData = ResponseInteger

export type DeleteIncorrectVideoGameCoverVariantsError =
  DeleteAssetVariantsIncorrectVideoGameCovers403ErrorResponse

export type DeleteModCoverData = ResponseVoid

export type DeleteModCoverError =
  | DeleteModsModIdCoverCoverId400ErrorResponse
  | DeleteModsModIdCoverCoverId403ErrorResponse
  | DeleteModsModIdCoverCoverId404ErrorResponse

export type DeleteModFlavorData = ResponseVoid

export type DeleteModFlavorError =
  | DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400ErrorResponse
  | DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403ErrorResponse
  | DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404ErrorResponse

/** DeleteModFlavorsResponse */
export interface DeleteModFlavorsResponse {
  pagination: PaginationResponse
}

export type DeleteModGraphicData = ResponseVoid

export type DeleteModGraphicError =
  | DeleteModsModIdGraphicsGraphicId403ErrorResponse
  | DeleteModsModIdGraphicsGraphicId404ErrorResponse
  | DeleteModsModIdGraphicsGraphicId500ErrorResponse

/** DeleteModsModIdCoverCoverId400Error */
export interface DeleteModsModIdCoverCoverId400Error {
  code: 'breaking_published_mod'
}

/** DeleteModsModIdCoverCoverId400ErrorResponse */
export interface DeleteModsModIdCoverCoverId400ErrorResponse {
  data: null
  errors: DeleteModsModIdCoverCoverId400Error[]
}

/** DeleteModsModIdCoverCoverId403Error */
export interface DeleteModsModIdCoverCoverId403Error {
  code: 'sign_in_required'
}

/** DeleteModsModIdCoverCoverId403ErrorResponse */
export interface DeleteModsModIdCoverCoverId403ErrorResponse {
  data: null
  errors: DeleteModsModIdCoverCoverId403Error[]
}

/** DeleteModsModIdCoverCoverId404Error */
export interface DeleteModsModIdCoverCoverId404Error {
  code: 'cover_not_found' | 'mod_not_found'
}

/** DeleteModsModIdCoverCoverId404ErrorResponse */
export interface DeleteModsModIdCoverCoverId404ErrorResponse {
  data: null
  errors: DeleteModsModIdCoverCoverId404Error[]
}

/** DeleteModsModIdGraphicsGraphicId403Error */
export interface DeleteModsModIdGraphicsGraphicId403Error {
  code: 'sign_in_required'
}

/** DeleteModsModIdGraphicsGraphicId403ErrorResponse */
export interface DeleteModsModIdGraphicsGraphicId403ErrorResponse {
  data: null
  errors: DeleteModsModIdGraphicsGraphicId403Error[]
}

/** DeleteModsModIdGraphicsGraphicId404Error */
export interface DeleteModsModIdGraphicsGraphicId404Error {
  code: 'asset_not_found' | 'mod_not_found'
}

/** DeleteModsModIdGraphicsGraphicId404ErrorResponse */
export interface DeleteModsModIdGraphicsGraphicId404ErrorResponse {
  data: null
  errors: DeleteModsModIdGraphicsGraphicId404Error[]
}

/** DeleteModsModIdGraphicsGraphicId500Error */
export interface DeleteModsModIdGraphicsGraphicId500Error {
  code: 'internal_server_error'
}

/** DeleteModsModIdGraphicsGraphicId500ErrorResponse */
export interface DeleteModsModIdGraphicsGraphicId500ErrorResponse {
  data: null
  errors: DeleteModsModIdGraphicsGraphicId500Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId400Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId400Error {
  code: 'release_in_initial_state' | 'release_not_pending'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId400ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId400ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseId400Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId403Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId403Error {
  code: 'sign_in_required'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId403ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId403ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseId403Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId404Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId404Error {
  code: 'mod_not_found' | 'release_not_found' | 'track_not_found'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseId404ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseId404ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseId404Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400Error {
  code: 'delete_origin_flavor_not_allowed'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403Error {
  code: 'sign_in_required'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403Error[]
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404Error */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404Error {
  code: 'mod_flavor_not_found' | 'mod_not_found' | 'release_not_found' | 'track_not_found'
}

/** DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404ErrorResponse */
export interface DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404ErrorResponse {
  data: null
  errors: DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404Error[]
}

export type DeleteMultiplayerGuestAvatarData = ResponseVoid

export type DeleteMultiplayerGuestAvatarError =
  | DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403ErrorResponse
  | DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404ErrorResponse

/** DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403Error */
export interface DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403Error {
  code: 'sign_in_required'
}

/** DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403ErrorResponse */
export interface DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403ErrorResponse {
  data: null
  errors: DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403Error[]
}

/** DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404Error */
export interface DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404Error {
  code: 'multiplayer_guest_avatar_not_found'
}

/** DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404ErrorResponse */
export interface DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404ErrorResponse {
  data: null
  errors: DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404Error[]
}

/** DeleteOdksKeyId400Error */
export interface DeleteOdksKeyId400Error {
  code: 'tag_referenced_by_filtered_feed'
}

/** DeleteOdksKeyId400ErrorResponse */
export interface DeleteOdksKeyId400ErrorResponse {
  data: null
  errors: DeleteOdksKeyId400Error[]
}

/** DeleteOdksKeyId403Error */
export interface DeleteOdksKeyId403Error {
  code: 'sign_in_required'
}

/** DeleteOdksKeyId403ErrorResponse */
export interface DeleteOdksKeyId403ErrorResponse {
  data: null
  errors: DeleteOdksKeyId403Error[]
}

/** DeleteOdksKeyId404Error */
export interface DeleteOdksKeyId404Error {
  code: 'official_distribution_key_not_found'
}

/** DeleteOdksKeyId404ErrorResponse */
export interface DeleteOdksKeyId404ErrorResponse {
  data: null
  errors: DeleteOdksKeyId404Error[]
}

/** DeleteOdksKeyIdModsModId400Error */
export interface DeleteOdksKeyIdModsModId400Error {
  code: 'official_distribution_key_not_assigned_to_mod'
}

/** DeleteOdksKeyIdModsModId400ErrorResponse */
export interface DeleteOdksKeyIdModsModId400ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdModsModId400Error[]
}

/** DeleteOdksKeyIdModsModId403Error */
export interface DeleteOdksKeyIdModsModId403Error {
  code: 'sign_in_required'
}

/** DeleteOdksKeyIdModsModId403ErrorResponse */
export interface DeleteOdksKeyIdModsModId403ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdModsModId403Error[]
}

/** DeleteOdksKeyIdModsModId404Error */
export interface DeleteOdksKeyIdModsModId404Error {
  code: 'mod_not_found' | 'official_distribution_key_not_found'
}

/** DeleteOdksKeyIdModsModId404ErrorResponse */
export interface DeleteOdksKeyIdModsModId404ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdModsModId404Error[]
}

/** DeleteOdksKeyIdStudiosStudioId400Error */
export interface DeleteOdksKeyIdStudiosStudioId400Error {
  code: 'official_distribution_key_not_assigned_to_studio' | 'studio_access_denied'
}

/** DeleteOdksKeyIdStudiosStudioId400ErrorResponse */
export interface DeleteOdksKeyIdStudiosStudioId400ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdStudiosStudioId400Error[]
}

/** DeleteOdksKeyIdStudiosStudioId403Error */
export interface DeleteOdksKeyIdStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** DeleteOdksKeyIdStudiosStudioId403ErrorResponse */
export interface DeleteOdksKeyIdStudiosStudioId403ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdStudiosStudioId403Error[]
}

/** DeleteOdksKeyIdStudiosStudioId404Error */
export interface DeleteOdksKeyIdStudiosStudioId404Error {
  code: 'official_distribution_key_not_found' | 'studio_not_found'
}

/** DeleteOdksKeyIdStudiosStudioId404ErrorResponse */
export interface DeleteOdksKeyIdStudiosStudioId404ErrorResponse {
  data: null
  errors: DeleteOdksKeyIdStudiosStudioId404Error[]
}

export type DeleteOfficialDistributionKeyData = ResponseVoid

export type DeleteOfficialDistributionKeyError =
  | DeleteOdksKeyId400ErrorResponse
  | DeleteOdksKeyId403ErrorResponse
  | DeleteOdksKeyId404ErrorResponse

export type DeletePendingReleaseData = ResponseVoid

export type DeletePendingReleaseError =
  | DeleteModsModIdTracksTrackIdReleasesReleaseId400ErrorResponse
  | DeleteModsModIdTracksTrackIdReleasesReleaseId403ErrorResponse
  | DeleteModsModIdTracksTrackIdReleasesReleaseId404ErrorResponse

export type DeletePromotedChallengeData = ResponseVoid

export type DeletePromotedChallengeError =
  | DeletePromotedChallengesPromotedChallengeId400ErrorResponse
  | DeletePromotedChallengesPromotedChallengeId403ErrorResponse
  | DeletePromotedChallengesPromotedChallengeId404ErrorResponse

/** DeletePromotedChallengesPromotedChallengeId400Error */
export interface DeletePromotedChallengesPromotedChallengeId400Error {
  code:
    | 'delete_open_promotion_not_allowed'
    | 'delete_published_promotion_not_allowed'
    | 'delete_season_associated_promotion_not_allowed'
}

/** DeletePromotedChallengesPromotedChallengeId400ErrorResponse */
export interface DeletePromotedChallengesPromotedChallengeId400ErrorResponse {
  data: null
  errors: DeletePromotedChallengesPromotedChallengeId400Error[]
}

/** DeletePromotedChallengesPromotedChallengeId403Error */
export interface DeletePromotedChallengesPromotedChallengeId403Error {
  code: 'sign_in_required'
}

/** DeletePromotedChallengesPromotedChallengeId403ErrorResponse */
export interface DeletePromotedChallengesPromotedChallengeId403ErrorResponse {
  data: null
  errors: DeletePromotedChallengesPromotedChallengeId403Error[]
}

/** DeletePromotedChallengesPromotedChallengeId404Error */
export interface DeletePromotedChallengesPromotedChallengeId404Error {
  code: 'promoted_challenge_not_found'
}

/** DeletePromotedChallengesPromotedChallengeId404ErrorResponse */
export interface DeletePromotedChallengesPromotedChallengeId404ErrorResponse {
  data: null
  errors: DeletePromotedChallengesPromotedChallengeId404Error[]
}

export type DeletePublisherData = ResponseVoid

export type DeletePublisherError =
  | DeletePublishersPublisherId400ErrorResponse
  | DeletePublishersPublisherId403ErrorResponse
  | DeletePublishersPublisherId404ErrorResponse

/** DeletePublishersPublisherId400Error */
export interface DeletePublishersPublisherId400Error {
  code: 'publisher_used_by_game'
}

/** DeletePublishersPublisherId400ErrorResponse */
export interface DeletePublishersPublisherId400ErrorResponse {
  data: null
  errors: DeletePublishersPublisherId400Error[]
}

/** DeletePublishersPublisherId403Error */
export interface DeletePublishersPublisherId403Error {
  code: 'sign_in_required'
}

/** DeletePublishersPublisherId403ErrorResponse */
export interface DeletePublishersPublisherId403ErrorResponse {
  data: null
  errors: DeletePublishersPublisherId403Error[]
}

/** DeletePublishersPublisherId404Error */
export interface DeletePublishersPublisherId404Error {
  code: 'publisher_not_found'
}

/** DeletePublishersPublisherId404ErrorResponse */
export interface DeletePublishersPublisherId404ErrorResponse {
  data: null
  errors: DeletePublishersPublisherId404Error[]
}

export type DeleteQuestData = ResponseVoid

export type DeleteQuestError =
  | DeleteQuestsQuestId400ErrorResponse
  | DeleteQuestsQuestId403ErrorResponse
  | DeleteQuestsQuestId404ErrorResponse

/** DeleteQuestsQuestId400Error */
export interface DeleteQuestsQuestId400Error {
  code: 'quest_has_completed_attempts' | 'quest_is_active'
}

/** DeleteQuestsQuestId400ErrorResponse */
export interface DeleteQuestsQuestId400ErrorResponse {
  data: null
  errors: DeleteQuestsQuestId400Error[]
}

/** DeleteQuestsQuestId403Error */
export interface DeleteQuestsQuestId403Error {
  code: 'sign_in_required'
}

/** DeleteQuestsQuestId403ErrorResponse */
export interface DeleteQuestsQuestId403ErrorResponse {
  data: null
  errors: DeleteQuestsQuestId403Error[]
}

/** DeleteQuestsQuestId404Error */
export interface DeleteQuestsQuestId404Error {
  code: 'quest_not_found'
}

/** DeleteQuestsQuestId404ErrorResponse */
export interface DeleteQuestsQuestId404ErrorResponse {
  data: null
  errors: DeleteQuestsQuestId404Error[]
}

export type DeleteRankingsData = ResponseVoid

export type DeleteRankingsError = DeleteChallengesRankings403ErrorResponse

export type DeleteResult = ResponseVoid

export type DeleteRunner2Data = ResponseVoid

export type DeleteRunner2Error =
  | DeleteGmsRunnersRunnerId400ErrorResponse
  | DeleteGmsRunnersRunnerId403ErrorResponse
  | DeleteGmsRunnersRunnerId404ErrorResponse
  | DeleteGmsRunnersRunnerId500ErrorResponse

export type DeleteRunnerData = ResponseVoid

export type DeleteRunnerError =
  | DeleteGmsRunners400ErrorResponse
  | DeleteGmsRunners403ErrorResponse
  | DeleteGmsRunners404ErrorResponse
  | DeleteGmsRunners500ErrorResponse

/** DeleteRunnersCleanup403Error */
export interface DeleteRunnersCleanup403Error {
  code: 'sign_in_required'
}

/** DeleteRunnersCleanup403ErrorResponse */
export interface DeleteRunnersCleanup403ErrorResponse {
  data: null
  errors: DeleteRunnersCleanup403Error[]
}

export type DeleteSeasonData = ResponseVoid

export type DeleteSeasonError =
  | DeleteSeasonsSeasonId403ErrorResponse
  | DeleteSeasonsSeasonId404ErrorResponse

/** DeleteSeasonsSeasonId403Error */
export interface DeleteSeasonsSeasonId403Error {
  code: 'sign_in_required'
}

/** DeleteSeasonsSeasonId403ErrorResponse */
export interface DeleteSeasonsSeasonId403ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonId403Error[]
}

/** DeleteSeasonsSeasonId404Error */
export interface DeleteSeasonsSeasonId404Error {
  code: 'season_not_found'
}

/** DeleteSeasonsSeasonId404ErrorResponse */
export interface DeleteSeasonsSeasonId404ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonId404Error[]
}

/** DeleteSeasonsSeasonIdBlockedGamesGameId403Error */
export interface DeleteSeasonsSeasonIdBlockedGamesGameId403Error {
  code: 'sign_in_required'
}

/** DeleteSeasonsSeasonIdBlockedGamesGameId403ErrorResponse */
export interface DeleteSeasonsSeasonIdBlockedGamesGameId403ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonIdBlockedGamesGameId403Error[]
}

/** DeleteSeasonsSeasonIdBlockedGamesGameId404Error */
export interface DeleteSeasonsSeasonIdBlockedGamesGameId404Error {
  code: 'game_not_found' | 'season_not_found'
}

/** DeleteSeasonsSeasonIdBlockedGamesGameId404ErrorResponse */
export interface DeleteSeasonsSeasonIdBlockedGamesGameId404ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonIdBlockedGamesGameId404Error[]
}

/** DeleteSeasonsSeasonIdBlockedStudiosStudioId403Error */
export interface DeleteSeasonsSeasonIdBlockedStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** DeleteSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse */
export interface DeleteSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonIdBlockedStudiosStudioId403Error[]
}

/** DeleteSeasonsSeasonIdBlockedStudiosStudioId404Error */
export interface DeleteSeasonsSeasonIdBlockedStudiosStudioId404Error {
  code: 'season_not_found' | 'studio_not_found'
}

/** DeleteSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse */
export interface DeleteSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse {
  data: null
  errors: DeleteSeasonsSeasonIdBlockedStudiosStudioId404Error[]
}

export type DeleteSectionData = ResponseVoid

export type DeleteSectionError =
  | DeleteCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse
  | DeleteCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse

export type DeleteStudioData = ResponseVoid

export type DeleteStudioError =
  | DeleteStudiosStudioId400ErrorResponse
  | DeleteStudiosStudioId403ErrorResponse
  | DeleteStudiosStudioId404ErrorResponse

/** DeleteStudiosStudioId400Error */
export interface DeleteStudiosStudioId400Error {
  code: 'entity_already_deleted' | 'studio_access_denied'
}

/** DeleteStudiosStudioId400ErrorResponse */
export interface DeleteStudiosStudioId400ErrorResponse {
  data: null
  errors: DeleteStudiosStudioId400Error[]
}

/** DeleteStudiosStudioId403Error */
export interface DeleteStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** DeleteStudiosStudioId403ErrorResponse */
export interface DeleteStudiosStudioId403ErrorResponse {
  data: null
  errors: DeleteStudiosStudioId403Error[]
}

/** DeleteStudiosStudioId404Error */
export interface DeleteStudiosStudioId404Error {
  code: 'studio_not_found'
}

/** DeleteStudiosStudioId404ErrorResponse */
export interface DeleteStudiosStudioId404ErrorResponse {
  data: null
  errors: DeleteStudiosStudioId404Error[]
}

/** DeleteStudiosStudioIdCoversCoverId400Error */
export interface DeleteStudiosStudioIdCoversCoverId400Error {
  code: 'studio_access_denied'
}

/** DeleteStudiosStudioIdCoversCoverId400ErrorResponse */
export interface DeleteStudiosStudioIdCoversCoverId400ErrorResponse {
  data: null
  errors: DeleteStudiosStudioIdCoversCoverId400Error[]
}

/** DeleteStudiosStudioIdCoversCoverId403Error */
export interface DeleteStudiosStudioIdCoversCoverId403Error {
  code: 'sign_in_required'
}

/** DeleteStudiosStudioIdCoversCoverId403ErrorResponse */
export interface DeleteStudiosStudioIdCoversCoverId403ErrorResponse {
  data: null
  errors: DeleteStudiosStudioIdCoversCoverId403Error[]
}

/** DeleteStudiosStudioIdCoversCoverId404Error */
export interface DeleteStudiosStudioIdCoversCoverId404Error {
  code: 'studio_cover_not_found' | 'studio_not_found'
}

/** DeleteStudiosStudioIdCoversCoverId404ErrorResponse */
export interface DeleteStudiosStudioIdCoversCoverId404ErrorResponse {
  data: null
  errors: DeleteStudiosStudioIdCoversCoverId404Error[]
}

export type DeleteTagData = ResponseVoid

export type DeleteTagError =
  | DeleteTagsTagId400ErrorResponse
  | DeleteTagsTagId403ErrorResponse
  | DeleteTagsTagId404ErrorResponse

/** DeleteTagsTagId400Error */
export interface DeleteTagsTagId400Error {
  code:
    | 'delete_discounted_games_tag_not_allowed'
    | 'tag_referenced_by_filtered_feed'
    | 'tag_referenced_by_official_distribution_key'
}

/** DeleteTagsTagId400ErrorResponse */
export interface DeleteTagsTagId400ErrorResponse {
  data: null
  errors: DeleteTagsTagId400Error[]
}

/** DeleteTagsTagId403Error */
export interface DeleteTagsTagId403Error {
  code: 'sign_in_required'
}

/** DeleteTagsTagId403ErrorResponse */
export interface DeleteTagsTagId403ErrorResponse {
  data: null
  errors: DeleteTagsTagId403Error[]
}

/** DeleteTagsTagId404Error */
export interface DeleteTagsTagId404Error {
  code: 'tag_not_found'
}

/** DeleteTagsTagId404ErrorResponse */
export interface DeleteTagsTagId404ErrorResponse {
  data: null
  errors: DeleteTagsTagId404Error[]
}

/** DeleteTagsTagIdGamesGameId400Error */
export interface DeleteTagsTagIdGamesGameId400Error {
  code: 'game_access_denied' | 'game_does_not_have_tag'
}

/** DeleteTagsTagIdGamesGameId400ErrorResponse */
export interface DeleteTagsTagIdGamesGameId400ErrorResponse {
  data: null
  errors: DeleteTagsTagIdGamesGameId400Error[]
}

/** DeleteTagsTagIdGamesGameId403Error */
export interface DeleteTagsTagIdGamesGameId403Error {
  code: 'sign_in_required'
}

/** DeleteTagsTagIdGamesGameId403ErrorResponse */
export interface DeleteTagsTagIdGamesGameId403ErrorResponse {
  data: null
  errors: DeleteTagsTagIdGamesGameId403Error[]
}

/** DeleteTagsTagIdGamesGameId404Error */
export interface DeleteTagsTagIdGamesGameId404Error {
  code: 'game_not_found' | 'tag_not_found'
}

/** DeleteTagsTagIdGamesGameId404ErrorResponse */
export interface DeleteTagsTagIdGamesGameId404ErrorResponse {
  data: null
  errors: DeleteTagsTagIdGamesGameId404Error[]
}

/** DeleteTagsTagIdIcon403Error */
export interface DeleteTagsTagIdIcon403Error {
  code: 'sign_in_required'
}

/** DeleteTagsTagIdIcon403ErrorResponse */
export interface DeleteTagsTagIdIcon403ErrorResponse {
  data: null
  errors: DeleteTagsTagIdIcon403Error[]
}

/** DeleteTagsTagIdIcon404Error */
export interface DeleteTagsTagIdIcon404Error {
  code: 'tag_not_found'
}

/** DeleteTagsTagIdIcon404ErrorResponse */
export interface DeleteTagsTagIdIcon404ErrorResponse {
  data: null
  errors: DeleteTagsTagIdIcon404Error[]
}

/** DeleteTagsTagIdModsModId400Error */
export interface DeleteTagsTagIdModsModId400Error {
  code: 'entity_does_not_have_tag' | 'tag_not_available_to_creators'
}

/** DeleteTagsTagIdModsModId400ErrorResponse */
export interface DeleteTagsTagIdModsModId400ErrorResponse {
  data: null
  errors: DeleteTagsTagIdModsModId400Error[]
}

/** DeleteTagsTagIdModsModId403Error */
export interface DeleteTagsTagIdModsModId403Error {
  code: 'sign_in_required'
}

/** DeleteTagsTagIdModsModId403ErrorResponse */
export interface DeleteTagsTagIdModsModId403ErrorResponse {
  data: null
  errors: DeleteTagsTagIdModsModId403Error[]
}

/** DeleteTagsTagIdModsModId404Error */
export interface DeleteTagsTagIdModsModId404Error {
  code: 'mod_not_found' | 'tag_not_found'
}

/** DeleteTagsTagIdModsModId404ErrorResponse */
export interface DeleteTagsTagIdModsModId404ErrorResponse {
  data: null
  errors: DeleteTagsTagIdModsModId404Error[]
}

/** DeleteUserUserIdBioLinksLinkId403Error */
export interface DeleteUserUserIdBioLinksLinkId403Error {
  code: 'sign_in_required'
}

/** DeleteUserUserIdBioLinksLinkId403ErrorResponse */
export interface DeleteUserUserIdBioLinksLinkId403ErrorResponse {
  data: null
  errors: DeleteUserUserIdBioLinksLinkId403Error[]
}

/** DeleteUserUserIdBioLinksLinkId404Error */
export interface DeleteUserUserIdBioLinksLinkId404Error {
  code: 'bio_link_not_found' | 'user_not_found'
}

/** DeleteUserUserIdBioLinksLinkId404ErrorResponse */
export interface DeleteUserUserIdBioLinksLinkId404ErrorResponse {
  data: null
  errors: DeleteUserUserIdBioLinksLinkId404Error[]
}

/** DeleteUserUserIdBioLinksLinkIdIcon403Error */
export interface DeleteUserUserIdBioLinksLinkIdIcon403Error {
  code: 'sign_in_required'
}

/** DeleteUserUserIdBioLinksLinkIdIcon403ErrorResponse */
export interface DeleteUserUserIdBioLinksLinkIdIcon403ErrorResponse {
  data: null
  errors: DeleteUserUserIdBioLinksLinkIdIcon403Error[]
}

/** DeleteUserUserIdBioLinksLinkIdIcon404Error */
export interface DeleteUserUserIdBioLinksLinkIdIcon404Error {
  code: 'bio_link_not_found' | 'user_not_found'
}

/** DeleteUserUserIdBioLinksLinkIdIcon404ErrorResponse */
export interface DeleteUserUserIdBioLinksLinkIdIcon404ErrorResponse {
  data: null
  errors: DeleteUserUserIdBioLinksLinkIdIcon404Error[]
}

/** DeleteUserUserIdBioLinksLinkIdIcon500Error */
export interface DeleteUserUserIdBioLinksLinkIdIcon500Error {
  code: 'internal_server_error'
}

/** DeleteUserUserIdBioLinksLinkIdIcon500ErrorResponse */
export interface DeleteUserUserIdBioLinksLinkIdIcon500ErrorResponse {
  data: null
  errors: DeleteUserUserIdBioLinksLinkIdIcon500Error[]
}

/** DeleteWrappersWrapperId400Error */
export interface DeleteWrappersWrapperId400Error {
  code: 'wrapper_is_active' | 'wrapper_is_default'
}

/** DeleteWrappersWrapperId400ErrorResponse */
export interface DeleteWrappersWrapperId400ErrorResponse {
  data: null
  errors: DeleteWrappersWrapperId400Error[]
}

/** DeleteWrappersWrapperId403Error */
export interface DeleteWrappersWrapperId403Error {
  code: 'sign_in_required'
}

/** DeleteWrappersWrapperId403ErrorResponse */
export interface DeleteWrappersWrapperId403ErrorResponse {
  data: null
  errors: DeleteWrappersWrapperId403Error[]
}

/** DeleteWrappersWrapperId404Error */
export interface DeleteWrappersWrapperId404Error {
  code: 'wrapper_not_found'
}

/** DeleteWrappersWrapperId404ErrorResponse */
export interface DeleteWrappersWrapperId404ErrorResponse {
  data: null
  errors: DeleteWrappersWrapperId404Error[]
}

export type DelistData = ResponseVoid

export type DelistError =
  | DeleteFundingWaitListSubscriberId403ErrorResponse
  | DeleteFundingWaitListSubscriberId404ErrorResponse

export type DetectManifestTraitsData = ResponseReprocessedModReleasesResponse

export type DetectManifestTraitsError =
  | PostModsDetectManifestTraits400ErrorResponse
  | PostModsDetectManifestTraits403ErrorResponse

export interface DetectManifestTraitsParams {
  /**
   * Make no changes to mods
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  dryRun: boolean
  /**
   * Force tag assignment
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  forceTagAssignment?: boolean
  /**
   * The page to return. The response will contain mod releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod releases to include in the response. Fewer mod releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

/** DeveloperFollowupResponse */
export interface DeveloperFollowupResponse {
  developer?: UserSummaryResponse | null
  /** @example "Probably caused by the last patch" */
  developerNotes?: string | null
  /** @example "Thanks, we're going to look into this" */
  replyToUser?: string | null
}

/** DiscountResponse */
export interface DiscountResponse {
  /**
   * Datetime when discounted price stops being effective. If null - effective since 'discountedPriceStartsAt' or always
   * @format date-time
   */
  endsAt?: string | null
  /**
   * Discounted price to be used instead of the standard price, or `null` if no discounted price is set for this item
   *
   * Notes: *The discounted price is a final value, i.e. not a percentage to be deducted from the standard price (like 10%, 20% etc), must be smaller than the standard price and is indicated in the currency specified in `currency` parameter.*
   * @example 15
   */
  price?: number | null
  /**
   * Datetime when discounted price starts being effective. If null - effective until 'discountedPriceEndsAt' or always
   * @format date-time
   */
  startsAt?: string | null
}

/** DisputeAddCommentRequest */
export interface DisputeAddCommentRequest {
  /** The comment */
  comment: string
}

/** DisputeAddRequest */
export interface DisputeAddRequest {
  /** The dispute description. */
  description?: string | null
  /**
   * Notes: *If pass "KEEP" value then user has the same access that was granted upon purchase, as before the dispute. If pass "REVERT" value then the user's access granted upon purchase will be suspended.*
   * @example "KEEP"
   */
  transactionStatus: 'KEEP' | 'REVERT'
}

/** DisputeCommentResponse */
export interface DisputeCommentResponse {
  comment?: string | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  commentId: string
  /** @format date-time */
  createdAt?: string | null
  createdBy?: BackOfficeUserResponse | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  disputeId: string
}

/** DisputeEditRequest */
export interface DisputeEditRequest {
  /** The dispute description. */
  description?: string | null
  /**
   * Notes: *If pass "KEEP" value then user has the same access that was granted upon purchase, as before the dispute. If pass "REVERT" value then the user's access granted upon purchase will be suspended.*
   * @example "KEEP"
   */
  transactionStatus?: 'KEEP' | 'REVERT' | null
}

/** DisputeResolveRequest */
export interface DisputeResolveRequest {
  /** The resolution comment. */
  resolutionComment?: string | null
  /**
   * Notes: *If pass "KEEP" value then user has the same access that was granted upon purchase, as before the dispute. If pass "REVERT" value then the user's access granted upon purchase will be suspended.*
   * @example "KEEP"
   */
  transactionStatus: 'KEEP' | 'REVERT'
}

/** DisputeResponse */
export interface DisputeResponse {
  comments?: DisputeCommentResponse[] | null
  /** @format date-time */
  createdAt?: string | null
  /** Notes: *Can be empty if the "disputeType" is "AUTOMATIC"* */
  createdBy?: BackOfficeUserResponse | null
  description?: string | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  disputeId: string
  /**
   * Notes: *"MANUAL" if create in BO, "AUTOMATIC" if create by handling webhook.*
   * @example "MANUAL"
   */
  disputeType?: 'AUTOMATIC' | 'MANUAL' | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876a" */
  gameId?: string | null
  /** @example "GAME NAME EXAMPLE" */
  gameName?: string | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  paymentId?: string | null
  /**
   * Notes: *If dispute is resolved the resolution will be "RESOLVED" otherwise "UNRESOLVED". Once dispute is resolved the resolution will not change.*
   * @example "UNRESOLVED"
   */
  resolution?: 'UNRESOLVED' | 'RESOLVED' | null
  /** Comment that was left when the dispute is resolved */
  resolutionComment?: string | null
  /**
   * Notes: *If dispute is resolved displays how it was resolved in "AUTOMATIC" or "MANUAL" way.*
   * @example "NOT_DEFINED"
   */
  resolutionType?: 'NOT_DEFINED' | 'AUTOMATIC' | 'MANUAL' | null
  /**
   * Notes: *Can be empty if the dispute is not resolved*
   * @format date-time
   */
  resolvedAt?: string | null
  /** Notes: *Can be empty if the dispute is not resolved or resolved by handling webhook* */
  resolvedBy?: BackOfficeUserResponse | null
  /**
   * Notes: *Shows when "transactionStatus" set to "REVERT" last time.*
   * @format date-time
   */
  revertedAt?: string | null
  /**
   * Notes: *If equal to "KEEP" then user has the same access that was granted upon purchase, as before the dispute. If equal to "REVERT" then the user's access granted upon purchase will be suspended.*
   * @example "KEEP"
   */
  transactionStatus?: 'KEEP' | 'REVERT' | null
  /** History of "Transaction Status" changes */
  transactionStatusChanges?: DisputeTransactionStatusChangeResponse[] | null
}

/** DisputeTransactionStatusChangeResponse */
export interface DisputeTransactionStatusChangeResponse {
  /** @format date-time */
  changedAt?: string | null
  /** BO user whose made changes */
  changedBy?: BackOfficeUserResponse | null
  newValue?: 'KEEP' | 'REVERT' | null
  oldValue?: 'KEEP' | 'REVERT' | null
}

/** DisputesResponse */
export interface DisputesResponse {
  disputes: DisputeResponse[]
  pagination: PaginationResponse
}

export type DownloadData = any

export type DownloadError =
  | GetOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse
  | GetOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse
  | GetOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse
  | GetOffersOfferIdErrorPagesErrorPageIdContent500ErrorResponse

export type DownloadErrorDataData = any

export type DownloadErrorDataError =
  | GetFeedsFeedIdUsersEmails403ErrorResponse
  | GetFeedsFeedIdUsersEmails404ErrorResponse

/** Duration */
export interface Duration {
  /** @format int32 */
  nano?: number
  negative?: boolean
  positive?: boolean
  /** @format int64 */
  seconds: number
  units?: TemporalUnit[]
  zero?: boolean
}

/** DynamicConfigClientCreateRequest */
export interface DynamicConfigClientCreateRequest {
  /** Client alias. Must be one of configured clients. */
  clientAlias: string
}

/** DynamicConfigClientDetailsResponse */
export interface DynamicConfigClientDetailsResponse {
  /** Status of a client */
  archived?: boolean | null
  /** Alias of related client */
  clientAlias?: string | null
}

/** DynamicConfigClientResponse */
export interface DynamicConfigClientResponse {
  /** Alias of related client */
  clientAlias?: string | null
}

/** DynamicConfigCreateRequest */
export interface DynamicConfigCreateRequest {
  /** Dynamic . Must be one of configured clients. */
  clientAlias: string
  /**
   * Dynamic config condition. If omitted by default set condition that matches no requests.
   * @example "{"expression":{"type":"AND","expressions":[{"parameter":"utm_source","type":"COMPARE_PARAMETERS","operator":"not-present"},{"parameter":"utm_medium","type":"COMPARE_PARAMETERS","operator":"not-present"}]},"type":"NOT"}"
   */
  condition?: MapStringObject | null
  /** The contents of the dynamic config in free text form. */
  content: string
  /**
   * Content-type specified for dynamic config content.
   * @example "text/plain"
   */
  contentType: string
  /**
   * Priority of dynamic config. Should be non-negative and unique among all configs. A configuration with a lower priority value takes precedence over a configuration with a higher priority value. If omitted, the priority is set to the next int value after the highest priority among existing dynamic configurations.
   * @format int32
   */
  priority?: number | null
  /** Title of the config. Only to be displayed in BO. */
  title: string
}

/** DynamicConfigResponse */
export interface DynamicConfigResponse {
  /** Alias of related client */
  clientAlias?: string | null
  /**
   * Dynamic config condition. `Null` value means condition matches no requests.
   * @example "{"expression":{"type":"AND","expressions":[{"parameter":"utm_source","type":"COMPARE_PARAMETERS","operator":"not-present"},{"parameter":"utm_medium","type":"COMPARE_PARAMETERS","operator":"not-present"}]},"type":"NOT"}"
   */
  condition?: MapStringObject | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  configId: string
  /** The contents of the dynamic config in free text form. */
  content?: string | null
  /**
   * Content-type of dynamic config content.
   * @example "text/plain"
   */
  contentType?: string | null
  /**
   * Priority of dynamic config. Non-negative and unique among all configs. A configuration with a lower priority value takes precedence over a configuration with a higher priority value.
   * @format int32
   */
  priority?: number | null
  /** true if this dynamic config is currently published */
  published?: boolean | null
  /** Title of the config */
  title?: string | null
}

/** DynamicConfigUpdateRequest */
export interface DynamicConfigUpdateRequest {
  /**
   * New dynamic config condition. If `null` or omitted, the condition is not changed.
   * @example "{"expression":{"type":"AND","expressions":[{"parameter":"utm_source","type":"COMPARE_PARAMETERS","operator":"not-present"},{"parameter":"utm_medium","type":"COMPARE_PARAMETERS","operator":"not-present"}]},"type":"NOT"}"
   */
  condition?: MapStringObject | null
  /** The contents of the dynamic config in free text form. */
  content?: string | null
  /** New content-type for dynamic config content. */
  contentType?: string | null
  /**
   * Priority of dynamic config. Should be non-negative and unique among all configs. A configuration with a lower priority value takes precedence over a configuration with a higher priority value. If `null` or omitted, the priority is not changed.
   * @format int32
   */
  priority?: number | null
  /** whether to publish/unpublish the dynamic config */
  published?: boolean | null
  /** New title. If present, it must not be blank. */
  title?: string | null
}

/** DynamicConfigsResponse */
export interface DynamicConfigsResponse {
  dynamicConfigs: DynamicConfigResponse[]
  pagination: PaginationResponse
}

/** EditAssetVariantConfigurationRequest */
export interface EditAssetVariantConfigurationRequest {
  /** Description string for what the configuration is used for. */
  description?: string | null
  /** Opaque string passed to the conversion tool to select target format. */
  format?: string | null
  /** Options passed to the conversion tool. */
  options?: MapStringObject | null
}

export type EditChallengeData = ResponseChallengeResponse

export type EditChallengeError =
  | PatchChallengesChallengeId400ErrorResponse
  | PatchChallengesChallengeId403ErrorResponse
  | PatchChallengesChallengeId404ErrorResponse

/** EditChallengeRequest */
export interface EditChallengeRequest {
  /** @example "gg2wom" */
  challengeShortId?: string | null
  /**
   * Timed challenge start time.
   * @format date-time
   * @example "2021-08-23T00:00:00.000Z"
   */
  endsAt?: string | null
  /**
   * New long description.
   * @example "In order to **win this challenge** you need to ++beat the final level++ in as _little_ time as possible!"
   */
  longDescription?: string | null
  /**
   * New challenge name.
   * @example "clear the game as fast as possible"
   */
  name?: string | null
  /**
   * New short description.
   * @example "Beat the final level in as little time as possible."
   */
  shortDescription?: string | null
  /**
   * *Deprecated in favor of `challengeShortId`*
   * @deprecated
   * @example "gg2wom"
   */
  shortId?: string | null
  /**
   * Timed challenge start time.
   * @format date-time
   * @example "2021-08-22T00:00:00.000Z"
   */
  startsAt?: string | null
  /**
   * If `true`, make challenge time. If `false`, make challenge untimed.
   *
   * When `true`, the `startsAt` and `endsAt` fields must contain valid time values.
   */
  timedChallenge?: boolean | null
}

export type EditClaimPeriodData = ResponseGameItemClaimPeriodResponse

export type EditClaimPeriodError =
  | PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse
  | PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse
  | PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse

export type EditConfigurationData = ResponseAssetVariantConfigurationResponse

export type EditConfigurationError =
  | PatchAssetVariantsConfigurationsConfigurationId400ErrorResponse
  | PatchAssetVariantsConfigurationsConfigurationId403ErrorResponse
  | PatchAssetVariantsConfigurationsConfigurationId404ErrorResponse

export type EditData = ResponseGameWrapperResponse

export type EditError =
  | PatchGameWrappersGameWrapperId400ErrorResponse
  | PatchGameWrappersGameWrapperId403ErrorResponse
  | PatchGameWrappersGameWrapperId404ErrorResponse

export type EditFail =
  | PatchWrappersWrapperId400ErrorResponse
  | PatchWrappersWrapperId403ErrorResponse
  | PatchWrappersWrapperId404ErrorResponse

export type EditGameData = ResponseGameResponse

export type EditGameError =
  | PatchGamesGameId400ErrorResponse
  | PatchGamesGameId403ErrorResponse
  | PatchGamesGameId404ErrorResponse

/** EditGameReleaseTrackRequest */
export interface EditGameReleaseTrackRequest {
  /**
   * If missing, the gameWrapperId is not changed. If null, the gameWrapperId is cleared.Otherwise, a valid game game wrapper id will be required to replace the existing one.
   * @example "42f69826-610a-431c-aa15-d60429121054"
   */
  gameWrapperId?: string | null
  /**
   * If not null, publish or unpublish the game track.
   * @example true
   */
  published?: boolean | null
  /** If not null, enable or disable use of game wrapper. When disabled, the game's own `index.html` is used. */
  useGameWrapper?: boolean | null
}

/** EditGameWrapperRequest */
export interface EditGameWrapperRequest {
  active?: boolean | null
  default?: boolean | null
  name?: string | null
}

export type EditQuestCollectionData = ResponseQuestCollectionResponse

export type EditQuestCollectionError =
  | PatchQuestCollectionsCollectionId400ErrorResponse
  | PatchQuestCollectionsCollectionId403ErrorResponse
  | PatchQuestCollectionsCollectionId404ErrorResponse

export type EditQuestData = ResponseQuestResponse

export type EditQuestError =
  | PatchQuestsQuestId400ErrorResponse
  | PatchQuestsQuestId403ErrorResponse
  | PatchQuestsQuestId404ErrorResponse

export type EditResult = ResponseWrapperResponse

export type EditSeasonData = ResponseSeasonDetailsResponse

export type EditSeasonError =
  | PatchSeasonsSeasonId400ErrorResponse
  | PatchSeasonsSeasonId403ErrorResponse
  | PatchSeasonsSeasonId404ErrorResponse

/** EditSeasonRequest */
export interface EditSeasonRequest {
  /**
   * Season end time
   * @format date-time
   * @example "2022-03-31T23:59:59.000+01:00"
   */
  endsAt?: string | null
  /**
   * Unique season identifier
   * @example "March 2022"
   */
  identifier?: string | null
  /** True to publish season; false to unpublish season */
  published?: boolean | null
  /**
   * Season start time
   * @format date-time
   * @example "2022-03-01T00:00:00.000+01:00"
   */
  startsAt?: string | null
  studioMetricsSettings?: StudioMetricSettingsRequest[] | null
}

export type EditSubscriberInfoData = ResponseFundingSubscriberResponse

export type EditSubscriberInfoError =
  | PatchFundingWaitListSubscriberId400ErrorResponse
  | PatchFundingWaitListSubscriberId403ErrorResponse
  | PatchFundingWaitListSubscriberId404ErrorResponse

export type EditTrackData = ResponseGameReleaseTrackResponse

export type EditTrackError =
  | PatchGamesGameIdTracksTrackId400ErrorResponse
  | PatchGamesGameIdTracksTrackId403ErrorResponse
  | PatchGamesGameIdTracksTrackId404ErrorResponse

export type EditUserSettingsData = ResponseUserResponse

export type EditUserSettingsError =
  | PatchUsersUserIdSettings400ErrorResponse
  | PatchUsersUserIdSettings403ErrorResponse
  | PatchUsersUserIdSettings404ErrorResponse

/** EditWrapperRequest */
export interface EditWrapperRequest {
  /** Wrapper state. */
  active?: boolean | null
  /** Default wrapper or not. */
  default?: boolean | null
  /** New wrapper name. */
  name?: string | null
}

export type ElevateBackOfficeUserData = ResponseVoid

export type ElevateBackOfficeUserError = PostAdministrationUsersElevate403ErrorResponse

export interface ElevateBackOfficeUserParams {
  email: string
}

/** BO emailing topic response */
export interface EmailTopicResponse {
  alias?: string | null
  description?: string | null
}

/** BO emailing topics paginated response */
export interface EmailTopicsResponse {
  pagination: PaginationResponse
  topics: EmailTopicResponse[]
}

/** BO Emailing topic for which consent was or wasn't given. */
export interface EmailingConsentResponse {
  /**
   * Alias of the emailing topic
   * @example "default"
   */
  alias?: string | null
  /**
   * `true` if user has given consent
   * @example true
   */
  consentGiven?: boolean | null
}

export type EnlistData = ResponseFundingSubscriberResponse

export type EnlistError = PostFundingWaitList400ErrorResponse | PostFundingWaitList403ErrorResponse

/** ErrorResponse */
export interface ErrorResponse {
  code: string
  details?: ErrorResponseDetails | null
}

/** ErrorResponse.Details */
export interface ErrorResponseDetails {
  fieldName?: string
  message?: string
  parameterName?: string
  settingId?: string
  settingKey?: SettingKey
}

/** EventsRequestedResponse */
export interface EventsRequestedResponse {
  /**
   * Number of events requested by this call
   * @format int64
   */
  eventsRequested?: number | null
  /**
   * Total number of events requested and not yet sent
   * @format int64
   */
  totalPendingRequestedEvents?: number | null
}

/** EventsSentResponse */
export interface EventsSentResponse {
  events: EventsSentResponseEvent[]
  pagination: PaginationResponse
}

/** EventsSentResponse.Event */
export interface EventsSentResponseEvent {
  entityId?: string
  entityType?:
    | 'CHALLENGE'
    | 'CMS_CONFIGURATION'
    | 'CMS_SECTION'
    | 'GAME'
    | 'MANAGED_FEED'
    | 'MOD'
    | 'MOD_RELEASE'
    | 'MOD_RELEASE_TRACK'
    | 'STUDIO'
    | 'USER'
}

/** ExpenseResponse */
export interface ExpenseResponse {
  /** @example 22.16 */
  amount?: number | null
  /**
   * Notes: *Three-letter currency code per ISO 4217.*
   * @example "EUR"
   */
  currency?: string | null
  /**
   * Notes: *After applying new structure for purchases this field always will be null.*
   *
   * *Deprecated since expenses are no longer separate entities*
   * @deprecated
   * @example "e2badb25-44ba-41a1-931d-b1f22745876e"
   */
  expenseId: string
}

/** ExpenseWithPercentResponse */
export interface ExpenseWithPercentResponse {
  /** @example 22.16 */
  amount?: number | null
  /**
   * Notes: *Three-letter currency code per ISO 4217.*
   * @example "EUR"
   */
  currency?: string | null
  /**
   * Notes: *After applying new structure for purchases this field always will be null.*
   *
   * *Deprecated since expenses are no longer separate entities*
   * @deprecated
   * @example "e2badb25-44ba-41a1-931d-b1f22745876e"
   */
  expenseId: string
  /**
   * @format double
   * @example 1.11
   */
  percent?: number | null
}

/** ExtensionResponse */
export interface ExtensionResponse {
  extensionId: string
}

/** Paginated list of managed feed entries */
export interface FeedEntriesResponse {
  /** @example "some-feed-thing" */
  alias?: string | null
  descriptionString?: StringResponse | null
  entries: FeedEntryDetailsResponse[]
  feedId: string
  /**
   * Feed entity version
   *
   * Notes: *Will be an empty string if the feed's version has not been initialized.*
   * @example "1"
   */
  feedVersion?: string | null
  /**
   * Alias of the managed feed.
   *
   * *Deprecated in favor of `alias`*
   * @deprecated
   * @example "some-feed-thing"
   */
  name?: string | null
  pagination: PaginationResponse
  /** Pending changes details. Null if there are no pending changes for the feed entries. */
  pendingChanges?: PendingChangesEntriesResponse | null
  titleString?: StringResponse | null
}

/** FeedEntryAttributeAssetResponse */
export interface FeedEntryAttributeAssetResponse {
  assetId: string
  contentType?: string | null
  eTag?: string | null
  etag?: string | null
  /** @format date-time */
  uploadedAt?: string | null
  url?: string | null
}

/** FeedEntryAttributeResponse */
export interface FeedEntryAttributeResponse {
  /** Attribute value if `type` is `"ANY"` and `null` otherwise */
  anyValue?: object | null
  /** Attribute asset details if `type` is `"ASSET"` and `null` otherwise */
  asset?: FeedEntryAttributeAssetResponse | null
  /** Attribute asset variants profile key details or `null` for `type` `"ASSET"` and only `null` otherwise */
  assetVariantsProfileKey?:
    | 'AUDIO_MOD_CONTENT'
    | 'BIO_LINK_IMAGE'
    | 'CUSTOM'
    | 'GAME_BANNER'
    | 'GAME_BANNER_GIF'
    | 'IMAGE_CHALLENGE_COVER_16_9'
    | 'IMAGE_CHALLENGE_COVER_4_3'
    | 'IMAGE_EXTENSION_COVER_16_9'
    | 'IMAGE_EXTENSION_COVER_16_9_GIF'
    | 'IMAGE_EXTENSION_GRAPHIC'
    | 'IMAGE_EXTENSION_GRAPHIC_GIF'
    | 'IMAGE_GAME_COVER_16_9'
    | 'IMAGE_GAME_COVER_16_9_GIF'
    | 'IMAGE_GAME_COVER_4_3'
    | 'IMAGE_GAME_COVER_4_3_GIF'
    | 'IMAGE_GAME_GRAPHIC'
    | 'IMAGE_GAME_GRAPHIC_GIF'
    | 'IMAGE_MOD_COVER_16_9'
    | 'IMAGE_MOD_COVER_16_9_GIF'
    | 'IMAGE_MOD_GRAPHIC'
    | 'IMAGE_MOD_GRAPHIC_GIF'
    | 'IMAGE_MOD_MOBILE_WALLPAPER'
    | 'IMAGE_MOD_WALLPAPER'
    | 'IMAGE_QUEST_COVER'
    | 'IMAGE_QUEST_COVER_GIF'
    | 'IMAGE_STICKER_COVER'
    | 'IMAGE_STUDIO_COVER_4_1'
    | 'USER_AVATAR'
    | 'VIDEO_EXTENSION_COVER_16_9'
    | 'VIDEO_EXTENSION_GRAPHIC'
    | 'VIDEO_GAME_COVER_16_9'
    | 'VIDEO_GAME_COVER_4_3'
    | 'VIDEO_GAME_GRAPHIC'
    | 'VIDEO_MOD_COVER_16_9'
    | 'VIDEO_MOD_GRAPHIC'
    | 'VIDEO_MOD_WALLPAPER'
    | null
  /**
   * Attribute id
   * @example "7536302b-423d-45a3-9b11-7d687a473d69"
   */
  attributeId: string
  /** Attribute string details if `type` is `"LOCALIZABLE_STRING"` and `null` otherwise */
  localizableString?: StringResponse | null
  /** Attribute name */
  name?: string | null
  /** Attribute type */
  type?: 'ANY' | 'ASSET' | 'LOCALIZABLE_STRING' | null
}

/** Detailed feed entry */
export interface FeedEntryDetailsResponse {
  attributes?: FeedEntryAttributeResponse[] | null
  challenge?: ChallengeResponse | null
  /** Custom content item type, or `null` for other types of entries. */
  customContentItemType?: string | null
  /**
   * When the entry was first added to the feed.
   * @format date-time
   */
  dateAdded?: string | null
  /**
   * ID of the feed entry: used for reordering/deleting entries.
   * @example "b45a8eb6-df58-4551-b30e-2a1c56d9077f"
   */
  entryId: string
  game?: GameResponse | null
  mod?: ModSummaryResponse | null
  tag?: TagTitleResponse | null
  user?: UserSummaryResponse | null
  /**
   * Number of votes this entry has received.
   * @format int32
   */
  votes?: number | null
}

/** FeedUpdateAlertResponse */
export interface FeedUpdateAlertResponse {
  entryId?: string | null
  entryName?: string | null
  entryType?: 'CHALLENGE' | 'EXTENSION' | 'GAME' | 'MOD' | null
  updateType?: 'DELETED' | 'UNPUBLISHED' | null
}

/** FilteredFeedDetailsResponse */
export interface FilteredFeedDetailsResponse {
  /**
   * Game filtering expression
   *
   * Notes: *https://github.com/opera-gaming/gaming-backend/wiki/Feature:-Managed-feeds#filtering_expression*
   * @example "{ "type": "HAS_TAG", "tagId": "a93a8eb6-df58-4551-b30e-2a1c56d9071d" }"
   */
  gameFilteringExpression?: MapStringObject | null
  /**
   * *Deprecated in favor of `sort.gameSort` in parent response*
   * @deprecated
   */
  gameSort?: string[] | null
  /**
   * Mod filtering expression
   *
   * Notes: *https://github.com/opera-gaming/gaming-backend/wiki/Feature:-Managed-feeds#filtering_expression*
   * @example "{ "type": "HAS_TAG", "tagId": "a93a8eb6-df58-4551-b30e-2a1c56d9071d" }"
   */
  modFilteringExpression?: MapStringObject | null
  snapshotConfiguration?: SnapshotConfigurationResponse | null
  snapshotRefreshSchedule?: SnapshotRefreshScheduleResponse | null
  /**
   * *Deprecated in favor of `timeFrame` in parent response*
   * @deprecated
   */
  timeFrame?: ManagedFeedTimeFrameResponse | null
}

/** BO enlist request for funding list. */
export interface FundingEnlistRequest {
  /**
   * Email address to be enlisted.
   * @example "moe.barracuda@mail.com"
   */
  email: string
}

/** BO funding subscriber response */
export interface FundingSubscriberResponse {
  /** Email address for the list entry. */
  email?: string | null
  /**
   * Date of registration.
   * @format date-time
   */
  enlistingDate?: string | null
  /** ID of the list entry. */
  subscriberId: string
  /**
   * Date of survey completion.
   * @format date-time
   */
  surveyCompleted?: string | null
  /**
   * Date of survey start.
   * @format date-time
   */
  surveyStarted?: string | null
  /** User information: will be `null` for guest entries. */
  user?: UserSummaryResponse | null
}

/** BO update entry request for funding list. */
export interface FundingSubscriberUpdateRequest {
  /**
   * Date survey completed.
   * @format date-time
   */
  surveyCompleted?: string | null
  /**
   * Date survey started.
   * @format date-time
   */
  surveyStarted?: string | null
}

/** BO funding subscribers paginated response */
export interface FundingSubscribersResponse {
  entries: FundingSubscriberResponse[]
  pagination: PaginationResponse
}

/** GameBannerResponse */
export interface GameBannerResponse {
  /**
   * Alias for banner
   * @example "topBanner"
   */
  alias?: string | null
  /** @example "f26d9a15-4f65-4412-8590-5a69b46009d1" */
  assetId?: string | null
  /** @example "q123dszx-4f65-4551-8590-5a69b46009d1" */
  bannerId: string
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  gameId: string
  /**
   * Game entity version
   *
   * Notes: *Will be an empty string if the game's version has not been initialized.*
   * @example "1"
   */
  gameVersion?: string | null
  /** @example "https://example.com/123456.png" */
  url?: string | null
}

/** GameBannersResponse */
export interface GameBannersResponse {
  banners: GameBannerResponse[]
  pagination: PaginationResponse
}

/** GameChallengeResponse */
export interface GameChallengeResponse {
  challenge?: ChallengeResponse | null
  game?: GameResponse | null
}

/** GameChallengesResponse */
export interface GameChallengesResponse {
  challenges: ChallengeResponse[]
  game?: GameResponse | null
  pagination: PaginationResponse
}

/** GameControlGroupResponse */
export interface GameControlGroupResponse {
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  id?: string | null
  /** List of game control subgroups that belong to the group */
  subgroups?: GameControlSubgroupResponse[] | null
  /** Game control group title as a localizable string */
  title?: StringResponse | null
}

/** GameControlResponse */
export interface GameControlResponse {
  /** Game control action as a localizable string */
  action?: StringResponse | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  id?: string | null
  /** Game control input as a localizable string */
  input?: StringResponse | null
}

/** GameControlSubgroupResponse */
export interface GameControlSubgroupResponse {
  /** List of game controls that belong to the subgroup */
  controls?: GameControlResponse[] | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  id?: string | null
  /** Game control group title as a localizable string */
  title?: StringResponse | null
}

/** GameEngineDetailsResponse */
export interface GameEngineDetailsResponse {
  /** @example "surreal-engine" */
  alias?: string | null
  default?: boolean | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  gameEngineId: string
  title?: string | null
  titleString?: StringResponse | null
  url?: string | null
  urlString?: StringResponse | null
  visible?: boolean | null
}

/** GameEngineResponse */
export interface GameEngineResponse {
  /** @example "surreal-engine" */
  alias?: string | null
  default?: boolean | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  gameEngineId: string
  title?: string | null
  url?: string | null
  visible?: boolean | null
}

/** GameEnginesResponse */
export interface GameEnginesResponse {
  engines: GameEngineResponse[]
  pagination: PaginationResponse
}

/** GameItemClaimPeriodRequest */
export interface GameItemClaimPeriodRequest {
  /**
   * @format date-time
   * @example "2021-06-10T13:45:09.900Z"
   */
  endsAt?: string | null
  /**
   * @format date-time
   * @example "2021-06-10T13:45:09.900Z"
   */
  startsAt?: string | null
}

/** GameItemClaimPeriodResponse */
export interface GameItemClaimPeriodResponse {
  /**
   * @format date-time
   * @example "2021-09-22T08:52:21.473Z"
   */
  endsAt?: string | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  itemId: string
  /**
   * The number of times the game was claimed during this claim period.
   * @format int32
   */
  numberOfClaims?: number | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  periodId: string
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  startsAt?: string | null
}

/** GameItemClaimPeriodsResponse */
export interface GameItemClaimPeriodsResponse {
  gameItemClaimPeriods: GameItemClaimPeriodResponse[]
  pagination: PaginationResponse
}

/** GameItemRequest */
export interface GameItemRequest {
  /** @example "EUR" */
  currency: string
  /** @example "Buy the full game! Only 599 US$, what are you? Poor?" */
  description: string
  /** Notes: *The price is indicated in the currency specified in `currency` parameter* */
  price: number
  /** @example "FULL_VERSION" */
  type: 'FULL_VERSION' | 'PAID_GAME'
}

/** GameItemResponse */
export interface GameItemResponse {
  /** @example "EUR" */
  currency?: string | null
  /** Indicates if a game item was deleted. */
  deleted?: boolean | null
  /** Description in default locale */
  description?: string | null
  discount?: DiscountResponse | null
  /**
   * Discounted price to be used instead of the standard price, or `null` if no discounted price is set for this item
   *
   * Notes: *The discounted price is a final value, i.e. not a percentage to be deducted from the standard price (like 10%, 20% etc), must be smaller than the standard price and is indicated in the currency specified in `currency` parameter.*
   * @example 15
   */
  discountedPrice?: number | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  gameId: string
  /** List of claim periods for the game item. */
  gameItemClaimPeriods?: GameItemClaimPeriodResponse[] | null
  /**
   * Game entity version
   *
   * Notes: *Will be an empty string if the game's version has not been initialized.*
   * @example "1"
   */
  gameVersion?: string | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  itemId: string
  /** The price is indicated in the currency specified in `currency` parameter */
  price?: number | null
  /**
   * Will be `null` for game items that have always been free. A free item may have an Payment Provider sku if it used to be paid.
   * @example "e2badb25-44ba-41a1-931d-b1f22745876e_ITEM_a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  sku?: string | null
  /** @example "FULL_VERSION" */
  type?: 'FULL_VERSION' | 'PAID_GAME' | null
  /**
   * Will be `null` for game items that have always been free. A free item may have an Xsolla sku if it used to be paid.
   *
   * *Deprecated in favor of `sku`*
   * @deprecated
   * @example "e2badb25-44ba-41a1-931d-b1f22745876e_ITEM_a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  xsollaSku?: string | null
}

/** GameItemUpdateRequest */
export interface GameItemUpdateRequest {
  /** @example "EUR" */
  currency?: string | null
  /** @example "Buy the full game! Only 599 US$, what are you? Poor?" */
  description?: string | null
  /**
   * Discounted price to be used instead of the standard price. If set to `null`, the current discounted price is deleted.
   *
   * Notes: *The discounted price is a final value, i.e. not a percentage to be deducted from the standard price (like 10%, 20% etc), must be smaller than the standard price and is indicated in the currency specified in `currency` parameter.*
   * @example 15
   */
  discountedPrice?: number | null
  /**
   * Datetime when discount price stops being effective.
   * @format date-time
   */
  discountedPriceEndsAt?: string | null
  /**
   * Datetime when discount price starts being effective.
   * @format date-time
   */
  discountedPriceStartsAt?: string | null
  /** Notes: *The price is indicated in the currency specified in `currency` parameter* */
  price?: number | null
  /** @example "FULL_VERSION" */
  type?: 'FULL_VERSION' | 'PAID_GAME' | null
}

/** GameItemsResponse */
export interface GameItemsResponse {
  gameItems: GameItemResponse[]
  pagination: PaginationResponse
}

/** GamePlayStatisticsResponse */
export interface GamePlayStatisticsResponse {
  /** @format date */
  endDate?: string | null
  /** @format date */
  startDate?: string | null
  /**
   * Number of times a game has been played.
   *
   * Notes: *May include game plays for which no play duration was recorded.*
   * @format int64
   */
  totalPlays?: number | null
  /**
   * Number of game plays with a recorded play duration.
   *
   * Notes: *Although in theory equivalent, may be less than `totalPlays` due to incomplete historical data.*
   * @format int64
   */
  totalSessions?: number | null
  /**
   * Total time played for game plays with a recorded duration, in seconds.
   * @format int64
   */
  totalTime?: number | null
  /**
   * Number of unique players that have played a game.
   * @format int64
   */
  uniquePlayers?: number | null
}

/** BO detailed game purchase response */
export interface GamePurchaseDetailedResponse {
  /** `Active` means the game is available in the user's library and he/she can play it. */
  active?: boolean | null
  /**
   * Notes: *The total cost of a cart that considers a chosen currency.*
   * @example "10"
   */
  amount?: string | null
  /**
   * Notes: *User’s commentary to the order.*
   * @example "First order"
   */
  comment?: string | null
  /**
   * Notes: *Three-letter currency code per ISO 4217.*
   * @example "EUR"
   */
  currency?: string | null
  /**
   * Notes: *`real` for real currency.*
   * @example "real"
   */
  currencyType?: string | null
  /**
   * Notes: *Time of the purchase*
   * @format date-time
   * @example "2022-01-13T05:22:33.536Z"
   */
  date?: string | null
  disputes?: DisputeResponse[] | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  gameId: string
  gameItem?: GameItemResponse | null
  /** @example "Best game ever" */
  gameName?: string | null
  /** If game `inactive` display the reason why it is inactive. For active game the reasons will be `not lost` */
  lossReason?: 'GAME_DELETION' | 'NOT_LOST' | 'REFUND' | 'SUSPEND' | 'UNPUBLISHED' | null
  /**
   * Notes: *Payment type. It can have the following values: default or sandbox.*
   * @example "sandbox"
   */
  mode?: string | null
  paymentId?: string | null
  /**
   * Notes: *Payment provider of the purchase.*
   * @example "XSOLLA"
   */
  paymentProvider?: 'STRIPE' | 'XSOLLA' | null
  /**
   * Notes: *Payment platform.*
   *
   * *Deprecated in favor of `paymentProvider`*
   * @deprecated
   * @example "xsolla"
   */
  platform?: string | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  purchaseId: string
  /**
   * Notes: *Purchase Provider Id.*
   * @example "pm_1O6AqQF83XuePUCKmkrJRyCA"
   */
  purchaseProviderId?: string | null
  /** Type of purchase: can be either normal or via the claiming feature. */
  purchaseType?: 'CLAIM' | 'FREE' | 'PURCHASE' | 'TIPPING' | null
  refunds?: RefundResponse[] | null
  respectPayment?: RespectPaymentSummaryResponse | null
  /**
   * Notes: *Order status.*
   * @example "paid"
   */
  status?: string | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  studioId?: string | null
  /** @example "Opera gaming studio no 1" */
  studioName?: string | null
  /**
   * Notes: *Transaction ID on payment system.*
   * @example "1070582715"
   */
  transactionId?: string | null
  user?: UserSummaryResponse | null
}

/** BO paginated list of detailed game purchase responses */
export interface GamePurchasesDetailedResponse {
  pagination: PaginationResponse
  purchases: GamePurchaseDetailedResponse[]
}

/** GameReleaseDateUpdateResponse */
export interface GameReleaseDateUpdateResponse {
  /**
   * Notes: *Shows for how much games `release date` updated from `game_releases.uploaded_at`. We updated only games published before '2021-12-01 10:26:55'. It's time where game changes functionality was added on prod.*
   * @format int32
   */
  updateReleaseDataFromUploadedAt?: number | null
  /**
   * Notes: *Shows for how much games `release date` updated from game changes where game change item equals `PUBLIC_TRACK_PUBLISHED`.*
   * @format int32
   */
  updatedReleaseDataFromGameChanges?: number | null
}

/** GameReleaseResponse */
export interface GameReleaseResponse {
  author?: UserSummaryResponse | null
  /** @example "89fb9376-6a15-4cf3-80fc-066f945e81d9" */
  releaseId: string
  /**
   * @format date-time
   * @example "2021-07-22T10:57:17.031Z"
   */
  uploadedAt?: string | null
  /** @example "12.34.56.789" */
  version?: string | null
}

/** GameReleaseTrackResponse */
export interface GameReleaseTrackResponse {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  fromGameReleaseTrack?: string | null
  gameWrapper?: GameWrapperResponse | null
  /** @example false */
  published?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  releaseTrackId: string
  /**
   * Allowed values: `public`, `internal`
   * @example "public"
   */
  type?: 'public' | 'internal' | null
  /**
   * If true, game is loaded using custom game wrapper, otherwise using `index.html` from game bundle.
   * @example true
   */
  useGameWrapper?: boolean | null
  /** @example true */
  useWrapper?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  wrapperId?: string | null
}

/** GameReleaseTracksPageableResponse */
export interface GameReleaseTracksPageableResponse {
  pagination: PaginationResponse
  tracks: GameReleaseTrackResponse[]
}

/** GameReleasesResponse */
export interface GameReleasesResponse {
  game?: GameResponse | null
  pagination: PaginationResponse
  releases: GameReleaseResponse[]
  track?: GameReleaseTrackResponse | null
}

/** GameResponse */
export interface GameResponse {
  /** @example "EARLY_TEENS" */
  ageRating?:
    | 'NOT_SET'
    | 'EVERYONE'
    | 'CHILDREN'
    | 'EARLY_TEENS'
    | 'TEENS'
    | 'ADULTS'
    | 'MATURE'
    | null
  /** True if cloud saves are actually enabled for this game, either due to a per-game override or due to the default setting. */
  allowSaves?: boolean | null
  /** True if cloud saves are enabled by default. */
  allowSavesDefault?: boolean | null
  banners?: GameBannerResponse[] | null
  /**
   * *Deprecated since best-of category feature has been dropped*
   * @deprecated
   * @example "["BEST_ACTION", "BEST_PUZZLE", "MOST_CHALLENGING"]"
   */
  bestOfCategories?: object[] | null
  blockAction?: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION' | null
  blocked?: boolean | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  createdAt?: string | null
  /** True if this game has been deleted, in which case all fields except `gameId` and `title` will be null, false or empty arrays. */
  deleted?: boolean | null
  gameEngine?: GameEngineResponse | null
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  gameId: string
  gameItems?: GameItemResponse[] | null
  /** @example "34fer5" */
  gameShortId?: string | null
  /**
   * Game entity version
   *
   * Notes: *Will be an empty string if the game's version has not been initialized.*
   * @example "1"
   */
  gameVersion?: string | null
  /** @format date-time */
  lastReportedAt?: string | null
  /** @example "A longer description, **now with markdown!**" */
  longDescription?: string | null
  /** @example "henshin-v3-" */
  mangledTitle?: string | null
  media?: MediaResponse[] | null
  /** @example "["DESKTOP", "MOBILE"]" */
  platforms?: ('DESKTOP' | 'MOBILE')[] | null
  publisher?: PublisherResponse | null
  /**
   * Limit in KB to the save revision size for the game.
   * @format int64
   * @example 300
   */
  saveRevisionSizeLimit?: number | null
  settings?: GameSettingResponse[] | null
  /** @example "A short description" */
  shortDescription?: string | null
  /** Game developer revenue share %. It overrides any values set for the studio. */
  stripeGameDeveloperRevenueSharePercentage?: string | null
  studio?: StudioResponse | null
  tags?: TagSummaryResponse[] | null
  tippingSticker?: TippingStickerResponse | null
  /** @example "Henshin... V3!" */
  title?: string | null
  tracks?: GameReleaseTrackResponse[] | null
  unresolvedReports?: ReportsByReasonResponse | null
  usesDefaultEngine?: boolean | null
}

/** GameReviewGamesResponse */
export interface GameReviewGamesResponse {
  games: GameResponse[]
  pagination: PaginationResponse
}

/** GameReviewRequestsResponse */
export interface GameReviewRequestsResponse {
  gameId: string
  gameShortId?: string | null
  gameVersion?: string | null
  pagination: PaginationResponse
  reviewRequests: ReviewRequestResponse[]
}

/** GameReviewReviewsResponse */
export interface GameReviewReviewsResponse {
  fromReviews?: PageReview | null
  gameId: string
  gameShortId?: string | null
  gameVersion?: string | null
  pagination: PaginationResponse
  reviews: ReviewResponse[]
}

/** BO game settings response */
export interface GameSettingResponse {
  setting?:
    | 'ALLOW_FULL_VERSION_PURCHASE'
    | 'ALLOW_LEGACY_SAVES'
    | 'ALLOW_PAID_GAME'
    | 'ALLOW_PRELOADING'
    | 'ALLOW_SAVES'
    | 'DEFAULT_MP_ROOM_SIZE'
    | 'INTERPOLATE_COLORS'
    | 'LATE_JOIN'
    | 'MAX_ALLOWED_RTT_MS'
    | 'MULTIPLAYER'
    | 'PAYMENT_PROVIDER'
    | 'SAVE_REVISION_SIZE_LIMIT_KB'
    | 'USES_SHARED_ARRAY_BUFFER_INTERNAL_TRACK'
    | 'USES_SHARED_ARRAY_BUFFER_PUBLIC_TRACK'
    | null
  type?: 'STRING' | 'INTEGER' | 'BOOLEAN' | 'GRAPHIC' | null
  /**
   * Notes: *This is always returned as a string: refer to `type` for the intended type.*
   * @example "true"
   */
  value?: string | null
}

/** GameShortIdsGenerationResponse */
export interface GameShortIdsGenerationResponse {
  /** @format int64 */
  gameShortIdsGenerated?: number | null
  /** @format int64 */
  gameShortIdsGenerationErrors?: number | null
}

/** GameStatisticsDataPointResponse */
export interface GameStatisticsDataPointResponse {
  /** Data point data series value. Null if value present */
  dataSeriesValue?: GameStatisticsDataSeriesResponse[] | null
  /** @format date */
  periodDate?: string | null
  /**
   * Data point value. Null if dataSeriesValue present
   * @format double
   */
  value?: number | null
}

/** GameStatisticsDataPointsResponse */
export interface GameStatisticsDataPointsResponse {
  dataPoints?: GameStatisticsDataPointResponse[] | null
  gameId: string
  metric?:
    | 'AVERAGE_PLAY_DURATION'
    | 'NUMBER_OF_NEW_PLAYERS'
    | 'NUMBER_OF_PLAYS'
    | 'NUMBER_OF_USERS_FAVORITED'
    | 'PERCENT_OF_RETURNING_PLAYERS'
    | 'PERCENT_OF_SIGNED_IN_USERS'
    | 'PLAYER_COUNTRY_PERCENTAGE'
    | null
  /** True if this response contains partial data. If so, repeat the same request later to receive (potentially) complete data. */
  partial?: boolean | null
  periodType?: 'DAILY' | 'MONTHLY' | 'WEEKLY' | null
  releaseTrackId?: string | null
  /** @format int32 */
  timeZoneOffset?: number | null
}

/** GameStatisticsDataSeriesResponse */
export interface GameStatisticsDataSeriesResponse {
  /** String used to labeling data */
  label?: string | null
  /**
   * Numeric value of labeled data
   * @format double
   */
  value?: number | null
}

/** GameTransferResponse */
export interface GameTransferResponse {
  /** @format date-time */
  createdAt?: string | null
  game?: GameResponse | null
  originalStudio?: StudioResponse | null
  requester?: UserSummaryResponse | null
  /** @format date-time */
  resolvedAt?: string | null
  reviewer?: BackOfficeUserResponse | null
  reviewerComment?: string | null
  status?: 'ABORTED' | 'APPROVED' | 'EXPIRED' | 'PENDING' | 'REJECTED' | null
  targetStudio?: StudioResponse | null
  targetStudioOwnerWhenRequested?: UserSummaryResponse | null
  transferId: string
}

/** GameTransfersResponse */
export interface GameTransfersResponse {
  gameTransfers: GameTransferResponse[]
  pagination: PaginationResponse
}

/** GameWrapperResponse */
export interface GameWrapperResponse {
  active?: boolean | null
  bucketIdentifier?: 'GX' | 'BONUS' | 'STORE' | null
  default?: boolean | null
  /** @format date-time */
  defaultSince?: string | null
  /** @format date-time */
  deletedAt?: string | null
  deletedBy?: BackOfficeUserResponse | null
  gameEngine?: GameEngineResponse | null
  gameWrapperId: string
  name?: string | null
  /** @format date-time */
  uploadedAt?: string | null
  uploadedBy?: BackOfficeUserResponse | null
}

/** GameWrappersResponse */
export interface GameWrappersResponse {
  gameWrappers: GameWrapperResponse[]
  pagination: PaginationResponse
}

/** GamesResponse */
export interface GamesResponse {
  games: GameResponse[]
  pagination: PaginationResponse
}

export type GenerateChallengeShortIDsData = ResponseChallengeShortIDsGenerationResponse

export type GenerateChallengeShortIDsError = PatchChallengesShortIds403ErrorResponse

export type GenerateGameShortIdsData = ResponseGameShortIdsGenerationResponse

export type GenerateGameShortIdsError = PatchGamesShortIds403ErrorResponse

export type GenerateNewGameShortIdData = ResponseString

export type GenerateNewGameShortIdError = GetChallengesGenerateShortId403ErrorResponse

export type GenerateNewGameShortIdFail = GetGamesGenerateShortId403ErrorResponse

export type GenerateNewGameShortIdResult = ResponseString

export type GetAccountDetailsData = ResponseBankAccountResponse

export type GetAccountDetailsError =
  | GetBankAccountsAccountId403ErrorResponse
  | GetBankAccountsAccountId404ErrorResponse

/** GetAdministrationUsers400Error */
export interface GetAdministrationUsers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetAdministrationUsers400ErrorResponse */
export interface GetAdministrationUsers400ErrorResponse {
  data: null
  errors: GetAdministrationUsers400Error[]
}

/** GetAdministrationUsers403Error */
export interface GetAdministrationUsers403Error {
  code: 'sign_in_required'
}

/** GetAdministrationUsers403ErrorResponse */
export interface GetAdministrationUsers403ErrorResponse {
  data: null
  errors: GetAdministrationUsers403Error[]
}

/** GetAlerts400Error */
export interface GetAlerts400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetAlerts400ErrorResponse */
export interface GetAlerts400ErrorResponse {
  data: null
  errors: GetAlerts400Error[]
}

/** GetAlerts403Error */
export interface GetAlerts403Error {
  code: 'sign_in_required'
}

/** GetAlerts403ErrorResponse */
export interface GetAlerts403ErrorResponse {
  data: null
  errors: GetAlerts403Error[]
}

export type GetAlertsData = ResponseSystemAlertsResponse

export type GetAlertsError = GetAlerts400ErrorResponse | GetAlerts403ErrorResponse

export interface GetAlertsParams {
  /**
   * The page to return. The response will contain backoffice alerts in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of backoffice alerts to include in the response. Fewer backoffice alerts may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  status?: 'NEW' | 'RESOLVED'
}

export type GetApiClientData = ResponseApiClientResponse

export type GetApiClientError =
  | GetApiclientsClientId403ErrorResponse
  | GetApiclientsClientId404ErrorResponse

/** GetApiclients400Error */
export interface GetApiclients400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetApiclients400ErrorResponse */
export interface GetApiclients400ErrorResponse {
  data: null
  errors: GetApiclients400Error[]
}

/** GetApiclients403Error */
export interface GetApiclients403Error {
  code: 'sign_in_required'
}

/** GetApiclients403ErrorResponse */
export interface GetApiclients403ErrorResponse {
  data: null
  errors: GetApiclients403Error[]
}

/** GetApiclientsClientId403Error */
export interface GetApiclientsClientId403Error {
  code: 'sign_in_required'
}

/** GetApiclientsClientId403ErrorResponse */
export interface GetApiclientsClientId403ErrorResponse {
  data: null
  errors: GetApiclientsClientId403Error[]
}

/** GetApiclientsClientId404Error */
export interface GetApiclientsClientId404Error {
  code: 'api_client_not_found'
}

/** GetApiclientsClientId404ErrorResponse */
export interface GetApiclientsClientId404ErrorResponse {
  data: null
  errors: GetApiclientsClientId404Error[]
}

/** GetAssetVariantsConfigurationsConfigurationId403Error */
export interface GetAssetVariantsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** GetAssetVariantsConfigurationsConfigurationId403ErrorResponse */
export interface GetAssetVariantsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: GetAssetVariantsConfigurationsConfigurationId403Error[]
}

/** GetAssetVariantsConfigurationsConfigurationId404Error */
export interface GetAssetVariantsConfigurationsConfigurationId404Error {
  code: 'asset_variant_configuration_not_found'
}

/** GetAssetVariantsConfigurationsConfigurationId404ErrorResponse */
export interface GetAssetVariantsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: GetAssetVariantsConfigurationsConfigurationId404Error[]
}

/** GetAssetVariantsConfigurationsPending400Error */
export interface GetAssetVariantsConfigurationsPending400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetAssetVariantsConfigurationsPending400ErrorResponse */
export interface GetAssetVariantsConfigurationsPending400ErrorResponse {
  data: null
  errors: GetAssetVariantsConfigurationsPending400Error[]
}

/** GetAssetVariantsConfigurationsPending403Error */
export interface GetAssetVariantsConfigurationsPending403Error {
  code: 'sign_in_required'
}

/** GetAssetVariantsConfigurationsPending403ErrorResponse */
export interface GetAssetVariantsConfigurationsPending403ErrorResponse {
  data: null
  errors: GetAssetVariantsConfigurationsPending403Error[]
}

/** GetAssetVariantsFormats403Error */
export interface GetAssetVariantsFormats403Error {
  code: 'sign_in_required'
}

/** GetAssetVariantsFormats403ErrorResponse */
export interface GetAssetVariantsFormats403ErrorResponse {
  data: null
  errors: GetAssetVariantsFormats403Error[]
}

/** GetAssetVariantsProfiles400Error */
export interface GetAssetVariantsProfiles400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetAssetVariantsProfiles400ErrorResponse */
export interface GetAssetVariantsProfiles400ErrorResponse {
  data: null
  errors: GetAssetVariantsProfiles400Error[]
}

/** GetAssetVariantsProfiles403Error */
export interface GetAssetVariantsProfiles403Error {
  code: 'sign_in_required'
}

/** GetAssetVariantsProfiles403ErrorResponse */
export interface GetAssetVariantsProfiles403ErrorResponse {
  data: null
  errors: GetAssetVariantsProfiles403Error[]
}

/** GetAssetVariantsProfilesProfileKeyConfigurations400Error */
export interface GetAssetVariantsProfilesProfileKeyConfigurations400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse */
export interface GetAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse {
  data: null
  errors: GetAssetVariantsProfilesProfileKeyConfigurations400Error[]
}

/** GetAssetVariantsProfilesProfileKeyConfigurations403Error */
export interface GetAssetVariantsProfilesProfileKeyConfigurations403Error {
  code: 'sign_in_required'
}

/** GetAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse */
export interface GetAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse {
  data: null
  errors: GetAssetVariantsProfilesProfileKeyConfigurations403Error[]
}

/** GetAssetVariantsProfilesProfileKeyConfigurations404Error */
export interface GetAssetVariantsProfilesProfileKeyConfigurations404Error {
  code: 'asset_variants_profile_not_found'
}

/** GetAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse */
export interface GetAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse {
  data: null
  errors: GetAssetVariantsProfilesProfileKeyConfigurations404Error[]
}

export type GetAvailableStudioSettingsData = ResponseAvailableLegacyStudioSettingsResponse

export type GetAvailableStudioSettingsError = GetStudioSettings403ErrorResponse

/** GetBackendhosts400Error */
export interface GetBackendhosts400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetBackendhosts400ErrorResponse */
export interface GetBackendhosts400ErrorResponse {
  data: null
  errors: GetBackendhosts400Error[]
}

/** GetBackendhosts403Error */
export interface GetBackendhosts403Error {
  code: 'sign_in_required'
}

/** GetBackendhosts403ErrorResponse */
export interface GetBackendhosts403ErrorResponse {
  data: null
  errors: GetBackendhosts403Error[]
}

/** GetBankAccounts400Error */
export interface GetBankAccounts400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetBankAccounts400ErrorResponse */
export interface GetBankAccounts400ErrorResponse {
  data: null
  errors: GetBankAccounts400Error[]
}

/** GetBankAccounts403Error */
export interface GetBankAccounts403Error {
  code: 'sign_in_required'
}

/** GetBankAccounts403ErrorResponse */
export interface GetBankAccounts403ErrorResponse {
  data: null
  errors: GetBankAccounts403Error[]
}

/** GetBankAccounts404Error */
export interface GetBankAccounts404Error {
  code: 'user_not_found'
}

/** GetBankAccounts404ErrorResponse */
export interface GetBankAccounts404ErrorResponse {
  data: null
  errors: GetBankAccounts404Error[]
}

/** GetBankAccountsAccountId403Error */
export interface GetBankAccountsAccountId403Error {
  code: 'sign_in_required'
}

/** GetBankAccountsAccountId403ErrorResponse */
export interface GetBankAccountsAccountId403ErrorResponse {
  data: null
  errors: GetBankAccountsAccountId403Error[]
}

/** GetBankAccountsAccountId404Error */
export interface GetBankAccountsAccountId404Error {
  code: 'bank_account_not_found'
}

/** GetBankAccountsAccountId404ErrorResponse */
export interface GetBankAccountsAccountId404ErrorResponse {
  data: null
  errors: GetBankAccountsAccountId404Error[]
}

/** GetBankAccountsAccountIdTransactions400Error */
export interface GetBankAccountsAccountIdTransactions400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetBankAccountsAccountIdTransactions400ErrorResponse */
export interface GetBankAccountsAccountIdTransactions400ErrorResponse {
  data: null
  errors: GetBankAccountsAccountIdTransactions400Error[]
}

/** GetBankAccountsAccountIdTransactions403Error */
export interface GetBankAccountsAccountIdTransactions403Error {
  code: 'sign_in_required'
}

/** GetBankAccountsAccountIdTransactions403ErrorResponse */
export interface GetBankAccountsAccountIdTransactions403ErrorResponse {
  data: null
  errors: GetBankAccountsAccountIdTransactions403Error[]
}

/** GetBankAccountsAccountIdTransactions404Error */
export interface GetBankAccountsAccountIdTransactions404Error {
  code: 'bank_account_not_found'
}

/** GetBankAccountsAccountIdTransactions404ErrorResponse */
export interface GetBankAccountsAccountIdTransactions404ErrorResponse {
  data: null
  errors: GetBankAccountsAccountIdTransactions404Error[]
}

/** GetBankTransactions400Error */
export interface GetBankTransactions400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetBankTransactions400ErrorResponse */
export interface GetBankTransactions400ErrorResponse {
  data: null
  errors: GetBankTransactions400Error[]
}

/** GetBankTransactions403Error */
export interface GetBankTransactions403Error {
  code: 'sign_in_required'
}

/** GetBankTransactions403ErrorResponse */
export interface GetBankTransactions403ErrorResponse {
  data: null
  errors: GetBankTransactions403Error[]
}

/** GetBankTransactionsTransactionId403Error */
export interface GetBankTransactionsTransactionId403Error {
  code: 'sign_in_required'
}

/** GetBankTransactionsTransactionId403ErrorResponse */
export interface GetBankTransactionsTransactionId403ErrorResponse {
  data: null
  errors: GetBankTransactionsTransactionId403Error[]
}

/** GetBankTransactionsTransactionId404Error */
export interface GetBankTransactionsTransactionId404Error {
  code: 'bank_transaction_not_found'
}

/** GetBankTransactionsTransactionId404ErrorResponse */
export interface GetBankTransactionsTransactionId404ErrorResponse {
  data: null
  errors: GetBankTransactionsTransactionId404Error[]
}

/** GetCdnHostings400Error */
export interface GetCdnHostings400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetCdnHostings400ErrorResponse */
export interface GetCdnHostings400ErrorResponse {
  data: null
  errors: GetCdnHostings400Error[]
}

/** GetCdnHostings403Error */
export interface GetCdnHostings403Error {
  code: 'sign_in_required'
}

/** GetCdnHostings403ErrorResponse */
export interface GetCdnHostings403ErrorResponse {
  data: null
  errors: GetCdnHostings403Error[]
}

export type GetChallengeData = ResponseChallengeResponse

export type GetChallengeError =
  | GetChallengesChallengeId403ErrorResponse
  | GetChallengesChallengeId404ErrorResponse

/** GetChallenges400Error */
export interface GetChallenges400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetChallenges400ErrorResponse */
export interface GetChallenges400ErrorResponse {
  data: null
  errors: GetChallenges400Error[]
}

/** GetChallenges403Error */
export interface GetChallenges403Error {
  code: 'sign_in_required'
}

/** GetChallenges403ErrorResponse */
export interface GetChallenges403ErrorResponse {
  data: null
  errors: GetChallenges403Error[]
}

/** GetChallengesChallengeId403Error */
export interface GetChallengesChallengeId403Error {
  code: 'sign_in_required'
}

/** GetChallengesChallengeId403ErrorResponse */
export interface GetChallengesChallengeId403ErrorResponse {
  data: null
  errors: GetChallengesChallengeId403Error[]
}

/** GetChallengesChallengeId404Error */
export interface GetChallengesChallengeId404Error {
  code: 'challenge_not_found'
}

/** GetChallengesChallengeId404ErrorResponse */
export interface GetChallengesChallengeId404ErrorResponse {
  data: null
  errors: GetChallengesChallengeId404Error[]
}

/** GetChallengesChallengeIdScoreVerifications400Error */
export interface GetChallengesChallengeIdScoreVerifications400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetChallengesChallengeIdScoreVerifications400ErrorResponse */
export interface GetChallengesChallengeIdScoreVerifications400ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdScoreVerifications400Error[]
}

/** GetChallengesChallengeIdScoreVerifications403Error */
export interface GetChallengesChallengeIdScoreVerifications403Error {
  code: 'sign_in_required'
}

/** GetChallengesChallengeIdScoreVerifications403ErrorResponse */
export interface GetChallengesChallengeIdScoreVerifications403ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdScoreVerifications403Error[]
}

/** GetChallengesChallengeIdScoreVerifications404Error */
export interface GetChallengesChallengeIdScoreVerifications404Error {
  code: 'challenge_not_found' | 'promoted_challenge_not_found'
}

/** GetChallengesChallengeIdScoreVerifications404ErrorResponse */
export interface GetChallengesChallengeIdScoreVerifications404ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdScoreVerifications404Error[]
}

/** GetChallengesChallengeIdUserScoresUserId400Error */
export interface GetChallengesChallengeIdUserScoresUserId400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetChallengesChallengeIdUserScoresUserId400ErrorResponse */
export interface GetChallengesChallengeIdUserScoresUserId400ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdUserScoresUserId400Error[]
}

/** GetChallengesChallengeIdUserScoresUserId403Error */
export interface GetChallengesChallengeIdUserScoresUserId403Error {
  code: 'sign_in_required'
}

/** GetChallengesChallengeIdUserScoresUserId403ErrorResponse */
export interface GetChallengesChallengeIdUserScoresUserId403ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdUserScoresUserId403Error[]
}

/** GetChallengesChallengeIdUserScoresUserId404Error */
export interface GetChallengesChallengeIdUserScoresUserId404Error {
  code: 'challenge_not_found' | 'user_not_found'
}

/** GetChallengesChallengeIdUserScoresUserId404ErrorResponse */
export interface GetChallengesChallengeIdUserScoresUserId404ErrorResponse {
  data: null
  errors: GetChallengesChallengeIdUserScoresUserId404Error[]
}

export type GetChallengesData = ResponseChallengesResponse

export type GetChallengesError = GetChallenges400ErrorResponse | GetChallenges403ErrorResponse

/** GetChallengesGenerateShortId403Error */
export interface GetChallengesGenerateShortId403Error {
  code: 'sign_in_required'
}

/** GetChallengesGenerateShortId403ErrorResponse */
export interface GetChallengesGenerateShortId403ErrorResponse {
  data: null
  errors: GetChallengesGenerateShortId403Error[]
}

export interface GetChallengesParams {
  /**
   * Only retrieve discoverable challenges
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  onlyDiscoverable?: boolean
  /**
   * The page to return. The response will contain challenges in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of challenges to include in the response. Fewer challenges may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Game name search term
   * @example "oranges"
   */
  searchGameName?: string
  /**
   * Challenge name search term
   * @example "apples"
   */
  searchName?: string
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `name-asc`
   */
  sort?: (
    | 'creation-date-asc'
    | 'creation-date-desc'
    | 'game-name-asc'
    | 'game-name-desc'
    | 'name-asc'
    | 'name-desc'
  )[]
}

/** GetChallengesScoresPendingVideoUploads403Error */
export interface GetChallengesScoresPendingVideoUploads403Error {
  code: 'sign_in_required'
}

/** GetChallengesScoresPendingVideoUploads403ErrorResponse */
export interface GetChallengesScoresPendingVideoUploads403ErrorResponse {
  data: null
  errors: GetChallengesScoresPendingVideoUploads403Error[]
}

export type GetClaimPeriodDetailsData = ResponseGameItemClaimPeriodResponse

export type GetClaimPeriodDetailsError =
  | GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse
  | GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse

export type GetClaimPeriodsDetailsData = ResponseGameItemClaimPeriodsResponse

export type GetClaimPeriodsDetailsError =
  | GetGamesGameIdItemsItemIdClaimPeriods400ErrorResponse
  | GetGamesGameIdItemsItemIdClaimPeriods403ErrorResponse
  | GetGamesGameIdItemsItemIdClaimPeriods404ErrorResponse

export interface GetClaimPeriodsDetailsParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  itemId: string
  /**
   * The page to return. The response will contain game item claim periods in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game item claim periods to include in the response. Fewer game item claim periods may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** GetClusterStatus403Error */
export interface GetClusterStatus403Error {
  code: 'sign_in_required'
}

/** GetClusterStatus403ErrorResponse */
export interface GetClusterStatus403ErrorResponse {
  data: null
  errors: GetClusterStatus403Error[]
}

export type GetClusterStatusData = ResponseClusterStatusResponse

export type GetClusterStatusError = GetClusterStatus403ErrorResponse

/** GetCmsConfigurations400Error */
export interface GetCmsConfigurations400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetCmsConfigurations400ErrorResponse */
export interface GetCmsConfigurations400ErrorResponse {
  data: null
  errors: GetCmsConfigurations400Error[]
}

/** GetCmsConfigurations403Error */
export interface GetCmsConfigurations403Error {
  code: 'sign_in_required'
}

/** GetCmsConfigurations403ErrorResponse */
export interface GetCmsConfigurations403ErrorResponse {
  data: null
  errors: GetCmsConfigurations403Error[]
}

/** GetCmsConfigurationsConfigurationId403Error */
export interface GetCmsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** GetCmsConfigurationsConfigurationId403ErrorResponse */
export interface GetCmsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: GetCmsConfigurationsConfigurationId403Error[]
}

/** GetCmsConfigurationsConfigurationId404Error */
export interface GetCmsConfigurationsConfigurationId404Error {
  code: 'configuration_not_found'
}

/** GetCmsConfigurationsConfigurationId404ErrorResponse */
export interface GetCmsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: GetCmsConfigurationsConfigurationId404Error[]
}

/** GetCmsConfigurationsConfigurationIdSections403Error */
export interface GetCmsConfigurationsConfigurationIdSections403Error {
  code: 'sign_in_required'
}

/** GetCmsConfigurationsConfigurationIdSections403ErrorResponse */
export interface GetCmsConfigurationsConfigurationIdSections403ErrorResponse {
  data: null
  errors: GetCmsConfigurationsConfigurationIdSections403Error[]
}

/** GetCmsConfigurationsConfigurationIdSections404Error */
export interface GetCmsConfigurationsConfigurationIdSections404Error {
  code: 'configuration_not_found'
}

/** GetCmsConfigurationsConfigurationIdSections404ErrorResponse */
export interface GetCmsConfigurationsConfigurationIdSections404ErrorResponse {
  data: null
  errors: GetCmsConfigurationsConfigurationIdSections404Error[]
}

export type GetCodeDetailsData = ResponseOfferCodeDetailsResponse

export type GetCodeDetailsError =
  | GetOffersOfferIdCodesCodeId403ErrorResponse
  | GetOffersOfferIdCodesCodeId404ErrorResponse

export interface GetCodeDetailsParams {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  codeId: string
  /** @default false */
  includeUnmaskedValue?: boolean
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
}

export type GetConfigurationDetailsData = ResponseCmsConfigurationResponse

export type GetConfigurationDetailsError =
  | GetCmsConfigurationsConfigurationId403ErrorResponse
  | GetCmsConfigurationsConfigurationId404ErrorResponse

export type GetData = ResponseOfferErrorPageResponse

export type GetDataPointsData = ResponseGameStatisticsDataPointsResponse

export type GetDataPointsError =
  | GetGamesGameIdStatistics400ErrorResponse
  | GetGamesGameIdStatistics403ErrorResponse
  | GetGamesGameIdStatistics404ErrorResponse

export interface GetDataPointsParams {
  /**
   * Last day of time period of interest
   * @format date
   * @example "2021-10-05"
   */
  endDate: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /** Metric to return data points for */
  metric:
    | 'AVERAGE_PLAY_DURATION'
    | 'NUMBER_OF_NEW_PLAYERS'
    | 'NUMBER_OF_PLAYS'
    | 'NUMBER_OF_USERS_FAVORITED'
    | 'PERCENT_OF_RETURNING_PLAYERS'
    | 'PERCENT_OF_SIGNED_IN_USERS'
    | 'PLAYER_COUNTRY_PERCENTAGE'
  /**
   * The page to return. The response will contain data series in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of data series to include in the response. Fewer data series may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Data point period type
   * @default "DAILY"
   */
  periodType?: 'DAILY' | 'MONTHLY' | 'WEEKLY'
  /**
   * First day of time period of interest
   * @format date
   * @example "2021-09-29"
   */
  startDate: string
  /**
   * Difference in minutes between the local time zone and UTC. See Javascript's `Date.prototype.getTimezoneOffset()`. The time zone `UTC+2` corresponds to the time zone offset `-120`.
   * @format int32
   * @example "-180"
   */
  timeZoneOffset: number
  /**
   * Release track to look up statistics for; defaults to the game's public track
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  trackId?: string
}

export type GetDeploymentDetailsData = ResponseWrapperDeploymentResponse

export type GetDeploymentDetailsError =
  | GetWrappersWrapperIdDeploymentsDeploymentId403ErrorResponse
  | GetWrappersWrapperIdDeploymentsDeploymentId404ErrorResponse

export type GetDetailsData = ResponseAssetVariantConfigurationResponse

export type GetDetailsError =
  | GetAssetVariantsConfigurationsConfigurationId403ErrorResponse
  | GetAssetVariantsConfigurationsConfigurationId404ErrorResponse

export type GetDisputeDetailsData = ResponseDisputeResponse

export type GetDisputeDetailsError =
  | GetDisputesDisputeId403ErrorResponse
  | GetDisputesDisputeId404ErrorResponse

/** GetDisputes400Error */
export interface GetDisputes400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetDisputes400ErrorResponse */
export interface GetDisputes400ErrorResponse {
  data: null
  errors: GetDisputes400Error[]
}

/** GetDisputes403Error */
export interface GetDisputes403Error {
  code: 'sign_in_required'
}

/** GetDisputes403ErrorResponse */
export interface GetDisputes403ErrorResponse {
  data: null
  errors: GetDisputes403Error[]
}

/** GetDisputes404Error */
export interface GetDisputes404Error {
  code: 'payment_not_found'
}

/** GetDisputes404ErrorResponse */
export interface GetDisputes404ErrorResponse {
  data: null
  errors: GetDisputes404Error[]
}

export type GetDisputesDetailsData = ResponseDisputesResponse

export type GetDisputesDetailsError =
  | GetDisputes400ErrorResponse
  | GetDisputes403ErrorResponse
  | GetDisputes404ErrorResponse

export interface GetDisputesDetailsParams {
  /** Dispute type to filter by. Can be used multiple times, or be a comma-separated list. */
  disputeType?: ('AUTOMATIC' | 'MANUAL')[]
  /**
   * The page to return. The response will contain disputes in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of disputes to include in the response. Fewer disputes may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Payment ID to filter by. Can be used multiple times, or be a comma-separated list. */
  paymentId?: string[]
  /** Resolution to filter by. Can be used multiple times, or be a comma-separated list. */
  resolution?: ('UNRESOLVED' | 'RESOLVED')[]
  /** Resolution type to filter by. Can be used multiple times, or be a comma-separated list. */
  resolutionType?: ('NOT_DEFINED' | 'AUTOMATIC' | 'MANUAL')[]
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `creation-date-desc`
   */
  sort?: (
    | 'creation-date-asc'
    | 'creation-date-desc'
    | 'resolution-date-asc'
    | 'resolution-date-desc'
  )[]
  /** Transaction status to filter by. Can be used multiple times, or be a comma-separated list. */
  transactionStatus?: ('KEEP' | 'REVERT')[]
}

/** GetDisputesDisputeId403Error */
export interface GetDisputesDisputeId403Error {
  code: 'sign_in_required'
}

/** GetDisputesDisputeId403ErrorResponse */
export interface GetDisputesDisputeId403ErrorResponse {
  data: null
  errors: GetDisputesDisputeId403Error[]
}

/** GetDisputesDisputeId404Error */
export interface GetDisputesDisputeId404Error {
  code: 'dispute_not_found'
}

/** GetDisputesDisputeId404ErrorResponse */
export interface GetDisputesDisputeId404ErrorResponse {
  data: null
  errors: GetDisputesDisputeId404Error[]
}

/** GetDynamicConfig400Error */
export interface GetDynamicConfig400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetDynamicConfig400ErrorResponse */
export interface GetDynamicConfig400ErrorResponse {
  data: null
  errors: GetDynamicConfig400Error[]
}

/** GetDynamicConfig403Error */
export interface GetDynamicConfig403Error {
  code: 'sign_in_required'
}

/** GetDynamicConfig403ErrorResponse */
export interface GetDynamicConfig403ErrorResponse {
  data: null
  errors: GetDynamicConfig403Error[]
}

/** GetDynamicConfigClients403Error */
export interface GetDynamicConfigClients403Error {
  code: 'sign_in_required'
}

/** GetDynamicConfigClients403ErrorResponse */
export interface GetDynamicConfigClients403ErrorResponse {
  data: null
  errors: GetDynamicConfigClients403Error[]
}

/** GetDynamicConfigConfigId403Error */
export interface GetDynamicConfigConfigId403Error {
  code: 'sign_in_required'
}

/** GetDynamicConfigConfigId403ErrorResponse */
export interface GetDynamicConfigConfigId403ErrorResponse {
  data: null
  errors: GetDynamicConfigConfigId403Error[]
}

/** GetDynamicConfigConfigId404Error */
export interface GetDynamicConfigConfigId404Error {
  code: 'dynamic_config_not_found'
}

/** GetDynamicConfigConfigId404ErrorResponse */
export interface GetDynamicConfigConfigId404ErrorResponse {
  data: null
  errors: GetDynamicConfigConfigId404Error[]
}

export type GetDynamicConfigDetailsData = ResponseDynamicConfigResponse

export type GetDynamicConfigDetailsError =
  | GetDynamicConfigConfigId403ErrorResponse
  | GetDynamicConfigConfigId404ErrorResponse

/** GetEmailingTopics400Error */
export interface GetEmailingTopics400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetEmailingTopics400ErrorResponse */
export interface GetEmailingTopics400ErrorResponse {
  data: null
  errors: GetEmailingTopics400Error[]
}

/** GetEmailingTopics403Error */
export interface GetEmailingTopics403Error {
  code: 'sign_in_required'
}

/** GetEmailingTopics403ErrorResponse */
export interface GetEmailingTopics403ErrorResponse {
  data: null
  errors: GetEmailingTopics403Error[]
}

/** GetEngines400Error */
export interface GetEngines400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetEngines400ErrorResponse */
export interface GetEngines400ErrorResponse {
  data: null
  errors: GetEngines400Error[]
}

/** GetEngines403Error */
export interface GetEngines403Error {
  code: 'sign_in_required'
}

/** GetEngines403ErrorResponse */
export interface GetEngines403ErrorResponse {
  data: null
  errors: GetEngines403Error[]
}

/** GetEnginesEngineId403Error */
export interface GetEnginesEngineId403Error {
  code: 'sign_in_required'
}

/** GetEnginesEngineId403ErrorResponse */
export interface GetEnginesEngineId403ErrorResponse {
  data: null
  errors: GetEnginesEngineId403Error[]
}

/** GetEnginesEngineId404Error */
export interface GetEnginesEngineId404Error {
  code: 'game_engine_not_found'
}

/** GetEnginesEngineId404ErrorResponse */
export interface GetEnginesEngineId404ErrorResponse {
  data: null
  errors: GetEnginesEngineId404Error[]
}

export type GetError =
  | GetOffersOfferIdErrorPagesErrorPageId403ErrorResponse
  | GetOffersOfferIdErrorPagesErrorPageId404ErrorResponse

export type GetFeedData = ResponseManagedFeedDetailsResponse

export type GetFeedEntriesV2Data = ResponseFeedEntriesResponse

export type GetFeedEntriesV2Error =
  | GetV2FeedsFeedIdEntries400ErrorResponse
  | GetV2FeedsFeedIdEntries403ErrorResponse
  | GetV2FeedsFeedIdEntries404ErrorResponse

export interface GetFeedEntriesV2Params {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedId: string
  /**
   * Parameter to request only active state of the feed if there are pending changes.The parameter value is ignored if there are no pending changes for feed.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  loadActiveState?: boolean
  /**
   * The page to return. The response will contain entries in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of entries to include in the response. Fewer entries may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `display-order-asc`
   */
  sort?: ('date-added-asc' | 'date-added-desc' | 'display-order-asc' | 'display-order-desc')[]
  /** Optional type to filter feed entries */
  type?: 'games' | 'challenges' | 'mods'
}

export type GetFeedError =
  | GetFeedsFeedId400ErrorResponse
  | GetFeedsFeedId403ErrorResponse
  | GetFeedsFeedId404ErrorResponse

export interface GetFeedParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedId: string
  /**
   * Parameter to request only active state of the feed if there are pending changes.The parameter value is ignored if there are no pending changes for feed.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  loadActiveState?: boolean
  /** Optional type to filter feed entries */
  type?: 'games' | 'challenges' | 'mods'
}

/** GetFeeds400Error */
export interface GetFeeds400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetFeeds400ErrorResponse */
export interface GetFeeds400ErrorResponse {
  data: null
  errors: GetFeeds400Error[]
}

/** GetFeeds403Error */
export interface GetFeeds403Error {
  code: 'sign_in_required'
}

/** GetFeeds403ErrorResponse */
export interface GetFeeds403ErrorResponse {
  data: null
  errors: GetFeeds403Error[]
}

export type GetFeedsData = ResponseManagedFeedsResponse

export type GetFeedsError = GetFeeds400ErrorResponse | GetFeeds403ErrorResponse

/** GetFeedsFeedId400Error */
export interface GetFeedsFeedId400Error {
  code: 'invalid_feed_type'
}

/** GetFeedsFeedId400ErrorResponse */
export interface GetFeedsFeedId400ErrorResponse {
  data: null
  errors: GetFeedsFeedId400Error[]
}

/** GetFeedsFeedId403Error */
export interface GetFeedsFeedId403Error {
  code: 'sign_in_required'
}

/** GetFeedsFeedId403ErrorResponse */
export interface GetFeedsFeedId403ErrorResponse {
  data: null
  errors: GetFeedsFeedId403Error[]
}

/** GetFeedsFeedId404Error */
export interface GetFeedsFeedId404Error {
  code: 'feed_not_found'
}

/** GetFeedsFeedId404ErrorResponse */
export interface GetFeedsFeedId404ErrorResponse {
  data: null
  errors: GetFeedsFeedId404Error[]
}

/** GetFeedsFeedIdUsersEmails403Error */
export interface GetFeedsFeedIdUsersEmails403Error {
  code: 'sign_in_required'
}

/** GetFeedsFeedIdUsersEmails403ErrorResponse */
export interface GetFeedsFeedIdUsersEmails403ErrorResponse {
  data: null
  errors: GetFeedsFeedIdUsersEmails403Error[]
}

/** GetFeedsFeedIdUsersEmails404Error */
export interface GetFeedsFeedIdUsersEmails404Error {
  code: 'feed_not_found'
}

/** GetFeedsFeedIdUsersEmails404ErrorResponse */
export interface GetFeedsFeedIdUsersEmails404ErrorResponse {
  data: null
  errors: GetFeedsFeedIdUsersEmails404Error[]
}

export interface GetFeedsParams {
  nameSearch?: string
  /**
   * If true, return only the feed currently selected as the global filter.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  onlyGlobalFilter?: boolean
  /**
   * The page to return. The response will contain feeds in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of feeds to include in the response. Fewer feeds may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** GetFundingWaitList400Error */
export interface GetFundingWaitList400Error {
  code:
    | 'invalid_filter'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetFundingWaitList400ErrorResponse */
export interface GetFundingWaitList400ErrorResponse {
  data: null
  errors: GetFundingWaitList400Error[]
}

/** GetFundingWaitList403Error */
export interface GetFundingWaitList403Error {
  code: 'sign_in_required'
}

/** GetFundingWaitList403ErrorResponse */
export interface GetFundingWaitList403ErrorResponse {
  data: null
  errors: GetFundingWaitList403Error[]
}

/** GetFundingWaitList404Error */
export interface GetFundingWaitList404Error {
  code: 'user_not_found'
}

/** GetFundingWaitList404ErrorResponse */
export interface GetFundingWaitList404ErrorResponse {
  data: null
  errors: GetFundingWaitList404Error[]
}

export type GetGameBannersData = ResponseGameBannersResponse

export type GetGameBannersError =
  | GetGamesGameIdBanners400ErrorResponse
  | GetGamesGameIdBanners403ErrorResponse
  | GetGamesGameIdBanners404ErrorResponse

export interface GetGameBannersParams {
  /** Search string for an alias */
  aliasSearch?: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain banners in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of banners to include in the response. Fewer banners may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type GetGameChallengesData = ResponseGameChallengesResponse

export type GetGameChallengesError =
  | GetGamesGameIdChallenges400ErrorResponse
  | GetGamesGameIdChallenges403ErrorResponse
  | GetGamesGameIdChallenges404ErrorResponse

export interface GetGameChallengesParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain challenges in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of challenges to include in the response. Fewer challenges may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `name-asc`
   */
  sort?: (
    | 'creation-date-asc'
    | 'creation-date-desc'
    | 'game-name-asc'
    | 'game-name-desc'
    | 'name-asc'
    | 'name-desc'
  )[]
}

export type GetGameData = ResponseGameResponse

export type GetGameEngineDetailsData = ResponseGameEngineDetailsResponse

export type GetGameEngineDetailsError =
  | GetEnginesEngineId403ErrorResponse
  | GetEnginesEngineId404ErrorResponse

export type GetGameEnginesData = ResponseGameEnginesResponse

export type GetGameEnginesError = GetEngines400ErrorResponse | GetEngines403ErrorResponse

export interface GetGameEnginesParams {
  /**
   * Filter engines by their default property
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  default?: boolean
  /**
   * The page to return. The response will contain game engines in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game engines to include in the response. Fewer game engines may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `title-asc`
   */
  sort?: ('title-asc' | 'title-desc')[]
  /**
   * Filter engines by their visible property
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  visible?: boolean
}

export type GetGameError = GetGamesGameId403ErrorResponse | GetGamesGameId404ErrorResponse

export type GetGameItemDetailsData = ResponseGameItemResponse

export type GetGameItemDetailsError =
  | GetGamesGameIdItemsItemId403ErrorResponse
  | GetGamesGameIdItemsItemId404ErrorResponse

export type GetGameItemsDetailsData = ResponseGameItemsResponse

export type GetGameItemsDetailsError =
  | GetGamesGameIdItems400ErrorResponse
  | GetGamesGameIdItems403ErrorResponse
  | GetGamesGameIdItems404ErrorResponse

export interface GetGameItemsDetailsParams {
  /**
   * If enabled, will only return deleted game items; if disabled, will only return items that aren't deleted. If left empty, returns all game items regardless of deletion.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  deleted?: boolean
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain game items in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game items to include in the response. Fewer game items may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** GetGamePlayStatistics403Error */
export interface GetGamePlayStatistics403Error {
  code: 'sign_in_required'
}

/** GetGamePlayStatistics403ErrorResponse */
export interface GetGamePlayStatistics403ErrorResponse {
  data: null
  errors: GetGamePlayStatistics403Error[]
}

export type GetGamePurchaseDetailsData = ResponseGamePurchaseDetailedResponse

export type GetGamePurchaseDetailsError =
  | GetPurchasesPurchaseId403ErrorResponse
  | GetPurchasesPurchaseId404ErrorResponse

export type GetGamePurchasesData = ResponseGamePurchasesDetailedResponse

export type GetGamePurchasesError =
  | GetPurchases400ErrorResponse
  | GetPurchases403ErrorResponse
  | GetPurchases404ErrorResponse

export interface GetGamePurchasesParams {
  /**
   * Filter by purchase status. A purchase is "active" if the game is available in the user's library and they can play it.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  active?: boolean
  /** Game ID to filter by. Can be used multiple times, or be a comma-separated list. */
  gameId?: string[]
  /** Game item type to filter by. Can be used multiple times, or be a comma-separated list. */
  itemType?: ('FULL_VERSION' | 'PAID_GAME')[]
  /** Filter by reason for loss of purchase. */
  lossReason?: 'GAME_DELETION' | 'NOT_LOST' | 'REFUND' | 'SUSPEND' | 'UNPUBLISHED'
  /**
   * The page to return. The response will contain purchases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of purchases to include in the response. Fewer purchases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Purchase type to filter by. Can be used multiple times, or be a comma-separated list. */
  purchaseType?: ('CLAIM' | 'FREE' | 'PURCHASE' | 'TIPPING')[]
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `purchase-date-desc`
   */
  sort?: ('purchase-date-asc' | 'purchase-date-desc')[]
  /** Transaction ID to filter by. Can be used multiple times, or be a comma-separated list. */
  transactionId?: string[]
  /** User ID to filter by. Can be used multiple times, or be a comma-separated list. */
  userId?: string[]
}

export type GetGameReleasesData = ResponseGameReleasesResponse

export type GetGameReleasesError =
  | GetGamesGameIdTracksTrackIdReleases400ErrorResponse
  | GetGamesGameIdTracksTrackIdReleases403ErrorResponse
  | GetGamesGameIdTracksTrackIdReleases404ErrorResponse

export interface GetGameReleasesParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain game releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game releases to include in the response. Fewer game releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `uploaded-date-desc`
   */
  sort?: ('uploaded-date-asc' | 'uploaded-date-desc')[]
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  trackId: string
}

export type GetGameReviewRequestsData = ResponseGameReviewRequestsResponse

export type GetGameReviewRequestsError =
  | GetReviewGamesGameIdReviewRequests400ErrorResponse
  | GetReviewGamesGameIdReviewRequests403ErrorResponse
  | GetReviewGamesGameIdReviewRequests404ErrorResponse

export interface GetGameReviewRequestsParams {
  /**
   * The ID of the review requested game
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain requests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of requests to include in the response. Fewer requests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `created-at-asc`
   */
  sort?: ('created-at-asc' | 'created-at-desc')[]
  /** Review requests status to filter by; if left empty, no filter is applied. */
  status?: ('UNRESOLVED' | 'RESOLVED')[]
}

export type GetGameReviewsData = ResponseGameReviewReviewsResponse

export type GetGameReviewsError =
  | GetReviewGamesGameIdReviews400ErrorResponse
  | GetReviewGamesGameIdReviews403ErrorResponse
  | GetReviewGamesGameIdReviews404ErrorResponse

export interface GetGameReviewsParams {
  /**
   * The ID of the reviewed game
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * The page to return. The response will contain requests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of requests to include in the response. Fewer requests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `created-at-asc`
   */
  sort?: ('created-at-asc' | 'created-at-desc')[]
}

export type GetGameTransferData = ResponseGameTransferResponse

export type GetGameTransferError =
  | GetGameTransfersTransferId403ErrorResponse
  | GetGameTransfersTransferId404ErrorResponse

/** GetGameTransfers400Error */
export interface GetGameTransfers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGameTransfers400ErrorResponse */
export interface GetGameTransfers400ErrorResponse {
  data: null
  errors: GetGameTransfers400Error[]
}

/** GetGameTransfers403Error */
export interface GetGameTransfers403Error {
  code: 'sign_in_required'
}

/** GetGameTransfers403ErrorResponse */
export interface GetGameTransfers403ErrorResponse {
  data: null
  errors: GetGameTransfers403Error[]
}

/** GetGameTransfers404Error */
export interface GetGameTransfers404Error {
  code: 'game_not_found' | 'studio_not_found'
}

/** GetGameTransfers404ErrorResponse */
export interface GetGameTransfers404ErrorResponse {
  data: null
  errors: GetGameTransfers404Error[]
}

export type GetGameTransfersData = ResponseGameTransfersResponse

export type GetGameTransfersError =
  | GetGameTransfers400ErrorResponse
  | GetGameTransfers403ErrorResponse
  | GetGameTransfers404ErrorResponse

export interface GetGameTransfersParams {
  /**
   * Only return game transfers for this game.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId?: string
  /**
   * Only return game transfers with such original studio.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  originalStudioId?: string
  /**
   * The page to return. The response will contain game transfers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game transfers to include in the response. Fewer game transfers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Filter game transfers by status. Can be used multiple times, or be a comma-separated list. */
  status?: ('ABORTED' | 'APPROVED' | 'EXPIRED' | 'PENDING' | 'REJECTED')[]
  /**
   * Only return game transfers with such target studio.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  targetStudioId?: string
}

/** GetGameTransfersTransferId403Error */
export interface GetGameTransfersTransferId403Error {
  code: 'sign_in_required'
}

/** GetGameTransfersTransferId403ErrorResponse */
export interface GetGameTransfersTransferId403ErrorResponse {
  data: null
  errors: GetGameTransfersTransferId403Error[]
}

/** GetGameTransfersTransferId404Error */
export interface GetGameTransfersTransferId404Error {
  code: 'game_transfer_not_found' | 'transfer_not_found'
}

/** GetGameTransfersTransferId404ErrorResponse */
export interface GetGameTransfersTransferId404ErrorResponse {
  data: null
  errors: GetGameTransfersTransferId404Error[]
}

/** GetGameWrappers400Error */
export interface GetGameWrappers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGameWrappers400ErrorResponse */
export interface GetGameWrappers400ErrorResponse {
  data: null
  errors: GetGameWrappers400Error[]
}

/** GetGameWrappers403Error */
export interface GetGameWrappers403Error {
  code: 'sign_in_required'
}

/** GetGameWrappers403ErrorResponse */
export interface GetGameWrappers403ErrorResponse {
  data: null
  errors: GetGameWrappers403Error[]
}

/** GetGameWrappers404Error */
export interface GetGameWrappers404Error {
  code: 'game_engine_not_found'
}

/** GetGameWrappers404ErrorResponse */
export interface GetGameWrappers404ErrorResponse {
  data: null
  errors: GetGameWrappers404Error[]
}

/** GetGames400Error */
export interface GetGames400Error {
  code:
    | 'invalid_challenge_criteria'
    | 'invalid_challenge_filter'
    | 'invalid_filter'
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
    | 'sign_in_required'
    | 'too_many_tags'
}

/** GetGames400ErrorResponse */
export interface GetGames400ErrorResponse {
  data: null
  errors: GetGames400Error[]
}

/** GetGames403Error */
export interface GetGames403Error {
  code: 'sign_in_required'
}

/** GetGames403ErrorResponse */
export interface GetGames403ErrorResponse {
  data: null
  errors: GetGames403Error[]
}

/** GetGames404Error */
export interface GetGames404Error {
  code:
    | 'challenge_not_found'
    | 'feed_not_found'
    | 'game_engine_not_found'
    | 'game_not_found'
    | 'game_play_not_found'
    | 'publisher_not_found'
    | 'studio_not_found'
    | 'tag_not_found'
    | 'user_not_found'
}

/** GetGames404ErrorResponse */
export interface GetGames404ErrorResponse {
  data: null
  errors: GetGames404Error[]
}

export type GetGamesData = ResponseGamesResponse

export type GetGamesError =
  | GetGames400ErrorResponse
  | GetGames403ErrorResponse
  | GetGames404ErrorResponse

export type GetGamesFail = GetReviewGames400ErrorResponse | GetReviewGames403ErrorResponse

/** GetGamesGameId403Error */
export interface GetGamesGameId403Error {
  code: 'sign_in_required'
}

/** GetGamesGameId403ErrorResponse */
export interface GetGamesGameId403ErrorResponse {
  data: null
  errors: GetGamesGameId403Error[]
}

/** GetGamesGameId404Error */
export interface GetGamesGameId404Error {
  code: 'game_not_found'
}

/** GetGamesGameId404ErrorResponse */
export interface GetGamesGameId404ErrorResponse {
  data: null
  errors: GetGamesGameId404Error[]
}

/** GetGamesGameIdBanners400Error */
export interface GetGamesGameIdBanners400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdBanners400ErrorResponse */
export interface GetGamesGameIdBanners400ErrorResponse {
  data: null
  errors: GetGamesGameIdBanners400Error[]
}

/** GetGamesGameIdBanners403Error */
export interface GetGamesGameIdBanners403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdBanners403ErrorResponse */
export interface GetGamesGameIdBanners403ErrorResponse {
  data: null
  errors: GetGamesGameIdBanners403Error[]
}

/** GetGamesGameIdBanners404Error */
export interface GetGamesGameIdBanners404Error {
  code: 'game_not_found'
}

/** GetGamesGameIdBanners404ErrorResponse */
export interface GetGamesGameIdBanners404ErrorResponse {
  data: null
  errors: GetGamesGameIdBanners404Error[]
}

/** GetGamesGameIdChallenges400Error */
export interface GetGamesGameIdChallenges400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdChallenges400ErrorResponse */
export interface GetGamesGameIdChallenges400ErrorResponse {
  data: null
  errors: GetGamesGameIdChallenges400Error[]
}

/** GetGamesGameIdChallenges403Error */
export interface GetGamesGameIdChallenges403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdChallenges403ErrorResponse */
export interface GetGamesGameIdChallenges403ErrorResponse {
  data: null
  errors: GetGamesGameIdChallenges403Error[]
}

/** GetGamesGameIdChallenges404Error */
export interface GetGamesGameIdChallenges404Error {
  code: 'game_not_found'
}

/** GetGamesGameIdChallenges404ErrorResponse */
export interface GetGamesGameIdChallenges404ErrorResponse {
  data: null
  errors: GetGamesGameIdChallenges404Error[]
}

/** GetGamesGameIdControlGroups403Error */
export interface GetGamesGameIdControlGroups403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdControlGroups403ErrorResponse */
export interface GetGamesGameIdControlGroups403ErrorResponse {
  data: null
  errors: GetGamesGameIdControlGroups403Error[]
}

/** GetGamesGameIdControlGroups404Error */
export interface GetGamesGameIdControlGroups404Error {
  code: 'game_not_found'
}

/** GetGamesGameIdControlGroups404ErrorResponse */
export interface GetGamesGameIdControlGroups404ErrorResponse {
  data: null
  errors: GetGamesGameIdControlGroups404Error[]
}

/** GetGamesGameIdGamePlayStatistics403Error */
export interface GetGamesGameIdGamePlayStatistics403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdGamePlayStatistics403ErrorResponse */
export interface GetGamesGameIdGamePlayStatistics403ErrorResponse {
  data: null
  errors: GetGamesGameIdGamePlayStatistics403Error[]
}

/** GetGamesGameIdGamePlayStatistics404Error */
export interface GetGamesGameIdGamePlayStatistics404Error {
  code: 'game_not_found' | 'track_not_found'
}

/** GetGamesGameIdGamePlayStatistics404ErrorResponse */
export interface GetGamesGameIdGamePlayStatistics404ErrorResponse {
  data: null
  errors: GetGamesGameIdGamePlayStatistics404Error[]
}

/** GetGamesGameIdItems400Error */
export interface GetGamesGameIdItems400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdItems400ErrorResponse */
export interface GetGamesGameIdItems400ErrorResponse {
  data: null
  errors: GetGamesGameIdItems400Error[]
}

/** GetGamesGameIdItems403Error */
export interface GetGamesGameIdItems403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdItems403ErrorResponse */
export interface GetGamesGameIdItems403ErrorResponse {
  data: null
  errors: GetGamesGameIdItems403Error[]
}

/** GetGamesGameIdItems404Error */
export interface GetGamesGameIdItems404Error {
  code: 'game_not_found'
}

/** GetGamesGameIdItems404ErrorResponse */
export interface GetGamesGameIdItems404ErrorResponse {
  data: null
  errors: GetGamesGameIdItems404Error[]
}

/** GetGamesGameIdItemsItemId403Error */
export interface GetGamesGameIdItemsItemId403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdItemsItemId403ErrorResponse */
export interface GetGamesGameIdItemsItemId403ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemId403Error[]
}

/** GetGamesGameIdItemsItemId404Error */
export interface GetGamesGameIdItemsItemId404Error {
  code: 'game_item_not_found' | 'game_not_found'
}

/** GetGamesGameIdItemsItemId404ErrorResponse */
export interface GetGamesGameIdItemsItemId404ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemId404Error[]
}

/** GetGamesGameIdItemsItemIdClaimPeriods400Error */
export interface GetGamesGameIdItemsItemIdClaimPeriods400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdItemsItemIdClaimPeriods400ErrorResponse */
export interface GetGamesGameIdItemsItemIdClaimPeriods400ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemIdClaimPeriods400Error[]
}

/** GetGamesGameIdItemsItemIdClaimPeriods403Error */
export interface GetGamesGameIdItemsItemIdClaimPeriods403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdItemsItemIdClaimPeriods403ErrorResponse */
export interface GetGamesGameIdItemsItemIdClaimPeriods403ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemIdClaimPeriods403Error[]
}

/** GetGamesGameIdItemsItemIdClaimPeriods404Error */
export interface GetGamesGameIdItemsItemIdClaimPeriods404Error {
  code: 'game_item_not_found' | 'game_not_found'
}

/** GetGamesGameIdItemsItemIdClaimPeriods404ErrorResponse */
export interface GetGamesGameIdItemsItemIdClaimPeriods404ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemIdClaimPeriods404Error[]
}

/** GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error */
export interface GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse */
export interface GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error[]
}

/** GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error */
export interface GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error {
  code: 'game_item_claim_period_not_found' | 'game_item_not_found' | 'game_not_found'
}

/** GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse */
export interface GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse {
  data: null
  errors: GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error[]
}

/** GetGamesGameIdStatistics400Error */
export interface GetGamesGameIdStatistics400Error {
  code:
    | 'date_range_too_long'
    | 'invalid_time_frame'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdStatistics400ErrorResponse */
export interface GetGamesGameIdStatistics400ErrorResponse {
  data: null
  errors: GetGamesGameIdStatistics400Error[]
}

/** GetGamesGameIdStatistics403Error */
export interface GetGamesGameIdStatistics403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdStatistics403ErrorResponse */
export interface GetGamesGameIdStatistics403ErrorResponse {
  data: null
  errors: GetGamesGameIdStatistics403Error[]
}

/** GetGamesGameIdStatistics404Error */
export interface GetGamesGameIdStatistics404Error {
  code: 'game_not_found' | 'track_not_found'
}

/** GetGamesGameIdStatistics404ErrorResponse */
export interface GetGamesGameIdStatistics404ErrorResponse {
  data: null
  errors: GetGamesGameIdStatistics404Error[]
}

/** GetGamesGameIdTracksTrackId403Error */
export interface GetGamesGameIdTracksTrackId403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdTracksTrackId403ErrorResponse */
export interface GetGamesGameIdTracksTrackId403ErrorResponse {
  data: null
  errors: GetGamesGameIdTracksTrackId403Error[]
}

/** GetGamesGameIdTracksTrackId404Error */
export interface GetGamesGameIdTracksTrackId404Error {
  code: 'game_not_found' | 'track_not_found'
}

/** GetGamesGameIdTracksTrackId404ErrorResponse */
export interface GetGamesGameIdTracksTrackId404ErrorResponse {
  data: null
  errors: GetGamesGameIdTracksTrackId404Error[]
}

/** GetGamesGameIdTracksTrackIdReleases400Error */
export interface GetGamesGameIdTracksTrackIdReleases400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGamesGameIdTracksTrackIdReleases400ErrorResponse */
export interface GetGamesGameIdTracksTrackIdReleases400ErrorResponse {
  data: null
  errors: GetGamesGameIdTracksTrackIdReleases400Error[]
}

/** GetGamesGameIdTracksTrackIdReleases403Error */
export interface GetGamesGameIdTracksTrackIdReleases403Error {
  code: 'sign_in_required'
}

/** GetGamesGameIdTracksTrackIdReleases403ErrorResponse */
export interface GetGamesGameIdTracksTrackIdReleases403ErrorResponse {
  data: null
  errors: GetGamesGameIdTracksTrackIdReleases403Error[]
}

/** GetGamesGameIdTracksTrackIdReleases404Error */
export interface GetGamesGameIdTracksTrackIdReleases404Error {
  code: 'game_not_found' | 'track_not_found'
}

/** GetGamesGameIdTracksTrackIdReleases404ErrorResponse */
export interface GetGamesGameIdTracksTrackIdReleases404ErrorResponse {
  data: null
  errors: GetGamesGameIdTracksTrackIdReleases404Error[]
}

/** GetGamesGenerateShortId403Error */
export interface GetGamesGenerateShortId403Error {
  code: 'sign_in_required'
}

/** GetGamesGenerateShortId403ErrorResponse */
export interface GetGamesGenerateShortId403ErrorResponse {
  data: null
  errors: GetGamesGenerateShortId403Error[]
}

export interface GetGamesParams {
  /**
   * Filter games by blocked status.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  blocked?: boolean
  /**
   * Challenge ID to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   */
  challengeId?: string[]
  /** Filter games with game item claim periods in the past/at that moment/in future. */
  claimPeriodTime?: 'PAST' | 'NOW' | 'FUTURE'
  /**
   * Alias of a feed whose mods should be returned.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedAlias?: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedId?: string
  /**
   * Game engine ID to filter by. Can be used multiple times, or be a comma-separated list. Uses OR with 'usesDefaultEngine' param.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  gameEngineId?: string[]
  /**
   * Game ID to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   */
  gameId?: string[]
  /** Game play ID to filter by. Can be used multiple times, or be a comma-separated list. */
  gamePlayId?: string[]
  /**
   * Filter by games that have or doesn't have any challenges.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  hasChallenges?: boolean
  /**
   * Filter games by usage of any engine.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  hasEngine?: boolean
  /**
   * Whether the games should have a publisher or not. If present, either games with or without publishers are included, otherwise all games are included in the filtering.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  hasPublisher?: boolean
  /** Filter games by unresolved reports. ANY - reported games with any report reason,NONE - games with no unresolved reports including never reported. Priority: ANY > NONE > report reason. Can be used multiple times, or be a comma-separated list. */
  hasUnresolvedReports?: (
    | 'OFFENSIVE_MATERIAL'
    | 'MISCATEGORIZED'
    | 'UNAUTHORIZED_DISTRIBUTION'
    | 'SPAM'
    | 'OTHER'
    | 'RECONSIDERATION'
    | 'ANY'
    | 'NONE'
  )[]
  /** Filter for publication status of the internal release track */
  internalTrack?: 'published' | 'not-published'
  /**
   * Parameter to request only active state of the feed if there are pending changes.The parameter value is ignored if there are no pending changes for feed.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  loadActiveState?: boolean
  /**
   * Filter games by minimal number of plays.
   * @format int64
   */
  minPlays?: number
  /**
   * Filter games by minimal number of ratings.
   * @format int64
   */
  minRatings?: number
  /**
   * Filter games based on whether they do/don't have multiplayer.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  multiplayer?: boolean
  /**
   * Filter games by reported.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  onlyReported?: boolean
  /**
   * Include only games with no assigned tags.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  onlyWithoutTags?: boolean
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Platform to filter by. Can be used multiple times, or be a comma-separated list. */
  platform?: ('DESKTOP' | 'MOBILE')[]
  /**
   * Only include games that support/don't support preloading.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  preloadingAllowed?: boolean
  /** Filter for publication status of the public release track */
  publicTrack?: 'published' | 'not-published'
  /** Publisher ID to filter by. Can be used multiple times, or be a comma-separated list. */
  publisherId?: string[]
  /** Search query applied to the game name */
  search?: string
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `creation-date-desc`
   */
  sort?: (
    | 'average-rating-asc'
    | 'average-rating-desc'
    | 'creation-date-asc'
    | 'creation-date-desc'
    | 'feed-display-order-asc'
    | 'feed-display-order-desc'
    | 'last-modified-asc'
    | 'last-modified-desc'
    | 'latest-unresolved-report-asc'
    | 'latest-unresolved-report-desc'
    | 'longest-played-asc'
    | 'longest-played-desc'
    | 'most-recently-played-asc'
    | 'most-recently-played-desc'
    | 'most-times-played-asc'
    | 'most-times-played-desc'
    | 'title-asc'
    | 'title-desc'
    | 'total-average-playtime-asc'
    | 'total-average-playtime-desc'
    | 'total-median-playtime-asc'
    | 'total-median-playtime-desc'
    | 'total-plays-asc'
    | 'total-plays-desc'
    | 'total-reports-asc'
    | 'total-reports-desc'
    | 'total-time-played-asc'
    | 'total-time-played-desc'
    | 'total-unique-players-asc'
    | 'total-unique-players-desc'
    | 'total-unresolved-reports-asc'
    | 'total-unresolved-reports-desc'
  )[]
  /** Studio ID to filter by. Can be used multiple times, or be a comma-separated list. */
  studioId?: string[]
  /** Studio name to filter by. Can be used multiple times, or be a comma-separated list. */
  studioName?: string[]
  /** Studio name substring to filter by */
  studioNameSearch?: string
  /**
   * Tag aliases to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  tagAlias?: string[]
  /**
   * Tags to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  tagId?: string[]
  /** User email to filter by. Can be used multiple times, or be a comma-separated list. */
  userEmail?: string[]
  /** User ID to filter by. Can be used multiple times, or be a comma-separated list. */
  userId?: string[]
  /**
   * User ID to impersonate for sorting based on user play statistics.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userIdForPlayStatistics?: string
  /** Username to filter by. Can be used multiple times, or be a comma-separated list. */
  username?: string[]
  /**
   * Filter games by usage of default engine. Uses OR with 'gameEngineId' param.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  usesDefaultEngine?: boolean
  /**
   * Only include games that override/don't override the default preloading setting.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  usesDefaultPreloading?: boolean
  /** Filter by games that have challenges with the selected win criteria. Can be used multiple times, or be a comma-separated list. */
  winCriteria?: ('highest-score' | 'lowest-score' | 'highest-time' | 'lowest-time')[]
}

export interface GetGamesParams2 {
  /**
   * The page to return. The response will contain requests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of requests to include in the response. Fewer requests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type GetGamesResult = ResponseGameReviewGamesResponse

/** GetGmsRunners400Error */
export interface GetGmsRunners400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetGmsRunners400ErrorResponse */
export interface GetGmsRunners400ErrorResponse {
  data: null
  errors: GetGmsRunners400Error[]
}

/** GetGmsRunners403Error */
export interface GetGmsRunners403Error {
  code: 'sign_in_required'
}

/** GetGmsRunners403ErrorResponse */
export interface GetGmsRunners403ErrorResponse {
  data: null
  errors: GetGmsRunners403Error[]
}

/** GetItemsCurrencies403Error */
export interface GetItemsCurrencies403Error {
  code: 'sign_in_required'
}

/** GetItemsCurrencies403ErrorResponse */
export interface GetItemsCurrencies403ErrorResponse {
  data: null
  errors: GetItemsCurrencies403Error[]
}

export type GetLocalesData = ResponseLocalesResponse

export type GetLocalesError = GetStringsLocales403ErrorResponse

export type GetLoginData = any

export type GetLogoutData = ResponseVoid

export type GetLogoutError = PostSessionLogout403ErrorResponse

export type GetModDetailsData = ResponseModDetailsResponse

export type GetModDetailsError = GetModsModId403ErrorResponse | GetModsModId404ErrorResponse

export type GetModFlavorsData = ResponseModFlavorListResponse

export type GetModFlavorsError =
  | GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403ErrorResponse
  | GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404ErrorResponse

export type GetModReviewRequestsData = ResponseModReviewRequestsResponse

export type GetModReviewRequestsError =
  | GetModsModIdReviewRequests400ErrorResponse
  | GetModsModIdReviewRequests403ErrorResponse
  | GetModsModIdReviewRequests404ErrorResponse

export interface GetModReviewRequestsParams {
  /**
   * The ID of the review requested mod
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId: string
  /**
   * The page to return. The response will contain requests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of requests to include in the response. Fewer requests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `created-at-asc`
   */
  sort?: ('created-at-asc' | 'created-at-desc')[]
  /** Review requests status to filter by; if left empty, no filter is applied. */
  status?: ('UNRESOLVED' | 'RESOLVED')[]
}

export type GetModReviewsData = ResponseModReviewsResponse

export type GetModReviewsError =
  | GetModsModIdReviews400ErrorResponse
  | GetModsModIdReviews403ErrorResponse
  | GetModsModIdReviews404ErrorResponse

export interface GetModReviewsParams {
  /**
   * The ID of the review requested mod
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId: string
  /**
   * The page to return. The response will contain requests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of requests to include in the response. Fewer requests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `created-at-asc`
   */
  sort?: ('created-at-asc' | 'created-at-desc')[]
}

export type GetModTransferData = ResponseModTransferResponse

export type GetModTransferError =
  | GetModTransfersTransferId403ErrorResponse
  | GetModTransfersTransferId404ErrorResponse

/** GetModTransfers400Error */
export interface GetModTransfers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetModTransfers400ErrorResponse */
export interface GetModTransfers400ErrorResponse {
  data: null
  errors: GetModTransfers400Error[]
}

/** GetModTransfers403Error */
export interface GetModTransfers403Error {
  code: 'sign_in_required'
}

/** GetModTransfers403ErrorResponse */
export interface GetModTransfers403ErrorResponse {
  data: null
  errors: GetModTransfers403Error[]
}

/** GetModTransfers404Error */
export interface GetModTransfers404Error {
  code: 'mod_not_found' | 'studio_not_found'
}

/** GetModTransfers404ErrorResponse */
export interface GetModTransfers404ErrorResponse {
  data: null
  errors: GetModTransfers404Error[]
}

export type GetModTransfersData = ResponseModTransfersResponse

export type GetModTransfersError =
  | GetModTransfers400ErrorResponse
  | GetModTransfers403ErrorResponse
  | GetModTransfers404ErrorResponse

export interface GetModTransfersParams {
  /**
   * Only return mod transfers for this mod.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId?: string
  /**
   * Only return mod transfers with such original studio.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  originalStudioId?: string
  /**
   * The page to return. The response will contain mod transfers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod transfers to include in the response. Fewer mod transfers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Filter mod transfers by status. Can be used multiple times, or be a comma-separated list. */
  status?: ('ABORTED' | 'APPROVED' | 'EXPIRED' | 'PENDING' | 'REJECTED')[]
  /**
   * Only return mod transfers with such target studio.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  targetStudioId?: string
}

/** GetModTransfersTransferId403Error */
export interface GetModTransfersTransferId403Error {
  code: 'sign_in_required'
}

/** GetModTransfersTransferId403ErrorResponse */
export interface GetModTransfersTransferId403ErrorResponse {
  data: null
  errors: GetModTransfersTransferId403Error[]
}

/** GetModTransfersTransferId404Error */
export interface GetModTransfersTransferId404Error {
  code: 'transfer_not_found'
}

/** GetModTransfersTransferId404ErrorResponse */
export interface GetModTransfersTransferId404ErrorResponse {
  data: null
  errors: GetModTransfersTransferId404Error[]
}

export type GetModUserFeedbackData = ResponseUserFeedbackResponse

export type GetModUserFeedbackError =
  | GetModsModIdUserFeedbackFeedbackId403ErrorResponse
  | GetModsModIdUserFeedbackFeedbackId404ErrorResponse

export type GetModUserFeedbacksData = ResponseModUserFeedbackListResponse

export type GetModUserFeedbacksError =
  | GetModsModIdUserFeedback400ErrorResponse
  | GetModsModIdUserFeedback403ErrorResponse
  | GetModsModIdUserFeedback404ErrorResponse

export interface GetModUserFeedbacksParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId: string
  /**
   * The page to return. The response will contain mod feedbacks in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod feedbacks to include in the response. Fewer mod feedbacks may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Feedback status to filter by; if left empty, no filter is applied. */
  status?: ('UNRESOLVED' | 'RESOLVED')[]
  /** Feedback type to filter by; if left empty, no filter is applied. */
  type?: ('BUG_REPORT' | 'FEATURE_REQUEST')[]
}

/** GetMods400Error */
export interface GetMods400Error {
  code:
    | 'invalid_filter'
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
    | 'too_many_tags'
}

/** GetMods400ErrorResponse */
export interface GetMods400ErrorResponse {
  data: null
  errors: GetMods400Error[]
}

/** GetMods403Error */
export interface GetMods403Error {
  code: 'sign_in_required'
}

/** GetMods403ErrorResponse */
export interface GetMods403ErrorResponse {
  data: null
  errors: GetMods403Error[]
}

/** GetMods404Error */
export interface GetMods404Error {
  code: 'feed_not_found' | 'studio_not_found' | 'tag_not_found'
}

/** GetMods404ErrorResponse */
export interface GetMods404ErrorResponse {
  data: null
  errors: GetMods404Error[]
}

export type GetModsData = ResponseModsResponse

export type GetModsError =
  | GetMods400ErrorResponse
  | GetMods403ErrorResponse
  | GetMods404ErrorResponse

export type GetModsFail =
  | GetReviewRequestsMods400ErrorResponse
  | GetReviewRequestsMods403ErrorResponse

/** GetModsLiveWallpaperSetting403Error */
export interface GetModsLiveWallpaperSetting403Error {
  code: 'sign_in_required'
}

/** GetModsLiveWallpaperSetting403ErrorResponse */
export interface GetModsLiveWallpaperSetting403ErrorResponse {
  data: null
  errors: GetModsLiveWallpaperSetting403Error[]
}

/** GetModsModId403Error */
export interface GetModsModId403Error {
  code: 'sign_in_required'
}

/** GetModsModId403ErrorResponse */
export interface GetModsModId403ErrorResponse {
  data: null
  errors: GetModsModId403Error[]
}

/** GetModsModId404Error */
export interface GetModsModId404Error {
  code: 'mod_not_found'
}

/** GetModsModId404ErrorResponse */
export interface GetModsModId404ErrorResponse {
  data: null
  errors: GetModsModId404Error[]
}

/** GetModsModIdReviewRequests400Error */
export interface GetModsModIdReviewRequests400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetModsModIdReviewRequests400ErrorResponse */
export interface GetModsModIdReviewRequests400ErrorResponse {
  data: null
  errors: GetModsModIdReviewRequests400Error[]
}

/** GetModsModIdReviewRequests403Error */
export interface GetModsModIdReviewRequests403Error {
  code: 'sign_in_required'
}

/** GetModsModIdReviewRequests403ErrorResponse */
export interface GetModsModIdReviewRequests403ErrorResponse {
  data: null
  errors: GetModsModIdReviewRequests403Error[]
}

/** GetModsModIdReviewRequests404Error */
export interface GetModsModIdReviewRequests404Error {
  code: 'mod_not_found'
}

/** GetModsModIdReviewRequests404ErrorResponse */
export interface GetModsModIdReviewRequests404ErrorResponse {
  data: null
  errors: GetModsModIdReviewRequests404Error[]
}

/** GetModsModIdReviews400Error */
export interface GetModsModIdReviews400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetModsModIdReviews400ErrorResponse */
export interface GetModsModIdReviews400ErrorResponse {
  data: null
  errors: GetModsModIdReviews400Error[]
}

/** GetModsModIdReviews403Error */
export interface GetModsModIdReviews403Error {
  code: 'sign_in_required'
}

/** GetModsModIdReviews403ErrorResponse */
export interface GetModsModIdReviews403ErrorResponse {
  data: null
  errors: GetModsModIdReviews403Error[]
}

/** GetModsModIdReviews404Error */
export interface GetModsModIdReviews404Error {
  code: 'mod_not_found'
}

/** GetModsModIdReviews404ErrorResponse */
export interface GetModsModIdReviews404ErrorResponse {
  data: null
  errors: GetModsModIdReviews404Error[]
}

/** GetModsModIdTracksTrackIdReleases400Error */
export interface GetModsModIdTracksTrackIdReleases400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetModsModIdTracksTrackIdReleases400ErrorResponse */
export interface GetModsModIdTracksTrackIdReleases400ErrorResponse {
  data: null
  errors: GetModsModIdTracksTrackIdReleases400Error[]
}

/** GetModsModIdTracksTrackIdReleases403Error */
export interface GetModsModIdTracksTrackIdReleases403Error {
  code: 'sign_in_required'
}

/** GetModsModIdTracksTrackIdReleases403ErrorResponse */
export interface GetModsModIdTracksTrackIdReleases403ErrorResponse {
  data: null
  errors: GetModsModIdTracksTrackIdReleases403Error[]
}

/** GetModsModIdTracksTrackIdReleases404Error */
export interface GetModsModIdTracksTrackIdReleases404Error {
  code: 'mod_not_found' | 'track_not_found'
}

/** GetModsModIdTracksTrackIdReleases404ErrorResponse */
export interface GetModsModIdTracksTrackIdReleases404ErrorResponse {
  data: null
  errors: GetModsModIdTracksTrackIdReleases404Error[]
}

/** GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403Error */
export interface GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403Error {
  code: 'sign_in_required'
}

/** GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403ErrorResponse */
export interface GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403ErrorResponse {
  data: null
  errors: GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403Error[]
}

/** GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404Error */
export interface GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404Error {
  code: 'mod_not_found' | 'release_not_found' | 'track_not_found'
}

/** GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404ErrorResponse */
export interface GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404ErrorResponse {
  data: null
  errors: GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404Error[]
}

/** GetModsModIdUserFeedback400Error */
export interface GetModsModIdUserFeedback400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetModsModIdUserFeedback400ErrorResponse */
export interface GetModsModIdUserFeedback400ErrorResponse {
  data: null
  errors: GetModsModIdUserFeedback400Error[]
}

/** GetModsModIdUserFeedback403Error */
export interface GetModsModIdUserFeedback403Error {
  code: 'sign_in_required'
}

/** GetModsModIdUserFeedback403ErrorResponse */
export interface GetModsModIdUserFeedback403ErrorResponse {
  data: null
  errors: GetModsModIdUserFeedback403Error[]
}

/** GetModsModIdUserFeedback404Error */
export interface GetModsModIdUserFeedback404Error {
  code: 'mod_not_found'
}

/** GetModsModIdUserFeedback404ErrorResponse */
export interface GetModsModIdUserFeedback404ErrorResponse {
  data: null
  errors: GetModsModIdUserFeedback404Error[]
}

/** GetModsModIdUserFeedbackFeedbackId403Error */
export interface GetModsModIdUserFeedbackFeedbackId403Error {
  code: 'sign_in_required'
}

/** GetModsModIdUserFeedbackFeedbackId403ErrorResponse */
export interface GetModsModIdUserFeedbackFeedbackId403ErrorResponse {
  data: null
  errors: GetModsModIdUserFeedbackFeedbackId403Error[]
}

/** GetModsModIdUserFeedbackFeedbackId404Error */
export interface GetModsModIdUserFeedbackFeedbackId404Error {
  code: 'mod_not_found' | 'user_feedback_not_found'
}

/** GetModsModIdUserFeedbackFeedbackId404ErrorResponse */
export interface GetModsModIdUserFeedbackFeedbackId404ErrorResponse {
  data: null
  errors: GetModsModIdUserFeedbackFeedbackId404Error[]
}

export interface GetModsParams {
  /**
   * Filter mods by blocked status.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  blocked?: boolean
  /** Crx ID substring to filter by */
  crxIdSearch?: string
  /**
   * Alias of a feed whose mods should be returned.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedAlias?: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  feedId?: string
  /** Filter mods by unresolved reports. ANY - reported mods with any report reason,NONE - mods with no unresolved reports including never reported. Priority: ANY > NONE > report reasonCan be used multiple times, or be a comma-separated list. OTHER gets ignored. */
  hasUnresolvedReports?: (
    | 'ANY'
    | 'OFFENSIVE_MATERIAL'
    | 'MISCATEGORIZED'
    | 'NONE'
    | 'UNAUTHORIZED_DISTRIBUTION'
    | 'SPAM'
    | 'RECONSIDERATION'
    | 'OTHER'
  )[]
  /** Filter for publication status of the internal release track */
  internalTrack?: 'published' | 'not-published'
  /**
   * Parameter to request only active state of the feed if there are pending changes.The parameter value is ignored if there are no pending changes for feed.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  loadActiveState?: boolean
  /** Mod ID substring to filter by */
  modIdSearch?: string
  /**
   * If enabled, only mods with more than one type are included.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  modKit?: boolean
  /** Mod short ID substring to filter by */
  modShortIdSearch?: string
  /**
   * Include only mods which have current release with failed diagnostics on any track.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  onlyDiagnosticsFailed?: boolean
  /**
   * Filter mods by reported.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  onlyReported?: boolean
  /**
   * The page to return. The response will contain mods in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mods to include in the response. Fewer mods may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Filter for publication status of the public release track */
  publicTrack?: 'published' | 'not-published'
  /** Filter for by runner version name */
  runnerVersionName?: string
  /** Search query applied to the mod title */
  search?: string
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `title-asc`
   */
  sort?: (
    | 'creation-date-asc'
    | 'creation-date-desc'
    | 'feed-display-order-asc'
    | 'feed-display-order-desc'
    | 'last-modified-asc'
    | 'last-modified-desc'
    | 'latest-unresolved-report-asc'
    | 'latest-unresolved-report-desc'
    | 'title-asc'
    | 'title-desc'
    | 'total-downloads-asc'
    | 'total-downloads-desc'
    | 'total-reports-asc'
    | 'total-reports-desc'
    | 'total-unresolved-reports-asc'
    | 'total-unresolved-reports-desc'
  )[]
  /** Studio ID to filter by. Can be used multiple times, or be a comma-separated list. */
  studioId?: string[]
  /** Studio name to filter by. Can be used multiple times, or be a comma-separated list. */
  studioName?: string[]
  /** Studio name substring to filter by */
  studioNameSearch?: string
  /**
   * Tag alias to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  tagAlias?: string[]
  /**
   * Tag ID to filter by. Can be used multiple times, or be a comma-separated list.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  tagId?: string[]
}

export interface GetModsParams2 {
  /**
   * The page to return. The response will contain mods in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mods to include in the response. Fewer mods may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type GetModsResult = ResponseModsResponse

export type GetModsSettingsData = ResponseLWThemesSettingsResponse

export type GetModsSettingsError = GetModsLiveWallpaperSetting403ErrorResponse

/** GetMultiplayerGuestAvatars400Error */
export interface GetMultiplayerGuestAvatars400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetMultiplayerGuestAvatars400ErrorResponse */
export interface GetMultiplayerGuestAvatars400ErrorResponse {
  data: null
  errors: GetMultiplayerGuestAvatars400Error[]
}

/** GetMultiplayerGuestAvatars403Error */
export interface GetMultiplayerGuestAvatars403Error {
  code: 'sign_in_required'
}

/** GetMultiplayerGuestAvatars403ErrorResponse */
export interface GetMultiplayerGuestAvatars403ErrorResponse {
  data: null
  errors: GetMultiplayerGuestAvatars403Error[]
}

/** GetMultiplayerRelays400Error */
export interface GetMultiplayerRelays400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetMultiplayerRelays400ErrorResponse */
export interface GetMultiplayerRelays400ErrorResponse {
  data: null
  errors: GetMultiplayerRelays400Error[]
}

/** GetMultiplayerRelays403Error */
export interface GetMultiplayerRelays403Error {
  code: 'sign_in_required'
}

/** GetMultiplayerRelays403ErrorResponse */
export interface GetMultiplayerRelays403ErrorResponse {
  data: null
  errors: GetMultiplayerRelays403Error[]
}

/** GetOdks400Error */
export interface GetOdks400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOdks400ErrorResponse */
export interface GetOdks400ErrorResponse {
  data: null
  errors: GetOdks400Error[]
}

/** GetOdks403Error */
export interface GetOdks403Error {
  code: 'sign_in_required'
}

/** GetOdks403ErrorResponse */
export interface GetOdks403ErrorResponse {
  data: null
  errors: GetOdks403Error[]
}

/** GetOdksKeyId403Error */
export interface GetOdksKeyId403Error {
  code: 'sign_in_required'
}

/** GetOdksKeyId403ErrorResponse */
export interface GetOdksKeyId403ErrorResponse {
  data: null
  errors: GetOdksKeyId403Error[]
}

/** GetOdksKeyId404Error */
export interface GetOdksKeyId404Error {
  code: 'official_distribution_key_not_found'
}

/** GetOdksKeyId404ErrorResponse */
export interface GetOdksKeyId404ErrorResponse {
  data: null
  errors: GetOdksKeyId404Error[]
}

/** GetOdksKeyIdMods400Error */
export interface GetOdksKeyIdMods400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOdksKeyIdMods400ErrorResponse */
export interface GetOdksKeyIdMods400ErrorResponse {
  data: null
  errors: GetOdksKeyIdMods400Error[]
}

/** GetOdksKeyIdMods403Error */
export interface GetOdksKeyIdMods403Error {
  code: 'sign_in_required'
}

/** GetOdksKeyIdMods403ErrorResponse */
export interface GetOdksKeyIdMods403ErrorResponse {
  data: null
  errors: GetOdksKeyIdMods403Error[]
}

/** GetOdksKeyIdMods404Error */
export interface GetOdksKeyIdMods404Error {
  code: 'official_distribution_key_not_found'
}

/** GetOdksKeyIdMods404ErrorResponse */
export interface GetOdksKeyIdMods404ErrorResponse {
  data: null
  errors: GetOdksKeyIdMods404Error[]
}

/** GetOdksKeyIdStudios400Error */
export interface GetOdksKeyIdStudios400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOdksKeyIdStudios400ErrorResponse */
export interface GetOdksKeyIdStudios400ErrorResponse {
  data: null
  errors: GetOdksKeyIdStudios400Error[]
}

/** GetOdksKeyIdStudios403Error */
export interface GetOdksKeyIdStudios403Error {
  code: 'sign_in_required'
}

/** GetOdksKeyIdStudios403ErrorResponse */
export interface GetOdksKeyIdStudios403ErrorResponse {
  data: null
  errors: GetOdksKeyIdStudios403Error[]
}

/** GetOdksKeyIdStudios404Error */
export interface GetOdksKeyIdStudios404Error {
  code: 'official_distribution_key_not_found'
}

/** GetOdksKeyIdStudios404ErrorResponse */
export interface GetOdksKeyIdStudios404ErrorResponse {
  data: null
  errors: GetOdksKeyIdStudios404Error[]
}

export type GetOfferDetailsData = ResponseOfferResponse

export type GetOfferDetailsError =
  | GetOffersOfferId403ErrorResponse
  | GetOffersOfferId404ErrorResponse

/** GetOfferPartners400Error */
export interface GetOfferPartners400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOfferPartners400ErrorResponse */
export interface GetOfferPartners400ErrorResponse {
  data: null
  errors: GetOfferPartners400Error[]
}

/** GetOfferPartners403Error */
export interface GetOfferPartners403Error {
  code: 'sign_in_required'
}

/** GetOfferPartners403ErrorResponse */
export interface GetOfferPartners403ErrorResponse {
  data: null
  errors: GetOfferPartners403Error[]
}

/** GetOffers400Error */
export interface GetOffers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOffers400ErrorResponse */
export interface GetOffers400ErrorResponse {
  data: null
  errors: GetOffers400Error[]
}

/** GetOffers403Error */
export interface GetOffers403Error {
  code: 'sign_in_required'
}

/** GetOffers403ErrorResponse */
export interface GetOffers403ErrorResponse {
  data: null
  errors: GetOffers403Error[]
}

/** GetOffers404Error */
export interface GetOffers404Error {
  code: 'partner_not_found'
}

/** GetOffers404ErrorResponse */
export interface GetOffers404ErrorResponse {
  data: null
  errors: GetOffers404Error[]
}

/** GetOffersOfferId403Error */
export interface GetOffersOfferId403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferId403ErrorResponse */
export interface GetOffersOfferId403ErrorResponse {
  data: null
  errors: GetOffersOfferId403Error[]
}

/** GetOffersOfferId404Error */
export interface GetOffersOfferId404Error {
  code: 'offer_not_found'
}

/** GetOffersOfferId404ErrorResponse */
export interface GetOffersOfferId404ErrorResponse {
  data: null
  errors: GetOffersOfferId404Error[]
}

/** GetOffersOfferIdCodes400Error */
export interface GetOffersOfferIdCodes400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOffersOfferIdCodes400ErrorResponse */
export interface GetOffersOfferIdCodes400ErrorResponse {
  data: null
  errors: GetOffersOfferIdCodes400Error[]
}

/** GetOffersOfferIdCodes403Error */
export interface GetOffersOfferIdCodes403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferIdCodes403ErrorResponse */
export interface GetOffersOfferIdCodes403ErrorResponse {
  data: null
  errors: GetOffersOfferIdCodes403Error[]
}

/** GetOffersOfferIdCodes404Error */
export interface GetOffersOfferIdCodes404Error {
  code: 'offer_not_found'
}

/** GetOffersOfferIdCodes404ErrorResponse */
export interface GetOffersOfferIdCodes404ErrorResponse {
  data: null
  errors: GetOffersOfferIdCodes404Error[]
}

/** GetOffersOfferIdCodesCodeId403Error */
export interface GetOffersOfferIdCodesCodeId403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferIdCodesCodeId403ErrorResponse */
export interface GetOffersOfferIdCodesCodeId403ErrorResponse {
  data: null
  errors: GetOffersOfferIdCodesCodeId403Error[]
}

/** GetOffersOfferIdCodesCodeId404Error */
export interface GetOffersOfferIdCodesCodeId404Error {
  code: 'offer_code_not_found' | 'offer_not_found'
}

/** GetOffersOfferIdCodesCodeId404ErrorResponse */
export interface GetOffersOfferIdCodesCodeId404ErrorResponse {
  data: null
  errors: GetOffersOfferIdCodesCodeId404Error[]
}

/** GetOffersOfferIdErrorPages400Error */
export interface GetOffersOfferIdErrorPages400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetOffersOfferIdErrorPages400ErrorResponse */
export interface GetOffersOfferIdErrorPages400ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPages400Error[]
}

/** GetOffersOfferIdErrorPages403Error */
export interface GetOffersOfferIdErrorPages403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferIdErrorPages403ErrorResponse */
export interface GetOffersOfferIdErrorPages403ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPages403Error[]
}

/** GetOffersOfferIdErrorPages404Error */
export interface GetOffersOfferIdErrorPages404Error {
  code: 'offer_not_found'
}

/** GetOffersOfferIdErrorPages404ErrorResponse */
export interface GetOffersOfferIdErrorPages404ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPages404Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageId403Error */
export interface GetOffersOfferIdErrorPagesErrorPageId403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferIdErrorPagesErrorPageId403ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageId403ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageId403Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageId404Error */
export interface GetOffersOfferIdErrorPagesErrorPageId404Error {
  code: 'offer_error_page_not_found' | 'offer_not_found'
}

/** GetOffersOfferIdErrorPagesErrorPageId404ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageId404ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageId404Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent400Error */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent400Error {
  code: 'offer_error_page_content_not_uploaded'
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageIdContent400Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent403Error */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent403Error {
  code: 'sign_in_required'
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageIdContent403Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent404Error */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent404Error {
  code: 'offer_error_page_not_found' | 'offer_not_found'
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageIdContent404Error[]
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent500Error */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent500Error {
  code: 'internal_server_error'
}

/** GetOffersOfferIdErrorPagesErrorPageIdContent500ErrorResponse */
export interface GetOffersOfferIdErrorPagesErrorPageIdContent500ErrorResponse {
  data: null
  errors: GetOffersOfferIdErrorPagesErrorPageIdContent500Error[]
}

export type GetOfficialDistributionKeyData = ResponseOdkDetailsResponse

export type GetOfficialDistributionKeyError =
  | GetOdksKeyId403ErrorResponse
  | GetOdksKeyId404ErrorResponse

export type GetOverallStatisticsData = ResponseGamePlayStatisticsResponse

export type GetOverallStatisticsError = GetGamePlayStatistics403ErrorResponse

export interface GetOverallStatisticsParams {
  /**
   * Last day of time period of interest, defaults to today
   * @format date
   * @example "2021-10-05"
   */
  endDate?: string
  /**
   * First day of time period of interest
   * @format date
   * @example "2021-09-29"
   */
  startDate?: string
}

/** GetPartners400Error */
export interface GetPartners400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPartners400ErrorResponse */
export interface GetPartners400ErrorResponse {
  data: null
  errors: GetPartners400Error[]
}

/** GetPartners403Error */
export interface GetPartners403Error {
  code: 'sign_in_required'
}

/** GetPartners403ErrorResponse */
export interface GetPartners403ErrorResponse {
  data: null
  errors: GetPartners403Error[]
}

export type GetPendingVideoUploadsData = ResponseVideoUploadsResponse

export type GetPendingVideoUploadsError = GetChallengesScoresPendingVideoUploads403ErrorResponse

export type GetPerGameStatisticsData = ResponseGamePlayStatisticsResponse

export type GetPerGameStatisticsError =
  | GetGamesGameIdGamePlayStatistics403ErrorResponse
  | GetGamesGameIdGamePlayStatistics404ErrorResponse

export interface GetPerGameStatisticsParams {
  /**
   * Last day of time period of interest, defaults to today
   * @format date
   * @example "2021-10-05"
   */
  endDate?: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * First day of time period of interest
   * @format date
   * @example "2021-09-29"
   */
  startDate?: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  trackId?: string
}

/** GetProfile403Error */
export interface GetProfile403Error {
  code: 'sign_in_required'
}

/** GetProfile403ErrorResponse */
export interface GetProfile403ErrorResponse {
  data: null
  errors: GetProfile403Error[]
}

export type GetProfileData = ResponseProfileResponse

export type GetProfileError = GetProfile403ErrorResponse

export type GetPromotedChallengeData = ResponsePromotedChallengeResponse

export type GetPromotedChallengeError =
  | GetPromotedChallengesPromotedChallengeId403ErrorResponse
  | GetPromotedChallengesPromotedChallengeId404ErrorResponse

/** GetPromotedChallenges403Error */
export interface GetPromotedChallenges403Error {
  code: 'sign_in_required'
}

/** GetPromotedChallenges403ErrorResponse */
export interface GetPromotedChallenges403ErrorResponse {
  data: null
  errors: GetPromotedChallenges403Error[]
}

/** GetPromotedChallenges404Error */
export interface GetPromotedChallenges404Error {
  code: 'season_not_found'
}

/** GetPromotedChallenges404ErrorResponse */
export interface GetPromotedChallenges404ErrorResponse {
  data: null
  errors: GetPromotedChallenges404Error[]
}

export type GetPromotedChallengesData = ResponsePromotedChallengesResponse

export type GetPromotedChallengesError =
  | GetPromotedChallenges403ErrorResponse
  | GetPromotedChallenges404ErrorResponse

export interface GetPromotedChallengesParams {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  seasonId?: string
}

/** GetPromotedChallengesPromotedChallengeId403Error */
export interface GetPromotedChallengesPromotedChallengeId403Error {
  code: 'sign_in_required'
}

/** GetPromotedChallengesPromotedChallengeId403ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeId403ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeId403Error[]
}

/** GetPromotedChallengesPromotedChallengeId404Error */
export interface GetPromotedChallengesPromotedChallengeId404Error {
  code: 'promoted_challenge_not_found'
}

/** GetPromotedChallengesPromotedChallengeId404ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeId404ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeId404Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications400Error */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications400ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications400ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScoreVerifications400Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications403Error */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications403Error {
  code: 'sign_in_required'
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications403ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications403ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScoreVerifications403Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications404Error */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications404Error {
  code: 'promoted_challenge_not_found'
}

/** GetPromotedChallengesPromotedChallengeIdScoreVerifications404ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScoreVerifications404ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScoreVerifications404Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScores400Error */
export interface GetPromotedChallengesPromotedChallengeIdScores400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPromotedChallengesPromotedChallengeIdScores400ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScores400ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScores400Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScores403Error */
export interface GetPromotedChallengesPromotedChallengeIdScores403Error {
  code: 'sign_in_required'
}

/** GetPromotedChallengesPromotedChallengeIdScores403ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScores403ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScores403Error[]
}

/** GetPromotedChallengesPromotedChallengeIdScores404Error */
export interface GetPromotedChallengesPromotedChallengeIdScores404Error {
  code: 'promoted_challenge_not_found'
}

/** GetPromotedChallengesPromotedChallengeIdScores404ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdScores404ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdScores404Error[]
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId400Error */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId400ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId400ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdUserScoresUserId400Error[]
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId403Error */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId403Error {
  code: 'sign_in_required'
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId403ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId403ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdUserScoresUserId403Error[]
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId404Error */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId404Error {
  code: 'promoted_challenge_not_found' | 'user_not_found'
}

/** GetPromotedChallengesPromotedChallengeIdUserScoresUserId404ErrorResponse */
export interface GetPromotedChallengesPromotedChallengeIdUserScoresUserId404ErrorResponse {
  data: null
  errors: GetPromotedChallengesPromotedChallengeIdUserScoresUserId404Error[]
}

export type GetPublisherDetailsData = ResponsePublisherDetailsResponse

export type GetPublisherDetailsError =
  | GetPublishersPublisherId403ErrorResponse
  | GetPublishersPublisherId404ErrorResponse

/** GetPublishers400Error */
export interface GetPublishers400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPublishers400ErrorResponse */
export interface GetPublishers400ErrorResponse {
  data: null
  errors: GetPublishers400Error[]
}

/** GetPublishers403Error */
export interface GetPublishers403Error {
  code: 'sign_in_required'
}

/** GetPublishers403ErrorResponse */
export interface GetPublishers403ErrorResponse {
  data: null
  errors: GetPublishers403Error[]
}

export type GetPublishersData = ResponseListPublishersResponse

export type GetPublishersError = GetPublishers400ErrorResponse | GetPublishers403ErrorResponse

export interface GetPublishersParams {
  /**
   * The page to return. The response will contain publishers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of publishers to include in the response. Fewer publishers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `title-asc`
   */
  sort?: ('title-asc' | 'title-desc')[]
}

/** GetPublishersPublisherId403Error */
export interface GetPublishersPublisherId403Error {
  code: 'sign_in_required'
}

/** GetPublishersPublisherId403ErrorResponse */
export interface GetPublishersPublisherId403ErrorResponse {
  data: null
  errors: GetPublishersPublisherId403Error[]
}

/** GetPublishersPublisherId404Error */
export interface GetPublishersPublisherId404Error {
  code: 'publisher_not_found'
}

/** GetPublishersPublisherId404ErrorResponse */
export interface GetPublishersPublisherId404ErrorResponse {
  data: null
  errors: GetPublishersPublisherId404Error[]
}

/** GetPurchases400Error */
export interface GetPurchases400Error {
  code:
    | 'invalid_game_item_type'
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetPurchases400ErrorResponse */
export interface GetPurchases400ErrorResponse {
  data: null
  errors: GetPurchases400Error[]
}

/** GetPurchases403Error */
export interface GetPurchases403Error {
  code: 'sign_in_required'
}

/** GetPurchases403ErrorResponse */
export interface GetPurchases403ErrorResponse {
  data: null
  errors: GetPurchases403Error[]
}

/** GetPurchases404Error */
export interface GetPurchases404Error {
  code: 'game_not_found' | 'transaction_not_found' | 'user_not_found'
}

/** GetPurchases404ErrorResponse */
export interface GetPurchases404ErrorResponse {
  data: null
  errors: GetPurchases404Error[]
}

/** GetPurchasesPurchaseId403Error */
export interface GetPurchasesPurchaseId403Error {
  code: 'sign_in_required'
}

/** GetPurchasesPurchaseId403ErrorResponse */
export interface GetPurchasesPurchaseId403ErrorResponse {
  data: null
  errors: GetPurchasesPurchaseId403Error[]
}

/** GetPurchasesPurchaseId404Error */
export interface GetPurchasesPurchaseId404Error {
  code: 'purchase_not_found'
}

/** GetPurchasesPurchaseId404ErrorResponse */
export interface GetPurchasesPurchaseId404ErrorResponse {
  data: null
  errors: GetPurchasesPurchaseId404Error[]
}

/** GetQuestCollections400Error */
export interface GetQuestCollections400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetQuestCollections400ErrorResponse */
export interface GetQuestCollections400ErrorResponse {
  data: null
  errors: GetQuestCollections400Error[]
}

/** GetQuestCollections403Error */
export interface GetQuestCollections403Error {
  code: 'sign_in_required'
}

/** GetQuestCollections403ErrorResponse */
export interface GetQuestCollections403ErrorResponse {
  data: null
  errors: GetQuestCollections403Error[]
}

export type GetQuestCollectionsData = ResponseQuestCollectionsResponse

export type GetQuestCollectionsError =
  | GetQuestCollections400ErrorResponse
  | GetQuestCollections403ErrorResponse

export interface GetQuestCollectionsParams {
  /** @default "ANY" */
  activeState?: 'ANY' | 'ACTIVE' | 'NOT_ACTIVE'
  aliasSearch?: string
  /**
   * The page to return. The response will contain quest collections in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of quest collections to include in the response. Fewer quest collections may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type GetQuestDetailsData = ResponseQuestResponse

export type GetQuestDetailsError =
  | GetQuestsQuestId403ErrorResponse
  | GetQuestsQuestId404ErrorResponse

/** GetQuests400Error */
export interface GetQuests400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetQuests400ErrorResponse */
export interface GetQuests400ErrorResponse {
  data: null
  errors: GetQuests400Error[]
}

/** GetQuests403Error */
export interface GetQuests403Error {
  code: 'sign_in_required'
}

/** GetQuests403ErrorResponse */
export interface GetQuests403ErrorResponse {
  data: null
  errors: GetQuests403Error[]
}

/** GetQuests404Error */
export interface GetQuests404Error {
  code: 'quest_collection_not_found'
}

/** GetQuests404ErrorResponse */
export interface GetQuests404ErrorResponse {
  data: null
  errors: GetQuests404Error[]
}

export type GetQuestsData = ResponseQuestListResponse

export type GetQuestsError =
  | GetQuests400ErrorResponse
  | GetQuests403ErrorResponse
  | GetQuests404ErrorResponse

export interface GetQuestsParams {
  /** @default "ANY" */
  activeState?: 'ANY' | 'ACTIVE' | 'NOT_ACTIVE'
  aliasSearch?: string
  /**
   * Quest Collection ID to filter by. Can be used multiple times, or be a comma-separated list. If set 'partOfAnyCollection' will be ignored. Also default sorting will be 'collection-order-desc'.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  collectionId?: string[]
  /**
   * The page to return. The response will contain quests in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of quests to include in the response. Fewer quests may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Whether to look for quests that are in a collection or not in any collection
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  partOfAnyCollection?: boolean
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `created-at-desc`
   */
  sort?: ('collection-order-asc' | 'collection-order-desc' | 'created-at-asc' | 'created-at-desc')[]
}

/** GetQuestsQuestId403Error */
export interface GetQuestsQuestId403Error {
  code: 'sign_in_required'
}

/** GetQuestsQuestId403ErrorResponse */
export interface GetQuestsQuestId403ErrorResponse {
  data: null
  errors: GetQuestsQuestId403Error[]
}

/** GetQuestsQuestId404Error */
export interface GetQuestsQuestId404Error {
  code: 'quest_not_found'
}

/** GetQuestsQuestId404ErrorResponse */
export interface GetQuestsQuestId404ErrorResponse {
  data: null
  errors: GetQuestsQuestId404Error[]
}

/** GetQuestsUsersUserId403Error */
export interface GetQuestsUsersUserId403Error {
  code: 'sign_in_required'
}

/** GetQuestsUsersUserId403ErrorResponse */
export interface GetQuestsUsersUserId403ErrorResponse {
  data: null
  errors: GetQuestsUsersUserId403Error[]
}

/** GetQuestsUsersUserId404Error */
export interface GetQuestsUsersUserId404Error {
  code: 'user_not_found'
}

/** GetQuestsUsersUserId404ErrorResponse */
export interface GetQuestsUsersUserId404ErrorResponse {
  data: null
  errors: GetQuestsUsersUserId404Error[]
}

export type GetRedirectData = any

export type GetRedirectError = GetSessionRedirect403ErrorResponse

/** GetRefunds400Error */
export interface GetRefunds400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetRefunds400ErrorResponse */
export interface GetRefunds400ErrorResponse {
  data: null
  errors: GetRefunds400Error[]
}

/** GetRefunds403Error */
export interface GetRefunds403Error {
  code: 'sign_in_required'
}

/** GetRefunds403ErrorResponse */
export interface GetRefunds403ErrorResponse {
  data: null
  errors: GetRefunds403Error[]
}

/** GetRefunds404Error */
export interface GetRefunds404Error {
  code: 'game_not_found' | 'transaction_not_found' | 'user_not_found'
}

/** GetRefunds404ErrorResponse */
export interface GetRefunds404ErrorResponse {
  data: null
  errors: GetRefunds404Error[]
}

export type GetRefundsDetailsData = ResponseRefundsResponse

export type GetRefundsDetailsError =
  | GetRefunds400ErrorResponse
  | GetRefunds403ErrorResponse
  | GetRefunds404ErrorResponse

export interface GetRefundsDetailsParams {
  /** User email to filter by. */
  email?: string
  /**
   * Game ID to filter by.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId?: string
  /**
   * The page to return. The response will contain refunds in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of refunds to include in the response. Fewer refunds may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Original transaction ID for a refund to filter by.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  paymentTransactionId?: string
  /**
   * User ID to filter by.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId?: string
  /** Username to filter by. */
  username?: string
}

/** GetRespectPayments400Error */
export interface GetRespectPayments400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetRespectPayments400ErrorResponse */
export interface GetRespectPayments400ErrorResponse {
  data: null
  errors: GetRespectPayments400Error[]
}

/** GetRespectPayments403Error */
export interface GetRespectPayments403Error {
  code: 'sign_in_required'
}

/** GetRespectPayments403ErrorResponse */
export interface GetRespectPayments403ErrorResponse {
  data: null
  errors: GetRespectPayments403Error[]
}

/** GetRespectPayments404Error */
export interface GetRespectPayments404Error {
  code: 'game_not_found' | 'user_not_found'
}

/** GetRespectPayments404ErrorResponse */
export interface GetRespectPayments404ErrorResponse {
  data: null
  errors: GetRespectPayments404Error[]
}

/** GetReviewGames400Error */
export interface GetReviewGames400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetReviewGames400ErrorResponse */
export interface GetReviewGames400ErrorResponse {
  data: null
  errors: GetReviewGames400Error[]
}

/** GetReviewGames403Error */
export interface GetReviewGames403Error {
  code: 'sign_in_required'
}

/** GetReviewGames403ErrorResponse */
export interface GetReviewGames403ErrorResponse {
  data: null
  errors: GetReviewGames403Error[]
}

/** GetReviewGamesGameIdReviewRequests400Error */
export interface GetReviewGamesGameIdReviewRequests400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetReviewGamesGameIdReviewRequests400ErrorResponse */
export interface GetReviewGamesGameIdReviewRequests400ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviewRequests400Error[]
}

/** GetReviewGamesGameIdReviewRequests403Error */
export interface GetReviewGamesGameIdReviewRequests403Error {
  code: 'sign_in_required'
}

/** GetReviewGamesGameIdReviewRequests403ErrorResponse */
export interface GetReviewGamesGameIdReviewRequests403ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviewRequests403Error[]
}

/** GetReviewGamesGameIdReviewRequests404Error */
export interface GetReviewGamesGameIdReviewRequests404Error {
  code: 'game_not_found'
}

/** GetReviewGamesGameIdReviewRequests404ErrorResponse */
export interface GetReviewGamesGameIdReviewRequests404ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviewRequests404Error[]
}

/** GetReviewGamesGameIdReviews400Error */
export interface GetReviewGamesGameIdReviews400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetReviewGamesGameIdReviews400ErrorResponse */
export interface GetReviewGamesGameIdReviews400ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviews400Error[]
}

/** GetReviewGamesGameIdReviews403Error */
export interface GetReviewGamesGameIdReviews403Error {
  code: 'sign_in_required'
}

/** GetReviewGamesGameIdReviews403ErrorResponse */
export interface GetReviewGamesGameIdReviews403ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviews403Error[]
}

/** GetReviewGamesGameIdReviews404Error */
export interface GetReviewGamesGameIdReviews404Error {
  code: 'game_not_found'
}

/** GetReviewGamesGameIdReviews404ErrorResponse */
export interface GetReviewGamesGameIdReviews404ErrorResponse {
  data: null
  errors: GetReviewGamesGameIdReviews404Error[]
}

/** GetReviewRequestsMods400Error */
export interface GetReviewRequestsMods400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetReviewRequestsMods400ErrorResponse */
export interface GetReviewRequestsMods400ErrorResponse {
  data: null
  errors: GetReviewRequestsMods400Error[]
}

/** GetReviewRequestsMods403Error */
export interface GetReviewRequestsMods403Error {
  code: 'sign_in_required'
}

/** GetReviewRequestsMods403ErrorResponse */
export interface GetReviewRequestsMods403ErrorResponse {
  data: null
  errors: GetReviewRequestsMods403Error[]
}

export type GetRunnersData = ResponseGmsRunnersResponse

export type GetRunnersError = GetGmsRunners400ErrorResponse | GetGmsRunners403ErrorResponse

export interface GetRunnersParams {
  /**
   * Filter runners by `deprecated` flag.
   * @default "ALL"
   */
  deprecationStatus?: 'ALL' | 'DEPRECATED' | 'NON_DEPRECATED'
  /**
   * The page to return. The response will contain GMS runner versions in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of GMS runner versions to include in the response. Fewer GMS runner versions may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** GetScheduledTasks403Error */
export interface GetScheduledTasks403Error {
  code: 'sign_in_required'
}

/** GetScheduledTasks403ErrorResponse */
export interface GetScheduledTasks403ErrorResponse {
  data: null
  errors: GetScheduledTasks403Error[]
}

export type GetScoreVerificationsData = ResponseScoreVerificationsResponse

export type GetScoreVerificationsError =
  | GetChallengesChallengeIdScoreVerifications400ErrorResponse
  | GetChallengesChallengeIdScoreVerifications403ErrorResponse
  | GetChallengesChallengeIdScoreVerifications404ErrorResponse

export type GetScoreVerificationsFail =
  | GetPromotedChallengesPromotedChallengeIdScoreVerifications400ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdScoreVerifications403ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdScoreVerifications404ErrorResponse

export interface GetScoreVerificationsParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  challengeId: string
  /**
   * Include scores already reviewed and marked as bad.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   * @example "yes"
   */
  includeBadScores?: boolean
  /**
   * The page to return. The response will contain score verifications in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of score verifications to include in the response. Fewer score verifications may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Include scores related to promoted challenge.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  promotedChallengeId?: string
}

export interface GetScoreVerificationsParams2 {
  /**
   * Include scores already reviewed and marked as bad.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   * @example "yes"
   */
  includeBadScores?: boolean
  /**
   * The page to return. The response will contain score verifications in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of score verifications to include in the response. Fewer score verifications may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  promotedChallengeId: string
}

export type GetScoreVerificationsResult = ResponseScoreVerificationsResponse

export type GetSeasonDetailsData = ResponseSeasonDetailsResponse

export type GetSeasonDetailsError =
  | GetSeasonsSeasonId403ErrorResponse
  | GetSeasonsSeasonId404ErrorResponse

/** GetSeasons400Error */
export interface GetSeasons400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetSeasons400ErrorResponse */
export interface GetSeasons400ErrorResponse {
  data: null
  errors: GetSeasons400Error[]
}

/** GetSeasons403Error */
export interface GetSeasons403Error {
  code: 'sign_in_required'
}

/** GetSeasons403ErrorResponse */
export interface GetSeasons403ErrorResponse {
  data: null
  errors: GetSeasons403Error[]
}

/** GetSeasonsSeasonId403Error */
export interface GetSeasonsSeasonId403Error {
  code: 'sign_in_required'
}

/** GetSeasonsSeasonId403ErrorResponse */
export interface GetSeasonsSeasonId403ErrorResponse {
  data: null
  errors: GetSeasonsSeasonId403Error[]
}

/** GetSeasonsSeasonId404Error */
export interface GetSeasonsSeasonId404Error {
  code: 'season_not_found'
}

/** GetSeasonsSeasonId404ErrorResponse */
export interface GetSeasonsSeasonId404ErrorResponse {
  data: null
  errors: GetSeasonsSeasonId404Error[]
}

/** GetSeasonsSeasonIdBlockedGames400Error */
export interface GetSeasonsSeasonIdBlockedGames400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetSeasonsSeasonIdBlockedGames400ErrorResponse */
export interface GetSeasonsSeasonIdBlockedGames400ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedGames400Error[]
}

/** GetSeasonsSeasonIdBlockedGames403Error */
export interface GetSeasonsSeasonIdBlockedGames403Error {
  code: 'sign_in_required'
}

/** GetSeasonsSeasonIdBlockedGames403ErrorResponse */
export interface GetSeasonsSeasonIdBlockedGames403ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedGames403Error[]
}

/** GetSeasonsSeasonIdBlockedGames404Error */
export interface GetSeasonsSeasonIdBlockedGames404Error {
  code: 'season_not_found'
}

/** GetSeasonsSeasonIdBlockedGames404ErrorResponse */
export interface GetSeasonsSeasonIdBlockedGames404ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedGames404Error[]
}

/** GetSeasonsSeasonIdBlockedStudios400Error */
export interface GetSeasonsSeasonIdBlockedStudios400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetSeasonsSeasonIdBlockedStudios400ErrorResponse */
export interface GetSeasonsSeasonIdBlockedStudios400ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedStudios400Error[]
}

/** GetSeasonsSeasonIdBlockedStudios403Error */
export interface GetSeasonsSeasonIdBlockedStudios403Error {
  code: 'sign_in_required'
}

/** GetSeasonsSeasonIdBlockedStudios403ErrorResponse */
export interface GetSeasonsSeasonIdBlockedStudios403ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedStudios403Error[]
}

/** GetSeasonsSeasonIdBlockedStudios404Error */
export interface GetSeasonsSeasonIdBlockedStudios404Error {
  code: 'season_not_found'
}

/** GetSeasonsSeasonIdBlockedStudios404ErrorResponse */
export interface GetSeasonsSeasonIdBlockedStudios404ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdBlockedStudios404Error[]
}

/** GetSeasonsSeasonIdPointsTransactions400Error */
export interface GetSeasonsSeasonIdPointsTransactions400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetSeasonsSeasonIdPointsTransactions400ErrorResponse */
export interface GetSeasonsSeasonIdPointsTransactions400ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdPointsTransactions400Error[]
}

/** GetSeasonsSeasonIdPointsTransactions403Error */
export interface GetSeasonsSeasonIdPointsTransactions403Error {
  code: 'sign_in_required'
}

/** GetSeasonsSeasonIdPointsTransactions403ErrorResponse */
export interface GetSeasonsSeasonIdPointsTransactions403ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdPointsTransactions403Error[]
}

/** GetSeasonsSeasonIdPointsTransactions404Error */
export interface GetSeasonsSeasonIdPointsTransactions404Error {
  code: 'season_not_found'
}

/** GetSeasonsSeasonIdPointsTransactions404ErrorResponse */
export interface GetSeasonsSeasonIdPointsTransactions404ErrorResponse {
  data: null
  errors: GetSeasonsSeasonIdPointsTransactions404Error[]
}

/** GetSeasonsStudioMetricsActivatePointsTransactions403Error */
export interface GetSeasonsStudioMetricsActivatePointsTransactions403Error {
  code: 'sign_in_required'
}

/** GetSeasonsStudioMetricsActivatePointsTransactions403ErrorResponse */
export interface GetSeasonsStudioMetricsActivatePointsTransactions403ErrorResponse {
  data: null
  errors: GetSeasonsStudioMetricsActivatePointsTransactions403Error[]
}

/** GetSeasonsStudioMetricsDaily403Error */
export interface GetSeasonsStudioMetricsDaily403Error {
  code: 'sign_in_required'
}

/** GetSeasonsStudioMetricsDaily403ErrorResponse */
export interface GetSeasonsStudioMetricsDaily403ErrorResponse {
  data: null
  errors: GetSeasonsStudioMetricsDaily403Error[]
}

/** GetSeasonsStudioMetricsHourly403Error */
export interface GetSeasonsStudioMetricsHourly403Error {
  code: 'sign_in_required'
}

/** GetSeasonsStudioMetricsHourly403ErrorResponse */
export interface GetSeasonsStudioMetricsHourly403ErrorResponse {
  data: null
  errors: GetSeasonsStudioMetricsHourly403Error[]
}

/** GetSeasonsStudioMetricsSeasonStart403Error */
export interface GetSeasonsStudioMetricsSeasonStart403Error {
  code: 'sign_in_required'
}

/** GetSeasonsStudioMetricsSeasonStart403ErrorResponse */
export interface GetSeasonsStudioMetricsSeasonStart403ErrorResponse {
  data: null
  errors: GetSeasonsStudioMetricsSeasonStart403Error[]
}

/** GetSessionRedirect403Error */
export interface GetSessionRedirect403Error {
  code: 'sign_in_required'
}

/** GetSessionRedirect403ErrorResponse */
export interface GetSessionRedirect403ErrorResponse {
  data: null
  errors: GetSessionRedirect403Error[]
}

export type GetSettingDetailsData = ResponseSettingDetailsResponse

export type GetSettingDetailsError =
  | GetSettingsSettingId400ErrorResponse
  | GetSettingsSettingId403ErrorResponse
  | GetSettingsSettingId404ErrorResponse

export interface GetSettingDetailsParams {
  /** Include override values for the given entity and/or its parent entities. Must be used together with `entityType`. */
  entityId?: string
  /** Include override values for the given type of entity or its parent entities. Must be used together with `entityId`. */
  entityType?:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
  settingId: string
}

/** GetSettings403Error */
export interface GetSettings403Error {
  code: 'sign_in_required'
}

/** GetSettings403ErrorResponse */
export interface GetSettings403ErrorResponse {
  data: null
  errors: GetSettings403Error[]
}

export type GetSettingsData = ResponseListLegacySettingResponse

export type GetSettingsError = GetSettings403ErrorResponse

/** GetSettingsSettingId400Error */
export interface GetSettingsSettingId400Error {
  code: 'invalid_setting_id'
}

/** GetSettingsSettingId400ErrorResponse */
export interface GetSettingsSettingId400ErrorResponse {
  data: null
  errors: GetSettingsSettingId400Error[]
}

/** GetSettingsSettingId403Error */
export interface GetSettingsSettingId403Error {
  code: 'sign_in_required'
}

/** GetSettingsSettingId403ErrorResponse */
export interface GetSettingsSettingId403ErrorResponse {
  data: null
  errors: GetSettingsSettingId403Error[]
}

/** GetSettingsSettingId404Error */
export interface GetSettingsSettingId404Error {
  code: 'setting_not_found'
}

/** GetSettingsSettingId404ErrorResponse */
export interface GetSettingsSettingId404ErrorResponse {
  data: null
  errors: GetSettingsSettingId404Error[]
}

/** GetSettingsTransactions400Error */
export interface GetSettingsTransactions400Error {
  code:
    | 'invalid_setting_id'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetSettingsTransactions400ErrorResponse */
export interface GetSettingsTransactions400ErrorResponse {
  data: null
  errors: GetSettingsTransactions400Error[]
}

/** GetSettingsTransactions403Error */
export interface GetSettingsTransactions403Error {
  code: 'sign_in_required'
}

/** GetSettingsTransactions403ErrorResponse */
export interface GetSettingsTransactions403ErrorResponse {
  data: null
  errors: GetSettingsTransactions403Error[]
}

export type GetStatisticsData = ResponseWaitListResponse

export type GetStatisticsError =
  | GetWaitlistsTopic403ErrorResponse
  | GetWaitlistsTopic404ErrorResponse

export type GetStickerDetailsData = ResponseStickerResponse

export type GetStickerDetailsError =
  | GetStickersStickerId403ErrorResponse
  | GetStickersStickerId404ErrorResponse

/** GetStickers400Error */
export interface GetStickers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetStickers400ErrorResponse */
export interface GetStickers400ErrorResponse {
  data: null
  errors: GetStickers400Error[]
}

/** GetStickers403Error */
export interface GetStickers403Error {
  code: 'sign_in_required'
}

/** GetStickers403ErrorResponse */
export interface GetStickers403ErrorResponse {
  data: null
  errors: GetStickers403Error[]
}

/** GetStickersStickerId403Error */
export interface GetStickersStickerId403Error {
  code: 'sign_in_required'
}

/** GetStickersStickerId403ErrorResponse */
export interface GetStickersStickerId403ErrorResponse {
  data: null
  errors: GetStickersStickerId403Error[]
}

/** GetStickersStickerId404Error */
export interface GetStickersStickerId404Error {
  code: 'sticker_not_found'
}

/** GetStickersStickerId404ErrorResponse */
export interface GetStickersStickerId404ErrorResponse {
  data: null
  errors: GetStickersStickerId404Error[]
}

export type GetStringDetailsData = ResponseStringResponse

export type GetStringDetailsError =
  | GetStringsStringId403ErrorResponse
  | GetStringsStringId404ErrorResponse

/** GetStrings400Error */
export interface GetStrings400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetStrings400ErrorResponse */
export interface GetStrings400ErrorResponse {
  data: null
  errors: GetStrings400Error[]
}

/** GetStrings403Error */
export interface GetStrings403Error {
  code: 'sign_in_required'
}

/** GetStrings403ErrorResponse */
export interface GetStrings403ErrorResponse {
  data: null
  errors: GetStrings403Error[]
}

export type GetStringsData = ResponseStringsResponse

export type GetStringsError = GetStrings400ErrorResponse | GetStrings403ErrorResponse

/** GetStringsLocales403Error */
export interface GetStringsLocales403Error {
  code: 'sign_in_required'
}

/** GetStringsLocales403ErrorResponse */
export interface GetStringsLocales403ErrorResponse {
  data: null
  errors: GetStringsLocales403Error[]
}

export interface GetStringsParams {
  /**
   * The page to return. The response will contain strings in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of strings to include in the response. Fewer strings may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** GetStringsStringId403Error */
export interface GetStringsStringId403Error {
  code: 'sign_in_required'
}

/** GetStringsStringId403ErrorResponse */
export interface GetStringsStringId403ErrorResponse {
  data: null
  errors: GetStringsStringId403Error[]
}

/** GetStringsStringId404Error */
export interface GetStringsStringId404Error {
  code: 'string_not_found'
}

/** GetStringsStringId404ErrorResponse */
export interface GetStringsStringId404ErrorResponse {
  data: null
  errors: GetStringsStringId404Error[]
}

export type GetStripeConnectAccountBalanceDetailsData =
  ResponseBackofficeStripeConnectAccountDetailsResponse

export type GetStripeConnectAccountBalanceDetailsError =
  | GetStudiosStudioIdStripeConnectAccountBalance400ErrorResponse
  | GetStudiosStudioIdStripeConnectAccountBalance403ErrorResponse
  | GetStudiosStudioIdStripeConnectAccountBalance404ErrorResponse
  | GetStudiosStudioIdStripeConnectAccountBalance500ErrorResponse

export type GetStudioData = ResponseStudioDetailsResponse

export type GetStudioError = GetStudiosStudioId403ErrorResponse | GetStudiosStudioId404ErrorResponse

/** GetStudioSettings403Error */
export interface GetStudioSettings403Error {
  code: 'sign_in_required'
}

/** GetStudioSettings403ErrorResponse */
export interface GetStudioSettings403ErrorResponse {
  data: null
  errors: GetStudioSettings403Error[]
}

export type GetStudioSettingsData = ResponseLegacyStudioSettingsResponse

export type GetStudioSettingsError =
  | GetStudiosStudioIdSettings403ErrorResponse
  | GetStudiosStudioIdSettings404ErrorResponse

export type GetStudioSettingsV2Data = ResponseStudioSettingsResponse

export type GetStudioSettingsV2Error =
  | GetV2StudiosStudioIdSettings403ErrorResponse
  | GetV2StudiosStudioIdSettings404ErrorResponse

/** GetStudios400Error */
export interface GetStudios400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetStudios400ErrorResponse */
export interface GetStudios400ErrorResponse {
  data: null
  errors: GetStudios400Error[]
}

/** GetStudios403Error */
export interface GetStudios403Error {
  code: 'sign_in_required'
}

/** GetStudios403ErrorResponse */
export interface GetStudios403ErrorResponse {
  data: null
  errors: GetStudios403Error[]
}

export type GetStudiosData = ResponseStudiosResponse

export type GetStudiosError = GetStudios400ErrorResponse | GetStudios403ErrorResponse

export interface GetStudiosParams {
  /**
   * Include only studios that can/can not upload custom games on Dev Cloud
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  canUploadCustomGames?: boolean
  /**
   * Include only studios that can/can not upload custom games on Dev Portal
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  canUploadCustomGamesOnDevPortal?: boolean
  /**
   * Include only studios that has/does not have monetization
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  hasMonetization?: boolean
  /** Include only studios whose name contains the given string */
  nameSearch?: string
  /**
   * Include only studios that has discoverable games
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  onlyWithDiscoverableGames?: boolean
  /**
   * Include only studios who has games
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  onlyWithGames?: boolean
  /**
   * The page to return. The response will contain studios in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of studios to include in the response. Fewer studios may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `name-asc`
   */
  sort?: ('name-asc' | 'name-desc')[]
  /** Include only studios with one of the given studioIds */
  studioIds?: string[]
  /** Include only studios which has all the given userIds as members */
  userIds?: string[]
}

/** GetStudiosSettings403Error */
export interface GetStudiosSettings403Error {
  code: 'sign_in_required'
}

/** GetStudiosSettings403ErrorResponse */
export interface GetStudiosSettings403ErrorResponse {
  data: null
  errors: GetStudiosSettings403Error[]
}

export type GetStudiosSettingsData = ResponseLegacyStudioSettingsListResponse

export type GetStudiosSettingsError = GetStudiosSettings403ErrorResponse

export interface GetStudiosSettingsParams {
  /**
   * Include only studios with ads monetization. Only one filter works at a time.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  includeAdsMonetizedStudios?: boolean
  /**
   * Include only studios with `claim a game` option. Only one filter works at a time.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  includeClaimGameStudios?: boolean
  /**
   * Include only studios with custom game builds feature. Only one filter works at a time.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  includeCustomGameBuilds?: boolean
  /**
   * Include only studios with custom upload limit. Only one filter works at a time.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  includeCustomUploadLimitStudios?: boolean
  /**
   * Include only studios with monetization. Only one filter works at a time.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  includeMonetizedStudios?: boolean
}

/** GetStudiosStudioId403Error */
export interface GetStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** GetStudiosStudioId403ErrorResponse */
export interface GetStudiosStudioId403ErrorResponse {
  data: null
  errors: GetStudiosStudioId403Error[]
}

/** GetStudiosStudioId404Error */
export interface GetStudiosStudioId404Error {
  code: 'studio_not_found'
}

/** GetStudiosStudioId404ErrorResponse */
export interface GetStudiosStudioId404ErrorResponse {
  data: null
  errors: GetStudiosStudioId404Error[]
}

/** GetStudiosStudioIdSettings403Error */
export interface GetStudiosStudioIdSettings403Error {
  code: 'sign_in_required'
}

/** GetStudiosStudioIdSettings403ErrorResponse */
export interface GetStudiosStudioIdSettings403ErrorResponse {
  data: null
  errors: GetStudiosStudioIdSettings403Error[]
}

/** GetStudiosStudioIdSettings404Error */
export interface GetStudiosStudioIdSettings404Error {
  code: 'studio_not_found'
}

/** GetStudiosStudioIdSettings404ErrorResponse */
export interface GetStudiosStudioIdSettings404ErrorResponse {
  data: null
  errors: GetStudiosStudioIdSettings404Error[]
}

/** GetStudiosStudioIdStripeConnectAccountBalance400Error */
export interface GetStudiosStudioIdStripeConnectAccountBalance400Error {
  code: 'stripe_connect_account_not_set_for_studio'
}

/** GetStudiosStudioIdStripeConnectAccountBalance400ErrorResponse */
export interface GetStudiosStudioIdStripeConnectAccountBalance400ErrorResponse {
  data: null
  errors: GetStudiosStudioIdStripeConnectAccountBalance400Error[]
}

/** GetStudiosStudioIdStripeConnectAccountBalance403Error */
export interface GetStudiosStudioIdStripeConnectAccountBalance403Error {
  code: 'sign_in_required'
}

/** GetStudiosStudioIdStripeConnectAccountBalance403ErrorResponse */
export interface GetStudiosStudioIdStripeConnectAccountBalance403ErrorResponse {
  data: null
  errors: GetStudiosStudioIdStripeConnectAccountBalance403Error[]
}

/** GetStudiosStudioIdStripeConnectAccountBalance404Error */
export interface GetStudiosStudioIdStripeConnectAccountBalance404Error {
  code: 'stripe_connect_account_not_found' | 'studio_not_found'
}

/** GetStudiosStudioIdStripeConnectAccountBalance404ErrorResponse */
export interface GetStudiosStudioIdStripeConnectAccountBalance404ErrorResponse {
  data: null
  errors: GetStudiosStudioIdStripeConnectAccountBalance404Error[]
}

/** GetStudiosStudioIdStripeConnectAccountBalance500Error */
export interface GetStudiosStudioIdStripeConnectAccountBalance500Error {
  code: 'internal_server_error'
}

/** GetStudiosStudioIdStripeConnectAccountBalance500ErrorResponse */
export interface GetStudiosStudioIdStripeConnectAccountBalance500ErrorResponse {
  data: null
  errors: GetStudiosStudioIdStripeConnectAccountBalance500Error[]
}

export type GetSubscribersData = ResponseFundingSubscribersResponse

export type GetSubscribersError =
  | GetFundingWaitList400ErrorResponse
  | GetFundingWaitList403ErrorResponse
  | GetFundingWaitList404ErrorResponse

export interface GetSubscribersParams {
  /** Email address(es) of users or guests to filter by. */
  email?: string[]
  /**
   * The page to return. The response will contain subscribers to funding list in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of subscribers to funding list to include in the response. Fewer subscribers to funding list may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Filter funding subscribers by being guest or registered user. Conflicts with the 'userId' param if set to 'GUEST' */
  subscriberType?: 'GUEST' | 'USER'
  /** ID(s) of users to filter by. */
  userId?: string[]
}

export type GetSupportedCurrenciesData = ResponseCurrenciesResponse

export type GetSupportedCurrenciesError = GetItemsCurrencies403ErrorResponse

export type GetTagDetailsData = ResponseTagDetailsResponse

export type GetTagDetailsError = GetTagsTagId403ErrorResponse | GetTagsTagId404ErrorResponse

/** GetTags400Error */
export interface GetTags400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetTags400ErrorResponse */
export interface GetTags400ErrorResponse {
  data: null
  errors: GetTags400Error[]
}

/** GetTags403Error */
export interface GetTags403Error {
  code: 'sign_in_required'
}

/** GetTags403ErrorResponse */
export interface GetTags403ErrorResponse {
  data: null
  errors: GetTags403Error[]
}

/** GetTags404Error */
export interface GetTags404Error {
  code: 'tag_not_found'
}

/** GetTags404ErrorResponse */
export interface GetTags404ErrorResponse {
  data: null
  errors: GetTags404Error[]
}

export type GetTagsData = ResponseTagsResponse

export type GetTagsError =
  | GetTags400ErrorResponse
  | GetTags403ErrorResponse
  | GetTags404ErrorResponse

export interface GetTagsParams {
  /** Filters tags by their categories. */
  category?: (
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'MOD_PLATFORM'
    | 'MOD_SIGNING_KEY'
    | 'MOD_TYPE'
    | 'MOD_WALLPAPER_TYPE'
  )[]
  /**
   * If `true`, include only internal tags. If `false`, include only tags that are visible in public APIs. If omitted, include all tags.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  onlyWithInternal?: boolean
  /**
   * The page to return. The response will contain tags in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of tags to include in the response. Fewer tags may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  parentTagId?: string[]
  parentTagTitleSearch?: string
  /**
   * If enabled, restricts the list to only root tags (i.e. tags with no parent).
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  rootOnly?: boolean
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `title-asc`
   */
  sort?: (
    | 'alias-asc'
    | 'alias-desc'
    | 'display-order-asc'
    | 'display-order-desc'
    | 'name-asc'
    | 'name-desc'
    | 'title-asc'
    | 'title-desc'
  )[]
  titleSearch?: string
}

/** GetTagsTagId403Error */
export interface GetTagsTagId403Error {
  code: 'sign_in_required'
}

/** GetTagsTagId403ErrorResponse */
export interface GetTagsTagId403ErrorResponse {
  data: null
  errors: GetTagsTagId403Error[]
}

/** GetTagsTagId404Error */
export interface GetTagsTagId404Error {
  code: 'tag_not_found'
}

/** GetTagsTagId404ErrorResponse */
export interface GetTagsTagId404ErrorResponse {
  data: null
  errors: GetTagsTagId404Error[]
}

/** GetTagsTagIdGames400Error */
export interface GetTagsTagIdGames400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetTagsTagIdGames400ErrorResponse */
export interface GetTagsTagIdGames400ErrorResponse {
  data: null
  errors: GetTagsTagIdGames400Error[]
}

/** GetTagsTagIdGames403Error */
export interface GetTagsTagIdGames403Error {
  code: 'sign_in_required'
}

/** GetTagsTagIdGames403ErrorResponse */
export interface GetTagsTagIdGames403ErrorResponse {
  data: null
  errors: GetTagsTagIdGames403Error[]
}

/** GetTagsTagIdGames404Error */
export interface GetTagsTagIdGames404Error {
  code: 'tag_not_found'
}

/** GetTagsTagIdGames404ErrorResponse */
export interface GetTagsTagIdGames404ErrorResponse {
  data: null
  errors: GetTagsTagIdGames404Error[]
}

export type GetTopicsData = ResponseEmailTopicsResponse

export type GetTopicsError = GetEmailingTopics400ErrorResponse | GetEmailingTopics403ErrorResponse

export type GetTopicsFail = GetWaitlistsTopics403ErrorResponse

export interface GetTopicsParams {
  /**
   * The page to return. The response will contain emailing topics in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of emailing topics to include in the response. Fewer emailing topics may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type GetTopicsResult = ResponseListWaitlistTopicResponse

export type GetTrackData = ResponseGameReleaseTrackResponse

export type GetTrackError =
  | GetGamesGameIdTracksTrackId403ErrorResponse
  | GetGamesGameIdTracksTrackId404ErrorResponse

export type GetTransactionDetailsData = ResponseBankTransactionResponse

export type GetTransactionDetailsError =
  | GetBankTransactionsTransactionId403ErrorResponse
  | GetBankTransactionsTransactionId404ErrorResponse

export type GetUserQuestProgressesData = ResponseQuestUserProgressListResponse

export type GetUserQuestProgressesError =
  | GetQuestsUsersUserId403ErrorResponse
  | GetQuestsUsersUserId404ErrorResponse

export type GetUserQuestProgressesV2Data = ResponseQuestUserProgressListResponseV2

export type GetUserQuestProgressesV2Error =
  | GetV2QuestsUsersUserId403ErrorResponse
  | GetV2QuestsUsersUserId404ErrorResponse

export type GetUserScoreVerificationsData = ResponseScoreVerificationsResponse

export type GetUserScoreVerificationsError =
  | GetChallengesChallengeIdUserScoresUserId400ErrorResponse
  | GetChallengesChallengeIdUserScoresUserId403ErrorResponse
  | GetChallengesChallengeIdUserScoresUserId404ErrorResponse

export type GetUserScoreVerificationsFail =
  | GetPromotedChallengesPromotedChallengeIdUserScoresUserId400ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdUserScoresUserId403ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdUserScoresUserId404ErrorResponse

export interface GetUserScoreVerificationsParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  challengeId: string
  /**
   * The page to return. The response will contain score verifications in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of score verifications to include in the response. Fewer score verifications may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  userId: string
}

export interface GetUserScoreVerificationsParams2 {
  /**
   * The page to return. The response will contain score verifications in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of score verifications to include in the response. Fewer score verifications may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  promotedChallengeId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  userId: string
}

export type GetUserScoreVerificationsResult = ResponseScoreVerificationsResponse

export type GetUserScoresData = ResponseScoresResponse

export type GetUserScoresError =
  | GetPromotedChallengesPromotedChallengeIdScores400ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdScores403ErrorResponse
  | GetPromotedChallengesPromotedChallengeIdScores404ErrorResponse

export interface GetUserScoresParams {
  /**
   * The page to return. The response will contain scores in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of scores to include in the response. Fewer scores may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  promotedChallengeId: string
}

/** GetUsers400Error */
export interface GetUsers400Error {
  code:
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetUsers400ErrorResponse */
export interface GetUsers400ErrorResponse {
  data: null
  errors: GetUsers400Error[]
}

/** GetUsers403Error */
export interface GetUsers403Error {
  code: 'sign_in_required'
}

/** GetUsers403ErrorResponse */
export interface GetUsers403ErrorResponse {
  data: null
  errors: GetUsers403Error[]
}

export type GetUsersData = ResponseBackOfficeUsersResponse

export type GetUsersError =
  | GetAdministrationUsers400ErrorResponse
  | GetAdministrationUsers403ErrorResponse

export type GetUsersFail = GetUsers400ErrorResponse | GetUsers403ErrorResponse

export interface GetUsersParams {
  /**
   * Filter list of users by activation status
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default true
   */
  active?: boolean
  /**
   * The page to return. The response will contain users in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of users to include in the response. Fewer users may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export interface GetUsersParams2 {
  /** Include only users whose email contains the given substring */
  emailSearch?: string
  /**
   * Include only users who have Brevo contact created
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  hasBrevoContact?: boolean
  /** Include only users whose name contains the given substring */
  nameSearch?: string
  /** Include only user with the given operaAuthUserId */
  operaAuthUserId?: string
  /**
   * The page to return. The response will contain users in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of users to include in the response. Fewer users may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `username-asc`
   */
  sort?: (
    | 'email-asc'
    | 'email-desc'
    | 'name-asc'
    | 'name-desc'
    | 'username-asc'
    | 'username-desc'
  )[]
  /** Include only user with the given userId */
  userId?: string
  /** Include only users whose username matches the given string */
  username?: string
  /** Include only users whose username contains the given substring */
  usernameSearch?: string
}

export type GetUsersResult = ResponseUsersResponse

/** GetV2FeedsFeedIdEntries400Error */
export interface GetV2FeedsFeedIdEntries400Error {
  code:
    | 'invalid_feed_type'
    | 'invalid_sort'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetV2FeedsFeedIdEntries400ErrorResponse */
export interface GetV2FeedsFeedIdEntries400ErrorResponse {
  data: null
  errors: GetV2FeedsFeedIdEntries400Error[]
}

/** GetV2FeedsFeedIdEntries403Error */
export interface GetV2FeedsFeedIdEntries403Error {
  code: 'sign_in_required'
}

/** GetV2FeedsFeedIdEntries403ErrorResponse */
export interface GetV2FeedsFeedIdEntries403ErrorResponse {
  data: null
  errors: GetV2FeedsFeedIdEntries403Error[]
}

/** GetV2FeedsFeedIdEntries404Error */
export interface GetV2FeedsFeedIdEntries404Error {
  code: 'feed_not_found'
}

/** GetV2FeedsFeedIdEntries404ErrorResponse */
export interface GetV2FeedsFeedIdEntries404ErrorResponse {
  data: null
  errors: GetV2FeedsFeedIdEntries404Error[]
}

/** GetV2QuestsUsersUserId403Error */
export interface GetV2QuestsUsersUserId403Error {
  code: 'sign_in_required'
}

/** GetV2QuestsUsersUserId403ErrorResponse */
export interface GetV2QuestsUsersUserId403ErrorResponse {
  data: null
  errors: GetV2QuestsUsersUserId403Error[]
}

/** GetV2QuestsUsersUserId404Error */
export interface GetV2QuestsUsersUserId404Error {
  code: 'user_not_found'
}

/** GetV2QuestsUsersUserId404ErrorResponse */
export interface GetV2QuestsUsersUserId404ErrorResponse {
  data: null
  errors: GetV2QuestsUsersUserId404Error[]
}

/** GetV2Settings403Error */
export interface GetV2Settings403Error {
  code: 'sign_in_required'
}

/** GetV2Settings403ErrorResponse */
export interface GetV2Settings403ErrorResponse {
  data: null
  errors: GetV2Settings403Error[]
}

/** GetV2StudiosStudioIdSettings403Error */
export interface GetV2StudiosStudioIdSettings403Error {
  code: 'sign_in_required'
}

/** GetV2StudiosStudioIdSettings403ErrorResponse */
export interface GetV2StudiosStudioIdSettings403ErrorResponse {
  data: null
  errors: GetV2StudiosStudioIdSettings403Error[]
}

/** GetV2StudiosStudioIdSettings404Error */
export interface GetV2StudiosStudioIdSettings404Error {
  code: 'studio_not_found'
}

/** GetV2StudiosStudioIdSettings404ErrorResponse */
export interface GetV2StudiosStudioIdSettings404ErrorResponse {
  data: null
  errors: GetV2StudiosStudioIdSettings404Error[]
}

/** GetWaitlistsTopic403Error */
export interface GetWaitlistsTopic403Error {
  code: 'sign_in_required'
}

/** GetWaitlistsTopic403ErrorResponse */
export interface GetWaitlistsTopic403ErrorResponse {
  data: null
  errors: GetWaitlistsTopic403Error[]
}

/** GetWaitlistsTopic404Error */
export interface GetWaitlistsTopic404Error {
  code: 'email_topic_not_found'
}

/** GetWaitlistsTopic404ErrorResponse */
export interface GetWaitlistsTopic404ErrorResponse {
  data: null
  errors: GetWaitlistsTopic404Error[]
}

/** GetWaitlistsTopics403Error */
export interface GetWaitlistsTopics403Error {
  code: 'sign_in_required'
}

/** GetWaitlistsTopics403ErrorResponse */
export interface GetWaitlistsTopics403ErrorResponse {
  data: null
  errors: GetWaitlistsTopics403Error[]
}

/** GetWrappers400Error */
export interface GetWrappers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetWrappers400ErrorResponse */
export interface GetWrappers400ErrorResponse {
  data: null
  errors: GetWrappers400Error[]
}

/** GetWrappers403Error */
export interface GetWrappers403Error {
  code: 'sign_in_required'
}

/** GetWrappers403ErrorResponse */
export interface GetWrappers403ErrorResponse {
  data: null
  errors: GetWrappers403Error[]
}

/** GetWrappersWrapperIdDeployments400Error */
export interface GetWrappersWrapperIdDeployments400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetWrappersWrapperIdDeployments400ErrorResponse */
export interface GetWrappersWrapperIdDeployments400ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeployments400Error[]
}

/** GetWrappersWrapperIdDeployments403Error */
export interface GetWrappersWrapperIdDeployments403Error {
  code: 'sign_in_required'
}

/** GetWrappersWrapperIdDeployments403ErrorResponse */
export interface GetWrappersWrapperIdDeployments403ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeployments403Error[]
}

/** GetWrappersWrapperIdDeployments404Error */
export interface GetWrappersWrapperIdDeployments404Error {
  code: 'wrapper_not_found'
}

/** GetWrappersWrapperIdDeployments404ErrorResponse */
export interface GetWrappersWrapperIdDeployments404ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeployments404Error[]
}

/** GetWrappersWrapperIdDeploymentsDeploymentId403Error */
export interface GetWrappersWrapperIdDeploymentsDeploymentId403Error {
  code: 'sign_in_required'
}

/** GetWrappersWrapperIdDeploymentsDeploymentId403ErrorResponse */
export interface GetWrappersWrapperIdDeploymentsDeploymentId403ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeploymentsDeploymentId403Error[]
}

/** GetWrappersWrapperIdDeploymentsDeploymentId404Error */
export interface GetWrappersWrapperIdDeploymentsDeploymentId404Error {
  code: 'wrapper_deployment_not_found' | 'wrapper_not_found'
}

/** GetWrappersWrapperIdDeploymentsDeploymentId404ErrorResponse */
export interface GetWrappersWrapperIdDeploymentsDeploymentId404ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeploymentsDeploymentId404Error[]
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases400Error */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases400ErrorResponse */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases400ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeploymentsDeploymentIdReleases400Error[]
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases403Error */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases403Error {
  code: 'sign_in_required'
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases403ErrorResponse */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases403ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeploymentsDeploymentIdReleases403Error[]
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases404Error */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases404Error {
  code: 'wrapper_deployment_not_found' | 'wrapper_not_found'
}

/** GetWrappersWrapperIdDeploymentsDeploymentIdReleases404ErrorResponse */
export interface GetWrappersWrapperIdDeploymentsDeploymentIdReleases404ErrorResponse {
  data: null
  errors: GetWrappersWrapperIdDeploymentsDeploymentIdReleases404Error[]
}

/** GmsRunnerBulkDeletionRequest */
export interface GmsRunnerBulkDeletionRequest {
  /** List of runner IDs which are going to be deleted */
  gmsRunnerIds: string[]
}

/** GmsRunnerResponse */
export interface GmsRunnerResponse {
  /** Indicates if a runner is deprecated: games can still be played on adeprecated runner version but developers can't push new releases on it. */
  deprecated?: boolean | null
  /** @example "A description for this runner version." */
  description?: string | null
  /** All the public URLs of files uploaded with the runner: at the moment it's always an empty list. */
  files?: string[] | null
  /**
   * Runner platform
   * @example "MAC"
   */
  platform?: string | null
  /** @example "a93a8sb6-df58-4551-b30e-2a1c58c9071d" */
  runnerVersionId?: string | null
  /** @example "1.0.3+25" */
  runnerVersionName?: string | null
  /** @format date-time */
  uploadedAt?: string | null
}

/** GmsRunnersResponse */
export interface GmsRunnersResponse {
  gmsRunners: GmsRunnerResponse[]
  pagination: PaginationResponse
}

/** BO icon */
export interface IconResponse {
  /** @example "f26d9a15-4f65-4412-8590-5a69b46009d1" */
  assetId?: string | null
  /** @example "https://example.com/123456.png" */
  iconUrl?: string | null
  name?: string | null
}

export type ImportCodesData = ResponseOfferCodeImportResponse

export type ImportCodesError =
  | PostOffersOfferIdImportCodes400ErrorResponse
  | PostOffersOfferIdImportCodes403ErrorResponse
  | PostOffersOfferIdImportCodes404ErrorResponse
  | PostOffersOfferIdImportCodes500ErrorResponse

export interface ImportCodesParams {
  batchAlias: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
}

export interface ImportCodesPayload {
  /** @format binary */
  file?: File
}

export type InitEmailTopicsData = ResponseVoid

export type InitEmailTopicsError = PostEmailingTopics403ErrorResponse

export type InvalidateSupplementalDataCacheData = ResponseInteger

export type InvalidateSupplementalDataCacheError =
  | PostModsInvalidateSupplementalDataCache403ErrorResponse
  | PostModsInvalidateSupplementalDataCache404ErrorResponse

export interface InvalidateSupplementalDataCacheParams {
  /**
   * Specific mod or mods to invalidate cache for
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   */
  modId?: string[]
}

/** LWThemeColorSettings */
export interface LWThemeColorSettings {
  /** @example "#f5f5f5" */
  textColor: string
  /** @example "#f5f5f5" */
  textShadowColor: string
}

/** LWThemesSettingsResponse */
export interface LWThemesSettingsResponse {
  darkTheme?: LWThemeColorSettings | null
  lightTheme?: LWThemeColorSettings | null
}

/** LegacySettingResponse */
export interface LegacySettingResponse {
  key?:
    | 'ADDITIONAL_LOCALES'
    | 'ALIAS_LENGTH_LIMIT'
    | 'ALLOW_DEBUG_MULTIPLAYER_ROOMS'
    | 'ALLOW_GAME_PRELOADING'
    | 'ALLOW_GAME_SAVES'
    | 'ALLOW_RUNNERLESS_BUILD_UPLOADS'
    | 'ALLOW_STORE_BUCKET_FOR_ASSETS'
    | 'ASSET_VARIANTS_LAMBDA_FUNCTION_ARN'
    | 'ASSET_VARIANTS_LAMBDA_FUNCTION_REGION'
    | 'ASSET_VARIANT_FORMATS'
    | 'ASSET_VARIANT_REQUEST_TIMEOUT_SECONDS'
    | 'BACK_OFFICE_AUTHENTICATION_FAILURE_PATH'
    | 'BONUS_TOKEN_SIGNING_KEY'
    | 'BONUS_TOKEN_TTL_SECONDS'
    | 'BREVO_API_KEY'
    | 'BREVO_AUTOMATION_API_BASE_URL'
    | 'BREVO_LIST_ID'
    | 'BREVO_TRACKER_API_KEY'
    | 'CACHE_RESET_PATHS'
    | 'CHALLENGE_LONG_DESCRIPTION_LENGTH_LIMIT'
    | 'CHALLENGE_NAME_LENGTH_LIMIT'
    | 'CHALLENGE_RANKING_BUCKETS'
    | 'CHALLENGE_SHORT_DESCRIPTION_LENGTH_LIMIT'
    | 'CLAIM_OFFER_ERROR_PAGE_URL'
    | 'CLOUDFLARE_API_ENDPOINT'
    | 'CMS_MAX_SECTIONS_PER_CONFIGURATION'
    | 'COUNTRIES_BY_ROLLBACK_REGION'
    | 'CURRENCY_EXCHANGE_RATE'
    | 'DC_AUTHENTICATION_FAILURE_PATH'
    | 'DEFAULT_GAME_BUNDLE_SIZE_LIMIT_MB'
    | 'DEFAULT_GAME_SAVE_REVISION_SIZE_LIMIT_KB'
    | 'DEFAULT_LOCALE'
    | 'DEFAULT_MOD_BUNDLE_SIZE_LIMIT_MB'
    | 'DEFAULT_MOD_FLAVOR_FEATURES'
    | 'DEFAULT_MOD_ICON'
    | 'DEFAULT_MOD_WALLPAPER_PREVIEW'
    | 'DEFAULT_PAYMENT_PROVIDER'
    | 'DEFAULT_ROLLBACK_SETTINGS'
    | 'DISABLED_ENDPOINTS'
    | 'DISABLE_ALL_MOD_FLAVORS'
    | 'DISABLE_ALL_SIGN_INS'
    | 'DISABLE_SHARED_ARRAY_BUFFER_SUPPORT'
    | 'DYNAMIC_CONFIG_RESPONSE_HEADERS'
    | 'ENDPOINT_CACHING_POLICIES'
    | 'EVENTBRIDGE_RETRY_TIMEOUT_SECONDS'
    | 'FEED_TITLE_LENGTH_LIMIT'
    | 'FUNDING_SURVEY_URL'
    | 'GAMES_AUTOLOAD_ENABLED'
    | 'GAME_BANNER_LIMIT_PER_GAME'
    | 'GAME_CHARTS_BANNER_ASPECT_RATIO_DIVIDEND'
    | 'GAME_CHARTS_BANNER_ASPECT_RATIO_DIVISOR'
    | 'GAME_CHARTS_BANNER_ASSET_ID'
    | 'GAME_CHARTS_BANNER_HEIGHT'
    | 'GAME_CHARTS_BANNER_WIDTH'
    | 'GAME_DATA_MIGRATION_ROLLOUT'
    | 'GAME_ENGINE_TITLE_LENGTH_LIMIT'
    | 'GAME_LONG_DESCRIPTION_LENGTH_LIMIT'
    | 'GAME_NAME_LENGTH_LIMIT'
    | 'GAME_SHORT_DESCRIPTION_LENGTH_LIMIT'
    | 'GAME_STATISTICS_PLAY_DURATION_CUTOFF_DAYS'
    | 'GEOLOCATION_COUNTRY_CODE_HEADER_NAME'
    | 'GEOLOCATION_REGION_CODE_HEADER_NAME'
    | 'GMS_RUNNERS_PACKAGE_SIZE_LIMIT_MB'
    | 'GMS_RUNNERS_UNPACK_RULES'
    | 'GMS_RUNNER_PROCESSING_PER_SCHEDULER_RUN'
    | 'GMS_RUNNER_SIGNATURE_PUBLIC_KEY'
    | 'GMS_RUNNER_SIGNATURE_VALIDATION_ENABLED'
    | 'GROUP_CONTACT_EMAIL_LENGTH_LIMIT'
    | 'GROUP_LONG_DESCRIPTION_LENGTH_LIMIT'
    | 'GROUP_NAME_LENGTH_LIMIT'
    | 'GX_PLAY_AGAIN_URL_PATH_FORMAT'
    | 'GX_PLAY_AGAIN_URL_QUERY'
    | 'GXC_AUTHENTICATION_FAILURE_PATH'
    | 'GXC_GAME_DETAILS_ROOM_URL_PARAMETER_NAME'
    | 'GXC_SEO_FRIENDLY_CHALLENGE_DETAILS_PATH_FORMAT'
    | 'GXC_SEO_FRIENDLY_GAME_DETAILS_PATH_FORMAT'
    | 'GXC_SEO_FRIENDLY_GAME_DETAILS_WITH_TRACK_PATH_FORMAT'
    | 'ITEM_DESCRIPTION_LENGTH'
    | 'JSON_REQUEST_BODY_SIZE_LIMIT'
    | 'LAST_VISITED_MARKETING_ATTRIBUTE_UPDATE_DELAY_SECONDS'
    | 'LEGACY_GAME_SAVE_REVISION_SIZE_LIMIT_KB'
    | 'LIVE_WALLPAPER_DEFAULT_TEXT_COLOR_DARK_THEME'
    | 'LIVE_WALLPAPER_DEFAULT_TEXT_COLOR_LIGHT_THEME'
    | 'LIVE_WALLPAPER_DEFAULT_TEXT_SHADOW_COLOR_DARK_THEME'
    | 'LIVE_WALLPAPER_DEFAULT_TEXT_SHADOW_COLOR_LIGHT_THEME'
    | 'LOBBY_POINTS_CONFIGURATION'
    | 'LONG_TERM_TTL_CACHE_CONTROL_RESPONSE_HEADERS'
    | 'MAX_ACTIVE_LOBBY_MEMBERS'
    | 'MAX_CHILD_TAGS'
    | 'MAX_DYNAMIC_CONFIG_CONTENT_SIZE_LIMIT_KB'
    | 'MAX_DYNAMIC_CONFIG_EXPRESSION_DEPTH'
    | 'MAX_EXTERNAL_TAGS_PER_GAME'
    | 'MAX_EXTERNAL_TAGS_PER_MOD'
    | 'MAX_FILTERING_EXPRESSION_DEPTH'
    | 'MAX_FILTERING_EXPRESSION_LEAVES'
    | 'MAX_FRIEND_CONNECTIONS'
    | 'MAX_GAMES_LIMIT'
    | 'MAX_GAMES_PRELOADED_PER_USER'
    | 'MAX_GAME_SAVE_REVISIONS_PER_SLOT'
    | 'MAX_GAME_SAVE_SLOTS_PER_GAME'
    | 'MAX_GAME_TRANSFER_COMMENT_LENGTH'
    | 'MAX_GROUPS_BY_INVITE'
    | 'MAX_INTERNAL_TAGS_PER_GAME'
    | 'MAX_INTERNAL_TAGS_PER_MOD'
    | 'MAX_ITEMS_PER_GAME'
    | 'MAX_LOBBY_CHALLENGES'
    | 'MAX_MODS_PER_STUDIO'
    | 'MAX_MOD_MANIFEST_ICONS'
    | 'MAX_MOD_MANIFEST_SIZE'
    | 'MAX_MOD_RUNNER_SIZE'
    | 'MAX_ODKS_PER_MOD'
    | 'MAX_ODKS_PER_STUDIO'
    | 'MAX_PARALLEL_QUERIES'
    | 'MAX_PENDING_ASSET_VARIANT_REQUESTS'
    | 'MAX_PENDING_RECEIVED_FRIEND_REQUESTS'
    | 'MAX_PENDING_SENT_FRIEND_REQUESTS'
    | 'MAX_SCORES_PER_USER'
    | 'MAX_TAGS_FOR_GAME_LISTING'
    | 'MAX_TAGS_FOR_MOD_LISTING'
    | 'MAX_TAG_DEPTH'
    | 'MAX_TOTAL_LOBBY_MEMBERS'
    | 'MAX_WALLPAPERS_PER_MOD'
    | 'MEDIUM_TERM_TTL_CACHE_CONTROL_RESPONSE_HEADERS'
    | 'MESSAGE_DESCRIPTION_LENGTH_LIMIT'
    | 'MESSAGE_TITLE_LENGTH_LIMIT'
    | 'MIN_LOBBY_CHALLENGES'
    | 'MIN_MOD_MANIFEST_ICONS'
    | 'MIN_RESPONSIVE_RELAYS_PER_REGION'
    | 'MOD_ICONS_REQUIRED_SIZES'
    | 'MOD_LONG_DESCRIPTION_LENGTH_LIMIT'
    | 'MOD_MANIFEST_ALLOWED_FIELDS'
    | 'MOD_PLATFORM_TAG_ALIAS_OVERRIDES'
    | 'MOD_SIGNING_KEY_POOL_SIZE'
    | 'MOD_TITLE_LENGTH_LIMIT'
    | 'MOD_TRANSFER_CONFIRMATION_LINK_PATH'
    | 'MOD_TRANSFER_CANCELLATION_LINK_PATH'
    | 'MOD_TYPE_TAG_ALIAS_OVERRIDES'
    | 'MOD_WALLPAPER_TYPE_TAG_ALIAS_OVERRIDES'
    | 'MOD_WALLPAPER_VIDEO_EXTENSIONS'
    | 'MULTIPLAYER_ALLOW_GUEST_HOSTS'
    | 'MULTIPLAYER_DEFAULT_MAX_ALLOWED_RTT_MS'
    | 'MULTIPLAYER_GUEST_USERNAMES'
    | 'MULTIPLAYER_MAX_DEFAULT_ROOM_SIZE'
    | 'MULTIPLAYER_RELAYS'
    | 'MULTIPLAYER_RELAY_CREATE_ROOM_TIMEOUT_MS'
    | 'MULTIPLAYER_RELAY_GET_ACTIVE_ROOM_COUNT_TIMEOUT_MS'
    | 'MULTIPLAYER_RELAY_GET_ROOMS_TIMEOUT_MS'
    | 'MULTIPLAYER_RELAY_GET_ROOM_INFO_TIMEOUT_MS'
    | 'MULTIPLAYER_RELAY_JOIN_ROOM_TIMEOUT_MS'
    | 'MULTIPLAYER_RELAY_SET_SETTINGS_TIMEOUT_MS'
    | 'OPERA_AUTH_WEBHOOK_KEY'
    | 'PAGE_SIZE_LIMIT'
    | 'PARALLEL_QUERY_TIMEOUT_MS'
    | 'PAYMENTS_ENABLED'
    | 'PROMOTED_CHALLENGE_COVER_ASPECT_RATIO_DIVIDEND'
    | 'PROMOTED_CHALLENGE_COVER_ASPECT_RATIO_DIVISOR'
    | 'PROMOTED_CHALLENGE_COVER_MIN_HEIGHT'
    | 'PROMOTED_CHALLENGE_COVER_MIN_WIDTH'
    | 'PUBLISHER_TITLE_LENGTH_LIMIT'
    | 'GEOLOCATION_ACCESS_CONFIG'
    | 'ROLLBACK_SETTINGS'
    | 'S3_EVENT_NOTIFICATIONS_API_KEY'
    | 'SEASON_DETAILS_TOP_STUDIOS_LIMIT'
    | 'SHORT_TERM_TTL_CACHE_CONTROL_RESPONSE_HEADERS'
    | 'SHOW_MARKETING_CONSENT'
    | 'STORE_SEO_FRIENDLY_MOD_PATH_FORMAT'
    | 'STORE_SEO_FRIENDLY_MOD_WITH_TRACK_PATH_FORMAT'
    | 'STRIPE_API_KEY'
    | 'STRIPE_CONNECT_ACCOUNT_WEBHOOK_KEY'
    | 'STRIPE_CONNECT_ACCOUNT_PAYOUT_MINIMAL_AGE_FROM_PREVIOUS_PAYOUT_DAYS'
    | 'STRIPE_CONNECT_ACCOUNT_PAYOUT_MINIMAL_AMOUNT_USD'
    | 'STRIPE_GAME_DEVELOPER_REVENUE_SHARE_PERCENTAGE'
    | 'STRIPE_SUPPORTED_LANGUAGES'
    | 'STRIPE_WEBHOOK_KEY'
    | 'STUDIO_METRICS_SETTINGS'
    | 'SUBSCRIBER_EMAIL_LENGTH_LIMIT'
    | 'SUPPORTED_CURRENCIES'
    | 'TIME_FRAMES'
    | 'TOP_RATED_CHART_MINIMUM_RATINGS'
    | 'TRANSFER_CONFIRMATION_LINK_MAX_LIFE_TIME'
    | 'TRANSFER_CONFIRMATION_LINK_TTL'
    | 'WORK_RESERVATION_MAX_CONCURRENCY'
    | 'UNRESPONSIVE_RELAY_SNOOZE_MINUTES'
    | 'UPDATED_MARKETING_ATTRIBUTES_BATCH_SIZE'
    | 'UPDATED_MARKETING_ATTRIBUTES_MAX_ITEMS'
    | 'USER_ACTIVITY_UPDATE_INTERVAL_SECONDS'
    | 'USER_DESCRIPTION_LENGTH_LIMIT'
    | 'USER_ID_TOKEN_MAX_AGE_SECONDS'
    | 'USER_ID_TOKEN_PRIVATE_KEY'
    | 'USER_SUPPLEMENTAL_DATA_CACHE_MAX_SIZE'
    | 'USE_ORIGIN_FROM_REFERER_HEADER'
    | 'USE_STABLE_SESSION_REPRESENTATIONS'
    | 'XSOLLA_API_KEY'
    | 'XSOLLA_MERCHANT_ID'
    | 'XSOLLA_PAYSTATION_ENDPOINT'
    | 'XSOLLA_PAYSTATION_SANDBOX_URL'
    | 'XSOLLA_PAYSTATION_URL'
    | 'XSOLLA_PROJECT_ID'
    | 'XSOLLA_SANDBOX_MODE'
    | 'XSOLLA_STORE_ENDPOINT'
    | 'XSOLLA_SUPPORTED_LANGUAGES'
    | 'XSOLLA_WEBHOOK_KEY'
    | null
  type?: 'STRING' | 'INTEGER' | 'BOOLEAN' | 'GRAPHIC' | null
  /**
   * Notes: *This is always returned as a string: refer to `type` for the intended type.
   * Supported types are currently string, number, boolean (`true`/`false`)and graphic (url to graphic)*
   * @example "20"
   */
  value?: string | null
  visibility?: 'VISIBLE' | 'HIDDEN' | null
}

/** Legacy studio settings response */
export interface LegacyStudioSettingResponse {
  /** @example "CUSTOM_GAME_BUILDS_ALLOWED" */
  setting?:
    | 'ADS_MONETIZATION_ALLOWED'
    | 'CLAIM_GAME_ALLOWED'
    | 'CUSTOM_GAME_BUILDS_ALLOWED'
    | 'GAME_BUNDLE_SIZE_LIMIT_MB'
    | 'MONETIZATION_ALLOWED'
    | 'MOD_BUNDLE_SIZE_LIMIT_MB'
    | 'STRIPE_CONNECT_ACCOUNT_COUNTRY'
    | 'STRIPE_CONNECT_ACCOUNT_ID'
    | 'STRIPE_CONNECT_ACCOUNT_PAYMENTS_ALLOWED'
    | null
  type?: 'STRING' | 'INTEGER' | 'BOOLEAN' | 'GRAPHIC' | null
  /**
   * Notes: *This is always returned as a string: refer to `type` for the intended type.
   * Supported types are currently string, number and boolean (`true`/`false`).*
   * @example "false"
   */
  value?: string | null
}

/** List of studios and their legacy settings */
export interface LegacyStudioSettingsListResponse {
  studioSettingsList?: LegacyStudioSettingsResponse[] | null
}

/** List of legacy studio settings for specific studio */
export interface LegacyStudioSettingsResponse {
  studio?: StudioResponse | null
  studioSettings?: LegacyStudioSettingResponse[] | null
}

export type ListAccountTransactionsData = ResponseBankTransactionsResponse

export type ListAccountTransactionsError =
  | GetBankAccountsAccountIdTransactions400ErrorResponse
  | GetBankAccountsAccountIdTransactions403ErrorResponse
  | GetBankAccountsAccountIdTransactions404ErrorResponse

export interface ListAccountTransactionsParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `number:` | 1234567890 | Identify by number. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  accountId: string
  /** Only include transactions with the given category. */
  category?: 'PIXELS_TRANSFERRED' | 'QUEST_COMPLETED' | 'RESPECT_ACTION'
  /**
   * The page to return. The response will contain transactions in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of transactions to include in the response. Fewer transactions may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Only include transactions of the given type. */
  transactionType?: 'CREDIT' | 'DEBIT' | 'DEPOSIT'
}

export type ListAccountsData = ResponseBankAccountsResponse

export type ListAccountsError =
  | GetBankAccounts400ErrorResponse
  | GetBankAccounts403ErrorResponse
  | GetBankAccounts404ErrorResponse

export interface ListAccountsParams {
  /**
   * Only include accounts in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /**
   * The page to return. The response will contain accounts in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of accounts to include in the response. Fewer accounts may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Only include accounts owned by the given user
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId?: string
}

export type ListApiClientsData = ResponseApiClientsResponse

export type ListApiClientsError = GetApiclients400ErrorResponse | GetApiclients403ErrorResponse

export interface ListApiClientsParams {
  /**
   * The page to return. The response will contain API clients in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of API clients to include in the response. Fewer API clients may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListBackendHostsData = ResponseBackendHostsResponse

export type ListBackendHostsError =
  | GetBackendhosts400ErrorResponse
  | GetBackendhosts403ErrorResponse

export interface ListBackendHostsParams {
  /**
   * The page to return. The response will contain backend hosts in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of backend hosts to include in the response. Fewer backend hosts may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListBlockedGamesData = ResponseSeasonBlockedGamesResponse

export type ListBlockedGamesError =
  | GetSeasonsSeasonIdBlockedGames400ErrorResponse
  | GetSeasonsSeasonIdBlockedGames403ErrorResponse
  | GetSeasonsSeasonIdBlockedGames404ErrorResponse

export interface ListBlockedGamesParams {
  /**
   * The page to return. The response will contain blocked games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of blocked games to include in the response. Fewer blocked games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  seasonId: string
}

export type ListBlockedStudiosData = ResponseSeasonBlockedStudiosResponse

export type ListBlockedStudiosError =
  | GetSeasonsSeasonIdBlockedStudios400ErrorResponse
  | GetSeasonsSeasonIdBlockedStudios403ErrorResponse
  | GetSeasonsSeasonIdBlockedStudios404ErrorResponse

export interface ListBlockedStudiosParams {
  /**
   * The page to return. The response will contain blocked studios in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of blocked studios to include in the response. Fewer blocked studios may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  seasonId: string
}

export type ListClientsData = ResponseListString

export type ListClientsError = GetDynamicConfigClients403ErrorResponse

export type ListCodesData = ResponseOfferCodesResponse

export type ListCodesError =
  | GetOffersOfferIdCodes400ErrorResponse
  | GetOffersOfferIdCodes403ErrorResponse
  | GetOffersOfferIdCodes404ErrorResponse

export interface ListCodesParams {
  /**
   * Only include codes in given active state
   * @default "ANY"
   */
  activeState?: 'ANY' | 'ACTIVE' | 'NOT_ACTIVE'
  /**
   * Only include codes in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /** Only include codes imported as part of the given batch */
  batchAlias?: string
  /**
   * ISO-3166-1 code of the country the code should be claimed in
   * @example "SE"
   */
  claimCountryCode?: string
  /**
   * ISO-3166-2 code of the region the code should be claimed in
   * @example "SE-AB"
   */
  claimRegionCode?: string
  /**
   * Only include codes in given claimed state
   * @default "ANY"
   */
  claimedState?: 'ANY' | 'CLAIMED' | 'NOT_CLAIMED'
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
  /**
   * The page to return. The response will contain codes in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of codes to include in the response. Fewer codes may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Only include codes in given redeemed state
   * @default "ANY"
   */
  redeemedState?: 'ANY' | 'REDEEMED' | 'NOT_REDEEMED'
  /**
   * ISO-3166-1 code of the country the code should last have been redeemed in
   * @example "SE"
   */
  redemptionCountryCode?: string
}

export type ListConfigurationsData = ResponseCmsConfigurationsResponse

export type ListConfigurationsError =
  | GetCmsConfigurations400ErrorResponse
  | GetCmsConfigurations403ErrorResponse

export interface ListConfigurationsParams {
  /**
   * The page to return. The response will contain configurations in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of configurations to include in the response. Fewer configurations may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `alias-asc`
   */
  sort?: ('alias-asc' | 'alias-desc')[]
}

export type ListData = ResponseCdnHostingsResponse

export type ListData1 = ResponseScheduledTasksResponse

export type ListDeploymentsData = ResponseWrapperDeploymentsResponse

export type ListDeploymentsError =
  | GetWrappersWrapperIdDeployments400ErrorResponse
  | GetWrappersWrapperIdDeployments403ErrorResponse
  | GetWrappersWrapperIdDeployments404ErrorResponse

export interface ListDeploymentsParams {
  /**
   * The page to return. The response will contain deployments in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of deployments to include in the response. Fewer deployments may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  wrapperId: string
}

export type ListDynamicConfigsData = ResponseDynamicConfigsResponse

export type ListDynamicConfigsError =
  | GetDynamicConfig400ErrorResponse
  | GetDynamicConfig403ErrorResponse

export interface ListDynamicConfigsParams {
  /**
   * Param to list configs by client alias
   * @example "netinstaller"
   */
  clientAlias?: string
  /**
   * The page to return. The response will contain dynamic configs in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of dynamic configs to include in the response. Fewer dynamic configs may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Config title search query.
   * @example "conf"
   */
  titleSearch?: string
}

export type ListError = GetCdnHostings400ErrorResponse | GetCdnHostings403ErrorResponse

export type ListErrorData = GetScheduledTasks403ErrorResponse

export type ListFail =
  | GetGameWrappers400ErrorResponse
  | GetGameWrappers403ErrorResponse
  | GetGameWrappers404ErrorResponse

export type ListFails =
  | GetOffersOfferIdErrorPages400ErrorResponse
  | GetOffersOfferIdErrorPages403ErrorResponse
  | GetOffersOfferIdErrorPages404ErrorResponse

export type ListFormatsData = ResponseListString

export type ListFormatsError = GetAssetVariantsFormats403ErrorResponse

export type ListGameControlGroupsData = ResponseListGameControlGroupsResponse

export type ListGameControlGroupsError =
  | GetGamesGameIdControlGroups403ErrorResponse
  | GetGamesGameIdControlGroups404ErrorResponse

/** ListGameControlGroupsResponse */
export interface ListGameControlGroupsResponse {
  /** List of game control groups */
  groups?: GameControlGroupResponse[] | null
}

export type ListGamesData = ResponseGamesResponse

export type ListGamesError =
  | GetTagsTagIdGames400ErrorResponse
  | GetTagsTagIdGames403ErrorResponse
  | GetTagsTagIdGames404ErrorResponse

export interface ListGamesParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  tagId: string
}

export type ListHttpError = GetWrappers400ErrorResponse | GetWrappers403ErrorResponse

export type ListModsForKeyData = ResponseModsResponse

export type ListModsForKeyError =
  | GetOdksKeyIdMods400ErrorResponse
  | GetOdksKeyIdMods403ErrorResponse
  | GetOdksKeyIdMods404ErrorResponse

export interface ListModsForKeyParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  keyId: string
  /**
   * The page to return. The response will contain official distribution keys in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of official distribution keys to include in the response. Fewer official distribution keys may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListMultiplayerGuestAvatarsData = ResponseMultiplayerGuestAvatarsResponse

export type ListMultiplayerGuestAvatarsError =
  | GetMultiplayerGuestAvatars400ErrorResponse
  | GetMultiplayerGuestAvatars403ErrorResponse

export interface ListMultiplayerGuestAvatarsParams {
  /**
   * The page to return. The response will contain multiplayerGuestAvatars in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of multiplayerGuestAvatars to include in the response. Fewer multiplayerGuestAvatars may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListOffersData = ResponseOffersResponse

export type ListOffersError =
  | GetOffers400ErrorResponse
  | GetOffers403ErrorResponse
  | GetOffers404ErrorResponse

export interface ListOffersParams {
  /**
   * Only include offers in given active state
   * @default "ANY"
   */
  activeState?: 'ANY' | 'ACTIVE' | 'NOT_ACTIVE'
  /**
   * Only include offers in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /**
   * Only include offers that are/aren't internal
   * @default "ANY"
   */
  internalState?: 'ANY' | 'INTERNAL' | 'NOT_INTERNAL'
  /**
   * Only include offers for the given partner
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  maybePartner?: string
  /**
   * The page to return. The response will contain offers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of offers to include in the response. Fewer offers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListOfficialDistributionKeysData = ResponseOdksResponse

export type ListOfficialDistributionKeysError = GetOdks400ErrorResponse | GetOdks403ErrorResponse

export interface ListOfficialDistributionKeysParams {
  /**
   * Only include official distribution keys in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /**
   * The page to return. The response will contain official distribution keys in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of official distribution keys to include in the response. Fewer official distribution keys may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListOutput = ResponseOfferErrorPagesResponse

export interface ListParams {
  /**
   * The page to return. The response will contain CDN hostings in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of CDN hostings to include in the response. Fewer CDN hostings may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export interface ListParams2 {
  /** Bucket identifier(s) of wrappers to filter by. */
  bucketIdentifier?: ('GX' | 'BONUS' | 'STORE')[]
  /**
   * Filter by whether wrapper is default or not
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  default?: boolean
  /**
   * Game engine id(s) of wrappers to filter by.
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   */
  gameEngineId?: string[]
  /**
   * The page to return. The response will contain game wrappers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of game wrappers to include in the response. Fewer game wrappers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export interface ListParams4 {
  /**
   * Only include error pages in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
  /**
   * The page to return. The response will contain error pages in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of error pages to include in the response. Fewer error pages may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export interface ListParams7 {
  /**
   * The page to return. The response will contain wrappers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of wrappers to include in the response. Fewer wrappers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Include only wrappers of the specified type */
  wrapperType?: 'GAME' | 'MOD'
}

export type ListPartnersData = ResponsePartnersResponse

export type ListPartnersError = GetOfferPartners400ErrorResponse | GetOfferPartners403ErrorResponse

export type ListPartnersFail = GetPartners400ErrorResponse | GetPartners403ErrorResponse

export interface ListPartnersParams {
  /**
   * Only include partners in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /**
   * The page to return. The response will contain partners in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of partners to include in the response. Fewer partners may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export interface ListPartnersParams2 {
  /**
   * Only include partners in given archived state
   * @default "NOT_ARCHIVED"
   */
  archivedState?: 'ANY' | 'ARCHIVED' | 'NOT_ARCHIVED'
  /**
   * The page to return. The response will contain partners in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of partners to include in the response. Fewer partners may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListPartnersResult = ResponsePartnersResponse

export type ListPendingData = ResponseAssetVariantConfigurationsResponse

export type ListPendingError =
  | GetAssetVariantsConfigurationsPending400ErrorResponse
  | GetAssetVariantsConfigurationsPending403ErrorResponse

export interface ListPendingParams {
  /**
   * The page to return. The response will contain asset variant configurations in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of asset variant configurations to include in the response. Fewer asset variant configurations may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListPointsData = ResponsePointsTransactionsResponse

export type ListPointsError =
  | GetSeasonsSeasonIdPointsTransactions400ErrorResponse
  | GetSeasonsSeasonIdPointsTransactions403ErrorResponse
  | GetSeasonsSeasonIdPointsTransactions404ErrorResponse

export interface ListPointsParams {
  /**
   * Only include transactions for a given scoring period
   * @format date
   */
  forPeriod?: string
  /** Only include transactions from a given studio metric (by metric key) */
  fromMetric?: string
  /**
   * Only include active transactions
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default true
   * @example "false"
   */
  onlyActive?: boolean
  /**
   * Only include manual transactions
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   * @example "true"
   */
  onlyManual?: boolean
  /**
   * Only include pending transactions. Overrides the `onlyActive` query parameter.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   * @example "true"
   */
  onlyPending?: boolean
  /**
   * The page to return. The response will contain points transactions in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of points transactions to include in the response. Fewer points transactions may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  seasonId: string
}

export type ListProcessedReleasesData = ResponseWrapperDeploymentReleasesResponse

export type ListProcessedReleasesError =
  | GetWrappersWrapperIdDeploymentsDeploymentIdReleases400ErrorResponse
  | GetWrappersWrapperIdDeploymentsDeploymentIdReleases403ErrorResponse
  | GetWrappersWrapperIdDeploymentsDeploymentIdReleases404ErrorResponse

export interface ListProcessedReleasesParams {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  deploymentId: string
  /**
   * The page to return. The response will contain processed releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of processed releases to include in the response. Fewer processed releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  wrapperId: string
}

export type ListProfileVariantConfigurationsData = ResponseAssetVariantConfigurationsResponse

export type ListProfileVariantConfigurationsError =
  | GetAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse
  | GetAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse
  | GetAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse

export interface ListProfileVariantConfigurationsParams {
  /**
   * The page to return. The response will contain variant configurations in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of variant configurations to include in the response. Fewer variant configurations may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  profileKey: string
}

export type ListProfilesData = ResponseAssetVariantsProfilesResponse

export type ListProfilesError =
  | GetAssetVariantsProfiles400ErrorResponse
  | GetAssetVariantsProfiles403ErrorResponse

export interface ListProfilesParams {
  /**
   * The page to return. The response will contain asset variants profiles in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of asset variants profiles to include in the response. Fewer asset variants profiles may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** ListPublishersResponse */
export interface ListPublishersResponse {
  pagination: PaginationResponse
  publishers: PublisherResponse[]
}

export type ListRelaysData = ResponseMultiplayerRelaysResponse

export type ListRelaysError =
  | GetMultiplayerRelays400ErrorResponse
  | GetMultiplayerRelays403ErrorResponse

export interface ListRelaysParams {
  /**
   * The page to return. The response will contain relays in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of relays to include in the response. Fewer relays may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Call each relay to refresh active room counts.
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  updateActiveRoomCounts?: boolean
}

export type ListReleasesData = ResponseModReleasesResponse

export type ListReleasesError =
  | GetModsModIdTracksTrackIdReleases400ErrorResponse
  | GetModsModIdTracksTrackIdReleases403ErrorResponse
  | GetModsModIdTracksTrackIdReleases404ErrorResponse

export interface ListReleasesParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId: string
  /**
   * The page to return. The response will contain mod releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod releases to include in the response. Fewer mod releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  trackId: string
}

export type ListRespectPaymentsData = ResponseRespectPaymentsResponse

export type ListRespectPaymentsError =
  | GetRespectPayments400ErrorResponse
  | GetRespectPayments403ErrorResponse
  | GetRespectPayments404ErrorResponse

export interface ListRespectPaymentsParams {
  /**
   * Filter by studios that received respect which this user is contributor in
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  contributorId?: string
  /**
   * Filter by game which received respect
   *
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId?: string
  /**
   * The page to return. The response will contain respect payments in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of respect payments to include in the response. Fewer respect payments may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Sort order. Can be used multiple times, or be a comma-separated list. If multiple sort options set, the later options are used to sort items that are equal according to the earlier sorting options. If the selected sorting doesn't enforce strict total order of elements, the items will be returned in a stable but unspecified order.
   *
   * *Default value*: `creation-date-desc`
   */
  sort?: ('creation-date-asc' | 'creation-date-desc')[]
  /**
   * If present, works as a filter for active status of related sticker
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  stickerActive?: boolean
  /**
   * Filter by user who paid respect
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId?: string
}

export type ListResult = ResponseGameWrappersResponse

export type ListResult1 = ResponseWrappersResponse

export type ListSeasonsData = ResponseSeasonsResponse

export type ListSeasonsError = GetSeasons400ErrorResponse | GetSeasons403ErrorResponse

export interface ListSeasonsParams {
  /**
   * Include deleted seasons as well
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  includeDeleted?: boolean
  /**
   * The page to return. The response will contain seasons in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of seasons to include in the response. Fewer seasons may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /**
   * Include only published/unpublished seasons
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  published?: boolean
  /** Include only seasons of the specified type */
  seasonType?: 'PLAYER' | 'STUDIO'
}

export type ListSectionsData = ResponseCmsSectionsResponse

export type ListSectionsError =
  | GetCmsConfigurationsConfigurationIdSections403ErrorResponse
  | GetCmsConfigurationsConfigurationIdSections404ErrorResponse

export type ListSettingOverrideTransactionsData = ResponseSettingOverrideTransactionsResponse

export type ListSettingOverrideTransactionsError =
  | GetSettingsTransactions400ErrorResponse
  | GetSettingsTransactions403ErrorResponse

export interface ListSettingOverrideTransactionsParams {
  /**
   * The page to return. The response will contain transactions in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of transactions to include in the response. Fewer transactions may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Include only transactions that changed the given setting. */
  settingId?: string
}

export type ListSettingsV2Data = ResponseSettingsRootNamespacesResponse

export type ListSettingsV2Error = GetV2Settings403ErrorResponse

export interface ListSettingsV2Params {
  /** Return overrides for the entity with the given id. Ignored unless `entityType` is also provided. */
  entityId?: string
  /** Include only settings that support being overridden for the given type of entity. */
  entityType?:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
  /**
   * Include only namespaces and settings with a maximum of this many ancestor namespaces. A value of `0` includes only the root namespaces, but no sub-namespaces or actual settings.
   * @format int32
   */
  maxDepth?: number
  /**
   * Include only settings in the specified namespaces and their sub-namespaces. Can be a comma-separated list or be specified multiple times.
   * @example "system.integration"
   */
  namespace?: string[]
  /** Include only settings whose title contains the specified substring (case-insensitive). */
  titleSearch?: string
}

export type ListStickersData = ResponseStickerPageResponse

export type ListStickersError = GetStickers400ErrorResponse | GetStickers403ErrorResponse

export interface ListStickersParams {
  /**
   * If present, works as a filter for active status
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   */
  active?: boolean
  /**
   * The page to return. The response will contain stickers in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of stickers to include in the response. Fewer stickers may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Title substring to filter by */
  titleSearch?: string
}

/**
 * If present, this feature list is used as the default mod flavor features for this mod.
 * @example "["mod.themes"]"
 */
export type ListString = string[]

export type ListStudiosForKeyData = ResponseStudiosResponse

export type ListStudiosForKeyError =
  | GetOdksKeyIdStudios400ErrorResponse
  | GetOdksKeyIdStudios403ErrorResponse
  | GetOdksKeyIdStudios404ErrorResponse

export interface ListStudiosForKeyParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `alias:` | alias:sample-text | Identify by alias. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  keyId: string
  /**
   * The page to return. The response will contain official distribution keys in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of official distribution keys to include in the response. Fewer official distribution keys may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type ListTransactionsData = ResponseBankTransactionsResponse

export type ListTransactionsError =
  | GetBankTransactions400ErrorResponse
  | GetBankTransactions403ErrorResponse

export interface ListTransactionsParams {
  /** Only include transactions with the given category. */
  category?: 'PIXELS_TRANSFERRED' | 'QUEST_COMPLETED' | 'RESPECT_ACTION'
  /**
   * The page to return. The response will contain transactions in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of transactions to include in the response. Fewer transactions may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Only include transactions of the given type. */
  transactionType?: 'CREDIT' | 'DEBIT' | 'DEPOSIT'
}

/** LocalesResponse */
export interface LocalesResponse {
  /** Additional locales */
  additionalLocales?: string[] | null
  /** Default locale */
  defaultLocale?: string | null
}

/** Managed feed details */
export interface ManagedFeedDetailsResponse {
  /** @example "soundtrack-by-big-o-and-hasan" */
  alias?: string | null
  /**
   * True if the global filter is NOT applied when filtering by this feed
   *
   * Notes: *In other words, if this feed contains some entries that are normally blocked by the global filter, then when filtering by this feed or otherwise listing this feed's contents, those entries are returned.*
   */
  bypassGlobalFilter?: boolean | null
  descriptionString?: StringResponse | null
  /** True if the daily feed entries rotation enabled */
  entriesRotationEnabled?: boolean | null
  /** Notes: ***Sorted** list of entry IDs in this feed* */
  entryIds?: string[] | null
  /**
   * ID of the feed entry: used for reordering/deleting entries.
   * @example "b45a8eb6-df58-4551-b30e-2a1c56d9077f"
   */
  feedId: string
  /**
   * Feed entity version
   *
   * Notes: *Will be an empty string if the feed's version has not been initialized.*
   * @example "1"
   */
  feedVersion?: string | null
  /**
   * Filtered feed details.
   *
   * Notes: *Will be null iff `type` is not `FILTERED_FEED`*
   */
  filteredFeed?: FilteredFeedDetailsResponse | null
  /** True if this feed is currently selected as the global filter */
  isGlobalFilter?: boolean | null
  /**
   * Alias of the managed feed.
   *
   * *Deprecated in favor of `alias`*
   * @deprecated
   * @example "soundtrack-by-big-b-and-hasan"
   */
  name?: string | null
  /** Pending changes details. Null if there are no pending changes. */
  pendingChanges?: PendingChangesResponse | null
  /** Feed parameters. */
  sort?: ManagedFeedSortResponse | null
  timeFrame?: ManagedFeedTimeFrameResponse | null
  titleString?: StringResponse | null
  type?: 'CUSTOM_FEED' | 'GAME_JAM' | 'USER_JAM' | 'VOTING' | 'FILTERED_FEED' | null
}

/** ManagedFeedSortResponse */
export interface ManagedFeedSortResponse {
  gameSort?: string[] | null
  modSort?: string[] | null
}

/** Managed feed summarised */
export interface ManagedFeedSummaryResponse {
  /** @example "made-during-the-december-2021-gms-coding-competition" */
  alias?: string | null
  /**
   * True if the global filter is NOT applied when filtering by this feed
   *
   * Notes: *In other words, if this feed contains some entries that are normally blocked by the global filter, then when filtering by this feed or otherwise listing this feed's contents, those entries are returned.*
   */
  bypassGlobalFilter?: boolean | null
  /**
   * @format int64
   * @example 5
   */
  challengeEntries?: number | null
  descriptionString?: StringResponse | null
  /** @example "52153a4f-079b-490d-b21b-f64e838eab84" */
  feedId: string
  /**
   * Feed entity version
   *
   * Notes: *Will be an empty string if the feed's version has not been initialized.*
   * @example "1"
   */
  feedVersion?: string | null
  /**
   * @format int64
   * @example 3
   */
  gameEntries?: number | null
  /** True if this feed is currently selected as the global filter */
  isGlobalFilter?: boolean | null
  /**
   * Alias of the managed feed.
   *
   * *Deprecated in favor of `alias`*
   * @deprecated
   * @example "made-during-the-december-2021-gms-coding-competition"
   */
  name?: string | null
  titleString?: StringResponse | null
  type?: 'CUSTOM_FEED' | 'GAME_JAM' | 'USER_JAM' | 'VOTING' | 'FILTERED_FEED' | null
}

/** Time frame endpoint (start or end). Exactly one of the `absoluteDate` and `relativeDate` fields will be non-null, and the other null. */
export interface ManagedFeedTimeFrameEndpointResponse {
  /** @format date */
  absoluteDate?: string | null
  /**
   * Relative date in milliseconds.
   *
   * Notes: *Subtracted from the current time to get the effective date.*
   * @format int64
   */
  relativeDate?: number | null
}

/** ManagedFeedTimeFrameResponse */
export interface ManagedFeedTimeFrameResponse {
  end?: ManagedFeedTimeFrameEndpointResponse | null
  start?: ManagedFeedTimeFrameEndpointResponse | null
}

/** List of managed feeds */
export interface ManagedFeedsResponse {
  feeds: ManagedFeedSummaryResponse[]
  pagination: PaginationResponse
}

/** Map<String, Object> */
export type MapStringObject = Record<string, any>

/** MediaResponse */
export interface MediaResponse {
  /** @example "f26d9a15-4f65-4412-8590-5a69b46009d1" */
  assetId?: string | null
  /** @example "https://example.com/1.png" */
  thumbnailUrl?: string | null
  /** @example "IMAGE" */
  type?: 'IMAGE' | 'VIDEO' | null
  /** @example "https://example.com/watch?v=123456" */
  url?: string | null
}

/**  mod details */
export interface ModDetailsResponse {
  /** @example "EVERYONE" */
  ageRating?:
    | 'NOT_SET'
    | 'EVERYONE'
    | 'CHILDREN'
    | 'EARLY_TEENS'
    | 'TEENS'
    | 'ADULTS'
    | 'MATURE'
    | null
  blockAction?: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION' | null
  blocked?: boolean | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  creationDate?: string | null
  /** @example "ghbmnnjooekpmoecnnnilnnbdlolhkhi" */
  crxId?: string | null
  /** The list of default mod flavor features for this mod, or `null` if none are configured. */
  defaultModFlavorFeatures?: string[] | null
  graphics?: MediaResponse[] | null
  /** Whether this mod should be hidden from the response of `GET /store/v3/mods`. */
  hiddenFromListing?: boolean | null
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  lastModified?: string | null
  /** @format date-time */
  lastReportedAt?: string | null
  /** @example "# Long description, **containing markdown**" */
  longDescription?: string | null
  /** @example "mod-v3-" */
  mangledTitle?: string | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  modId: string
  /** @example "asdfw4" */
  modShortId?: string | null
  modTags?: TagSummaryResponse[] | null
  modTypeTags?: TagSummaryResponse[] | null
  /**
   * Mod entity version
   * @example "1"
   */
  modVersion?: string | null
  modWallpaperTypeTags?: TagSummaryResponse[] | null
  /**
   * @format int64
   * @example 10
   */
  numberOfDownloads?: number | null
  /** Notes: *List of distribution keys associated to the mod.* */
  officialDistributionKeys?: OdkResponse[] | null
  releaseTracks?: ModReleaseTrackDetailsResponse[] | null
  studio?: StudioResponse | null
  /** Notes: *List of distribution keys associated to the mod's studio.* */
  studioOfficialDistributionKeys?: OdkResponse[] | null
  /** @example "MyMod" */
  title?: string | null
  unresolvedReports?: ReportsByReasonResponse | null
  /** True if user feedback is allowed for this mod */
  userFeedbackAllowed?: boolean | null
  /** True if automatic wrapper deployment releases are enabled for this mod */
  wrapperDeploymentReleasesEnabled?: boolean | null
}

/**  mod manifest validation diagnostic response */
export interface ModDiagnosticResponse {
  /** Error code of the diagnostic failure */
  errorCode?:
    | 'AppIconNotFound'
    | 'AuthorInvalid'
    | 'BackgroundMusicNotFound'
    | 'BrowserSoundsNotFound'
    | 'DescriptionMissing'
    | 'DeveloperInvalid'
    | 'DeveloperMissing'
    | 'FirstFrameNotFound'
    | 'FontNotFound'
    | 'GameStripEntrypointNotFound'
    | 'GroupsLayersResourceNotFound'
    | 'GroupsShaderNotFound'
    | 'IconInvalidImage'
    | 'IconNotFound'
    | 'IconSizeTooLarge'
    | 'IconSizeTooSmall'
    | 'IconUnsupportedFormat'
    | 'IconUnsupportedImageType'
    | 'IconWrongDimensions'
    | 'IconsMissing'
    | 'IconsMissingSizes'
    | 'ImageOverrideImageInvalidName'
    | 'ImageOverrideImageNotFound'
    | 'InvalidIconName'
    | 'InvalidIconPath'
    | 'KeyboardSoundsNotFound'
    | 'ManifestVersionMissing'
    | 'MobileImageOverrideImageNotFound'
    | 'MobileImageOverrideImageInvalidName'
    | 'ModLicenseInvalid'
    | 'ModMissing'
    | 'ModPayloadMissing'
    | 'ModSchemaVersionMissing'
    | 'NameInvalid'
    | 'NameMissing'
    | 'NameTooLong'
    | 'PageStylesCssNotFound'
    | 'ShadersNotFound'
    | 'SplashScreenVideoNotFound'
    | 'StickerImageInvalid'
    | 'StickerImageNotFound'
    | 'StickerPreviewInvalid'
    | 'StickerPreviewNotFound'
    | 'TooFewIcons'
    | 'TooManyIcons'
    | 'TooManyWallpapers'
    | 'UnknownFields'
    | 'UnsupportedModSchemaVersion'
    | 'VersionInvalid'
    | 'VersionMissing'
    | 'VibePaletteNotFound'
    | 'VibePreviewNotFound'
    | 'WallpaperDarkFirstFrameInvalidImage'
    | 'WallpaperImageMobileNotFound'
    | 'WallpaperImageNotFound'
    | 'WallpaperLightFirstFrameInvalidImage'
    | 'WallpaperPreviewDarkInvalidDimensions'
    | 'WallpaperPreviewDarkInvalidImage'
    | 'WallpaperPreviewDarkUnsupportedFormat'
    | 'WallpaperPreviewLightInvalidDimensions'
    | 'WallpaperPreviewLightInvalidImage'
    | 'WallpaperPreviewLightUnsupportedFormat'
    | 'WallpaperPreviewNotFound'
    | 'WrongManifestVersion'
    | 'WrongModSchemaVersion'
    | null
  /**
   * JSON path to a field where the error appeared
   * @example "$.name"
   */
  jsonPath?: string | null
  /** Diagnostic level */
  level?: 'ERROR' | 'WARNING' | null
  /** Possible error details */
  message?: string | null
}

/** ModFlavorListResponse */
export interface ModFlavorListResponse {
  flavors?: ModFlavorResponse[] | null
}

/** ModFlavorResponse */
export interface ModFlavorResponse {
  /**
   * The date at which the mod flavor was finished
   *
   * Notes: *`null` if the flavor is not finished yet*
   * @format date-time
   */
  createdAt?: string | null
  /** The features applied to the mod flavor */
  features?: string[] | null
  /** ID of the mod flavor */
  flavorId: string
  /** ID of the mod flavor's parent flavor */
  parentFlavorId?: string | null
  /**
   * The size, in bytes, of the mod flavor's CRX
   *
   * Notes: *`null` if the size hasn't been recorded yet*
   * @format int64
   */
  size?: number | null
  /**
   * The state of the mod flavor
   *
   * Notes: *Included for debugging purposes.
   *
   * The `FINISHED` state implies that the mod flavor has
   * finished its transformation and is ready to be installed.
   *
   * The `IN_PROGRESS` state implies that the mod flavor has been
   * requested and is being transformed, but has not finished.
   *
   * The `PENDING` state implies that the mod flavor's parent flavor
   * is being generated and this mod flavor will begin its
   * transformation automatically after the parent flavor is
   * finished.
   *
   * The `FAILED` state implies that the mod flavor, or its parent
   * flavor, failed somehow during transformation and it will not
   * be usable.
   *
   * The `OBSOLETE` state implies that the mod flavor is not to be
   * installed, and can be considered broken in some manner. This
   * could happen if the deletion process is interrupted and the
   * mod flavor cannot be guaranteed to remain in its former state.*
   */
  state?: 'PENDING' | 'IN_PROGRESS' | 'FINISHED' | 'FAILED' | 'OBSOLETE' | null
}

/** BO mod/ODK assignment response */
export interface ModOdkResponse {
  key?: OdkDetailsResponse | null
  mod?: ModDetailsResponse | null
}

/** ModReleaseDetailsResponse */
export interface ModReleaseDetailsResponse {
  author?: UserSummaryResponse | null
  description?: string | null
  /** Mod manifest validation diagnostics */
  diagnostics?: ModDiagnosticResponse[] | null
  /** Mod icons. */
  icons?: IconResponse[] | null
  /** Manifest source code. */
  manifestSource?: MapStringObject | null
  /** Official distribution keys */
  officialDistributionKeys?: OdkResponse[] | null
  platforms?: ('MOBILE' | 'DESKTOP')[] | null
  /**
   * Mod release entity id
   * @example "89fb9376-6a15-4cf3-80fc-066f945e81d9"
   */
  releaseId: string
  releaseType?:
    | 'AUTOMATIC'
    | 'BO_REPACKAGE'
    | 'DC_MANIFEST_EDIT'
    | 'DC_PROMOTE'
    | 'DC_REPACKAGE'
    | 'DC_UPLOAD'
    | 'DEV_PORTAL_MANIFEST_EDIT'
    | 'DEV_PORTAL_PROMOTE'
    | 'DEV_PORTAL_UPLOAD'
    | 'DEV_PORTAL_REPACKAGE'
    | 'GM_UPLOAD'
    | 'UNCLASSIFIED'
    | null
  /**
   * Mod release entity version
   * @example "1"
   */
  releaseVersion?: string | null
  runner?: ModRunnerResponse | null
  state?: 'PENDING' | 'CURRENT' | 'OBSOLETE' | 'DELETED' | null
  title?: string | null
  types?:
    | (
        | 'APP_ICON'
        | 'BACKGROUND_MUSIC'
        | 'BROWSER_SOUNDS'
        | 'FONTS'
        | 'IMAGE_OVERRIDES'
        | 'KEYBOARD_SOUNDS'
        | 'MOBILE_IMAGE_OVERRIDES'
        | 'PAGE_STYLE'
        | 'SD_EFFECTS'
        | 'SHADER'
        | 'SPLASH_SCREEN'
        | 'STICKERS'
        | 'THEME'
        | 'WALLPAPER'
        | 'GAME_STRIP'
      )[]
    | null
  /**
   * @format date-time
   * @example "2021-07-22T10:57:17.031Z"
   */
  uploadedAt?: string | null
  /** @example "0.1.0" */
  version?: string | null
  /** Mod previews. For "OBSOLETE" or "DELETED" releases the fields "assetId" and "url" can be `null` for preview. */
  wallpaperPreviews?: WallpaperPreviewResponse[] | null
  wallpaperTypes?: ('IMAGE' | 'MOBILE_IMAGE' | 'VIDEO' | 'LIVE' | 'COLORIZED')[] | null
  /** Bundled wrapper, or null */
  wrapper?: WrapperResponse | null
}

/** ModReleaseResponse */
export interface ModReleaseResponse {
  author?: UserSummaryResponse | null
  description?: string | null
  platforms?: ('MOBILE' | 'DESKTOP')[] | null
  /**
   * Mod release entity id
   * @example "89fb9376-6a15-4cf3-80fc-066f945e81d9"
   */
  releaseId: string
  releaseType?:
    | 'AUTOMATIC'
    | 'BO_REPACKAGE'
    | 'DC_MANIFEST_EDIT'
    | 'DC_PROMOTE'
    | 'DC_REPACKAGE'
    | 'DC_UPLOAD'
    | 'DEV_PORTAL_MANIFEST_EDIT'
    | 'DEV_PORTAL_PROMOTE'
    | 'DEV_PORTAL_UPLOAD'
    | 'DEV_PORTAL_REPACKAGE'
    | 'GM_UPLOAD'
    | 'UNCLASSIFIED'
    | null
  /**
   * Mod release entity version
   * @example "1"
   */
  releaseVersion?: string | null
  runner?: ModRunnerResponse | null
  state?: 'PENDING' | 'CURRENT' | 'OBSOLETE' | 'DELETED' | null
  title?: string | null
  types?:
    | (
        | 'APP_ICON'
        | 'BACKGROUND_MUSIC'
        | 'BROWSER_SOUNDS'
        | 'FONTS'
        | 'IMAGE_OVERRIDES'
        | 'KEYBOARD_SOUNDS'
        | 'MOBILE_IMAGE_OVERRIDES'
        | 'PAGE_STYLE'
        | 'SD_EFFECTS'
        | 'SHADER'
        | 'SPLASH_SCREEN'
        | 'STICKERS'
        | 'THEME'
        | 'WALLPAPER'
        | 'GAME_STRIP'
      )[]
    | null
  /**
   * @format date-time
   * @example "2021-07-22T10:57:17.031Z"
   */
  uploadedAt?: string | null
  /** @example "0.1.0" */
  version?: string | null
  /** Mod previews. For "OBSOLETE" or "DELETED" releases the fields "assetId" and "url" can be `null` for preview. */
  wallpaperPreviews?: WallpaperPreviewResponse[] | null
  wallpaperTypes?: ('IMAGE' | 'MOBILE_IMAGE' | 'VIDEO' | 'LIVE' | 'COLORIZED')[] | null
}

/** BO mod release track details */
export interface ModReleaseTrackDetailsResponse {
  currentRelease?: ModReleaseDetailsResponse | null
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  fromGameReleaseTrack?: string | null
  /** @example false */
  published?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  releaseTrackId: string
  /**
   * Allowed values: `public`, `internal`
   * @example "public"
   */
  type?: 'public' | 'internal' | null
  /** @example true */
  useWrapper?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  wrapperId?: string | null
}

/** BO mod release track */
export interface ModReleaseTrackResponse {
  currentRelease?: ModReleaseResponse | null
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  fromGameReleaseTrack?: string | null
  /** @example false */
  published?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  releaseTrackId: string
  /**
   * Allowed values: `public`, `internal`
   * @example "public"
   */
  type?: 'public' | 'internal' | null
  /** @example true */
  useWrapper?: boolean | null
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  wrapperId?: string | null
}

/** BO paginated list of summary mod release details responses */
export interface ModReleasesDetailsResponse {
  modReleases: ModReleaseDetailsResponse[]
  pagination: PaginationResponse
  releaseIdsWithErrors: string[]
}

/**  mod releases */
export interface ModReleasesResponse {
  pagination: PaginationResponse
  releases: ModReleaseResponse[]
}

/** ModReviewRequestsResponse */
export interface ModReviewRequestsResponse {
  internalReleaseLink?: string | null
  mod?: ModDetailsResponse | null
  pagination: PaginationResponse
  publicReleaseLink?: string | null
  reviewRequests: ReviewRequestResponse[]
}

/** ModReviewsResponse */
export interface ModReviewsResponse {
  fromReviews?: PageReview | null
  modId: string
  modShortId?: string | null
  modVersion?: string | null
  pagination: PaginationResponse
  reviews: ReviewResponse[]
}

/** ModRunnerResponse */
export interface ModRunnerResponse {
  /** @example "["WIN", "MAC", "WASM"]" */
  platforms?: string[] | null
  /** @example "2023.400.0.314" */
  runnerVersion?: string | null
}

/**  mod summary */
export interface ModSummaryResponse {
  blockAction?: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION' | null
  blocked?: boolean | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  creationDate?: string | null
  /** @example "ghbmnnjooekpmoecnnnilnnbdlolhkhi" */
  crxId?: string | null
  /**
   * @format date-time
   * @example "2021-07-22T08:52:21.473Z"
   */
  lastModified?: string | null
  /** @format date-time */
  lastReportedAt?: string | null
  /** @example "mod-v3-" */
  mangledTitle?: string | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  modId: string
  /** @example "asdfw4" */
  modShortId?: string | null
  modTags?: TagSummaryResponse[] | null
  modTypeTags?: TagSummaryResponse[] | null
  /**
   * Mod entity version
   * @example "1"
   */
  modVersion?: string | null
  modWallpaperTypeTags?: TagSummaryResponse[] | null
  releaseTracks?: ModReleaseTrackResponse[] | null
  studio?: StudioResponse | null
  /** @example "MyMod" */
  title?: string | null
  unresolvedReports?: ReportsByReasonResponse | null
}

/** BO mod title response */
export interface ModTitleResponse {
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  modId: string
  /** @example "MyMod" */
  title?: string | null
}

/** ModTransferResponse */
export interface ModTransferResponse {
  /** @format date-time */
  createdAt?: string | null
  mod?: ModSummaryResponse | null
  originalStudio?: StudioResponse | null
  requester?: UserSummaryResponse | null
  /** @format date-time */
  resolvedAt?: string | null
  reviewer?: BackOfficeUserResponse | null
  reviewerComment?: string | null
  status?: 'ABORTED' | 'APPROVED' | 'EXPIRED' | 'PENDING' | 'REJECTED' | null
  targetStudio?: StudioResponse | null
  targetStudioOwnerWhenRequested?: UserSummaryResponse | null
  transferId: string
}

/** ModTransfersResponse */
export interface ModTransfersResponse {
  modTransfers: ModTransferResponse[]
  pagination: PaginationResponse
}

/** ModUserFeedbackListResponse */
export interface ModUserFeedbackListResponse {
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  modId: string
  /**
   * Mod entity version
   * @example "1"
   */
  modVersion?: string | null
  pagination: PaginationResponse
  userFeedback: UserFeedbackResponse[]
}

/**  paginated list of mods */
export interface ModsResponse {
  mods: ModSummaryResponse[]
  pagination: PaginationResponse
}

/** MultiplayerGuestAvatarResponse */
export interface MultiplayerGuestAvatarResponse {
  /** Multiplayer guest avatar URL */
  avatarUrl?: string | null
  /** ID of the guest avatar */
  multiplayerGuestAvatarId: string
}

/** MultiplayerGuestAvatarsResponse */
export interface MultiplayerGuestAvatarsResponse {
  multiplayerGuestAvatars: MultiplayerGuestAvatarResponse[]
  pagination: PaginationResponse
}

/** MultiplayerRelayResponse */
export interface MultiplayerRelayResponse {
  /**
   * Current number of active rooms. Unreliable if the relay is marked as unresponsive.
   * @format int64
   */
  activeRoomCount?: number | null
  /**
   * Internal endpoint for gRPC connections from backend instances.
   * @example "relay-13.cluster.internal:50055"
   */
  controlAddress?: string | null
  /** True if the relay is currently marked as unresponsive. */
  isUnresponsive?: boolean | null
  /**
   * Server region.
   * @example "Scandinavia"
   */
  region?: string | null
  /**
   * Public endpoint for connections from players.
   * @example "https://relay-13.multiplayer.gmx.dev"
   */
  relayOrigin?: string | null
}

/** MultiplayerRelaysResponse */
export interface MultiplayerRelaysResponse {
  pagination: PaginationResponse
  relays: MultiplayerRelayResponse[]
}

/** BO official distribution key detailed response */
export interface OdkDetailsResponse {
  /** True if the key has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the key, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** True if this official distribution key has ever been used to sign a mod release. */
  hasBeenUsed?: boolean | null
  /** Official distribution key ID */
  keyId?: string | null
  /** Key type */
  keyType?: 'RSA_2048' | 'RSA_4096' | null
  /** Mods to which this official distribution key is currently assigned. */
  modsWithKey?: ModTitleResponse[] | null
  /** Public key */
  publicKey?: string | null
  /** Studios to which this official distribution key is currently assigned. */
  studiosWithKey?: StudioSummaryResponse[] | null
  tag?: TagDetailsResponse | null
}

/** BO official distribution key response */
export interface OdkResponse {
  /** True if the key has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the key, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** Official distribution key ID */
  keyId?: string | null
  /** Key type */
  keyType?: 'RSA_2048' | 'RSA_4096' | null
  /** Public key */
  publicKey?: string | null
  tag?: TagDetailsResponse | null
}

/** BO official distribution key summary response */
export interface OdkSummaryResponse {
  /** Official distribution key alias */
  alias?: string | null
  /** Official distribution key ID */
  keyId?: string | null
}

/** BO paginated list of official mod distribution keys response */
export interface OdksResponse {
  keys: OdkResponse[]
  pagination: PaginationResponse
}

/** OfferCodeClaimResponse */
export interface OfferCodeClaimResponse {
  /** True if the claim has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the offer, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** Unique claim id. */
  claimId: string
  /**
   * When the code was claimed.
   * @format date-time
   */
  claimedAt?: string | null
  /** The user id of the claimer. NOTE: This is not a GX.games user id! */
  claimerUserId?: string | null
  /**
   * Country code expressed using ISO 3166-1 alpha-2
   * @example "SE"
   */
  countryCode?: string | null
  /** BO user that manually created the claim, or null if the claim was not created manually via the BO API. */
  createdBy?: BackOfficeUserResponse | null
  /**
   * Region code expressed using ISO 3166-2
   * @example "SE-O"
   */
  regionCode?: string | null
}

/** OfferCodeDetailsResponse */
export interface OfferCodeDetailsResponse {
  /** True if the code is active. Inactive codes are not available to be claimed. */
  active?: boolean | null
  /** Additional imported code data, or null if no additional data was imported. */
  additionalData?: MapStringObject | null
  /** True if the code has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the code, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** The batch alias specified when the code was imported. */
  batchAlias?: string | null
  /** All claims of this code, current or archived. */
  claims?: OfferCodeClaimResponse[] | null
  /** Unique code id */
  codeId: string
  /**
   * When the code was created (i.e. imported).
   * @format date-time
   */
  createdAt?: string | null
  /** The current claim of the code, or null if the code is not currently claimed. */
  currentClaim?: OfferCodeClaimResponse | null
  /** The latest redemption of the code, or null if the code has not been redeemed. */
  latestRedemption?: OfferCodeRedemptionResponse | null
  /** The target URL with masked code value, or null if the code has no target URL. */
  maskedTargetUrl?: string | null
  /** The code value with most characters masked. */
  maskedValue?: string | null
  /** True if the code has been redeemed on the partner's platform. */
  redeemed?: boolean | null
  /** All redemptions of this code. */
  redemptions?: OfferCodeRedemptionResponse[] | null
  /** The target URL with full unmasked code value, or null if the code has no target URL or if the unmasked value was not requested. */
  unmaskedTargetUrl?: string | null
  /** The full unmasked code value, or null if the unmasked value was not requested. */
  unmaskedValue?: string | null
}

/** OfferCodeImportResponse */
export interface OfferCodeImportResponse {
  /**
   * The number of imported codes. If less than `numberOfParsedCodes`, some of the parsed codes already existed in the database in a not archived state.
   * @format int64
   */
  numberOfImportedCodes?: number | null
  /**
   * The number of codes parsed from the input CSV file.
   * @format int64
   */
  numberOfParsedCodes?: number | null
}

/** OfferCodeRedemptionResponse */
export interface OfferCodeRedemptionResponse {
  /**
   * Country code expressed using ISO 3166-1 alpha-2
   * @example "SE"
   */
  countryCode?: string | null
  /**
   * Date when the code was marked as redeemed in our BE
   * @format date-time
   */
  recordedAt?: string | null
  /**
   * Date when the code was redeemed
   * @format date-time
   */
  redeemedAt?: string | null
}

/** OfferCodeResponse */
export interface OfferCodeResponse {
  /** True if the code is active. Inactive codes are not available to be claimed. */
  active?: boolean | null
  /** Additional imported code data, or null if no additional data was imported. */
  additionalData?: MapStringObject | null
  /** True if the code has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the code, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** The batch alias specified when the code was imported. */
  batchAlias?: string | null
  /** Unique code id */
  codeId: string
  /**
   * When the code was created (i.e. imported).
   * @format date-time
   */
  createdAt?: string | null
  /** The current claim of the code, or null if the code is not currently claimed. */
  currentClaim?: OfferCodeClaimResponse | null
  /** The latest redemption of the code, or null if the code has not been redeemed. */
  latestRedemption?: OfferCodeRedemptionResponse | null
  /** The target URL with masked code value, or null if the code has no target URL. */
  maskedTargetUrl?: string | null
  /** The code value with most characters masked. */
  maskedValue?: string | null
  /** True if the code has been redeemed on the partner's platform. */
  redeemed?: boolean | null
}

/** OfferCodeUnclaimResponse */
export interface OfferCodeUnclaimResponse {
  /** The previously current and now archived claim, or null if the code was not claimed in the first place. */
  archivedClaim?: OfferCodeClaimResponse | null
}

/** OfferCodesBulkUpdateResponse */
export interface OfferCodesBulkUpdateResponse {
  /**
   * The number of codes that were modified by the bulk update. May be less than the number of selected codes, if some codes did not support the requested updates or was already in the desired state.
   * @format int32
   */
  numberOfUpdatedCodes?: number | null
}

/** OfferCodesResponse */
export interface OfferCodesResponse {
  codes: OfferCodeResponse[]
  pagination: PaginationResponse
}

/** OfferErrorPageResponse */
export interface OfferErrorPageResponse {
  /** True if the error page has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the error page, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /** Client alias of clients that will use this error page. */
  clientAlias?: string | null
  /**
   * When the error page was created.
   * @format date-time
   */
  createdAt?: string | null
  /** BO user that created the error page. */
  createdBy?: BackOfficeUserResponse | null
  /** True if the error page is configured for default offer client alias */
  defaultErrorPage?: boolean | null
  /** Unique error page id. */
  errorPageId: string
  /** Public URL of the error page, or `null` if no content has been uploaded. */
  publicUrl?: string | null
  /**
   * When the error page content was last uploaded, or `null` if no content has been uploaded.
   * @format date-time
   */
  uploadedAt?: string | null
  /** BO user that last uploaded the error page content, or `null` if no content has been uploaded. */
  uploadedBy?: BackOfficeUserResponse | null
}

/** OfferErrorPagesResponse */
export interface OfferErrorPagesResponse {
  errorPages: OfferErrorPageResponse[]
  pagination: PaginationResponse
}

/** OfferResponse */
export interface OfferResponse {
  /** True if the offer is active. Only codes belonging to an active offer can be claimed. */
  active?: boolean | null
  /** Unique offer alias. */
  alias?: string | null
  /** True if the offer has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the offer, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /**
   * When the offer was created.
   * @format date-time
   */
  createdAt?: string | null
  /** BO user that created the offer. */
  createdBy?: BackOfficeUserResponse | null
  /** True if the offer is internal. Codes from internal offers can't be claimed via API. */
  internal?: boolean | null
  /**
   * Maximum number of codes that can be claimed per calendar day (UTC), or null if not set.
   * @format int32
   */
  maxClaimsPerDay?: number | null
  /**
   * Total number of imported codes. Does NOT count archived codes.
   * @format int32
   */
  numberOfCodes?: number | null
  /**
   * Number of reserved (not available to claim) codes, or null if not set.
   * @format int32
   */
  numberOfReservedCodes?: number | null
  /**
   * Total number of unclaimed codes. Does NOT count archived codes.
   * @format int32
   */
  numberOfUnclaimedCodes?: number | null
  /** Unique offer id */
  offerId: string
  partner?: PartnerResponse | null
  /** Offer title. */
  title?: StringResponse | null
}

/** OffersResponse */
export interface OffersResponse {
  offers: OfferResponse[]
  pagination: PaginationResponse
}

export type OverrideMaxGamesLimitData = ResponseVoid

export type OverrideMaxGamesLimitError =
  | PatchUsersOverrideMaxGamesLimit400ErrorResponse
  | PatchUsersOverrideMaxGamesLimit403ErrorResponse
  | PatchUsersOverrideMaxGamesLimit404ErrorResponse

export interface OverrideMaxGamesLimitParams {
  /**
   * New max games limit for user
   * @format int32
   */
  newMaxGamesLimit: number
  /**
   * User to override setting for
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  userId: string
}

/** Page<Review> */
export interface PageReview {
  content?: Review[]
  empty?: boolean
  first?: boolean
  last?: boolean
  /** @format int32 */
  number?: number
  /** @format int32 */
  numberOfElements?: number
  pageable?: string
  /** @format int32 */
  size?: number
  sort?: Sort
  /** @format int64 */
  totalElements?: number
  /** @format int32 */
  totalPages?: number
}

/** PaginatedMigrationResponse */
export interface PaginatedMigrationResponse {
  pagination: PaginationResponse
}

/** Pagination info */
export interface PaginationResponse {
  /**
   * @format int32
   * @example 3
   */
  currPage: number
  /**
   * @format int32
   * @example 10
   */
  numPerPage: number
  /**
   * @format int64
   * @example 37
   */
  totalItems: number
  /**
   * @format int32
   * @example 4
   */
  totalPages: number
}

/** PartnerResponse */
export interface PartnerResponse {
  /** Unique partner alias. */
  alias?: string | null
  /** True if the partner has been archived. */
  archived?: boolean | null
  /** The BO user that (last) archived the partner, or null if `archived` is false. */
  archivedBy?: BackOfficeUserResponse | null
  /**
   * When the partner was created.
   * @format date-time
   */
  createdAt?: string | null
  /** The BO user that created the partner. */
  createdBy?: BackOfficeUserResponse | null
  /** Unique partner id. */
  partnerId: string
  /** Partner title. */
  title?: StringResponse | null
}

/** PartnersResponse */
export interface PartnersResponse {
  pagination: PaginationResponse
  partners: PartnerResponse[]
}

/** PatchAdministrationUsersBoUserId400Error */
export interface PatchAdministrationUsersBoUserId400Error {
  code: 'invalid_request_payload'
}

/** PatchAdministrationUsersBoUserId400ErrorResponse */
export interface PatchAdministrationUsersBoUserId400ErrorResponse {
  data: null
  errors: PatchAdministrationUsersBoUserId400Error[]
}

/** PatchAdministrationUsersBoUserId403Error */
export interface PatchAdministrationUsersBoUserId403Error {
  code: 'sign_in_required'
}

/** PatchAdministrationUsersBoUserId403ErrorResponse */
export interface PatchAdministrationUsersBoUserId403ErrorResponse {
  data: null
  errors: PatchAdministrationUsersBoUserId403Error[]
}

/** PatchAdministrationUsersBoUserId404Error */
export interface PatchAdministrationUsersBoUserId404Error {
  code: 'back_office_user_not_found' | 'dynamic_config_client_not_found'
}

/** PatchAdministrationUsersBoUserId404ErrorResponse */
export interface PatchAdministrationUsersBoUserId404ErrorResponse {
  data: null
  errors: PatchAdministrationUsersBoUserId404Error[]
}

/** PatchAlertsAlertId400Error */
export interface PatchAlertsAlertId400Error {
  code: 'invalid_request_payload' | 'no_changes'
}

/** PatchAlertsAlertId400ErrorResponse */
export interface PatchAlertsAlertId400ErrorResponse {
  data: null
  errors: PatchAlertsAlertId400Error[]
}

/** PatchAlertsAlertId403Error */
export interface PatchAlertsAlertId403Error {
  code: 'sign_in_required'
}

/** PatchAlertsAlertId403ErrorResponse */
export interface PatchAlertsAlertId403ErrorResponse {
  data: null
  errors: PatchAlertsAlertId403Error[]
}

/** PatchAlertsAlertId404Error */
export interface PatchAlertsAlertId404Error {
  code: 'system_alert_not_found'
}

/** PatchAlertsAlertId404ErrorResponse */
export interface PatchAlertsAlertId404ErrorResponse {
  data: null
  errors: PatchAlertsAlertId404Error[]
}

/** PatchApiclientsClientId400Error */
export interface PatchApiclientsClientId400Error {
  code:
    | 'api_client_alias_not_unique'
    | 'api_client_alias_not_valid'
    | 'api_client_alias_too_long'
    | 'invalid_allowed_origin'
    | 'invalid_allowed_origin_pattern'
    | 'invalid_request_payload'
}

/** PatchApiclientsClientId400ErrorResponse */
export interface PatchApiclientsClientId400ErrorResponse {
  data: null
  errors: PatchApiclientsClientId400Error[]
}

/** PatchApiclientsClientId403Error */
export interface PatchApiclientsClientId403Error {
  code: 'sign_in_required'
}

/** PatchApiclientsClientId403ErrorResponse */
export interface PatchApiclientsClientId403ErrorResponse {
  data: null
  errors: PatchApiclientsClientId403Error[]
}

/** PatchApiclientsClientId404Error */
export interface PatchApiclientsClientId404Error {
  code: 'api_client_not_found'
}

/** PatchApiclientsClientId404ErrorResponse */
export interface PatchApiclientsClientId404ErrorResponse {
  data: null
  errors: PatchApiclientsClientId404Error[]
}

/** PatchAssetVariantsConfigurationsConfigurationId400Error */
export interface PatchAssetVariantsConfigurationsConfigurationId400Error {
  code: 'invalid_asset_variant_format' | 'invalid_request_payload'
}

/** PatchAssetVariantsConfigurationsConfigurationId400ErrorResponse */
export interface PatchAssetVariantsConfigurationsConfigurationId400ErrorResponse {
  data: null
  errors: PatchAssetVariantsConfigurationsConfigurationId400Error[]
}

/** PatchAssetVariantsConfigurationsConfigurationId403Error */
export interface PatchAssetVariantsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** PatchAssetVariantsConfigurationsConfigurationId403ErrorResponse */
export interface PatchAssetVariantsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: PatchAssetVariantsConfigurationsConfigurationId403Error[]
}

/** PatchAssetVariantsConfigurationsConfigurationId404Error */
export interface PatchAssetVariantsConfigurationsConfigurationId404Error {
  code: 'asset_variant_configuration_not_found'
}

/** PatchAssetVariantsConfigurationsConfigurationId404ErrorResponse */
export interface PatchAssetVariantsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: PatchAssetVariantsConfigurationsConfigurationId404Error[]
}

/** PatchBankAccountsAccountId400Error */
export interface PatchBankAccountsAccountId400Error {
  code: 'invalid_request_payload' | 'no_changes'
}

/** PatchBankAccountsAccountId400ErrorResponse */
export interface PatchBankAccountsAccountId400ErrorResponse {
  data: null
  errors: PatchBankAccountsAccountId400Error[]
}

/** PatchBankAccountsAccountId403Error */
export interface PatchBankAccountsAccountId403Error {
  code: 'sign_in_required'
}

/** PatchBankAccountsAccountId403ErrorResponse */
export interface PatchBankAccountsAccountId403ErrorResponse {
  data: null
  errors: PatchBankAccountsAccountId403Error[]
}

/** PatchBankAccountsAccountId404Error */
export interface PatchBankAccountsAccountId404Error {
  code: 'bank_account_not_found'
}

/** PatchBankAccountsAccountId404ErrorResponse */
export interface PatchBankAccountsAccountId404ErrorResponse {
  data: null
  errors: PatchBankAccountsAccountId404Error[]
}

/** PatchChallengesChallengeId400Error */
export interface PatchChallengesChallengeId400Error {
  code:
    | 'challenge_access_denied'
    | 'challenge_already_ended'
    | 'challenge_duration_too_short'
    | 'challenge_ends_at_missing'
    | 'challenge_ends_before_it_starts'
    | 'challenge_name_exists'
    | 'challenge_name_too_long'
    | 'challenge_starts_at_missing'
    | 'invalid_characters_in_name'
    | 'invalid_request_payload'
    | 'long_description_too_long'
    | 'no_changes'
    | 'short_description_too_long'
    | 'short_id_already_changed'
    | 'short_id_length_is_invalid'
}

/** PatchChallengesChallengeId400ErrorResponse */
export interface PatchChallengesChallengeId400ErrorResponse {
  data: null
  errors: PatchChallengesChallengeId400Error[]
}

/** PatchChallengesChallengeId403Error */
export interface PatchChallengesChallengeId403Error {
  code: 'sign_in_required'
}

/** PatchChallengesChallengeId403ErrorResponse */
export interface PatchChallengesChallengeId403ErrorResponse {
  data: null
  errors: PatchChallengesChallengeId403Error[]
}

/** PatchChallengesChallengeId404Error */
export interface PatchChallengesChallengeId404Error {
  code: 'challenge_not_found'
}

/** PatchChallengesChallengeId404ErrorResponse */
export interface PatchChallengesChallengeId404ErrorResponse {
  data: null
  errors: PatchChallengesChallengeId404Error[]
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId400Error */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId400Error {
  code: 'invalid_request_payload'
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId400ErrorResponse */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId400ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdScoreVerificationsScoreId400Error[]
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId403Error */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId403Error {
  code: 'sign_in_required'
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId403ErrorResponse */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId403ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdScoreVerificationsScoreId403Error[]
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId404Error */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId404Error {
  code: 'challenge_not_found' | 'score_not_found'
}

/** PatchChallengesChallengeIdScoreVerificationsScoreId404ErrorResponse */
export interface PatchChallengesChallengeIdScoreVerificationsScoreId404ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdScoreVerificationsScoreId404Error[]
}

/** PatchChallengesChallengeIdSettings400Error */
export interface PatchChallengesChallengeIdSettings400Error {
  code:
    | 'challenge_is_promoted'
    | 'cover_art_missing'
    | 'invalid_request_payload'
    | 'long_description_empty'
    | 'no_changes'
    | 'short_description_empty'
}

/** PatchChallengesChallengeIdSettings400ErrorResponse */
export interface PatchChallengesChallengeIdSettings400ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdSettings400Error[]
}

/** PatchChallengesChallengeIdSettings403Error */
export interface PatchChallengesChallengeIdSettings403Error {
  code: 'sign_in_required'
}

/** PatchChallengesChallengeIdSettings403ErrorResponse */
export interface PatchChallengesChallengeIdSettings403ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdSettings403Error[]
}

/** PatchChallengesChallengeIdSettings404Error */
export interface PatchChallengesChallengeIdSettings404Error {
  code: 'challenge_not_found' | 'track_not_found'
}

/** PatchChallengesChallengeIdSettings404ErrorResponse */
export interface PatchChallengesChallengeIdSettings404ErrorResponse {
  data: null
  errors: PatchChallengesChallengeIdSettings404Error[]
}

/** PatchChallengesRankings400Error */
export interface PatchChallengesRankings400Error {
  code:
    | 'invalid_setting_value'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PatchChallengesRankings400ErrorResponse */
export interface PatchChallengesRankings400ErrorResponse {
  data: null
  errors: PatchChallengesRankings400Error[]
}

/** PatchChallengesRankings403Error */
export interface PatchChallengesRankings403Error {
  code: 'sign_in_required'
}

/** PatchChallengesRankings403ErrorResponse */
export interface PatchChallengesRankings403ErrorResponse {
  data: null
  errors: PatchChallengesRankings403Error[]
}

/** PatchChallengesRankings404Error */
export interface PatchChallengesRankings404Error {
  code: 'challenge_not_found'
}

/** PatchChallengesRankings404ErrorResponse */
export interface PatchChallengesRankings404ErrorResponse {
  data: null
  errors: PatchChallengesRankings404Error[]
}

/** PatchChallengesShortIds403Error */
export interface PatchChallengesShortIds403Error {
  code: 'sign_in_required'
}

/** PatchChallengesShortIds403ErrorResponse */
export interface PatchChallengesShortIds403ErrorResponse {
  data: null
  errors: PatchChallengesShortIds403Error[]
}

/** PatchChallengesTopScores403Error */
export interface PatchChallengesTopScores403Error {
  code: 'sign_in_required'
}

/** PatchChallengesTopScores403ErrorResponse */
export interface PatchChallengesTopScores403ErrorResponse {
  data: null
  errors: PatchChallengesTopScores403Error[]
}

/** PatchCmsConfigurationsConfigurationId400Error */
export interface PatchCmsConfigurationsConfigurationId400Error {
  code:
    | 'cms_configuration_alias_invalid'
    | 'cms_configuration_alias_taken'
    | 'cms_configuration_alias_too_long'
    | 'configuration_name_not_unique'
    | 'configuration_section_not_found'
    | 'configuration_sections_mismatch'
    | 'invalid_request_payload'
    | 'no_changes'
}

/** PatchCmsConfigurationsConfigurationId400ErrorResponse */
export interface PatchCmsConfigurationsConfigurationId400ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationId400Error[]
}

/** PatchCmsConfigurationsConfigurationId403Error */
export interface PatchCmsConfigurationsConfigurationId403Error {
  code: 'sign_in_required'
}

/** PatchCmsConfigurationsConfigurationId403ErrorResponse */
export interface PatchCmsConfigurationsConfigurationId403ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationId403Error[]
}

/** PatchCmsConfigurationsConfigurationId404Error */
export interface PatchCmsConfigurationsConfigurationId404Error {
  code: 'configuration_not_found'
}

/** PatchCmsConfigurationsConfigurationId404ErrorResponse */
export interface PatchCmsConfigurationsConfigurationId404ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationId404Error[]
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId400Error */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId400Error {
  code: 'date_range_invalid' | 'feed_not_found' | 'invalid_request_payload'
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId400ErrorResponse */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId400ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationIdSectionsSectionId400Error[]
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId403Error */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId403Error {
  code: 'sign_in_required'
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationIdSectionsSectionId403Error[]
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId404Error */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId404Error {
  code: 'configuration_not_found' | 'configuration_section_not_found'
}

/** PatchCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse */
export interface PatchCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse {
  data: null
  errors: PatchCmsConfigurationsConfigurationIdSectionsSectionId404Error[]
}

/** PatchDynamicConfigClientsClientAliasArchive403Error */
export interface PatchDynamicConfigClientsClientAliasArchive403Error {
  code: 'sign_in_required'
}

/** PatchDynamicConfigClientsClientAliasArchive403ErrorResponse */
export interface PatchDynamicConfigClientsClientAliasArchive403ErrorResponse {
  data: null
  errors: PatchDynamicConfigClientsClientAliasArchive403Error[]
}

/** PatchDynamicConfigClientsClientAliasArchive404Error */
export interface PatchDynamicConfigClientsClientAliasArchive404Error {
  code: 'dynamic_config_client_not_found'
}

/** PatchDynamicConfigClientsClientAliasArchive404ErrorResponse */
export interface PatchDynamicConfigClientsClientAliasArchive404ErrorResponse {
  data: null
  errors: PatchDynamicConfigClientsClientAliasArchive404Error[]
}

/** PatchDynamicConfigConfigId400Error */
export interface PatchDynamicConfigConfigId400Error {
  code:
    | 'dynamic_config_content_exceed_allowed_limit'
    | 'dynamic_config_priority_already_used'
    | 'dynamic_config_priority_negative_value'
    | 'dynamic_config_title_taken'
    | 'invalid_dynamic_config_condition'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'published_dynamic_config_must_have_condition'
    | 'too_complex_dynamic_config_condition'
}

/** PatchDynamicConfigConfigId400ErrorResponse */
export interface PatchDynamicConfigConfigId400ErrorResponse {
  data: null
  errors: PatchDynamicConfigConfigId400Error[]
}

/** PatchDynamicConfigConfigId403Error */
export interface PatchDynamicConfigConfigId403Error {
  code: 'sign_in_required'
}

/** PatchDynamicConfigConfigId403ErrorResponse */
export interface PatchDynamicConfigConfigId403ErrorResponse {
  data: null
  errors: PatchDynamicConfigConfigId403Error[]
}

/** PatchDynamicConfigConfigId404Error */
export interface PatchDynamicConfigConfigId404Error {
  code: 'dynamic_config_not_found'
}

/** PatchDynamicConfigConfigId404ErrorResponse */
export interface PatchDynamicConfigConfigId404ErrorResponse {
  data: null
  errors: PatchDynamicConfigConfigId404Error[]
}

/** PatchEnginesEngineId400Error */
export interface PatchEnginesEngineId400Error {
  code:
    | 'default_game_engine_already_set'
    | 'game_engine_alias_blank'
    | 'game_engine_alias_not_unique'
    | 'game_engine_alias_too_long'
    | 'invalid_game_engine_alias'
    | 'invalid_request_payload'
    | 'no_changes'
}

/** PatchEnginesEngineId400ErrorResponse */
export interface PatchEnginesEngineId400ErrorResponse {
  data: null
  errors: PatchEnginesEngineId400Error[]
}

/** PatchEnginesEngineId403Error */
export interface PatchEnginesEngineId403Error {
  code: 'sign_in_required'
}

/** PatchEnginesEngineId403ErrorResponse */
export interface PatchEnginesEngineId403ErrorResponse {
  data: null
  errors: PatchEnginesEngineId403Error[]
}

/** PatchEnginesEngineId404Error */
export interface PatchEnginesEngineId404Error {
  code: 'game_engine_not_found'
}

/** PatchEnginesEngineId404ErrorResponse */
export interface PatchEnginesEngineId404ErrorResponse {
  data: null
  errors: PatchEnginesEngineId404Error[]
}

export type PatchFeedData = ResponseManagedFeedDetailsResponse

export type PatchFeedError =
  | PatchFeedsFeedId400ErrorResponse
  | PatchFeedsFeedId403ErrorResponse
  | PatchFeedsFeedId404ErrorResponse

/** PatchFeedsFeedId400Error */
export interface PatchFeedsFeedId400Error {
  code:
    | 'feed_alias_invalid'
    | 'feed_alias_taken'
    | 'feed_snapshot_invalid_sorting'
    | 'feed_snapshot_not_configured'
    | 'invalid_feed_type'
    | 'invalid_filtering_expression'
    | 'invalid_request_payload'
    | 'invalid_snapshot_configuration'
    | 'invalid_sort'
    | 'invalid_time_frame'
    | 'invalid_time_frame_endpoint'
    | 'managed_feed_parameter_value_invalid'
    | 'mismatching_feed_entries'
    | 'mismatching_feed_entries_amount'
    | 'no_changes'
    | 'tag_not_found'
    | 'too_complex_filtering_expression'
    | 'user_not_found'
    | 'wrong_feed_type'
}

/** PatchFeedsFeedId400ErrorResponse */
export interface PatchFeedsFeedId400ErrorResponse {
  data: null
  errors: PatchFeedsFeedId400Error[]
}

/** PatchFeedsFeedId403Error */
export interface PatchFeedsFeedId403Error {
  code: 'sign_in_required'
}

/** PatchFeedsFeedId403ErrorResponse */
export interface PatchFeedsFeedId403ErrorResponse {
  data: null
  errors: PatchFeedsFeedId403Error[]
}

/** PatchFeedsFeedId404Error */
export interface PatchFeedsFeedId404Error {
  code: 'feed_not_found'
}

/** PatchFeedsFeedId404ErrorResponse */
export interface PatchFeedsFeedId404ErrorResponse {
  data: null
  errors: PatchFeedsFeedId404Error[]
}

/** PatchFeedsFeedIdEntriesEntryId400Error */
export interface PatchFeedsFeedIdEntriesEntryId400Error {
  code: 'invalid_collection_entry_attribute' | 'invalid_request_payload'
}

/** PatchFeedsFeedIdEntriesEntryId400ErrorResponse */
export interface PatchFeedsFeedIdEntriesEntryId400ErrorResponse {
  data: null
  errors: PatchFeedsFeedIdEntriesEntryId400Error[]
}

/** PatchFeedsFeedIdEntriesEntryId403Error */
export interface PatchFeedsFeedIdEntriesEntryId403Error {
  code: 'sign_in_required'
}

/** PatchFeedsFeedIdEntriesEntryId403ErrorResponse */
export interface PatchFeedsFeedIdEntriesEntryId403ErrorResponse {
  data: null
  errors: PatchFeedsFeedIdEntriesEntryId403Error[]
}

/** PatchFeedsFeedIdEntriesEntryId404Error */
export interface PatchFeedsFeedIdEntriesEntryId404Error {
  code: 'attribute_not_found' | 'entry_not_found' | 'feed_not_found'
}

/** PatchFeedsFeedIdEntriesEntryId404ErrorResponse */
export interface PatchFeedsFeedIdEntriesEntryId404ErrorResponse {
  data: null
  errors: PatchFeedsFeedIdEntriesEntryId404Error[]
}

/** PatchFundingWaitListSubscriberId400Error */
export interface PatchFundingWaitListSubscriberId400Error {
  code: 'date_is_invalid' | 'invalid_request_payload'
}

/** PatchFundingWaitListSubscriberId400ErrorResponse */
export interface PatchFundingWaitListSubscriberId400ErrorResponse {
  data: null
  errors: PatchFundingWaitListSubscriberId400Error[]
}

/** PatchFundingWaitListSubscriberId403Error */
export interface PatchFundingWaitListSubscriberId403Error {
  code: 'sign_in_required'
}

/** PatchFundingWaitListSubscriberId403ErrorResponse */
export interface PatchFundingWaitListSubscriberId403ErrorResponse {
  data: null
  errors: PatchFundingWaitListSubscriberId403Error[]
}

/** PatchFundingWaitListSubscriberId404Error */
export interface PatchFundingWaitListSubscriberId404Error {
  code: 'waitlist_subscriber_not_found'
}

/** PatchFundingWaitListSubscriberId404ErrorResponse */
export interface PatchFundingWaitListSubscriberId404ErrorResponse {
  data: null
  errors: PatchFundingWaitListSubscriberId404Error[]
}

/** PatchGamePlayStatistics403Error */
export interface PatchGamePlayStatistics403Error {
  code: 'sign_in_required'
}

/** PatchGamePlayStatistics403ErrorResponse */
export interface PatchGamePlayStatistics403ErrorResponse {
  data: null
  errors: PatchGamePlayStatistics403Error[]
}

/** PatchGameTransfersTransferId400Error */
export interface PatchGameTransfersTransferId400Error {
  code:
    | 'game_transfer_already_resolved'
    | 'invalid_game_transfer'
    | 'invalid_request_payload'
    | 'invalid_transfer'
    | 'reviewer_comment_too_long'
    | 'transfer_already_resolved'
}

/** PatchGameTransfersTransferId400ErrorResponse */
export interface PatchGameTransfersTransferId400ErrorResponse {
  data: null
  errors: PatchGameTransfersTransferId400Error[]
}

/** PatchGameTransfersTransferId403Error */
export interface PatchGameTransfersTransferId403Error {
  code: 'sign_in_required'
}

/** PatchGameTransfersTransferId403ErrorResponse */
export interface PatchGameTransfersTransferId403ErrorResponse {
  data: null
  errors: PatchGameTransfersTransferId403Error[]
}

/** PatchGameTransfersTransferId404Error */
export interface PatchGameTransfersTransferId404Error {
  code: 'game_transfer_not_found' | 'transfer_not_found'
}

/** PatchGameTransfersTransferId404ErrorResponse */
export interface PatchGameTransfersTransferId404ErrorResponse {
  data: null
  errors: PatchGameTransfersTransferId404Error[]
}

/** PatchGameWrappersGameWrapperId400Error */
export interface PatchGameWrappersGameWrapperId400Error {
  code:
    | 'game_wrapper_is_default'
    | 'game_wrapper_name_taken'
    | 'game_wrapper_not_active'
    | 'game_wrapper_not_uploaded'
    | 'invalid_request_payload'
}

/** PatchGameWrappersGameWrapperId400ErrorResponse */
export interface PatchGameWrappersGameWrapperId400ErrorResponse {
  data: null
  errors: PatchGameWrappersGameWrapperId400Error[]
}

/** PatchGameWrappersGameWrapperId403Error */
export interface PatchGameWrappersGameWrapperId403Error {
  code: 'sign_in_required'
}

/** PatchGameWrappersGameWrapperId403ErrorResponse */
export interface PatchGameWrappersGameWrapperId403ErrorResponse {
  data: null
  errors: PatchGameWrappersGameWrapperId403Error[]
}

/** PatchGameWrappersGameWrapperId404Error */
export interface PatchGameWrappersGameWrapperId404Error {
  code: 'game_wrapper_not_found'
}

/** PatchGameWrappersGameWrapperId404ErrorResponse */
export interface PatchGameWrappersGameWrapperId404ErrorResponse {
  data: null
  errors: PatchGameWrappersGameWrapperId404Error[]
}

/** PatchGamesGameId400Error */
export interface PatchGamesGameId400Error {
  code:
    | 'age_rating_not_set'
    | 'gallery_size_inconsistent'
    | 'game_access_denied'
    | 'game_description_empty'
    | 'game_has_game_engine_and_uses_default_engine'
    | 'game_is_blocked'
    | 'game_name_empty'
    | 'game_name_taken'
    | 'game_name_too_long'
    | 'invalid_characters_in_name'
    | 'invalid_request_payload'
    | 'invalid_setting_value'
    | 'long_description_too_long'
    | 'missing_game_covers'
    | 'no_changes'
    | 'no_graphics_uploaded'
    | 'no_platforms_added'
    | 'no_release_for_track'
    | 'payment_provider_not_allowed'
    | 'short_description_too_long'
    | 'short_id_already_changed'
    | 'short_id_empty'
    | 'short_id_length_is_invalid'
    | 'stripe_connect_account_county_code_not_set'
    | 'stripe_connect_account_not_set_for_studio'
    | 'stripe_connect_account_payments_not_allowed'
}

/** PatchGamesGameId400ErrorResponse */
export interface PatchGamesGameId400ErrorResponse {
  data: null
  errors: PatchGamesGameId400Error[]
}

/** PatchGamesGameId403Error */
export interface PatchGamesGameId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameId403ErrorResponse */
export interface PatchGamesGameId403ErrorResponse {
  data: null
  errors: PatchGamesGameId403Error[]
}

/** PatchGamesGameId404Error */
export interface PatchGamesGameId404Error {
  code: 'game_asset_not_found' | 'game_engine_not_found' | 'game_not_found' | 'publisher_not_found'
}

/** PatchGamesGameId404ErrorResponse */
export interface PatchGamesGameId404ErrorResponse {
  data: null
  errors: PatchGamesGameId404Error[]
}

/** PatchGamesGameIdBannersBannerId400Error */
export interface PatchGamesGameIdBannersBannerId400Error {
  code:
    | 'game_access_denied'
    | 'game_banner_alias_not_unique'
    | 'invalid_game_banner_alias'
    | 'invalid_request_payload'
}

/** PatchGamesGameIdBannersBannerId400ErrorResponse */
export interface PatchGamesGameIdBannersBannerId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdBannersBannerId400Error[]
}

/** PatchGamesGameIdBannersBannerId403Error */
export interface PatchGamesGameIdBannersBannerId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdBannersBannerId403ErrorResponse */
export interface PatchGamesGameIdBannersBannerId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdBannersBannerId403Error[]
}

/** PatchGamesGameIdBannersBannerId404Error */
export interface PatchGamesGameIdBannersBannerId404Error {
  code: 'game_banner_not_found' | 'game_not_found'
}

/** PatchGamesGameIdBannersBannerId404ErrorResponse */
export interface PatchGamesGameIdBannersBannerId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdBannersBannerId404Error[]
}

/** PatchGamesGameIdControlGroups400Error */
export interface PatchGamesGameIdControlGroups400Error {
  code: 'game_access_denied' | 'invalid_display_order' | 'invalid_request_payload'
}

/** PatchGamesGameIdControlGroups400ErrorResponse */
export interface PatchGamesGameIdControlGroups400ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroups400Error[]
}

/** PatchGamesGameIdControlGroups403Error */
export interface PatchGamesGameIdControlGroups403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdControlGroups403ErrorResponse */
export interface PatchGamesGameIdControlGroups403ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroups403Error[]
}

/** PatchGamesGameIdControlGroups404Error */
export interface PatchGamesGameIdControlGroups404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** PatchGamesGameIdControlGroups404ErrorResponse */
export interface PatchGamesGameIdControlGroups404ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroups404Error[]
}

/** PatchGamesGameIdControlGroupsControlGroupId400Error */
export interface PatchGamesGameIdControlGroupsControlGroupId400Error {
  code: 'game_access_denied' | 'invalid_display_order' | 'invalid_request_payload'
}

/** PatchGamesGameIdControlGroupsControlGroupId400ErrorResponse */
export interface PatchGamesGameIdControlGroupsControlGroupId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroupsControlGroupId400Error[]
}

/** PatchGamesGameIdControlGroupsControlGroupId403Error */
export interface PatchGamesGameIdControlGroupsControlGroupId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdControlGroupsControlGroupId403ErrorResponse */
export interface PatchGamesGameIdControlGroupsControlGroupId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroupsControlGroupId403Error[]
}

/** PatchGamesGameIdControlGroupsControlGroupId404Error */
export interface PatchGamesGameIdControlGroupsControlGroupId404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** PatchGamesGameIdControlGroupsControlGroupId404ErrorResponse */
export interface PatchGamesGameIdControlGroupsControlGroupId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlGroupsControlGroupId404Error[]
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId400Error */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId400Error {
  code: 'game_access_denied' | 'invalid_display_order' | 'invalid_request_payload'
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlSubgroupsControlSubgroupId400Error[]
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId403Error */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlSubgroupsControlSubgroupId403Error[]
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId404Error */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId404Error {
  code: 'game_control_not_found' | 'game_controls_group_not_found' | 'game_not_found'
}

/** PatchGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse */
export interface PatchGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdControlSubgroupsControlSubgroupId404Error[]
}

/** PatchGamesGameIdItemsItemId400Error */
export interface PatchGamesGameIdItemsItemId400Error {
  code:
    | 'changing_game_item_type_not_allowed'
    | 'discount_tag_not_set'
    | 'discounted_price_too_high'
    | 'discounted_price_too_low'
    | 'invalid_currency_name'
    | 'invalid_request_payload'
    | 'invalid_time_frame'
    | 'negative_price'
    | 'no_changes'
    | 'price_exceeds_maximum_value'
    | 'string_value_empty'
}

/** PatchGamesGameIdItemsItemId400ErrorResponse */
export interface PatchGamesGameIdItemsItemId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemId400Error[]
}

/** PatchGamesGameIdItemsItemId403Error */
export interface PatchGamesGameIdItemsItemId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdItemsItemId403ErrorResponse */
export interface PatchGamesGameIdItemsItemId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemId403Error[]
}

/** PatchGamesGameIdItemsItemId404Error */
export interface PatchGamesGameIdItemsItemId404Error {
  code: 'game_item_not_found' | 'game_not_found'
}

/** PatchGamesGameIdItemsItemId404ErrorResponse */
export interface PatchGamesGameIdItemsItemId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemId404Error[]
}

/** PatchGamesGameIdItemsItemId500Error */
export interface PatchGamesGameIdItemsItemId500Error {
  code: 'internal_server_error'
}

/** PatchGamesGameIdItemsItemId500ErrorResponse */
export interface PatchGamesGameIdItemsItemId500ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemId500Error[]
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error {
  code:
    | 'claim_game_not_allowed'
    | 'game_item_claim_period_conflicts_with_claims'
    | 'game_item_claim_period_date_range_invalid'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'overlapping_game_item_claim_periods'
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400Error[]
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403Error[]
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error {
  code: 'game_item_claim_period_not_found' | 'game_item_not_found' | 'game_not_found'
}

/** PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse */
export interface PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404Error[]
}

/** PatchGamesGameIdTracksTrackId400Error */
export interface PatchGamesGameIdTracksTrackId400Error {
  code:
    | 'age_rating_not_set'
    | 'game_description_empty'
    | 'game_has_promoted_challenge'
    | 'game_is_blocked'
    | 'invalid_request_payload'
    | 'missing_game_covers'
    | 'no_changes'
    | 'no_graphics_uploaded'
    | 'no_platforms_added'
    | 'no_release_for_track'
    | 'shared_array_buffer_is_not_supported'
}

/** PatchGamesGameIdTracksTrackId400ErrorResponse */
export interface PatchGamesGameIdTracksTrackId400ErrorResponse {
  data: null
  errors: PatchGamesGameIdTracksTrackId400Error[]
}

/** PatchGamesGameIdTracksTrackId403Error */
export interface PatchGamesGameIdTracksTrackId403Error {
  code: 'sign_in_required'
}

/** PatchGamesGameIdTracksTrackId403ErrorResponse */
export interface PatchGamesGameIdTracksTrackId403ErrorResponse {
  data: null
  errors: PatchGamesGameIdTracksTrackId403Error[]
}

/** PatchGamesGameIdTracksTrackId404Error */
export interface PatchGamesGameIdTracksTrackId404Error {
  code: 'game_not_found' | 'game_wrapper_not_found' | 'track_not_found'
}

/** PatchGamesGameIdTracksTrackId404ErrorResponse */
export interface PatchGamesGameIdTracksTrackId404ErrorResponse {
  data: null
  errors: PatchGamesGameIdTracksTrackId404Error[]
}

/** PatchGamesReleaseDates403Error */
export interface PatchGamesReleaseDates403Error {
  code: 'sign_in_required'
}

/** PatchGamesReleaseDates403ErrorResponse */
export interface PatchGamesReleaseDates403ErrorResponse {
  data: null
  errors: PatchGamesReleaseDates403Error[]
}

/** PatchGamesShortIds403Error */
export interface PatchGamesShortIds403Error {
  code: 'sign_in_required'
}

/** PatchGamesShortIds403ErrorResponse */
export interface PatchGamesShortIds403ErrorResponse {
  data: null
  errors: PatchGamesShortIds403Error[]
}

/** PatchGamesUnpublishSharedArrayBufferInternalTracks400Error */
export interface PatchGamesUnpublishSharedArrayBufferInternalTracks400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PatchGamesUnpublishSharedArrayBufferInternalTracks400ErrorResponse */
export interface PatchGamesUnpublishSharedArrayBufferInternalTracks400ErrorResponse {
  data: null
  errors: PatchGamesUnpublishSharedArrayBufferInternalTracks400Error[]
}

/** PatchGamesUnpublishSharedArrayBufferInternalTracks403Error */
export interface PatchGamesUnpublishSharedArrayBufferInternalTracks403Error {
  code: 'sign_in_required'
}

/** PatchGamesUnpublishSharedArrayBufferInternalTracks403ErrorResponse */
export interface PatchGamesUnpublishSharedArrayBufferInternalTracks403ErrorResponse {
  data: null
  errors: PatchGamesUnpublishSharedArrayBufferInternalTracks403Error[]
}

/** PatchGamesUnpublishSharedArrayBufferPublicTracks400Error */
export interface PatchGamesUnpublishSharedArrayBufferPublicTracks400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PatchGamesUnpublishSharedArrayBufferPublicTracks400ErrorResponse */
export interface PatchGamesUnpublishSharedArrayBufferPublicTracks400ErrorResponse {
  data: null
  errors: PatchGamesUnpublishSharedArrayBufferPublicTracks400Error[]
}

/** PatchGamesUnpublishSharedArrayBufferPublicTracks403Error */
export interface PatchGamesUnpublishSharedArrayBufferPublicTracks403Error {
  code: 'sign_in_required'
}

/** PatchGamesUnpublishSharedArrayBufferPublicTracks403ErrorResponse */
export interface PatchGamesUnpublishSharedArrayBufferPublicTracks403ErrorResponse {
  data: null
  errors: PatchGamesUnpublishSharedArrayBufferPublicTracks403Error[]
}

/** PatchGmsRunnersRunnerId400Error */
export interface PatchGmsRunnersRunnerId400Error {
  code: 'gms_runner_description_too_long' | 'invalid_request_payload' | 'no_changes'
}

/** PatchGmsRunnersRunnerId400ErrorResponse */
export interface PatchGmsRunnersRunnerId400ErrorResponse {
  data: null
  errors: PatchGmsRunnersRunnerId400Error[]
}

/** PatchGmsRunnersRunnerId403Error */
export interface PatchGmsRunnersRunnerId403Error {
  code: 'sign_in_required'
}

/** PatchGmsRunnersRunnerId403ErrorResponse */
export interface PatchGmsRunnersRunnerId403ErrorResponse {
  data: null
  errors: PatchGmsRunnersRunnerId403Error[]
}

/** PatchGmsRunnersRunnerId404Error */
export interface PatchGmsRunnersRunnerId404Error {
  code: 'gms_runner_version_not_found'
}

/** PatchGmsRunnersRunnerId404ErrorResponse */
export interface PatchGmsRunnersRunnerId404ErrorResponse {
  data: null
  errors: PatchGmsRunnersRunnerId404Error[]
}

/** PatchLegacySettingRequest */
export interface PatchLegacySettingRequest {
  /** @example "TOP_RATED_CHART_MINIMUM_RATINGS" */
  key: string
  /** @example "50" */
  value: string
}

/** PatchLegacySettingsRequest */
export interface PatchLegacySettingsRequest {
  settings: PatchLegacySettingRequest[]
}

/** PatchLegacyStudioSettingsRequest */
export interface PatchLegacyStudioSettingsRequest {
  /** @example "CUSTOM_GAME_BUILDS_ALLOWED" */
  setting: string
  /** @example "FALSE" */
  value: string
}

/** PatchModTransfersTransferId400Error */
export interface PatchModTransfersTransferId400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_transfer'
    | 'reviewer_comment_too_long'
    | 'transfer_already_resolved'
}

/** PatchModTransfersTransferId400ErrorResponse */
export interface PatchModTransfersTransferId400ErrorResponse {
  data: null
  errors: PatchModTransfersTransferId400Error[]
}

/** PatchModTransfersTransferId403Error */
export interface PatchModTransfersTransferId403Error {
  code: 'sign_in_required'
}

/** PatchModTransfersTransferId403ErrorResponse */
export interface PatchModTransfersTransferId403ErrorResponse {
  data: null
  errors: PatchModTransfersTransferId403Error[]
}

/** PatchModTransfersTransferId404Error */
export interface PatchModTransfersTransferId404Error {
  code: 'transfer_not_found'
}

/** PatchModTransfersTransferId404ErrorResponse */
export interface PatchModTransfersTransferId404ErrorResponse {
  data: null
  errors: PatchModTransfersTransferId404Error[]
}

/** PatchModsModId400Error */
export interface PatchModsModId400Error {
  code:
    | 'gallery_size_inconsistent'
    | 'invalid_mod_flavor_spec'
    | 'invalid_request_payload'
    | 'long_description_too_long'
    | 'no_changes'
    | 'short_id_already_changed'
    | 'short_id_length_is_invalid'
}

/** PatchModsModId400ErrorResponse */
export interface PatchModsModId400ErrorResponse {
  data: null
  errors: PatchModsModId400Error[]
}

/** PatchModsModId403Error */
export interface PatchModsModId403Error {
  code: 'sign_in_required'
}

/** PatchModsModId403ErrorResponse */
export interface PatchModsModId403ErrorResponse {
  data: null
  errors: PatchModsModId403Error[]
}

/** PatchModsModId404Error */
export interface PatchModsModId404Error {
  code: 'asset_not_found' | 'mod_not_found' | 'wrapper_not_found'
}

/** PatchModsModId404ErrorResponse */
export interface PatchModsModId404ErrorResponse {
  data: null
  errors: PatchModsModId404Error[]
}

/** PatchModsModIdTracksTrackId400Error */
export interface PatchModsModIdTracksTrackId400Error {
  code:
    | 'age_rating_not_set'
    | 'entity_is_blocked'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'no_release_for_track'
    | 'published_mod_must_have_image_cover'
}

/** PatchModsModIdTracksTrackId400ErrorResponse */
export interface PatchModsModIdTracksTrackId400ErrorResponse {
  data: null
  errors: PatchModsModIdTracksTrackId400Error[]
}

/** PatchModsModIdTracksTrackId403Error */
export interface PatchModsModIdTracksTrackId403Error {
  code: 'sign_in_required'
}

/** PatchModsModIdTracksTrackId403ErrorResponse */
export interface PatchModsModIdTracksTrackId403ErrorResponse {
  data: null
  errors: PatchModsModIdTracksTrackId403Error[]
}

/** PatchModsModIdTracksTrackId404Error */
export interface PatchModsModIdTracksTrackId404Error {
  code: 'mod_not_found' | 'track_not_found'
}

/** PatchModsModIdTracksTrackId404ErrorResponse */
export interface PatchModsModIdTracksTrackId404ErrorResponse {
  data: null
  errors: PatchModsModIdTracksTrackId404Error[]
}

/** PatchOdksKeyId400Error */
export interface PatchOdksKeyId400Error {
  code: 'invalid_request_payload' | 'no_changes' | 'tag_referenced_by_filtered_feed'
}

/** PatchOdksKeyId400ErrorResponse */
export interface PatchOdksKeyId400ErrorResponse {
  data: null
  errors: PatchOdksKeyId400Error[]
}

/** PatchOdksKeyId403Error */
export interface PatchOdksKeyId403Error {
  code: 'sign_in_required'
}

/** PatchOdksKeyId403ErrorResponse */
export interface PatchOdksKeyId403ErrorResponse {
  data: null
  errors: PatchOdksKeyId403Error[]
}

/** PatchOdksKeyId404Error */
export interface PatchOdksKeyId404Error {
  code: 'official_distribution_key_not_found'
}

/** PatchOdksKeyId404ErrorResponse */
export interface PatchOdksKeyId404ErrorResponse {
  data: null
  errors: PatchOdksKeyId404Error[]
}

/** PatchOfferPartnersPartnerId400Error */
export interface PatchOfferPartnersPartnerId400Error {
  code:
    | 'invalid_request_payload'
    | 'no_changes'
    | 'offer_partner_alias_not_unique'
    | 'offer_partner_alias_not_valid'
    | 'offer_partner_alias_too_long'
    | 'offer_partner_is_archived'
    | 'partner_alias_change_not_allowed'
    | 'partner_archival_not_allowed'
}

/** PatchOfferPartnersPartnerId400ErrorResponse */
export interface PatchOfferPartnersPartnerId400ErrorResponse {
  data: null
  errors: PatchOfferPartnersPartnerId400Error[]
}

/** PatchOfferPartnersPartnerId403Error */
export interface PatchOfferPartnersPartnerId403Error {
  code: 'sign_in_required'
}

/** PatchOfferPartnersPartnerId403ErrorResponse */
export interface PatchOfferPartnersPartnerId403ErrorResponse {
  data: null
  errors: PatchOfferPartnersPartnerId403Error[]
}

/** PatchOfferPartnersPartnerId404Error */
export interface PatchOfferPartnersPartnerId404Error {
  code: 'partner_not_found'
}

/** PatchOfferPartnersPartnerId404ErrorResponse */
export interface PatchOfferPartnersPartnerId404ErrorResponse {
  data: null
  errors: PatchOfferPartnersPartnerId404Error[]
}

/** PatchOffersOfferId400Error */
export interface PatchOffersOfferId400Error {
  code:
    | 'invalid_request_payload'
    | 'no_changes'
    | 'offer_alias_not_unique'
    | 'offer_alias_not_valid'
    | 'offer_alias_too_long'
    | 'offer_is_active'
    | 'offer_is_archived'
    | 'partner_is_archived'
}

/** PatchOffersOfferId400ErrorResponse */
export interface PatchOffersOfferId400ErrorResponse {
  data: null
  errors: PatchOffersOfferId400Error[]
}

/** PatchOffersOfferId403Error */
export interface PatchOffersOfferId403Error {
  code: 'sign_in_required'
}

/** PatchOffersOfferId403ErrorResponse */
export interface PatchOffersOfferId403ErrorResponse {
  data: null
  errors: PatchOffersOfferId403Error[]
}

/** PatchOffersOfferId404Error */
export interface PatchOffersOfferId404Error {
  code: 'offer_not_found' | 'partner_not_found'
}

/** PatchOffersOfferId404ErrorResponse */
export interface PatchOffersOfferId404ErrorResponse {
  data: null
  errors: PatchOffersOfferId404Error[]
}

/** PatchOffersOfferIdCodes400Error */
export interface PatchOffersOfferIdCodes400Error {
  code: 'invalid_request_payload' | 'no_changes' | 'offer_code_is_archived' | 'offer_is_archived'
}

/** PatchOffersOfferIdCodes400ErrorResponse */
export interface PatchOffersOfferIdCodes400ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodes400Error[]
}

/** PatchOffersOfferIdCodes403Error */
export interface PatchOffersOfferIdCodes403Error {
  code: 'sign_in_required'
}

/** PatchOffersOfferIdCodes403ErrorResponse */
export interface PatchOffersOfferIdCodes403ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodes403Error[]
}

/** PatchOffersOfferIdCodes404Error */
export interface PatchOffersOfferIdCodes404Error {
  code: 'offer_not_found'
}

/** PatchOffersOfferIdCodes404ErrorResponse */
export interface PatchOffersOfferIdCodes404ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodes404Error[]
}

/** PatchOffersOfferIdCodesCodeId400Error */
export interface PatchOffersOfferIdCodesCodeId400Error {
  code: 'invalid_request_payload' | 'no_changes' | 'offer_code_is_archived' | 'offer_is_archived'
}

/** PatchOffersOfferIdCodesCodeId400ErrorResponse */
export interface PatchOffersOfferIdCodesCodeId400ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodesCodeId400Error[]
}

/** PatchOffersOfferIdCodesCodeId403Error */
export interface PatchOffersOfferIdCodesCodeId403Error {
  code: 'sign_in_required'
}

/** PatchOffersOfferIdCodesCodeId403ErrorResponse */
export interface PatchOffersOfferIdCodesCodeId403ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodesCodeId403Error[]
}

/** PatchOffersOfferIdCodesCodeId404Error */
export interface PatchOffersOfferIdCodesCodeId404Error {
  code: 'offer_code_not_found' | 'offer_not_found'
}

/** PatchOffersOfferIdCodesCodeId404ErrorResponse */
export interface PatchOffersOfferIdCodesCodeId404ErrorResponse {
  data: null
  errors: PatchOffersOfferIdCodesCodeId404Error[]
}

/** PatchOffersOfferIdErrorPagesErrorPageId400Error */
export interface PatchOffersOfferIdErrorPagesErrorPageId400Error {
  code: 'invalid_request_payload' | 'no_changes'
}

/** PatchOffersOfferIdErrorPagesErrorPageId400ErrorResponse */
export interface PatchOffersOfferIdErrorPagesErrorPageId400ErrorResponse {
  data: null
  errors: PatchOffersOfferIdErrorPagesErrorPageId400Error[]
}

/** PatchOffersOfferIdErrorPagesErrorPageId403Error */
export interface PatchOffersOfferIdErrorPagesErrorPageId403Error {
  code: 'sign_in_required'
}

/** PatchOffersOfferIdErrorPagesErrorPageId403ErrorResponse */
export interface PatchOffersOfferIdErrorPagesErrorPageId403ErrorResponse {
  data: null
  errors: PatchOffersOfferIdErrorPagesErrorPageId403Error[]
}

/** PatchOffersOfferIdErrorPagesErrorPageId404Error */
export interface PatchOffersOfferIdErrorPagesErrorPageId404Error {
  code: 'offer_error_page_not_found' | 'offer_not_found'
}

/** PatchOffersOfferIdErrorPagesErrorPageId404ErrorResponse */
export interface PatchOffersOfferIdErrorPagesErrorPageId404ErrorResponse {
  data: null
  errors: PatchOffersOfferIdErrorPagesErrorPageId404Error[]
}

/** PatchPartnersPartnerId400Error */
export interface PatchPartnersPartnerId400Error {
  code:
    | 'invalid_request_payload'
    | 'no_changes'
    | 'partner_alias_change_not_allowed'
    | 'partner_alias_not_unique'
    | 'partner_alias_not_valid'
    | 'partner_alias_too_long'
    | 'partner_archival_not_allowed'
    | 'partner_is_archived'
}

/** PatchPartnersPartnerId400ErrorResponse */
export interface PatchPartnersPartnerId400ErrorResponse {
  data: null
  errors: PatchPartnersPartnerId400Error[]
}

/** PatchPartnersPartnerId403Error */
export interface PatchPartnersPartnerId403Error {
  code: 'sign_in_required'
}

/** PatchPartnersPartnerId403ErrorResponse */
export interface PatchPartnersPartnerId403ErrorResponse {
  data: null
  errors: PatchPartnersPartnerId403Error[]
}

/** PatchPartnersPartnerId404Error */
export interface PatchPartnersPartnerId404Error {
  code: 'partner_not_found'
}

/** PatchPartnersPartnerId404ErrorResponse */
export interface PatchPartnersPartnerId404ErrorResponse {
  data: null
  errors: PatchPartnersPartnerId404Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeId400Error */
export interface PatchPaymentsPaymentIdDisputesDisputeId400Error {
  code:
    | 'dispute_description_too_long'
    | 'dispute_transaction_status_change_not_allowed'
    | 'invalid_request_payload'
    | 'no_changes'
}

/** PatchPaymentsPaymentIdDisputesDisputeId400ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeId400ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeId400Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeId403Error */
export interface PatchPaymentsPaymentIdDisputesDisputeId403Error {
  code: 'sign_in_required'
}

/** PatchPaymentsPaymentIdDisputesDisputeId403ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeId403ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeId403Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeId404Error */
export interface PatchPaymentsPaymentIdDisputesDisputeId404Error {
  code: 'dispute_not_found' | 'payment_not_found'
}

/** PatchPaymentsPaymentIdDisputesDisputeId404ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeId404ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeId404Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve400Error */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve400Error {
  code:
    | 'dispute_already_resolved'
    | 'dispute_resolution_comment_too_long'
    | 'invalid_request_payload'
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve400ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve400ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeIdResolve400Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve403Error */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve403Error {
  code: 'sign_in_required'
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve403ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve403ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeIdResolve403Error[]
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve404Error */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve404Error {
  code: 'dispute_not_found' | 'payment_not_found'
}

/** PatchPaymentsPaymentIdDisputesDisputeIdResolve404ErrorResponse */
export interface PatchPaymentsPaymentIdDisputesDisputeIdResolve404ErrorResponse {
  data: null
  errors: PatchPaymentsPaymentIdDisputesDisputeIdResolve404Error[]
}

/** PatchPromotedChallengesPromotedChallengeId400Error */
export interface PatchPromotedChallengesPromotedChallengeId400Error {
  code:
    | 'challenge_already_promoted'
    | 'challenge_deleted'
    | 'challenge_not_published'
    | 'cover_art_missing'
    | 'game_not_discoverable'
    | 'invalid_request_payload'
    | 'long_description_empty'
    | 'overlapping_active_promoted_challenge'
    | 'promoted_challenge_active_range_not_set'
    | 'promoted_challenge_active_range_wrong'
    | 'promoted_challenge_not_open'
    | 'promoted_challenge_still_active'
    | 'season_not_found'
    | 'short_description_empty'
}

/** PatchPromotedChallengesPromotedChallengeId400ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeId400ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeId400Error[]
}

/** PatchPromotedChallengesPromotedChallengeId403Error */
export interface PatchPromotedChallengesPromotedChallengeId403Error {
  code: 'sign_in_required'
}

/** PatchPromotedChallengesPromotedChallengeId403ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeId403ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeId403Error[]
}

/** PatchPromotedChallengesPromotedChallengeId404Error */
export interface PatchPromotedChallengesPromotedChallengeId404Error {
  code: 'promoted_challenge_not_found'
}

/** PatchPromotedChallengesPromotedChallengeId404ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeId404ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeId404Error[]
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400Error */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400Error {
  code: 'invalid_request_payload'
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400Error[]
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403Error */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403Error {
  code: 'sign_in_required'
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403Error[]
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404Error */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404Error {
  code: 'promoted_challenge_not_found' | 'score_not_found'
}

/** PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404ErrorResponse */
export interface PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404ErrorResponse {
  data: null
  errors: PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404Error[]
}

/** PatchQuestCollectionsCollectionId400Error */
export interface PatchQuestCollectionsCollectionId400Error {
  code: 'invalid_request_payload' | 'no_changes'
}

/** PatchQuestCollectionsCollectionId400ErrorResponse */
export interface PatchQuestCollectionsCollectionId400ErrorResponse {
  data: null
  errors: PatchQuestCollectionsCollectionId400Error[]
}

/** PatchQuestCollectionsCollectionId403Error */
export interface PatchQuestCollectionsCollectionId403Error {
  code: 'sign_in_required'
}

/** PatchQuestCollectionsCollectionId403ErrorResponse */
export interface PatchQuestCollectionsCollectionId403ErrorResponse {
  data: null
  errors: PatchQuestCollectionsCollectionId403Error[]
}

/** PatchQuestCollectionsCollectionId404Error */
export interface PatchQuestCollectionsCollectionId404Error {
  code: 'quest_collection_not_found' | 'quest_not_found'
}

/** PatchQuestCollectionsCollectionId404ErrorResponse */
export interface PatchQuestCollectionsCollectionId404ErrorResponse {
  data: null
  errors: PatchQuestCollectionsCollectionId404Error[]
}

/** PatchQuestsQuestId400Error */
export interface PatchQuestsQuestId400Error {
  code:
    | 'bank_transaction_note_too_long'
    | 'invalid_bank_transaction_amount'
    | 'invalid_quest_reward_edit'
    | 'invalid_quest_type_parameters'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'no_quest_collection_assigned'
    | 'quest_alias_not_unique'
    | 'quest_alias_not_valid'
    | 'quest_alias_too_long'
    | 'quest_already_in_collection'
    | 'quest_call_to_action_url_invalid'
    | 'quest_cover_image_not_uploaded'
    | 'quest_not_in_collection'
}

/** PatchQuestsQuestId400ErrorResponse */
export interface PatchQuestsQuestId400ErrorResponse {
  data: null
  errors: PatchQuestsQuestId400Error[]
}

/** PatchQuestsQuestId403Error */
export interface PatchQuestsQuestId403Error {
  code: 'sign_in_required'
}

/** PatchQuestsQuestId403ErrorResponse */
export interface PatchQuestsQuestId403ErrorResponse {
  data: null
  errors: PatchQuestsQuestId403Error[]
}

/** PatchQuestsQuestId404Error */
export interface PatchQuestsQuestId404Error {
  code: 'quest_collection_not_found' | 'quest_not_found'
}

/** PatchQuestsQuestId404ErrorResponse */
export interface PatchQuestsQuestId404ErrorResponse {
  data: null
  errors: PatchQuestsQuestId404Error[]
}

/** PatchSeasonsSeasonId400Error */
export interface PatchSeasonsSeasonId400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_studio_metric_key'
    | 'overlapping_active_seasons'
    | 'season_date_range_invalid'
    | 'season_ends_at_missing'
    | 'season_identifier_missing'
    | 'season_identifier_taken'
    | 'season_starts_at_missing'
}

/** PatchSeasonsSeasonId400ErrorResponse */
export interface PatchSeasonsSeasonId400ErrorResponse {
  data: null
  errors: PatchSeasonsSeasonId400Error[]
}

/** PatchSeasonsSeasonId403Error */
export interface PatchSeasonsSeasonId403Error {
  code: 'sign_in_required'
}

/** PatchSeasonsSeasonId403ErrorResponse */
export interface PatchSeasonsSeasonId403ErrorResponse {
  data: null
  errors: PatchSeasonsSeasonId403Error[]
}

/** PatchSeasonsSeasonId404Error */
export interface PatchSeasonsSeasonId404Error {
  code: 'season_not_found'
}

/** PatchSeasonsSeasonId404ErrorResponse */
export interface PatchSeasonsSeasonId404ErrorResponse {
  data: null
  errors: PatchSeasonsSeasonId404Error[]
}

/** PatchSettings400Error */
export interface PatchSettings400Error {
  code: 'invalid_request_payload' | 'invalid_setting' | 'invalid_setting_value' | 'no_changes'
}

/** PatchSettings400ErrorResponse */
export interface PatchSettings400ErrorResponse {
  data: null
  errors: PatchSettings400Error[]
}

/** PatchSettings403Error */
export interface PatchSettings403Error {
  code: 'sign_in_required'
}

/** PatchSettings403ErrorResponse */
export interface PatchSettings403ErrorResponse {
  data: null
  errors: PatchSettings403Error[]
}

/** PatchSettings404Error */
export interface PatchSettings404Error {
  code: 'setting_not_found'
}

/** PatchSettings404ErrorResponse */
export interface PatchSettings404ErrorResponse {
  data: null
  errors: PatchSettings404Error[]
}

/** PatchStickers400Error */
export interface PatchStickers400Error {
  code: 'invalid_request_payload'
}

/** PatchStickers400ErrorResponse */
export interface PatchStickers400ErrorResponse {
  data: null
  errors: PatchStickers400Error[]
}

/** PatchStickers403Error */
export interface PatchStickers403Error {
  code: 'sign_in_required'
}

/** PatchStickers403ErrorResponse */
export interface PatchStickers403ErrorResponse {
  data: null
  errors: PatchStickers403Error[]
}

/** PatchStickers404Error */
export interface PatchStickers404Error {
  code: 'sticker_not_found'
}

/** PatchStickers404ErrorResponse */
export interface PatchStickers404ErrorResponse {
  data: null
  errors: PatchStickers404Error[]
}

/** PatchStickersStickerId400Error */
export interface PatchStickersStickerId400Error {
  code:
    | 'invalid_request_payload'
    | 'no_changes'
    | 'sticker_alias_not_unique'
    | 'sticker_alias_not_valid'
    | 'sticker_alias_too_long'
    | 'sticker_amount_invalid'
    | 'sticker_amount_less_than_one'
    | 'sticker_has_no_cover'
}

/** PatchStickersStickerId400ErrorResponse */
export interface PatchStickersStickerId400ErrorResponse {
  data: null
  errors: PatchStickersStickerId400Error[]
}

/** PatchStickersStickerId403Error */
export interface PatchStickersStickerId403Error {
  code: 'sign_in_required'
}

/** PatchStickersStickerId403ErrorResponse */
export interface PatchStickersStickerId403ErrorResponse {
  data: null
  errors: PatchStickersStickerId403Error[]
}

/** PatchStickersStickerId404Error */
export interface PatchStickersStickerId404Error {
  code: 'sticker_not_found'
}

/** PatchStickersStickerId404ErrorResponse */
export interface PatchStickersStickerId404ErrorResponse {
  data: null
  errors: PatchStickersStickerId404Error[]
}

/** PatchStringsStringId400Error */
export interface PatchStringsStringId400Error {
  code:
    | 'feed_description_too_long'
    | 'feed_title_too_long'
    | 'game_engine_title_too_long'
    | 'game_engine_url_invalid'
    | 'invalid_locale_name'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'publisher_title_too_long'
    | 'publisher_url_invalid'
    | 'sticker_note_too_long'
    | 'sticker_title_too_long'
    | 'string_locale_not_unique'
    | 'string_value_empty'
    | 'string_value_not_unique'
}

/** PatchStringsStringId400ErrorResponse */
export interface PatchStringsStringId400ErrorResponse {
  data: null
  errors: PatchStringsStringId400Error[]
}

/** PatchStringsStringId403Error */
export interface PatchStringsStringId403Error {
  code: 'sign_in_required'
}

/** PatchStringsStringId403ErrorResponse */
export interface PatchStringsStringId403ErrorResponse {
  data: null
  errors: PatchStringsStringId403Error[]
}

/** PatchStringsStringId404Error */
export interface PatchStringsStringId404Error {
  code: 'string_not_found'
}

/** PatchStringsStringId404ErrorResponse */
export interface PatchStringsStringId404ErrorResponse {
  data: null
  errors: PatchStringsStringId404Error[]
}

export type PatchStudioData = ResponseStudioDetailsResponse

export type PatchStudioError =
  | PatchStudiosStudioId400ErrorResponse
  | PatchStudiosStudioId403ErrorResponse
  | PatchStudiosStudioId404ErrorResponse

/** PatchStudioSettingsRequest */
export interface PatchStudioSettingsRequest {
  changes: SettingOverrideChangeRequest[]
  comment?: string | null
}

/** PatchStudiosStudioId400Error */
export interface PatchStudiosStudioId400Error {
  code:
    | 'invalid_characters_in_name'
    | 'invalid_request_payload'
    | 'long_description_too_long'
    | 'no_changes'
    | 'studio_access_denied'
    | 'studio_contact_email_too_long'
    | 'studio_name_taken'
    | 'studio_name_too_long'
}

/** PatchStudiosStudioId400ErrorResponse */
export interface PatchStudiosStudioId400ErrorResponse {
  data: null
  errors: PatchStudiosStudioId400Error[]
}

/** PatchStudiosStudioId403Error */
export interface PatchStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** PatchStudiosStudioId403ErrorResponse */
export interface PatchStudiosStudioId403ErrorResponse {
  data: null
  errors: PatchStudiosStudioId403Error[]
}

/** PatchStudiosStudioId404Error */
export interface PatchStudiosStudioId404Error {
  code: 'studio_not_found'
}

/** PatchStudiosStudioId404ErrorResponse */
export interface PatchStudiosStudioId404ErrorResponse {
  data: null
  errors: PatchStudiosStudioId404Error[]
}

/** PatchStudiosStudioIdSettings400Error */
export interface PatchStudiosStudioIdSettings400Error {
  code: 'invalid_request_payload' | 'invalid_setting_value' | 'studio_access_denied'
}

/** PatchStudiosStudioIdSettings400ErrorResponse */
export interface PatchStudiosStudioIdSettings400ErrorResponse {
  data: null
  errors: PatchStudiosStudioIdSettings400Error[]
}

/** PatchStudiosStudioIdSettings403Error */
export interface PatchStudiosStudioIdSettings403Error {
  code: 'sign_in_required'
}

/** PatchStudiosStudioIdSettings403ErrorResponse */
export interface PatchStudiosStudioIdSettings403ErrorResponse {
  data: null
  errors: PatchStudiosStudioIdSettings403Error[]
}

/** PatchStudiosStudioIdSettings404Error */
export interface PatchStudiosStudioIdSettings404Error {
  code: 'setting_not_found' | 'studio_not_found'
}

/** PatchStudiosStudioIdSettings404ErrorResponse */
export interface PatchStudiosStudioIdSettings404ErrorResponse {
  data: null
  errors: PatchStudiosStudioIdSettings404Error[]
}

/** PatchTags400Error */
export interface PatchTags400Error {
  code:
    | 'invalid_display_order'
    | 'invalid_request_payload'
    | 'mismatching_tag_ids'
    | 'mismatching_tag_ids_amount'
    | 'tag_category_mismatch'
}

/** PatchTags400ErrorResponse */
export interface PatchTags400ErrorResponse {
  data: null
  errors: PatchTags400Error[]
}

/** PatchTags403Error */
export interface PatchTags403Error {
  code: 'sign_in_required'
}

/** PatchTags403ErrorResponse */
export interface PatchTags403ErrorResponse {
  data: null
  errors: PatchTags403Error[]
}

/** PatchTagsTagId400Error */
export interface PatchTagsTagId400Error {
  code:
    | 'invalid_display_order'
    | 'invalid_request_payload'
    | 'invalid_tag_alias'
    | 'max_child_tags_limit_exceeded'
    | 'max_tag_depth_exceeded'
    | 'mismatching_tag_ids'
    | 'mismatching_tag_ids_amount'
    | 'rename_discounted_games_tag_not_allowed'
    | 'tag_alias_not_unique'
    | 'tag_alias_too_long'
    | 'tag_ancestry_cycle'
    | 'tag_category_inconsistent'
    | 'tag_not_found'
}

/** PatchTagsTagId400ErrorResponse */
export interface PatchTagsTagId400ErrorResponse {
  data: null
  errors: PatchTagsTagId400Error[]
}

/** PatchTagsTagId403Error */
export interface PatchTagsTagId403Error {
  code: 'sign_in_required'
}

/** PatchTagsTagId403ErrorResponse */
export interface PatchTagsTagId403ErrorResponse {
  data: null
  errors: PatchTagsTagId403Error[]
}

/** PatchTagsTagId404Error */
export interface PatchTagsTagId404Error {
  code: 'tag_not_found'
}

/** PatchTagsTagId404ErrorResponse */
export interface PatchTagsTagId404ErrorResponse {
  data: null
  errors: PatchTagsTagId404Error[]
}

/** PatchUserChallengePlayStatistics403Error */
export interface PatchUserChallengePlayStatistics403Error {
  code: 'sign_in_required'
}

/** PatchUserChallengePlayStatistics403ErrorResponse */
export interface PatchUserChallengePlayStatistics403ErrorResponse {
  data: null
  errors: PatchUserChallengePlayStatistics403Error[]
}

/** PatchUserGamePlayStatistics403Error */
export interface PatchUserGamePlayStatistics403Error {
  code: 'sign_in_required'
}

/** PatchUserGamePlayStatistics403ErrorResponse */
export interface PatchUserGamePlayStatistics403ErrorResponse {
  data: null
  errors: PatchUserGamePlayStatistics403Error[]
}

/** PatchUserUserIdBioLinks400Error */
export interface PatchUserUserIdBioLinks400Error {
  code: 'invalid_link_ids' | 'invalid_request_payload'
}

/** PatchUserUserIdBioLinks400ErrorResponse */
export interface PatchUserUserIdBioLinks400ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinks400Error[]
}

/** PatchUserUserIdBioLinks403Error */
export interface PatchUserUserIdBioLinks403Error {
  code: 'sign_in_required'
}

/** PatchUserUserIdBioLinks403ErrorResponse */
export interface PatchUserUserIdBioLinks403ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinks403Error[]
}

/** PatchUserUserIdBioLinks404Error */
export interface PatchUserUserIdBioLinks404Error {
  code: 'bio_link_not_found' | 'user_not_found'
}

/** PatchUserUserIdBioLinks404ErrorResponse */
export interface PatchUserUserIdBioLinks404ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinks404Error[]
}

/** PatchUserUserIdBioLinksLinkId400Error */
export interface PatchUserUserIdBioLinksLinkId400Error {
  code:
    | 'bio_link_title_too_long'
    | 'bio_link_url_invalid'
    | 'invalid_request_payload'
    | 'no_changes'
}

/** PatchUserUserIdBioLinksLinkId400ErrorResponse */
export interface PatchUserUserIdBioLinksLinkId400ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinksLinkId400Error[]
}

/** PatchUserUserIdBioLinksLinkId403Error */
export interface PatchUserUserIdBioLinksLinkId403Error {
  code: 'sign_in_required'
}

/** PatchUserUserIdBioLinksLinkId403ErrorResponse */
export interface PatchUserUserIdBioLinksLinkId403ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinksLinkId403Error[]
}

/** PatchUserUserIdBioLinksLinkId404Error */
export interface PatchUserUserIdBioLinksLinkId404Error {
  code: 'bio_link_not_found' | 'user_not_found'
}

/** PatchUserUserIdBioLinksLinkId404ErrorResponse */
export interface PatchUserUserIdBioLinksLinkId404ErrorResponse {
  data: null
  errors: PatchUserUserIdBioLinksLinkId404Error[]
}

/** PatchUsersOverrideMaxGamesLimit400Error */
export interface PatchUsersOverrideMaxGamesLimit400Error {
  code: 'max_games_limit_less_than_zero'
}

/** PatchUsersOverrideMaxGamesLimit400ErrorResponse */
export interface PatchUsersOverrideMaxGamesLimit400ErrorResponse {
  data: null
  errors: PatchUsersOverrideMaxGamesLimit400Error[]
}

/** PatchUsersOverrideMaxGamesLimit403Error */
export interface PatchUsersOverrideMaxGamesLimit403Error {
  code: 'sign_in_required'
}

/** PatchUsersOverrideMaxGamesLimit403ErrorResponse */
export interface PatchUsersOverrideMaxGamesLimit403ErrorResponse {
  data: null
  errors: PatchUsersOverrideMaxGamesLimit403Error[]
}

/** PatchUsersOverrideMaxGamesLimit404Error */
export interface PatchUsersOverrideMaxGamesLimit404Error {
  code: 'user_not_found'
}

/** PatchUsersOverrideMaxGamesLimit404ErrorResponse */
export interface PatchUsersOverrideMaxGamesLimit404ErrorResponse {
  data: null
  errors: PatchUsersOverrideMaxGamesLimit404Error[]
}

/** PatchUsersUserIdSettings400Error */
export interface PatchUsersUserIdSettings400Error {
  code: 'invalid_request_payload' | 'invalid_setting_value' | 'no_changes'
}

/** PatchUsersUserIdSettings400ErrorResponse */
export interface PatchUsersUserIdSettings400ErrorResponse {
  data: null
  errors: PatchUsersUserIdSettings400Error[]
}

/** PatchUsersUserIdSettings403Error */
export interface PatchUsersUserIdSettings403Error {
  code: 'sign_in_required'
}

/** PatchUsersUserIdSettings403ErrorResponse */
export interface PatchUsersUserIdSettings403ErrorResponse {
  data: null
  errors: PatchUsersUserIdSettings403Error[]
}

/** PatchUsersUserIdSettings404Error */
export interface PatchUsersUserIdSettings404Error {
  code: 'user_not_found'
}

/** PatchUsersUserIdSettings404ErrorResponse */
export interface PatchUsersUserIdSettings404ErrorResponse {
  data: null
  errors: PatchUsersUserIdSettings404Error[]
}

/** PatchV2StudiosStudioIdSettings400Error */
export interface PatchV2StudiosStudioIdSettings400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_setting'
    | 'invalid_setting_id'
    | 'invalid_setting_value'
    | 'no_changes'
    | 'setting_not_overridden'
    | 'studio_access_denied'
}

/** PatchV2StudiosStudioIdSettings400ErrorResponse */
export interface PatchV2StudiosStudioIdSettings400ErrorResponse {
  data: null
  errors: PatchV2StudiosStudioIdSettings400Error[]
}

/** PatchV2StudiosStudioIdSettings403Error */
export interface PatchV2StudiosStudioIdSettings403Error {
  code: 'sign_in_required'
}

/** PatchV2StudiosStudioIdSettings403ErrorResponse */
export interface PatchV2StudiosStudioIdSettings403ErrorResponse {
  data: null
  errors: PatchV2StudiosStudioIdSettings403Error[]
}

/** PatchV2StudiosStudioIdSettings404Error */
export interface PatchV2StudiosStudioIdSettings404Error {
  code: 'setting_not_found' | 'studio_not_found'
}

/** PatchV2StudiosStudioIdSettings404ErrorResponse */
export interface PatchV2StudiosStudioIdSettings404ErrorResponse {
  data: null
  errors: PatchV2StudiosStudioIdSettings404Error[]
}

/** PatchWrappersWrapperId400Error */
export interface PatchWrappersWrapperId400Error {
  code:
    | 'invalid_request_payload'
    | 'wrapper_deployment_is_active'
    | 'wrapper_is_default'
    | 'wrapper_name_taken'
    | 'wrapper_not_active'
    | 'wrapper_not_uploaded'
}

/** PatchWrappersWrapperId400ErrorResponse */
export interface PatchWrappersWrapperId400ErrorResponse {
  data: null
  errors: PatchWrappersWrapperId400Error[]
}

/** PatchWrappersWrapperId403Error */
export interface PatchWrappersWrapperId403Error {
  code: 'sign_in_required'
}

/** PatchWrappersWrapperId403ErrorResponse */
export interface PatchWrappersWrapperId403ErrorResponse {
  data: null
  errors: PatchWrappersWrapperId403Error[]
}

/** PatchWrappersWrapperId404Error */
export interface PatchWrappersWrapperId404Error {
  code: 'wrapper_not_found'
}

/** PatchWrappersWrapperId404ErrorResponse */
export interface PatchWrappersWrapperId404ErrorResponse {
  data: null
  errors: PatchWrappersWrapperId404Error[]
}

/** BO paginated list of payment ids */
export interface PaymentsIdsResponse {
  pagination: PaginationResponse
  paymentIds: string[]
}

/** PendingChangesConfigurationRequest */
export interface PendingChangesConfigurationRequest {
  /**
   * The scheduled time to apply pending changes.
   *
   * Notes: *A null value means that the pending changes are not scheduled to be applied automatically.*
   * @format date-time
   */
  applyPendingChangesAt?: string | null
}

/** PendingChangesEntriesResponse */
export interface PendingChangesEntriesResponse {
  /** Property shows state of the pending changes for the feed entries. */
  thisResponseContains?: 'ACTIVE_STATE' | 'PENDING_STATE' | null
}

/** PendingChangesResponse */
export interface PendingChangesResponse {
  /**
   * Time when pending changes could be applied to the feed
   *
   * Notes: *Null if pending changes will be applied manually.*
   * @format date-time
   * @example "2022-11-11T08:00:00.000Z"
   */
  applyPendingChangesAt?: string | null
  /** Property shows state of the pending changes for the current feed */
  thisResponseContains?: 'ACTIVE_STATE' | 'PENDING_STATE' | null
}

/**
 * PointsTransactionRequest
 * Season points transaction.
 *
 * Exactly one of `username`, `studioId` and `groupId` must be non-null.
 */
export interface PointsTransactionRequest {
  /**
   * Optional comment for internal (back-office) use.
   * @example "Cheater!"
   */
  comment?: string | null
  /**
   * Group id of studio whose points to adjust.
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  groupId?: string | null
  /**
   * Amount to adjust points by. Can be positive or negative, but not zero.
   * @format int32
   */
  pointsAdjustment: number
  /**
   * Optional promoted challenge that is the source of the points.
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  promotedChallengeId?: string | null
  /**
   * ID of the studio whose points to adjust.
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  studioId?: string | null
  /**
   * Username of user whose points to adjust.
   * @example "Starlord"
   */
  username?: string | null
}

/** PointsTransactionResponse */
export interface PointsTransactionResponse {
  active?: boolean | null
  /**
   * Optional comment for internal (back-office) use.
   * @example "Cheater!"
   */
  comment?: string | null
  /**
   * Time when transaction was created.
   * @format date-time
   * @example "2022-03-05T12:34:51.432+01:00"
   */
  createdAt?: string | null
  createdBy?: BackOfficeUserResponse | null
  /** The key of the studio metric of this transaction. */
  metricKey?: string | null
  /**
   * Amount the user's points were adjusted by. Can be positive or negative.
   * @format int32
   */
  pointsAdjustment?: number | null
  promotedChallenge?: PromotedChallengeResponse | null
  scoringPeriod?: string | null
  studio?: StudioResponse | null
  /**
   * Transaction id
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  transactionId: string
  user?: UserSummaryResponse | null
}

/** PointsTransactionsResponse */
export interface PointsTransactionsResponse {
  pagination: PaginationResponse
  transactions: PointsTransactionResponse[]
}

/** PostAdministrationUsersElevate403Error */
export interface PostAdministrationUsersElevate403Error {
  code: 'sign_in_required'
}

/** PostAdministrationUsersElevate403ErrorResponse */
export interface PostAdministrationUsersElevate403ErrorResponse {
  data: null
  errors: PostAdministrationUsersElevate403Error[]
}

/** PostApiclients400Error */
export interface PostApiclients400Error {
  code:
    | 'api_client_alias_not_unique'
    | 'api_client_alias_not_valid'
    | 'api_client_alias_too_long'
    | 'invalid_allowed_origin'
    | 'invalid_allowed_origin_pattern'
    | 'invalid_request_payload'
}

/** PostApiclients400ErrorResponse */
export interface PostApiclients400ErrorResponse {
  data: null
  errors: PostApiclients400Error[]
}

/** PostApiclients403Error */
export interface PostApiclients403Error {
  code: 'sign_in_required'
}

/** PostApiclients403ErrorResponse */
export interface PostApiclients403ErrorResponse {
  data: null
  errors: PostApiclients403Error[]
}

/** PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403Error */
export interface PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403Error {
  code: 'sign_in_required'
}

/** PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403ErrorResponse */
export interface PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403ErrorResponse {
  data: null
  errors: PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403Error[]
}

/** PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404Error */
export interface PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404Error {
  code:
    | 'asset_variant_configuration_not_found'
    | 'challenge_not_found'
    | 'game_not_found'
    | 'mod_not_found'
    | 'quest_not_found'
    | 'release_not_found'
    | 'sticker_not_found'
    | 'studio_not_found'
    | 'user_not_found'
}

/** PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404ErrorResponse */
export interface PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404ErrorResponse {
  data: null
  errors: PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404Error[]
}

/** PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403Error */
export interface PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403Error {
  code: 'sign_in_required'
}

/** PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403ErrorResponse */
export interface PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403ErrorResponse {
  data: null
  errors: PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403Error[]
}

/** PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404Error */
export interface PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404Error {
  code: 'asset_variant_configuration_not_found'
}

/** PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404ErrorResponse */
export interface PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404ErrorResponse {
  data: null
  errors: PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404Error[]
}

/** PostAssetVariantsProfilesProfileKeyConfigurations400Error */
export interface PostAssetVariantsProfilesProfileKeyConfigurations400Error {
  code:
    | 'asset_variant_key_not_unique'
    | 'image_invalid_aspect_ratio'
    | 'invalid_asset_variant_format'
    | 'invalid_asset_variant_key'
    | 'invalid_request_payload'
}

/** PostAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse */
export interface PostAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse {
  data: null
  errors: PostAssetVariantsProfilesProfileKeyConfigurations400Error[]
}

/** PostAssetVariantsProfilesProfileKeyConfigurations403Error */
export interface PostAssetVariantsProfilesProfileKeyConfigurations403Error {
  code: 'sign_in_required'
}

/** PostAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse */
export interface PostAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse {
  data: null
  errors: PostAssetVariantsProfilesProfileKeyConfigurations403Error[]
}

/** PostAssetVariantsProfilesProfileKeyConfigurations404Error */
export interface PostAssetVariantsProfilesProfileKeyConfigurations404Error {
  code: 'asset_variants_profile_not_found'
}

/** PostAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse */
export interface PostAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse {
  data: null
  errors: PostAssetVariantsProfilesProfileKeyConfigurations404Error[]
}

/** PostAssetsUpdateBucketIdentifier403Error */
export interface PostAssetsUpdateBucketIdentifier403Error {
  code: 'sign_in_required'
}

/** PostAssetsUpdateBucketIdentifier403ErrorResponse */
export interface PostAssetsUpdateBucketIdentifier403ErrorResponse {
  data: null
  errors: PostAssetsUpdateBucketIdentifier403Error[]
}

/** PostBackendhosts400Error */
export interface PostBackendhosts400Error {
  code:
    | 'backend_host_alias_not_unique'
    | 'backend_host_alias_not_valid'
    | 'backend_host_alias_too_long'
    | 'backend_host_hostname_not_unique'
    | 'backend_host_zero_allowed_api_namespaces'
    | 'invalid_request_payload'
}

/** PostBackendhosts400ErrorResponse */
export interface PostBackendhosts400ErrorResponse {
  data: null
  errors: PostBackendhosts400Error[]
}

/** PostBackendhosts403Error */
export interface PostBackendhosts403Error {
  code: 'sign_in_required'
}

/** PostBackendhosts403ErrorResponse */
export interface PostBackendhosts403ErrorResponse {
  data: null
  errors: PostBackendhosts403Error[]
}

/** PostBankAccounts400Error */
export interface PostBankAccounts400Error {
  code: 'invalid_request_payload' | 'too_many_bank_accounts'
}

/** PostBankAccounts400ErrorResponse */
export interface PostBankAccounts400ErrorResponse {
  data: null
  errors: PostBankAccounts400Error[]
}

/** PostBankAccounts403Error */
export interface PostBankAccounts403Error {
  code: 'sign_in_required'
}

/** PostBankAccounts403ErrorResponse */
export interface PostBankAccounts403ErrorResponse {
  data: null
  errors: PostBankAccounts403Error[]
}

/** PostBankAccounts404Error */
export interface PostBankAccounts404Error {
  code: 'bank_currency_not_found' | 'user_not_found'
}

/** PostBankAccounts404ErrorResponse */
export interface PostBankAccounts404ErrorResponse {
  data: null
  errors: PostBankAccounts404Error[]
}

/** PostBankTransactions400Error */
export interface PostBankTransactions400Error {
  code:
    | 'bank_account_currency_mismatch'
    | 'bank_account_insufficient_balance'
    | 'bank_account_is_archived'
    | 'bank_transaction_note_too_long'
    | 'invalid_bank_transaction_amount'
    | 'invalid_bank_transfer'
    | 'invalid_request_payload'
}

/** PostBankTransactions400ErrorResponse */
export interface PostBankTransactions400ErrorResponse {
  data: null
  errors: PostBankTransactions400Error[]
}

/** PostBankTransactions403Error */
export interface PostBankTransactions403Error {
  code: 'sign_in_required'
}

/** PostBankTransactions403ErrorResponse */
export interface PostBankTransactions403ErrorResponse {
  data: null
  errors: PostBankTransactions403Error[]
}

/** PostBankTransactions404Error */
export interface PostBankTransactions404Error {
  code: 'bank_account_not_found'
}

/** PostBankTransactions404ErrorResponse */
export interface PostBankTransactions404ErrorResponse {
  data: null
  errors: PostBankTransactions404Error[]
}

/** PostCmsConfigurations400Error */
export interface PostCmsConfigurations400Error {
  code:
    | 'cms_configuration_alias_invalid'
    | 'cms_configuration_alias_taken'
    | 'cms_configuration_alias_too_long'
    | 'configuration_name_missing'
    | 'configuration_name_not_unique'
    | 'invalid_request_payload'
}

/** PostCmsConfigurations400ErrorResponse */
export interface PostCmsConfigurations400ErrorResponse {
  data: null
  errors: PostCmsConfigurations400Error[]
}

/** PostCmsConfigurations403Error */
export interface PostCmsConfigurations403Error {
  code: 'sign_in_required'
}

/** PostCmsConfigurations403ErrorResponse */
export interface PostCmsConfigurations403ErrorResponse {
  data: null
  errors: PostCmsConfigurations403Error[]
}

/** PostCmsConfigurationsConfigurationIdSections400Error */
export interface PostCmsConfigurationsConfigurationIdSections400Error {
  code:
    | 'configuration_section_feed_id_missing'
    | 'configuration_section_name_missing'
    | 'configuration_section_type_missing'
    | 'configuration_too_many_sections'
    | 'date_range_invalid'
    | 'feed_not_found'
    | 'invalid_request_payload'
}

/** PostCmsConfigurationsConfigurationIdSections400ErrorResponse */
export interface PostCmsConfigurationsConfigurationIdSections400ErrorResponse {
  data: null
  errors: PostCmsConfigurationsConfigurationIdSections400Error[]
}

/** PostCmsConfigurationsConfigurationIdSections403Error */
export interface PostCmsConfigurationsConfigurationIdSections403Error {
  code: 'sign_in_required'
}

/** PostCmsConfigurationsConfigurationIdSections403ErrorResponse */
export interface PostCmsConfigurationsConfigurationIdSections403ErrorResponse {
  data: null
  errors: PostCmsConfigurationsConfigurationIdSections403Error[]
}

/** PostCmsConfigurationsConfigurationIdSections404Error */
export interface PostCmsConfigurationsConfigurationIdSections404Error {
  code: 'configuration_not_found'
}

/** PostCmsConfigurationsConfigurationIdSections404ErrorResponse */
export interface PostCmsConfigurationsConfigurationIdSections404ErrorResponse {
  data: null
  errors: PostCmsConfigurationsConfigurationIdSections404Error[]
}

/** PostDynamicConfigClients400Error */
export interface PostDynamicConfigClients400Error {
  code:
    | 'dynamic_config_alias_alias_not_valid'
    | 'dynamic_config_alias_alias_too_long'
    | 'dynamic_config_alias_already_taken'
    | 'invalid_request_payload'
}

/** PostDynamicConfigClients400ErrorResponse */
export interface PostDynamicConfigClients400ErrorResponse {
  data: null
  errors: PostDynamicConfigClients400Error[]
}

/** PostDynamicConfigClients403Error */
export interface PostDynamicConfigClients403Error {
  code: 'sign_in_required'
}

/** PostDynamicConfigClients403ErrorResponse */
export interface PostDynamicConfigClients403ErrorResponse {
  data: null
  errors: PostDynamicConfigClients403Error[]
}

/** PostDynamicConfigConfigIdPurgeCache403Error */
export interface PostDynamicConfigConfigIdPurgeCache403Error {
  code: 'sign_in_required'
}

/** PostDynamicConfigConfigIdPurgeCache403ErrorResponse */
export interface PostDynamicConfigConfigIdPurgeCache403ErrorResponse {
  data: null
  errors: PostDynamicConfigConfigIdPurgeCache403Error[]
}

/** PostDynamicConfigConfigIdPurgeCache404Error */
export interface PostDynamicConfigConfigIdPurgeCache404Error {
  code: 'dynamic_config_not_found'
}

/** PostDynamicConfigConfigIdPurgeCache404ErrorResponse */
export interface PostDynamicConfigConfigIdPurgeCache404ErrorResponse {
  data: null
  errors: PostDynamicConfigConfigIdPurgeCache404Error[]
}

/** PostDynamicConfigPurgeCache403Error */
export interface PostDynamicConfigPurgeCache403Error {
  code: 'sign_in_required'
}

/** PostDynamicConfigPurgeCache403ErrorResponse */
export interface PostDynamicConfigPurgeCache403ErrorResponse {
  data: null
  errors: PostDynamicConfigPurgeCache403Error[]
}

/** PostEmailingTopics403Error */
export interface PostEmailingTopics403Error {
  code: 'sign_in_required'
}

/** PostEmailingTopics403ErrorResponse */
export interface PostEmailingTopics403ErrorResponse {
  data: null
  errors: PostEmailingTopics403Error[]
}

/** PostEmailingTopicsMarketingConsent400Error */
export interface PostEmailingTopicsMarketingConsent400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostEmailingTopicsMarketingConsent400ErrorResponse */
export interface PostEmailingTopicsMarketingConsent400ErrorResponse {
  data: null
  errors: PostEmailingTopicsMarketingConsent400Error[]
}

/** PostEmailingTopicsMarketingConsent403Error */
export interface PostEmailingTopicsMarketingConsent403Error {
  code: 'sign_in_required'
}

/** PostEmailingTopicsMarketingConsent403ErrorResponse */
export interface PostEmailingTopicsMarketingConsent403ErrorResponse {
  data: null
  errors: PostEmailingTopicsMarketingConsent403Error[]
}

/** PostEngines400Error */
export interface PostEngines400Error {
  code:
    | 'game_engine_alias_not_unique'
    | 'game_engine_alias_too_long'
    | 'game_engine_title_taken'
    | 'game_engine_title_too_long'
    | 'game_engine_url_invalid'
    | 'invalid_game_engine_alias'
    | 'invalid_request_payload'
}

/** PostEngines400ErrorResponse */
export interface PostEngines400ErrorResponse {
  data: null
  errors: PostEngines400Error[]
}

/** PostEngines403Error */
export interface PostEngines403Error {
  code: 'sign_in_required'
}

/** PostEngines403ErrorResponse */
export interface PostEngines403ErrorResponse {
  data: null
  errors: PostEngines403Error[]
}

/** PostFeeds400Error */
export interface PostFeeds400Error {
  code:
    | 'feed_alias_taken'
    | 'feed_description_too_long'
    | 'feed_title_too_long'
    | 'invalid_feed_type'
    | 'invalid_request_payload'
}

/** PostFeeds400ErrorResponse */
export interface PostFeeds400ErrorResponse {
  data: null
  errors: PostFeeds400Error[]
}

/** PostFeeds403Error */
export interface PostFeeds403Error {
  code: 'sign_in_required'
}

/** PostFeeds403ErrorResponse */
export interface PostFeeds403ErrorResponse {
  data: null
  errors: PostFeeds403Error[]
}

/** PostFeedsFeedIdApplyPendingChanges400Error */
export interface PostFeedsFeedIdApplyPendingChanges400Error {
  code: 'feed_alias_taken' | 'no_pending_changes_for_feed'
}

/** PostFeedsFeedIdApplyPendingChanges400ErrorResponse */
export interface PostFeedsFeedIdApplyPendingChanges400ErrorResponse {
  data: null
  errors: PostFeedsFeedIdApplyPendingChanges400Error[]
}

/** PostFeedsFeedIdApplyPendingChanges403Error */
export interface PostFeedsFeedIdApplyPendingChanges403Error {
  code: 'sign_in_required'
}

/** PostFeedsFeedIdApplyPendingChanges403ErrorResponse */
export interface PostFeedsFeedIdApplyPendingChanges403ErrorResponse {
  data: null
  errors: PostFeedsFeedIdApplyPendingChanges403Error[]
}

/** PostFeedsFeedIdApplyPendingChanges404Error */
export interface PostFeedsFeedIdApplyPendingChanges404Error {
  code: 'feed_not_found'
}

/** PostFeedsFeedIdApplyPendingChanges404ErrorResponse */
export interface PostFeedsFeedIdApplyPendingChanges404ErrorResponse {
  data: null
  errors: PostFeedsFeedIdApplyPendingChanges404Error[]
}

/** PostFeedsFeedIdBeginPendingChanges400Error */
export interface PostFeedsFeedIdBeginPendingChanges400Error {
  code:
    | 'feed_not_support_pending_changes'
    | 'invalid_request_payload'
    | 'pending_changes_for_feed_exist'
}

/** PostFeedsFeedIdBeginPendingChanges400ErrorResponse */
export interface PostFeedsFeedIdBeginPendingChanges400ErrorResponse {
  data: null
  errors: PostFeedsFeedIdBeginPendingChanges400Error[]
}

/** PostFeedsFeedIdBeginPendingChanges403Error */
export interface PostFeedsFeedIdBeginPendingChanges403Error {
  code: 'sign_in_required'
}

/** PostFeedsFeedIdBeginPendingChanges403ErrorResponse */
export interface PostFeedsFeedIdBeginPendingChanges403ErrorResponse {
  data: null
  errors: PostFeedsFeedIdBeginPendingChanges403Error[]
}

/** PostFeedsFeedIdBeginPendingChanges404Error */
export interface PostFeedsFeedIdBeginPendingChanges404Error {
  code: 'feed_not_found'
}

/** PostFeedsFeedIdBeginPendingChanges404ErrorResponse */
export interface PostFeedsFeedIdBeginPendingChanges404ErrorResponse {
  data: null
  errors: PostFeedsFeedIdBeginPendingChanges404Error[]
}

/** PostFeedsFeedIdCancelPendingChanges400Error */
export interface PostFeedsFeedIdCancelPendingChanges400Error {
  code: 'no_pending_changes_for_feed'
}

/** PostFeedsFeedIdCancelPendingChanges400ErrorResponse */
export interface PostFeedsFeedIdCancelPendingChanges400ErrorResponse {
  data: null
  errors: PostFeedsFeedIdCancelPendingChanges400Error[]
}

/** PostFeedsFeedIdCancelPendingChanges403Error */
export interface PostFeedsFeedIdCancelPendingChanges403Error {
  code: 'sign_in_required'
}

/** PostFeedsFeedIdCancelPendingChanges403ErrorResponse */
export interface PostFeedsFeedIdCancelPendingChanges403ErrorResponse {
  data: null
  errors: PostFeedsFeedIdCancelPendingChanges403Error[]
}

/** PostFeedsFeedIdCancelPendingChanges404Error */
export interface PostFeedsFeedIdCancelPendingChanges404Error {
  code: 'feed_not_found'
}

/** PostFeedsFeedIdCancelPendingChanges404ErrorResponse */
export interface PostFeedsFeedIdCancelPendingChanges404ErrorResponse {
  data: null
  errors: PostFeedsFeedIdCancelPendingChanges404Error[]
}

/** PostFeedsFeedIdEntries400Error */
export interface PostFeedsFeedIdEntries400Error {
  code:
    | 'entry_already_in_feed'
    | 'invalid_collection_entry_attribute'
    | 'invalid_request_payload'
    | 'no_ids_given'
    | 'too_many_ids_given'
}

/** PostFeedsFeedIdEntries400ErrorResponse */
export interface PostFeedsFeedIdEntries400ErrorResponse {
  data: null
  errors: PostFeedsFeedIdEntries400Error[]
}

/** PostFeedsFeedIdEntries403Error */
export interface PostFeedsFeedIdEntries403Error {
  code: 'sign_in_required'
}

/** PostFeedsFeedIdEntries403ErrorResponse */
export interface PostFeedsFeedIdEntries403ErrorResponse {
  data: null
  errors: PostFeedsFeedIdEntries403Error[]
}

/** PostFeedsFeedIdEntries404Error */
export interface PostFeedsFeedIdEntries404Error {
  code:
    | 'challenge_not_found'
    | 'feed_not_found'
    | 'game_not_found'
    | 'mod_not_found'
    | 'tag_not_found'
}

/** PostFeedsFeedIdEntries404ErrorResponse */
export interface PostFeedsFeedIdEntries404ErrorResponse {
  data: null
  errors: PostFeedsFeedIdEntries404Error[]
}

/** PostFeedsFeedIdRefreshSnapshot400Error */
export interface PostFeedsFeedIdRefreshSnapshot400Error {
  code:
    | 'feed_snapshot_not_configured'
    | 'invalid_filtering_expression'
    | 'invalid_sort'
    | 'too_many_tags'
    | 'wrong_feed_type'
}

/** PostFeedsFeedIdRefreshSnapshot400ErrorResponse */
export interface PostFeedsFeedIdRefreshSnapshot400ErrorResponse {
  data: null
  errors: PostFeedsFeedIdRefreshSnapshot400Error[]
}

/** PostFeedsFeedIdRefreshSnapshot403Error */
export interface PostFeedsFeedIdRefreshSnapshot403Error {
  code: 'sign_in_required'
}

/** PostFeedsFeedIdRefreshSnapshot403ErrorResponse */
export interface PostFeedsFeedIdRefreshSnapshot403ErrorResponse {
  data: null
  errors: PostFeedsFeedIdRefreshSnapshot403Error[]
}

/** PostFeedsFeedIdRefreshSnapshot404Error */
export interface PostFeedsFeedIdRefreshSnapshot404Error {
  code: 'feed_not_found'
}

/** PostFeedsFeedIdRefreshSnapshot404ErrorResponse */
export interface PostFeedsFeedIdRefreshSnapshot404ErrorResponse {
  data: null
  errors: PostFeedsFeedIdRefreshSnapshot404Error[]
}

/** PostFundingWaitList400Error */
export interface PostFundingWaitList400Error {
  code:
    | 'already_enlisted'
    | 'funding_subscriber_email_too_long'
    | 'invalid_email_format'
    | 'invalid_request_payload'
}

/** PostFundingWaitList400ErrorResponse */
export interface PostFundingWaitList400ErrorResponse {
  data: null
  errors: PostFundingWaitList400Error[]
}

/** PostFundingWaitList403Error */
export interface PostFundingWaitList403Error {
  code: 'sign_in_required'
}

/** PostFundingWaitList403ErrorResponse */
export interface PostFundingWaitList403ErrorResponse {
  data: null
  errors: PostFundingWaitList403Error[]
}

export type PostGameReviewV2Data = ResponseReviewResponse

export type PostGameReviewV2Error =
  | PostV2GamesGameIdReviews400ErrorResponse
  | PostV2GamesGameIdReviews403ErrorResponse
  | PostV2GamesGameIdReviews404ErrorResponse

/** PostGameWrappers400Error */
export interface PostGameWrappers400Error {
  code:
    | 'game_wrapper_name_empty'
    | 'game_wrapper_name_taken'
    | 'invalid_request_payload'
    | 'no_default_engine_set'
}

/** PostGameWrappers400ErrorResponse */
export interface PostGameWrappers400ErrorResponse {
  data: null
  errors: PostGameWrappers400Error[]
}

/** PostGameWrappers403Error */
export interface PostGameWrappers403Error {
  code: 'sign_in_required'
}

/** PostGameWrappers403ErrorResponse */
export interface PostGameWrappers403ErrorResponse {
  data: null
  errors: PostGameWrappers403Error[]
}

/** PostGameWrappers404Error */
export interface PostGameWrappers404Error {
  code: 'game_engine_not_found'
}

/** PostGameWrappers404ErrorResponse */
export interface PostGameWrappers404ErrorResponse {
  data: null
  errors: PostGameWrappers404Error[]
}

/** PostGameWrappers500Error */
export interface PostGameWrappers500Error {
  code: 'internal_server_error'
}

/** PostGameWrappers500ErrorResponse */
export interface PostGameWrappers500ErrorResponse {
  data: null
  errors: PostGameWrappers500Error[]
}

/** PostGamesApplyDefaultStorageTagsToPublishingFiles400Error */
export interface PostGamesApplyDefaultStorageTagsToPublishingFiles400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostGamesApplyDefaultStorageTagsToPublishingFiles400ErrorResponse */
export interface PostGamesApplyDefaultStorageTagsToPublishingFiles400ErrorResponse {
  data: null
  errors: PostGamesApplyDefaultStorageTagsToPublishingFiles400Error[]
}

/** PostGamesApplyDefaultStorageTagsToPublishingFiles403Error */
export interface PostGamesApplyDefaultStorageTagsToPublishingFiles403Error {
  code: 'sign_in_required'
}

/** PostGamesApplyDefaultStorageTagsToPublishingFiles403ErrorResponse */
export interface PostGamesApplyDefaultStorageTagsToPublishingFiles403ErrorResponse {
  data: null
  errors: PostGamesApplyDefaultStorageTagsToPublishingFiles403Error[]
}

/** PostGamesApplyDefaultStorageTagsToReleaseFiles400Error */
export interface PostGamesApplyDefaultStorageTagsToReleaseFiles400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostGamesApplyDefaultStorageTagsToReleaseFiles400ErrorResponse */
export interface PostGamesApplyDefaultStorageTagsToReleaseFiles400ErrorResponse {
  data: null
  errors: PostGamesApplyDefaultStorageTagsToReleaseFiles400Error[]
}

/** PostGamesApplyDefaultStorageTagsToReleaseFiles403Error */
export interface PostGamesApplyDefaultStorageTagsToReleaseFiles403Error {
  code: 'sign_in_required'
}

/** PostGamesApplyDefaultStorageTagsToReleaseFiles403ErrorResponse */
export interface PostGamesApplyDefaultStorageTagsToReleaseFiles403ErrorResponse {
  data: null
  errors: PostGamesApplyDefaultStorageTagsToReleaseFiles403Error[]
}

/** PostGamesGameIdBanners400Error */
export interface PostGamesGameIdBanners400Error {
  code:
    | 'empty_game_banner_alias'
    | 'game_access_denied'
    | 'game_banner_alias_not_unique'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_game_banner_alias'
    | 'invalid_image'
    | 'too_many_game_banners'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PostGamesGameIdBanners400ErrorResponse */
export interface PostGamesGameIdBanners400ErrorResponse {
  data: null
  errors: PostGamesGameIdBanners400Error[]
}

/** PostGamesGameIdBanners403Error */
export interface PostGamesGameIdBanners403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdBanners403ErrorResponse */
export interface PostGamesGameIdBanners403ErrorResponse {
  data: null
  errors: PostGamesGameIdBanners403Error[]
}

/** PostGamesGameIdBanners404Error */
export interface PostGamesGameIdBanners404Error {
  code: 'game_not_found'
}

/** PostGamesGameIdBanners404ErrorResponse */
export interface PostGamesGameIdBanners404ErrorResponse {
  data: null
  errors: PostGamesGameIdBanners404Error[]
}

/** PostGamesGameIdBanners500Error */
export interface PostGamesGameIdBanners500Error {
  code: 'internal_server_error'
}

/** PostGamesGameIdBanners500ErrorResponse */
export interface PostGamesGameIdBanners500ErrorResponse {
  data: null
  errors: PostGamesGameIdBanners500Error[]
}

/** PostGamesGameIdControlGroups400Error */
export interface PostGamesGameIdControlGroups400Error {
  code:
    | 'game_access_denied'
    | 'game_controls_group_name_is_empty'
    | 'game_controls_group_name_too_long'
    | 'invalid_request_payload'
}

/** PostGamesGameIdControlGroups400ErrorResponse */
export interface PostGamesGameIdControlGroups400ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroups400Error[]
}

/** PostGamesGameIdControlGroups403Error */
export interface PostGamesGameIdControlGroups403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdControlGroups403ErrorResponse */
export interface PostGamesGameIdControlGroups403ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroups403Error[]
}

/** PostGamesGameIdControlGroups404Error */
export interface PostGamesGameIdControlGroups404Error {
  code: 'game_not_found'
}

/** PostGamesGameIdControlGroups404ErrorResponse */
export interface PostGamesGameIdControlGroups404ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroups404Error[]
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400Error */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400Error {
  code:
    | 'game_access_denied'
    | 'game_controls_group_name_is_empty'
    | 'game_controls_group_name_too_long'
    | 'invalid_request_payload'
    | 'parent_game_controls_group_is_invalid'
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400ErrorResponse */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400Error[]
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403Error */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403ErrorResponse */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403Error[]
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404Error */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404ErrorResponse */
export interface PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404ErrorResponse {
  data: null
  errors: PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404Error[]
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls400Error */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls400Error {
  code:
    | 'game_access_denied'
    | 'game_control_action_too_long'
    | 'game_control_input_too_long'
    | 'invalid_request_payload'
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls400ErrorResponse */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls400ErrorResponse {
  data: null
  errors: PostGamesGameIdControlSubgroupsControlSubgroupIdControls400Error[]
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls403Error */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls403ErrorResponse */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls403ErrorResponse {
  data: null
  errors: PostGamesGameIdControlSubgroupsControlSubgroupIdControls403Error[]
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls404Error */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls404Error {
  code: 'game_controls_group_not_found' | 'game_not_found'
}

/** PostGamesGameIdControlSubgroupsControlSubgroupIdControls404ErrorResponse */
export interface PostGamesGameIdControlSubgroupsControlSubgroupIdControls404ErrorResponse {
  data: null
  errors: PostGamesGameIdControlSubgroupsControlSubgroupIdControls404Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriods400Error */
export interface PostGamesGameIdItemsItemIdClaimPeriods400Error {
  code:
    | 'claim_game_not_allowed'
    | 'game_item_claim_period_date_range_invalid'
    | 'invalid_request_payload'
    | 'overlapping_game_item_claim_periods'
}

/** PostGamesGameIdItemsItemIdClaimPeriods400ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriods400ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriods400Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriods403Error */
export interface PostGamesGameIdItemsItemIdClaimPeriods403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdItemsItemIdClaimPeriods403ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriods403ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriods403Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriods404Error */
export interface PostGamesGameIdItemsItemIdClaimPeriods404Error {
  code: 'game_item_not_found' | 'game_not_found'
}

/** PostGamesGameIdItemsItemIdClaimPeriods404ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriods404ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriods404Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400Error */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400Error {
  code: 'game_item_claim_period_not_active'
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403Error */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403Error {
  code: 'sign_in_required'
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403Error[]
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404Error */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404Error {
  code: 'game_item_claim_period_not_found' | 'game_item_not_found' | 'game_not_found'
}

/** PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404ErrorResponse */
export interface PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404ErrorResponse {
  data: null
  errors: PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404Error[]
}

/** PostGamesReprocess403Error */
export interface PostGamesReprocess403Error {
  code: 'sign_in_required'
}

/** PostGamesReprocess403ErrorResponse */
export interface PostGamesReprocess403ErrorResponse {
  data: null
  errors: PostGamesReprocess403Error[]
}

/** PostGamesReprocessCurrentReleases403Error */
export interface PostGamesReprocessCurrentReleases403Error {
  code: 'sign_in_required'
}

/** PostGamesReprocessCurrentReleases403ErrorResponse */
export interface PostGamesReprocessCurrentReleases403ErrorResponse {
  data: null
  errors: PostGamesReprocessCurrentReleases403Error[]
}

/** PostMigrationGamesAddReleasableAccessEntities400Error */
export interface PostMigrationGamesAddReleasableAccessEntities400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostMigrationGamesAddReleasableAccessEntities400ErrorResponse */
export interface PostMigrationGamesAddReleasableAccessEntities400ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableAccessEntities400Error[]
}

/** PostMigrationGamesAddReleasableAccessEntities403Error */
export interface PostMigrationGamesAddReleasableAccessEntities403Error {
  code: 'sign_in_required'
}

/** PostMigrationGamesAddReleasableAccessEntities403ErrorResponse */
export interface PostMigrationGamesAddReleasableAccessEntities403ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableAccessEntities403Error[]
}

/** PostMigrationGamesAddReleasableAssetEntities400Error */
export interface PostMigrationGamesAddReleasableAssetEntities400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostMigrationGamesAddReleasableAssetEntities400ErrorResponse */
export interface PostMigrationGamesAddReleasableAssetEntities400ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableAssetEntities400Error[]
}

/** PostMigrationGamesAddReleasableAssetEntities403Error */
export interface PostMigrationGamesAddReleasableAssetEntities403Error {
  code: 'sign_in_required'
}

/** PostMigrationGamesAddReleasableAssetEntities403ErrorResponse */
export interface PostMigrationGamesAddReleasableAssetEntities403ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableAssetEntities403Error[]
}

/** PostMigrationGamesAddReleasableCoverEntities400Error */
export interface PostMigrationGamesAddReleasableCoverEntities400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostMigrationGamesAddReleasableCoverEntities400ErrorResponse */
export interface PostMigrationGamesAddReleasableCoverEntities400ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableCoverEntities400Error[]
}

/** PostMigrationGamesAddReleasableCoverEntities403Error */
export interface PostMigrationGamesAddReleasableCoverEntities403Error {
  code: 'sign_in_required'
}

/** PostMigrationGamesAddReleasableCoverEntities403ErrorResponse */
export interface PostMigrationGamesAddReleasableCoverEntities403ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableCoverEntities403Error[]
}

/** PostMigrationGamesAddReleasableEntities400Error */
export interface PostMigrationGamesAddReleasableEntities400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostMigrationGamesAddReleasableEntities400ErrorResponse */
export interface PostMigrationGamesAddReleasableEntities400ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableEntities400Error[]
}

/** PostMigrationGamesAddReleasableEntities403Error */
export interface PostMigrationGamesAddReleasableEntities403Error {
  code: 'sign_in_required'
}

/** PostMigrationGamesAddReleasableEntities403ErrorResponse */
export interface PostMigrationGamesAddReleasableEntities403ErrorResponse {
  data: null
  errors: PostMigrationGamesAddReleasableEntities403Error[]
}

/** PostMigrationModsFlavorsRecordSizes400Error */
export interface PostMigrationModsFlavorsRecordSizes400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostMigrationModsFlavorsRecordSizes400ErrorResponse */
export interface PostMigrationModsFlavorsRecordSizes400ErrorResponse {
  data: null
  errors: PostMigrationModsFlavorsRecordSizes400Error[]
}

/** PostMigrationModsFlavorsRecordSizes403Error */
export interface PostMigrationModsFlavorsRecordSizes403Error {
  code: 'sign_in_required'
}

/** PostMigrationModsFlavorsRecordSizes403ErrorResponse */
export interface PostMigrationModsFlavorsRecordSizes403ErrorResponse {
  data: null
  errors: PostMigrationModsFlavorsRecordSizes403Error[]
}

export type PostModReviewV2Data = ResponseReviewResponse

export type PostModReviewV2Error =
  | PostV2ModsModIdReviews400ErrorResponse
  | PostV2ModsModIdReviews403ErrorResponse
  | PostV2ModsModIdReviews404ErrorResponse

/** PostModsApplyDefaultStorageTagsToPublishingFiles400Error */
export interface PostModsApplyDefaultStorageTagsToPublishingFiles400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostModsApplyDefaultStorageTagsToPublishingFiles400ErrorResponse */
export interface PostModsApplyDefaultStorageTagsToPublishingFiles400ErrorResponse {
  data: null
  errors: PostModsApplyDefaultStorageTagsToPublishingFiles400Error[]
}

/** PostModsApplyDefaultStorageTagsToPublishingFiles403Error */
export interface PostModsApplyDefaultStorageTagsToPublishingFiles403Error {
  code: 'sign_in_required'
}

/** PostModsApplyDefaultStorageTagsToPublishingFiles403ErrorResponse */
export interface PostModsApplyDefaultStorageTagsToPublishingFiles403ErrorResponse {
  data: null
  errors: PostModsApplyDefaultStorageTagsToPublishingFiles403Error[]
}

/** PostModsApplyDefaultStorageTagsToReleaseFiles400Error */
export interface PostModsApplyDefaultStorageTagsToReleaseFiles400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostModsApplyDefaultStorageTagsToReleaseFiles400ErrorResponse */
export interface PostModsApplyDefaultStorageTagsToReleaseFiles400ErrorResponse {
  data: null
  errors: PostModsApplyDefaultStorageTagsToReleaseFiles400Error[]
}

/** PostModsApplyDefaultStorageTagsToReleaseFiles403Error */
export interface PostModsApplyDefaultStorageTagsToReleaseFiles403Error {
  code: 'sign_in_required'
}

/** PostModsApplyDefaultStorageTagsToReleaseFiles403ErrorResponse */
export interface PostModsApplyDefaultStorageTagsToReleaseFiles403ErrorResponse {
  data: null
  errors: PostModsApplyDefaultStorageTagsToReleaseFiles403Error[]
}

/** PostModsDetectManifestTraits400Error */
export interface PostModsDetectManifestTraits400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostModsDetectManifestTraits400ErrorResponse */
export interface PostModsDetectManifestTraits400ErrorResponse {
  data: null
  errors: PostModsDetectManifestTraits400Error[]
}

/** PostModsDetectManifestTraits403Error */
export interface PostModsDetectManifestTraits403Error {
  code: 'sign_in_required'
}

/** PostModsDetectManifestTraits403ErrorResponse */
export interface PostModsDetectManifestTraits403ErrorResponse {
  data: null
  errors: PostModsDetectManifestTraits403Error[]
}

/** PostModsFlavorsDeleteFlavorForAllReleases400Error */
export interface PostModsFlavorsDeleteFlavorForAllReleases400Error {
  code:
    | 'mod_flavors_not_disabled'
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostModsFlavorsDeleteFlavorForAllReleases400ErrorResponse */
export interface PostModsFlavorsDeleteFlavorForAllReleases400ErrorResponse {
  data: null
  errors: PostModsFlavorsDeleteFlavorForAllReleases400Error[]
}

/** PostModsFlavorsDeleteFlavorForAllReleases403Error */
export interface PostModsFlavorsDeleteFlavorForAllReleases403Error {
  code: 'sign_in_required'
}

/** PostModsFlavorsDeleteFlavorForAllReleases403ErrorResponse */
export interface PostModsFlavorsDeleteFlavorForAllReleases403ErrorResponse {
  data: null
  errors: PostModsFlavorsDeleteFlavorForAllReleases403Error[]
}

/** PostModsInvalidateSupplementalDataCache403Error */
export interface PostModsInvalidateSupplementalDataCache403Error {
  code: 'sign_in_required'
}

/** PostModsInvalidateSupplementalDataCache403ErrorResponse */
export interface PostModsInvalidateSupplementalDataCache403ErrorResponse {
  data: null
  errors: PostModsInvalidateSupplementalDataCache403Error[]
}

/** PostModsInvalidateSupplementalDataCache404Error */
export interface PostModsInvalidateSupplementalDataCache404Error {
  code: 'mod_not_found'
}

/** PostModsInvalidateSupplementalDataCache404ErrorResponse */
export interface PostModsInvalidateSupplementalDataCache404ErrorResponse {
  data: null
  errors: PostModsInvalidateSupplementalDataCache404Error[]
}

/** PostModsModIdTracksTrackIdCreateRelease400Error */
export interface PostModsModIdTracksTrackIdCreateRelease400Error {
  code: 'no_release_for_track' | 'pending_release_already_exists'
}

/** PostModsModIdTracksTrackIdCreateRelease400ErrorResponse */
export interface PostModsModIdTracksTrackIdCreateRelease400ErrorResponse {
  data: null
  errors: PostModsModIdTracksTrackIdCreateRelease400Error[]
}

/** PostModsModIdTracksTrackIdCreateRelease403Error */
export interface PostModsModIdTracksTrackIdCreateRelease403Error {
  code: 'sign_in_required'
}

/** PostModsModIdTracksTrackIdCreateRelease403ErrorResponse */
export interface PostModsModIdTracksTrackIdCreateRelease403ErrorResponse {
  data: null
  errors: PostModsModIdTracksTrackIdCreateRelease403Error[]
}

/** PostModsModIdTracksTrackIdCreateRelease404Error */
export interface PostModsModIdTracksTrackIdCreateRelease404Error {
  code: 'mod_not_found' | 'track_not_found'
}

/** PostModsModIdTracksTrackIdCreateRelease404ErrorResponse */
export interface PostModsModIdTracksTrackIdCreateRelease404ErrorResponse {
  data: null
  errors: PostModsModIdTracksTrackIdCreateRelease404Error[]
}

/** PostModsModIdTracksTrackIdCreateRelease500Error */
export interface PostModsModIdTracksTrackIdCreateRelease500Error {
  code: 'internal_server_error'
}

/** PostModsModIdTracksTrackIdCreateRelease500ErrorResponse */
export interface PostModsModIdTracksTrackIdCreateRelease500ErrorResponse {
  data: null
  errors: PostModsModIdTracksTrackIdCreateRelease500Error[]
}

/** PostModsRecordReleaseContents400Error */
export interface PostModsRecordReleaseContents400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostModsRecordReleaseContents400ErrorResponse */
export interface PostModsRecordReleaseContents400ErrorResponse {
  data: null
  errors: PostModsRecordReleaseContents400Error[]
}

/** PostModsRecordReleaseContents403Error */
export interface PostModsRecordReleaseContents403Error {
  code: 'sign_in_required'
}

/** PostModsRecordReleaseContents403ErrorResponse */
export interface PostModsRecordReleaseContents403ErrorResponse {
  data: null
  errors: PostModsRecordReleaseContents403Error[]
}

/** PostModsReprocess403Error */
export interface PostModsReprocess403Error {
  code: 'sign_in_required'
}

/** PostModsReprocess403ErrorResponse */
export interface PostModsReprocess403ErrorResponse {
  data: null
  errors: PostModsReprocess403Error[]
}

/** PostModsReprocessCurrentReleases403Error */
export interface PostModsReprocessCurrentReleases403Error {
  code: 'sign_in_required'
}

/** PostModsReprocessCurrentReleases403ErrorResponse */
export interface PostModsReprocessCurrentReleases403ErrorResponse {
  data: null
  errors: PostModsReprocessCurrentReleases403Error[]
}

/** PostModsUpdateAudioModContent403Error */
export interface PostModsUpdateAudioModContent403Error {
  code: 'sign_in_required'
}

/** PostModsUpdateAudioModContent403ErrorResponse */
export interface PostModsUpdateAudioModContent403ErrorResponse {
  data: null
  errors: PostModsUpdateAudioModContent403Error[]
}

/** PostOdks400Error */
export interface PostOdks400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_tag_alias'
    | 'string_value_empty'
    | 'string_value_not_unique'
    | 'tag_alias_not_unique'
}

/** PostOdks400ErrorResponse */
export interface PostOdks400ErrorResponse {
  data: null
  errors: PostOdks400Error[]
}

/** PostOdks403Error */
export interface PostOdks403Error {
  code: 'sign_in_required'
}

/** PostOdks403ErrorResponse */
export interface PostOdks403ErrorResponse {
  data: null
  errors: PostOdks403Error[]
}

/** PostOfferPartners400Error */
export interface PostOfferPartners400Error {
  code:
    | 'invalid_request_payload'
    | 'offer_partner_alias_not_unique'
    | 'offer_partner_alias_not_valid'
    | 'offer_partner_alias_too_long'
}

/** PostOfferPartners400ErrorResponse */
export interface PostOfferPartners400ErrorResponse {
  data: null
  errors: PostOfferPartners400Error[]
}

/** PostOfferPartners403Error */
export interface PostOfferPartners403Error {
  code: 'sign_in_required'
}

/** PostOfferPartners403ErrorResponse */
export interface PostOfferPartners403ErrorResponse {
  data: null
  errors: PostOfferPartners403Error[]
}

/** PostOffers400Error */
export interface PostOffers400Error {
  code:
    | 'invalid_request_payload'
    | 'offer_alias_not_unique'
    | 'offer_alias_not_valid'
    | 'offer_alias_too_long'
}

/** PostOffers400ErrorResponse */
export interface PostOffers400ErrorResponse {
  data: null
  errors: PostOffers400Error[]
}

/** PostOffers403Error */
export interface PostOffers403Error {
  code: 'sign_in_required'
}

/** PostOffers403ErrorResponse */
export interface PostOffers403ErrorResponse {
  data: null
  errors: PostOffers403Error[]
}

/** PostOffers404Error */
export interface PostOffers404Error {
  code: 'partner_not_found'
}

/** PostOffers404ErrorResponse */
export interface PostOffers404ErrorResponse {
  data: null
  errors: PostOffers404Error[]
}

/** PostOffersOfferIdCodesCodeIdClaim400Error */
export interface PostOffersOfferIdCodesCodeIdClaim400Error {
  code:
    | 'invalid_offer_claimer_user_id'
    | 'invalid_offer_client_alias'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'offer_code_is_archived'
    | 'offer_code_is_claimed'
    | 'offer_code_is_not_active'
    | 'offer_is_archived'
    | 'offer_is_internal'
}

/** PostOffersOfferIdCodesCodeIdClaim400ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdClaim400ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdClaim400Error[]
}

/** PostOffersOfferIdCodesCodeIdClaim403Error */
export interface PostOffersOfferIdCodesCodeIdClaim403Error {
  code: 'sign_in_required'
}

/** PostOffersOfferIdCodesCodeIdClaim403ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdClaim403ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdClaim403Error[]
}

/** PostOffersOfferIdCodesCodeIdClaim404Error */
export interface PostOffersOfferIdCodesCodeIdClaim404Error {
  code: 'offer_code_not_found' | 'offer_not_found'
}

/** PostOffersOfferIdCodesCodeIdClaim404ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdClaim404ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdClaim404Error[]
}

/** PostOffersOfferIdCodesCodeIdClaim500Error */
export interface PostOffersOfferIdCodesCodeIdClaim500Error {
  code: 'offer_default_error_page_not_configured'
}

/** PostOffersOfferIdCodesCodeIdClaim500ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdClaim500ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdClaim500Error[]
}

/** PostOffersOfferIdCodesCodeIdUnclaim403Error */
export interface PostOffersOfferIdCodesCodeIdUnclaim403Error {
  code: 'sign_in_required'
}

/** PostOffersOfferIdCodesCodeIdUnclaim403ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdUnclaim403ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdUnclaim403Error[]
}

/** PostOffersOfferIdCodesCodeIdUnclaim404Error */
export interface PostOffersOfferIdCodesCodeIdUnclaim404Error {
  code: 'offer_code_not_found' | 'offer_not_found'
}

/** PostOffersOfferIdCodesCodeIdUnclaim404ErrorResponse */
export interface PostOffersOfferIdCodesCodeIdUnclaim404ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesCodeIdUnclaim404Error[]
}

/** PostOffersOfferIdCodesRedeemReport400Error */
export interface PostOffersOfferIdCodesRedeemReport400Error {
  code: 'code_redemption_import_failed' | 'offer_is_archived'
}

/** PostOffersOfferIdCodesRedeemReport400ErrorResponse */
export interface PostOffersOfferIdCodesRedeemReport400ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesRedeemReport400Error[]
}

/** PostOffersOfferIdCodesRedeemReport403Error */
export interface PostOffersOfferIdCodesRedeemReport403Error {
  code: 'sign_in_required'
}

/** PostOffersOfferIdCodesRedeemReport403ErrorResponse */
export interface PostOffersOfferIdCodesRedeemReport403ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesRedeemReport403Error[]
}

/** PostOffersOfferIdCodesRedeemReport404Error */
export interface PostOffersOfferIdCodesRedeemReport404Error {
  code: 'offer_not_found'
}

/** PostOffersOfferIdCodesRedeemReport404ErrorResponse */
export interface PostOffersOfferIdCodesRedeemReport404ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesRedeemReport404Error[]
}

/** PostOffersOfferIdCodesRedeemReport500Error */
export interface PostOffersOfferIdCodesRedeemReport500Error {
  code: 'internal_server_error'
}

/** PostOffersOfferIdCodesRedeemReport500ErrorResponse */
export interface PostOffersOfferIdCodesRedeemReport500ErrorResponse {
  data: null
  errors: PostOffersOfferIdCodesRedeemReport500Error[]
}

/** PostOffersOfferIdErrorPages400Error */
export interface PostOffersOfferIdErrorPages400Error {
  code:
    | 'invalid_request_payload'
    | 'offer_error_page_client_alias_blank'
    | 'offer_error_page_client_alias_not_unique'
    | 'offer_error_page_client_alias_not_valid'
    | 'offer_error_page_client_alias_too_long'
}

/** PostOffersOfferIdErrorPages400ErrorResponse */
export interface PostOffersOfferIdErrorPages400ErrorResponse {
  data: null
  errors: PostOffersOfferIdErrorPages400Error[]
}

/** PostOffersOfferIdErrorPages403Error */
export interface PostOffersOfferIdErrorPages403Error {
  code: 'sign_in_required'
}

/** PostOffersOfferIdErrorPages403ErrorResponse */
export interface PostOffersOfferIdErrorPages403ErrorResponse {
  data: null
  errors: PostOffersOfferIdErrorPages403Error[]
}

/** PostOffersOfferIdErrorPages404Error */
export interface PostOffersOfferIdErrorPages404Error {
  code: 'offer_not_found'
}

/** PostOffersOfferIdErrorPages404ErrorResponse */
export interface PostOffersOfferIdErrorPages404ErrorResponse {
  data: null
  errors: PostOffersOfferIdErrorPages404Error[]
}

/** PostOffersOfferIdImportCodes400Error */
export interface PostOffersOfferIdImportCodes400Error {
  code: 'offer_code_import_failed'
}

/** PostOffersOfferIdImportCodes400ErrorResponse */
export interface PostOffersOfferIdImportCodes400ErrorResponse {
  data: null
  errors: PostOffersOfferIdImportCodes400Error[]
}

/** PostOffersOfferIdImportCodes403Error */
export interface PostOffersOfferIdImportCodes403Error {
  code: 'sign_in_required'
}

/** PostOffersOfferIdImportCodes403ErrorResponse */
export interface PostOffersOfferIdImportCodes403ErrorResponse {
  data: null
  errors: PostOffersOfferIdImportCodes403Error[]
}

/** PostOffersOfferIdImportCodes404Error */
export interface PostOffersOfferIdImportCodes404Error {
  code: 'offer_not_found'
}

/** PostOffersOfferIdImportCodes404ErrorResponse */
export interface PostOffersOfferIdImportCodes404ErrorResponse {
  data: null
  errors: PostOffersOfferIdImportCodes404Error[]
}

/** PostOffersOfferIdImportCodes500Error */
export interface PostOffersOfferIdImportCodes500Error {
  code: 'internal_server_error'
}

/** PostOffersOfferIdImportCodes500ErrorResponse */
export interface PostOffersOfferIdImportCodes500ErrorResponse {
  data: null
  errors: PostOffersOfferIdImportCodes500Error[]
}

/** PostPartners400Error */
export interface PostPartners400Error {
  code:
    | 'invalid_request_payload'
    | 'partner_alias_not_unique'
    | 'partner_alias_not_valid'
    | 'partner_alias_too_long'
}

/** PostPartners400ErrorResponse */
export interface PostPartners400ErrorResponse {
  data: null
  errors: PostPartners400Error[]
}

/** PostPartners403Error */
export interface PostPartners403Error {
  code: 'sign_in_required'
}

/** PostPartners403ErrorResponse */
export interface PostPartners403ErrorResponse {
  data: null
  errors: PostPartners403Error[]
}

/** PostPaymentsAddNewFieldValues400Error */
export interface PostPaymentsAddNewFieldValues400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostPaymentsAddNewFieldValues400ErrorResponse */
export interface PostPaymentsAddNewFieldValues400ErrorResponse {
  data: null
  errors: PostPaymentsAddNewFieldValues400Error[]
}

/** PostPaymentsAddNewFieldValues403Error */
export interface PostPaymentsAddNewFieldValues403Error {
  code: 'sign_in_required'
}

/** PostPaymentsAddNewFieldValues403ErrorResponse */
export interface PostPaymentsAddNewFieldValues403ErrorResponse {
  data: null
  errors: PostPaymentsAddNewFieldValues403Error[]
}

/** PostPaymentsPaymentIdDisputes400Error */
export interface PostPaymentsPaymentIdDisputes400Error {
  code: 'dispute_description_too_long' | 'invalid_request_payload'
}

/** PostPaymentsPaymentIdDisputes400ErrorResponse */
export interface PostPaymentsPaymentIdDisputes400ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputes400Error[]
}

/** PostPaymentsPaymentIdDisputes403Error */
export interface PostPaymentsPaymentIdDisputes403Error {
  code: 'sign_in_required'
}

/** PostPaymentsPaymentIdDisputes403ErrorResponse */
export interface PostPaymentsPaymentIdDisputes403ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputes403Error[]
}

/** PostPaymentsPaymentIdDisputes404Error */
export interface PostPaymentsPaymentIdDisputes404Error {
  code: 'payment_not_found'
}

/** PostPaymentsPaymentIdDisputes404ErrorResponse */
export interface PostPaymentsPaymentIdDisputes404ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputes404Error[]
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment400Error */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment400Error {
  code: 'dispute_comment_too_long' | 'invalid_request_payload'
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment400ErrorResponse */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment400ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputesDisputeIdComment400Error[]
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment403Error */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment403Error {
  code: 'sign_in_required'
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment403ErrorResponse */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment403ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputesDisputeIdComment403Error[]
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment404Error */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment404Error {
  code: 'dispute_not_found' | 'payment_not_found'
}

/** PostPaymentsPaymentIdDisputesDisputeIdComment404ErrorResponse */
export interface PostPaymentsPaymentIdDisputesDisputeIdComment404ErrorResponse {
  data: null
  errors: PostPaymentsPaymentIdDisputesDisputeIdComment404Error[]
}

/** PostPromotedChallenges400Error */
export interface PostPromotedChallenges400Error {
  code: 'challenge_not_found' | 'invalid_request_payload'
}

/** PostPromotedChallenges400ErrorResponse */
export interface PostPromotedChallenges400ErrorResponse {
  data: null
  errors: PostPromotedChallenges400Error[]
}

/** PostPromotedChallenges403Error */
export interface PostPromotedChallenges403Error {
  code: 'sign_in_required'
}

/** PostPromotedChallenges403ErrorResponse */
export interface PostPromotedChallenges403ErrorResponse {
  data: null
  errors: PostPromotedChallenges403Error[]
}

/** PostPublishers400Error */
export interface PostPublishers400Error {
  code:
    | 'invalid_request_payload'
    | 'publisher_title_taken'
    | 'publisher_title_too_long'
    | 'publisher_url_invalid'
}

/** PostPublishers400ErrorResponse */
export interface PostPublishers400ErrorResponse {
  data: null
  errors: PostPublishers400Error[]
}

/** PostPublishers403Error */
export interface PostPublishers403Error {
  code: 'sign_in_required'
}

/** PostPublishers403ErrorResponse */
export interface PostPublishers403ErrorResponse {
  data: null
  errors: PostPublishers403Error[]
}

/** PostPurchasesAddNewFieldValues400Error */
export interface PostPurchasesAddNewFieldValues400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostPurchasesAddNewFieldValues400ErrorResponse */
export interface PostPurchasesAddNewFieldValues400ErrorResponse {
  data: null
  errors: PostPurchasesAddNewFieldValues400Error[]
}

/** PostPurchasesAddNewFieldValues403Error */
export interface PostPurchasesAddNewFieldValues403Error {
  code: 'sign_in_required'
}

/** PostPurchasesAddNewFieldValues403ErrorResponse */
export interface PostPurchasesAddNewFieldValues403ErrorResponse {
  data: null
  errors: PostPurchasesAddNewFieldValues403Error[]
}

/** PostPurchasesSetTippingPurchaseType400Error */
export interface PostPurchasesSetTippingPurchaseType400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostPurchasesSetTippingPurchaseType400ErrorResponse */
export interface PostPurchasesSetTippingPurchaseType400ErrorResponse {
  data: null
  errors: PostPurchasesSetTippingPurchaseType400Error[]
}

/** PostPurchasesSetTippingPurchaseType403Error */
export interface PostPurchasesSetTippingPurchaseType403Error {
  code: 'sign_in_required'
}

/** PostPurchasesSetTippingPurchaseType403ErrorResponse */
export interface PostPurchasesSetTippingPurchaseType403ErrorResponse {
  data: null
  errors: PostPurchasesSetTippingPurchaseType403Error[]
}

/** PostQuestCollections400Error */
export interface PostQuestCollections400Error {
  code:
    | 'invalid_request_payload'
    | 'quest_collection_alias_not_unique'
    | 'quest_collection_alias_not_valid'
    | 'quest_collection_alias_too_long'
}

/** PostQuestCollections400ErrorResponse */
export interface PostQuestCollections400ErrorResponse {
  data: null
  errors: PostQuestCollections400Error[]
}

/** PostQuestCollections403Error */
export interface PostQuestCollections403Error {
  code: 'sign_in_required'
}

/** PostQuestCollections403ErrorResponse */
export interface PostQuestCollections403ErrorResponse {
  data: null
  errors: PostQuestCollections403Error[]
}

/** PostQuestCollectionsCollectionIdRotate400Error */
export interface PostQuestCollectionsCollectionIdRotate400Error {
  code: 'not_supported'
}

/** PostQuestCollectionsCollectionIdRotate400ErrorResponse */
export interface PostQuestCollectionsCollectionIdRotate400ErrorResponse {
  data: null
  errors: PostQuestCollectionsCollectionIdRotate400Error[]
}

/** PostQuestCollectionsCollectionIdRotate403Error */
export interface PostQuestCollectionsCollectionIdRotate403Error {
  code: 'sign_in_required'
}

/** PostQuestCollectionsCollectionIdRotate403ErrorResponse */
export interface PostQuestCollectionsCollectionIdRotate403ErrorResponse {
  data: null
  errors: PostQuestCollectionsCollectionIdRotate403Error[]
}

/** PostQuestCollectionsCollectionIdRotate404Error */
export interface PostQuestCollectionsCollectionIdRotate404Error {
  code: 'quest_collection_not_found'
}

/** PostQuestCollectionsCollectionIdRotate404ErrorResponse */
export interface PostQuestCollectionsCollectionIdRotate404ErrorResponse {
  data: null
  errors: PostQuestCollectionsCollectionIdRotate404Error[]
}

/** PostQuests400Error */
export interface PostQuests400Error {
  code:
    | 'bank_transaction_note_too_long'
    | 'invalid_bank_transaction_amount'
    | 'invalid_quest_type_parameters'
    | 'invalid_request_payload'
    | 'quest_alias_not_unique'
    | 'quest_alias_not_valid'
    | 'quest_alias_too_long'
    | 'quest_call_to_action_url_invalid'
    | 'quest_type_not_supported'
    | 'string_value_empty'
}

/** PostQuests400ErrorResponse */
export interface PostQuests400ErrorResponse {
  data: null
  errors: PostQuests400Error[]
}

/** PostQuests403Error */
export interface PostQuests403Error {
  code: 'sign_in_required'
}

/** PostQuests403ErrorResponse */
export interface PostQuests403ErrorResponse {
  data: null
  errors: PostQuests403Error[]
}

/** PostQuests404Error */
export interface PostQuests404Error {
  code: 'quest_collection_not_found'
}

/** PostQuests404ErrorResponse */
export interface PostQuests404ErrorResponse {
  data: null
  errors: PostQuests404Error[]
}

/** PostQuestsQuestIdReset403Error */
export interface PostQuestsQuestIdReset403Error {
  code: 'sign_in_required'
}

/** PostQuestsQuestIdReset403ErrorResponse */
export interface PostQuestsQuestIdReset403ErrorResponse {
  data: null
  errors: PostQuestsQuestIdReset403Error[]
}

/** PostQuestsQuestIdReset404Error */
export interface PostQuestsQuestIdReset404Error {
  code: 'quest_not_found'
}

/** PostQuestsQuestIdReset404ErrorResponse */
export interface PostQuestsQuestIdReset404ErrorResponse {
  data: null
  errors: PostQuestsQuestIdReset404Error[]
}

/** PostRefundsAddNewFieldValues400Error */
export interface PostRefundsAddNewFieldValues400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostRefundsAddNewFieldValues400ErrorResponse */
export interface PostRefundsAddNewFieldValues400ErrorResponse {
  data: null
  errors: PostRefundsAddNewFieldValues400Error[]
}

/** PostRefundsAddNewFieldValues403Error */
export interface PostRefundsAddNewFieldValues403Error {
  code: 'sign_in_required'
}

/** PostRefundsAddNewFieldValues403ErrorResponse */
export interface PostRefundsAddNewFieldValues403ErrorResponse {
  data: null
  errors: PostRefundsAddNewFieldValues403Error[]
}

/** PostScheduledTasksReschedule400Error */
export interface PostScheduledTasksReschedule400Error {
  code: 'scheduled task locked'
}

/** PostScheduledTasksReschedule400ErrorResponse */
export interface PostScheduledTasksReschedule400ErrorResponse {
  data: null
  errors: PostScheduledTasksReschedule400Error[]
}

/** PostScheduledTasksReschedule403Error */
export interface PostScheduledTasksReschedule403Error {
  code: 'sign_in_required'
}

/** PostScheduledTasksReschedule403ErrorResponse */
export interface PostScheduledTasksReschedule403ErrorResponse {
  data: null
  errors: PostScheduledTasksReschedule403Error[]
}

/** PostScheduledTasksReschedule404Error */
export interface PostScheduledTasksReschedule404Error {
  code: 'scheduled_task_not_found'
}

/** PostScheduledTasksReschedule404ErrorResponse */
export interface PostScheduledTasksReschedule404ErrorResponse {
  data: null
  errors: PostScheduledTasksReschedule404Error[]
}

/** PostSeasonsSeasonIdPointsTransactions400Error */
export interface PostSeasonsSeasonIdPointsTransactions400Error {
  code:
    | 'group_not_found'
    | 'invalid_request_payload'
    | 'invalid_season_points_adjustment'
    | 'season_points_adjustment_zero'
    | 'user_not_found'
}

/** PostSeasonsSeasonIdPointsTransactions400ErrorResponse */
export interface PostSeasonsSeasonIdPointsTransactions400ErrorResponse {
  data: null
  errors: PostSeasonsSeasonIdPointsTransactions400Error[]
}

/** PostSeasonsSeasonIdPointsTransactions403Error */
export interface PostSeasonsSeasonIdPointsTransactions403Error {
  code: 'sign_in_required'
}

/** PostSeasonsSeasonIdPointsTransactions403ErrorResponse */
export interface PostSeasonsSeasonIdPointsTransactions403ErrorResponse {
  data: null
  errors: PostSeasonsSeasonIdPointsTransactions403Error[]
}

/** PostSeasonsSeasonIdPointsTransactions404Error */
export interface PostSeasonsSeasonIdPointsTransactions404Error {
  code: 'promoted_challenge_not_found' | 'season_not_found'
}

/** PostSeasonsSeasonIdPointsTransactions404ErrorResponse */
export interface PostSeasonsSeasonIdPointsTransactions404ErrorResponse {
  data: null
  errors: PostSeasonsSeasonIdPointsTransactions404Error[]
}

/** PostSessionLogout403Error */
export interface PostSessionLogout403Error {
  code: 'sign_in_required'
}

/** PostSessionLogout403ErrorResponse */
export interface PostSessionLogout403ErrorResponse {
  data: null
  errors: PostSessionLogout403Error[]
}

/** PostSettingsTransactions400Error */
export interface PostSettingsTransactions400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_setting'
    | 'invalid_setting_id'
    | 'invalid_setting_override'
    | 'invalid_setting_value'
    | 'no_changes'
    | 'setting_not_overridden'
}

/** PostSettingsTransactions400ErrorResponse */
export interface PostSettingsTransactions400ErrorResponse {
  data: null
  errors: PostSettingsTransactions400Error[]
}

/** PostSettingsTransactions403Error */
export interface PostSettingsTransactions403Error {
  code: 'sign_in_required'
}

/** PostSettingsTransactions403ErrorResponse */
export interface PostSettingsTransactions403ErrorResponse {
  data: null
  errors: PostSettingsTransactions403Error[]
}

/** PostSettingsTransactions404Error */
export interface PostSettingsTransactions404Error {
  code: 'invalid_entity_id' | 'setting_not_found'
}

/** PostSettingsTransactions404ErrorResponse */
export interface PostSettingsTransactions404ErrorResponse {
  data: null
  errors: PostSettingsTransactions404Error[]
}

/** PostStatsApiRequestEvents403Error */
export interface PostStatsApiRequestEvents403Error {
  code: 'sign_in_required'
}

/** PostStatsApiRequestEvents403ErrorResponse */
export interface PostStatsApiRequestEvents403ErrorResponse {
  data: null
  errors: PostStatsApiRequestEvents403Error[]
}

/** PostStatsApiSendRequestedEvents400Error */
export interface PostStatsApiSendRequestedEvents400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
    | 'stats_event_sending_process_already_running'
}

/** PostStatsApiSendRequestedEvents400ErrorResponse */
export interface PostStatsApiSendRequestedEvents400ErrorResponse {
  data: null
  errors: PostStatsApiSendRequestedEvents400Error[]
}

/** PostStatsApiSendRequestedEvents403Error */
export interface PostStatsApiSendRequestedEvents403Error {
  code: 'sign_in_required'
}

/** PostStatsApiSendRequestedEvents403ErrorResponse */
export interface PostStatsApiSendRequestedEvents403ErrorResponse {
  data: null
  errors: PostStatsApiSendRequestedEvents403Error[]
}

/** PostStickers400Error */
export interface PostStickers400Error {
  code:
    | 'invalid_display_order'
    | 'invalid_request_payload'
    | 'sticker_alias_not_unique'
    | 'sticker_alias_not_valid'
    | 'sticker_alias_too_long'
    | 'sticker_amount_invalid'
    | 'sticker_amount_less_than_one'
    | 'sticker_note_too_long'
    | 'sticker_title_empty'
    | 'sticker_title_not_unique'
    | 'sticker_title_too_long'
    | 'too_many_fiat_currency_stickers'
}

/** PostStickers400ErrorResponse */
export interface PostStickers400ErrorResponse {
  data: null
  errors: PostStickers400Error[]
}

/** PostStickers403Error */
export interface PostStickers403Error {
  code: 'sign_in_required'
}

/** PostStickers403ErrorResponse */
export interface PostStickers403ErrorResponse {
  data: null
  errors: PostStickers403Error[]
}

/** PostStudios400Error */
export interface PostStudios400Error {
  code:
    | 'invalid_characters_in_name'
    | 'invalid_request_payload'
    | 'max_studios_limit_reached'
    | 'studio_name_taken'
    | 'studio_name_too_long'
}

/** PostStudios400ErrorResponse */
export interface PostStudios400ErrorResponse {
  data: null
  errors: PostStudios400Error[]
}

/** PostStudios403Error */
export interface PostStudios403Error {
  code: 'sign_in_required'
}

/** PostStudios403ErrorResponse */
export interface PostStudios403ErrorResponse {
  data: null
  errors: PostStudios403Error[]
}

/** PostStudios404Error */
export interface PostStudios404Error {
  code: 'user_not_found'
}

/** PostStudios404ErrorResponse */
export interface PostStudios404ErrorResponse {
  data: null
  errors: PostStudios404Error[]
}

/** PostStudiosStudioIdCovers400Error */
export interface PostStudiosStudioIdCovers400Error {
  code:
    | 'aws_upload_error'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'studio_access_denied'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PostStudiosStudioIdCovers400ErrorResponse */
export interface PostStudiosStudioIdCovers400ErrorResponse {
  data: null
  errors: PostStudiosStudioIdCovers400Error[]
}

/** PostStudiosStudioIdCovers403Error */
export interface PostStudiosStudioIdCovers403Error {
  code: 'sign_in_required'
}

/** PostStudiosStudioIdCovers403ErrorResponse */
export interface PostStudiosStudioIdCovers403ErrorResponse {
  data: null
  errors: PostStudiosStudioIdCovers403Error[]
}

/** PostStudiosStudioIdCovers404Error */
export interface PostStudiosStudioIdCovers404Error {
  code: 'studio_not_found'
}

/** PostStudiosStudioIdCovers404ErrorResponse */
export interface PostStudiosStudioIdCovers404ErrorResponse {
  data: null
  errors: PostStudiosStudioIdCovers404Error[]
}

/** PostTagsCreateModPlatformTags403Error */
export interface PostTagsCreateModPlatformTags403Error {
  code: 'sign_in_required'
}

/** PostTagsCreateModPlatformTags403ErrorResponse */
export interface PostTagsCreateModPlatformTags403ErrorResponse {
  data: null
  errors: PostTagsCreateModPlatformTags403Error[]
}

/** PostTagsCreateModTypeTags403Error */
export interface PostTagsCreateModTypeTags403Error {
  code: 'sign_in_required'
}

/** PostTagsCreateModTypeTags403ErrorResponse */
export interface PostTagsCreateModTypeTags403ErrorResponse {
  data: null
  errors: PostTagsCreateModTypeTags403Error[]
}

/** PostTagsCreateModWallpaperTypeTags403Error */
export interface PostTagsCreateModWallpaperTypeTags403Error {
  code: 'sign_in_required'
}

/** PostTagsCreateModWallpaperTypeTags403ErrorResponse */
export interface PostTagsCreateModWallpaperTypeTags403ErrorResponse {
  data: null
  errors: PostTagsCreateModWallpaperTypeTags403Error[]
}

/** PostTagsRecalculateNumberOfEntities403Error */
export interface PostTagsRecalculateNumberOfEntities403Error {
  code: 'sign_in_required'
}

/** PostTagsRecalculateNumberOfEntities403ErrorResponse */
export interface PostTagsRecalculateNumberOfEntities403ErrorResponse {
  data: null
  errors: PostTagsRecalculateNumberOfEntities403Error[]
}

/** PostUserUserIdBioLinks400Error */
export interface PostUserUserIdBioLinks400Error {
  code:
    | 'bio_link_title_too_long'
    | 'bio_link_url_invalid'
    | 'invalid_request_payload'
    | 'too_many_bio_links'
}

/** PostUserUserIdBioLinks400ErrorResponse */
export interface PostUserUserIdBioLinks400ErrorResponse {
  data: null
  errors: PostUserUserIdBioLinks400Error[]
}

/** PostUserUserIdBioLinks403Error */
export interface PostUserUserIdBioLinks403Error {
  code: 'sign_in_required'
}

/** PostUserUserIdBioLinks403ErrorResponse */
export interface PostUserUserIdBioLinks403ErrorResponse {
  data: null
  errors: PostUserUserIdBioLinks403Error[]
}

/** PostUserUserIdBioLinks404Error */
export interface PostUserUserIdBioLinks404Error {
  code: 'user_not_found'
}

/** PostUserUserIdBioLinks404ErrorResponse */
export interface PostUserUserIdBioLinks404ErrorResponse {
  data: null
  errors: PostUserUserIdBioLinks404Error[]
}

/** PostV2DynamicConfig400Error */
export interface PostV2DynamicConfig400Error {
  code:
    | 'dynamic_config_alias_does_not_exist'
    | 'dynamic_config_content_exceed_allowed_limit'
    | 'dynamic_config_priority_already_used'
    | 'dynamic_config_priority_negative_value'
    | 'dynamic_config_title_taken'
    | 'invalid_dynamic_config_condition'
    | 'invalid_request_payload'
    | 'too_complex_dynamic_config_condition'
}

/** PostV2DynamicConfig400ErrorResponse */
export interface PostV2DynamicConfig400ErrorResponse {
  data: null
  errors: PostV2DynamicConfig400Error[]
}

/** PostV2DynamicConfig403Error */
export interface PostV2DynamicConfig403Error {
  code: 'sign_in_required'
}

/** PostV2DynamicConfig403ErrorResponse */
export interface PostV2DynamicConfig403ErrorResponse {
  data: null
  errors: PostV2DynamicConfig403Error[]
}

/** PostV2GamesGameIdReviews400Error */
export interface PostV2GamesGameIdReviews400Error {
  code:
    | 'game_access_denied'
    | 'invalid_request_payload'
    | 'no_changes'
    | 'review_internal_comment_required'
    | 'review_message_to_subject_required'
}

/** PostV2GamesGameIdReviews400ErrorResponse */
export interface PostV2GamesGameIdReviews400ErrorResponse {
  data: null
  errors: PostV2GamesGameIdReviews400Error[]
}

/** PostV2GamesGameIdReviews403Error */
export interface PostV2GamesGameIdReviews403Error {
  code: 'sign_in_required'
}

/** PostV2GamesGameIdReviews403ErrorResponse */
export interface PostV2GamesGameIdReviews403ErrorResponse {
  data: null
  errors: PostV2GamesGameIdReviews403Error[]
}

/** PostV2GamesGameIdReviews404Error */
export interface PostV2GamesGameIdReviews404Error {
  code:
    | 'challenge_not_found'
    | 'game_not_found'
    | 'review_request_not_found'
    | 'studio_not_found'
    | 'track_not_found'
    | 'user_not_found'
}

/** PostV2GamesGameIdReviews404ErrorResponse */
export interface PostV2GamesGameIdReviews404ErrorResponse {
  data: null
  errors: PostV2GamesGameIdReviews404Error[]
}

/** PostV2ModsModIdReviews400Error */
export interface PostV2ModsModIdReviews400Error {
  code:
    | 'invalid_request_payload'
    | 'no_changes'
    | 'review_internal_comment_required'
    | 'review_message_to_subject_required'
}

/** PostV2ModsModIdReviews400ErrorResponse */
export interface PostV2ModsModIdReviews400ErrorResponse {
  data: null
  errors: PostV2ModsModIdReviews400Error[]
}

/** PostV2ModsModIdReviews403Error */
export interface PostV2ModsModIdReviews403Error {
  code: 'sign_in_required'
}

/** PostV2ModsModIdReviews403ErrorResponse */
export interface PostV2ModsModIdReviews403ErrorResponse {
  data: null
  errors: PostV2ModsModIdReviews403Error[]
}

/** PostV2ModsModIdReviews404Error */
export interface PostV2ModsModIdReviews404Error {
  code:
    | 'challenge_not_found'
    | 'game_not_found'
    | 'mod_not_found'
    | 'review_request_not_found'
    | 'studio_not_found'
    | 'track_not_found'
    | 'user_not_found'
}

/** PostV2ModsModIdReviews404ErrorResponse */
export interface PostV2ModsModIdReviews404ErrorResponse {
  data: null
  errors: PostV2ModsModIdReviews404Error[]
}

/** PostV2Quests400Error */
export interface PostV2Quests400Error {
  code:
    | 'bank_transaction_note_too_long'
    | 'invalid_bank_transaction_amount'
    | 'invalid_quest_type_parameters'
    | 'invalid_request_payload'
    | 'quest_alias_not_unique'
    | 'quest_alias_not_valid'
    | 'quest_alias_too_long'
    | 'quest_call_to_action_url_invalid'
    | 'quest_type_not_supported'
    | 'string_value_empty'
}

/** PostV2Quests400ErrorResponse */
export interface PostV2Quests400ErrorResponse {
  data: null
  errors: PostV2Quests400Error[]
}

/** PostV2Quests403Error */
export interface PostV2Quests403Error {
  code: 'sign_in_required'
}

/** PostV2Quests403ErrorResponse */
export interface PostV2Quests403ErrorResponse {
  data: null
  errors: PostV2Quests403Error[]
}

/** PostV2Quests404Error */
export interface PostV2Quests404Error {
  code: 'offer_not_found' | 'quest_collection_not_found'
}

/** PostV2Quests404ErrorResponse */
export interface PostV2Quests404ErrorResponse {
  data: null
  errors: PostV2Quests404Error[]
}

/** PostV2Seasons400Error */
export interface PostV2Seasons400Error {
  code:
    | 'invalid_request_payload'
    | 'overlapping_active_seasons'
    | 'season_date_range_invalid'
    | 'season_ends_at_missing'
    | 'season_identifier_missing'
    | 'season_identifier_taken'
    | 'season_starts_at_missing'
}

/** PostV2Seasons400ErrorResponse */
export interface PostV2Seasons400ErrorResponse {
  data: null
  errors: PostV2Seasons400Error[]
}

/** PostV2Seasons403Error */
export interface PostV2Seasons403Error {
  code: 'sign_in_required'
}

/** PostV2Seasons403ErrorResponse */
export interface PostV2Seasons403ErrorResponse {
  data: null
  errors: PostV2Seasons403Error[]
}

/** PostV2Tags400Error */
export interface PostV2Tags400Error {
  code:
    | 'invalid_request_payload'
    | 'invalid_tag_alias'
    | 'max_child_tags_limit_exceeded'
    | 'max_tag_depth_exceeded'
    | 'tag_alias_not_unique'
    | 'tag_alias_too_long'
    | 'tag_category_inconsistent'
    | 'tag_not_found'
    | 'tag_title_empty'
    | 'tag_title_taken'
}

/** PostV2Tags400ErrorResponse */
export interface PostV2Tags400ErrorResponse {
  data: null
  errors: PostV2Tags400Error[]
}

/** PostV2Tags403Error */
export interface PostV2Tags403Error {
  code: 'sign_in_required'
}

/** PostV2Tags403ErrorResponse */
export interface PostV2Tags403ErrorResponse {
  data: null
  errors: PostV2Tags403Error[]
}

/** PostWrappers400Error */
export interface PostWrappers400Error {
  code:
    | 'internal_server_error'
    | 'invalid_request_payload'
    | 'wrapper_name_empty'
    | 'wrapper_name_taken'
}

/** PostWrappers400ErrorResponse */
export interface PostWrappers400ErrorResponse {
  data: null
  errors: PostWrappers400Error[]
}

/** PostWrappers403Error */
export interface PostWrappers403Error {
  code: 'sign_in_required'
}

/** PostWrappers403ErrorResponse */
export interface PostWrappers403ErrorResponse {
  data: null
  errors: PostWrappers403Error[]
}

/** PostWrappersApplyDefaultStorageTagsToModWrappers400Error */
export interface PostWrappersApplyDefaultStorageTagsToModWrappers400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PostWrappersApplyDefaultStorageTagsToModWrappers400ErrorResponse */
export interface PostWrappersApplyDefaultStorageTagsToModWrappers400ErrorResponse {
  data: null
  errors: PostWrappersApplyDefaultStorageTagsToModWrappers400Error[]
}

/** PostWrappersApplyDefaultStorageTagsToModWrappers403Error */
export interface PostWrappersApplyDefaultStorageTagsToModWrappers403Error {
  code: 'sign_in_required'
}

/** PostWrappersApplyDefaultStorageTagsToModWrappers403ErrorResponse */
export interface PostWrappersApplyDefaultStorageTagsToModWrappers403ErrorResponse {
  data: null
  errors: PostWrappersApplyDefaultStorageTagsToModWrappers403Error[]
}

/** PostWrappersReprocessModWrappers403Error */
export interface PostWrappersReprocessModWrappers403Error {
  code: 'sign_in_required'
}

/** PostWrappersReprocessModWrappers403ErrorResponse */
export interface PostWrappersReprocessModWrappers403ErrorResponse {
  data: null
  errors: PostWrappersReprocessModWrappers403Error[]
}

/** PostWrappersWrapperIdDeployments400Error */
export interface PostWrappersWrapperIdDeployments400Error {
  code: 'wrapper_deployment_is_active' | 'wrapper_is_not_default'
}

/** PostWrappersWrapperIdDeployments400ErrorResponse */
export interface PostWrappersWrapperIdDeployments400ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeployments400Error[]
}

/** PostWrappersWrapperIdDeployments403Error */
export interface PostWrappersWrapperIdDeployments403Error {
  code: 'sign_in_required'
}

/** PostWrappersWrapperIdDeployments403ErrorResponse */
export interface PostWrappersWrapperIdDeployments403ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeployments403Error[]
}

/** PostWrappersWrapperIdDeployments404Error */
export interface PostWrappersWrapperIdDeployments404Error {
  code: 'wrapper_not_found'
}

/** PostWrappersWrapperIdDeployments404ErrorResponse */
export interface PostWrappersWrapperIdDeployments404ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeployments404Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort400Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort400Error {
  code: 'wrapper_deployment_is_not_active'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort400ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort400ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdAbort400Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort403Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort403Error {
  code: 'sign_in_required'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort403ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort403ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdAbort403Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort404Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort404Error {
  code: 'wrapper_deployment_not_found' | 'wrapper_not_found'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdAbort404ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdAbort404ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdAbort404Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume400Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume400Error {
  code: 'wrapper_deployment_is_not_active'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume400ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume400ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdResume400Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume403Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume403Error {
  code: 'sign_in_required'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume403ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume403ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdResume403Error[]
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume404Error */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume404Error {
  code: 'wrapper_deployment_not_found' | 'wrapper_not_found'
}

/** PostWrappersWrapperIdDeploymentsDeploymentIdResume404ErrorResponse */
export interface PostWrappersWrapperIdDeploymentsDeploymentIdResume404ErrorResponse {
  data: null
  errors: PostWrappersWrapperIdDeploymentsDeploymentIdResume404Error[]
}

/** ProfileResponse */
export interface ProfileResponse {
  /** True if user is active */
  active?: boolean | null
  /** List of dynamic config clients user has access to */
  allowedDynamicConfigClients?: DynamicConfigClientResponse[] | null
  /** Email address */
  email?: string | null
  /** Given name */
  name?: string | null
  /** List of roles assigned to user */
  roles?:
    | (
        | 'ADMIN'
        | 'CONTENT_MANAGER'
        | 'DYNAMIC_CONFIG_MANAGER'
        | 'GM_OPS'
        | 'OFFER_MANAGER'
        | 'VIEWER'
      )[]
    | null
}

/** PromotedChallengeResponse */
export interface PromotedChallengeResponse {
  challenge?: ChallengeResponse | null
  /**
   * underlying challenge's criteria
   *
   * Allowed values: `highest_wins`, `lowest_wins`
   * @example "lowest_wins"
   */
  challengeCriteria?: 'highest_wins' | 'lowest_wins' | null
  /** id of the promoted challenge */
  challengeId: string
  /** underlying challenge's long description */
  challengeLongDescription?: string | null
  /** underlying challenge's name */
  challengeName?: string | null
  /** underlying challenge's short description */
  challengeShortDescription?: string | null
  /**
   * underlying challenge's type
   *
   * Allowed values: `points`, `duration`
   * @example "duration"
   */
  challengeType?: 'points' | 'duration' | null
  /**
   * promotion be inactive from this date
   * @format date-time
   */
  endsAt?: string | null
  /** long description of the challenge promotion */
  longDescription?: string | null
  /** true if this promotion is currently open */
  open?: boolean | null
  /** unique id of challenge promotion */
  promotedChallengeId: string
  /** true if this promotion is currently published/live */
  published?: boolean | null
  /**
   * when promotion was published, or null if not published
   * @format date-time
   */
  publishedAt?: string | null
  /**
   * season
   *
   * Notes: *null if the promotion is not associated with a season*
   */
  season?: SeasonResponse | null
  /**
   * season id
   *
   * Notes: *null if the promotion is not associated with a season*
   *
   * *Deprecated in favor of `season.seasonId`*
   * @deprecated
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  seasonId?: string | null
  /** short description of the challenge promotion */
  shortDescription?: string | null
  /**
   * promotion be active from this date if it is also published
   * @format date-time
   */
  startsAt?: string | null
}

/** PromotedChallengesResponse */
export interface PromotedChallengesResponse {
  promotedChallenges?: PromotedChallengeResponse[] | null
}

/** PublisherDetailsResponse */
export interface PublisherDetailsResponse {
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  publisherId: string
  /** Publisher title as a localizable string */
  titleString?: StringResponse | null
  /** Publisher url as a localizable string */
  urlString?: StringResponse | null
}

/** PublisherResponse */
export interface PublisherResponse {
  /** @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d" */
  publisherId: string
  /** Publisher title, in default locale */
  title?: string | null
  /** Publisher url, in default locale */
  url?: string | null
}

export type PurgeConfigurationCacheData = ResponseVoid

export type PurgeConfigurationCacheError =
  | PostDynamicConfigConfigIdPurgeCache403ErrorResponse
  | PostDynamicConfigConfigIdPurgeCache404ErrorResponse

export type PurgeEveryCacheData = ResponseVoid

export type PurgeEveryCacheError = PostDynamicConfigPurgeCache403ErrorResponse

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400Error */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400Error {
  code: 'active_feed_state_is_readonly' | 'wrong_collection_entry_attribute_type'
}

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400ErrorResponse */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400ErrorResponse {
  data: null
  errors: PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400Error[]
}

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403Error */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403Error {
  code: 'sign_in_required'
}

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403ErrorResponse */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403ErrorResponse {
  data: null
  errors: PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403Error[]
}

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404Error */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404Error {
  code: 'attribute_not_found' | 'entry_not_found' | 'feed_not_found'
}

/** PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404ErrorResponse */
export interface PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404ErrorResponse {
  data: null
  errors: PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404Error[]
}

/** PutGameWrappersGameWrapperIdUpload400Error */
export interface PutGameWrappersGameWrapperIdUpload400Error {
  code: 'game_wrapper_is_default' | 'game_wrapper_malformed'
}

/** PutGameWrappersGameWrapperIdUpload400ErrorResponse */
export interface PutGameWrappersGameWrapperIdUpload400ErrorResponse {
  data: null
  errors: PutGameWrappersGameWrapperIdUpload400Error[]
}

/** PutGameWrappersGameWrapperIdUpload403Error */
export interface PutGameWrappersGameWrapperIdUpload403Error {
  code: 'sign_in_required'
}

/** PutGameWrappersGameWrapperIdUpload403ErrorResponse */
export interface PutGameWrappersGameWrapperIdUpload403ErrorResponse {
  data: null
  errors: PutGameWrappersGameWrapperIdUpload403Error[]
}

/** PutGameWrappersGameWrapperIdUpload404Error */
export interface PutGameWrappersGameWrapperIdUpload404Error {
  code: 'game_wrapper_not_found'
}

/** PutGameWrappersGameWrapperIdUpload404ErrorResponse */
export interface PutGameWrappersGameWrapperIdUpload404ErrorResponse {
  data: null
  errors: PutGameWrappersGameWrapperIdUpload404Error[]
}

/** PutGameWrappersGameWrapperIdUpload500Error */
export interface PutGameWrappersGameWrapperIdUpload500Error {
  code: 'internal_server_error'
}

/** PutGameWrappersGameWrapperIdUpload500ErrorResponse */
export interface PutGameWrappersGameWrapperIdUpload500ErrorResponse {
  data: null
  errors: PutGameWrappersGameWrapperIdUpload500Error[]
}

/** PutGamesGameIdCover400Error */
export interface PutGamesGameIdCover400Error {
  code:
    | 'game_access_denied'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutGamesGameIdCover400ErrorResponse */
export interface PutGamesGameIdCover400ErrorResponse {
  data: null
  errors: PutGamesGameIdCover400Error[]
}

/** PutGamesGameIdCover403Error */
export interface PutGamesGameIdCover403Error {
  code: 'sign_in_required'
}

/** PutGamesGameIdCover403ErrorResponse */
export interface PutGamesGameIdCover403ErrorResponse {
  data: null
  errors: PutGamesGameIdCover403Error[]
}

/** PutGamesGameIdCover404Error */
export interface PutGamesGameIdCover404Error {
  code: 'game_not_found'
}

/** PutGamesGameIdCover404ErrorResponse */
export interface PutGamesGameIdCover404ErrorResponse {
  data: null
  errors: PutGamesGameIdCover404Error[]
}

/** PutGamesGameIdCover500Error */
export interface PutGamesGameIdCover500Error {
  code: 'internal_server_error'
}

/** PutGamesGameIdCover500ErrorResponse */
export interface PutGamesGameIdCover500ErrorResponse {
  data: null
  errors: PutGamesGameIdCover500Error[]
}

export type PutIconData = ResponseTagDetailsResponse

export type PutIconError =
  | PutTagsTagIdIcon400ErrorResponse
  | PutTagsTagIdIcon403ErrorResponse
  | PutTagsTagIdIcon404ErrorResponse
  | PutTagsTagIdIcon500ErrorResponse

export interface PutIconPayload {
  /** @format binary */
  file?: File
}

/** PutModsFlavorsSettingsDefaultFeatures400Error */
export interface PutModsFlavorsSettingsDefaultFeatures400Error {
  code: 'invalid_setting_value' | 'no_changes'
}

/** PutModsFlavorsSettingsDefaultFeatures400ErrorResponse */
export interface PutModsFlavorsSettingsDefaultFeatures400ErrorResponse {
  data: null
  errors: PutModsFlavorsSettingsDefaultFeatures400Error[]
}

/** PutModsFlavorsSettingsDefaultFeatures403Error */
export interface PutModsFlavorsSettingsDefaultFeatures403Error {
  code: 'sign_in_required'
}

/** PutModsFlavorsSettingsDefaultFeatures403ErrorResponse */
export interface PutModsFlavorsSettingsDefaultFeatures403ErrorResponse {
  data: null
  errors: PutModsFlavorsSettingsDefaultFeatures403Error[]
}

/** PutModsModIdCover400Error */
export interface PutModsModIdCover400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutModsModIdCover400ErrorResponse */
export interface PutModsModIdCover400ErrorResponse {
  data: null
  errors: PutModsModIdCover400Error[]
}

/** PutModsModIdCover403Error */
export interface PutModsModIdCover403Error {
  code: 'sign_in_required'
}

/** PutModsModIdCover403ErrorResponse */
export interface PutModsModIdCover403ErrorResponse {
  data: null
  errors: PutModsModIdCover403Error[]
}

/** PutModsModIdCover404Error */
export interface PutModsModIdCover404Error {
  code: 'mod_not_found'
}

/** PutModsModIdCover404ErrorResponse */
export interface PutModsModIdCover404ErrorResponse {
  data: null
  errors: PutModsModIdCover404Error[]
}

/** PutModsModIdCover500Error */
export interface PutModsModIdCover500Error {
  code: 'internal_server_error'
}

/** PutModsModIdCover500ErrorResponse */
export interface PutModsModIdCover500ErrorResponse {
  data: null
  errors: PutModsModIdCover500Error[]
}

/** PutModsModIdGraphics400Error */
export interface PutModsModIdGraphics400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'max_graphics_limit_reached'
    | 'preview_required_for_videos'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutModsModIdGraphics400ErrorResponse */
export interface PutModsModIdGraphics400ErrorResponse {
  data: null
  errors: PutModsModIdGraphics400Error[]
}

/** PutModsModIdGraphics403Error */
export interface PutModsModIdGraphics403Error {
  code: 'sign_in_required'
}

/** PutModsModIdGraphics403ErrorResponse */
export interface PutModsModIdGraphics403ErrorResponse {
  data: null
  errors: PutModsModIdGraphics403Error[]
}

/** PutModsModIdGraphics404Error */
export interface PutModsModIdGraphics404Error {
  code: 'mod_not_found'
}

/** PutModsModIdGraphics404ErrorResponse */
export interface PutModsModIdGraphics404ErrorResponse {
  data: null
  errors: PutModsModIdGraphics404Error[]
}

/** PutModsModIdGraphics500Error */
export interface PutModsModIdGraphics500Error {
  code: 'internal_server_error'
}

/** PutModsModIdGraphics500ErrorResponse */
export interface PutModsModIdGraphics500ErrorResponse {
  data: null
  errors: PutModsModIdGraphics500Error[]
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease400Error */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease400Error {
  code:
    | 'file_must_be_zip'
    | 'mod_manifest_malformed'
    | 'mod_manifest_missing'
    | 'mod_manifest_too_big'
    | 'no_release_for_track'
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease400ErrorResponse */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease400ErrorResponse {
  data: null
  errors: PutModsModIdTracksTrackIdRevalidateCurrentRelease400Error[]
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease403Error */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease403Error {
  code: 'sign_in_required'
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease403ErrorResponse */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease403ErrorResponse {
  data: null
  errors: PutModsModIdTracksTrackIdRevalidateCurrentRelease403Error[]
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease404Error */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease404Error {
  code: 'mod_not_found' | 'track_not_found'
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease404ErrorResponse */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease404ErrorResponse {
  data: null
  errors: PutModsModIdTracksTrackIdRevalidateCurrentRelease404Error[]
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease500Error */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease500Error {
  code: 'internal_server_error'
}

/** PutModsModIdTracksTrackIdRevalidateCurrentRelease500ErrorResponse */
export interface PutModsModIdTracksTrackIdRevalidateCurrentRelease500ErrorResponse {
  data: null
  errors: PutModsModIdTracksTrackIdRevalidateCurrentRelease500Error[]
}

/** PutModsRevalidateCurrentReleases400Error */
export interface PutModsRevalidateCurrentReleases400Error {
  code:
    | 'page_invalid'
    | 'page_less_than_0'
    | 'page_size_invalid'
    | 'page_size_less_than_1'
    | 'page_size_too_high'
}

/** PutModsRevalidateCurrentReleases400ErrorResponse */
export interface PutModsRevalidateCurrentReleases400ErrorResponse {
  data: null
  errors: PutModsRevalidateCurrentReleases400Error[]
}

/** PutModsRevalidateCurrentReleases403Error */
export interface PutModsRevalidateCurrentReleases403Error {
  code: 'sign_in_required'
}

/** PutModsRevalidateCurrentReleases403ErrorResponse */
export interface PutModsRevalidateCurrentReleases403ErrorResponse {
  data: null
  errors: PutModsRevalidateCurrentReleases403Error[]
}

/** PutMultiplayerGuestAvatars400Error */
export interface PutMultiplayerGuestAvatars400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutMultiplayerGuestAvatars400ErrorResponse */
export interface PutMultiplayerGuestAvatars400ErrorResponse {
  data: null
  errors: PutMultiplayerGuestAvatars400Error[]
}

/** PutMultiplayerGuestAvatars403Error */
export interface PutMultiplayerGuestAvatars403Error {
  code: 'sign_in_required'
}

/** PutMultiplayerGuestAvatars403ErrorResponse */
export interface PutMultiplayerGuestAvatars403ErrorResponse {
  data: null
  errors: PutMultiplayerGuestAvatars403Error[]
}

/** PutMultiplayerGuestAvatars500Error */
export interface PutMultiplayerGuestAvatars500Error {
  code: 'internal_server_error'
}

/** PutMultiplayerGuestAvatars500ErrorResponse */
export interface PutMultiplayerGuestAvatars500ErrorResponse {
  data: null
  errors: PutMultiplayerGuestAvatars500Error[]
}

/** PutOdksKeyIdModsModId400Error */
export interface PutOdksKeyIdModsModId400Error {
  code: 'mod_already_has_official_distribution_key' | 'too_many_official_distribution_keys'
}

/** PutOdksKeyIdModsModId400ErrorResponse */
export interface PutOdksKeyIdModsModId400ErrorResponse {
  data: null
  errors: PutOdksKeyIdModsModId400Error[]
}

/** PutOdksKeyIdModsModId403Error */
export interface PutOdksKeyIdModsModId403Error {
  code: 'sign_in_required'
}

/** PutOdksKeyIdModsModId403ErrorResponse */
export interface PutOdksKeyIdModsModId403ErrorResponse {
  data: null
  errors: PutOdksKeyIdModsModId403Error[]
}

/** PutOdksKeyIdModsModId404Error */
export interface PutOdksKeyIdModsModId404Error {
  code: 'mod_not_found' | 'official_distribution_key_not_found'
}

/** PutOdksKeyIdModsModId404ErrorResponse */
export interface PutOdksKeyIdModsModId404ErrorResponse {
  data: null
  errors: PutOdksKeyIdModsModId404Error[]
}

/** PutOdksKeyIdStudiosStudioId400Error */
export interface PutOdksKeyIdStudiosStudioId400Error {
  code:
    | 'studio_access_denied'
    | 'studio_already_has_official_distribution_key'
    | 'too_many_official_distribution_keys'
}

/** PutOdksKeyIdStudiosStudioId400ErrorResponse */
export interface PutOdksKeyIdStudiosStudioId400ErrorResponse {
  data: null
  errors: PutOdksKeyIdStudiosStudioId400Error[]
}

/** PutOdksKeyIdStudiosStudioId403Error */
export interface PutOdksKeyIdStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** PutOdksKeyIdStudiosStudioId403ErrorResponse */
export interface PutOdksKeyIdStudiosStudioId403ErrorResponse {
  data: null
  errors: PutOdksKeyIdStudiosStudioId403Error[]
}

/** PutOdksKeyIdStudiosStudioId404Error */
export interface PutOdksKeyIdStudiosStudioId404Error {
  code: 'official_distribution_key_not_found' | 'studio_not_found'
}

/** PutOdksKeyIdStudiosStudioId404ErrorResponse */
export interface PutOdksKeyIdStudiosStudioId404ErrorResponse {
  data: null
  errors: PutOdksKeyIdStudiosStudioId404Error[]
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent400Error */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent400Error {
  code: 'file_must_be_zip' | 'offer_error_page_index_html_missing'
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse {
  data: null
  errors: PutOffersOfferIdErrorPagesErrorPageIdContent400Error[]
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent403Error */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent403Error {
  code: 'sign_in_required'
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse {
  data: null
  errors: PutOffersOfferIdErrorPagesErrorPageIdContent403Error[]
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent404Error */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent404Error {
  code: 'offer_error_page_not_found' | 'offer_not_found'
}

/** PutOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse */
export interface PutOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse {
  data: null
  errors: PutOffersOfferIdErrorPagesErrorPageIdContent404Error[]
}

/** PutQuestsQuestIdCover400Error */
export interface PutQuestsQuestIdCover400Error {
  code:
    | 'aws_upload_error'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutQuestsQuestIdCover400ErrorResponse */
export interface PutQuestsQuestIdCover400ErrorResponse {
  data: null
  errors: PutQuestsQuestIdCover400Error[]
}

/** PutQuestsQuestIdCover403Error */
export interface PutQuestsQuestIdCover403Error {
  code: 'sign_in_required'
}

/** PutQuestsQuestIdCover403ErrorResponse */
export interface PutQuestsQuestIdCover403ErrorResponse {
  data: null
  errors: PutQuestsQuestIdCover403Error[]
}

/** PutQuestsQuestIdCover404Error */
export interface PutQuestsQuestIdCover404Error {
  code: 'quest_not_found'
}

/** PutQuestsQuestIdCover404ErrorResponse */
export interface PutQuestsQuestIdCover404ErrorResponse {
  data: null
  errors: PutQuestsQuestIdCover404Error[]
}

/** PutSeasonsSeasonIdBlockedGamesGameId400Error */
export interface PutSeasonsSeasonIdBlockedGamesGameId400Error {
  code: 'invalid_request_payload' | 'wrong_season_type'
}

/** PutSeasonsSeasonIdBlockedGamesGameId400ErrorResponse */
export interface PutSeasonsSeasonIdBlockedGamesGameId400ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedGamesGameId400Error[]
}

/** PutSeasonsSeasonIdBlockedGamesGameId403Error */
export interface PutSeasonsSeasonIdBlockedGamesGameId403Error {
  code: 'sign_in_required'
}

/** PutSeasonsSeasonIdBlockedGamesGameId403ErrorResponse */
export interface PutSeasonsSeasonIdBlockedGamesGameId403ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedGamesGameId403Error[]
}

/** PutSeasonsSeasonIdBlockedGamesGameId404Error */
export interface PutSeasonsSeasonIdBlockedGamesGameId404Error {
  code: 'game_not_found' | 'season_not_found'
}

/** PutSeasonsSeasonIdBlockedGamesGameId404ErrorResponse */
export interface PutSeasonsSeasonIdBlockedGamesGameId404ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedGamesGameId404Error[]
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId400Error */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId400Error {
  code: 'invalid_request_payload' | 'wrong_season_type'
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId400ErrorResponse */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId400ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedStudiosStudioId400Error[]
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId403Error */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId403Error {
  code: 'sign_in_required'
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedStudiosStudioId403Error[]
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId404Error */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId404Error {
  code: 'season_not_found' | 'studio_not_found'
}

/** PutSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse */
export interface PutSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse {
  data: null
  errors: PutSeasonsSeasonIdBlockedStudiosStudioId404Error[]
}

/** PutSettingsSettingKey400Error */
export interface PutSettingsSettingKey400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'invalid_setting_value'
    | 'mod_icon_unsupported_image_type'
    | 'mod_icon_wrong_dimensions'
    | 'no_changes'
    | 'unsupported_graphic_format'
}

/** PutSettingsSettingKey400ErrorResponse */
export interface PutSettingsSettingKey400ErrorResponse {
  data: null
  errors: PutSettingsSettingKey400Error[]
}

/** PutSettingsSettingKey403Error */
export interface PutSettingsSettingKey403Error {
  code: 'sign_in_required'
}

/** PutSettingsSettingKey403ErrorResponse */
export interface PutSettingsSettingKey403ErrorResponse {
  data: null
  errors: PutSettingsSettingKey403Error[]
}

/** PutSettingsSettingKey404Error */
export interface PutSettingsSettingKey404Error {
  code: 'setting_not_found'
}

/** PutSettingsSettingKey404ErrorResponse */
export interface PutSettingsSettingKey404ErrorResponse {
  data: null
  errors: PutSettingsSettingKey404Error[]
}

/** PutStickersStickerIdCover400Error */
export interface PutStickersStickerIdCover400Error {
  code:
    | 'aws_upload_error'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutStickersStickerIdCover400ErrorResponse */
export interface PutStickersStickerIdCover400ErrorResponse {
  data: null
  errors: PutStickersStickerIdCover400Error[]
}

/** PutStickersStickerIdCover403Error */
export interface PutStickersStickerIdCover403Error {
  code: 'sign_in_required'
}

/** PutStickersStickerIdCover403ErrorResponse */
export interface PutStickersStickerIdCover403ErrorResponse {
  data: null
  errors: PutStickersStickerIdCover403Error[]
}

/** PutStickersStickerIdCover404Error */
export interface PutStickersStickerIdCover404Error {
  code: 'sticker_not_found'
}

/** PutStickersStickerIdCover404ErrorResponse */
export interface PutStickersStickerIdCover404ErrorResponse {
  data: null
  errors: PutStickersStickerIdCover404Error[]
}

/** PutTagsTagIdGamesGameId400Error */
export interface PutTagsTagIdGamesGameId400Error {
  code:
    | 'game_access_denied'
    | 'game_already_has_tag'
    | 'game_has_too_many_tags'
    | 'tag_category_mismatch'
    | 'tag_has_child_tags'
}

/** PutTagsTagIdGamesGameId400ErrorResponse */
export interface PutTagsTagIdGamesGameId400ErrorResponse {
  data: null
  errors: PutTagsTagIdGamesGameId400Error[]
}

/** PutTagsTagIdGamesGameId403Error */
export interface PutTagsTagIdGamesGameId403Error {
  code: 'sign_in_required'
}

/** PutTagsTagIdGamesGameId403ErrorResponse */
export interface PutTagsTagIdGamesGameId403ErrorResponse {
  data: null
  errors: PutTagsTagIdGamesGameId403Error[]
}

/** PutTagsTagIdGamesGameId404Error */
export interface PutTagsTagIdGamesGameId404Error {
  code: 'game_not_found' | 'tag_not_found'
}

/** PutTagsTagIdGamesGameId404ErrorResponse */
export interface PutTagsTagIdGamesGameId404ErrorResponse {
  data: null
  errors: PutTagsTagIdGamesGameId404Error[]
}

/** PutTagsTagIdIcon400Error */
export interface PutTagsTagIdIcon400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutTagsTagIdIcon400ErrorResponse */
export interface PutTagsTagIdIcon400ErrorResponse {
  data: null
  errors: PutTagsTagIdIcon400Error[]
}

/** PutTagsTagIdIcon403Error */
export interface PutTagsTagIdIcon403Error {
  code: 'sign_in_required'
}

/** PutTagsTagIdIcon403ErrorResponse */
export interface PutTagsTagIdIcon403ErrorResponse {
  data: null
  errors: PutTagsTagIdIcon403Error[]
}

/** PutTagsTagIdIcon404Error */
export interface PutTagsTagIdIcon404Error {
  code: 'tag_not_found'
}

/** PutTagsTagIdIcon404ErrorResponse */
export interface PutTagsTagIdIcon404ErrorResponse {
  data: null
  errors: PutTagsTagIdIcon404Error[]
}

/** PutTagsTagIdIcon500Error */
export interface PutTagsTagIdIcon500Error {
  code: 'internal_server_error'
}

/** PutTagsTagIdIcon500ErrorResponse */
export interface PutTagsTagIdIcon500ErrorResponse {
  data: null
  errors: PutTagsTagIdIcon500Error[]
}

/** PutTagsTagIdModsModId400Error */
export interface PutTagsTagIdModsModId400Error {
  code:
    | 'entity_already_has_tag'
    | 'entity_has_too_many_tags'
    | 'tag_category_mismatch'
    | 'tag_has_child_tags'
    | 'tag_not_available_to_creators'
}

/** PutTagsTagIdModsModId400ErrorResponse */
export interface PutTagsTagIdModsModId400ErrorResponse {
  data: null
  errors: PutTagsTagIdModsModId400Error[]
}

/** PutTagsTagIdModsModId403Error */
export interface PutTagsTagIdModsModId403Error {
  code: 'sign_in_required'
}

/** PutTagsTagIdModsModId403ErrorResponse */
export interface PutTagsTagIdModsModId403ErrorResponse {
  data: null
  errors: PutTagsTagIdModsModId403Error[]
}

/** PutTagsTagIdModsModId404Error */
export interface PutTagsTagIdModsModId404Error {
  code: 'mod_not_found' | 'tag_not_found'
}

/** PutTagsTagIdModsModId404ErrorResponse */
export interface PutTagsTagIdModsModId404ErrorResponse {
  data: null
  errors: PutTagsTagIdModsModId404Error[]
}

/** PutUserUserIdBioLinksLinkIdIcon400Error */
export interface PutUserUserIdBioLinksLinkIdIcon400Error {
  code:
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutUserUserIdBioLinksLinkIdIcon400ErrorResponse */
export interface PutUserUserIdBioLinksLinkIdIcon400ErrorResponse {
  data: null
  errors: PutUserUserIdBioLinksLinkIdIcon400Error[]
}

/** PutUserUserIdBioLinksLinkIdIcon403Error */
export interface PutUserUserIdBioLinksLinkIdIcon403Error {
  code: 'sign_in_required'
}

/** PutUserUserIdBioLinksLinkIdIcon403ErrorResponse */
export interface PutUserUserIdBioLinksLinkIdIcon403ErrorResponse {
  data: null
  errors: PutUserUserIdBioLinksLinkIdIcon403Error[]
}

/** PutUserUserIdBioLinksLinkIdIcon404Error */
export interface PutUserUserIdBioLinksLinkIdIcon404Error {
  code: 'bio_link_not_found' | 'user_not_found'
}

/** PutUserUserIdBioLinksLinkIdIcon404ErrorResponse */
export interface PutUserUserIdBioLinksLinkIdIcon404ErrorResponse {
  data: null
  errors: PutUserUserIdBioLinksLinkIdIcon404Error[]
}

/** PutUserUserIdBioLinksLinkIdIcon500Error */
export interface PutUserUserIdBioLinksLinkIdIcon500Error {
  code: 'internal_server_error'
}

/** PutUserUserIdBioLinksLinkIdIcon500ErrorResponse */
export interface PutUserUserIdBioLinksLinkIdIcon500ErrorResponse {
  data: null
  errors: PutUserUserIdBioLinksLinkIdIcon500Error[]
}

/** PutV2ChallengesChallengeIdCover400Error */
export interface PutV2ChallengesChallengeIdCover400Error {
  code:
    | 'challenge_access_denied'
    | 'challenge_not_found'
    | 'image_invalid_aspect_ratio'
    | 'image_too_small'
    | 'invalid_image'
    | 'unsupported_graphic_format'
    | 'video_not_allowed_as_image'
}

/** PutV2ChallengesChallengeIdCover400ErrorResponse */
export interface PutV2ChallengesChallengeIdCover400ErrorResponse {
  data: null
  errors: PutV2ChallengesChallengeIdCover400Error[]
}

/** PutV2ChallengesChallengeIdCover403Error */
export interface PutV2ChallengesChallengeIdCover403Error {
  code: 'sign_in_required'
}

/** PutV2ChallengesChallengeIdCover403ErrorResponse */
export interface PutV2ChallengesChallengeIdCover403ErrorResponse {
  data: null
  errors: PutV2ChallengesChallengeIdCover403Error[]
}

/** PutV2ChallengesChallengeIdCover404Error */
export interface PutV2ChallengesChallengeIdCover404Error {
  code: 'challenge_not_found'
}

/** PutV2ChallengesChallengeIdCover404ErrorResponse */
export interface PutV2ChallengesChallengeIdCover404ErrorResponse {
  data: null
  errors: PutV2ChallengesChallengeIdCover404Error[]
}

/** PutV2ChallengesChallengeIdCover500Error */
export interface PutV2ChallengesChallengeIdCover500Error {
  code: 'internal_server_error'
}

/** PutV2ChallengesChallengeIdCover500ErrorResponse */
export interface PutV2ChallengesChallengeIdCover500ErrorResponse {
  data: null
  errors: PutV2ChallengesChallengeIdCover500Error[]
}

/** PutV2GamesGameIdItems400Error */
export interface PutV2GamesGameIdItems400Error {
  code:
    | 'full_version_game_item_already_added'
    | 'game_access_denied'
    | 'invalid_currency_name'
    | 'invalid_game_item_type'
    | 'invalid_request_payload'
    | 'monetization_for_studio_disabled'
    | 'negative_price'
    | 'no_changes'
    | 'paid_game_item_already_added'
    | 'price_exceeds_maximum_value'
    | 'too_many_game_items'
}

/** PutV2GamesGameIdItems400ErrorResponse */
export interface PutV2GamesGameIdItems400ErrorResponse {
  data: null
  errors: PutV2GamesGameIdItems400Error[]
}

/** PutV2GamesGameIdItems403Error */
export interface PutV2GamesGameIdItems403Error {
  code: 'sign_in_required'
}

/** PutV2GamesGameIdItems403ErrorResponse */
export interface PutV2GamesGameIdItems403ErrorResponse {
  data: null
  errors: PutV2GamesGameIdItems403Error[]
}

/** PutV2GamesGameIdItems404Error */
export interface PutV2GamesGameIdItems404Error {
  code: 'game_not_found'
}

/** PutV2GamesGameIdItems404ErrorResponse */
export interface PutV2GamesGameIdItems404ErrorResponse {
  data: null
  errors: PutV2GamesGameIdItems404Error[]
}

/** PutV2GamesGameIdItems500Error */
export interface PutV2GamesGameIdItems500Error {
  code: 'internal_server_error'
}

/** PutV2GamesGameIdItems500ErrorResponse */
export interface PutV2GamesGameIdItems500ErrorResponse {
  data: null
  errors: PutV2GamesGameIdItems500Error[]
}

/** PutWrappersWrapperIdUpload400Error */
export interface PutWrappersWrapperIdUpload400Error {
  code: 'wrapper_is_default' | 'wrapper_malformed'
}

/** PutWrappersWrapperIdUpload400ErrorResponse */
export interface PutWrappersWrapperIdUpload400ErrorResponse {
  data: null
  errors: PutWrappersWrapperIdUpload400Error[]
}

/** PutWrappersWrapperIdUpload403Error */
export interface PutWrappersWrapperIdUpload403Error {
  code: 'sign_in_required'
}

/** PutWrappersWrapperIdUpload403ErrorResponse */
export interface PutWrappersWrapperIdUpload403ErrorResponse {
  data: null
  errors: PutWrappersWrapperIdUpload403Error[]
}

/** PutWrappersWrapperIdUpload404Error */
export interface PutWrappersWrapperIdUpload404Error {
  code: 'wrapper_not_found'
}

/** PutWrappersWrapperIdUpload404ErrorResponse */
export interface PutWrappersWrapperIdUpload404ErrorResponse {
  data: null
  errors: PutWrappersWrapperIdUpload404Error[]
}

/** PutWrappersWrapperIdUpload500Error */
export interface PutWrappersWrapperIdUpload500Error {
  code: 'internal_server_error'
}

/** PutWrappersWrapperIdUpload500ErrorResponse */
export interface PutWrappersWrapperIdUpload500ErrorResponse {
  data: null
  errors: PutWrappersWrapperIdUpload500Error[]
}

/** BO quest collection response model */
export interface QuestCollectionResponse {
  alias?: string | null
  collectionId: string
  collectionType?: 'DAILY_QUESTS' | 'PERSONAL_QUESTS' | null
  isActive?: boolean | null
  /**
   * Indicates which quest should be made available next.
   *
   * Notes: *Only relevant if type is `DAILY_QUESTS`; this field is `null` otherwise*
   * @format int32
   * @example 4
   */
  nextQuestIndex?: number | null
  /** Quests in this collection, sorted by their collection index values. */
  quests?: QuestSummaryResponse[] | null
  /**
   * Ids of quests that will become available at the next rotation.
   *
   * Notes: *All referenced quests will be present in the `quests` list. Only relevant if type is `DAILY_QUESTS`; this field is `null` otherwise.*
   */
  upcomingQuestIds?: string[] | null
}

/** BO quest collection summary response model */
export interface QuestCollectionSummaryResponse {
  alias?: string | null
  collectionId: string
  collectionType?: 'DAILY_QUESTS' | 'PERSONAL_QUESTS' | null
  isActive?: boolean | null
}

/** BO request for quest collection update */
export interface QuestCollectionUpdateRequest {
  /**
   * Whether the collection is active or not
   *
   * Notes: *The (de)activation of a collection has no effect on status or progress of the quest instances that are part of it.*
   */
  active?: boolean | null
  /**
   * Index of the first quest to make available at the next rotation.
   *
   * Notes: *Can only be updated on collections of the type `DAILY_QUESTS`.*
   * @format int32
   */
  nextQuestIndex?: number | null
  /** List of quest IDs in quest collection in the new order */
  questIds?: string[] | null
}

/** BO paginated list of quest collections response model */
export interface QuestCollectionsResponse {
  pagination: PaginationResponse
  questCollections: QuestCollectionResponse[]
}

/** BO request for quest creation */
export interface QuestCreationRequest {
  /**
   * Unique quest alias
   * @example "do-the-groceries"
   */
  alias: string
  /**
   * Bank transaction note used when paying out the reward
   * @example "You have done the groceries."
   */
  bankTransactionNote: string
  /**
   * Call-to-action title in default language
   * @example "It's morphin' time!"
   */
  callToAction?: string | null
  /**
   * URL to the call-to-action
   * @example "https://gx.opera.com/put-sth-here"
   */
  callToActionUrl?: string | null
  /**
   * Description in default language
   * @example "Go to the local grocery store and purchase tomatoes, carrots and minced meat."
   */
  description: string
  /** Parameters: may be required based on quest type */
  parameters?: MapStringObject | null
  /**
   * Alias of the quest collection this instance will be part of
   * @example "default"
   */
  questCollection?: string | null
  /**
   * Amount of the reward earned upon quest completion
   * @format int64
   */
  rewardAmount: number
  /** Currency of the reward earned upon quest completion */
  rewardCurrency: 'RPX' | 'GPX' | 'BPX' | 'USD'
  /**
   * Title in default language
   * @example "Do the groceries!"
   */
  title: string
  /** Quest type */
  type:
    | 'ADD_GAMES_TO_FAVORITES'
    | 'ADD_NEW_FRIENDS'
    | 'BUY_GAMES'
    | 'PARTICIPATE_IN_CHALLENGES'
    | 'PARTNER_QUEST'
    | 'PAY_RESPECT_TO_GAMES'
    | 'PAY_RESPECT_TO_STUDIOS'
    | 'PLAY_GAMES'
    | 'SETUP_CUSTOM_AVATAR'
    | 'SETUP_PROFILE_DESCRIPTION'
    | 'SETUP_BIO_LINKS'
    | 'SPEND_VIRTUAL_CURRENCY'
}

/** BO paginated list of quests response model */
export interface QuestListResponse {
  pagination: PaginationResponse
  quests: QuestResponse[]
}

/** QuestOfferCodeRewardRequest */
export interface QuestOfferCodeRewardRequest {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  offerId: string
}

/** QuestOfferCodeRewardResponse */
export interface QuestOfferCodeRewardResponse {
  offer?: OfferResponse | null
}

/** QuestPixelsRewardRequest */
export interface QuestPixelsRewardRequest {
  /**
   * Amount of the reward earned upon quest completion
   * @format int64
   */
  amount: number
  /**
   * Bank transaction note used when paying out the reward
   * @example "You have done the groceries."
   */
  bankTransactionNote: string
  /** Currency of the reward earned upon quest completion */
  currency: 'RPX' | 'GPX' | 'BPX' | 'USD'
}

/** BO quest reward response model */
export interface QuestPixelsRewardResponse {
  /** @format int64 */
  amount?: number | null
  currency?: 'RPX' | 'GPX' | 'BPX' | 'USD' | null
  /** @example "You've eaten a steak, well done!" */
  transactionNote?: string | null
}

/** BO quest response model */
export interface QuestResponse {
  /** @example "eat-a-steak" */
  alias?: string | null
  /**
   * For daily quests only: indicates when the quest started
   *
   * Notes: *Should be ignored for any quests except dailies*
   * @format date-time
   */
  availableSince?: string | null
  callToAction?: StringResponse | null
  callToActionUrl?: string | null
  /** @format int32 */
  collectionIndex?: number | null
  /** @example "https://play.gmx.dev/quests/f2761fbd-52d1-4744-9d58-5d3f6af6a822/covers/3830d077-147a-496d-a930-fc2924f3908e" */
  coverImageUrl?: string | null
  /** @format date-time */
  createdAt?: string | null
  createdBy?: BackOfficeUserResponse | null
  description?: StringResponse | null
  isActive?: boolean | null
  /**
   * For daily quests only: `true` means that the quest is currently available
   *
   * Notes: *Should be ignored for any quests except dailies*
   */
  isAvailable?: boolean | null
  /** Offer code reward details, or `null` if `rewardType` is not `OFFER_CODE` */
  offerCodeReward?: QuestOfferCodeRewardResponse | null
  parameters?: MapStringObject | null
  /** Pixels reward details, or `null` if `rewardType` is not `PIXELS` */
  pixelsReward?: QuestPixelsRewardResponse | null
  questCollection?: QuestCollectionSummaryResponse | null
  /** @example "f2761fbd-52d1-4744-9d58-5d3f6af6a822" */
  questId: string
  /**
   * *Use `rewardType` and `pixelsReward`/`offerCodeReward` instead.*
   * @deprecated
   */
  reward?: QuestPixelsRewardResponse | null
  /** The quest's reward type */
  rewardType?: 'OFFER_CODE' | 'PIXELS' | null
  title?: StringResponse | null
  type?:
    | 'ADD_GAMES_TO_FAVORITES'
    | 'ADD_NEW_FRIENDS'
    | 'BUY_GAMES'
    | 'PARTICIPATE_IN_CHALLENGES'
    | 'PARTNER_QUEST'
    | 'PAY_RESPECT_TO_GAMES'
    | 'PAY_RESPECT_TO_STUDIOS'
    | 'PLAY_GAMES'
    | 'SETUP_CUSTOM_AVATAR'
    | 'SETUP_PROFILE_DESCRIPTION'
    | 'SETUP_BIO_LINKS'
    | 'SPEND_VIRTUAL_CURRENCY'
    | null
}

/** BO quest summary response model */
export interface QuestSummaryResponse {
  /** @example "eat-a-steak" */
  alias?: string | null
  /** @format int32 */
  collectionIndex?: number | null
  isActive?: boolean | null
  /**
   * For daily quests only: `true` means that the quest is currently available
   *
   * Notes: *Should be ignored for any quests except dailies*
   */
  isAvailable?: boolean | null
  /** @example "f2761fbd-52d1-4744-9d58-5d3f6af6a822" */
  questId: string
}

/** BO request for quest update */
export interface QuestUpdateRequest {
  /** Whether the quest is active or not */
  active?: boolean | null
  /**
   * New quest alias
   * @example "do-the-groceries"
   */
  alias?: string | null
  /** New quest reward transaction note */
  bankTransactionNote?: string | null
  /**
   * New URL for the call-to-action.
   *
   * Notes: *The call-to-action title can be updated via `PATCH /bo/strings/{stringId}`*
   */
  callToActionUrl?: string | null
  /** New quest parameters */
  parameters?: MapStringObject | null
  /**
   * Alias of the quest collection this instance will be part of. Changing the assigned quest collection (by either removing the quest or moving it to a different collection) marks the quest as inactive and unavailable. In order to change the collection of an active quest while keeping it active, the request must contain `active=true`, too.
   *
   * Notes: *If missing, quest collection is not changed. If null, this quest instance is removed from its current collection, marked as inactive and reset*
   * @example "default"
   */
  questCollection?: string | null
  /**
   * New quest reward amount
   * @format int64
   */
  rewardAmount?: number | null
  /** New quest reward currency */
  rewardCurrency?: 'RPX' | 'GPX' | 'BPX' | 'USD' | null
}

/** QuestUserOfferCodeRewardResponse */
export interface QuestUserOfferCodeRewardResponse {
  /**
   * The offer code claim, or `null` if no code has been claimed yet
   *
   * Notes: *A code is claimed ASAP after the quest has been completed and an active code is or becomes available to claim.*
   */
  claim?: OfferCodeClaimResponse | null
  /**
   * The offer code claimed by the user, or `null` if none has been claimed yet
   *
   * Notes: *A code is claimed ASAP after the quest has been completed and an active code is or becomes available to claim.*
   */
  code?: OfferCodeResponse | null
  offer?: OfferResponse | null
}

/** QuestUserProgressListResponse */
export interface QuestUserProgressListResponse {
  /** Quests with a user's progress */
  quests?: QuestUserProgressResponse[] | null
}

/** QuestUserProgressListResponseV2 */
export interface QuestUserProgressListResponseV2 {
  /** Quests with a user's progress */
  quests: QuestUserProgressResponseV2[]
}

/** QuestUserProgressResponse */
export interface QuestUserProgressResponse {
  /** Whether the user has completed the quest */
  completed?: boolean | null
  /** The download url of the quest's cover image */
  coverImageUrl?: string | null
  /** The quest description as a localizable string */
  description?: StringResponse | null
  /**
   * The maximum progress one can get for the quest
   * @format int32
   */
  maximumProgress?: number | null
  /**
   * The user's progress for the quest
   * @format int32
   */
  progress?: number | null
  /** ID of the quest */
  questId: string
  /** The type of the quest */
  questType?:
    | 'ADD_GAMES_TO_FAVORITES'
    | 'ADD_NEW_FRIENDS'
    | 'BUY_GAMES'
    | 'PARTICIPATE_IN_CHALLENGES'
    | 'PARTNER_QUEST'
    | 'PAY_RESPECT_TO_GAMES'
    | 'PAY_RESPECT_TO_STUDIOS'
    | 'PLAY_GAMES'
    | 'SETUP_CUSTOM_AVATAR'
    | 'SETUP_PROFILE_DESCRIPTION'
    | 'SETUP_BIO_LINKS'
    | 'SPEND_VIRTUAL_CURRENCY'
    | null
  /**
   * The amount of the reward
   * @format int64
   */
  rewardAmount?: number | null
  /** The currency of the reward */
  rewardCurrency?: 'RPX' | 'GPX' | 'BPX' | 'USD' | null
  /** The quest title as a localizable string */
  title?: StringResponse | null
}

/** QuestUserProgressResponseV2 */
export interface QuestUserProgressResponseV2 {
  /** Alias of the quest */
  alias: string
  /** Whether the user has completed the quest */
  completed: boolean
  /** The download url of the quest's cover image */
  coverImageUrl: string
  /** The quest description as a localizable string */
  description: StringResponse
  /**
   * The maximum progress one can get for the quest
   * @format int32
   */
  maximumProgress: number
  /** Offer code reward details, or `null` if `rewardType` is not `OFFER_CODE` */
  offerCodeReward: QuestUserOfferCodeRewardResponse
  /** Pixels reward details, or `null` if `rewardType` is not `PIXELS` */
  pixelsReward: QuestPixelsRewardResponse
  /**
   * The user's progress for the quest
   * @format int32
   */
  progress: number
  /** ID of the quest */
  questId: string
  /** The type of the quest */
  questType:
    | 'ADD_GAMES_TO_FAVORITES'
    | 'ADD_NEW_FRIENDS'
    | 'BUY_GAMES'
    | 'PARTICIPATE_IN_CHALLENGES'
    | 'PARTNER_QUEST'
    | 'PAY_RESPECT_TO_GAMES'
    | 'PAY_RESPECT_TO_STUDIOS'
    | 'PLAY_GAMES'
    | 'SETUP_CUSTOM_AVATAR'
    | 'SETUP_PROFILE_DESCRIPTION'
    | 'SETUP_BIO_LINKS'
    | 'SPEND_VIRTUAL_CURRENCY'
  /** The quest's reward type */
  rewardType: 'OFFER_CODE' | 'PIXELS'
  /** The quest title as a localizable string */
  title: StringResponse
}

export type RecalculateGamePlayStatisticsData = ResponseString

export type RecalculateGamePlayStatisticsError = PatchGamePlayStatistics403ErrorResponse

export type RecalculateNumberOfEntitiesData = ResponseVoid

export type RecalculateNumberOfEntitiesError = PostTagsRecalculateNumberOfEntities403ErrorResponse

export type RecalculateUserChallengePlayStatisticsData = ResponseString

export type RecalculateUserChallengePlayStatisticsError =
  PatchUserChallengePlayStatistics403ErrorResponse

export type RecalculateUserGamePlayStatisticsData = ResponseString

export type RecalculateUserGamePlayStatisticsError = PatchUserGamePlayStatistics403ErrorResponse

export type RecordContentsData = ResponseReprocessedModReleasesResponse

export type RecordContentsError =
  | PostModsRecordReleaseContents400ErrorResponse
  | PostModsRecordReleaseContents403ErrorResponse

export interface RecordContentsParams {
  /**
   * The page to return. The response will contain mod releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of mod releases to include in the response. Fewer mod releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
  /** Reprocessing scope */
  scope: string
}

export type RecordModFlavorSizesData = ResponseRecordModFlavorSizesResponse

export type RecordModFlavorSizesError =
  | PostMigrationModsFlavorsRecordSizes400ErrorResponse
  | PostMigrationModsFlavorsRecordSizes403ErrorResponse

export interface RecordModFlavorSizesParams {
  /**
   * The page to return. The response will contain Mod flavors in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of Mod flavors to include in the response. Fewer Mod flavors may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** RecordModFlavorSizesResponse */
export interface RecordModFlavorSizesResponse {
  pagination: PaginationResponse
}

export type RefreshSnapshotData = ResponseVoid

export type RefreshSnapshotError =
  | PostFeedsFeedIdRefreshSnapshot400ErrorResponse
  | PostFeedsFeedIdRefreshSnapshot403ErrorResponse
  | PostFeedsFeedIdRefreshSnapshot404ErrorResponse

/** RefundResponse */
export interface RefundResponse {
  /**
   * Notes: *Amount refunded to the user.*
   * @example 10.99
   */
  amount?: number | null
  /** @example "backend.developer@opera.com" */
  author?: string | null
  /**
   * Notes: *Xsolla refund codes.*
   * @format int64
   * @example 10
   */
  code?: number | null
  /**
   * Notes: *Three-letter currency code per ISO 4217.*
   * @example "EUR"
   */
  currency?: string | null
  /**
   * @format date-time
   * @example "2021-04-17T18:03:11.431Z"
   */
  date?: string | null
  gameItem?: GameItemResponse | null
  /**
   * Notes: *Original transaction ID for a refund. Related to Xsolla only.*
   * @example "g15adb25-48ba-91a1-531d-v1f22745876e"
   */
  paymentTransactionId?: string | null
  /** @example "Cancellation requested by the game." */
  reason?: string | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  refundId: string
  /**
   * Notes: *Refund id on Payment Provider side.*
   * @example "722408720780275002"
   */
  refundProviderId?: string | null
  transaction?: TransactionResponse | null
  /** @example "FULL" */
  type?: 'FULL' | null
  user?: UserSummaryResponse | null
}

/** BO paginated list of refund ids */
export interface RefundsIdsResponse {
  pagination: PaginationResponse
  refundIds: string[]
}

/** RefundsResponse */
export interface RefundsResponse {
  pagination: PaginationResponse
  refunds: RefundResponse[]
}

/** Releasable */
export interface Releasable {
  accesses: ReleasableAccess[]
  archived: boolean
  blocked: boolean
  covers: string[]
  /** @format date-time */
  createdAt: string
  discoverable: boolean
  gmProjectId: string
  graphics: string[]
  /** @format date-time */
  lastModified: string
  releasableId: string
  releasableType: 'EXTENSION' | 'GAME' | 'MOD'
  releaseTracks: string[]
  reviewRequests: string[]
  sortedGraphics?: StreamReleasableAsset
  store: 'GX_GAMES' | 'OPERA_GX_MODS' | 'OPERA_ONE_THEMES' | 'OPERA_EXTENSIONS'
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  studio: string
  studioId: string
  title: string
}

/** ReleasableAccess */
export interface ReleasableAccess {
  releasable: Releasable
  releasableId: string
  studioId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  user: string
  userId: string
}

export type RemoveCoverData = ResponseVoid

export type RemoveCoverError =
  | DeleteStudiosStudioIdCoversCoverId400ErrorResponse
  | DeleteStudiosStudioIdCoversCoverId403ErrorResponse
  | DeleteStudiosStudioIdCoversCoverId404ErrorResponse

export type RemoveFeedEntryData = ResponseManagedFeedDetailsResponse

export type RemoveFeedEntryError =
  | DeleteFeedsFeedIdEntriesEntryId403ErrorResponse
  | DeleteFeedsFeedIdEntriesEntryId404ErrorResponse

export type RemoveFromModData = ResponseVoid

export type RemoveFromModError =
  | DeleteOdksKeyIdModsModId400ErrorResponse
  | DeleteOdksKeyIdModsModId403ErrorResponse
  | DeleteOdksKeyIdModsModId404ErrorResponse

export type RemoveFromStudioData = ResponseVoid

export type RemoveFromStudioError =
  | DeleteOdksKeyIdStudiosStudioId400ErrorResponse
  | DeleteOdksKeyIdStudiosStudioId403ErrorResponse
  | DeleteOdksKeyIdStudiosStudioId404ErrorResponse

export type RemoveGameData = ResponseVoid

export type RemoveGameError =
  | DeleteTagsTagIdGamesGameId400ErrorResponse
  | DeleteTagsTagIdGamesGameId403ErrorResponse
  | DeleteTagsTagIdGamesGameId404ErrorResponse

export type RemoveGmsRunnersData = ResponseVoid

export type RemoveGmsRunnersError = DeleteRunnersCleanup403ErrorResponse

export type RemoveModData = ResponseModDetailsResponse

export type RemoveModError =
  | DeleteTagsTagIdModsModId400ErrorResponse
  | DeleteTagsTagIdModsModId403ErrorResponse
  | DeleteTagsTagIdModsModId404ErrorResponse

export type ReorderStickersData = ResponseStickerListResponse

export type ReorderStickersError =
  | PatchStickers400ErrorResponse
  | PatchStickers403ErrorResponse
  | PatchStickers404ErrorResponse

/** ReorderStickersRequest */
export interface ReorderStickersRequest {
  /** List of sticker IDs in the new order */
  stickerIds: string[]
}

/** BO reports by reason response */
export interface ReportsByReasonResponse {
  /**
   * @format int32
   * @example 3
   */
  miscategorizedCount?: number | null
  /**
   * @format int32
   * @example 2
   */
  offensiveMaterialCount?: number | null
  /**
   * @format int32
   * @example 4
   */
  otherCount?: number | null
  /**
   * @format int32
   * @example 4
   */
  reconsiderationCount?: number | null
  /**
   * @format int32
   * @example 1
   */
  spamCount?: number | null
  /**
   * Number of all reports regardless of reason.
   * @format int32
   * @example 15
   */
  totalReports?: number | null
  /**
   * @format int32
   * @example 5
   */
  unauthorizedDistributionCount?: number | null
}

export type ReprocessCurrentReleasesData = ResponseInteger

export type ReprocessCurrentReleasesError = PostGamesReprocessCurrentReleases403ErrorResponse

export type ReprocessCurrentReleasesFail = PostModsReprocessCurrentReleases403ErrorResponse

export interface ReprocessCurrentReleasesParams {
  /** Reprocessing scope */
  scope: string
}

export interface ReprocessCurrentReleasesParams2 {
  /** Reprocess only releases with given mod type */
  modType?:
    | 'APP_ICON'
    | 'BACKGROUND_MUSIC'
    | 'BROWSER_SOUNDS'
    | 'FONTS'
    | 'IMAGE_OVERRIDES'
    | 'KEYBOARD_SOUNDS'
    | 'MOBILE_IMAGE_OVERRIDES'
    | 'PAGE_STYLE'
    | 'SD_EFFECTS'
    | 'SHADER'
    | 'SPLASH_SCREEN'
    | 'STICKERS'
    | 'THEME'
    | 'WALLPAPER'
    | 'GAME_STRIP'
  /** Reprocessing scope */
  scope: string
  /** Reprocess only releases with given wallpaper type */
  wallpaperType?: 'IMAGE' | 'MOBILE_IMAGE' | 'VIDEO' | 'LIVE' | 'COLORIZED'
}

export type ReprocessCurrentReleasesResult = ResponseInteger

export type ReprocessGamesData = ResponseInteger

export type ReprocessGamesError = PostGamesReprocess403ErrorResponse

export interface ReprocessGamesParams {
  /** Reprocessing scope */
  scope: string
}

export type ReprocessModWrappersData = ResponseInteger

export type ReprocessModWrappersError = PostWrappersReprocessModWrappers403ErrorResponse

export interface ReprocessModWrappersParams {
  /** Reprocessing scope */
  scope: string
}

export type ReprocessModsData = ResponseInteger

export type ReprocessModsError = PostModsReprocess403ErrorResponse

export interface ReprocessModsParams {
  /** Reprocessing scope */
  scope: string
}

/** ReprocessedGameReleaseResponse */
export interface ReprocessedGameReleaseResponse {
  changes?: MapStringObject | null
  errors?: ErrorResponse[] | null
  gameId: string
  releaseId: string
}

/** ReprocessedGameReleasesResponse */
export interface ReprocessedGameReleasesResponse {
  pagination: PaginationResponse
  releases: ReprocessedGameReleaseResponse[]
}

/** ReprocessedGameResponse */
export interface ReprocessedGameResponse {
  changes?: MapStringObject | null
  errors?: ErrorResponse[] | null
  gameId: string
}

/** ReprocessedGamesResponse */
export interface ReprocessedGamesResponse {
  games: ReprocessedGameResponse[]
  pagination: PaginationResponse
}

/** ReprocessedModReleaseResponse */
export interface ReprocessedModReleaseResponse {
  changes?: MapStringObject | null
  errors?: ErrorResponse[] | null
  modId: string
  releaseId: string
}

/** ReprocessedModReleasesResponse */
export interface ReprocessedModReleasesResponse {
  pagination: PaginationResponse
  releases: ReprocessedModReleaseResponse[]
}

/** ReprocessedModResponse */
export interface ReprocessedModResponse {
  changes?: MapStringObject | null
  errors?: ErrorResponse[] | null
  modId: string
}

/** ReprocessedModsResponse */
export interface ReprocessedModsResponse {
  mods: ReprocessedModResponse[]
  pagination: PaginationResponse
}

/** ReprocessedWrapperResponse */
export interface ReprocessedWrapperResponse {
  changes?: MapStringObject | null
  errors?: ErrorResponse[] | null
  wrapperId: string
}

/** ReprocessedWrappersResponse */
export interface ReprocessedWrappersResponse {
  pagination: PaginationResponse
  wrappers: ReprocessedWrapperResponse[]
}

export type RequestEventsData = ResponseEventsRequestedResponse

export type RequestEventsError = PostStatsApiRequestEvents403ErrorResponse

export interface RequestEventsParams {
  /**
   * Entity id (prefix)
   * @default ""
   */
  entityId?: string
  /** Entity type */
  entityType:
    | 'CHALLENGE'
    | 'CMS_CONFIGURATION'
    | 'CMS_SECTION'
    | 'GAME'
    | 'MANAGED_FEED'
    | 'MOD'
    | 'MOD_RELEASE'
    | 'MOD_RELEASE_TRACK'
    | 'STUDIO'
    | 'USER'
}

export type RescheduleData = ResponseScheduledTaskResponse

export type RescheduleError =
  | PostScheduledTasksReschedule400ErrorResponse
  | PostScheduledTasksReschedule403ErrorResponse
  | PostScheduledTasksReschedule404ErrorResponse

export interface RescheduleParams {
  /**
   * Reschedule the task even if it is currently locked
   *
   * Allowed boolean values: `true`, `false`, `yes`, `no`
   * @default false
   */
  force?: boolean
  /** Type of task to reschedule */
  taskType: string
}

export type ResetQuestData = ResponseVoid

export type ResetQuestError =
  | PostQuestsQuestIdReset403ErrorResponse
  | PostQuestsQuestIdReset404ErrorResponse

export type ResolveAlertData = ResponseSystemAlertResponse

export type ResolveAlertError =
  | PatchAlertsAlertId400ErrorResponse
  | PatchAlertsAlertId403ErrorResponse
  | PatchAlertsAlertId404ErrorResponse

export type ResolveDisputeData = ResponseDisputeResponse

export type ResolveDisputeError =
  | PatchPaymentsPaymentIdDisputesDisputeIdResolve400ErrorResponse
  | PatchPaymentsPaymentIdDisputesDisputeIdResolve403ErrorResponse
  | PatchPaymentsPaymentIdDisputesDisputeIdResolve404ErrorResponse

export type ResolveGameTransferData = ResponseGameTransferResponse

export type ResolveGameTransferError =
  | PatchGameTransfersTransferId400ErrorResponse
  | PatchGameTransfersTransferId403ErrorResponse
  | PatchGameTransfersTransferId404ErrorResponse

export type ResolveModTransferData = ResponseModTransferResponse

export type ResolveModTransferError =
  | PatchModTransfersTransferId400ErrorResponse
  | PatchModTransfersTransferId403ErrorResponse
  | PatchModTransfersTransferId404ErrorResponse

/** RespectPaymentResponse */
export interface RespectPaymentResponse {
  /**
   * When the respect record was created
   * @format date-time
   */
  creationDate?: string | null
  /** Game that received respect */
  game?: GameResponse | null
  /** The ID of the respect payment */
  paymentId: string
  /** Sticker respect was paid with */
  sticker?: StickerResponse | null
  /** Studio that game belonged to when it received respect */
  studio?: StudioResponse | null
  /** User who paid respect */
  user?: UserSummaryResponse | null
}

/** RespectPaymentSummaryResponse */
export interface RespectPaymentSummaryResponse {
  /**
   * The respect payment amount
   * @format int32
   */
  amount?: number | null
  /**
   * When the respect record was created
   * @format date-time
   */
  creationDate?: string | null
  /** The currency of respect payment */
  currency?: string | null
  /**
   * The number of stickers in respect payment
   * @format int32
   */
  numberOfStickers?: number | null
  /** The ID of the respect payment */
  paymentId: string
}

/** RespectPaymentsResponse */
export interface RespectPaymentsResponse {
  pagination: PaginationResponse
  respectPayments: RespectPaymentResponse[]
}

/** Response<ApiClientResponse> */
export interface ResponseApiClientResponse {
  data: ApiClientResponse
  errors: ErrorResponse[]
}

/** Response<ApiClientsResponse> */
export interface ResponseApiClientsResponse {
  data: ApiClientsResponse
  errors: ErrorResponse[]
}

/** Response<AssetVariantConfigurationResponse> */
export interface ResponseAssetVariantConfigurationResponse {
  data: AssetVariantConfigurationResponse
  errors: ErrorResponse[]
}

/** Response<AssetVariantConfigurationsResponse> */
export interface ResponseAssetVariantConfigurationsResponse {
  data: AssetVariantConfigurationsResponse
  errors: ErrorResponse[]
}

/** Response<AssetVariantsProfilesResponse> */
export interface ResponseAssetVariantsProfilesResponse {
  data: AssetVariantsProfilesResponse
  errors: ErrorResponse[]
}

/** Response<AvailableLegacyStudioSettingsResponse> */
export interface ResponseAvailableLegacyStudioSettingsResponse {
  data: {
    empty?: boolean
    first?: LegacyStudioSettingResponse
    last?: LegacyStudioSettingResponse
  }
  errors: ErrorResponse[]
}

/** Response<BackOfficeUserResponse> */
export interface ResponseBackOfficeUserResponse {
  data: BackOfficeUserResponse
  errors: ErrorResponse[]
}

/** Response<BackOfficeUsersResponse> */
export interface ResponseBackOfficeUsersResponse {
  data: BackOfficeUsersResponse
  errors: ErrorResponse[]
}

/** Response<BackendHostResponse> */
export interface ResponseBackendHostResponse {
  data: BackendHostResponse
  errors: ErrorResponse[]
}

/** Response<BackendHostsResponse> */
export interface ResponseBackendHostsResponse {
  data: BackendHostsResponse
  errors: ErrorResponse[]
}

/** Response<BackofficeStripeConnectAccountDetailsResponse> */
export interface ResponseBackofficeStripeConnectAccountDetailsResponse {
  data: BackofficeStripeConnectAccountDetailsResponse
  errors: ErrorResponse[]
}

/** Response<BankAccountResponse> */
export interface ResponseBankAccountResponse {
  data: BankAccountResponse
  errors: ErrorResponse[]
}

/** Response<BankAccountsResponse> */
export interface ResponseBankAccountsResponse {
  data: BankAccountsResponse
  errors: ErrorResponse[]
}

/** Response<BankTransactionResponse> */
export interface ResponseBankTransactionResponse {
  data: BankTransactionResponse
  errors: ErrorResponse[]
}

/** Response<BankTransactionsResponse> */
export interface ResponseBankTransactionsResponse {
  data: BankTransactionsResponse
  errors: ErrorResponse[]
}

/** Response<BioLinkResponse> */
export interface ResponseBioLinkResponse {
  data: BioLinkResponse
  errors: ErrorResponse[]
}

/** Response<BioLinksResponse> */
export interface ResponseBioLinksResponse {
  data: BioLinksResponse
  errors: ErrorResponse[]
}

/** Response<Boolean> */
export interface ResponseBoolean {
  data: boolean
  errors: ErrorResponse[]
}

/** Response<CdnHostingsResponse> */
export interface ResponseCdnHostingsResponse {
  data: CdnHostingsResponse
  errors: ErrorResponse[]
}

/** Response<ChallengeResponse> */
export interface ResponseChallengeResponse {
  data: ChallengeResponse
  errors: ErrorResponse[]
}

/** Response<ChallengeSettingResponse> */
export interface ResponseChallengeSettingResponse {
  data: ChallengeSettingResponse
  errors: ErrorResponse[]
}

/** Response<ChallengeShortIDsGenerationResponse> */
export interface ResponseChallengeShortIDsGenerationResponse {
  data: ChallengeShortIDsGenerationResponse
  errors: ErrorResponse[]
}

/** Response<ChallengesResponse> */
export interface ResponseChallengesResponse {
  data: ChallengesResponse
  errors: ErrorResponse[]
}

/** Response<ClusterStatusResponse> */
export interface ResponseClusterStatusResponse {
  data: ClusterStatusResponse
  errors: ErrorResponse[]
}

/** Response<CmsConfigurationResponse> */
export interface ResponseCmsConfigurationResponse {
  data: CmsConfigurationResponse
  errors: ErrorResponse[]
}

/** Response<CmsConfigurationsResponse> */
export interface ResponseCmsConfigurationsResponse {
  data: CmsConfigurationsResponse
  errors: ErrorResponse[]
}

/** Response<CmsSectionResponse> */
export interface ResponseCmsSectionResponse {
  data: CmsSectionResponse
  errors: ErrorResponse[]
}

/** Response<CmsSectionsResponse> */
export interface ResponseCmsSectionsResponse {
  data: CmsSectionsResponse
  errors: ErrorResponse[]
}

/** Response<CodeRedemptionImportResponse> */
export interface ResponseCodeRedemptionImportResponse {
  data: CodeRedemptionImportResponse
  errors: ErrorResponse[]
}

/** Response<CoverResponse> */
export interface ResponseCoverResponse {
  data: CoverResponse
  errors: ErrorResponse[]
}

/** Response<CurrenciesResponse> */
export interface ResponseCurrenciesResponse {
  data: CurrenciesResponse
  errors: ErrorResponse[]
}

/** Response<DefaultTopicConvertedEntriesResponse> */
export interface ResponseDefaultTopicConvertedEntriesResponse {
  data: DefaultTopicConvertedEntriesResponse
  errors: ErrorResponse[]
}

/** Response<DeleteModFlavorsResponse> */
export interface ResponseDeleteModFlavorsResponse {
  data: DeleteModFlavorsResponse
  errors: ErrorResponse[]
}

/** Response<DisputeResponse> */
export interface ResponseDisputeResponse {
  data: DisputeResponse
  errors: ErrorResponse[]
}

/** Response<DisputesResponse> */
export interface ResponseDisputesResponse {
  data: DisputesResponse
  errors: ErrorResponse[]
}

/** Response<DynamicConfigClientDetailsResponse> */
export interface ResponseDynamicConfigClientDetailsResponse {
  data: DynamicConfigClientDetailsResponse
  errors: ErrorResponse[]
}

/** Response<DynamicConfigResponse> */
export interface ResponseDynamicConfigResponse {
  data: DynamicConfigResponse
  errors: ErrorResponse[]
}

/** Response<DynamicConfigsResponse> */
export interface ResponseDynamicConfigsResponse {
  data: DynamicConfigsResponse
  errors: ErrorResponse[]
}

/** Response<EmailTopicsResponse> */
export interface ResponseEmailTopicsResponse {
  data: EmailTopicsResponse
  errors: ErrorResponse[]
}

/** Response<EventsRequestedResponse> */
export interface ResponseEventsRequestedResponse {
  data: EventsRequestedResponse
  errors: ErrorResponse[]
}

/** Response<EventsSentResponse> */
export interface ResponseEventsSentResponse {
  data: EventsSentResponse
  errors: ErrorResponse[]
}

/** Response<FeedEntriesResponse> */
export interface ResponseFeedEntriesResponse {
  data: FeedEntriesResponse
  errors: ErrorResponse[]
}

/** Response<FeedEntryDetailsResponse> */
export interface ResponseFeedEntryDetailsResponse {
  data: FeedEntryDetailsResponse
  errors: ErrorResponse[]
}

/** Response<FundingSubscriberResponse> */
export interface ResponseFundingSubscriberResponse {
  data: FundingSubscriberResponse
  errors: ErrorResponse[]
}

/** Response<FundingSubscribersResponse> */
export interface ResponseFundingSubscribersResponse {
  data: FundingSubscribersResponse
  errors: ErrorResponse[]
}

/** Response<GameBannerResponse> */
export interface ResponseGameBannerResponse {
  data: GameBannerResponse
  errors: ErrorResponse[]
}

/** Response<GameBannersResponse> */
export interface ResponseGameBannersResponse {
  data: GameBannersResponse
  errors: ErrorResponse[]
}

/** Response<GameChallengesResponse> */
export interface ResponseGameChallengesResponse {
  data: GameChallengesResponse
  errors: ErrorResponse[]
}

/** Response<GameControlGroupResponse> */
export interface ResponseGameControlGroupResponse {
  data: GameControlGroupResponse
  errors: ErrorResponse[]
}

/** Response<GameControlResponse> */
export interface ResponseGameControlResponse {
  data: GameControlResponse
  errors: ErrorResponse[]
}

/** Response<GameControlSubgroupResponse> */
export interface ResponseGameControlSubgroupResponse {
  data: GameControlSubgroupResponse
  errors: ErrorResponse[]
}

/** Response<GameEngineDetailsResponse> */
export interface ResponseGameEngineDetailsResponse {
  data: GameEngineDetailsResponse
  errors: ErrorResponse[]
}

/** Response<GameEnginesResponse> */
export interface ResponseGameEnginesResponse {
  data: GameEnginesResponse
  errors: ErrorResponse[]
}

/** Response<GameItemClaimPeriodResponse> */
export interface ResponseGameItemClaimPeriodResponse {
  data: GameItemClaimPeriodResponse
  errors: ErrorResponse[]
}

/** Response<GameItemClaimPeriodsResponse> */
export interface ResponseGameItemClaimPeriodsResponse {
  data: GameItemClaimPeriodsResponse
  errors: ErrorResponse[]
}

/** Response<GameItemResponse> */
export interface ResponseGameItemResponse {
  data: GameItemResponse
  errors: ErrorResponse[]
}

/** Response<GameItemsResponse> */
export interface ResponseGameItemsResponse {
  data: GameItemsResponse
  errors: ErrorResponse[]
}

/** Response<GamePlayStatisticsResponse> */
export interface ResponseGamePlayStatisticsResponse {
  data: GamePlayStatisticsResponse
  errors: ErrorResponse[]
}

/** Response<GamePurchaseDetailedResponse> */
export interface ResponseGamePurchaseDetailedResponse {
  data: GamePurchaseDetailedResponse
  errors: ErrorResponse[]
}

/** Response<GamePurchasesDetailedResponse> */
export interface ResponseGamePurchasesDetailedResponse {
  data: GamePurchasesDetailedResponse
  errors: ErrorResponse[]
}

/** Response<GameReleaseDateUpdateResponse> */
export interface ResponseGameReleaseDateUpdateResponse {
  data: GameReleaseDateUpdateResponse
  errors: ErrorResponse[]
}

/** Response<GameReleaseTrackResponse> */
export interface ResponseGameReleaseTrackResponse {
  data: GameReleaseTrackResponse
  errors: ErrorResponse[]
}

/** Response<GameReleaseTracksPageableResponse> */
export interface ResponseGameReleaseTracksPageableResponse {
  data: GameReleaseTracksPageableResponse
  errors: ErrorResponse[]
}

/** Response<GameReleasesResponse> */
export interface ResponseGameReleasesResponse {
  data: GameReleasesResponse
  errors: ErrorResponse[]
}

/** Response<GameResponse> */
export interface ResponseGameResponse {
  data: GameResponse
  errors: ErrorResponse[]
}

/** Response<GameReviewGamesResponse> */
export interface ResponseGameReviewGamesResponse {
  data: GameReviewGamesResponse
  errors: ErrorResponse[]
}

/** Response<GameReviewRequestsResponse> */
export interface ResponseGameReviewRequestsResponse {
  data: GameReviewRequestsResponse
  errors: ErrorResponse[]
}

/** Response<GameReviewReviewsResponse> */
export interface ResponseGameReviewReviewsResponse {
  data: GameReviewReviewsResponse
  errors: ErrorResponse[]
}

/** Response<GameShortIdsGenerationResponse> */
export interface ResponseGameShortIdsGenerationResponse {
  data: GameShortIdsGenerationResponse
  errors: ErrorResponse[]
}

/** Response<GameStatisticsDataPointsResponse> */
export interface ResponseGameStatisticsDataPointsResponse {
  data: GameStatisticsDataPointsResponse
  errors: ErrorResponse[]
}

/** Response<GameTransferResponse> */
export interface ResponseGameTransferResponse {
  data: GameTransferResponse
  errors: ErrorResponse[]
}

/** Response<GameTransfersResponse> */
export interface ResponseGameTransfersResponse {
  data: GameTransfersResponse
  errors: ErrorResponse[]
}

/** Response<GameWrapperResponse> */
export interface ResponseGameWrapperResponse {
  data: GameWrapperResponse
  errors: ErrorResponse[]
}

/** Response<GameWrappersResponse> */
export interface ResponseGameWrappersResponse {
  data: GameWrappersResponse
  errors: ErrorResponse[]
}

/** Response<GamesResponse> */
export interface ResponseGamesResponse {
  data: GamesResponse
  errors: ErrorResponse[]
}

/** Response<GmsRunnerResponse> */
export interface ResponseGmsRunnerResponse {
  data: GmsRunnerResponse
  errors: ErrorResponse[]
}

/** Response<GmsRunnersResponse> */
export interface ResponseGmsRunnersResponse {
  data: GmsRunnersResponse
  errors: ErrorResponse[]
}

/** Response<Integer> */
export interface ResponseInteger {
  /** @format int32 */
  data: number
  errors: ErrorResponse[]
}

/** Response<LWThemesSettingsResponse> */
export interface ResponseLWThemesSettingsResponse {
  data: LWThemesSettingsResponse
  errors: ErrorResponse[]
}

/** Response<LegacySettingResponse> */
export interface ResponseLegacySettingResponse {
  data: LegacySettingResponse
  errors: ErrorResponse[]
}

/** Response<LegacyStudioSettingsListResponse> */
export interface ResponseLegacyStudioSettingsListResponse {
  data: LegacyStudioSettingsListResponse
  errors: ErrorResponse[]
}

/** Response<LegacyStudioSettingsResponse> */
export interface ResponseLegacyStudioSettingsResponse {
  data: LegacyStudioSettingsResponse
  errors: ErrorResponse[]
}

/** Response<ListGameControlGroupsResponse> */
export interface ResponseListGameControlGroupsResponse {
  data: ListGameControlGroupsResponse
  errors: ErrorResponse[]
}

/** Response<List<LegacySettingResponse>> */
export interface ResponseListLegacySettingResponse {
  data: LegacySettingResponse[]
  errors: ErrorResponse[]
}

/** Response<ListPublishersResponse> */
export interface ResponseListPublishersResponse {
  data: ListPublishersResponse
  errors: ErrorResponse[]
}

/** Response<List<String>> */
export interface ResponseListString {
  data: string[]
  errors: ErrorResponse[]
}

/** Response<List<TagSummaryResponse>> */
export interface ResponseListTagSummaryResponse {
  data: TagSummaryResponse[]
  errors: ErrorResponse[]
}

/** Response<List<WaitlistTopicResponse>> */
export interface ResponseListWaitlistTopicResponse {
  data: WaitlistTopicResponse[]
  errors: ErrorResponse[]
}

/** Response<LocalesResponse> */
export interface ResponseLocalesResponse {
  data: LocalesResponse
  errors: ErrorResponse[]
}

/** Response<ManagedFeedDetailsResponse> */
export interface ResponseManagedFeedDetailsResponse {
  data: ManagedFeedDetailsResponse
  errors: ErrorResponse[]
}

/** Response<ManagedFeedsResponse> */
export interface ResponseManagedFeedsResponse {
  data: ManagedFeedsResponse
  errors: ErrorResponse[]
}

/** Response<MediaResponse> */
export interface ResponseMediaResponse {
  data: MediaResponse
  errors: ErrorResponse[]
}

/** Response<ModDetailsResponse> */
export interface ResponseModDetailsResponse {
  data: ModDetailsResponse
  errors: ErrorResponse[]
}

/** Response<ModFlavorListResponse> */
export interface ResponseModFlavorListResponse {
  data: ModFlavorListResponse
  errors: ErrorResponse[]
}

/** Response<ModOdkResponse> */
export interface ResponseModOdkResponse {
  data: ModOdkResponse
  errors: ErrorResponse[]
}

/** Response<ModReleaseDetailsResponse> */
export interface ResponseModReleaseDetailsResponse {
  data: ModReleaseDetailsResponse
  errors: ErrorResponse[]
}

/** Response<ModReleasesDetailsResponse> */
export interface ResponseModReleasesDetailsResponse {
  data: ModReleasesDetailsResponse
  errors: ErrorResponse[]
}

/** Response<ModReleasesResponse> */
export interface ResponseModReleasesResponse {
  data: ModReleasesResponse
  errors: ErrorResponse[]
}

/** Response<ModReviewRequestsResponse> */
export interface ResponseModReviewRequestsResponse {
  data: ModReviewRequestsResponse
  errors: ErrorResponse[]
}

/** Response<ModReviewsResponse> */
export interface ResponseModReviewsResponse {
  data: ModReviewsResponse
  errors: ErrorResponse[]
}

/** Response<ModTransferResponse> */
export interface ResponseModTransferResponse {
  data: ModTransferResponse
  errors: ErrorResponse[]
}

/** Response<ModTransfersResponse> */
export interface ResponseModTransfersResponse {
  data: ModTransfersResponse
  errors: ErrorResponse[]
}

/** Response<ModUserFeedbackListResponse> */
export interface ResponseModUserFeedbackListResponse {
  data: ModUserFeedbackListResponse
  errors: ErrorResponse[]
}

/** Response<ModsResponse> */
export interface ResponseModsResponse {
  data: ModsResponse
  errors: ErrorResponse[]
}

/** Response<MultiplayerGuestAvatarResponse> */
export interface ResponseMultiplayerGuestAvatarResponse {
  data: MultiplayerGuestAvatarResponse
  errors: ErrorResponse[]
}

/** Response<MultiplayerGuestAvatarsResponse> */
export interface ResponseMultiplayerGuestAvatarsResponse {
  data: MultiplayerGuestAvatarsResponse
  errors: ErrorResponse[]
}

/** Response<MultiplayerRelaysResponse> */
export interface ResponseMultiplayerRelaysResponse {
  data: MultiplayerRelaysResponse
  errors: ErrorResponse[]
}

/** Response<OdkDetailsResponse> */
export interface ResponseOdkDetailsResponse {
  data: OdkDetailsResponse
  errors: ErrorResponse[]
}

/** Response<OdksResponse> */
export interface ResponseOdksResponse {
  data: OdksResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodeClaimResponse> */
export interface ResponseOfferCodeClaimResponse {
  data: OfferCodeClaimResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodeDetailsResponse> */
export interface ResponseOfferCodeDetailsResponse {
  data: OfferCodeDetailsResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodeImportResponse> */
export interface ResponseOfferCodeImportResponse {
  data: OfferCodeImportResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodeUnclaimResponse> */
export interface ResponseOfferCodeUnclaimResponse {
  data: OfferCodeUnclaimResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodesBulkUpdateResponse> */
export interface ResponseOfferCodesBulkUpdateResponse {
  data: OfferCodesBulkUpdateResponse
  errors: ErrorResponse[]
}

/** Response<OfferCodesResponse> */
export interface ResponseOfferCodesResponse {
  data: OfferCodesResponse
  errors: ErrorResponse[]
}

/** Response<OfferErrorPageResponse> */
export interface ResponseOfferErrorPageResponse {
  data: OfferErrorPageResponse
  errors: ErrorResponse[]
}

/** Response<OfferErrorPagesResponse> */
export interface ResponseOfferErrorPagesResponse {
  data: OfferErrorPagesResponse
  errors: ErrorResponse[]
}

/** Response<OfferResponse> */
export interface ResponseOfferResponse {
  data: OfferResponse
  errors: ErrorResponse[]
}

/** Response<OffersResponse> */
export interface ResponseOffersResponse {
  data: OffersResponse
  errors: ErrorResponse[]
}

/** Response<PaginatedMigrationResponse> */
export interface ResponsePaginatedMigrationResponse {
  data: PaginatedMigrationResponse
  errors: ErrorResponse[]
}

/** Response<PartnerResponse> */
export interface ResponsePartnerResponse {
  data: PartnerResponse
  errors: ErrorResponse[]
}

/** Response<PartnersResponse> */
export interface ResponsePartnersResponse {
  data: PartnersResponse
  errors: ErrorResponse[]
}

/** Response<PaymentsIdsResponse> */
export interface ResponsePaymentsIdsResponse {
  data: PaymentsIdsResponse
  errors: ErrorResponse[]
}

/** Response<PointsTransactionResponse> */
export interface ResponsePointsTransactionResponse {
  data: PointsTransactionResponse
  errors: ErrorResponse[]
}

/** Response<PointsTransactionsResponse> */
export interface ResponsePointsTransactionsResponse {
  data: PointsTransactionsResponse
  errors: ErrorResponse[]
}

/** Response<ProfileResponse> */
export interface ResponseProfileResponse {
  data: ProfileResponse
  errors: ErrorResponse[]
}

/** Response<PromotedChallengeResponse> */
export interface ResponsePromotedChallengeResponse {
  data: PromotedChallengeResponse
  errors: ErrorResponse[]
}

/** Response<PromotedChallengesResponse> */
export interface ResponsePromotedChallengesResponse {
  data: PromotedChallengesResponse
  errors: ErrorResponse[]
}

/** Response<PublisherDetailsResponse> */
export interface ResponsePublisherDetailsResponse {
  data: PublisherDetailsResponse
  errors: ErrorResponse[]
}

/** Response<PublisherResponse> */
export interface ResponsePublisherResponse {
  data: PublisherResponse
  errors: ErrorResponse[]
}

/** Response<QuestCollectionResponse> */
export interface ResponseQuestCollectionResponse {
  data: QuestCollectionResponse
  errors: ErrorResponse[]
}

/** Response<QuestCollectionsResponse> */
export interface ResponseQuestCollectionsResponse {
  data: QuestCollectionsResponse
  errors: ErrorResponse[]
}

/** Response<QuestListResponse> */
export interface ResponseQuestListResponse {
  data: QuestListResponse
  errors: ErrorResponse[]
}

/** Response<QuestResponse> */
export interface ResponseQuestResponse {
  data: QuestResponse
  errors: ErrorResponse[]
}

/** Response<QuestUserProgressListResponse> */
export interface ResponseQuestUserProgressListResponse {
  data: QuestUserProgressListResponse
  errors: ErrorResponse[]
}

/** Response<QuestUserProgressListResponseV2> */
export interface ResponseQuestUserProgressListResponseV2 {
  data: QuestUserProgressListResponseV2
  errors: ErrorResponse[]
}

/** Response<RecordModFlavorSizesResponse> */
export interface ResponseRecordModFlavorSizesResponse {
  data: RecordModFlavorSizesResponse
  errors: ErrorResponse[]
}

/** Response<RefundsIdsResponse> */
export interface ResponseRefundsIdsResponse {
  data: RefundsIdsResponse
  errors: ErrorResponse[]
}

/** Response<RefundsResponse> */
export interface ResponseRefundsResponse {
  data: RefundsResponse
  errors: ErrorResponse[]
}

/** Response<ReprocessedGameReleasesResponse> */
export interface ResponseReprocessedGameReleasesResponse {
  data: ReprocessedGameReleasesResponse
  errors: ErrorResponse[]
}

/** Response<ReprocessedGamesResponse> */
export interface ResponseReprocessedGamesResponse {
  data: ReprocessedGamesResponse
  errors: ErrorResponse[]
}

/** Response<ReprocessedModReleasesResponse> */
export interface ResponseReprocessedModReleasesResponse {
  data: ReprocessedModReleasesResponse
  errors: ErrorResponse[]
}

/** Response<ReprocessedModsResponse> */
export interface ResponseReprocessedModsResponse {
  data: ReprocessedModsResponse
  errors: ErrorResponse[]
}

/** Response<ReprocessedWrappersResponse> */
export interface ResponseReprocessedWrappersResponse {
  data: ReprocessedWrappersResponse
  errors: ErrorResponse[]
}

/** Response<RespectPaymentsResponse> */
export interface ResponseRespectPaymentsResponse {
  data: RespectPaymentsResponse
  errors: ErrorResponse[]
}

/** Response<ReviewResponse> */
export interface ResponseReviewResponse {
  data: ReviewResponse
  errors: ErrorResponse[]
}

/** Response<ScheduledTaskResponse> */
export interface ResponseScheduledTaskResponse {
  data: ScheduledTaskResponse
  errors: ErrorResponse[]
}

/** Response<ScheduledTasksResponse> */
export interface ResponseScheduledTasksResponse {
  data: ScheduledTasksResponse
  errors: ErrorResponse[]
}

/** Response<ScoreVerificationResponse> */
export interface ResponseScoreVerificationResponse {
  data: ScoreVerificationResponse
  errors: ErrorResponse[]
}

/** Response<ScoreVerificationsResponse> */
export interface ResponseScoreVerificationsResponse {
  data: ScoreVerificationsResponse
  errors: ErrorResponse[]
}

/** Response<ScoresResponse> */
export interface ResponseScoresResponse {
  data: ScoresResponse
  errors: ErrorResponse[]
}

/** Response<SeasonBlockedGameResponse> */
export interface ResponseSeasonBlockedGameResponse {
  data: SeasonBlockedGameResponse
  errors: ErrorResponse[]
}

/** Response<SeasonBlockedGamesResponse> */
export interface ResponseSeasonBlockedGamesResponse {
  data: SeasonBlockedGamesResponse
  errors: ErrorResponse[]
}

/** Response<SeasonBlockedStudioResponse> */
export interface ResponseSeasonBlockedStudioResponse {
  data: SeasonBlockedStudioResponse
  errors: ErrorResponse[]
}

/** Response<SeasonBlockedStudiosResponse> */
export interface ResponseSeasonBlockedStudiosResponse {
  data: SeasonBlockedStudiosResponse
  errors: ErrorResponse[]
}

/** Response<SeasonDetailsResponse> */
export interface ResponseSeasonDetailsResponse {
  data: SeasonDetailsResponse
  errors: ErrorResponse[]
}

/** Response<SeasonResponse> */
export interface ResponseSeasonResponse {
  data: SeasonResponse
  errors: ErrorResponse[]
}

/** Response<SeasonsResponse> */
export interface ResponseSeasonsResponse {
  data: SeasonsResponse
  errors: ErrorResponse[]
}

/** Response<SettingDetailsResponse> */
export interface ResponseSettingDetailsResponse {
  data: SettingDetailsResponse
  errors: ErrorResponse[]
}

/** Response<SettingOverrideTransactionResponse> */
export interface ResponseSettingOverrideTransactionResponse {
  data: SettingOverrideTransactionResponse
  errors: ErrorResponse[]
}

/** Response<SettingOverrideTransactionsResponse> */
export interface ResponseSettingOverrideTransactionsResponse {
  data: SettingOverrideTransactionsResponse
  errors: ErrorResponse[]
}

/** Response<SettingsRootNamespacesResponse> */
export interface ResponseSettingsRootNamespacesResponse {
  data: SettingsRootNamespacesResponse
  errors: ErrorResponse[]
}

/** Response<StickerListResponse> */
export interface ResponseStickerListResponse {
  data: StickerListResponse
  errors: ErrorResponse[]
}

/** Response<StickerPageResponse> */
export interface ResponseStickerPageResponse {
  data: StickerPageResponse
  errors: ErrorResponse[]
}

/** Response<StickerResponse> */
export interface ResponseStickerResponse {
  data: StickerResponse
  errors: ErrorResponse[]
}

/** Response<String> */
export interface ResponseString {
  data: string
  errors: ErrorResponse[]
}

/** Response<StringResponse> */
export interface ResponseStringResponse {
  data: StringResponse
  errors: ErrorResponse[]
}

/** Response<StringsResponse> */
export interface ResponseStringsResponse {
  data: StringsResponse
  errors: ErrorResponse[]
}

/** Response<StudioDetailsResponse> */
export interface ResponseStudioDetailsResponse {
  data: StudioDetailsResponse
  errors: ErrorResponse[]
}

/** Response<StudioOdkResponse> */
export interface ResponseStudioOdkResponse {
  data: StudioOdkResponse
  errors: ErrorResponse[]
}

/** Response<StudioResponse> */
export interface ResponseStudioResponse {
  data: StudioResponse
  errors: ErrorResponse[]
}

/** Response<StudioSettingsResponse> */
export interface ResponseStudioSettingsResponse {
  data: StudioSettingsResponse
  errors: ErrorResponse[]
}

/** Response<StudiosResponse> */
export interface ResponseStudiosResponse {
  data: StudiosResponse
  errors: ErrorResponse[]
}

/** Response<SystemAlertResponse> */
export interface ResponseSystemAlertResponse {
  data: SystemAlertResponse
  errors: ErrorResponse[]
}

/** Response<SystemAlertsResponse> */
export interface ResponseSystemAlertsResponse {
  data: SystemAlertsResponse
  errors: ErrorResponse[]
}

/** Response<TagDetailsResponse> */
export interface ResponseTagDetailsResponse {
  data: TagDetailsResponse
  errors: ErrorResponse[]
}

/** Response<TagsResponse> */
export interface ResponseTagsResponse {
  data: TagsResponse
  errors: ErrorResponse[]
}

/** Response<UserFeedbackResponse> */
export interface ResponseUserFeedbackResponse {
  data: UserFeedbackResponse
  errors: ErrorResponse[]
}

/** Response<UserResponse> */
export interface ResponseUserResponse {
  data: UserResponse
  errors: ErrorResponse[]
}

/** Response<UsersResponse> */
export interface ResponseUsersResponse {
  data: UsersResponse
  errors: ErrorResponse[]
}

/** Response<VideoUploadsResponse> */
export interface ResponseVideoUploadsResponse {
  data: VideoUploadsResponse
  errors: ErrorResponse[]
}

/** Response<Void> */
export interface ResponseVoid {
  data: null
  errors: ErrorResponse[]
}

/** Response<WaitListResponse> */
export interface ResponseWaitListResponse {
  data: WaitListResponse
  errors: ErrorResponse[]
}

/** Response<WrapperDeploymentReleasesResponse> */
export interface ResponseWrapperDeploymentReleasesResponse {
  data: WrapperDeploymentReleasesResponse
  errors: ErrorResponse[]
}

/** Response<WrapperDeploymentResponse> */
export interface ResponseWrapperDeploymentResponse {
  data: WrapperDeploymentResponse
  errors: ErrorResponse[]
}

/** Response<WrapperDeploymentsResponse> */
export interface ResponseWrapperDeploymentsResponse {
  data: WrapperDeploymentsResponse
  errors: ErrorResponse[]
}

/** Response<WrapperResponse> */
export interface ResponseWrapperResponse {
  data: WrapperResponse
  errors: ErrorResponse[]
}

/** Response<WrappersResponse> */
export interface ResponseWrappersResponse {
  data: WrappersResponse
  errors: ErrorResponse[]
}

export type ResumeDeploymentData = ResponseVoid

export type ResumeDeploymentError =
  | PostWrappersWrapperIdDeploymentsDeploymentIdResume400ErrorResponse
  | PostWrappersWrapperIdDeploymentsDeploymentIdResume403ErrorResponse
  | PostWrappersWrapperIdDeploymentsDeploymentIdResume404ErrorResponse

export type RevalidateCurrentReleaseData = ResponseModReleaseDetailsResponse

export type RevalidateCurrentReleaseError =
  | PutModsModIdTracksTrackIdRevalidateCurrentRelease400ErrorResponse
  | PutModsModIdTracksTrackIdRevalidateCurrentRelease403ErrorResponse
  | PutModsModIdTracksTrackIdRevalidateCurrentRelease404ErrorResponse
  | PutModsModIdTracksTrackIdRevalidateCurrentRelease500ErrorResponse

export type RevalidateCurrentReleasesData = ResponseModReleasesDetailsResponse

export type RevalidateCurrentReleasesError =
  | PutModsRevalidateCurrentReleases400ErrorResponse
  | PutModsRevalidateCurrentReleases403ErrorResponse

export interface RevalidateCurrentReleasesParams {
  /**
   * The page to return. The response will contain releases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of releases to include in the response. Fewer releases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** Review */
export interface Review {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  challenge: string
  challengeId: string
  /** @format date-time */
  createdAt: string
  decision: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION'
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  game: string
  gameId: string
  internalComment: string
  messageToRequester: string
  messageToSubject: string
  releasable: Releasable
  releasableId: string
  reviewId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  reviewer: string
  reviewerId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  studio: string
  studioId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  user: string
  userId: string
}

/** ReviewRequestResponse */
export interface ReviewRequestResponse {
  comment?: string | null
  /** @format date-time */
  createdAt?: string | null
  reason?:
    | 'OFFENSIVE_MATERIAL'
    | 'MISCATEGORIZED'
    | 'UNAUTHORIZED_DISTRIBUTION'
    | 'SPAM'
    | 'OTHER'
    | 'RECONSIDERATION'
    | null
  requestId: string
  requester?: UserSummaryResponse | null
  review?: ReviewSummaryResponse | null
}

/** ReviewResponse */
export interface ReviewResponse {
  /** @format date-time */
  createdAt?: string | null
  decision?: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION' | null
  internalComment?: string | null
  messageToRequester?: string | null
  messageToSubject?: string | null
  reviewId: string
  reviewerId?: string | null
  reviewerName?: string | null
}

/** ReviewSummaryResponse */
export interface ReviewSummaryResponse {
  /** @format date-time */
  createdAt?: string | null
  decision?: 'BLOCKED' | 'UNBLOCKED' | 'NO_ACTION' | null
  reviewId: string
  reviewerId?: string | null
  reviewerName?: string | null
}

export type RotateQuestCollectionData = ResponseQuestCollectionResponse

export type RotateQuestCollectionError =
  | PostQuestCollectionsCollectionIdRotate400ErrorResponse
  | PostQuestCollectionsCollectionIdRotate403ErrorResponse
  | PostQuestCollectionsCollectionIdRotate404ErrorResponse

export type SaveMarketingConsentForUsersData = ResponseDefaultTopicConvertedEntriesResponse

export type SaveMarketingConsentForUsersError =
  | PostEmailingTopicsMarketingConsent400ErrorResponse
  | PostEmailingTopicsMarketingConsent403ErrorResponse

export interface SaveMarketingConsentForUsersParams {
  /**
   * The page to return. The response will contain default emailing consent entries in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of default emailing consent entries to include in the response. Fewer default emailing consent entries may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** ScheduledTaskResponse */
export interface ScheduledTaskResponse {
  /** Configured minimum time between consecutive executions of the task, or null if the task is currently disabled. */
  executionInterval?: Duration | null
  /**
   * When the last execution of the task finished, or null if it has never been executed.
   * @format date-time
   */
  lastExecutedAt?: string | null
  /** Configured lock timeout. */
  lockTimeout?: Duration | null
  /**
   * Expire time of lock acquired when currently ongoing execution began, or null if the last execution finished without interruption.
   * @format date-time
   */
  lockedUntil?: string | null
  /** The task type / unique task identifier. */
  taskType?: string | null
}

/** ScheduledTasksResponse */
export interface ScheduledTasksResponse {
  scheduledTasks?: ScheduledTaskResponse[] | null
}

/** ScoreResponse */
export interface ScoreResponse {
  /**
   * Time of score submission.
   * @format date-time
   * @example "2021-10-14T23:22:46.238Z"
   */
  achievementDate?: string | null
  /**
   * The scoring user's country code.
   * @example "NO"
   */
  countryCode?: string | null
  /**
   * The score value.
   * @format int64
   * @example 17
   */
  score?: number | null
  /** Unique id. */
  scoreId: string
  user?: UserSummaryResponse | null
}

/** ScoreVerificationAssetResponse */
export interface ScoreVerificationAssetResponse {
  /** Score verification asset type. */
  assetType?: 'GAMEPLAY_VIDEO' | null
  /** Content-type specified when asset was uploaded. */
  contentType?: string | null
  /**
   * Time of upload.
   * @format date-time
   * @example "2021-10-14T23:22:46.493Z"
   */
  uploadedAt?: string | null
  /** URL to uploaded asset, or null if not uploaded yet. */
  url?: string | null
}

/** ScoreVerificationResponse */
export interface ScoreVerificationResponse {
  assets?: ScoreVerificationAssetResponse[] | null
  /**
   * Optional review comment.
   * @example "This is a cheater!"
   */
  comment?: string | null
  /**
   * Time of review.
   * @format date-time
   * @example "2021-10-14T23:22:46.493Z"
   */
  reviewedAt?: string | null
  reviewedBy?: BackOfficeUserResponse | null
  score?: ScoreResponse | null
  /** True if the score has been reviewed good, false if it has been reviewed bad, or null if it has not been reviewed yet. */
  scoreGood?: boolean | null
}

/** ScoreVerificationsResponse */
export interface ScoreVerificationsResponse {
  pagination: PaginationResponse
  scoreVerifications: ScoreVerificationResponse[]
}

/** ScoresResponse */
export interface ScoresResponse {
  pagination: PaginationResponse
  scores: ScoreResponse[]
}

/** SeasonBlockRequest */
export interface SeasonBlockRequest {
  /** Optional comment. */
  comment?: string | null
}

/** SeasonBlockedGameResponse */
export interface SeasonBlockedGameResponse {
  /**
   * When the entity was blocked.
   * @format date-time
   */
  blockedAt?: string | null
  /** The BO user that blocked the entity. */
  blockedBy?: BackOfficeUserResponse | null
  /** Optional comment. */
  comment?: string | null
  /** The blocked game. */
  game?: GameResponse | null
}

/** SeasonBlockedGamesResponse */
export interface SeasonBlockedGamesResponse {
  blockedGames: SeasonBlockedGameResponse[]
  pagination: PaginationResponse
}

/** SeasonBlockedStudioResponse */
export interface SeasonBlockedStudioResponse {
  /**
   * When the entity was blocked.
   * @format date-time
   */
  blockedAt?: string | null
  /** The BO user that blocked the entity. */
  blockedBy?: BackOfficeUserResponse | null
  /** Optional comment. */
  comment?: string | null
  /** The blocked studio. */
  studio?: StudioResponse | null
}

/** SeasonBlockedStudiosResponse */
export interface SeasonBlockedStudiosResponse {
  blockedStudios: SeasonBlockedStudioResponse[]
  pagination: PaginationResponse
}

/** SeasonDetailsResponse */
export interface SeasonDetailsResponse {
  /** True if season has been deleted. */
  deleted?: boolean | null
  /**
   * Season end time
   * @format date-time
   * @example "2022-03-31T23:59:59.000+01:00"
   */
  endsAt?: string | null
  /**
   * Unique season identifier.
   * @example "March 2022"
   */
  identifier?: string | null
  /** True if season is currently published. */
  published?: boolean | null
  /**
   * Season id.
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  seasonId: string
  /** Season type. */
  seasonType?: 'PLAYER' | 'STUDIO' | null
  standings?: SeasonPointsResponse[] | null
  /**
   * Season start time
   * @format date-time
   * @example "2022-03-01T00:00:00.000+01:00"
   */
  startsAt?: string | null
  studioMetricsSettings?: StudioMetricSettingsResponse[] | null
}

/** SeasonPointsResponse */
export interface SeasonPointsResponse {
  /**
   * Point sum in the season.
   * @format int64
   */
  points?: number | null
  studio?: StudioResponse | null
  user?: UserSummaryResponse | null
}

/** SeasonResponse */
export interface SeasonResponse {
  /** True if season has been deleted. */
  deleted?: boolean | null
  /**
   * Season end time
   * @format date-time
   * @example "2022-03-31T23:59:59.000+01:00"
   */
  endsAt?: string | null
  /**
   * Unique season identifier.
   * @example "March 2022"
   */
  identifier?: string | null
  /** True if season is currently published. */
  published?: boolean | null
  /**
   * Season id.
   * @example "12345678-1234-1234-1234-123456789abc"
   */
  seasonId: string
  /** Season type. */
  seasonType?: 'PLAYER' | 'STUDIO' | null
  /**
   * Season start time
   * @format date-time
   * @example "2022-03-01T00:00:00.000+01:00"
   */
  startsAt?: string | null
}

/** SeasonsResponse */
export interface SeasonsResponse {
  pagination: PaginationResponse
  seasons: SeasonResponse[]
}

/** SecurityContext */
export type SecurityContext = object

export type SendRequestedEventsData = ResponseEventsSentResponse

export type SendRequestedEventsError =
  | PostStatsApiSendRequestedEvents400ErrorResponse
  | PostStatsApiSendRequestedEvents403ErrorResponse

export interface SendRequestedEventsParams {
  /**
   * The page to return. The response will contain events in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of events to include in the response. Fewer events may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type SetPatchLegacySettingRequest = PatchLegacySettingRequest[]

export type SetRedeemedData = ResponseCodeRedemptionImportResponse

export type SetRedeemedError =
  | PostOffersOfferIdCodesRedeemReport400ErrorResponse
  | PostOffersOfferIdCodesRedeemReport403ErrorResponse
  | PostOffersOfferIdCodesRedeemReport404ErrorResponse
  | PostOffersOfferIdCodesRedeemReport500ErrorResponse

export interface SetRedeemedPayload {
  /** @format binary */
  file?: File
}

export type SetReleasableAccess = ReleasableAccess[]

export type SetTippingPurchaseTypeData = ResponseGamePurchasesDetailedResponse

export type SetTippingPurchaseTypeError =
  | PostPurchasesSetTippingPurchaseType400ErrorResponse
  | PostPurchasesSetTippingPurchaseType403ErrorResponse

export interface SetTippingPurchaseTypeParams {
  /**
   * The page to return. The response will contain purchases in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of purchases to include in the response. Fewer purchases may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

/** SettingDetailsResponse */
export interface SettingDetailsResponse {
  /** Setting alias. Only guaranteed to be unique within the enclosing settings namespace. */
  alias?: string | null
  /** True if this setting can be overridden globally. If false, it can only be overridden for specific entities. */
  allowsGlobalOverride?: boolean | null
  /** Types of entities this setting allows being overridden for, or `null` if it does not support per-entity overrides. */
  allowsOverrideFor?:
    | (
        | 'API_CLIENT'
        | 'BACKEND_HOST'
        | 'CDN_HOSTING'
        | 'GAME_ENGINE'
        | 'MANAGED_FEED'
        | 'OFFER_PARTNER'
        | 'PARTNER'
        | 'QUEST_COLLECTION'
        | 'STORAGE_BUCKET'
        | 'STUDIO'
        | 'USER'
        | 'EXTENSION'
        | 'GAME'
        | 'MOD'
        | 'OFFER'
        | 'CHALLENGE'
      )[]
    | null
  /** Optional setting description. Will be `null` if not available. */
  description?: string | null
  /** Type of entity for which this setting can be overridden, or `null` if it does not support per-entity overrides. */
  entityType?:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
    | null
  /** Set of valid enumerators if `valueType` is `ENUMERATOR`, and `null` otherwise. */
  enumerators?: string[] | null
  recentChanges?: SettingOverrideRecentChangeResponse[] | null
  /** The setting ID, in the form {namespace}.{child namespace}.{alias} */
  settingId: string
  /** Setting title. */
  title?: string | null
  /** Setting value type. */
  valueType?:
    | 'ASPECT_RATIO'
    | 'BOOLEAN'
    | 'DATA_SIZE'
    | 'DURATION'
    | 'ENUMERATOR'
    | 'INTEGER'
    | 'STRING'
    | 'STRUCTURED'
    | null
  /** Setting values ordered from most relevant (active override) to least relevant (hard-coded default). */
  values?: SettingValueResponse[] | null
  /** Setting visibility. If `HIDDEN` then the current override value (if not `null`) will be masked, i.e. have most of the characters replaced by a masking character to hide the true value. */
  visibility?: 'DEFAULT' | 'HIDDEN' | null
}

/** SettingKey */
export interface SettingKey {
  defaultValue?: string
  type?: 'STRING' | 'INTEGER' | 'BOOLEAN' | 'GRAPHIC'
}

/** SettingOverrideChangeRequest */
export interface SettingOverrideChangeRequest {
  /** New override value, or `null` to delete the current override and revert the setting to its default value. */
  newValue: string | null
  /** Full id of setting to change. */
  settingId: string
}

/** SettingOverrideRecentChangeResponse */
export interface SettingOverrideRecentChangeResponse {
  /** Unique id of this particular setting change. */
  changeId: string
  /** The entity whose setting override was changed, or `null` if the change affected the setting's main/global override. */
  forEntity?: TypedEntityResponse | null
  /** True if this change deleted the setting's override, thus reverting it back to its default value. */
  newIsDefault?: boolean | null
  /** New effective setting value. If the change was that the current override was deleted then this will be the setting's default value (at the time of the change), or `null` if the setting had no default value. */
  newValue?: string | null
  /** True if the setting had no override prior to this change. */
  oldIsDefault?: boolean | null
  /** Previous effective setting value. If the setting had no override prior to the change then this will be the setting's default value (at the time of the change), or `null` if the setting had no default value. */
  oldValue?: string | null
  transaction?: SettingOverrideTransactionSummaryResponse | null
}

/** SettingOverrideTransactionChangeResponse */
export interface SettingOverrideTransactionChangeResponse {
  /** Unique id of this particular setting change. */
  changeId: string
  /** The entity whose setting override was changed, or `null` if the change affected the setting's main/global override. */
  forEntity?: TypedEntityResponse | null
  /** True if this change deleted the setting's override, thus reverting it back to its default value. */
  newIsDefault?: boolean | null
  /** New effective setting value. If the change was that the current override was deleted then this will be the setting's default value (at the time of the change), or `null` if the setting had no default value. */
  newValue?: string | null
  /** True if the setting had no override prior to this change. */
  oldIsDefault?: boolean | null
  /** Previous effective setting value. If the setting had no override prior to the change then this will be the setting's default value (at the time of the change), or `null` if the setting had no default value. */
  oldValue?: string | null
  /** The full id of the setting that was changed. */
  settingId: string
}

/** SettingOverrideTransactionResponse */
export interface SettingOverrideTransactionResponse {
  /** @format date-time */
  changedAt?: string | null
  changer?: ChangerResponse | null
  changes?: SettingOverrideTransactionChangeResponse[] | null
  comment?: string | null
  transactionId: string
}

/** SettingOverrideTransactionSummaryResponse */
export interface SettingOverrideTransactionSummaryResponse {
  /** @format date-time */
  changedAt?: string | null
  changer?: ChangerResponse | null
  comment?: string | null
  transactionId: string
}

/** SettingOverrideTransactionsResponse */
export interface SettingOverrideTransactionsResponse {
  pagination: PaginationResponse
  transactions: SettingOverrideTransactionResponse[]
}

/** SettingResponse */
export interface SettingResponse {
  /** Setting alias. Only guaranteed to be unique within the enclosing settings namespace. */
  alias?: string | null
  /** True if this setting can be overridden globally. If false, it can only be overridden for specific entities. */
  allowsGlobalOverride?: boolean | null
  /** Types of entities this setting allows being overridden for, or `null` if it does not support per-entity overrides. */
  allowsOverrideFor?:
    | (
        | 'API_CLIENT'
        | 'BACKEND_HOST'
        | 'CDN_HOSTING'
        | 'GAME_ENGINE'
        | 'MANAGED_FEED'
        | 'OFFER_PARTNER'
        | 'PARTNER'
        | 'QUEST_COLLECTION'
        | 'STORAGE_BUCKET'
        | 'STUDIO'
        | 'USER'
        | 'EXTENSION'
        | 'GAME'
        | 'MOD'
        | 'OFFER'
        | 'CHALLENGE'
      )[]
    | null
  /** Optional setting description. Will be `null` if not available. */
  description?: string | null
  /** Type of entity for which this setting can be overridden, or `null` if it does not support per-entity overrides. */
  entityType?:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
    | null
  /** Set of valid enumerators if `valueType` is `ENUMERATOR`, and `null` otherwise. */
  enumerators?: string[] | null
  /** The setting ID, in the form {namespace}.{child namespace}.{alias} */
  settingId: string
  /** Setting title. */
  title?: string | null
  /** Setting value type. */
  valueType?:
    | 'ASPECT_RATIO'
    | 'BOOLEAN'
    | 'DATA_SIZE'
    | 'DURATION'
    | 'ENUMERATOR'
    | 'INTEGER'
    | 'STRING'
    | 'STRUCTURED'
    | null
  /** Setting values ordered from most relevant (active override) to least relevant (hard-coded default). */
  values?: SettingValueResponse[] | null
  /** Setting visibility. If `HIDDEN` then the current override value (if not `null`) will be masked, i.e. have most of the characters replaced by a masking character to hide the true value. */
  visibility?: 'DEFAULT' | 'HIDDEN' | null
}

/** SettingValueResponse */
export interface SettingValueResponse {
  /** Entity that this value is an override for, or `null` if this is a global override or system default. */
  forEntity?: TypedEntityResponse | null
  /** String representation of the setting value. */
  value?: string | null
  /** Setting value type. */
  valueType?: 'OVERRIDE' | 'LEGACY_OVERRIDE' | 'DEFAULT' | null
}

/** SettingsNamespaceResponse */
export interface SettingsNamespaceResponse {
  /** Settings namespace alias. Only guaranteed */
  alias?: string | null
  settings?: SettingResponse[] | null
  subNamespaces?: SettingsNamespaceResponse[] | null
  /** Settings namespace title. */
  title?: string | null
}

/** SettingsRootNamespacesResponse */
export interface SettingsRootNamespacesResponse {
  rootNamespaces?: SettingsNamespaceResponse[] | null
}

/** SnapshotConfigurationResponse */
export interface SnapshotConfigurationResponse {
  /**
   * Snapshot refresh interval in milliseconds
   *
   * Notes: *Null means no automatic refresh schedule is active. Manual refresh is required to update the feed's contents.*
   * @format int64
   * @example 604800000
   */
  refreshInterval?: number | null
}

/** SnapshotRefreshScheduleResponse */
export interface SnapshotRefreshScheduleResponse {
  /**
   * Time of next automatic refresh of the snapshot, or null if no such refresh is currently scheduled.
   * @format date-time
   * @example "2022-11-11T08:00:00.000Z"
   */
  nextScheduledRefreshAt?: string | null
  /**
   * Time of most recent manual refresh of the snapshot, or null if no such refresh has ever been performed.
   * @format date-time
   * @example "2022-11-07T11:35:14.000Z"
   */
  previousManualRefreshAt?: string | null
  /**
   * Time of most recent scheduled/automatic refresh of the snapshot, or null if no such refresh has ever been performed.
   *
   * Notes: *This value indicates when the refresh actually happened, rather than when it was scheduled to happen.*
   * @format date-time
   * @example "2022-11-04T08:03:10.000Z"
   */
  previousScheduledRefreshAt?: string | null
}

/** Sort */
export interface Sort {
  empty?: boolean
  sorted?: boolean
  unsorted?: boolean
}

/** StickerListResponse */
export interface StickerListResponse {
  /** The stickers */
  stickers?: StickerResponse[] | null
}

/** StickerPageResponse */
export interface StickerPageResponse {
  /** The pagination */
  pagination: PaginationResponse
  /** The stickers */
  stickers: StickerResponse[]
}

/** StickerResponse */
export interface StickerResponse {
  /** Whether the sticker is active */
  active?: boolean | null
  /** The alias of the sticker */
  alias?: string | null
  /**
   * The amount of the sticker. For tipping sticker per-game value please refer to 'respect.sticker.tipping-sticker-price' per-game setting
   * @format int32
   */
  amount?: number | null
  /** URL of the cover image, or `null` if the sticker has no cover */
  coverUrl?: string | null
  /** The Back Office user who created the sticker */
  createdBy?: BackOfficeUserResponse | null
  /**
   * The date on which the sticker was created
   * @format date-time
   */
  creationDate?: string | null
  /** The currency of the sticker */
  currency?: 'RPX' | 'GPX' | 'BPX' | 'USD' | null
  /**
   * The display order of the sticker
   * @format int32
   */
  displayOrder?: number | null
  /** The note of the sticker, as a localizable string */
  note?: StringResponse | null
  /** The ID of the sticker */
  stickerId: string
  /** The title of the sticker, as a localizable string */
  title?: StringResponse | null
}

/** StorageBucketResponse */
export interface StorageBucketResponse {
  /** Unique storage bucket alias */
  alias?: string | null
  /**
   * Storage bucket id (UUID4)
   * @example "37e478cd-1aeb-4be3-9297-268912730a94"
   */
  bucketId: string
  /** Storage provider */
  provider?: 'AWS_S3' | 'OPERA_CEPH' | null
  /** Bucket name at storage provider */
  providerBucketName?: string | null
  /** Provider (AWS) region, or null if not configured */
  providerRegion?: string | null
}

/** Stream<ReleasableAsset> */
export interface StreamReleasableAsset {
  parallel?: boolean
}

/** StringResponse */
export interface StringResponse {
  stringId: string
  translations?: StringTranslationResponse[] | null
  type?:
    | 'CONFIGURATION_SECTION_DESCRIPTION'
    | 'CONFIGURATION_SECTION_NAME'
    | 'GAME_CONTROL_ACTION'
    | 'GAME_CONTROL_INPUT'
    | 'GAME_CONTROLS_GROUP_NAME'
    | 'GAME_ITEM_DESCRIPTION'
    | 'TAG_TITLE'
    | 'MOD_TAG_TITLE'
    | 'FEED_TITLE'
    | 'FEED_DESCRIPTION'
    | 'GAME_ENGINE_TITLE'
    | 'GAME_ENGINE_URL'
    | 'PUBLISHER_TITLE'
    | 'PUBLISHER_URL'
    | 'CUSTOM_CONTENT'
    | 'OFFER_PARTNER_TITLE'
    | 'OFFER_TITLE'
    | 'QUEST_TITLE'
    | 'QUEST_DESCRIPTION'
    | 'QUEST_CALL_TO_ACTION_TITLE'
    | 'STICKER_NOTE'
    | 'STICKER_TITLE'
    | null
  value?: string | null
}

/** StringTranslationResponse */
export interface StringTranslationResponse {
  locale?: string | null
  value?: string | null
}

/** StringsResponse */
export interface StringsResponse {
  pagination: PaginationResponse
  strings: StringResponse[]
}

/** BO detailed studio response */
export interface StudioDetailsResponse {
  /** @example "e@mail.com" */
  contactEmail?: string | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-07-13T05:22:33.536Z"
   */
  creationDate?: string | null
  /** `true` if the studio can upload game builds using Dev portal. */
  customGameBuildsAllowed?: boolean | null
  gameIds?: string[] | null
  /**
   * *Deprecated in favor of `studioId`*
   * @deprecated
   */
  groupId?: string | null
  /** @example "A long cool description" */
  longDescription?: string | null
  members?: StudioMemberResponse[] | null
  modIds?: string[] | null
  /** @example "Opera gaming studio no 1" */
  name?: string | null
  officialDistributionKeys?: OdkSummaryResponse[] | null
  owners?: UserSummaryResponse[] | null
  /**
   * Revenue share % when selling game items on Stripe.
   *
   * Notes: *Valid for all games made by this studio unless overwritten on a per-game basis.*
   * @example "87"
   */
  stripeStudioRevenueSharePercentage?: string | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  studioId: string
  /**
   * Studio entity version
   *
   * Notes: *Will be an empty string if the studio's version has not been initialized.*
   * @example "1"
   */
  studioVersion?: string | null
}

/** StudioMemberResponse */
export interface StudioMemberResponse {
  /**
   * @format date-time
   * @example "2021-06-22T12:12:14.819Z"
   */
  joinDate?: string | null
  /** @example "OWNER" */
  role?: 'OWNER' | 'CONTRIBUTOR' | null
  user?: UserSummaryResponse | null
}

/** StudioMetricSettingsRequest */
export interface StudioMetricSettingsRequest {
  /**
   * Buckets for translating the metric's calculated value to points.
   *
   * Depending on the metric, this translation may happen once for the studio, or once
   * per game published by the studio (for metrics that calculate a value for each game),
   * or in some other fashion.
   *
   * Note: Points are awarded according to the bucket with the highest threshold that is
   * greater than or equal to the metric's calculated value, regardless of the ordering
   * of the buckets in this list.
   */
  buckets?: StudioMetricSettingsRequestBucket[] | null
  /**
   * If true, the metric is disabled.
   *
   * A disabled metric will not be called by hourly or daily runs, not calculate any
   * values, and not award any points.
   */
  disabled?: boolean | null
  /**
   * Maximum points to award to a studio per season for this metric.
   *
   * Significant for metrics that can award points to a studio multiple times per season,
   * e.g. once per game published by the studio.
   * @format int32
   * @example 1000
   */
  maximumPoints?: number | null
  /**
   * The studio metric's unique identifier.
   * @example "ccu"
   */
  metricKey?: string | null
}

/** StudioMetricSettingsRequest.Bucket */
export interface StudioMetricSettingsRequestBucket {
  /**
   * Points awarded to studios whose calculated metric value is greater than or
   * equal to the bucket's `threshold`.
   * @format int32
   * @example 100
   */
  points: number
  /**
   * Metric value threshold.
   *
   * If the metric's calculated value is equal to or greater than the threshold, the
   * bucket's `points` score is awarded to the studio.
   * @format int32
   * @example 25
   */
  threshold: number
}

/** StudioMetricSettingsResponse */
export interface StudioMetricSettingsResponse {
  /**
   * Buckets for translating the metric's calculated value to points.
   *
   * Depending on the metric, this translation may happen once for the studio, or once
   * per game published by the studio (for metrics that calculate a value for each game),
   * or in some other fashion.
   */
  buckets?: StudioMetricSettingsResponseBucket[] | null
  /**
   * If true, the metric is disabled.
   *
   * A disabled metric will not be called by hourly or daily runs, not calculate any
   * values, and not award any points.
   */
  disabled?: boolean | null
  /**
   * Maximum points to award to a studio per season for this metric.
   *
   * Significant for metrics that can award points to a studio multiple times per season,
   * e.g. once per game published by the studio.
   *
   * If null, there is no limit for this metric.
   * @format int32
   */
  maximumPoints?: number | null
  /** The studio metric's unique identifier. */
  metricKey?: string | null
}

/** StudioMetricSettingsResponse.Bucket */
export interface StudioMetricSettingsResponseBucket {
  /**
   * Points awarded to studios whose calculated metric value is greater than or
   * equal to the bucket's `threshold`.
   * @format int32
   */
  points?: number
  /**
   * Metric value threshold.
   *
   * If the metric's calculated value is equal to or greater than the threshold, the
   * bucket's `points` score is awarded to the studio.
   * @format int32
   */
  threshold?: number
}

/** BO studio/ODK assignment response */
export interface StudioOdkResponse {
  key?: OdkDetailsResponse | null
  studio?: StudioResponse | null
}

/** StudioResponse */
export interface StudioResponse {
  /** @example "e@mail.com" */
  contactEmail?: string | null
  covers?: CoverResponse[] | null
  /**
   * @format date-time
   * @example "2021-07-13T05:22:33.536Z"
   */
  creationDate?: string | null
  gameIds?: string[] | null
  /**
   * *Deprecated in favor of `studioId`*
   * @deprecated
   */
  groupId?: string | null
  /** @example "A long cool description" */
  longDescription?: string | null
  members?: StudioMemberResponse[] | null
  modIds?: string[] | null
  /** @example "Opera gaming studio no 1" */
  name?: string | null
  officialDistributionKeys?: OdkSummaryResponse[] | null
  owners?: UserSummaryResponse[] | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  studioId: string
  /**
   * Studio entity version
   *
   * Notes: *Will be an empty string if the studio's version has not been initialized.*
   * @example "1"
   */
  studioVersion?: string | null
}

/** List of studio settings for specific studio */
export interface StudioSettingsResponse {
  studio?: StudioResponse | null
  studioSettings?: SettingResponse[] | null
}

/** BO studio summary response */
export interface StudioSummaryResponse {
  /** @example "Opera gaming studio no 1" */
  name?: string | null
  /** @example "c6e77af8-95c4-4e51-851e-73b154fe6d52" */
  studioId: string
}

/** StudiosResponse */
export interface StudiosResponse {
  pagination: PaginationResponse
  studios: StudioResponse[]
}

export type SubmitScoreReviewData = ResponseScoreVerificationResponse

export type SubmitScoreReviewError =
  | PatchChallengesChallengeIdScoreVerificationsScoreId400ErrorResponse
  | PatchChallengesChallengeIdScoreVerificationsScoreId403ErrorResponse
  | PatchChallengesChallengeIdScoreVerificationsScoreId404ErrorResponse

export type SubmitScoreReviewFail =
  | PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400ErrorResponse
  | PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403ErrorResponse
  | PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404ErrorResponse

/** SubmitScoreReviewRequest */
export interface SubmitScoreReviewRequest {
  /** Optional comment. */
  comment?: string | null
  /** True if the score is good, false if the score is bad. */
  scoreGood?: boolean | null
}

export type SubmitScoreReviewResult = ResponseScoreVerificationResponse

/** SystemAlertResponse */
export interface SystemAlertResponse {
  alertId: string
  /** @format date-time */
  createdAt?: string | null
  entityId?: string | null
  entityType?: 'FEED' | null
  feedUpdateDetails?: FeedUpdateAlertResponse | null
  status?: 'NEW' | 'RESOLVED' | null
}

/** SystemAlertsResponse */
export interface SystemAlertsResponse {
  alerts: SystemAlertResponse[]
  pagination: PaginationResponse
}

/** TagDetailsResponse */
export interface TagDetailsResponse {
  alias?: string | null
  availableToCreators?: boolean | null
  category?:
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'MOD_PLATFORM'
    | 'MOD_SIGNING_KEY'
    | 'MOD_TYPE'
    | 'MOD_WALLPAPER_TYPE'
    | null
  childTags?: TagTitleResponse[] | null
  /** @format int32 */
  displayOrder?: number | null
  icon?: string | null
  internal?: boolean | null
  /** @format int64 */
  numberOfChildren?: number | null
  /** @format int32 */
  numberOfGames?: number | null
  parentTag?: TagSummaryResponse | null
  tagId: string
  title?: string | null
  titleString?: StringResponse | null
}

/** TagSummaryResponse */
export interface TagSummaryResponse {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  alias?: string | null
  availableToCreators?: boolean | null
  category?:
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'MOD_PLATFORM'
    | 'MOD_SIGNING_KEY'
    | 'MOD_TYPE'
    | 'MOD_WALLPAPER_TYPE'
    | null
  /** @format int32 */
  displayOrder?: number | null
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  icon?: string | null
  internal?: boolean | null
  /** @format int64 */
  numberOfChildren?: number | null
  /** @format int32 */
  numberOfGames?: number | null
  parentTag?: TagSummaryResponse | null
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  tagId: string
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  title?: string | null
}

/** TagTitleResponse */
export interface TagTitleResponse {
  alias?: string | null
  availableToCreators?: boolean | null
  category?:
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'MOD_PLATFORM'
    | 'MOD_SIGNING_KEY'
    | 'MOD_TYPE'
    | 'MOD_WALLPAPER_TYPE'
    | null
  /** @format int32 */
  displayOrder?: number | null
  icon?: string | null
  internal?: boolean | null
  tagId: string
  title?: string | null
}

/** TagsResponse */
export interface TagsResponse {
  pagination: PaginationResponse
  tags: TagSummaryResponse[]
}

/** TemporalUnit */
export interface TemporalUnit {
  dateBased?: boolean
  duration?: Duration
  durationEstimated?: boolean
  timeBased?: boolean
}

export type TerminateClaimPeriodData = ResponseGameItemClaimPeriodResponse

export type TerminateClaimPeriodError =
  | PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400ErrorResponse
  | PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403ErrorResponse
  | PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404ErrorResponse

/** BO tipping settings response */
export interface TippingSettingsResponse {
  /**
   * Maximum tipping sticker price.
   * @format int32
   */
  maxTippingStickerPrice?: number | null
  /**
   * Minimal tipping sticker price'.
   * @format int32
   */
  minTippingStickerPrice?: number | null
}

/** BO tipping sticker response */
export interface TippingStickerResponse {
  /**
   * Tipping amount. If tipping not enabled equals 'null'.
   * @format int32
   */
  amount?: number | null
  /** Currently can be only 'USD'. If tipping not enabled equals 'null'. */
  currency?: string | null
  /** ´true´ if `respect.tipping.feature-enabled` is enabled and tipping is enabled for this game. */
  tippingEnabled?: boolean | null
  tippingSettings?: TippingSettingsResponse | null
}

/** TransactionResponse */
export interface TransactionResponse {
  /** Notes: *Checkout details.* */
  checkout?: ExpenseResponse | null
  /** Notes: *Direct withholding tax.* */
  directWht?: ExpenseWithPercentResponse | null
  /**
   * Notes: *Merchant ID.*
   * @format int64
   * @example 205427
   */
  merchantId?: number | null
  /** Notes: *Amount paid by the user.* */
  paymentDetailsPayment?: ExpenseResponse | null
  /** Notes: *Payment system fee.* */
  paymentMethodFee?: ExpenseResponse | null
  /** Notes: *Amount debited from the payment system.* */
  paymentMethodSum?: ExpenseResponse | null
  /** Notes: *Payout details.* */
  payout?: ExpenseResponse | null
  /**
   * Notes: *Exchange rate between payment and payout currencies.*
   * @format double
   * @example 1
   */
  payoutCurrencyRate?: number | null
  /**
   * Notes: *Game’s Xsolla ID.*
   * @format int64
   * @example 135053
   */
  projectId?: number | null
  /** Notes: *Object with data on repatriation costs, imposed on Xsolla by third parties.* */
  repatriationCommission?: ExpenseResponse | null
  /** Notes: *Sales tax (object; USA and Canada only).* */
  salesTax?: ExpenseWithPercentResponse | null
  /** Notes: *Total price of purchase.* */
  total?: ExpenseResponse | null
  /**
   * @format int64
   * @example 207593
   */
  transactionAgreement?: number | null
  /**
   * Notes: *Test transaction. The parameter has the 1 value if it is a test transaction, or is not sent if the transaction is real.*
   * @format int64
   * @example 1
   */
  transactionDryRun?: number | null
  /** @example "b4ea560a-19be-4d41-8ded-26f3c6328062" */
  transactionExternalId?: string | null
  /** @example "e2badb25-44ba-41a1-931d-b1f22745876e" */
  transactionId: string
  /** @example "945524242" */
  transactionPaymentSystemId?: string | null
  /**
   * Notes: *User’s country. Two-letter uppercase country code per ISO 3166-1 alpha-2.*
   * @example "US"
   */
  userCountry?: string | null
  /** @example "176.232.56.172" */
  userIp?: string | null
  userPhone?: string | null
  /**
   * Notes: *User’s ZIP or postal code.*
   * @example "190000"
   */
  userZip?: string | null
  /** Notes: *VAT details (EU only).* */
  vat?: ExpenseWithPercentResponse | null
  /** Notes: *Amount debited from Xsolla balance.* */
  xsollaBalanceSum?: ExpenseResponse | null
  /** Notes: *Xsolla fee.* */
  xsollaFee?: ExpenseResponse | null
}

/** TransferUpdateRequest */
export interface TransferUpdateRequest {
  comment?: string | null
  status: 'APPROVED' | 'REJECTED'
}

export type TriggerHourlyRun2Data = Void

export type TriggerHourlyRun2Error = GetSeasonsStudioMetricsHourly403ErrorResponse

export interface TriggerHourlyRun2Params {
  /** @format int32 */
  hour: number
  /** @format date */
  scoringPeriod: string
}

export type TriggerHourlyRunData = Void

export type TriggerHourlyRunError = GetSeasonsStudioMetricsDaily403ErrorResponse

export interface TriggerHourlyRunParams {
  /** @format date */
  scoringPeriod: string
}

export type TriggerRequestsData = ResponseInteger

export type TriggerRequestsError =
  | PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403ErrorResponse
  | PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404ErrorResponse

export interface TriggerRequestsParams {
  /** @example "363b2a7b-671c-45ed-8975-0ebf62c40abf" */
  configurationId: string
  /**
   * Only trigger requests for selected subset of asset variants: `stuck` (requested long ago and not uploaded), `pending` (requested and not yet uploaded) or `all`.
   * @default "stuck"
   */
  filter?: 'stuck' | 'pending' | 'all'
}

export type TriggerSeasonStartRunData = Void

export type TriggerSeasonStartRunError = GetSeasonsStudioMetricsSeasonStart403ErrorResponse

/** TypedEntityRequest */
export interface TypedEntityRequest {
  /** Full entity id (typically UUID4) */
  entityId: string
  /** Entity type */
  entityType:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
}

/** TypedEntityResponse */
export interface TypedEntityResponse {
  /** The API client or `null` if `entityType` is not `API_CLIENT` */
  apiClient?: ApiClientResponse | null
  /** The backend host or `null` if `entityType` is not `BACKEND_HOST` */
  backendHost?: BackendHostResponse | null
  /** The CDN hosting or `null` if `entityType` is not `CDN_HOSTING` */
  cdnHosting?: CdnHostingResponse | null
  /** The challenge or `null` if `entityType` is not `CHALLENGE` */
  challenge?: ChallengeResponse | null
  /** Full entity id (typically UUID4) */
  entityId: string
  /** Entity type */
  entityType?:
    | 'API_CLIENT'
    | 'BACKEND_HOST'
    | 'CDN_HOSTING'
    | 'GAME_ENGINE'
    | 'MANAGED_FEED'
    | 'OFFER_PARTNER'
    | 'PARTNER'
    | 'QUEST_COLLECTION'
    | 'STORAGE_BUCKET'
    | 'STUDIO'
    | 'USER'
    | 'EXTENSION'
    | 'GAME'
    | 'MOD'
    | 'OFFER'
    | 'CHALLENGE'
    | null
  /** True if the recorded entity id is no longer valid */
  entityUnavailable?: boolean | null
  /** The extension or `null` if `entityType` is not `EXTENSION` */
  extension?: ExtensionResponse | null
  /** The game or `null` if `entityType` is not `GAME` */
  game?: GameResponse | null
  /** The game engine or `null` if `entityType` is not `GAME_ENGINE` */
  gameEngine?: GameEngineResponse | null
  /** The managed feed or `null` if `entityType` is not `MANAGED_FEED` */
  managedFeed?: ManagedFeedSummaryResponse | null
  /** The mod or `null` if `entityType` is not `MOD` */
  mod?: ModSummaryResponse | null
  /** The offer or `null` if `entityType` is not `OFFER` */
  offer?: OfferResponse | null
  /**
   * The partner or `null` if `entityType` is not `PARTNER`
   *
   * *Superseded by `partner`*
   * @deprecated
   */
  offerPartner?: PartnerResponse | null
  /** The partner or `null` if `entityType` is not `PARTNER` */
  partner?: PartnerResponse | null
  /** The quest collection or `null` if `entityType` is not `QUEST_COLLECTION` */
  questCollection?: QuestCollectionSummaryResponse | null
  /** The storage bucket or `null` if `entityType` is not `STORAGE_BUCKET` */
  storageBucket?: StorageBucketResponse | null
  /** The studio or `null` if `entityType` is not `STUDIO` or `GROUP` */
  studio?: StudioResponse | null
  /** The user or `null` if `entityType` is not `USER` */
  user?: UserSummaryResponse | null
}

export type UnblockGameData = ResponseBoolean

export type UnblockGameError =
  | DeleteSeasonsSeasonIdBlockedGamesGameId403ErrorResponse
  | DeleteSeasonsSeasonIdBlockedGamesGameId404ErrorResponse

export type UnblockStudioData = ResponseBoolean

export type UnblockStudioError =
  | DeleteSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse
  | DeleteSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse

export type UnpublishInternalTracksWithSharedArrayBufferData =
  ResponseGameReleaseTracksPageableResponse

export type UnpublishInternalTracksWithSharedArrayBufferError =
  | PatchGamesUnpublishSharedArrayBufferInternalTracks400ErrorResponse
  | PatchGamesUnpublishSharedArrayBufferInternalTracks403ErrorResponse

export interface UnpublishInternalTracksWithSharedArrayBufferParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type UnpublishPublicTracksWithSharedArrayBufferData =
  ResponseGameReleaseTracksPageableResponse

export type UnpublishPublicTracksWithSharedArrayBufferError =
  | PatchGamesUnpublishSharedArrayBufferPublicTracks400ErrorResponse
  | PatchGamesUnpublishSharedArrayBufferPublicTracks403ErrorResponse

export interface UnpublishPublicTracksWithSharedArrayBufferParams {
  /**
   * The page to return. The response will contain games in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of games to include in the response. Fewer games may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type UpdateAccountData = ResponseBankAccountResponse

export type UpdateAccountError =
  | PatchBankAccountsAccountId400ErrorResponse
  | PatchBankAccountsAccountId403ErrorResponse
  | PatchBankAccountsAccountId404ErrorResponse

export type UpdateApiClientData = ResponseApiClientResponse

export type UpdateApiClientError =
  | PatchApiclientsClientId400ErrorResponse
  | PatchApiclientsClientId403ErrorResponse
  | PatchApiclientsClientId404ErrorResponse

/** UpdateApiClientRequest */
export interface UpdateApiClientRequest {
  /** New unique API client alias. */
  alias?: string | null
  /** List of API namespaces the client should be allowed to make (CORS) requests to. */
  allowedApiNamespaces?:
    | (
        | 'BO'
        | 'BONUS'
        | 'CONFIG'
        | 'DC'
        | 'DEV'
        | 'DISCORD'
        | 'EXT_PUB'
        | 'EXT_STORE'
        | 'GAME_DEV'
        | 'GG'
        | 'GGS'
        | 'GMS'
        | 'GX'
        | 'GXC'
        | 'JUGGLE'
        | 'ME'
        | 'OFFERS'
        | 'PARTNERS'
        | 'PAYMENT_PROVIDERS'
        | 'PLATFORMS'
        | 'PROFILE'
        | 'PUB'
        | 'SESSION'
        | 'STORE'
        | 'VERSION'
      )[]
    | null
  /** List of patterns matching origins the API client should be allowed to make (CORS) requests from. Patterns can have wild-cards (`*`) at the beginning of the hostname (`https://*.example.org`) and/or in place of the port number (`http://localhost:*`). */
  allowedOriginPatterns?: string[] | null
  /** List of origins the API client should be allowed to make (CORS) requests from. */
  allowedOrigins?: string[] | null
  /** New primary origin, or null to remove the current primary origin. */
  primaryOrigin?: string | null
  /** New site identifier, or null to remove the current site identifier. */
  siteIdentifier?: string | null
}

export type UpdateAudioContentData = ResponseVoid

export type UpdateAudioContentError = PostModsUpdateAudioModContent403ErrorResponse

/** UpdateBackOfficeUserRequest */
export interface UpdateBackOfficeUserRequest {
  /**
   * Whether or not the BO user is active.
   *
   * Notes: *If the value is empty or missing, the user flag won't be changed.*
   */
  active?: boolean | null
  allowedDynamicConfigClients?: string[] | null
  name?: string | null
  roles?:
    | (
        | 'ADMIN'
        | 'CONTENT_MANAGER'
        | 'DYNAMIC_CONFIG_MANAGER'
        | 'GM_OPS'
        | 'OFFER_MANAGER'
        | 'VIEWER'
      )[]
    | null
}

/** UpdateBankAccountRequest */
export interface UpdateBankAccountRequest {
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
}

export type UpdateBioLinkData = ResponseBioLinkResponse

export type UpdateBioLinkError =
  | PatchUserUserIdBioLinksLinkId400ErrorResponse
  | PatchUserUserIdBioLinksLinkId403ErrorResponse
  | PatchUserUserIdBioLinksLinkId404ErrorResponse

/** UpdateBioLinkRequest */
export interface UpdateBioLinkRequest {
  /** Whether or not link will be shown to all users in link 'owner' profile */
  active?: boolean | null
  /** @example "Twitter" */
  title?: string | null
  /** Not empty url */
  url?: string | null
}

export type UpdateBioLinksOrderData = ResponseBioLinksResponse

export type UpdateBioLinksOrderError =
  | PatchUserUserIdBioLinks400ErrorResponse
  | PatchUserUserIdBioLinks403ErrorResponse
  | PatchUserUserIdBioLinks404ErrorResponse

/** UpdateBioLinksOrderRequest */
export interface UpdateBioLinksOrderRequest {
  /** List of link ids in a new order */
  linkIds: string[]
}

export type UpdateBucketIdentifierData = ResponseInteger

export type UpdateBucketIdentifierError = PostAssetsUpdateBucketIdentifier403ErrorResponse

export interface UpdateBucketIdentifierParams {
  from: 'BONUS' | 'GMS' | 'GX' | 'GX_CLOUD_SAVES' | 'LOGS' | 'GG_OPSTORE' | 'STORE'
  /** Path prefix(es) of assets to update */
  pathPrefix: string[]
  to: 'BONUS' | 'GMS' | 'GX' | 'GX_CLOUD_SAVES' | 'LOGS' | 'GG_OPSTORE' | 'STORE'
}

export type UpdateChallengeSettingData = ResponseChallengeSettingResponse

export type UpdateChallengeSettingError =
  | PatchChallengesChallengeIdSettings400ErrorResponse
  | PatchChallengesChallengeIdSettings403ErrorResponse
  | PatchChallengesChallengeIdSettings404ErrorResponse

/** UpdateChallengeSettingRequest */
export interface UpdateChallengeSettingRequest {
  published: boolean
  /** @example "42f69826-610a-431c-aa15-d60429121054" */
  releaseTrackId: string
}

/** UpdateCmsConfigurationRequest */
export interface UpdateCmsConfigurationRequest {
  /**
   * New configuration alias
   *
   * Notes: *The value can contain only lower-case letters, digits and hyphens (`-`).*
   * @example "sidebar"
   */
  alias?: string | null
  /**
   * New description
   *
   * Notes: *The description is for informal BO display purposes only.*
   * @example "Sidebar for GX.games"
   */
  description?: string | null
  /**
   * New configuration name
   *
   * Notes: *The name property has been deprecated in favor of the alias property.*
   * @example "Sidebar"
   */
  name?: string | null
  /**
   * New sections order
   *
   * Notes: *The list, if present, must contain the id of each section in the page config exactly once.*
   */
  sectionIds?: string[] | null
}

/** UpdateCmsSectionRequest */
export interface UpdateCmsSectionRequest {
  /**
   * When section stops being available
   * @format date-time
   */
  availabilityEndsAt?: string | null
  /**
   * When section becomes available
   * @format date-time
   */
  availabilityStartsAt?: string | null
  /**
   * Section feed id.
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  feedId?: string | null
  /** Section type value managed by FE. */
  sectionType?: string | null
  /** Section visibility user group. */
  visibleFor?: 'ALL_USERS' | 'GUESTS' | 'SIGNED_IN_USERS' | null
}

export type UpdateCodeData = ResponseOfferCodeDetailsResponse

export type UpdateCodeError =
  | PatchOffersOfferIdCodesCodeId400ErrorResponse
  | PatchOffersOfferIdCodesCodeId403ErrorResponse
  | PatchOffersOfferIdCodesCodeId404ErrorResponse

export type UpdateConfigurationData = ResponseCmsConfigurationResponse

export type UpdateConfigurationError =
  | PatchCmsConfigurationsConfigurationId400ErrorResponse
  | PatchCmsConfigurationsConfigurationId403ErrorResponse
  | PatchCmsConfigurationsConfigurationId404ErrorResponse

export type UpdateData = ResponseOfferErrorPageResponse

export type UpdateDefaultModFlavorFeaturesData = ResponseVoid

export type UpdateDefaultModFlavorFeaturesError =
  | PutModsFlavorsSettingsDefaultFeatures400ErrorResponse
  | PutModsFlavorsSettingsDefaultFeatures403ErrorResponse

export interface UpdateDefaultModFlavorFeaturesParams {
  comment?: string
  features: string[]
}

export type UpdateDisputeData = ResponseDisputeResponse

export type UpdateDisputeError =
  | PatchPaymentsPaymentIdDisputesDisputeId400ErrorResponse
  | PatchPaymentsPaymentIdDisputesDisputeId403ErrorResponse
  | PatchPaymentsPaymentIdDisputesDisputeId404ErrorResponse

export type UpdateDynamicConfigData = ResponseDynamicConfigResponse

export type UpdateDynamicConfigError =
  | PatchDynamicConfigConfigId400ErrorResponse
  | PatchDynamicConfigConfigId403ErrorResponse
  | PatchDynamicConfigConfigId404ErrorResponse

export type UpdateError =
  | PatchOffersOfferIdErrorPagesErrorPageId400ErrorResponse
  | PatchOffersOfferIdErrorPagesErrorPageId403ErrorResponse
  | PatchOffersOfferIdErrorPagesErrorPageId404ErrorResponse

/** UpdateFeedEntryAttributeRequest */
export interface UpdateFeedEntryAttributeRequest {
  /** New/updated attribute value, if `type` is `"ANY"`. Required when adding a new attribute with `type` `"ANY"`. Should be omitted or null for other types of attributes. */
  anyValue?: object | null
  /** Attribute asset variants profile key if `type` is `"ASSET"`. Should be omitted for existing attributes */
  assetVariantsProfileKey?:
    | 'AUDIO_MOD_CONTENT'
    | 'BIO_LINK_IMAGE'
    | 'CUSTOM'
    | 'GAME_BANNER'
    | 'GAME_BANNER_GIF'
    | 'IMAGE_CHALLENGE_COVER_16_9'
    | 'IMAGE_CHALLENGE_COVER_4_3'
    | 'IMAGE_EXTENSION_COVER_16_9'
    | 'IMAGE_EXTENSION_COVER_16_9_GIF'
    | 'IMAGE_EXTENSION_GRAPHIC'
    | 'IMAGE_EXTENSION_GRAPHIC_GIF'
    | 'IMAGE_GAME_COVER_16_9'
    | 'IMAGE_GAME_COVER_16_9_GIF'
    | 'IMAGE_GAME_COVER_4_3'
    | 'IMAGE_GAME_COVER_4_3_GIF'
    | 'IMAGE_GAME_GRAPHIC'
    | 'IMAGE_GAME_GRAPHIC_GIF'
    | 'IMAGE_MOD_COVER_16_9'
    | 'IMAGE_MOD_COVER_16_9_GIF'
    | 'IMAGE_MOD_GRAPHIC'
    | 'IMAGE_MOD_GRAPHIC_GIF'
    | 'IMAGE_MOD_MOBILE_WALLPAPER'
    | 'IMAGE_MOD_WALLPAPER'
    | 'IMAGE_QUEST_COVER'
    | 'IMAGE_QUEST_COVER_GIF'
    | 'IMAGE_STICKER_COVER'
    | 'IMAGE_STUDIO_COVER_4_1'
    | 'USER_AVATAR'
    | 'VIDEO_EXTENSION_COVER_16_9'
    | 'VIDEO_EXTENSION_GRAPHIC'
    | 'VIDEO_GAME_COVER_16_9'
    | 'VIDEO_GAME_COVER_4_3'
    | 'VIDEO_GAME_GRAPHIC'
    | 'VIDEO_MOD_COVER_16_9'
    | 'VIDEO_MOD_GRAPHIC'
    | 'VIDEO_MOD_WALLPAPER'
    | null
  /**
   * Attribute id of existing attribute. If provided, `name` and `type` must be omitted or null. If not provided (or if null) `name` and `type` are required.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  attributeId?: string | null
  /** New/updated string value (for the default locale), if `type` is `"LOCALIZABLE_STRING"`. Should be omitted or null for other types of attributes. */
  localizableStringValue?: string | null
  /** Name of new attribute to add. */
  name?: string | null
  /** Type of new attribute to add. */
  type?: 'ANY' | 'ASSET' | 'LOCALIZABLE_STRING' | null
}

export type UpdateFeedEntryData = ResponseFeedEntryDetailsResponse

export type UpdateFeedEntryError =
  | PatchFeedsFeedIdEntriesEntryId400ErrorResponse
  | PatchFeedsFeedIdEntriesEntryId403ErrorResponse
  | PatchFeedsFeedIdEntriesEntryId404ErrorResponse

/** UpdateFeedEntryRequest */
export interface UpdateFeedEntryRequest {
  /** Entry attributes. */
  attributes: UpdateFeedEntryAttributeRequest[]
}

/** UpdateFilteredFeedRequest */
export interface UpdateFilteredFeedRequest {
  /**
   * Game filtering expression
   * @example "{ "type": "HAS_TAG", "tagId": "a93a8eb6-df58-4551-b30e-2a1c56d9071d" }"
   */
  gameFilteringExpression?: MapStringObject | null
  /**
   * Feed sort parameters
   *
   * *Deprecated in favor of `sort.gameSort` in parent request*
   * @deprecated
   */
  gameSort?: string[] | null
  /**
   * Mod filtering expression
   * @example "{ "type": "HAS_TAG", "tagId": "a93a8eb6-df58-4551-b30e-2a1c56d9071d" }"
   */
  modFilteringExpression?: MapStringObject | null
  snapshotConfiguration?: UpdateSnapshotConfigurationRequest | null
  snapshotRefreshSchedule?: UpdateSnapshotRefreshScheduleRequest | null
  /**
   * *Deprecated in favor of `timeFrame` in parent request*
   * @deprecated
   */
  timeFrame?: UpdateManagedFeedTimeFrameRequest | null
}

export type UpdateGameBannerData = ResponseGameBannerResponse

export type UpdateGameBannerError =
  | PatchGamesGameIdBannersBannerId400ErrorResponse
  | PatchGamesGameIdBannersBannerId403ErrorResponse
  | PatchGamesGameIdBannersBannerId404ErrorResponse

/** UpdateGameBannerRequest */
export interface UpdateGameBannerRequest {
  alias: string
}

export type UpdateGameControlGroupData = ResponseGameControlGroupResponse

export type UpdateGameControlGroupError =
  | PatchGamesGameIdControlGroupsControlGroupId400ErrorResponse
  | PatchGamesGameIdControlGroupsControlGroupId403ErrorResponse
  | PatchGamesGameIdControlGroupsControlGroupId404ErrorResponse

export type UpdateGameControlGroupOrderingData = ResponseListGameControlGroupsResponse

export type UpdateGameControlGroupOrderingError =
  | PatchGamesGameIdControlGroups400ErrorResponse
  | PatchGamesGameIdControlGroups403ErrorResponse
  | PatchGamesGameIdControlGroups404ErrorResponse

/** UpdateGameControlGroupOrderingRequest */
export interface UpdateGameControlGroupOrderingRequest {
  /** Ordered list of control group IDs. Defines ordering while displaying in GXC and DC. */
  groupIds: string[]
}

/** UpdateGameControlGroupRequest */
export interface UpdateGameControlGroupRequest {
  /** Ordered list of control subgroup IDs. Defines ordering while displaying in GXC and DC. */
  subgroupIds?: string[] | null
}

export type UpdateGameControlSubgroupData = ResponseGameControlSubgroupResponse

export type UpdateGameControlSubgroupError =
  | PatchGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse
  | PatchGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse
  | PatchGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse

/** UpdateGameControlSubgroupRequest */
export interface UpdateGameControlSubgroupRequest {
  /** Ordered list of control IDs. Defines ordering while displaying in GXC and DC. */
  controlIds?: string[] | null
}

export type UpdateGameEngineData = ResponseGameEngineDetailsResponse

export type UpdateGameEngineError =
  | PatchEnginesEngineId400ErrorResponse
  | PatchEnginesEngineId403ErrorResponse
  | PatchEnginesEngineId404ErrorResponse

/** UpdateGameEngineRequest */
export interface UpdateGameEngineRequest {
  /** New game engine alias. */
  alias?: string | null
  /**
   * New game engine default value.
   *
   * Notes: *If empty or missing, the game engine default is not changed.*
   */
  isDefault?: boolean | null
  /**
   * New game engine visible value.
   *
   * Notes: *If empty or missing, the game engine visibility is not changed.*
   */
  isVisible?: boolean | null
}

export type UpdateGameItemData = ResponseGameItemResponse

export type UpdateGameItemError =
  | PatchGamesGameIdItemsItemId400ErrorResponse
  | PatchGamesGameIdItemsItemId403ErrorResponse
  | PatchGamesGameIdItemsItemId404ErrorResponse
  | PatchGamesGameIdItemsItemId500ErrorResponse

/** UpdateGameRequest */
export interface UpdateGameRequest {
  /** @example "EVERYONE" */
  ageRating?:
    | 'NOT_SET'
    | 'EVERYONE'
    | 'CHILDREN'
    | 'EARLY_TEENS'
    | 'TEENS'
    | 'ADULTS'
    | 'MATURE'
    | null
  /** true if the game is supposed to support FULL_VERSION game purchase */
  allowFullVersionPurchase?: boolean | null
  /** Whether to allow legacy saves for the game. If `null` or absent, the setting is not changed. */
  allowLegacySaves?: boolean | null
  /** `true` if the game is supposed to support game purchase */
  allowPaidGamePurchase?: boolean | null
  /**
   * If no value is given, the setting is not updated; otherwise:
   * - `true` allows preloading for this game;
   * - `false` disables preloading for this game;
   * - `null` uses the default preloading settings.
   */
  allowPreloading?: boolean | null
  /**
   * Whether to allow cloud saves for the game.
   *
   * Notes: *If missing, the setting is not changed. If `null`, the per-game override is removed, thus falling back to the system default.*
   */
  allowSaves?: boolean | null
  /**
   * Notes: *Ordered list of IDs of the game media files*
   * @example "["94a939c1-2d93-48e8-ab12-c036954a0fa4", "f9f52b0a-0e87-479e-97b7-254c80b5e12c"]"
   */
  gallery?: string[] | null
  /**
   * New game engine.
   *
   * Notes: *If missing, game engine is not changed. If null, game engine is cleared.*
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameEngineId?: string | null
  /** @example "gg2wom" */
  gameShortId?: string | null
  /**
   * @example "help Sulka find a new home!
   *
   * _≻ 20 tiny levels to solve ≺_
   *
   * made in 60 hours
   * <3
   *
   * "
   */
  longDescription?: string | null
  /**
   * Payment vendor that will be used for purchases.
   * @example "XSOLLA"
   */
  paymentProvider?: 'STRIPE' | 'XSOLLA' | null
  /** @example "["DESKTOP", "MOBILE"]" */
  platforms?: ('DESKTOP' | 'MOBILE')[] | null
  /**
   * New publisher.
   *
   * Notes: *If missing, the publisher is not changed. If null, the publisher is cleared.*
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  publisherId?: string | null
  /**
   * Limit in KB to the save revision size for the game.
   *
   * Notes: *If missing, the setting is not changed. If `null`, the per-game override is removed, thus falling back to the system default.*
   * @format int32
   * @example 500
   */
  saveRevisionSizeLimit?: number | null
  /** @example "help Sulka find a new home!" */
  shortDescription?: string | null
  /**
   * *Deprecated in favor of `gameShortId`*
   * @deprecated
   * @example "gg2wom"
   */
  shortId?: string | null
  /**
   * Revenue share % when selling game items on Stripe.
   * @example "87"
   */
  stripeGameDeveloperRevenueSharePercentage?: string | null
  /**
   * Price of tipping sticker for the game
   * @format int32
   */
  tippingStickerPrice?: number | null
  /** @example "Sulka" */
  title?: string | null
  /**
   * New 'uses default engine' value.
   *
   * Notes: *If empty or missing, value is not changed.*
   */
  usesDefaultEngine?: boolean | null
}

/** UpdateGmsRunnerRequest */
export interface UpdateGmsRunnerRequest {
  /** Set to `true` to deprecate this runner version: it can still play gamesbut developers won't be able to push new releases that run on it. */
  deprecated?: boolean | null
  /** @example "A new description for this runner version." */
  description?: string | null
  /** @example "1.0.3+25" */
  runnerVersionName?: string | null
}

export type UpdateGraphicSettingData = ResponseLegacySettingResponse

export type UpdateGraphicSettingError =
  | PutSettingsSettingKey400ErrorResponse
  | PutSettingsSettingKey403ErrorResponse
  | PutSettingsSettingKey404ErrorResponse

export interface UpdateGraphicSettingPayload {
  /** @format binary */
  file?: File
}

/** UpdateManagedFeedRequest */
export interface UpdateManagedFeedRequest {
  /** @example "popular-among-middle-aged-dutch-wood-carvers-living-in-haparanda" */
  alias?: string | null
  /**
   * The scheduled time to apply pending changes.
   *
   * Notes: *A null value means that changes are not scheduled to be applied automatically  and changes can be applied manually.*
   * @format date-time
   */
  applyPendingChangesAt?: string | null
  /** If `true`, the global filter is NOT applied when using this feed. */
  bypassGlobalFilter?: boolean | null
  /** If `true`, the daily feed entries rotation enabled. */
  entriesRotationEnabled?: boolean | null
  /**
   * Notes: ***Sorted** list of entry IDs for this feed*
   * @example "["f26d9a15-4f65-4412-8590-5a69b46009d1", "a93a8eb6-df58-4551-b30e-2a1c56d9071d"]"
   */
  entryIds?: string[] | null
  filteredFeed?: UpdateFilteredFeedRequest | null
  /** If `true`, select this feed as the global filter. If `false` and this feed is the global filter, unselect it such that there is no global filter. */
  isGlobalFilter?: boolean | null
  sort?: UpdateManagedFeedSortRequest | null
  timeFrame?: UpdateManagedFeedTimeFrameRequest | null
  /** Only required for special feed types like e.g. game jams; can be `null` otherwise */
  type?: 'CUSTOM_FEED' | 'GAME_JAM' | 'USER_JAM' | 'VOTING' | 'FILTERED_FEED' | null
}

/** UpdateManagedFeedSortRequest */
export interface UpdateManagedFeedSortRequest {
  /** Game sort parameters */
  gameSort?: string[] | null
  /** Mod sort parameters */
  modSort?: string[] | null
}

/**
 * UpdateManagedFeedTimeFrameEndpointRequest
 * Time frame endpoint (start or end). Exactly one of the `absoluteDate` and `relativeDate` fields must be non-null, and the other null or omitted.
 */
export interface UpdateManagedFeedTimeFrameEndpointRequest {
  /**
   * @format date
   * @example "2022-11-14"
   */
  absoluteDate?: string
  /**
   * Relative date in milliseconds.
   *
   * Notes: *Subtracted from the current time to get the effective date.*
   * @format int64
   */
  relativeDate?: number | null
}

/** UpdateManagedFeedTimeFrameRequest */
export interface UpdateManagedFeedTimeFrameRequest {
  /** Time frame endpoint (start or end). Exactly one of the `absoluteDate` and `relativeDate` fields must be non-null, and the other null or omitted. */
  end?: UpdateManagedFeedTimeFrameEndpointRequest | null
  /** Time frame endpoint (start or end). Exactly one of the `absoluteDate` and `relativeDate` fields must be non-null, and the other null or omitted. */
  start?: UpdateManagedFeedTimeFrameEndpointRequest | null
}

export type UpdateModData = ResponseModDetailsResponse

export type UpdateModError =
  | PatchModsModId400ErrorResponse
  | PatchModsModId403ErrorResponse
  | PatchModsModId404ErrorResponse

/** UpdateModReleaseTrackRequest */
export interface UpdateModReleaseTrackRequest {
  /** Whether the release track should be published. */
  published: boolean
}

/** UpdateModRequest */
export interface UpdateModRequest {
  /** @example "ADULTS" */
  ageRating?:
    | 'NOT_SET'
    | 'EVERYONE'
    | 'CHILDREN'
    | 'EARLY_TEENS'
    | 'TEENS'
    | 'ADULTS'
    | 'MATURE'
    | null
  /**
   * If present, this feature list is used as the default mod flavor features for this mod.
   * @example "["mod.themes"]"
   */
  defaultModFlavorFeatures?: string[] | null
  /**
   * Notes: *Ordered list of IDs of the mod media files*
   * @example "["94a939c1-2d93-48e8-ab12-c036954a0fa4", "f9f52b0a-0e87-479e-97b7-254c80b5e12c"]"
   */
  gallery?: string[] | null
  /** If not null, set whether this mod should be hidden from the response of `GET /store/v3/mods`. */
  hiddenFromListing?: boolean | null
  /**
   * @example "Some longer description...
   * With **markdown** support!"
   */
  longDescription?: string | null
  /** @example "8au4e2" */
  shortId?: string | null
  /** If not null, enable or disable use of wrapper. Note: Mod won't work with disabled wrapper. */
  useWrapper?: boolean | null
  /** If true, automatic wrapper deployment releases are enabled for this mod. If false, automatic wrapper deployment releases are disabled for this mod. If null or omitted, the setting is left unchanged. */
  wrapperDeploymentReleasesEnabled?: boolean | null
  /**
   * If missing, the wrapperId is not changed. If null, the wrapperId is cleared.Otherwise, a valid wrapper id will be required to replace the existing one.
   * @example "42f69826-610a-431c-aa15-d60429121054"
   */
  wrapperId?: string | null
}

/** UpdateOfferCodeRequest */
export interface UpdateOfferCodeRequest {
  /** New active state (true or false), or null to leave the active state unchanged. */
  active?: boolean | null
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
  /** New batch alias, or null to leave the batch alias unchanged. */
  batchAlias?: string | null
  /** New code value, or null to leave the code value unchanged. */
  value?: string | null
}

/** UpdateOfferCodesRequest */
export interface UpdateOfferCodesRequest {
  /** New active state (true or false), or null to leave the active state unchanged. */
  active?: boolean | null
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
  /** New batch alias, or null to leave the batch alias unchanged. */
  batchAlias?: string | null
  /** Ids of set of codes to update, or null to update codes selected via query parameters. */
  codeIds?: string[] | null
}

export type UpdateOfferData = ResponseOfferResponse

export type UpdateOfferError =
  | PatchOffersOfferId400ErrorResponse
  | PatchOffersOfferId403ErrorResponse
  | PatchOffersOfferId404ErrorResponse

/** UpdateOfferErrorPageRequest */
export interface UpdateOfferErrorPageRequest {
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
}

/** UpdateOfferRequest */
export interface UpdateOfferRequest {
  /** New active state (true or false), or null to leave the active state unchanged. */
  active?: boolean | null
  /** New alias. Must be unique among non-archived offers. */
  alias?: string | null
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
  /** New internal flag (true or false), or null to leave the internal flag unchanged. */
  internal?: boolean | null
  /**
   * New maximum number of claimed codes per calendar day (UTC), or null to leave the setting unchanged.
   * @format int32
   */
  maxClaimsPerDay?: number | null
  /**
   * New number of reserved codes, or null to leave the setting unchanged.
   * @format int32
   */
  numberOfReservedCodes?: number | null
  /**
   * Id of new partner.
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  partnerId?: string | null
}

export type UpdateOfficialDistributionKeyData = ResponseOdkDetailsResponse

export type UpdateOfficialDistributionKeyError =
  | PatchOdksKeyId400ErrorResponse
  | PatchOdksKeyId403ErrorResponse
  | PatchOdksKeyId404ErrorResponse

/** UpdateOfficialDistributionKeyRequest */
export interface UpdateOfficialDistributionKeyRequest {
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
}

export type UpdatePartnerData = ResponsePartnerResponse

export type UpdatePartnerError =
  | PatchOfferPartnersPartnerId400ErrorResponse
  | PatchOfferPartnersPartnerId403ErrorResponse
  | PatchOfferPartnersPartnerId404ErrorResponse

export type UpdatePartnerFail =
  | PatchPartnersPartnerId400ErrorResponse
  | PatchPartnersPartnerId403ErrorResponse
  | PatchPartnersPartnerId404ErrorResponse

/** UpdatePartnerRequest */
export interface UpdatePartnerRequest {
  /** New alias. Must be unique among non-archived partners. */
  alias?: string | null
  /** New archived state (true or false), or null to leave the archived state unchanged. */
  archived?: boolean | null
}

export type UpdatePartnerResult = ResponsePartnerResponse

export type UpdatePromotedChallengeData = ResponsePromotedChallengeResponse

export type UpdatePromotedChallengeError =
  | PatchPromotedChallengesPromotedChallengeId400ErrorResponse
  | PatchPromotedChallengesPromotedChallengeId403ErrorResponse
  | PatchPromotedChallengesPromotedChallengeId404ErrorResponse

/** UpdatePromotedChallengeRequest */
export interface UpdatePromotedChallengeRequest {
  /** Underlying challenge's long description */
  challengeLongDescription?: string | null
  /** Underlying challenge's name */
  challengeName?: string | null
  /** Underlying challenge's short description */
  challengeShortDescription?: string | null
  /**
   * promotion be inactive from this date
   * @format date-time
   */
  endsAt?: string | null
  /** long description of challenge promotion */
  longDescription?: string | null
  /** whether to open/close the challenge promotion */
  open?: boolean | null
  /** whether to publish/unpublish the challenge promotion */
  published?: boolean | null
  /**
   * season id
   *
   * Notes: *if set to an empty string, the season association is cleared*
   * @example "a93a8eb6-df58-4551-b30e-2a1c56d9071d"
   */
  seasonId?: string | null
  /** short description of challenge promotion */
  shortDescription?: string | null
  /**
   * promotion be active from this date if it is also published
   * @format date-time
   */
  startsAt?: string | null
}

export type UpdateRankingsData = ResponseChallengesResponse

export type UpdateRankingsError =
  | PatchChallengesRankings400ErrorResponse
  | PatchChallengesRankings403ErrorResponse
  | PatchChallengesRankings404ErrorResponse

export interface UpdateRankingsParams {
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  challengeId?: string
  /**
   * The page to return. The response will contain Challenges in the range `[page * pageSize, (page + 1) * pageSize]`.
   * @format int32
   * @default 0
   */
  page?: number
  /**
   * The (maximum) number of Challenges to include in the response. Fewer Challenges may be included if this is the last page.
   * @format int32
   * @default 25
   */
  pageSize?: number
}

export type UpdateRootTagsOrderingData = ResponseListTagSummaryResponse

export type UpdateRootTagsOrderingError = PatchTags400ErrorResponse | PatchTags403ErrorResponse

export type UpdateRunnerData = ResponseGmsRunnerResponse

export type UpdateRunnerError =
  | PatchGmsRunnersRunnerId400ErrorResponse
  | PatchGmsRunnersRunnerId403ErrorResponse
  | PatchGmsRunnersRunnerId404ErrorResponse

export type UpdateSectionData = ResponseCmsSectionResponse

export type UpdateSectionError =
  | PatchCmsConfigurationsConfigurationIdSectionsSectionId400ErrorResponse
  | PatchCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse
  | PatchCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse

export type UpdateSettingsData = ResponseVoid

export type UpdateSettingsError =
  | PatchSettings400ErrorResponse
  | PatchSettings403ErrorResponse
  | PatchSettings404ErrorResponse

/** UpdateSnapshotConfigurationRequest */
export interface UpdateSnapshotConfigurationRequest {
  /**
   * Snapshot refresh interval in milliseconds
   *
   * Notes: *A null value means no new automatic refresh is scheduled after the next automatic refresh.*
   * @format int64
   * @example 604800000
   */
  refreshInterval?: number | null
}

/** UpdateSnapshotRefreshScheduleRequest */
export interface UpdateSnapshotRefreshScheduleRequest {
  /**
   * Time of next scheduled/automatic snapshot refresh.
   *
   * Notes: *A null value means no automatic refresh is scheduled. The current snapshot remains and is still used, and can be manually refreshed.*
   * @format date-time
   */
  nextScheduledRefreshAt?: string | null
}

export type UpdateStickerData = ResponseStickerResponse

export type UpdateStickerError =
  | PatchStickersStickerId400ErrorResponse
  | PatchStickersStickerId403ErrorResponse
  | PatchStickersStickerId404ErrorResponse

/** UpdateStickerRequest */
export interface UpdateStickerRequest {
  /** The new value for whether the sticker is active, or absent/`null` to leave it as is */
  active?: boolean | null
  /** The new alias, or absent/`null` to leave it as is. */
  alias?: string | null
  /**
   * The new amount, or absent/`null` to leave it as is.
   * @format int32
   */
  amount?: number | null
}

export type UpdateStringData = ResponseStringResponse

export type UpdateStringError =
  | PatchStringsStringId400ErrorResponse
  | PatchStringsStringId403ErrorResponse
  | PatchStringsStringId404ErrorResponse

/** UpdateStringRequest */
export interface UpdateStringRequest {
  /**
   * Updated translations.
   *
   * Any current translation (i.e. locale) not included in the list is deleted. Current
   * translations included in the list are updated with the new value (if changed).
   *
   * Any new translation (i.e. locale) included in the list is added, in which case the
   * locale name must be one listed in the ADDITIONAL_LOCALES setting's value.
   */
  translations?: UpdateStringTranslationRequest[] | null
  /** Value for default locale, and fallback value for unsupported locales. */
  value?: string | null
}

/** UpdateStringTranslationRequest */
export interface UpdateStringTranslationRequest {
  /**
   * Locale of translation.
   * @example "de_DE"
   */
  locale: string
  /** Translated string value. */
  value: string
}

/** UpdateStudioRequest */
export interface UpdateStudioRequest {
  /** Controls whether the studio is allowed to upload game builds using Dev portal. If this field is not in the request body, the setting is not changed; if `null`, the setting is cleared and the studio may be allowed based on the global setting; otherwise, the global setting is overridden just for this studio, where `true` allows custom game builds and `false` doesn't. */
  allowCustomGameBuilds?: boolean | null
  /** @example "email@address" */
  contactEmail?: string | null
  /** @example "We proudly make 5/7 games" */
  longDescription?: string | null
  /** @example "Lagom Bra Games studio" */
  name?: string | null
  /**
   * Revenue share % when selling game items on Stripe.
   *
   * Notes: *Valid for all games made by this studio unless overwritten on a per-game basis.*
   * @example "87"
   */
  stripeStudioRevenueSharePercentage?: string | null
}

export type UpdateStudioSettingsData = ResponseLegacyStudioSettingsResponse

export type UpdateStudioSettingsError =
  | PatchStudiosStudioIdSettings400ErrorResponse
  | PatchStudiosStudioIdSettings403ErrorResponse
  | PatchStudiosStudioIdSettings404ErrorResponse

export type UpdateStudioSettingsV2Data = ResponseStudioSettingsResponse

export type UpdateStudioSettingsV2Error =
  | PatchV2StudiosStudioIdSettings400ErrorResponse
  | PatchV2StudiosStudioIdSettings403ErrorResponse
  | PatchV2StudiosStudioIdSettings404ErrorResponse

/** UpdateSystemAlertStatusRequest */
export interface UpdateSystemAlertStatusRequest {
  /** @example "A new status to set" */
  status: 'NEW' | 'RESOLVED'
}

export type UpdateTagData = ResponseTagDetailsResponse

export type UpdateTagError =
  | PatchTagsTagId400ErrorResponse
  | PatchTagsTagId403ErrorResponse
  | PatchTagsTagId404ErrorResponse

/** UpdateTagRequest */
export interface UpdateTagRequest {
  /**
   * New tag alias.
   *
   * Notes: *If empty or missing, the tag alias is not changed.*
   */
  alias?: string | null
  /**
   * New tag availableToCreators flag. Set to true to allow game contributors add/remove this tag to games in DC.
   *
   * Notes: *If missing, the tag's availableToCreators flag's value is not changed.*
   */
  availableToCreators?: boolean | null
  /**
   * Ordered list of children tags. Defines ordering while displaying tags in GXC and DC.
   *
   * Notes: *If missing, tags display order is not changed.*
   */
  childrenTagIds?: string[] | null
  /**
   * New tag display order. Defines ordering while displaying tags in GXC and DC.
   *
   * Notes: *If missing, the tag's display order value is not changed.*
   * @format int32
   */
  displayOrder?: number | null
  /**
   * New tag internal flag. Set to false to make tag visible in GXC and DC.
   *
   * Notes: *If missing, the tag's internal flag's value is not changed.*
   */
  internal?: boolean | null
  /**
   * New parent tag id.
   *
   * Notes: *If empty or missing, the tag's parent tag is removed.*
   */
  parentTagId?: string | null
}

/** UpdateTagsRequest */
export interface UpdateTagsRequest {
  /** Ordered list of root tags. Defines ordering while displaying tags in GXC and DC. */
  tagIds: string[]
}

export type UpdateTopScoresData = ResponseVoid

export type UpdateTopScoresError = PatchChallengesTopScores403ErrorResponse

export type UpdateTrackData = ResponseModDetailsResponse

export type UpdateTrackError =
  | PatchModsModIdTracksTrackId400ErrorResponse
  | PatchModsModIdTracksTrackId403ErrorResponse
  | PatchModsModIdTracksTrackId404ErrorResponse

export type UpdateUserData = ResponseBackOfficeUserResponse

export type UpdateUserError =
  | PatchAdministrationUsersBoUserId400ErrorResponse
  | PatchAdministrationUsersBoUserId403ErrorResponse
  | PatchAdministrationUsersBoUserId404ErrorResponse

/** UpdateUserRequest */
export interface UpdateUserRequest {
  /**
   * Max non-archived bank accounts this user can have.
   *
   * Notes: *If missing, max accounts is not changed. If null, max accounts is reset to the system default.*
   * @format int32
   */
  maxBankAccounts?: number | null
}

export type UploadBioLinkIconData = ResponseBioLinkResponse

export type UploadBioLinkIconError =
  | PutUserUserIdBioLinksLinkIdIcon400ErrorResponse
  | PutUserUserIdBioLinksLinkIdIcon403ErrorResponse
  | PutUserUserIdBioLinksLinkIdIcon404ErrorResponse
  | PutUserUserIdBioLinksLinkIdIcon500ErrorResponse

export interface UploadBioLinkIconPayload {
  /** @format binary */
  file?: File
}

export interface UploadBody {
  /** @format binary */
  file?: File
}

export interface UploadCoverBody {
  /** @format binary */
  file?: File
}

export type UploadCoverData = ResponseVoid

export type UploadCoverError =
  | PutQuestsQuestIdCover400ErrorResponse
  | PutQuestsQuestIdCover403ErrorResponse
  | PutQuestsQuestIdCover404ErrorResponse

export type UploadCoverFail =
  | PutStickersStickerIdCover400ErrorResponse
  | PutStickersStickerIdCover403ErrorResponse
  | PutStickersStickerIdCover404ErrorResponse

export type UploadCoverFails =
  | PostStudiosStudioIdCovers400ErrorResponse
  | PostStudiosStudioIdCovers403ErrorResponse
  | PostStudiosStudioIdCovers404ErrorResponse

export interface UploadCoverInput {
  /** @format binary */
  file?: File
}

export type UploadCoverOutput = ResponseCoverResponse

export interface UploadCoverPayload {
  /** @format binary */
  file?: File
}

export type UploadCoverResult = ResponseVoid

export type UploadCoverV2Data = ResponseCoverResponse

export type UploadCoverV2Error =
  | PutV2ChallengesChallengeIdCover400ErrorResponse
  | PutV2ChallengesChallengeIdCover403ErrorResponse
  | PutV2ChallengesChallengeIdCover404ErrorResponse
  | PutV2ChallengesChallengeIdCover500ErrorResponse

export interface UploadCoverV2Params {
  /** Aspect ratio for the cover to upload: can only be either 4:3 or 16:9 */
  aspectRatio: '4:3' | '16:9'
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  challengeId: string
}

export interface UploadCoverV2Payload {
  /** @format binary */
  file?: File
}

export type UploadData = ResponseGameWrapperResponse

export type UploadEntryAttributeAssetData = ResponseFeedEntryDetailsResponse

export type UploadEntryAttributeAssetError =
  | PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400ErrorResponse
  | PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403ErrorResponse
  | PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404ErrorResponse

export interface UploadEntryAttributeAssetPayload {
  /** @format binary */
  file?: File
}

export type UploadError =
  | PutGameWrappersGameWrapperIdUpload400ErrorResponse
  | PutGameWrappersGameWrapperIdUpload403ErrorResponse
  | PutGameWrappersGameWrapperIdUpload404ErrorResponse
  | PutGameWrappersGameWrapperIdUpload500ErrorResponse

export type UploadFail =
  | PutOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse
  | PutOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse
  | PutOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse

export type UploadFails =
  | PutWrappersWrapperIdUpload400ErrorResponse
  | PutWrappersWrapperIdUpload403ErrorResponse
  | PutWrappersWrapperIdUpload404ErrorResponse
  | PutWrappersWrapperIdUpload500ErrorResponse

export type UploadGameBannerData = ResponseGameBannerResponse

export type UploadGameBannerError =
  | PostGamesGameIdBanners400ErrorResponse
  | PostGamesGameIdBanners403ErrorResponse
  | PostGamesGameIdBanners404ErrorResponse
  | PostGamesGameIdBanners500ErrorResponse

export interface UploadGameBannerParams {
  /** Alias for a game banner */
  alias: string
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
}

export interface UploadGameBannerPayload {
  /** @format binary */
  file?: File
}

export type UploadGameCoverData = ResponseCoverResponse

export type UploadGameCoverError =
  | PutGamesGameIdCover400ErrorResponse
  | PutGamesGameIdCover403ErrorResponse
  | PutGamesGameIdCover404ErrorResponse
  | PutGamesGameIdCover500ErrorResponse

export interface UploadGameCoverParams {
  /** Aspect ratio for the cover to upload: can only be either 4:3 or 16:9 */
  aspectRatio: '4:3' | '16:9'
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  gameId: string
  /**
   * Media type of game cover
   * @default "IMAGE"
   */
  type?: 'IMAGE' | 'VIDEO'
}

export interface UploadGameCoverPayload {
  /** @format binary */
  file?: File
}

export interface UploadInput {
  /** @format binary */
  file?: File
}

export type UploadModCoverData = ResponseCoverResponse

export type UploadModCoverError =
  | PutModsModIdCover400ErrorResponse
  | PutModsModIdCover403ErrorResponse
  | PutModsModIdCover404ErrorResponse
  | PutModsModIdCover500ErrorResponse

export interface UploadModCoverParams {
  /** Aspect ratio for the cover to upload: can only be 16:9 */
  aspectRatio: '16:9'
  /**
   * | Prefix | Example | Description |
   * | ------ | ------- | ----------- |
   * | (none) | a93a8eb6-df58-4551-b30e-2a1c56d9071d | Identify by full UUID4 identifier. |
   * | `shortid:` (optional) | shortid:a1b2c3 | Identify by SEO-friendly short id. |
   * @example "363b2a7b-671c-45ed-8975-0ebf62c40abf"
   */
  modId: string
  /**
   * Media type of mod cover
   * @default "IMAGE"
   */
  type?: 'IMAGE' | 'VIDEO'
}

export interface UploadModCoverPayload {
  /** @format binary */
  file?: File
}

export type UploadModGraphicsData = ResponseMediaResponse

export type UploadModGraphicsError =
  | PutModsModIdGraphics400ErrorResponse
  | PutModsModIdGraphics403ErrorResponse
  | PutModsModIdGraphics404ErrorResponse
  | PutModsModIdGraphics500ErrorResponse

export interface UploadModGraphicsPayload {
  /** @format binary */
  file?: File
  /** @format binary */
  preview?: File
}

export type UploadMultiplayerGuestAvatarData = ResponseMultiplayerGuestAvatarResponse

export type UploadMultiplayerGuestAvatarError =
  | PutMultiplayerGuestAvatars400ErrorResponse
  | PutMultiplayerGuestAvatars403ErrorResponse
  | PutMultiplayerGuestAvatars500ErrorResponse

export interface UploadMultiplayerGuestAvatarPayload {
  /** @format binary */
  file?: File
}

export type UploadOutput = ResponseWrapperResponse

export interface UploadPayload {
  /** @format binary */
  file?: File
}

export type UploadResult = ResponseOfferErrorPageResponse

/** UserFeedbackResponse */
export interface UserFeedbackResponse {
  /** @example "This game runs very slow and crashes when I press Space :(" */
  comment?: string | null
  /**
   * @format date-time
   * @example "2022-01-13T05:22:33.536Z"
   */
  createdAt?: string | null
  developerFollowup?: DeveloperFollowupResponse | null
  /** @example "9dcb3a01-1722-4940-b807-576703ea47a5" */
  feedbackId: string
  /**
   * @format date-time
   * @example "2022-01-22T15:02:56.992Z"
   */
  lastModified?: string | null
  status?: 'UNRESOLVED' | 'RESOLVED' | null
  type?: 'BUG_REPORT' | 'FEATURE_REQUEST' | null
  user?: UserSummaryResponse | null
}

/** UserPartnerAccountAssociationResponse */
export interface UserPartnerAccountAssociationResponse {
  /** True if the association has been archived */
  archived?: boolean | null
  /** The back-office user that archived the association, or null if `archived` is false or if the association was not archived via the back-office. */
  archivedBy?: BackOfficeUserResponse | null
  /**
   * When the association was created
   * @format date-time
   */
  createdAt?: string | null
  /** Back-office user that manually created the association, or null if it was not created manually */
  createdBy?: BackOfficeUserResponse | null
  partner?: PartnerResponse | null
  /** Unique identifier of the account on the partner's side */
  partnerAccountId: string
}

/** UserResponse */
export interface UserResponse {
  /** List of user bio links */
  bioLinks?: BioLinkResponse[] | null
  /** The user's birthdate */
  birthdate?: string | null
  /** Description of the user */
  description?: string | null
  /** @example "steve.lobster@mail.com" */
  email?: string | null
  emailVerified?: boolean | null
  emailingConsent?: EmailingConsentResponse[] | null
  /** When the user was last active */
  lastVisited?: string | null
  /** @example "Steve Lobster" */
  name?: string | null
  /** @example "OPERA_AUTH_USER_ID_135135_ad" */
  operaAuthUserId?: string | null
  /** List of associated partner accounts */
  partnerAccounts?: UserPartnerAccountAssociationResponse[] | null
  /** @example "https://play.gmx.dev/users/123-ab-45-cde-6789?q1w23er45tyyui" */
  pictureUrl?: string | null
  /** User settings response */
  settings?: UserSettingsResponse | null
  /** @example "89fb9376-6a15-4cf3-80fc-066f945e81d9" */
  userId: string
  /**
   * User entity version
   *
   * Notes: *Will be an empty string if the user's version has not been initialized.*
   * @example "1"
   */
  userVersion?: string | null
  /** @example "steve_lobster" */
  username?: string | null
}

/** UserSettingsResponse */
export interface UserSettingsResponse {
  /**
   * The number of max non-archived bank accounts a user can create.
   * @format int32
   */
  maxBankAccounts?: number | null
}

/** UserSummaryResponse */
export interface UserSummaryResponse {
  /** @example "steve.lobster@mail.com" */
  email?: string | null
  /** @example "Steve Lobster" */
  name?: string | null
  /** @example "OPERA_AUTH_USER_ID_135135_ad" */
  operaAuthUserId?: string | null
  /** @example "https://play.gmx.dev/users/123-ab-45-cde-6789?q1w23er45tyyui" */
  pictureUrl?: string | null
  /** @example "89fb9376-6a15-4cf3-80fc-066f945e81d9" */
  userId: string
  /**
   * User entity version
   *
   * Notes: *Will be an empty string if the user's version has not been initialized.*
   * @example "1"
   */
  userVersion?: string | null
  /** @example "steve_lobster" */
  username?: string | null
}

/** UsersResponse */
export interface UsersResponse {
  pagination: PaginationResponse
  users: UserResponse[]
}

/** VideoUploadsResponse */
export interface VideoUploadsResponse {
  /**
   * Number of pending video uploads for scores.
   * @format int32
   */
  number?: number | null
}

/** Void */
export type Void = object

/** WaitListResponse */
export interface WaitListResponse {
  /** Wait list email topic */
  emailTopic?: string | null
  /**
   * Number of subscribers that are linked to registered GX.games users
   * @format int32
   */
  subscribedUsers?: number | null
  /**
   * Total file size of uploaded attachments
   * @format int64
   */
  totalFileSize?: number | null
  /**
   * Total number of subscribers
   * @format int32
   */
  totalSubscribers?: number | null
}

/** WaitlistTopicResponse */
export interface WaitlistTopicResponse {
  alias?: string | null
}

/** Back Office wallpaper preview */
export interface WallpaperPreviewResponse {
  /** @example "f26d9a15-4f65-4412-8590-5a69b46009d1" */
  assetId?: string | null
  /**
   * Name of preview
   * @example "200x150"
   */
  name?: string | null
  /**
   * Type of preview theme
   * @example "DARK"
   */
  themeType?: string | null
  /** @example "https://example.com/123456.png" */
  url?: string | null
  /** Wallpaper Id in manifest */
  wallpaperId: string
}

/** WrapperDeploymentReleaseResponse */
export interface WrapperDeploymentReleaseResponse {
  /**
   * Unique id of the created release, or null
   * @example "d41b8df5-08f1-45b9-8949-ed4d605c61d7"
   */
  createdReleaseId?: string | null
  /**
   * Unique id of the releasable (mod / game)
   * @example "d84f336a-5967-4f5a-9ba3-9be40b812de7"
   */
  releasableId?: string | null
  /**
   * Unique id of the release track
   * @example "8a93a2fa-833a-4736-a4c5-79cc0bbae3fc"
   */
  releaseTrackId?: string | null
  /**
   * Unique id of the source release (current release before deployment)
   * @example "ee710e9e-6fc5-4933-ad83-1767b999acf2"
   */
  sourceReleaseId?: string | null
  /** Status of release processing */
  status?:
    | 'CREATED'
    | 'SKIPPED_WRAPPER_UP_TO_DATE'
    | 'SKIPPED_WRAPPER_DISABLED'
    | 'SKIPPED_WRAPPER_OVERRIDDEN'
    | 'SKIPPED_AUTOMATIC_RELEASES_DISABLED'
    | 'SKIPPED_PENDING_RELEASE'
    | 'SKIPPED_ERROR'
    | null
  /** Type of the release track */
  trackType?: 'INTERNAL' | 'PUBLIC' | null
}

/** WrapperDeploymentReleasesResponse */
export interface WrapperDeploymentReleasesResponse {
  pagination: PaginationResponse
  releases: WrapperDeploymentReleaseResponse[]
}

/** WrapperDeploymentResponse */
export interface WrapperDeploymentResponse {
  /**
   * Time at which the deployment was aborted, or null
   * @format date-time
   */
  abortedAt?: string | null
  /** Back office user that aborted the deployment, or null */
  abortedBy?: BackOfficeUserResponse | null
  /** True if the deployment is currently ongoing */
  active?: boolean | null
  /**
   * Time at which the deployment was completed, or null
   * @format date-time
   */
  completedAt?: string | null
  /**
   * Unique id of wrapper deployment
   * @example "2cdfddda-885c-4c15-938a-550599f492f7"
   */
  deploymentId: string
  /**
   * Time at which the deployment was initiated
   * @format date-time
   */
  initiatedAt?: string | null
  /** Back office user that initiated the deployment */
  initiatedBy?: BackOfficeUserResponse | null
  /**
   * Estimated number of release tracks / releases that will be processed by this deployment
   * @format int32
   */
  numberOfAffectedReleases?: number | null
  /**
   * Number of release tracks / releases that have been processed (so far) by this deployment
   * @format int32
   */
  numberOfProcessedReleases?: number | null
  /**
   * Unique id of the deployed wrapper
   * @example "916474db-093d-430f-bfca-aa797705acbf"
   */
  wrapperId: string
}

/** WrapperDeploymentsResponse */
export interface WrapperDeploymentsResponse {
  deployments: WrapperDeploymentResponse[]
  pagination: PaginationResponse
}

/** WrapperResponse */
export interface WrapperResponse {
  active?: boolean | null
  bucketIdentifier?: 'GX' | 'BONUS' | 'STORE' | null
  default?: boolean | null
  /** @format date-time */
  defaultSince?: string | null
  /** @format date-time */
  deletedAt?: string | null
  deletedBy?: BackOfficeUserResponse | null
  /** Most recent deployment of this wrapper, or null */
  lastDeployment?: WrapperDeploymentResponse | null
  name?: string | null
  type?: 'GAME' | 'MOD' | null
  /** @format date-time */
  uploadedAt?: string | null
  uploadedBy?: BackOfficeUserResponse | null
  wrapperId: string
}

/** WrappersResponse */
export interface WrappersResponse {
  pagination: PaginationResponse
  wrappers: WrapperResponse[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = ''
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string' ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      return data
    })
  }
}

/**
 * @title Public REST API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  administration = {
    /**
     * No description
     *
     * @tags administration, InternalController
     * @name ElevateBackOfficeUser
     * @summary Elevate the currently logged in back-office user to admin
     * @request POST:/bo/administration/users/elevate
     * @secure
     * @response `200` `ElevateBackOfficeUserData`
     * @response `403` `PostAdministrationUsersElevate403ErrorResponse`
     */
    elevateBackOfficeUser: (query: ElevateBackOfficeUserParams, params: RequestParams = {}) =>
      this.request<ElevateBackOfficeUserData, ElevateBackOfficeUserError>({
        path: `/bo/administration/users/elevate`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags administration, UsersController
     * @name GetUsers
     * @summary List all back-office users
     * @request GET:/bo/administration/users
     * @secure
     * @response `200` `GetUsersData`
     * @response `400` `GetAdministrationUsers400ErrorResponse`
     * @response `403` `GetAdministrationUsers403ErrorResponse`
     */
    getUsers: (query: GetUsersParams, params: RequestParams = {}) =>
      this.request<GetUsersData, GetUsersError>({
        path: `/bo/administration/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags administration, UsersController
     * @name UpdateUser
     * @summary Update a back-office user
     * @request PATCH:/bo/administration/users/{boUserId}
     * @secure
     * @response `200` `UpdateUserData`
     * @response `400` `PatchAdministrationUsersBoUserId400ErrorResponse`
     * @response `403` `PatchAdministrationUsersBoUserId403ErrorResponse`
     * @response `404` `PatchAdministrationUsersBoUserId404ErrorResponse`
     */
    updateUser: (boUserId: string, data: UpdateBackOfficeUserRequest, params: RequestParams = {}) =>
      this.request<UpdateUserData, UpdateUserError>({
        path: `/bo/administration/users/${boUserId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  alerts = {
    /**
     * No description
     *
     * @tags alerts, SystemAlertsController
     * @name GetAlerts
     * @summary Fetch list of BO alerts.
     * @request GET:/bo/alerts
     * @secure
     * @response `200` `GetAlertsData`
     * @response `400` `GetAlerts400ErrorResponse`
     * @response `403` `GetAlerts403ErrorResponse`
     */
    getAlerts: (query: GetAlertsParams, params: RequestParams = {}) =>
      this.request<GetAlertsData, GetAlertsError>({
        path: `/bo/alerts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags alerts, SystemAlertsController
     * @name ResolveAlert
     * @summary Change alert's status.
     * @request PATCH:/bo/alerts/{alertId}
     * @secure
     * @response `200` `ResolveAlertData`
     * @response `400` `PatchAlertsAlertId400ErrorResponse`
     * @response `403` `PatchAlertsAlertId403ErrorResponse`
     * @response `404` `PatchAlertsAlertId404ErrorResponse`
     */
    resolveAlert: (
      alertId: string,
      data: UpdateSystemAlertStatusRequest,
      params: RequestParams = {},
    ) =>
      this.request<ResolveAlertData, ResolveAlertError>({
        path: `/bo/alerts/${alertId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  apiclients = {
    /**
     * No description
     *
     * @tags apiclients, ApiClientsController
     * @name CreateApiClient
     * @summary Create API client configuration
     * @request POST:/bo/apiclients
     * @secure
     * @response `200` `CreateApiClientData`
     * @response `400` `PostApiclients400ErrorResponse`
     * @response `403` `PostApiclients403ErrorResponse`
     */
    createApiClient: (data: CreateApiClientRequest, params: RequestParams = {}) =>
      this.request<CreateApiClientData, CreateApiClientError>({
        path: `/bo/apiclients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags apiclients, ApiClientsController
     * @name DeleteApiClient
     * @summary Delete API client configuration
     * @request DELETE:/bo/apiclients/{clientId}
     * @secure
     * @response `200` `DeleteApiClientData`
     * @response `403` `DeleteApiclientsClientId403ErrorResponse`
     * @response `404` `DeleteApiclientsClientId404ErrorResponse`
     */
    deleteApiClient: (clientId: string, params: RequestParams = {}) =>
      this.request<DeleteApiClientData, DeleteApiClientError>({
        path: `/bo/apiclients/${clientId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags apiclients, ApiClientsController
     * @name GetApiClient
     * @summary Get API client details
     * @request GET:/bo/apiclients/{clientId}
     * @secure
     * @response `200` `GetApiClientData`
     * @response `403` `GetApiclientsClientId403ErrorResponse`
     * @response `404` `GetApiclientsClientId404ErrorResponse`
     */
    getApiClient: (clientId: string, params: RequestParams = {}) =>
      this.request<GetApiClientData, GetApiClientError>({
        path: `/bo/apiclients/${clientId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags apiclients, ApiClientsController
     * @name ListApiClients
     * @summary List API client configurations
     * @request GET:/bo/apiclients
     * @secure
     * @response `200` `ListApiClientsData`
     * @response `400` `GetApiclients400ErrorResponse`
     * @response `403` `GetApiclients403ErrorResponse`
     */
    listApiClients: (query: ListApiClientsParams, params: RequestParams = {}) =>
      this.request<ListApiClientsData, ListApiClientsError>({
        path: `/bo/apiclients`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags apiclients, ApiClientsController
     * @name UpdateApiClient
     * @summary Update API client configuration
     * @request PATCH:/bo/apiclients/{clientId}
     * @secure
     * @response `200` `UpdateApiClientData`
     * @response `400` `PatchApiclientsClientId400ErrorResponse`
     * @response `403` `PatchApiclientsClientId403ErrorResponse`
     * @response `404` `PatchApiclientsClientId404ErrorResponse`
     */
    updateApiClient: (clientId: string, data: UpdateApiClientRequest, params: RequestParams = {}) =>
      this.request<UpdateApiClientData, UpdateApiClientError>({
        path: `/bo/apiclients/${clientId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  assetVariants = {
    /**
     * No description
     *
     * @tags asset-variants, AssetVariantsProfilesController
     * @name AddProfileConfiguration
     * @summary Add variant configuration to profile
     * @request POST:/bo/asset-variants/profiles/{profileKey}/configurations
     * @secure
     * @response `200` `AddProfileConfigurationData`
     * @response `400` `PostAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse`
     * @response `403` `PostAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse`
     * @response `404` `PostAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse`
     */
    addProfileConfiguration: (
      profileKey: string,
      data: CreateAssetVariantConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<AddProfileConfigurationData, AddProfileConfigurationError>({
        path: `/bo/asset-variants/profiles/${profileKey}/configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name CreateAssetVariants
     * @summary Schedule creation of missing asset variants
     * @request POST:/bo/asset-variants/configurations/{configurationId}/create-asset-variants
     * @secure
     * @response `200` `CreateAssetVariantsData`
     * @response `403` `PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants403ErrorResponse`
     * @response `404` `PostAssetVariantsConfigurationsConfigurationIdCreateAssetVariants404ErrorResponse`
     */
    createAssetVariants: (
      { configurationId, ...query }: CreateAssetVariantsParams,
      params: RequestParams = {},
    ) =>
      this.request<CreateAssetVariantsData, CreateAssetVariantsError>({
        path: `/bo/asset-variants/configurations/${configurationId}/create-asset-variants`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name DeleteConfiguration
     * @summary Delete asset variant configuration
     * @request DELETE:/bo/asset-variants/configurations/{configurationId}
     * @secure
     * @response `200` `DeleteConfigurationData`
     * @response `403` `DeleteAssetVariantsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `DeleteAssetVariantsConfigurationsConfigurationId404ErrorResponse`
     */
    deleteConfiguration: (configurationId: string, params: RequestParams = {}) =>
      this.request<DeleteConfigurationData, DeleteConfigurationError>({
        path: `/bo/asset-variants/configurations/${configurationId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantsController
     * @name DeleteIncorrectVideoGameCoverVariants
     * @summary Delete incorrect video game cover asset variants
     * @request DELETE:/bo/asset-variants/incorrect-video-game-covers
     * @secure
     * @response `200` `DeleteIncorrectVideoGameCoverVariantsData`
     * @response `403` `DeleteAssetVariantsIncorrectVideoGameCovers403ErrorResponse`
     */
    deleteIncorrectVideoGameCoverVariants: (params: RequestParams = {}) =>
      this.request<
        DeleteIncorrectVideoGameCoverVariantsData,
        DeleteIncorrectVideoGameCoverVariantsError
      >({
        path: `/bo/asset-variants/incorrect-video-game-covers`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name EditConfiguration
     * @summary Edit asset variant configuration
     * @request PATCH:/bo/asset-variants/configurations/{configurationId}
     * @secure
     * @response `200` `EditConfigurationData`
     * @response `400` `PatchAssetVariantsConfigurationsConfigurationId400ErrorResponse`
     * @response `403` `PatchAssetVariantsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `PatchAssetVariantsConfigurationsConfigurationId404ErrorResponse`
     */
    editConfiguration: (
      configurationId: string,
      data: EditAssetVariantConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<EditConfigurationData, EditConfigurationError>({
        path: `/bo/asset-variants/configurations/${configurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name GetDetails
     * @summary Get configuration details
     * @request GET:/bo/asset-variants/configurations/{configurationId}
     * @secure
     * @response `200` `GetDetailsData`
     * @response `403` `GetAssetVariantsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `GetAssetVariantsConfigurationsConfigurationId404ErrorResponse`
     */
    getDetails: (configurationId: string, params: RequestParams = {}) =>
      this.request<GetDetailsData, GetDetailsError>({
        path: `/bo/asset-variants/configurations/${configurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantsController
     * @name ListFormats
     * @summary List valid asset variant formats.
     * @request GET:/bo/asset-variants/formats
     * @secure
     * @response `200` `ListFormatsData`
     * @response `403` `GetAssetVariantsFormats403ErrorResponse`
     */
    listFormats: (params: RequestParams = {}) =>
      this.request<ListFormatsData, ListFormatsError>({
        path: `/bo/asset-variants/formats`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name ListPending
     * @summary List configurations with pending requests
     * @request GET:/bo/asset-variants/configurations/pending
     * @secure
     * @response `200` `ListPendingData`
     * @response `400` `GetAssetVariantsConfigurationsPending400ErrorResponse`
     * @response `403` `GetAssetVariantsConfigurationsPending403ErrorResponse`
     */
    listPending: (query: ListPendingParams, params: RequestParams = {}) =>
      this.request<ListPendingData, ListPendingError>({
        path: `/bo/asset-variants/configurations/pending`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantsProfilesController
     * @name ListProfiles
     * @summary List asset variants profiles
     * @request GET:/bo/asset-variants/profiles
     * @secure
     * @response `200` `ListProfilesData`
     * @response `400` `GetAssetVariantsProfiles400ErrorResponse`
     * @response `403` `GetAssetVariantsProfiles403ErrorResponse`
     */
    listProfiles: (query: ListProfilesParams, params: RequestParams = {}) =>
      this.request<ListProfilesData, ListProfilesError>({
        path: `/bo/asset-variants/profiles`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantsProfilesController
     * @name ListProfileVariantConfigurations
     * @summary List asset variant configurations
     * @request GET:/bo/asset-variants/profiles/{profileKey}/configurations
     * @secure
     * @response `200` `ListProfileVariantConfigurationsData`
     * @response `400` `GetAssetVariantsProfilesProfileKeyConfigurations400ErrorResponse`
     * @response `403` `GetAssetVariantsProfilesProfileKeyConfigurations403ErrorResponse`
     * @response `404` `GetAssetVariantsProfilesProfileKeyConfigurations404ErrorResponse`
     */
    listProfileVariantConfigurations: (
      { profileKey, ...query }: ListProfileVariantConfigurationsParams,
      params: RequestParams = {},
    ) =>
      this.request<ListProfileVariantConfigurationsData, ListProfileVariantConfigurationsError>({
        path: `/bo/asset-variants/profiles/${profileKey}/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags asset-variants, AssetVariantConfigurationsController
     * @name TriggerRequests
     * @summary Trigger requests for asset variants
     * @request POST:/bo/asset-variants/configurations/{configurationId}/trigger-requests
     * @secure
     * @response `200` `TriggerRequestsData`
     * @response `403` `PostAssetVariantsConfigurationsConfigurationIdTriggerRequests403ErrorResponse`
     * @response `404` `PostAssetVariantsConfigurationsConfigurationIdTriggerRequests404ErrorResponse`
     */
    triggerRequests: (
      { configurationId, ...query }: TriggerRequestsParams,
      params: RequestParams = {},
    ) =>
      this.request<TriggerRequestsData, TriggerRequestsError>({
        path: `/bo/asset-variants/configurations/${configurationId}/trigger-requests`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  assets = {
    /**
     * No description
     *
     * @tags assets, AssetsController
     * @name UpdateBucketIdentifier
     * @summary Update the bucket identifier of some assets
     * @request POST:/bo/assets/update-bucket-identifier
     * @secure
     * @response `200` `UpdateBucketIdentifierData`
     * @response `403` `PostAssetsUpdateBucketIdentifier403ErrorResponse`
     */
    updateBucketIdentifier: (query: UpdateBucketIdentifierParams, params: RequestParams = {}) =>
      this.request<UpdateBucketIdentifierData, UpdateBucketIdentifierError>({
        path: `/bo/assets/update-bucket-identifier`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  backendhosts = {
    /**
     * No description
     *
     * @tags backendhosts, BackendHostsController
     * @name CreateBackendHost
     * @summary Create backend host configuration
     * @request POST:/bo/backendhosts
     * @secure
     * @response `200` `CreateBackendHostData`
     * @response `400` `PostBackendhosts400ErrorResponse`
     * @response `403` `PostBackendhosts403ErrorResponse`
     */
    createBackendHost: (data: CreateBackendHostRequest, params: RequestParams = {}) =>
      this.request<CreateBackendHostData, CreateBackendHostError>({
        path: `/bo/backendhosts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags backendhosts, BackendHostsController
     * @name DeleteBackendHost
     * @summary Delete backend host configuration
     * @request DELETE:/bo/backendhosts/{hostId}
     * @secure
     * @response `200` `DeleteBackendHostData`
     * @response `403` `DeleteBackendhostsHostId403ErrorResponse`
     * @response `404` `DeleteBackendhostsHostId404ErrorResponse`
     */
    deleteBackendHost: (hostId: string, params: RequestParams = {}) =>
      this.request<DeleteBackendHostData, DeleteBackendHostError>({
        path: `/bo/backendhosts/${hostId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags backendhosts, BackendHostsController
     * @name ListBackendHosts
     * @summary List backend host configurations
     * @request GET:/bo/backendhosts
     * @secure
     * @response `200` `ListBackendHostsData`
     * @response `400` `GetBackendhosts400ErrorResponse`
     * @response `403` `GetBackendhosts403ErrorResponse`
     */
    listBackendHosts: (query: ListBackendHostsParams, params: RequestParams = {}) =>
      this.request<ListBackendHostsData, ListBackendHostsError>({
        path: `/bo/backendhosts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  bank = {
    /**
     * No description
     *
     * @tags bank, BankAccountsController
     * @name CreateAccount
     * @summary Create bank account
     * @request POST:/bo/bank/accounts
     * @secure
     * @response `200` `CreateAccountData`
     * @response `400` `PostBankAccounts400ErrorResponse`
     * @response `403` `PostBankAccounts403ErrorResponse`
     * @response `404` `PostBankAccounts404ErrorResponse`
     */
    createAccount: (data: CreateBankAccountRequest, params: RequestParams = {}) =>
      this.request<CreateAccountData, CreateAccountError>({
        path: `/bo/bank/accounts`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankTransactionsController
     * @name CreateTransaction
     * @summary Create transaction(s)
     * @request POST:/bo/bank/transactions
     * @secure
     * @response `200` `CreateTransactionData`
     * @response `400` `PostBankTransactions400ErrorResponse`
     * @response `403` `PostBankTransactions403ErrorResponse`
     * @response `404` `PostBankTransactions404ErrorResponse`
     */
    createTransaction: (data: CreateBankTransactionRequest, params: RequestParams = {}) =>
      this.request<CreateTransactionData, CreateTransactionError>({
        path: `/bo/bank/transactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankAccountsController
     * @name GetAccountDetails
     * @summary Get bank account details
     * @request GET:/bo/bank/accounts/{accountId}
     * @secure
     * @response `200` `GetAccountDetailsData`
     * @response `403` `GetBankAccountsAccountId403ErrorResponse`
     * @response `404` `GetBankAccountsAccountId404ErrorResponse`
     */
    getAccountDetails: (accountId: string, params: RequestParams = {}) =>
      this.request<GetAccountDetailsData, GetAccountDetailsError>({
        path: `/bo/bank/accounts/${accountId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankTransactionsController
     * @name GetTransactionDetails
     * @summary Get transaction details
     * @request GET:/bo/bank/transactions/{transactionId}
     * @secure
     * @response `200` `GetTransactionDetailsData`
     * @response `403` `GetBankTransactionsTransactionId403ErrorResponse`
     * @response `404` `GetBankTransactionsTransactionId404ErrorResponse`
     */
    getTransactionDetails: (transactionId: string, params: RequestParams = {}) =>
      this.request<GetTransactionDetailsData, GetTransactionDetailsError>({
        path: `/bo/bank/transactions/${transactionId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankAccountsController
     * @name ListAccounts
     * @summary List bank accounts
     * @request GET:/bo/bank/accounts
     * @secure
     * @response `200` `ListAccountsData`
     * @response `400` `GetBankAccounts400ErrorResponse`
     * @response `403` `GetBankAccounts403ErrorResponse`
     * @response `404` `GetBankAccounts404ErrorResponse`
     */
    listAccounts: (query: ListAccountsParams, params: RequestParams = {}) =>
      this.request<ListAccountsData, ListAccountsError>({
        path: `/bo/bank/accounts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankAccountTransactionsController
     * @name ListAccountTransactions
     * @summary List account transactions
     * @request GET:/bo/bank/accounts/{accountId}/transactions
     * @secure
     * @response `200` `ListAccountTransactionsData`
     * @response `400` `GetBankAccountsAccountIdTransactions400ErrorResponse`
     * @response `403` `GetBankAccountsAccountIdTransactions403ErrorResponse`
     * @response `404` `GetBankAccountsAccountIdTransactions404ErrorResponse`
     */
    listAccountTransactions: (
      { accountId, ...query }: ListAccountTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<ListAccountTransactionsData, ListAccountTransactionsError>({
        path: `/bo/bank/accounts/${accountId}/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankTransactionsController
     * @name ListTransactions
     * @summary List transactions
     * @request GET:/bo/bank/transactions
     * @secure
     * @response `200` `ListTransactionsData`
     * @response `400` `GetBankTransactions400ErrorResponse`
     * @response `403` `GetBankTransactions403ErrorResponse`
     */
    listTransactions: (query: ListTransactionsParams, params: RequestParams = {}) =>
      this.request<ListTransactionsData, ListTransactionsError>({
        path: `/bo/bank/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags bank, BankAccountsController
     * @name UpdateAccount
     * @summary Update bank account
     * @request PATCH:/bo/bank/accounts/{accountId}
     * @secure
     * @response `200` `UpdateAccountData`
     * @response `400` `PatchBankAccountsAccountId400ErrorResponse`
     * @response `403` `PatchBankAccountsAccountId403ErrorResponse`
     * @response `404` `PatchBankAccountsAccountId404ErrorResponse`
     */
    updateAccount: (
      accountId: string,
      data: UpdateBankAccountRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateAccountData, UpdateAccountError>({
        path: `/bo/bank/accounts/${accountId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  cdnHostings = {
    /**
     * No description
     *
     * @tags cdn-hostings, CdnHostingsController
     * @name List
     * @summary List CDN hostings
     * @request GET:/bo/cdn-hostings
     * @secure
     * @response `200` `ListData`
     * @response `400` `GetCdnHostings400ErrorResponse`
     * @response `403` `GetCdnHostings403ErrorResponse`
     */
    list: (query: ListParams, params: RequestParams = {}) =>
      this.request<ListData, ListError>({
        path: `/bo/cdn-hostings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  challenges = {
    /**
     * No description
     *
     * @tags challenges, ChallengeCoverController
     * @name DeleteChallengeCover
     * @summary Delete cover for a challenge
     * @request DELETE:/bo/challenges/{challengeId}/cover/{coverId}
     * @secure
     * @response `200` `DeleteChallengeCoverData`
     * @response `400` `DeleteChallengesChallengeIdCoverCoverId400ErrorResponse`
     * @response `403` `DeleteChallengesChallengeIdCoverCoverId403ErrorResponse`
     * @response `404` `DeleteChallengesChallengeIdCoverCoverId404ErrorResponse`
     * @response `500` `DeleteChallengesChallengeIdCoverCoverId500ErrorResponse`
     */
    deleteChallengeCover: (challengeId: string, coverId: string, params: RequestParams = {}) =>
      this.request<DeleteChallengeCoverData, DeleteChallengeCoverError>({
        path: `/bo/challenges/${challengeId}/cover/${coverId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name DeleteRankings
     * @summary Delete challenge rankings
     * @request DELETE:/bo/challenges/rankings
     * @secure
     * @response `200` `DeleteRankingsData`
     * @response `403` `DeleteChallengesRankings403ErrorResponse`
     */
    deleteRankings: (params: RequestParams = {}) =>
      this.request<DeleteRankingsData, DeleteRankingsError>({
        path: `/bo/challenges/rankings`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name EditChallenge
     * @summary Edit a challenge
     * @request PATCH:/bo/challenges/{challengeId}
     * @secure
     * @response `200` `EditChallengeData`
     * @response `400` `PatchChallengesChallengeId400ErrorResponse`
     * @response `403` `PatchChallengesChallengeId403ErrorResponse`
     * @response `404` `PatchChallengesChallengeId404ErrorResponse`
     */
    editChallenge: (challengeId: string, data: EditChallengeRequest, params: RequestParams = {}) =>
      this.request<EditChallengeData, EditChallengeError>({
        path: `/bo/challenges/${challengeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GenerateChallengeShortIDs
     * @summary Generate short ids for all challenges
     * @request PATCH:/bo/challenges/short-ids
     * @secure
     * @response `200` `GenerateChallengeShortIDsData`
     * @response `403` `PatchChallengesShortIds403ErrorResponse`
     */
    generateChallengeShortIDs: (params: RequestParams = {}) =>
      this.request<GenerateChallengeShortIDsData, GenerateChallengeShortIDsError>({
        path: `/bo/challenges/short-ids`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GenerateNewGameShortId
     * @summary Generate new short id
     * @request GET:/bo/challenges/generate-short-id
     * @secure
     * @response `200` `GenerateNewGameShortIdData`
     * @response `403` `GetChallengesGenerateShortId403ErrorResponse`
     */
    generateNewGameShortId: (params: RequestParams = {}) =>
      this.request<GenerateNewGameShortIdData, GenerateNewGameShortIdError>({
        path: `/bo/challenges/generate-short-id`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GetChallenge
     * @summary Get a single challenge
     * @request GET:/bo/challenges/{challengeId}
     * @secure
     * @response `200` `GetChallengeData`
     * @response `403` `GetChallengesChallengeId403ErrorResponse`
     * @response `404` `GetChallengesChallengeId404ErrorResponse`
     */
    getChallenge: (challengeId: string, params: RequestParams = {}) =>
      this.request<GetChallengeData, GetChallengeError>({
        path: `/bo/challenges/${challengeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GetChallenges
     * @summary Get all challenges
     * @request GET:/bo/challenges
     * @secure
     * @response `200` `GetChallengesData`
     * @response `400` `GetChallenges400ErrorResponse`
     * @response `403` `GetChallenges403ErrorResponse`
     */
    getChallenges: (query: GetChallengesParams, params: RequestParams = {}) =>
      this.request<GetChallengesData, GetChallengesError>({
        path: `/bo/challenges`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GetPendingVideoUploads
     * @summary Get a number of pending video uploads for scores
     * @request GET:/bo/challenges/scores/pending-video-uploads
     * @secure
     * @response `200` `GetPendingVideoUploadsData`
     * @response `403` `GetChallengesScoresPendingVideoUploads403ErrorResponse`
     */
    getPendingVideoUploads: (params: RequestParams = {}) =>
      this.request<GetPendingVideoUploadsData, GetPendingVideoUploadsError>({
        path: `/bo/challenges/scores/pending-video-uploads`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GetScoreVerifications
     * @summary Get score verifications for top challenge scores
     * @request GET:/bo/challenges/{challengeId}/score-verifications
     * @secure
     * @response `200` `GetScoreVerificationsData`
     * @response `400` `GetChallengesChallengeIdScoreVerifications400ErrorResponse`
     * @response `403` `GetChallengesChallengeIdScoreVerifications403ErrorResponse`
     * @response `404` `GetChallengesChallengeIdScoreVerifications404ErrorResponse`
     */
    getScoreVerifications: (
      { challengeId, ...query }: GetScoreVerificationsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetScoreVerificationsData, GetScoreVerificationsError>({
        path: `/bo/challenges/${challengeId}/score-verifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name GetUserScoreVerifications
     * @summary Get score verifications for a specific user
     * @request GET:/bo/challenges/{challengeId}/user-scores/{userId}
     * @secure
     * @response `200` `GetUserScoreVerificationsData`
     * @response `400` `GetChallengesChallengeIdUserScoresUserId400ErrorResponse`
     * @response `403` `GetChallengesChallengeIdUserScoresUserId403ErrorResponse`
     * @response `404` `GetChallengesChallengeIdUserScoresUserId404ErrorResponse`
     */
    getUserScoreVerifications: (
      { challengeId, userId, ...query }: GetUserScoreVerificationsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetUserScoreVerificationsData, GetUserScoreVerificationsError>({
        path: `/bo/challenges/${challengeId}/user-scores/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name SubmitScoreReview
     * @summary Submit review of challenge score
     * @request PATCH:/bo/challenges/{challengeId}/score-verifications/{scoreId}
     * @secure
     * @response `200` `SubmitScoreReviewData`
     * @response `400` `PatchChallengesChallengeIdScoreVerificationsScoreId400ErrorResponse`
     * @response `403` `PatchChallengesChallengeIdScoreVerificationsScoreId403ErrorResponse`
     * @response `404` `PatchChallengesChallengeIdScoreVerificationsScoreId404ErrorResponse`
     */
    submitScoreReview: (
      challengeId: string,
      scoreId: string,
      data: SubmitScoreReviewRequest,
      params: RequestParams = {},
    ) =>
      this.request<SubmitScoreReviewData, SubmitScoreReviewError>({
        path: `/bo/challenges/${challengeId}/score-verifications/${scoreId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name UpdateChallengeSetting
     * @summary Publish or unpublish a challenge.
     * @request PATCH:/bo/challenges/{challengeId}/settings
     * @secure
     * @response `200` `UpdateChallengeSettingData`
     * @response `400` `PatchChallengesChallengeIdSettings400ErrorResponse`
     * @response `403` `PatchChallengesChallengeIdSettings403ErrorResponse`
     * @response `404` `PatchChallengesChallengeIdSettings404ErrorResponse`
     */
    updateChallengeSetting: (
      challengeId: string,
      data: UpdateChallengeSettingRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateChallengeSettingData, UpdateChallengeSettingError>({
        path: `/bo/challenges/${challengeId}/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name UpdateRankings
     * @summary Update challenge rankings
     * @request PATCH:/bo/challenges/rankings
     * @secure
     * @response `200` `UpdateRankingsData`
     * @response `400` `PatchChallengesRankings400ErrorResponse`
     * @response `403` `PatchChallengesRankings403ErrorResponse`
     * @response `404` `PatchChallengesRankings404ErrorResponse`
     */
    updateRankings: (query: UpdateRankingsParams, params: RequestParams = {}) =>
      this.request<UpdateRankingsData, UpdateRankingsError>({
        path: `/bo/challenges/rankings`,
        method: 'PATCH',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengesController
     * @name UpdateTopScores
     * @summary Update top scores for challenges
     * @request PATCH:/bo/challenges/top-scores
     * @secure
     * @response `200` `UpdateTopScoresData`
     * @response `403` `PatchChallengesTopScores403ErrorResponse`
     */
    updateTopScores: (params: RequestParams = {}) =>
      this.request<UpdateTopScoresData, UpdateTopScoresError>({
        path: `/bo/challenges/top-scores`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenges, ChallengeCoverController
     * @name UploadCoverV2
     * @summary Set cover art image for a challenge from an uploaded file.
     * @request PUT:/bo/v2/challenges/{challengeId}/cover
     * @secure
     * @response `201` `UploadCoverV2Data`
     * @response `400` `PutV2ChallengesChallengeIdCover400ErrorResponse`
     * @response `403` `PutV2ChallengesChallengeIdCover403ErrorResponse`
     * @response `404` `PutV2ChallengesChallengeIdCover404ErrorResponse`
     * @response `500` `PutV2ChallengesChallengeIdCover500ErrorResponse`
     */
    uploadCoverV2: (
      { challengeId, ...query }: UploadCoverV2Params,
      data: UploadCoverV2Payload,
      params: RequestParams = {},
    ) =>
      this.request<UploadCoverV2Data, UploadCoverV2Error>({
        path: `/bo/v2/challenges/${challengeId}/cover`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  cluster = {
    /**
     * No description
     *
     * @tags cluster, ClusterController
     * @name GetClusterStatus
     * @summary Retrieve BE cluster status
     * @request GET:/bo/cluster/status
     * @secure
     * @response `200` `GetClusterStatusData`
     * @response `403` `GetClusterStatus403ErrorResponse`
     */
    getClusterStatus: (params: RequestParams = {}) =>
      this.request<GetClusterStatusData, GetClusterStatusError>({
        path: `/bo/cluster/status`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  cms = {
    /**
     * No description
     *
     * @tags cms, CmsConfigurationsController
     * @name CreateConfiguration
     * @summary Create configuration
     * @request POST:/bo/cms/configurations
     * @secure
     * @response `200` `CreateConfigurationData`
     * @response `400` `PostCmsConfigurations400ErrorResponse`
     * @response `403` `PostCmsConfigurations403ErrorResponse`
     */
    createConfiguration: (data: CreateCmsConfigurationRequest, params: RequestParams = {}) =>
      this.request<CreateConfigurationData, CreateConfigurationError>({
        path: `/bo/cms/configurations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationSectionsController
     * @name CreateSection
     * @summary Create section
     * @request POST:/bo/cms/configurations/{configurationId}/sections
     * @secure
     * @response `200` `CreateSectionData`
     * @response `400` `PostCmsConfigurationsConfigurationIdSections400ErrorResponse`
     * @response `403` `PostCmsConfigurationsConfigurationIdSections403ErrorResponse`
     * @response `404` `PostCmsConfigurationsConfigurationIdSections404ErrorResponse`
     */
    createSection: (
      configurationId: string,
      data: CreateCmsSectionRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateSectionData, CreateSectionError>({
        path: `/bo/cms/configurations/${configurationId}/sections`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationsController
     * @name DeleteConfiguration
     * @summary Delete configuration
     * @request DELETE:/bo/cms/configurations/{configurationId}
     * @secure
     * @response `200` `DeleteConfigurationResult`
     * @response `403` `DeleteCmsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `DeleteCmsConfigurationsConfigurationId404ErrorResponse`
     */
    deleteConfiguration: (configurationId: string, params: RequestParams = {}) =>
      this.request<DeleteConfigurationResult, DeleteConfigurationFail>({
        path: `/bo/cms/configurations/${configurationId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationSectionsController
     * @name DeleteSection
     * @summary Delete section
     * @request DELETE:/bo/cms/configurations/{configurationId}/sections/{sectionId}
     * @secure
     * @response `200` `DeleteSectionData`
     * @response `403` `DeleteCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse`
     * @response `404` `DeleteCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse`
     */
    deleteSection: (configurationId: string, sectionId: string, params: RequestParams = {}) =>
      this.request<DeleteSectionData, DeleteSectionError>({
        path: `/bo/cms/configurations/${configurationId}/sections/${sectionId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationsController
     * @name GetConfigurationDetails
     * @summary Get configuration details
     * @request GET:/bo/cms/configurations/{configurationId}
     * @secure
     * @response `200` `GetConfigurationDetailsData`
     * @response `403` `GetCmsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `GetCmsConfigurationsConfigurationId404ErrorResponse`
     */
    getConfigurationDetails: (configurationId: string, params: RequestParams = {}) =>
      this.request<GetConfigurationDetailsData, GetConfigurationDetailsError>({
        path: `/bo/cms/configurations/${configurationId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationsController
     * @name ListConfigurations
     * @summary List configurations
     * @request GET:/bo/cms/configurations
     * @secure
     * @response `200` `ListConfigurationsData`
     * @response `400` `GetCmsConfigurations400ErrorResponse`
     * @response `403` `GetCmsConfigurations403ErrorResponse`
     */
    listConfigurations: (query: ListConfigurationsParams, params: RequestParams = {}) =>
      this.request<ListConfigurationsData, ListConfigurationsError>({
        path: `/bo/cms/configurations`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationSectionsController
     * @name ListSections
     * @summary List sections
     * @request GET:/bo/cms/configurations/{configurationId}/sections
     * @secure
     * @response `200` `ListSectionsData`
     * @response `403` `GetCmsConfigurationsConfigurationIdSections403ErrorResponse`
     * @response `404` `GetCmsConfigurationsConfigurationIdSections404ErrorResponse`
     */
    listSections: (configurationId: string, params: RequestParams = {}) =>
      this.request<ListSectionsData, ListSectionsError>({
        path: `/bo/cms/configurations/${configurationId}/sections`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationsController
     * @name UpdateConfiguration
     * @summary Update configuration
     * @request PATCH:/bo/cms/configurations/{configurationId}
     * @secure
     * @response `200` `UpdateConfigurationData`
     * @response `400` `PatchCmsConfigurationsConfigurationId400ErrorResponse`
     * @response `403` `PatchCmsConfigurationsConfigurationId403ErrorResponse`
     * @response `404` `PatchCmsConfigurationsConfigurationId404ErrorResponse`
     */
    updateConfiguration: (
      configurationId: string,
      data: UpdateCmsConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateConfigurationData, UpdateConfigurationError>({
        path: `/bo/cms/configurations/${configurationId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags cms, CmsConfigurationSectionsController
     * @name UpdateSection
     * @summary Update section
     * @request PATCH:/bo/cms/configurations/{configurationId}/sections/{sectionId}
     * @secure
     * @response `200` `UpdateSectionData`
     * @response `400` `PatchCmsConfigurationsConfigurationIdSectionsSectionId400ErrorResponse`
     * @response `403` `PatchCmsConfigurationsConfigurationIdSectionsSectionId403ErrorResponse`
     * @response `404` `PatchCmsConfigurationsConfigurationIdSectionsSectionId404ErrorResponse`
     */
    updateSection: (
      configurationId: string,
      sectionId: string,
      data: UpdateCmsSectionRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateSectionData, UpdateSectionError>({
        path: `/bo/cms/configurations/${configurationId}/sections/${sectionId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  disputes = {
    /**
     * No description
     *
     * @tags disputes, DisputesController
     * @name GetDisputeDetails
     * @summary Get dispute details
     * @request GET:/bo/disputes/{disputeId}
     * @secure
     * @response `200` `GetDisputeDetailsData`
     * @response `403` `GetDisputesDisputeId403ErrorResponse`
     * @response `404` `GetDisputesDisputeId404ErrorResponse`
     */
    getDisputeDetails: (disputeId: string, params: RequestParams = {}) =>
      this.request<GetDisputeDetailsData, GetDisputeDetailsError>({
        path: `/bo/disputes/${disputeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags disputes, DisputesController
     * @name GetDisputesDetails
     * @summary Get list of disputes
     * @request GET:/bo/disputes
     * @secure
     * @response `200` `GetDisputesDetailsData`
     * @response `400` `GetDisputes400ErrorResponse`
     * @response `403` `GetDisputes403ErrorResponse`
     * @response `404` `GetDisputes404ErrorResponse`
     */
    getDisputesDetails: (query: GetDisputesDetailsParams, params: RequestParams = {}) =>
      this.request<GetDisputesDetailsData, GetDisputesDetailsError>({
        path: `/bo/disputes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  dynamicConfig = {
    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name CreateDynamicConfigV2
     * @summary Create a new dynamic config
     * @request POST:/bo/v2/dynamic-config
     * @secure
     * @response `200` `CreateDynamicConfigV2Data`
     * @response `400` `PostV2DynamicConfig400ErrorResponse`
     * @response `403` `PostV2DynamicConfig403ErrorResponse`
     */
    createDynamicConfigV2: (data: DynamicConfigCreateRequest, params: RequestParams = {}) =>
      this.request<CreateDynamicConfigV2Data, CreateDynamicConfigV2Error>({
        path: `/bo/v2/dynamic-config`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name DeleteDynamicConfig
     * @summary Delete dynamic config
     * @request DELETE:/bo/dynamic-config/{configId}
     * @secure
     * @response `200` `DeleteDynamicConfigData`
     * @response `403` `DeleteDynamicConfigConfigId403ErrorResponse`
     * @response `404` `DeleteDynamicConfigConfigId404ErrorResponse`
     */
    deleteDynamicConfig: (configId: string, params: RequestParams = {}) =>
      this.request<DeleteDynamicConfigData, DeleteDynamicConfigError>({
        path: `/bo/dynamic-config/${configId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name GetDynamicConfigDetails
     * @summary Get dynamic configs details
     * @request GET:/bo/dynamic-config/{configId}
     * @secure
     * @response `200` `GetDynamicConfigDetailsData`
     * @response `403` `GetDynamicConfigConfigId403ErrorResponse`
     * @response `404` `GetDynamicConfigConfigId404ErrorResponse`
     */
    getDynamicConfigDetails: (configId: string, params: RequestParams = {}) =>
      this.request<GetDynamicConfigDetailsData, GetDynamicConfigDetailsError>({
        path: `/bo/dynamic-config/${configId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name ListClients
     * @summary List available clients for dynamic config
     * @request GET:/bo/dynamic-config/clients
     * @secure
     * @response `200` `ListClientsData`
     * @response `403` `GetDynamicConfigClients403ErrorResponse`
     */
    listClients: (params: RequestParams = {}) =>
      this.request<ListClientsData, ListClientsError>({
        path: `/bo/dynamic-config/clients`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name ListDynamicConfigs
     * @summary List dynamic configs
     * @request GET:/bo/dynamic-config
     * @secure
     * @response `200` `ListDynamicConfigsData`
     * @response `400` `GetDynamicConfig400ErrorResponse`
     * @response `403` `GetDynamicConfig403ErrorResponse`
     */
    listDynamicConfigs: (query: ListDynamicConfigsParams, params: RequestParams = {}) =>
      this.request<ListDynamicConfigsData, ListDynamicConfigsError>({
        path: `/bo/dynamic-config`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name PurgeConfigurationCache
     * @summary Invalidate cache for a given config
     * @request POST:/bo/dynamic-config/{configId}/purge-cache
     * @secure
     * @response `200` `PurgeConfigurationCacheData`
     * @response `403` `PostDynamicConfigConfigIdPurgeCache403ErrorResponse`
     * @response `404` `PostDynamicConfigConfigIdPurgeCache404ErrorResponse`
     */
    purgeConfigurationCache: (configId: string, params: RequestParams = {}) =>
      this.request<PurgeConfigurationCacheData, PurgeConfigurationCacheError>({
        path: `/bo/dynamic-config/${configId}/purge-cache`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name PurgeEveryCache
     * @summary Invalidate cache for every config
     * @request POST:/bo/dynamic-config/purge-cache
     * @secure
     * @response `200` `PurgeEveryCacheData`
     * @response `403` `PostDynamicConfigPurgeCache403ErrorResponse`
     */
    purgeEveryCache: (params: RequestParams = {}) =>
      this.request<PurgeEveryCacheData, PurgeEveryCacheError>({
        path: `/bo/dynamic-config/purge-cache`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config, DynamicConfigController
     * @name UpdateDynamicConfig
     * @summary Update dynamic config
     * @request PATCH:/bo/dynamic-config/{configId}
     * @secure
     * @response `200` `UpdateDynamicConfigData`
     * @response `400` `PatchDynamicConfigConfigId400ErrorResponse`
     * @response `403` `PatchDynamicConfigConfigId403ErrorResponse`
     * @response `404` `PatchDynamicConfigConfigId404ErrorResponse`
     */
    updateDynamicConfig: (
      configId: string,
      data: DynamicConfigUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateDynamicConfigData, UpdateDynamicConfigError>({
        path: `/bo/dynamic-config/${configId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  dynamicConfigClients = {
    /**
     * No description
     *
     * @tags dynamic-config-clients, DynamicConfigClientController
     * @name ArchiveDynamicConfigClient
     * @summary Archive dynamic config client
     * @request PATCH:/bo/dynamic-config-clients/{clientAlias}/archive
     * @secure
     * @response `200` `ArchiveDynamicConfigClientData`
     * @response `403` `PatchDynamicConfigClientsClientAliasArchive403ErrorResponse`
     * @response `404` `PatchDynamicConfigClientsClientAliasArchive404ErrorResponse`
     */
    archiveDynamicConfigClient: (clientAlias: string, params: RequestParams = {}) =>
      this.request<ArchiveDynamicConfigClientData, ArchiveDynamicConfigClientError>({
        path: `/bo/dynamic-config-clients/${clientAlias}/archive`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dynamic-config-clients, DynamicConfigClientController
     * @name CreateDynamicConfigClient
     * @summary Create a new dynamic config client
     * @request POST:/bo/dynamic-config-clients
     * @secure
     * @response `200` `CreateDynamicConfigClientData`
     * @response `400` `PostDynamicConfigClients400ErrorResponse`
     * @response `403` `PostDynamicConfigClients403ErrorResponse`
     */
    createDynamicConfigClient: (
      data: DynamicConfigClientCreateRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateDynamicConfigClientData, CreateDynamicConfigClientError>({
        path: `/bo/dynamic-config-clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  emailingTopics = {
    /**
     * No description
     *
     * @tags emailing-topics, EmailTopicController
     * @name GetTopics
     * @summary Get all emailing topics, with pagination.
     * @request GET:/bo/emailing-topics
     * @secure
     * @response `200` `GetTopicsData`
     * @response `400` `GetEmailingTopics400ErrorResponse`
     * @response `403` `GetEmailingTopics403ErrorResponse`
     */
    getTopics: (query: GetTopicsParams, params: RequestParams = {}) =>
      this.request<GetTopicsData, GetTopicsError>({
        path: `/bo/emailing-topics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emailing-topics, EmailTopicController
     * @name InitEmailTopics
     * @summary Create email topics `funding` and `default`.
     * @request POST:/bo/emailing-topics
     * @secure
     * @response `200` `InitEmailTopicsData`
     * @response `403` `PostEmailingTopics403ErrorResponse`
     */
    initEmailTopics: (params: RequestParams = {}) =>
      this.request<InitEmailTopicsData, InitEmailTopicsError>({
        path: `/bo/emailing-topics`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags emailing-topics, EmailTopicController
     * @name SaveMarketingConsentForUsers
     * @summary Create default email topic entries based on user marketing consent settings.
     * @request POST:/bo/emailing-topics/marketing-consent
     * @secure
     * @response `200` `SaveMarketingConsentForUsersData`
     * @response `400` `PostEmailingTopicsMarketingConsent400ErrorResponse`
     * @response `403` `PostEmailingTopicsMarketingConsent403ErrorResponse`
     */
    saveMarketingConsentForUsers: (
      query: SaveMarketingConsentForUsersParams,
      params: RequestParams = {},
    ) =>
      this.request<SaveMarketingConsentForUsersData, SaveMarketingConsentForUsersError>({
        path: `/bo/emailing-topics/marketing-consent`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  engines = {
    /**
     * No description
     *
     * @tags engines, GameEngineController
     * @name CreateGameEngine
     * @summary Create game engine
     * @request POST:/bo/engines
     * @secure
     * @response `200` `CreateGameEngineData`
     * @response `400` `PostEngines400ErrorResponse`
     * @response `403` `PostEngines403ErrorResponse`
     */
    createGameEngine: (data: CreateGameEngineRequest, params: RequestParams = {}) =>
      this.request<CreateGameEngineData, CreateGameEngineError>({
        path: `/bo/engines`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines, GameEngineController
     * @name DeleteGameEngine
     * @summary Delete game engine
     * @request DELETE:/bo/engines/{engineId}
     * @secure
     * @response `200` `DeleteGameEngineData`
     * @response `400` `DeleteEnginesEngineId400ErrorResponse`
     * @response `403` `DeleteEnginesEngineId403ErrorResponse`
     * @response `404` `DeleteEnginesEngineId404ErrorResponse`
     */
    deleteGameEngine: (engineId: string, params: RequestParams = {}) =>
      this.request<DeleteGameEngineData, DeleteGameEngineError>({
        path: `/bo/engines/${engineId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines, GameEngineController
     * @name GetGameEngineDetails
     * @summary Get game engine details
     * @request GET:/bo/engines/{engineId}
     * @secure
     * @response `200` `GetGameEngineDetailsData`
     * @response `403` `GetEnginesEngineId403ErrorResponse`
     * @response `404` `GetEnginesEngineId404ErrorResponse`
     */
    getGameEngineDetails: (engineId: string, params: RequestParams = {}) =>
      this.request<GetGameEngineDetailsData, GetGameEngineDetailsError>({
        path: `/bo/engines/${engineId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines, GameEngineController
     * @name GetGameEngines
     * @summary List game engines
     * @request GET:/bo/engines
     * @secure
     * @response `200` `GetGameEnginesData`
     * @response `400` `GetEngines400ErrorResponse`
     * @response `403` `GetEngines403ErrorResponse`
     */
    getGameEngines: (query: GetGameEnginesParams, params: RequestParams = {}) =>
      this.request<GetGameEnginesData, GetGameEnginesError>({
        path: `/bo/engines`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags engines, GameEngineController
     * @name UpdateGameEngine
     * @summary Update game engine
     * @request PATCH:/bo/engines/{engineId}
     * @secure
     * @response `200` `UpdateGameEngineData`
     * @response `400` `PatchEnginesEngineId400ErrorResponse`
     * @response `403` `PatchEnginesEngineId403ErrorResponse`
     * @response `404` `PatchEnginesEngineId404ErrorResponse`
     */
    updateGameEngine: (
      engineId: string,
      data: UpdateGameEngineRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameEngineData, UpdateGameEngineError>({
        path: `/bo/engines/${engineId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  feeds = {
    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name AddFeedEntry
     * @summary Add entry to a managed feed
     * @request POST:/bo/feeds/{feedId}/entries
     * @secure
     * @response `200` `AddFeedEntryData`
     * @response `400` `PostFeedsFeedIdEntries400ErrorResponse`
     * @response `403` `PostFeedsFeedIdEntries403ErrorResponse`
     * @response `404` `PostFeedsFeedIdEntries404ErrorResponse`
     */
    addFeedEntry: (feedId: string, data: AddFeedEntryRequest, params: RequestParams = {}) =>
      this.request<AddFeedEntryData, AddFeedEntryError>({
        path: `/bo/feeds/${feedId}/entries`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name ApplyPendingChanges
     * @summary Apply pending changes to a managed feed
     * @request POST:/bo/feeds/{feedId}/apply-pending-changes
     * @secure
     * @response `200` `ApplyPendingChangesData`
     * @response `400` `PostFeedsFeedIdApplyPendingChanges400ErrorResponse`
     * @response `403` `PostFeedsFeedIdApplyPendingChanges403ErrorResponse`
     * @response `404` `PostFeedsFeedIdApplyPendingChanges404ErrorResponse`
     */
    applyPendingChanges: (feedId: string, params: RequestParams = {}) =>
      this.request<ApplyPendingChangesData, ApplyPendingChangesError>({
        path: `/bo/feeds/${feedId}/apply-pending-changes`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name BeginPendingChanges
     * @summary Switch managed feed into pending changes processing mode
     * @request POST:/bo/feeds/{feedId}/begin-pending-changes
     * @secure
     * @response `200` `BeginPendingChangesData`
     * @response `400` `PostFeedsFeedIdBeginPendingChanges400ErrorResponse`
     * @response `403` `PostFeedsFeedIdBeginPendingChanges403ErrorResponse`
     * @response `404` `PostFeedsFeedIdBeginPendingChanges404ErrorResponse`
     */
    beginPendingChanges: (
      feedId: string,
      data: PendingChangesConfigurationRequest,
      params: RequestParams = {},
    ) =>
      this.request<BeginPendingChangesData, BeginPendingChangesError>({
        path: `/bo/feeds/${feedId}/begin-pending-changes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name CancelPendingChanges
     * @summary Cancel pending changes for managed feed
     * @request POST:/bo/feeds/{feedId}/cancel-pending-changes
     * @secure
     * @response `200` `CancelPendingChangesData`
     * @response `400` `PostFeedsFeedIdCancelPendingChanges400ErrorResponse`
     * @response `403` `PostFeedsFeedIdCancelPendingChanges403ErrorResponse`
     * @response `404` `PostFeedsFeedIdCancelPendingChanges404ErrorResponse`
     */
    cancelPendingChanges: (feedId: string, params: RequestParams = {}) =>
      this.request<CancelPendingChangesData, CancelPendingChangesError>({
        path: `/bo/feeds/${feedId}/cancel-pending-changes`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name CreateFeed
     * @summary Create a new managed feed
     * @request POST:/bo/feeds
     * @secure
     * @response `200` `CreateFeedData`
     * @response `400` `PostFeeds400ErrorResponse`
     * @response `403` `PostFeeds403ErrorResponse`
     */
    createFeed: (data: CreateManagedFeedRequest, params: RequestParams = {}) =>
      this.request<CreateFeedData, CreateFeedError>({
        path: `/bo/feeds`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name DeleteFeed
     * @summary Delete a managed feed
     * @request DELETE:/bo/feeds/{feedId}
     * @secure
     * @response `200` `DeleteFeedData`
     * @response `400` `DeleteFeedsFeedId400ErrorResponse`
     * @response `403` `DeleteFeedsFeedId403ErrorResponse`
     * @response `404` `DeleteFeedsFeedId404ErrorResponse`
     */
    deleteFeed: (feedId: string, params: RequestParams = {}) =>
      this.request<DeleteFeedData, DeleteFeedError>({
        path: `/bo/feeds/${feedId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name DownloadErrorData
     * @summary Get list of user emails in feed which must be USER_JAM
     * @request GET:/bo/feeds/{feedId}/users/emails
     * @secure
     * @response `200` `DownloadErrorDataData`
     * @response `403` `GetFeedsFeedIdUsersEmails403ErrorResponse`
     * @response `404` `GetFeedsFeedIdUsersEmails404ErrorResponse`
     */
    downloadErrorData: (feedId: string, params: RequestParams = {}) =>
      this.request<DownloadErrorDataData, DownloadErrorDataError>({
        path: `/bo/feeds/${feedId}/users/emails`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name GetFeed
     * @summary Get details for a feed
     * @request GET:/bo/feeds/{feedId}
     * @secure
     * @response `200` `GetFeedData`
     * @response `400` `GetFeedsFeedId400ErrorResponse`
     * @response `403` `GetFeedsFeedId403ErrorResponse`
     * @response `404` `GetFeedsFeedId404ErrorResponse`
     */
    getFeed: ({ feedId, ...query }: GetFeedParams, params: RequestParams = {}) =>
      this.request<GetFeedData, GetFeedError>({
        path: `/bo/feeds/${feedId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name GetFeedEntriesV2
     * @summary Get paginated list of managed feed entries
     * @request GET:/bo/v2/feeds/{feedId}/entries
     * @secure
     * @response `200` `GetFeedEntriesV2Data`
     * @response `400` `GetV2FeedsFeedIdEntries400ErrorResponse`
     * @response `403` `GetV2FeedsFeedIdEntries403ErrorResponse`
     * @response `404` `GetV2FeedsFeedIdEntries404ErrorResponse`
     */
    getFeedEntriesV2: ({ feedId, ...query }: GetFeedEntriesV2Params, params: RequestParams = {}) =>
      this.request<GetFeedEntriesV2Data, GetFeedEntriesV2Error>({
        path: `/bo/v2/feeds/${feedId}/entries`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name GetFeeds
     * @summary Get all feeds, sorted alphabetically by name
     * @request GET:/bo/feeds
     * @secure
     * @response `200` `GetFeedsData`
     * @response `400` `GetFeeds400ErrorResponse`
     * @response `403` `GetFeeds403ErrorResponse`
     */
    getFeeds: (query: GetFeedsParams, params: RequestParams = {}) =>
      this.request<GetFeedsData, GetFeedsError>({
        path: `/bo/feeds`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name PatchFeed
     * @summary Edit a managed feed
     * @request PATCH:/bo/feeds/{feedId}
     * @secure
     * @response `200` `PatchFeedData`
     * @response `400` `PatchFeedsFeedId400ErrorResponse`
     * @response `403` `PatchFeedsFeedId403ErrorResponse`
     * @response `404` `PatchFeedsFeedId404ErrorResponse`
     */
    patchFeed: (feedId: string, data: UpdateManagedFeedRequest, params: RequestParams = {}) =>
      this.request<PatchFeedData, PatchFeedError>({
        path: `/bo/feeds/${feedId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name RefreshSnapshot
     * @summary Manually refresh filtered feed snapshot
     * @request POST:/bo/feeds/{feedId}/refresh-snapshot
     * @secure
     * @response `200` `RefreshSnapshotData`
     * @response `400` `PostFeedsFeedIdRefreshSnapshot400ErrorResponse`
     * @response `403` `PostFeedsFeedIdRefreshSnapshot403ErrorResponse`
     * @response `404` `PostFeedsFeedIdRefreshSnapshot404ErrorResponse`
     */
    refreshSnapshot: (feedId: string, params: RequestParams = {}) =>
      this.request<RefreshSnapshotData, RefreshSnapshotError>({
        path: `/bo/feeds/${feedId}/refresh-snapshot`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name RemoveFeedEntry
     * @summary Remove entry from a managed feed
     * @request DELETE:/bo/feeds/{feedId}/entries/{entryId}
     * @secure
     * @response `200` `RemoveFeedEntryData`
     * @response `403` `DeleteFeedsFeedIdEntriesEntryId403ErrorResponse`
     * @response `404` `DeleteFeedsFeedIdEntriesEntryId404ErrorResponse`
     */
    removeFeedEntry: (feedId: string, entryId: string, params: RequestParams = {}) =>
      this.request<RemoveFeedEntryData, RemoveFeedEntryError>({
        path: `/bo/feeds/${feedId}/entries/${entryId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name UpdateFeedEntry
     * @summary Update managed feed entry
     * @request PATCH:/bo/feeds/{feedId}/entries/{entryId}
     * @secure
     * @response `200` `UpdateFeedEntryData`
     * @response `400` `PatchFeedsFeedIdEntriesEntryId400ErrorResponse`
     * @response `403` `PatchFeedsFeedIdEntriesEntryId403ErrorResponse`
     * @response `404` `PatchFeedsFeedIdEntriesEntryId404ErrorResponse`
     */
    updateFeedEntry: (
      feedId: string,
      entryId: string,
      data: UpdateFeedEntryRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateFeedEntryData, UpdateFeedEntryError>({
        path: `/bo/feeds/${feedId}/entries/${entryId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags feeds, ManagedFeedController
     * @name UploadEntryAttributeAsset
     * @summary Upload managed feed entry attribute asset
     * @request PUT:/bo/feeds/{feedId}/entries/{entryId}/attributes/{attributeId}/upload-asset
     * @secure
     * @response `200` `UploadEntryAttributeAssetData`
     * @response `400` `PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset400ErrorResponse`
     * @response `403` `PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset403ErrorResponse`
     * @response `404` `PutFeedsFeedIdEntriesEntryIdAttributesAttributeIdUploadAsset404ErrorResponse`
     */
    uploadEntryAttributeAsset: (
      feedId: string,
      entryId: string,
      attributeId: string,
      data: UploadEntryAttributeAssetPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadEntryAttributeAssetData, UploadEntryAttributeAssetError>({
        path: `/bo/feeds/${feedId}/entries/${entryId}/attributes/${attributeId}/upload-asset`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  funding = {
    /**
     * No description
     *
     * @tags funding, FundingController
     * @name Delist
     * @summary Remove entry from funding list.
     * @request DELETE:/bo/funding/wait-list/{subscriberId}
     * @secure
     * @response `200` `DelistData`
     * @response `403` `DeleteFundingWaitListSubscriberId403ErrorResponse`
     * @response `404` `DeleteFundingWaitListSubscriberId404ErrorResponse`
     */
    delist: (subscriberId: string, params: RequestParams = {}) =>
      this.request<DelistData, DelistError>({
        path: `/bo/funding/wait-list/${subscriberId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags funding, FundingController
     * @name EditSubscriberInfo
     * @summary Edit funding subscriber info.
     * @request PATCH:/bo/funding/wait-list/{subscriberId}
     * @secure
     * @response `200` `EditSubscriberInfoData`
     * @response `400` `PatchFundingWaitListSubscriberId400ErrorResponse`
     * @response `403` `PatchFundingWaitListSubscriberId403ErrorResponse`
     * @response `404` `PatchFundingWaitListSubscriberId404ErrorResponse`
     */
    editSubscriberInfo: (
      subscriberId: string,
      data: FundingSubscriberUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<EditSubscriberInfoData, EditSubscriberInfoError>({
        path: `/bo/funding/wait-list/${subscriberId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags funding, FundingController
     * @name Enlist
     * @summary Enlist guest or user by email address.
     * @request POST:/bo/funding/wait-list
     * @secure
     * @response `200` `EnlistData`
     * @response `400` `PostFundingWaitList400ErrorResponse`
     * @response `403` `PostFundingWaitList403ErrorResponse`
     */
    enlist: (data: FundingEnlistRequest, params: RequestParams = {}) =>
      this.request<EnlistData, EnlistError>({
        path: `/bo/funding/wait-list`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags funding, FundingController
     * @name GetSubscribers
     * @summary Get all entries for funding list, paginated.
     * @request GET:/bo/funding/wait-list
     * @secure
     * @response `200` `GetSubscribersData`
     * @response `400` `GetFundingWaitList400ErrorResponse`
     * @response `403` `GetFundingWaitList403ErrorResponse`
     * @response `404` `GetFundingWaitList404ErrorResponse`
     */
    getSubscribers: (query: GetSubscribersParams, params: RequestParams = {}) =>
      this.request<GetSubscribersData, GetSubscribersError>({
        path: `/bo/funding/wait-list`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  gamePlayStatistics = {
    /**
     * No description
     *
     * @tags game-play-statistics, GamePlayStatisticsController
     * @name GetOverallStatistics
     * @summary Get overall game play statistics
     * @request GET:/bo/game-play-statistics
     * @secure
     * @response `200` `GetOverallStatisticsData`
     * @response `403` `GetGamePlayStatistics403ErrorResponse`
     */
    getOverallStatistics: (query: GetOverallStatisticsParams, params: RequestParams = {}) =>
      this.request<GetOverallStatisticsData, GetOverallStatisticsError>({
        path: `/bo/game-play-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-play-statistics, GamePlayStatisticsController
     * @name RecalculateGamePlayStatistics
     * @summary Recalculate overall game play statistics
     * @request PATCH:/bo/game-play-statistics
     * @secure
     * @response `200` `RecalculateGamePlayStatisticsData`
     * @response `403` `PatchGamePlayStatistics403ErrorResponse`
     */
    recalculateGamePlayStatistics: (params: RequestParams = {}) =>
      this.request<RecalculateGamePlayStatisticsData, RecalculateGamePlayStatisticsError>({
        path: `/bo/game-play-statistics`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  gameTransfers = {
    /**
     * No description
     *
     * @tags game-transfers, GameTransferController
     * @name GetGameTransfer
     * @summary Get game transfer
     * @request GET:/bo/game-transfers/{transferId}
     * @secure
     * @response `200` `GetGameTransferData`
     * @response `403` `GetGameTransfersTransferId403ErrorResponse`
     * @response `404` `GetGameTransfersTransferId404ErrorResponse`
     */
    getGameTransfer: (transferId: string, params: RequestParams = {}) =>
      this.request<GetGameTransferData, GetGameTransferError>({
        path: `/bo/game-transfers/${transferId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-transfers, GameTransferController
     * @name GetGameTransfers
     * @summary Get game transfers
     * @request GET:/bo/game-transfers
     * @secure
     * @response `200` `GetGameTransfersData`
     * @response `400` `GetGameTransfers400ErrorResponse`
     * @response `403` `GetGameTransfers403ErrorResponse`
     * @response `404` `GetGameTransfers404ErrorResponse`
     */
    getGameTransfers: (query: GetGameTransfersParams, params: RequestParams = {}) =>
      this.request<GetGameTransfersData, GetGameTransfersError>({
        path: `/bo/game-transfers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-transfers, GameTransferController
     * @name ResolveGameTransfer
     * @summary Resolve game transfer
     * @request PATCH:/bo/game-transfers/{transferId}
     * @secure
     * @response `200` `ResolveGameTransferData`
     * @response `400` `PatchGameTransfersTransferId400ErrorResponse`
     * @response `403` `PatchGameTransfersTransferId403ErrorResponse`
     * @response `404` `PatchGameTransfersTransferId404ErrorResponse`
     */
    resolveGameTransfer: (
      transferId: string,
      data: TransferUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ResolveGameTransferData, ResolveGameTransferError>({
        path: `/bo/game-transfers/${transferId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  gameWrappers = {
    /**
     * No description
     *
     * @tags game-wrappers, GameWrapperController
     * @name Create
     * @summary Create new game wrapper
     * @request POST:/bo/game-wrappers
     * @secure
     * @response `200` `CreateData`
     * @response `400` `PostGameWrappers400ErrorResponse`
     * @response `403` `PostGameWrappers403ErrorResponse`
     * @response `404` `PostGameWrappers404ErrorResponse`
     * @response `500` `PostGameWrappers500ErrorResponse`
     */
    create: (data: CreateGameWrapperRequest, params: RequestParams = {}) =>
      this.request<CreateData, CreateError>({
        path: `/bo/game-wrappers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-wrappers, GameWrapperController
     * @name Delete
     * @summary Delete a game wrapper
     * @request DELETE:/bo/game-wrappers/{gameWrapperId}
     * @secure
     * @response `200` `DeleteData`
     * @response `400` `DeleteGameWrappersGameWrapperId400ErrorResponse`
     * @response `403` `DeleteGameWrappersGameWrapperId403ErrorResponse`
     * @response `404` `DeleteGameWrappersGameWrapperId404ErrorResponse`
     */
    delete: (gameWrapperId: string, params: RequestParams = {}) =>
      this.request<DeleteData, DeleteError>({
        path: `/bo/game-wrappers/${gameWrapperId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-wrappers, GameWrapperController
     * @name Edit
     * @summary Edit game wrapper
     * @request PATCH:/bo/game-wrappers/{gameWrapperId}
     * @secure
     * @response `200` `EditData`
     * @response `400` `PatchGameWrappersGameWrapperId400ErrorResponse`
     * @response `403` `PatchGameWrappersGameWrapperId403ErrorResponse`
     * @response `404` `PatchGameWrappersGameWrapperId404ErrorResponse`
     */
    edit: (gameWrapperId: string, data: EditGameWrapperRequest, params: RequestParams = {}) =>
      this.request<EditData, EditError>({
        path: `/bo/game-wrappers/${gameWrapperId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-wrappers, GameWrapperController
     * @name List
     * @summary List game wrappers
     * @request GET:/bo/game-wrappers
     * @secure
     * @response `200` `ListResult`
     * @response `400` `GetGameWrappers400ErrorResponse`
     * @response `403` `GetGameWrappers403ErrorResponse`
     * @response `404` `GetGameWrappers404ErrorResponse`
     */
    list: (query: ListParams2, params: RequestParams = {}) =>
      this.request<ListResult, ListFail>({
        path: `/bo/game-wrappers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags game-wrappers, GameWrapperController
     * @name Upload
     * @summary Upload new game wrapper
     * @request PUT:/bo/game-wrappers/{gameWrapperId}/upload
     * @secure
     * @response `200` `UploadData`
     * @response `400` `PutGameWrappersGameWrapperIdUpload400ErrorResponse`
     * @response `403` `PutGameWrappersGameWrapperIdUpload403ErrorResponse`
     * @response `404` `PutGameWrappersGameWrapperIdUpload404ErrorResponse`
     * @response `500` `PutGameWrappersGameWrapperIdUpload500ErrorResponse`
     */
    upload: (gameWrapperId: string, data: UploadPayload, params: RequestParams = {}) =>
      this.request<UploadData, UploadError>({
        path: `/bo/game-wrappers/${gameWrapperId}/upload`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  games = {
    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name AddClaimPeriod
     * @summary Add game item claim period for full version game
     * @request POST:/bo/games/{gameId}/items/{itemId}/claim-periods
     * @secure
     * @response `200` `AddClaimPeriodData`
     * @response `400` `PostGamesGameIdItemsItemIdClaimPeriods400ErrorResponse`
     * @response `403` `PostGamesGameIdItemsItemIdClaimPeriods403ErrorResponse`
     * @response `404` `PostGamesGameIdItemsItemIdClaimPeriods404ErrorResponse`
     */
    addClaimPeriod: (
      gameId: string,
      itemId: string,
      data: GameItemClaimPeriodRequest,
      params: RequestParams = {},
    ) =>
      this.request<AddClaimPeriodData, AddClaimPeriodError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemsController
     * @name AddGameItemV2
     * @summary Add game item for game
     * @request PUT:/bo/v2/games/{gameId}/items
     * @secure
     * @response `200` `AddGameItemV2Data`
     * @response `400` `PutV2GamesGameIdItems400ErrorResponse`
     * @response `403` `PutV2GamesGameIdItems403ErrorResponse`
     * @response `404` `PutV2GamesGameIdItems404ErrorResponse`
     * @response `500` `PutV2GamesGameIdItems500ErrorResponse`
     */
    addGameItemV2: (gameId: string, data: GameItemRequest, params: RequestParams = {}) =>
      this.request<AddGameItemV2Data, AddGameItemV2Error>({
        path: `/bo/v2/games/${gameId}/items`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name AddReleaseDates
     * @summary Generate release date
     * @request PATCH:/bo/games/release-dates
     * @secure
     * @response `200` `AddReleaseDatesData`
     * @response `403` `PatchGamesReleaseDates403ErrorResponse`
     */
    addReleaseDates: (params: RequestParams = {}) =>
      this.request<AddReleaseDatesData, AddReleaseDatesError>({
        path: `/bo/games/release-dates`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name ApplyDefaultStorageTagsToPublishingFiles
     * @summary Apply default storage tags to game publishing files
     * @request POST:/bo/games/apply-default-storage-tags-to-publishing-files
     * @secure
     * @response `200` `ApplyDefaultStorageTagsToPublishingFilesData`
     * @response `400` `PostGamesApplyDefaultStorageTagsToPublishingFiles400ErrorResponse`
     * @response `403` `PostGamesApplyDefaultStorageTagsToPublishingFiles403ErrorResponse`
     */
    applyDefaultStorageTagsToPublishingFiles: (
      query: ApplyDefaultStorageTagsToPublishingFilesParams,
      params: RequestParams = {},
    ) =>
      this.request<
        ApplyDefaultStorageTagsToPublishingFilesData,
        ApplyDefaultStorageTagsToPublishingFilesError
      >({
        path: `/bo/games/apply-default-storage-tags-to-publishing-files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name ApplyDefaultStorageTagsToReleaseFiles
     * @summary Apply default storage tags to game release files
     * @request POST:/bo/games/apply-default-storage-tags-to-release-files
     * @secure
     * @response `200` `ApplyDefaultStorageTagsToReleaseFilesData`
     * @response `400` `PostGamesApplyDefaultStorageTagsToReleaseFiles400ErrorResponse`
     * @response `403` `PostGamesApplyDefaultStorageTagsToReleaseFiles403ErrorResponse`
     */
    applyDefaultStorageTagsToReleaseFiles: (
      query: ApplyDefaultStorageTagsToReleaseFilesParams,
      params: RequestParams = {},
    ) =>
      this.request<
        ApplyDefaultStorageTagsToReleaseFilesData,
        ApplyDefaultStorageTagsToReleaseFilesError
      >({
        path: `/bo/games/apply-default-storage-tags-to-release-files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name CreateGameControl
     * @summary Create a game control
     * @request POST:/bo/games/{gameId}/control-subgroups/{controlSubgroupId}/controls
     * @secure
     * @response `200` `CreateGameControlData`
     * @response `400` `PostGamesGameIdControlSubgroupsControlSubgroupIdControls400ErrorResponse`
     * @response `403` `PostGamesGameIdControlSubgroupsControlSubgroupIdControls403ErrorResponse`
     * @response `404` `PostGamesGameIdControlSubgroupsControlSubgroupIdControls404ErrorResponse`
     */
    createGameControl: (
      gameId: string,
      controlSubgroupId: string,
      data: CreateGameControlRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateGameControlData, CreateGameControlError>({
        path: `/bo/games/${gameId}/control-subgroups/${controlSubgroupId}/controls`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name CreateGameControlGroup
     * @summary Create a game control group
     * @request POST:/bo/games/{gameId}/control-groups
     * @secure
     * @response `200` `CreateGameControlGroupData`
     * @response `400` `PostGamesGameIdControlGroups400ErrorResponse`
     * @response `403` `PostGamesGameIdControlGroups403ErrorResponse`
     * @response `404` `PostGamesGameIdControlGroups404ErrorResponse`
     */
    createGameControlGroup: (
      gameId: string,
      data: CreateGameControlGroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateGameControlGroupData, CreateGameControlGroupError>({
        path: `/bo/games/${gameId}/control-groups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name CreateGameControlSubgroup
     * @summary Create a game control subgroup
     * @request POST:/bo/games/{gameId}/control-groups/{controlGroupId}/control-subgroups
     * @secure
     * @response `200` `CreateGameControlSubgroupData`
     * @response `400` `PostGamesGameIdControlGroupsControlGroupIdControlSubgroups400ErrorResponse`
     * @response `403` `PostGamesGameIdControlGroupsControlGroupIdControlSubgroups403ErrorResponse`
     * @response `404` `PostGamesGameIdControlGroupsControlGroupIdControlSubgroups404ErrorResponse`
     */
    createGameControlSubgroup: (
      gameId: string,
      controlGroupId: string,
      data: CreateGameControlSubgroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateGameControlSubgroupData, CreateGameControlSubgroupError>({
        path: `/bo/games/${gameId}/control-groups/${controlGroupId}/control-subgroups`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name DeleteClaimPeriod
     * @summary Delete game item claim period for a full version game
     * @request DELETE:/bo/games/{gameId}/items/{itemId}/claim-periods/{periodId}
     * @secure
     * @response `200` `DeleteClaimPeriodData`
     * @response `400` `DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse`
     */
    deleteClaimPeriod: (
      gameId: string,
      itemId: string,
      periodId: string,
      params: RequestParams = {},
    ) =>
      this.request<DeleteClaimPeriodData, DeleteClaimPeriodError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods/${periodId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameBannerController
     * @name DeleteGameBanner
     * @summary Remove banner for a game
     * @request DELETE:/bo/games/{gameId}/banners/{bannerId}
     * @secure
     * @response `200` `DeleteGameBannerData`
     * @response `400` `DeleteGamesGameIdBannersBannerId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdBannersBannerId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdBannersBannerId404ErrorResponse`
     */
    deleteGameBanner: (gameId: string, bannerId: string, params: RequestParams = {}) =>
      this.request<DeleteGameBannerData, DeleteGameBannerError>({
        path: `/bo/games/${gameId}/banners/${bannerId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name DeleteGameControl
     * @summary Delete a game control
     * @request DELETE:/bo/games/{gameId}/controls/{gameControlId}
     * @secure
     * @response `200` `DeleteGameControlData`
     * @response `400` `DeleteGamesGameIdControlsGameControlId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdControlsGameControlId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdControlsGameControlId404ErrorResponse`
     */
    deleteGameControl: (gameId: string, gameControlId: string, params: RequestParams = {}) =>
      this.request<DeleteGameControlData, DeleteGameControlError>({
        path: `/bo/games/${gameId}/controls/${gameControlId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name DeleteGameControlGroup
     * @summary Delete a game control group
     * @request DELETE:/bo/games/{gameId}/control-groups/{controlGroupId}
     * @secure
     * @response `200` `DeleteGameControlGroupData`
     * @response `400` `DeleteGamesGameIdControlGroupsControlGroupId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdControlGroupsControlGroupId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdControlGroupsControlGroupId404ErrorResponse`
     */
    deleteGameControlGroup: (gameId: string, controlGroupId: string, params: RequestParams = {}) =>
      this.request<DeleteGameControlGroupData, DeleteGameControlGroupError>({
        path: `/bo/games/${gameId}/control-groups/${controlGroupId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name DeleteGameControlSubgroup
     * @summary Delete a game control subgroup
     * @request DELETE:/bo/games/{gameId}/control-subgroups/{controlSubgroupId}
     * @secure
     * @response `200` `DeleteGameControlSubgroupData`
     * @response `400` `DeleteGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse`
     */
    deleteGameControlSubgroup: (
      gameId: string,
      controlSubgroupId: string,
      params: RequestParams = {},
    ) =>
      this.request<DeleteGameControlSubgroupData, DeleteGameControlSubgroupError>({
        path: `/bo/games/${gameId}/control-subgroups/${controlSubgroupId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameCoverController
     * @name DeleteGameCover
     * @summary Delete cover for a game
     * @request DELETE:/bo/games/{gameId}/cover/{coverId}
     * @secure
     * @response `200` `DeleteGameCoverData`
     * @response `400` `DeleteGamesGameIdCoverCoverId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdCoverCoverId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdCoverCoverId404ErrorResponse`
     */
    deleteGameCover: (gameId: string, coverId: string, params: RequestParams = {}) =>
      this.request<DeleteGameCoverData, DeleteGameCoverError>({
        path: `/bo/games/${gameId}/cover/${coverId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemsController
     * @name DeleteGameItem
     * @summary Delete game item for a game
     * @request DELETE:/bo/games/{gameId}/items/{itemId}
     * @secure
     * @response `200` `DeleteGameItemData`
     * @response `400` `DeleteGamesGameIdItemsItemId400ErrorResponse`
     * @response `403` `DeleteGamesGameIdItemsItemId403ErrorResponse`
     * @response `404` `DeleteGamesGameIdItemsItemId404ErrorResponse`
     * @response `500` `DeleteGamesGameIdItemsItemId500ErrorResponse`
     */
    deleteGameItem: (gameId: string, itemId: string, params: RequestParams = {}) =>
      this.request<DeleteGameItemData, DeleteGameItemError>({
        path: `/bo/games/${gameId}/items/${itemId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name EditClaimPeriod
     * @summary Update game item claim period
     * @request PATCH:/bo/games/{gameId}/items/{itemId}/claim-periods/{periodId}
     * @secure
     * @response `200` `EditClaimPeriodData`
     * @response `400` `PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId400ErrorResponse`
     * @response `403` `PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse`
     * @response `404` `PatchGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse`
     */
    editClaimPeriod: (
      gameId: string,
      itemId: string,
      periodId: string,
      data: GameItemClaimPeriodRequest,
      params: RequestParams = {},
    ) =>
      this.request<EditClaimPeriodData, EditClaimPeriodError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods/${periodId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name EditGame
     * @summary Update a game
     * @request PATCH:/bo/games/{gameId}
     * @secure
     * @response `200` `EditGameData`
     * @response `400` `PatchGamesGameId400ErrorResponse`
     * @response `403` `PatchGamesGameId403ErrorResponse`
     * @response `404` `PatchGamesGameId404ErrorResponse`
     */
    editGame: (gameId: string, data: UpdateGameRequest, params: RequestParams = {}) =>
      this.request<EditGameData, EditGameError>({
        path: `/bo/games/${gameId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name EditTrack
     * @summary Edit a game release track
     * @request PATCH:/bo/games/{gameId}/tracks/{trackId}
     * @secure
     * @response `200` `EditTrackData`
     * @response `400` `PatchGamesGameIdTracksTrackId400ErrorResponse`
     * @response `403` `PatchGamesGameIdTracksTrackId403ErrorResponse`
     * @response `404` `PatchGamesGameIdTracksTrackId404ErrorResponse`
     */
    editTrack: (
      gameId: string,
      trackId: string,
      data: EditGameReleaseTrackRequest,
      params: RequestParams = {},
    ) =>
      this.request<EditTrackData, EditTrackError>({
        path: `/bo/games/${gameId}/tracks/${trackId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GenerateGameShortIds
     * @summary Generate short ids for all games
     * @request PATCH:/bo/games/short-ids
     * @secure
     * @response `200` `GenerateGameShortIdsData`
     * @response `403` `PatchGamesShortIds403ErrorResponse`
     */
    generateGameShortIds: (params: RequestParams = {}) =>
      this.request<GenerateGameShortIdsData, GenerateGameShortIdsError>({
        path: `/bo/games/short-ids`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GenerateNewGameShortId
     * @summary Generate new short id
     * @request GET:/bo/games/generate-short-id
     * @secure
     * @response `200` `GenerateNewGameShortIdResult`
     * @response `403` `GetGamesGenerateShortId403ErrorResponse`
     */
    generateNewGameShortId: (params: RequestParams = {}) =>
      this.request<GenerateNewGameShortIdResult, GenerateNewGameShortIdFail>({
        path: `/bo/games/generate-short-id`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name GetClaimPeriodDetails
     * @summary Get full version game item claim period details
     * @request GET:/bo/games/{gameId}/items/{itemId}/claim-periods/{periodId}
     * @secure
     * @response `200` `GetClaimPeriodDetailsData`
     * @response `403` `GetGamesGameIdItemsItemIdClaimPeriodsPeriodId403ErrorResponse`
     * @response `404` `GetGamesGameIdItemsItemIdClaimPeriodsPeriodId404ErrorResponse`
     */
    getClaimPeriodDetails: (
      gameId: string,
      itemId: string,
      periodId: string,
      params: RequestParams = {},
    ) =>
      this.request<GetClaimPeriodDetailsData, GetClaimPeriodDetailsError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods/${periodId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name GetClaimPeriodsDetails
     * @summary Get full version game item claim periods details
     * @request GET:/bo/games/{gameId}/items/{itemId}/claim-periods
     * @secure
     * @response `200` `GetClaimPeriodsDetailsData`
     * @response `400` `GetGamesGameIdItemsItemIdClaimPeriods400ErrorResponse`
     * @response `403` `GetGamesGameIdItemsItemIdClaimPeriods403ErrorResponse`
     * @response `404` `GetGamesGameIdItemsItemIdClaimPeriods404ErrorResponse`
     */
    getClaimPeriodsDetails: (
      { gameId, itemId, ...query }: GetClaimPeriodsDetailsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetClaimPeriodsDetailsData, GetClaimPeriodsDetailsError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameStatisticsController
     * @name GetDataPoints
     * @summary Request missing game statistics metric data points for date range and return data points that are already available
     * @request GET:/bo/games/{gameId}/statistics
     * @secure
     * @response `200` `GetDataPointsData`
     * @response `400` `GetGamesGameIdStatistics400ErrorResponse`
     * @response `403` `GetGamesGameIdStatistics403ErrorResponse`
     * @response `404` `GetGamesGameIdStatistics404ErrorResponse`
     */
    getDataPoints: ({ gameId, ...query }: GetDataPointsParams, params: RequestParams = {}) =>
      this.request<GetDataPointsData, GetDataPointsError>({
        path: `/bo/games/${gameId}/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GetGame
     * @summary Get a single game
     * @request GET:/bo/games/{gameId}
     * @secure
     * @response `200` `GetGameData`
     * @response `403` `GetGamesGameId403ErrorResponse`
     * @response `404` `GetGamesGameId404ErrorResponse`
     */
    getGame: (gameId: string, params: RequestParams = {}) =>
      this.request<GetGameData, GetGameError>({
        path: `/bo/games/${gameId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameBannerController
     * @name GetGameBanners
     * @summary Get banners for game
     * @request GET:/bo/games/{gameId}/banners
     * @secure
     * @response `200` `GetGameBannersData`
     * @response `400` `GetGamesGameIdBanners400ErrorResponse`
     * @response `403` `GetGamesGameIdBanners403ErrorResponse`
     * @response `404` `GetGamesGameIdBanners404ErrorResponse`
     */
    getGameBanners: ({ gameId, ...query }: GetGameBannersParams, params: RequestParams = {}) =>
      this.request<GetGameBannersData, GetGameBannersError>({
        path: `/bo/games/${gameId}/banners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GetGameChallenges
     * @summary Get game challenges
     * @request GET:/bo/games/{gameId}/challenges
     * @secure
     * @response `200` `GetGameChallengesData`
     * @response `400` `GetGamesGameIdChallenges400ErrorResponse`
     * @response `403` `GetGamesGameIdChallenges403ErrorResponse`
     * @response `404` `GetGamesGameIdChallenges404ErrorResponse`
     */
    getGameChallenges: (
      { gameId, ...query }: GetGameChallengesParams,
      params: RequestParams = {},
    ) =>
      this.request<GetGameChallengesData, GetGameChallengesError>({
        path: `/bo/games/${gameId}/challenges`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemsController
     * @name GetGameItemDetails
     * @summary Get game item details
     * @request GET:/bo/games/{gameId}/items/{itemId}
     * @secure
     * @response `200` `GetGameItemDetailsData`
     * @response `403` `GetGamesGameIdItemsItemId403ErrorResponse`
     * @response `404` `GetGamesGameIdItemsItemId404ErrorResponse`
     */
    getGameItemDetails: (gameId: string, itemId: string, params: RequestParams = {}) =>
      this.request<GetGameItemDetailsData, GetGameItemDetailsError>({
        path: `/bo/games/${gameId}/items/${itemId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemsController
     * @name GetGameItemsDetails
     * @summary Get game items details
     * @request GET:/bo/games/{gameId}/items
     * @secure
     * @response `200` `GetGameItemsDetailsData`
     * @response `400` `GetGamesGameIdItems400ErrorResponse`
     * @response `403` `GetGamesGameIdItems403ErrorResponse`
     * @response `404` `GetGamesGameIdItems404ErrorResponse`
     */
    getGameItemsDetails: (
      { gameId, ...query }: GetGameItemsDetailsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetGameItemsDetailsData, GetGameItemsDetailsError>({
        path: `/bo/games/${gameId}/items`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GetGameReleases
     * @summary Get game releases
     * @request GET:/bo/games/{gameId}/tracks/{trackId}/releases
     * @secure
     * @response `200` `GetGameReleasesData`
     * @response `400` `GetGamesGameIdTracksTrackIdReleases400ErrorResponse`
     * @response `403` `GetGamesGameIdTracksTrackIdReleases403ErrorResponse`
     * @response `404` `GetGamesGameIdTracksTrackIdReleases404ErrorResponse`
     */
    getGameReleases: (
      { gameId, trackId, ...query }: GetGameReleasesParams,
      params: RequestParams = {},
    ) =>
      this.request<GetGameReleasesData, GetGameReleasesError>({
        path: `/bo/games/${gameId}/tracks/${trackId}/releases`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GetGames
     * @summary Get all games with optional filters
     * @request GET:/bo/games
     * @secure
     * @response `200` `GetGamesData`
     * @response `400` `GetGames400ErrorResponse`
     * @response `403` `GetGames403ErrorResponse`
     * @response `404` `GetGames404ErrorResponse`
     */
    getGames: (query: GetGamesParams, params: RequestParams = {}) =>
      this.request<GetGamesData, GetGamesError>({
        path: `/bo/games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamePlayStatisticsController
     * @name GetPerGameStatistics
     * @summary Get game play statistics for specified game
     * @request GET:/bo/games/{gameId}/game-play-statistics
     * @secure
     * @response `200` `GetPerGameStatisticsData`
     * @response `403` `GetGamesGameIdGamePlayStatistics403ErrorResponse`
     * @response `404` `GetGamesGameIdGamePlayStatistics404ErrorResponse`
     */
    getPerGameStatistics: (
      { gameId, ...query }: GetPerGameStatisticsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetPerGameStatisticsData, GetPerGameStatisticsError>({
        path: `/bo/games/${gameId}/game-play-statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name GetTrack
     * @summary Get a single game release track
     * @request GET:/bo/games/{gameId}/tracks/{trackId}
     * @secure
     * @response `200` `GetTrackData`
     * @response `403` `GetGamesGameIdTracksTrackId403ErrorResponse`
     * @response `404` `GetGamesGameIdTracksTrackId404ErrorResponse`
     */
    getTrack: (gameId: string, trackId: string, params: RequestParams = {}) =>
      this.request<GetTrackData, GetTrackError>({
        path: `/bo/games/${gameId}/tracks/${trackId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name ListGameControlGroups
     * @summary Get game controls info for a specific game
     * @request GET:/bo/games/{gameId}/control-groups
     * @secure
     * @response `200` `ListGameControlGroupsData`
     * @response `403` `GetGamesGameIdControlGroups403ErrorResponse`
     * @response `404` `GetGamesGameIdControlGroups404ErrorResponse`
     */
    listGameControlGroups: (gameId: string, params: RequestParams = {}) =>
      this.request<ListGameControlGroupsData, ListGameControlGroupsError>({
        path: `/bo/games/${gameId}/control-groups`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameReviewController
     * @name PostGameReviewV2
     * @summary Post game review
     * @request POST:/bo/v2/games/{gameId}/reviews
     * @secure
     * @response `200` `PostGameReviewV2Data`
     * @response `400` `PostV2GamesGameIdReviews400ErrorResponse`
     * @response `403` `PostV2GamesGameIdReviews403ErrorResponse`
     * @response `404` `PostV2GamesGameIdReviews404ErrorResponse`
     */
    postGameReviewV2: (gameId: string, data: CreateReviewRequest, params: RequestParams = {}) =>
      this.request<PostGameReviewV2Data, PostGameReviewV2Error>({
        path: `/bo/v2/games/${gameId}/reviews`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name ReprocessCurrentReleases
     * @summary Schedule reprocessing of current releases
     * @request POST:/bo/games/reprocess-current-releases
     * @secure
     * @response `200` `ReprocessCurrentReleasesData`
     * @response `403` `PostGamesReprocessCurrentReleases403ErrorResponse`
     */
    reprocessCurrentReleases: (query: ReprocessCurrentReleasesParams, params: RequestParams = {}) =>
      this.request<ReprocessCurrentReleasesData, ReprocessCurrentReleasesError>({
        path: `/bo/games/reprocess-current-releases`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name ReprocessGames
     * @summary Schedule reprocessing of all games
     * @request POST:/bo/games/reprocess
     * @secure
     * @response `200` `ReprocessGamesData`
     * @response `403` `PostGamesReprocess403ErrorResponse`
     */
    reprocessGames: (query: ReprocessGamesParams, params: RequestParams = {}) =>
      this.request<ReprocessGamesData, ReprocessGamesError>({
        path: `/bo/games/reprocess`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemClaimPeriodsController
     * @name TerminateClaimPeriod
     * @summary Terminate an ongoing game item claim period spot on
     * @request POST:/bo/games/{gameId}/items/{itemId}/claim-periods/{periodId}/terminate
     * @secure
     * @response `200` `TerminateClaimPeriodData`
     * @response `400` `PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate400ErrorResponse`
     * @response `403` `PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate403ErrorResponse`
     * @response `404` `PostGamesGameIdItemsItemIdClaimPeriodsPeriodIdTerminate404ErrorResponse`
     */
    terminateClaimPeriod: (
      gameId: string,
      itemId: string,
      periodId: string,
      params: RequestParams = {},
    ) =>
      this.request<TerminateClaimPeriodData, TerminateClaimPeriodError>({
        path: `/bo/games/${gameId}/items/${itemId}/claim-periods/${periodId}/terminate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name UnpublishInternalTracksWithSharedArrayBuffer
     * @summary Unpublish internal release tracks of all games which latest release version uses SharedArrayBuffer
     * @request PATCH:/bo/games/unpublish-shared-array-buffer-internal-tracks
     * @secure
     * @response `200` `UnpublishInternalTracksWithSharedArrayBufferData`
     * @response `400` `PatchGamesUnpublishSharedArrayBufferInternalTracks400ErrorResponse`
     * @response `403` `PatchGamesUnpublishSharedArrayBufferInternalTracks403ErrorResponse`
     */
    unpublishInternalTracksWithSharedArrayBuffer: (
      query: UnpublishInternalTracksWithSharedArrayBufferParams,
      params: RequestParams = {},
    ) =>
      this.request<
        UnpublishInternalTracksWithSharedArrayBufferData,
        UnpublishInternalTracksWithSharedArrayBufferError
      >({
        path: `/bo/games/unpublish-shared-array-buffer-internal-tracks`,
        method: 'PATCH',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GamesController
     * @name UnpublishPublicTracksWithSharedArrayBuffer
     * @summary Unpublish public release tracks of all games which latest release version uses SharedArrayBuffer
     * @request PATCH:/bo/games/unpublish-shared-array-buffer-public-tracks
     * @secure
     * @response `200` `UnpublishPublicTracksWithSharedArrayBufferData`
     * @response `400` `PatchGamesUnpublishSharedArrayBufferPublicTracks400ErrorResponse`
     * @response `403` `PatchGamesUnpublishSharedArrayBufferPublicTracks403ErrorResponse`
     */
    unpublishPublicTracksWithSharedArrayBuffer: (
      query: UnpublishPublicTracksWithSharedArrayBufferParams,
      params: RequestParams = {},
    ) =>
      this.request<
        UnpublishPublicTracksWithSharedArrayBufferData,
        UnpublishPublicTracksWithSharedArrayBufferError
      >({
        path: `/bo/games/unpublish-shared-array-buffer-public-tracks`,
        method: 'PATCH',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameBannerController
     * @name UpdateGameBanner
     * @summary Update game banner
     * @request PATCH:/bo/games/{gameId}/banners/{bannerId}
     * @secure
     * @response `200` `UpdateGameBannerData`
     * @response `400` `PatchGamesGameIdBannersBannerId400ErrorResponse`
     * @response `403` `PatchGamesGameIdBannersBannerId403ErrorResponse`
     * @response `404` `PatchGamesGameIdBannersBannerId404ErrorResponse`
     */
    updateGameBanner: (
      gameId: string,
      bannerId: string,
      data: UpdateGameBannerRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameBannerData, UpdateGameBannerError>({
        path: `/bo/games/${gameId}/banners/${bannerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name UpdateGameControlGroup
     * @summary Update game control group
     * @request PATCH:/bo/games/{gameId}/control-groups/{controlGroupId}
     * @secure
     * @response `200` `UpdateGameControlGroupData`
     * @response `400` `PatchGamesGameIdControlGroupsControlGroupId400ErrorResponse`
     * @response `403` `PatchGamesGameIdControlGroupsControlGroupId403ErrorResponse`
     * @response `404` `PatchGamesGameIdControlGroupsControlGroupId404ErrorResponse`
     */
    updateGameControlGroup: (
      gameId: string,
      controlGroupId: string,
      data: UpdateGameControlGroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameControlGroupData, UpdateGameControlGroupError>({
        path: `/bo/games/${gameId}/control-groups/${controlGroupId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name UpdateGameControlGroupOrdering
     * @summary Update a game controls info
     * @request PATCH:/bo/games/{gameId}/control-groups
     * @secure
     * @response `200` `UpdateGameControlGroupOrderingData`
     * @response `400` `PatchGamesGameIdControlGroups400ErrorResponse`
     * @response `403` `PatchGamesGameIdControlGroups403ErrorResponse`
     * @response `404` `PatchGamesGameIdControlGroups404ErrorResponse`
     */
    updateGameControlGroupOrdering: (
      gameId: string,
      data: UpdateGameControlGroupOrderingRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameControlGroupOrderingData, UpdateGameControlGroupOrderingError>({
        path: `/bo/games/${gameId}/control-groups`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameControlsController
     * @name UpdateGameControlSubgroup
     * @summary Update game control subgroup
     * @request PATCH:/bo/games/{gameId}/control-subgroups/{controlSubgroupId}
     * @secure
     * @response `200` `UpdateGameControlSubgroupData`
     * @response `400` `PatchGamesGameIdControlSubgroupsControlSubgroupId400ErrorResponse`
     * @response `403` `PatchGamesGameIdControlSubgroupsControlSubgroupId403ErrorResponse`
     * @response `404` `PatchGamesGameIdControlSubgroupsControlSubgroupId404ErrorResponse`
     */
    updateGameControlSubgroup: (
      gameId: string,
      controlSubgroupId: string,
      data: UpdateGameControlSubgroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameControlSubgroupData, UpdateGameControlSubgroupError>({
        path: `/bo/games/${gameId}/control-subgroups/${controlSubgroupId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameItemsController
     * @name UpdateGameItem
     * @summary Update game item
     * @request PATCH:/bo/games/{gameId}/items/{itemId}
     * @secure
     * @response `200` `UpdateGameItemData`
     * @response `400` `PatchGamesGameIdItemsItemId400ErrorResponse`
     * @response `403` `PatchGamesGameIdItemsItemId403ErrorResponse`
     * @response `404` `PatchGamesGameIdItemsItemId404ErrorResponse`
     * @response `500` `PatchGamesGameIdItemsItemId500ErrorResponse`
     */
    updateGameItem: (
      gameId: string,
      itemId: string,
      data: GameItemUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGameItemData, UpdateGameItemError>({
        path: `/bo/games/${gameId}/items/${itemId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameBannerController
     * @name UploadGameBanner
     * @summary Upload banner image for game
     * @request POST:/bo/games/{gameId}/banners
     * @secure
     * @response `200` `UploadGameBannerData`
     * @response `400` `PostGamesGameIdBanners400ErrorResponse`
     * @response `403` `PostGamesGameIdBanners403ErrorResponse`
     * @response `404` `PostGamesGameIdBanners404ErrorResponse`
     * @response `500` `PostGamesGameIdBanners500ErrorResponse`
     */
    uploadGameBanner: (
      { gameId, ...query }: UploadGameBannerParams,
      data: UploadGameBannerPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadGameBannerData, UploadGameBannerError>({
        path: `/bo/games/${gameId}/banners`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags games, GameCoverController
     * @name UploadGameCover
     * @summary Upload cover image for game
     * @request PUT:/bo/games/{gameId}/cover
     * @secure
     * @response `200` `UploadGameCoverData`
     * @response `400` `PutGamesGameIdCover400ErrorResponse`
     * @response `403` `PutGamesGameIdCover403ErrorResponse`
     * @response `404` `PutGamesGameIdCover404ErrorResponse`
     * @response `500` `PutGamesGameIdCover500ErrorResponse`
     */
    uploadGameCover: (
      { gameId, ...query }: UploadGameCoverParams,
      data: UploadGameCoverPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadGameCoverData, UploadGameCoverError>({
        path: `/bo/games/${gameId}/cover`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  gmsRunners = {
    /**
     * No description
     *
     * @tags gms-runners, GmsRunnerBulkDeletionController
     * @name DeleteRunner
     * @summary Delete multiple runner versions
     * @request DELETE:/bo/gms-runners
     * @secure
     * @response `200` `DeleteRunnerData`
     * @response `400` `DeleteGmsRunners400ErrorResponse`
     * @response `403` `DeleteGmsRunners403ErrorResponse`
     * @response `404` `DeleteGmsRunners404ErrorResponse`
     * @response `500` `DeleteGmsRunners500ErrorResponse`
     */
    deleteRunner: (data: GmsRunnerBulkDeletionRequest, params: RequestParams = {}) =>
      this.request<DeleteRunnerData, DeleteRunnerError>({
        path: `/bo/gms-runners`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags gms-runners, GmsRunnerController
     * @name DeleteRunner2
     * @summary Delete a runner version
     * @request DELETE:/bo/gms-runners/{runnerId}
     * @originalName deleteRunner
     * @duplicate
     * @secure
     * @response `200` `DeleteRunner2Data`
     * @response `400` `DeleteGmsRunnersRunnerId400ErrorResponse`
     * @response `403` `DeleteGmsRunnersRunnerId403ErrorResponse`
     * @response `404` `DeleteGmsRunnersRunnerId404ErrorResponse`
     * @response `500` `DeleteGmsRunnersRunnerId500ErrorResponse`
     */
    deleteRunner2: (runnerId: string, params: RequestParams = {}) =>
      this.request<DeleteRunner2Data, DeleteRunner2Error>({
        path: `/bo/gms-runners/${runnerId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags gms-runners, GmsRunnerController
     * @name GetRunners
     * @summary Paginated list of all uploaded runner versions
     * @request GET:/bo/gms-runners
     * @secure
     * @response `200` `GetRunnersData`
     * @response `400` `GetGmsRunners400ErrorResponse`
     * @response `403` `GetGmsRunners403ErrorResponse`
     */
    getRunners: (query: GetRunnersParams, params: RequestParams = {}) =>
      this.request<GetRunnersData, GetRunnersError>({
        path: `/bo/gms-runners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags gms-runners, GmsRunnerController
     * @name UpdateRunner
     * @summary Modify any data of an uploaded runner version, except the files uploaded
     * @request PATCH:/bo/gms-runners/{runnerId}
     * @secure
     * @response `200` `UpdateRunnerData`
     * @response `400` `PatchGmsRunnersRunnerId400ErrorResponse`
     * @response `403` `PatchGmsRunnersRunnerId403ErrorResponse`
     * @response `404` `PatchGmsRunnersRunnerId404ErrorResponse`
     */
    updateRunner: (runnerId: string, data: UpdateGmsRunnerRequest, params: RequestParams = {}) =>
      this.request<UpdateRunnerData, UpdateRunnerError>({
        path: `/bo/gms-runners/${runnerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  items = {
    /**
     * No description
     *
     * @tags items, GameItemsController
     * @name GetSupportedCurrencies
     * @summary List of supported currencies
     * @request GET:/bo/items/currencies
     * @secure
     * @response `200` `GetSupportedCurrenciesData`
     * @response `403` `GetItemsCurrencies403ErrorResponse`
     */
    getSupportedCurrencies: (params: RequestParams = {}) =>
      this.request<GetSupportedCurrenciesData, GetSupportedCurrenciesError>({
        path: `/bo/items/currencies`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  migration = {
    /**
     * No description
     *
     * @tags migration, GamesController
     * @name AddReleasableAccessToGames
     * @summary Add `ReleasableAccess` entities to games
     * @request POST:/bo/migration/games/add-releasable-access-entities
     * @secure
     * @response `200` `AddReleasableAccessToGamesData`
     * @response `400` `PostMigrationGamesAddReleasableAccessEntities400ErrorResponse`
     * @response `403` `PostMigrationGamesAddReleasableAccessEntities403ErrorResponse`
     */
    addReleasableAccessToGames: (
      query: AddReleasableAccessToGamesParams,
      params: RequestParams = {},
    ) =>
      this.request<AddReleasableAccessToGamesData, AddReleasableAccessToGamesError>({
        path: `/bo/migration/games/add-releasable-access-entities`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration, GamesController
     * @name AddReleasableAssetToGames
     * @summary Add `ReleasableAsset` entities to games
     * @request POST:/bo/migration/games/add-releasable-asset-entities
     * @secure
     * @response `200` `AddReleasableAssetToGamesData`
     * @response `400` `PostMigrationGamesAddReleasableAssetEntities400ErrorResponse`
     * @response `403` `PostMigrationGamesAddReleasableAssetEntities403ErrorResponse`
     */
    addReleasableAssetToGames: (
      query: AddReleasableAssetToGamesParams,
      params: RequestParams = {},
    ) =>
      this.request<AddReleasableAssetToGamesData, AddReleasableAssetToGamesError>({
        path: `/bo/migration/games/add-releasable-asset-entities`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration, GamesController
     * @name AddReleasableCoverToGames
     * @summary Add `ReleasableCover` entities to games
     * @request POST:/bo/migration/games/add-releasable-cover-entities
     * @secure
     * @response `200` `AddReleasableCoverToGamesData`
     * @response `400` `PostMigrationGamesAddReleasableCoverEntities400ErrorResponse`
     * @response `403` `PostMigrationGamesAddReleasableCoverEntities403ErrorResponse`
     */
    addReleasableCoverToGames: (
      query: AddReleasableCoverToGamesParams,
      params: RequestParams = {},
    ) =>
      this.request<AddReleasableCoverToGamesData, AddReleasableCoverToGamesError>({
        path: `/bo/migration/games/add-releasable-cover-entities`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration, GamesController
     * @name AddReleasableToGames
     * @summary Add `Releasable`, `ReleaseTrack` and `Release` entities to games
     * @request POST:/bo/migration/games/add-releasable-entities
     * @secure
     * @response `200` `AddReleasableToGamesData`
     * @response `400` `PostMigrationGamesAddReleasableEntities400ErrorResponse`
     * @response `403` `PostMigrationGamesAddReleasableEntities403ErrorResponse`
     */
    addReleasableToGames: (query: AddReleasableToGamesParams, params: RequestParams = {}) =>
      this.request<AddReleasableToGamesData, AddReleasableToGamesError>({
        path: `/bo/migration/games/add-releasable-entities`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags migration, ModFlavorController
     * @name RecordModFlavorSizes
     * @summary Migration for recording size of mod flavors
     * @request POST:/bo/migration/mods/flavors/record-sizes
     * @secure
     * @response `200` `RecordModFlavorSizesData`
     * @response `400` `PostMigrationModsFlavorsRecordSizes400ErrorResponse`
     * @response `403` `PostMigrationModsFlavorsRecordSizes403ErrorResponse`
     */
    recordModFlavorSizes: (query: RecordModFlavorSizesParams, params: RequestParams = {}) =>
      this.request<RecordModFlavorSizesData, RecordModFlavorSizesError>({
        path: `/bo/migration/mods/flavors/record-sizes`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  modTransfers = {
    /**
     * No description
     *
     * @tags mod-transfers, ModTransferController
     * @name GetModTransfer
     * @summary Get mod transfer
     * @request GET:/bo/mod-transfers/{transferId}
     * @secure
     * @response `200` `GetModTransferData`
     * @response `403` `GetModTransfersTransferId403ErrorResponse`
     * @response `404` `GetModTransfersTransferId404ErrorResponse`
     */
    getModTransfer: (transferId: string, params: RequestParams = {}) =>
      this.request<GetModTransferData, GetModTransferError>({
        path: `/bo/mod-transfers/${transferId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mod-transfers, ModTransferController
     * @name GetModTransfers
     * @summary Get mod transfers
     * @request GET:/bo/mod-transfers
     * @secure
     * @response `200` `GetModTransfersData`
     * @response `400` `GetModTransfers400ErrorResponse`
     * @response `403` `GetModTransfers403ErrorResponse`
     * @response `404` `GetModTransfers404ErrorResponse`
     */
    getModTransfers: (query: GetModTransfersParams, params: RequestParams = {}) =>
      this.request<GetModTransfersData, GetModTransfersError>({
        path: `/bo/mod-transfers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mod-transfers, ModTransferController
     * @name ResolveModTransfer
     * @summary Resolve mod transfer
     * @request PATCH:/bo/mod-transfers/{transferId}
     * @secure
     * @response `200` `ResolveModTransferData`
     * @response `400` `PatchModTransfersTransferId400ErrorResponse`
     * @response `403` `PatchModTransfersTransferId403ErrorResponse`
     * @response `404` `PatchModTransfersTransferId404ErrorResponse`
     */
    resolveModTransfer: (
      transferId: string,
      data: TransferUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<ResolveModTransferData, ResolveModTransferError>({
        path: `/bo/mod-transfers/${transferId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  mods = {
    /**
     * No description
     *
     * @tags mods, ModsController
     * @name ApplyDefaultStorageTagsToPublishingFiles
     * @summary Apply default storage tags to mods publishing files
     * @request POST:/bo/mods/apply-default-storage-tags-to-publishing-files
     * @secure
     * @response `200` `ApplyDefaultStorageTagsToPublishingFilesResult`
     * @response `400` `PostModsApplyDefaultStorageTagsToPublishingFiles400ErrorResponse`
     * @response `403` `PostModsApplyDefaultStorageTagsToPublishingFiles403ErrorResponse`
     */
    applyDefaultStorageTagsToPublishingFiles: (
      query: ApplyDefaultStorageTagsToPublishingFilesParams2,
      params: RequestParams = {},
    ) =>
      this.request<
        ApplyDefaultStorageTagsToPublishingFilesResult,
        ApplyDefaultStorageTagsToPublishingFilesFail
      >({
        path: `/bo/mods/apply-default-storage-tags-to-publishing-files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name ApplyDefaultStorageTagsToReleaseFiles
     * @summary Apply default storage tags to mod release files
     * @request POST:/bo/mods/apply-default-storage-tags-to-release-files
     * @secure
     * @response `200` `ApplyDefaultStorageTagsToReleaseFilesResult`
     * @response `400` `PostModsApplyDefaultStorageTagsToReleaseFiles400ErrorResponse`
     * @response `403` `PostModsApplyDefaultStorageTagsToReleaseFiles403ErrorResponse`
     */
    applyDefaultStorageTagsToReleaseFiles: (
      query: ApplyDefaultStorageTagsToReleaseFilesParams2,
      params: RequestParams = {},
    ) =>
      this.request<
        ApplyDefaultStorageTagsToReleaseFilesResult,
        ApplyDefaultStorageTagsToReleaseFilesFail
      >({
        path: `/bo/mods/apply-default-storage-tags-to-release-files`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModReleaseTracksController
     * @name CreateRelease
     * @summary Create a new current release
     * @request POST:/bo/mods/{modId}/tracks/{trackId}/create-release
     * @secure
     * @response `200` `CreateReleaseData`
     * @response `400` `PostModsModIdTracksTrackIdCreateRelease400ErrorResponse`
     * @response `403` `PostModsModIdTracksTrackIdCreateRelease403ErrorResponse`
     * @response `404` `PostModsModIdTracksTrackIdCreateRelease404ErrorResponse`
     * @response `500` `PostModsModIdTracksTrackIdCreateRelease500ErrorResponse`
     */
    createRelease: (modId: string, trackId: string, params: RequestParams = {}) =>
      this.request<CreateReleaseData, CreateReleaseError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/create-release`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModFlavorController
     * @name DeleteFlavorForAllReleases
     * @summary Delete all mod flavors with a given feature set
     * @request POST:/bo/mods/flavors/delete-flavor-for-all-releases
     * @secure
     * @response `200` `DeleteFlavorForAllReleasesData`
     * @response `400` `PostModsFlavorsDeleteFlavorForAllReleases400ErrorResponse`
     * @response `403` `PostModsFlavorsDeleteFlavorForAllReleases403ErrorResponse`
     */
    deleteFlavorForAllReleases: (
      query: DeleteFlavorForAllReleasesParams,
      params: RequestParams = {},
    ) =>
      this.request<DeleteFlavorForAllReleasesData, DeleteFlavorForAllReleasesError>({
        path: `/bo/mods/flavors/delete-flavor-for-all-releases`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModCoverController
     * @name DeleteModCover
     * @summary Delete cover for a mod
     * @request DELETE:/bo/mods/{modId}/cover/{coverId}
     * @secure
     * @response `200` `DeleteModCoverData`
     * @response `400` `DeleteModsModIdCoverCoverId400ErrorResponse`
     * @response `403` `DeleteModsModIdCoverCoverId403ErrorResponse`
     * @response `404` `DeleteModsModIdCoverCoverId404ErrorResponse`
     */
    deleteModCover: (modId: string, coverId: string, params: RequestParams = {}) =>
      this.request<DeleteModCoverData, DeleteModCoverError>({
        path: `/bo/mods/${modId}/cover/${coverId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModFlavorController
     * @name DeleteModFlavor
     * @summary Delete a mod flavor
     * @request DELETE:/bo/mods/{modId}/tracks/{trackId}/releases/{releaseId}/flavors/{flavorId}
     * @secure
     * @response `200` `DeleteModFlavorData`
     * @response `400` `DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId400ErrorResponse`
     * @response `403` `DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId403ErrorResponse`
     * @response `404` `DeleteModsModIdTracksTrackIdReleasesReleaseIdFlavorsFlavorId404ErrorResponse`
     */
    deleteModFlavor: (
      modId: string,
      trackId: string,
      releaseId: string,
      flavorId: string,
      params: RequestParams = {},
    ) =>
      this.request<DeleteModFlavorData, DeleteModFlavorError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/releases/${releaseId}/flavors/${flavorId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModGraphicsController
     * @name DeleteModGraphic
     * @summary Delete graphic for a mod
     * @request DELETE:/bo/mods/{modId}/graphics/{graphicId}
     * @secure
     * @response `200` `DeleteModGraphicData`
     * @response `403` `DeleteModsModIdGraphicsGraphicId403ErrorResponse`
     * @response `404` `DeleteModsModIdGraphicsGraphicId404ErrorResponse`
     * @response `500` `DeleteModsModIdGraphicsGraphicId500ErrorResponse`
     */
    deleteModGraphic: (modId: string, graphicId: string, params: RequestParams = {}) =>
      this.request<DeleteModGraphicData, DeleteModGraphicError>({
        path: `/bo/mods/${modId}/graphics/${graphicId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModReleasesController
     * @name DeletePendingRelease
     * @summary Delete a pending release
     * @request DELETE:/bo/mods/{modId}/tracks/{trackId}/releases/{releaseId}
     * @secure
     * @response `200` `DeletePendingReleaseData`
     * @response `400` `DeleteModsModIdTracksTrackIdReleasesReleaseId400ErrorResponse`
     * @response `403` `DeleteModsModIdTracksTrackIdReleasesReleaseId403ErrorResponse`
     * @response `404` `DeleteModsModIdTracksTrackIdReleasesReleaseId404ErrorResponse`
     */
    deletePendingRelease: (
      modId: string,
      trackId: string,
      releaseId: string,
      params: RequestParams = {},
    ) =>
      this.request<DeletePendingReleaseData, DeletePendingReleaseError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/releases/${releaseId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name DetectManifestTraits
     * @summary Detect mod manifest traits for current mod releases
     * @request POST:/bo/mods/detect-manifest-traits
     * @secure
     * @response `200` `DetectManifestTraitsData`
     * @response `400` `PostModsDetectManifestTraits400ErrorResponse`
     * @response `403` `PostModsDetectManifestTraits403ErrorResponse`
     */
    detectManifestTraits: (query: DetectManifestTraitsParams, params: RequestParams = {}) =>
      this.request<DetectManifestTraitsData, DetectManifestTraitsError>({
        path: `/bo/mods/detect-manifest-traits`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name GetModDetails
     * @summary Get mod details
     * @request GET:/bo/mods/{modId}
     * @secure
     * @response `200` `GetModDetailsData`
     * @response `403` `GetModsModId403ErrorResponse`
     * @response `404` `GetModsModId404ErrorResponse`
     */
    getModDetails: (modId: string, params: RequestParams = {}) =>
      this.request<GetModDetailsData, GetModDetailsError>({
        path: `/bo/mods/${modId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModFlavorController
     * @name GetModFlavors
     * @summary List all existing flavors of a specific release
     * @request GET:/bo/mods/{modId}/tracks/{trackId}/releases/{releaseId}/flavors
     * @secure
     * @response `200` `GetModFlavorsData`
     * @response `403` `GetModsModIdTracksTrackIdReleasesReleaseIdFlavors403ErrorResponse`
     * @response `404` `GetModsModIdTracksTrackIdReleasesReleaseIdFlavors404ErrorResponse`
     */
    getModFlavors: (
      modId: string,
      trackId: string,
      releaseId: string,
      params: RequestParams = {},
    ) =>
      this.request<GetModFlavorsData, GetModFlavorsError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/releases/${releaseId}/flavors`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ReviewController
     * @name GetModReviewRequests
     * @summary Get all review requests of a specific mod
     * @request GET:/bo/mods/{modId}/review-requests
     * @secure
     * @response `200` `GetModReviewRequestsData`
     * @response `400` `GetModsModIdReviewRequests400ErrorResponse`
     * @response `403` `GetModsModIdReviewRequests403ErrorResponse`
     * @response `404` `GetModsModIdReviewRequests404ErrorResponse`
     */
    getModReviewRequests: (
      { modId, ...query }: GetModReviewRequestsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetModReviewRequestsData, GetModReviewRequestsError>({
        path: `/bo/mods/${modId}/review-requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ReviewController
     * @name GetModReviews
     * @summary Get all reviews of a specific mod
     * @request GET:/bo/mods/{modId}/reviews
     * @secure
     * @response `200` `GetModReviewsData`
     * @response `400` `GetModsModIdReviews400ErrorResponse`
     * @response `403` `GetModsModIdReviews403ErrorResponse`
     * @response `404` `GetModsModIdReviews404ErrorResponse`
     */
    getModReviews: ({ modId, ...query }: GetModReviewsParams, params: RequestParams = {}) =>
      this.request<GetModReviewsData, GetModReviewsError>({
        path: `/bo/mods/${modId}/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name GetMods
     * @summary Get all mods
     * @request GET:/bo/mods
     * @secure
     * @response `200` `GetModsData`
     * @response `400` `GetMods400ErrorResponse`
     * @response `403` `GetMods403ErrorResponse`
     * @response `404` `GetMods404ErrorResponse`
     */
    getMods: (query: GetModsParams, params: RequestParams = {}) =>
      this.request<GetModsData, GetModsError>({
        path: `/bo/mods`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name GetModsSettings
     * @summary List of live wallpaper settings with current values
     * @request GET:/bo/mods/live-wallpaper-setting
     * @secure
     * @response `200` `GetModsSettingsData`
     * @response `403` `GetModsLiveWallpaperSetting403ErrorResponse`
     */
    getModsSettings: (params: RequestParams = {}) =>
      this.request<GetModsSettingsData, GetModsSettingsError>({
        path: `/bo/mods/live-wallpaper-setting`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModUserFeedbackController
     * @name GetModUserFeedback
     * @summary Get details of user feedback for a mod
     * @request GET:/bo/mods/{modId}/user-feedback/{feedbackId}
     * @secure
     * @response `200` `GetModUserFeedbackData`
     * @response `403` `GetModsModIdUserFeedbackFeedbackId403ErrorResponse`
     * @response `404` `GetModsModIdUserFeedbackFeedbackId404ErrorResponse`
     */
    getModUserFeedback: (modId: string, feedbackId: string, params: RequestParams = {}) =>
      this.request<GetModUserFeedbackData, GetModUserFeedbackError>({
        path: `/bo/mods/${modId}/user-feedback/${feedbackId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModUserFeedbackController
     * @name GetModUserFeedbacks
     * @summary Get all user feedback for a mod
     * @request GET:/bo/mods/{modId}/user-feedback
     * @secure
     * @response `200` `GetModUserFeedbacksData`
     * @response `400` `GetModsModIdUserFeedback400ErrorResponse`
     * @response `403` `GetModsModIdUserFeedback403ErrorResponse`
     * @response `404` `GetModsModIdUserFeedback404ErrorResponse`
     */
    getModUserFeedbacks: (
      { modId, ...query }: GetModUserFeedbacksParams,
      params: RequestParams = {},
    ) =>
      this.request<GetModUserFeedbacksData, GetModUserFeedbacksError>({
        path: `/bo/mods/${modId}/user-feedback`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name InvalidateSupplementalDataCache
     * @summary Invalidate the supplemental data cache for one, multiple or all mods
     * @request POST:/bo/mods/invalidate-supplemental-data-cache
     * @secure
     * @response `200` `InvalidateSupplementalDataCacheData`
     * @response `403` `PostModsInvalidateSupplementalDataCache403ErrorResponse`
     * @response `404` `PostModsInvalidateSupplementalDataCache404ErrorResponse`
     */
    invalidateSupplementalDataCache: (
      query: InvalidateSupplementalDataCacheParams,
      params: RequestParams = {},
    ) =>
      this.request<InvalidateSupplementalDataCacheData, InvalidateSupplementalDataCacheError>({
        path: `/bo/mods/invalidate-supplemental-data-cache`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModReleasesController
     * @name ListReleases
     * @summary List past and current releases on release track
     * @request GET:/bo/mods/{modId}/tracks/{trackId}/releases
     * @secure
     * @response `200` `ListReleasesData`
     * @response `400` `GetModsModIdTracksTrackIdReleases400ErrorResponse`
     * @response `403` `GetModsModIdTracksTrackIdReleases403ErrorResponse`
     * @response `404` `GetModsModIdTracksTrackIdReleases404ErrorResponse`
     */
    listReleases: ({ modId, trackId, ...query }: ListReleasesParams, params: RequestParams = {}) =>
      this.request<ListReleasesData, ListReleasesError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/releases`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ReviewController
     * @name PostModReviewV2
     * @summary Post mod review
     * @request POST:/bo/v2/mods/{modId}/reviews
     * @secure
     * @response `200` `PostModReviewV2Data`
     * @response `400` `PostV2ModsModIdReviews400ErrorResponse`
     * @response `403` `PostV2ModsModIdReviews403ErrorResponse`
     * @response `404` `PostV2ModsModIdReviews404ErrorResponse`
     */
    postModReviewV2: (modId: string, data: CreateReviewRequest, params: RequestParams = {}) =>
      this.request<PostModReviewV2Data, PostModReviewV2Error>({
        path: `/bo/v2/mods/${modId}/reviews`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name RecordContents
     * @summary Record mod release contents of already uploaded (current) releases
     * @request POST:/bo/mods/record-release-contents
     * @secure
     * @response `200` `RecordContentsData`
     * @response `400` `PostModsRecordReleaseContents400ErrorResponse`
     * @response `403` `PostModsRecordReleaseContents403ErrorResponse`
     */
    recordContents: (query: RecordContentsParams, params: RequestParams = {}) =>
      this.request<RecordContentsData, RecordContentsError>({
        path: `/bo/mods/record-release-contents`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name ReprocessCurrentReleases
     * @summary Schedule reprocessing of current releases
     * @request POST:/bo/mods/reprocess-current-releases
     * @secure
     * @response `200` `ReprocessCurrentReleasesResult`
     * @response `403` `PostModsReprocessCurrentReleases403ErrorResponse`
     */
    reprocessCurrentReleases: (
      query: ReprocessCurrentReleasesParams2,
      params: RequestParams = {},
    ) =>
      this.request<ReprocessCurrentReleasesResult, ReprocessCurrentReleasesFail>({
        path: `/bo/mods/reprocess-current-releases`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name ReprocessMods
     * @summary Schedule reprocessing of all mods
     * @request POST:/bo/mods/reprocess
     * @secure
     * @response `200` `ReprocessModsData`
     * @response `403` `PostModsReprocess403ErrorResponse`
     */
    reprocessMods: (query: ReprocessModsParams, params: RequestParams = {}) =>
      this.request<ReprocessModsData, ReprocessModsError>({
        path: `/bo/mods/reprocess`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModReleaseTracksController
     * @name RevalidateCurrentRelease
     * @summary Revalidate the current release on a given track
     * @request PUT:/bo/mods/{modId}/tracks/{trackId}/revalidate-current-release
     * @secure
     * @response `200` `RevalidateCurrentReleaseData`
     * @response `400` `PutModsModIdTracksTrackIdRevalidateCurrentRelease400ErrorResponse`
     * @response `403` `PutModsModIdTracksTrackIdRevalidateCurrentRelease403ErrorResponse`
     * @response `404` `PutModsModIdTracksTrackIdRevalidateCurrentRelease404ErrorResponse`
     * @response `500` `PutModsModIdTracksTrackIdRevalidateCurrentRelease500ErrorResponse`
     */
    revalidateCurrentRelease: (modId: string, trackId: string, params: RequestParams = {}) =>
      this.request<RevalidateCurrentReleaseData, RevalidateCurrentReleaseError>({
        path: `/bo/mods/${modId}/tracks/${trackId}/revalidate-current-release`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModReleasesController
     * @name RevalidateCurrentReleases
     * @summary Revalidate the current releases
     * @request PUT:/bo/mods/revalidate-current-releases
     * @secure
     * @response `200` `RevalidateCurrentReleasesData`
     * @response `400` `PutModsRevalidateCurrentReleases400ErrorResponse`
     * @response `403` `PutModsRevalidateCurrentReleases403ErrorResponse`
     */
    revalidateCurrentReleases: (
      query: RevalidateCurrentReleasesParams,
      params: RequestParams = {},
    ) =>
      this.request<RevalidateCurrentReleasesData, RevalidateCurrentReleasesError>({
        path: `/bo/mods/revalidate-current-releases`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name UpdateAudioContent
     * @summary Set media_type to AUDIO for audio mod release content
     * @request POST:/bo/mods/update-audio-mod-content
     * @secure
     * @response `200` `UpdateAudioContentData`
     * @response `403` `PostModsUpdateAudioModContent403ErrorResponse`
     */
    updateAudioContent: (params: RequestParams = {}) =>
      this.request<UpdateAudioContentData, UpdateAudioContentError>({
        path: `/bo/mods/update-audio-mod-content`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModFlavorController
     * @name UpdateDefaultModFlavorFeatures
     * @summary Update the default set of mod flavor features for all mods
     * @request PUT:/bo/mods/flavors/settings/default-features
     * @secure
     * @response `200` `UpdateDefaultModFlavorFeaturesData`
     * @response `400` `PutModsFlavorsSettingsDefaultFeatures400ErrorResponse`
     * @response `403` `PutModsFlavorsSettingsDefaultFeatures403ErrorResponse`
     */
    updateDefaultModFlavorFeatures: (
      query: UpdateDefaultModFlavorFeaturesParams,
      params: RequestParams = {},
    ) =>
      this.request<UpdateDefaultModFlavorFeaturesData, UpdateDefaultModFlavorFeaturesError>({
        path: `/bo/mods/flavors/settings/default-features`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name UpdateMod
     * @summary Update a mod
     * @request PATCH:/bo/mods/{modId}
     * @secure
     * @response `200` `UpdateModData`
     * @response `400` `PatchModsModId400ErrorResponse`
     * @response `403` `PatchModsModId403ErrorResponse`
     * @response `404` `PatchModsModId404ErrorResponse`
     */
    updateMod: (modId: string, data: UpdateModRequest, params: RequestParams = {}) =>
      this.request<UpdateModData, UpdateModError>({
        path: `/bo/mods/${modId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModsController
     * @name UpdateTrack
     * @summary Edit (publish / un-publish) mod release track
     * @request PATCH:/bo/mods/{modId}/tracks/{trackId}
     * @secure
     * @response `200` `UpdateTrackData`
     * @response `400` `PatchModsModIdTracksTrackId400ErrorResponse`
     * @response `403` `PatchModsModIdTracksTrackId403ErrorResponse`
     * @response `404` `PatchModsModIdTracksTrackId404ErrorResponse`
     */
    updateTrack: (
      modId: string,
      trackId: string,
      data: UpdateModReleaseTrackRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateTrackData, UpdateTrackError>({
        path: `/bo/mods/${modId}/tracks/${trackId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModCoverController
     * @name UploadModCover
     * @summary Upload cover image for mod
     * @request PUT:/bo/mods/{modId}/cover
     * @secure
     * @response `200` `UploadModCoverData`
     * @response `400` `PutModsModIdCover400ErrorResponse`
     * @response `403` `PutModsModIdCover403ErrorResponse`
     * @response `404` `PutModsModIdCover404ErrorResponse`
     * @response `500` `PutModsModIdCover500ErrorResponse`
     */
    uploadModCover: (
      { modId, ...query }: UploadModCoverParams,
      data: UploadModCoverPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadModCoverData, UploadModCoverError>({
        path: `/bo/mods/${modId}/cover`,
        method: 'PUT',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mods, ModGraphicsController
     * @name UploadModGraphics
     * @summary Upload graphics (images/videos) for mod
     * @request PUT:/bo/mods/{modId}/graphics
     * @secure
     * @response `201` `UploadModGraphicsData`
     * @response `400` `PutModsModIdGraphics400ErrorResponse`
     * @response `403` `PutModsModIdGraphics403ErrorResponse`
     * @response `404` `PutModsModIdGraphics404ErrorResponse`
     * @response `500` `PutModsModIdGraphics500ErrorResponse`
     */
    uploadModGraphics: (
      modId: string,
      data: UploadModGraphicsPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadModGraphicsData, UploadModGraphicsError>({
        path: `/bo/mods/${modId}/graphics`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  multiplayer = {
    /**
     * No description
     *
     * @tags multiplayer, MultiplayerGuestController
     * @name DeleteMultiplayerGuestAvatar
     * @summary Delete a multiplayer guest avatar
     * @request DELETE:/bo/multiplayer/guest/avatars/{multiplayerGuestAvatarId}
     * @secure
     * @response `200` `DeleteMultiplayerGuestAvatarData`
     * @response `403` `DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId403ErrorResponse`
     * @response `404` `DeleteMultiplayerGuestAvatarsMultiplayerGuestAvatarId404ErrorResponse`
     */
    deleteMultiplayerGuestAvatar: (multiplayerGuestAvatarId: string, params: RequestParams = {}) =>
      this.request<DeleteMultiplayerGuestAvatarData, DeleteMultiplayerGuestAvatarError>({
        path: `/bo/multiplayer/guest/avatars/${multiplayerGuestAvatarId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags multiplayer, MultiplayerGuestController
     * @name ListMultiplayerGuestAvatars
     * @summary Get list of all multiplayer guest avatars
     * @request GET:/bo/multiplayer/guest/avatars
     * @secure
     * @response `200` `ListMultiplayerGuestAvatarsData`
     * @response `400` `GetMultiplayerGuestAvatars400ErrorResponse`
     * @response `403` `GetMultiplayerGuestAvatars403ErrorResponse`
     */
    listMultiplayerGuestAvatars: (
      query: ListMultiplayerGuestAvatarsParams,
      params: RequestParams = {},
    ) =>
      this.request<ListMultiplayerGuestAvatarsData, ListMultiplayerGuestAvatarsError>({
        path: `/bo/multiplayer/guest/avatars`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags multiplayer, MultiplayerController
     * @name ListRelays
     * @summary Get list of all multiplayer relays
     * @request GET:/bo/multiplayer/relays
     * @secure
     * @response `200` `ListRelaysData`
     * @response `400` `GetMultiplayerRelays400ErrorResponse`
     * @response `403` `GetMultiplayerRelays403ErrorResponse`
     */
    listRelays: (query: ListRelaysParams, params: RequestParams = {}) =>
      this.request<ListRelaysData, ListRelaysError>({
        path: `/bo/multiplayer/relays`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags multiplayer, MultiplayerGuestController
     * @name UploadMultiplayerGuestAvatar
     * @summary Upload a multiplayer guest avatar
     * @request PUT:/bo/multiplayer/guest/avatars
     * @secure
     * @response `200` `UploadMultiplayerGuestAvatarData`
     * @response `400` `PutMultiplayerGuestAvatars400ErrorResponse`
     * @response `403` `PutMultiplayerGuestAvatars403ErrorResponse`
     * @response `500` `PutMultiplayerGuestAvatars500ErrorResponse`
     */
    uploadMultiplayerGuestAvatar: (
      data: UploadMultiplayerGuestAvatarPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadMultiplayerGuestAvatarData, UploadMultiplayerGuestAvatarError>({
        path: `/bo/multiplayer/guest/avatars`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  odks = {
    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name AssignToMod
     * @summary Add official distribution key to mod
     * @request PUT:/bo/odks/{keyId}/mods/{modId}
     * @secure
     * @response `200` `AssignToModData`
     * @response `400` `PutOdksKeyIdModsModId400ErrorResponse`
     * @response `403` `PutOdksKeyIdModsModId403ErrorResponse`
     * @response `404` `PutOdksKeyIdModsModId404ErrorResponse`
     */
    assignToMod: (keyId: string, modId: string, params: RequestParams = {}) =>
      this.request<AssignToModData, AssignToModError>({
        path: `/bo/odks/${keyId}/mods/${modId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name AssignToStudio
     * @summary Assign an official distribution key to a studio.
     * @request PUT:/bo/odks/{keyId}/studios/{studioId}
     * @secure
     * @response `200` `AssignToStudioData`
     * @response `400` `PutOdksKeyIdStudiosStudioId400ErrorResponse`
     * @response `403` `PutOdksKeyIdStudiosStudioId403ErrorResponse`
     * @response `404` `PutOdksKeyIdStudiosStudioId404ErrorResponse`
     */
    assignToStudio: (keyId: string, studioId: string, params: RequestParams = {}) =>
      this.request<AssignToStudioData, AssignToStudioError>({
        path: `/bo/odks/${keyId}/studios/${studioId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name CreateOfficialDistributionKey
     * @summary Create an official distribution key.
     * @request POST:/bo/odks
     * @secure
     * @response `200` `CreateOfficialDistributionKeyData`
     * @response `400` `PostOdks400ErrorResponse`
     * @response `403` `PostOdks403ErrorResponse`
     */
    createOfficialDistributionKey: (
      data: CreateOfficialDistributionKeyRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateOfficialDistributionKeyData, CreateOfficialDistributionKeyError>({
        path: `/bo/odks`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name DeleteOfficialDistributionKey
     * @summary Delete an official distribution key.
     * @request DELETE:/bo/odks/{keyId}
     * @secure
     * @response `200` `DeleteOfficialDistributionKeyData`
     * @response `400` `DeleteOdksKeyId400ErrorResponse`
     * @response `403` `DeleteOdksKeyId403ErrorResponse`
     * @response `404` `DeleteOdksKeyId404ErrorResponse`
     */
    deleteOfficialDistributionKey: (keyId: string, params: RequestParams = {}) =>
      this.request<DeleteOfficialDistributionKeyData, DeleteOfficialDistributionKeyError>({
        path: `/bo/odks/${keyId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name GetOfficialDistributionKey
     * @summary Get details on official distribution key.
     * @request GET:/bo/odks/{keyId}
     * @secure
     * @response `200` `GetOfficialDistributionKeyData`
     * @response `403` `GetOdksKeyId403ErrorResponse`
     * @response `404` `GetOdksKeyId404ErrorResponse`
     */
    getOfficialDistributionKey: (keyId: string, params: RequestParams = {}) =>
      this.request<GetOfficialDistributionKeyData, GetOfficialDistributionKeyError>({
        path: `/bo/odks/${keyId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name ListModsForKey
     * @summary Get a paginated list of all mods the ODK is assigned to.
     * @request GET:/bo/odks/{keyId}/mods
     * @secure
     * @response `200` `ListModsForKeyData`
     * @response `400` `GetOdksKeyIdMods400ErrorResponse`
     * @response `403` `GetOdksKeyIdMods403ErrorResponse`
     * @response `404` `GetOdksKeyIdMods404ErrorResponse`
     */
    listModsForKey: ({ keyId, ...query }: ListModsForKeyParams, params: RequestParams = {}) =>
      this.request<ListModsForKeyData, ListModsForKeyError>({
        path: `/bo/odks/${keyId}/mods`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name ListOfficialDistributionKeys
     * @summary Get a paginated list of all official distribution keys.
     * @request GET:/bo/odks
     * @secure
     * @response `200` `ListOfficialDistributionKeysData`
     * @response `400` `GetOdks400ErrorResponse`
     * @response `403` `GetOdks403ErrorResponse`
     */
    listOfficialDistributionKeys: (
      query: ListOfficialDistributionKeysParams,
      params: RequestParams = {},
    ) =>
      this.request<ListOfficialDistributionKeysData, ListOfficialDistributionKeysError>({
        path: `/bo/odks`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name ListStudiosForKey
     * @summary Get a paginated list of all studios the ODK is assigned to.
     * @request GET:/bo/odks/{keyId}/studios
     * @secure
     * @response `200` `ListStudiosForKeyData`
     * @response `400` `GetOdksKeyIdStudios400ErrorResponse`
     * @response `403` `GetOdksKeyIdStudios403ErrorResponse`
     * @response `404` `GetOdksKeyIdStudios404ErrorResponse`
     */
    listStudiosForKey: ({ keyId, ...query }: ListStudiosForKeyParams, params: RequestParams = {}) =>
      this.request<ListStudiosForKeyData, ListStudiosForKeyError>({
        path: `/bo/odks/${keyId}/studios`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name RemoveFromMod
     * @summary Un-assign the given ODK from a mod.
     * @request DELETE:/bo/odks/{keyId}/mods/{modId}
     * @secure
     * @response `200` `RemoveFromModData`
     * @response `400` `DeleteOdksKeyIdModsModId400ErrorResponse`
     * @response `403` `DeleteOdksKeyIdModsModId403ErrorResponse`
     * @response `404` `DeleteOdksKeyIdModsModId404ErrorResponse`
     */
    removeFromMod: (keyId: string, modId: string, params: RequestParams = {}) =>
      this.request<RemoveFromModData, RemoveFromModError>({
        path: `/bo/odks/${keyId}/mods/${modId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name RemoveFromStudio
     * @summary Un-assign the given ODK from a studio.
     * @request DELETE:/bo/odks/{keyId}/studios/{studioId}
     * @secure
     * @response `200` `RemoveFromStudioData`
     * @response `400` `DeleteOdksKeyIdStudiosStudioId400ErrorResponse`
     * @response `403` `DeleteOdksKeyIdStudiosStudioId403ErrorResponse`
     * @response `404` `DeleteOdksKeyIdStudiosStudioId404ErrorResponse`
     */
    removeFromStudio: (keyId: string, studioId: string, params: RequestParams = {}) =>
      this.request<RemoveFromStudioData, RemoveFromStudioError>({
        path: `/bo/odks/${keyId}/studios/${studioId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags odks, OfficialDistributionKeyController
     * @name UpdateOfficialDistributionKey
     * @summary Update official distribution key.
     * @request PATCH:/bo/odks/{keyId}
     * @secure
     * @response `200` `UpdateOfficialDistributionKeyData`
     * @response `400` `PatchOdksKeyId400ErrorResponse`
     * @response `403` `PatchOdksKeyId403ErrorResponse`
     * @response `404` `PatchOdksKeyId404ErrorResponse`
     */
    updateOfficialDistributionKey: (
      keyId: string,
      data: UpdateOfficialDistributionKeyRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateOfficialDistributionKeyData, UpdateOfficialDistributionKeyError>({
        path: `/bo/odks/${keyId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  offerPartners = {
    /**
     * No description
     *
     * @tags offer-partners, OfferPartnersController
     * @name CreatePartner
     * @summary Create partner
     * @request POST:/bo/offer-partners
     * @secure
     * @response `200` `CreatePartnerData`
     * @response `400` `PostOfferPartners400ErrorResponse`
     * @response `403` `PostOfferPartners403ErrorResponse`
     */
    createPartner: (data: CreatePartnerRequest, params: RequestParams = {}) =>
      this.request<CreatePartnerData, CreatePartnerError>({
        path: `/bo/offer-partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offer-partners, OfferPartnersController
     * @name ListPartners
     * @summary List partners
     * @request GET:/bo/offer-partners
     * @secure
     * @response `200` `ListPartnersData`
     * @response `400` `GetOfferPartners400ErrorResponse`
     * @response `403` `GetOfferPartners403ErrorResponse`
     */
    listPartners: (query: ListPartnersParams, params: RequestParams = {}) =>
      this.request<ListPartnersData, ListPartnersError>({
        path: `/bo/offer-partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offer-partners, OfferPartnersController
     * @name UpdatePartner
     * @summary Update partner
     * @request PATCH:/bo/offer-partners/{partnerId}
     * @secure
     * @response `200` `UpdatePartnerData`
     * @response `400` `PatchOfferPartnersPartnerId400ErrorResponse`
     * @response `403` `PatchOfferPartnersPartnerId403ErrorResponse`
     * @response `404` `PatchOfferPartnersPartnerId404ErrorResponse`
     */
    updatePartner: (partnerId: string, data: UpdatePartnerRequest, params: RequestParams = {}) =>
      this.request<UpdatePartnerData, UpdatePartnerError>({
        path: `/bo/offer-partners/${partnerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  offers = {
    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name BulkUpdateCodes
     * @summary Bulk update codes
     * @request PATCH:/bo/offers/{offerId}/codes
     * @secure
     * @response `200` `BulkUpdateCodesData`
     * @response `400` `PatchOffersOfferIdCodes400ErrorResponse`
     * @response `403` `PatchOffersOfferIdCodes403ErrorResponse`
     * @response `404` `PatchOffersOfferIdCodes404ErrorResponse`
     */
    bulkUpdateCodes: (
      { offerId, ...query }: BulkUpdateCodesParams,
      data: UpdateOfferCodesRequest,
      params: RequestParams = {},
    ) =>
      this.request<BulkUpdateCodesData, BulkUpdateCodesError>({
        path: `/bo/offers/${offerId}/codes`,
        method: 'PATCH',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name ClaimCode
     * @summary Manually claim code
     * @request POST:/bo/offers/{offerId}/codes/{codeId}/claim
     * @secure
     * @response `200` `ClaimCodeData`
     * @response `400` `PostOffersOfferIdCodesCodeIdClaim400ErrorResponse`
     * @response `403` `PostOffersOfferIdCodesCodeIdClaim403ErrorResponse`
     * @response `404` `PostOffersOfferIdCodesCodeIdClaim404ErrorResponse`
     * @response `500` `PostOffersOfferIdCodesCodeIdClaim500ErrorResponse`
     */
    claimCode: (
      offerId: string,
      codeId: string,
      data: ClaimOfferCodeRequest,
      params: RequestParams = {},
    ) =>
      this.request<ClaimCodeData, ClaimCodeError>({
        path: `/bo/offers/${offerId}/codes/${codeId}/claim`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name ClaimCode2
     * @summary Unclaim code if currently claimed
     * @request POST:/bo/offers/{offerId}/codes/{codeId}/unclaim
     * @originalName claimCode
     * @duplicate
     * @secure
     * @response `200` `ClaimCode2Data`
     * @response `403` `PostOffersOfferIdCodesCodeIdUnclaim403ErrorResponse`
     * @response `404` `PostOffersOfferIdCodesCodeIdUnclaim404ErrorResponse`
     */
    claimCode2: (offerId: string, codeId: string, params: RequestParams = {}) =>
      this.request<ClaimCode2Data, ClaimCode2Error>({
        path: `/bo/offers/${offerId}/codes/${codeId}/unclaim`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name Create
     * @summary Create error page
     * @request POST:/bo/offers/{offerId}/error-pages
     * @secure
     * @response `200` `CreateResult`
     * @response `400` `PostOffersOfferIdErrorPages400ErrorResponse`
     * @response `403` `PostOffersOfferIdErrorPages403ErrorResponse`
     * @response `404` `PostOffersOfferIdErrorPages404ErrorResponse`
     */
    create: (offerId: string, data: CreateOfferErrorPageRequest, params: RequestParams = {}) =>
      this.request<CreateResult, CreateFail>({
        path: `/bo/offers/${offerId}/error-pages`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OffersController
     * @name CreateOffer
     * @summary Create offer
     * @request POST:/bo/offers
     * @secure
     * @response `200` `CreateOfferData`
     * @response `400` `PostOffers400ErrorResponse`
     * @response `403` `PostOffers403ErrorResponse`
     * @response `404` `PostOffers404ErrorResponse`
     */
    createOffer: (data: CreateOfferRequest, params: RequestParams = {}) =>
      this.request<CreateOfferData, CreateOfferError>({
        path: `/bo/offers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name Download
     * @summary Download current error page content
     * @request GET:/bo/offers/{offerId}/error-pages/{errorPageId}/content
     * @secure
     * @response `200` `DownloadData`
     * @response `400` `GetOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse`
     * @response `403` `GetOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse`
     * @response `404` `GetOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse`
     * @response `500` `GetOffersOfferIdErrorPagesErrorPageIdContent500ErrorResponse`
     */
    download: (offerId: string, errorPageId: string, params: RequestParams = {}) =>
      this.request<DownloadData, DownloadError>({
        path: `/bo/offers/${offerId}/error-pages/${errorPageId}/content`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name Get
     * @summary Get error page details
     * @request GET:/bo/offers/{offerId}/error-pages/{errorPageId}
     * @secure
     * @response `200` `GetData`
     * @response `403` `GetOffersOfferIdErrorPagesErrorPageId403ErrorResponse`
     * @response `404` `GetOffersOfferIdErrorPagesErrorPageId404ErrorResponse`
     */
    get: (offerId: string, errorPageId: string, params: RequestParams = {}) =>
      this.request<GetData, GetError>({
        path: `/bo/offers/${offerId}/error-pages/${errorPageId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name GetCodeDetails
     * @summary Get code details
     * @request GET:/bo/offers/{offerId}/codes/{codeId}
     * @secure
     * @response `200` `GetCodeDetailsData`
     * @response `403` `GetOffersOfferIdCodesCodeId403ErrorResponse`
     * @response `404` `GetOffersOfferIdCodesCodeId404ErrorResponse`
     */
    getCodeDetails: (
      { offerId, codeId, ...query }: GetCodeDetailsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetCodeDetailsData, GetCodeDetailsError>({
        path: `/bo/offers/${offerId}/codes/${codeId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OffersController
     * @name GetOfferDetails
     * @summary Get offer details
     * @request GET:/bo/offers/{offerId}
     * @secure
     * @response `200` `GetOfferDetailsData`
     * @response `403` `GetOffersOfferId403ErrorResponse`
     * @response `404` `GetOffersOfferId404ErrorResponse`
     */
    getOfferDetails: (offerId: string, params: RequestParams = {}) =>
      this.request<GetOfferDetailsData, GetOfferDetailsError>({
        path: `/bo/offers/${offerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OffersController
     * @name ImportCodes
     * @summary Import codes from uploaded CSV file
     * @request POST:/bo/offers/{offerId}/import-codes
     * @secure
     * @response `200` `ImportCodesData`
     * @response `400` `PostOffersOfferIdImportCodes400ErrorResponse`
     * @response `403` `PostOffersOfferIdImportCodes403ErrorResponse`
     * @response `404` `PostOffersOfferIdImportCodes404ErrorResponse`
     * @response `500` `PostOffersOfferIdImportCodes500ErrorResponse`
     */
    importCodes: (
      { offerId, ...query }: ImportCodesParams,
      data: ImportCodesPayload,
      params: RequestParams = {},
    ) =>
      this.request<ImportCodesData, ImportCodesError>({
        path: `/bo/offers/${offerId}/import-codes`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name List
     * @summary List error pages
     * @request GET:/bo/offers/{offerId}/error-pages
     * @secure
     * @response `200` `ListOutput`
     * @response `400` `GetOffersOfferIdErrorPages400ErrorResponse`
     * @response `403` `GetOffersOfferIdErrorPages403ErrorResponse`
     * @response `404` `GetOffersOfferIdErrorPages404ErrorResponse`
     */
    list: ({ offerId, ...query }: ListParams4, params: RequestParams = {}) =>
      this.request<ListOutput, ListFails>({
        path: `/bo/offers/${offerId}/error-pages`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name ListCodes
     * @summary List codes
     * @request GET:/bo/offers/{offerId}/codes
     * @secure
     * @response `200` `ListCodesData`
     * @response `400` `GetOffersOfferIdCodes400ErrorResponse`
     * @response `403` `GetOffersOfferIdCodes403ErrorResponse`
     * @response `404` `GetOffersOfferIdCodes404ErrorResponse`
     */
    listCodes: ({ offerId, ...query }: ListCodesParams, params: RequestParams = {}) =>
      this.request<ListCodesData, ListCodesError>({
        path: `/bo/offers/${offerId}/codes`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OffersController
     * @name ListOffers
     * @summary List offers
     * @request GET:/bo/offers
     * @secure
     * @response `200` `ListOffersData`
     * @response `400` `GetOffers400ErrorResponse`
     * @response `403` `GetOffers403ErrorResponse`
     * @response `404` `GetOffers404ErrorResponse`
     */
    listOffers: (query: ListOffersParams, params: RequestParams = {}) =>
      this.request<ListOffersData, ListOffersError>({
        path: `/bo/offers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name SetRedeemed
     * @summary Upload report with list of codes redeemed
     * @request POST:/bo/offers/{offerId}/codes/redeem-report
     * @secure
     * @response `200` `SetRedeemedData`
     * @response `400` `PostOffersOfferIdCodesRedeemReport400ErrorResponse`
     * @response `403` `PostOffersOfferIdCodesRedeemReport403ErrorResponse`
     * @response `404` `PostOffersOfferIdCodesRedeemReport404ErrorResponse`
     * @response `500` `PostOffersOfferIdCodesRedeemReport500ErrorResponse`
     */
    setRedeemed: (offerId: string, data: SetRedeemedPayload, params: RequestParams = {}) =>
      this.request<SetRedeemedData, SetRedeemedError>({
        path: `/bo/offers/${offerId}/codes/redeem-report`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name Update
     * @summary Update error page
     * @request PATCH:/bo/offers/{offerId}/error-pages/{errorPageId}
     * @secure
     * @response `200` `UpdateData`
     * @response `400` `PatchOffersOfferIdErrorPagesErrorPageId400ErrorResponse`
     * @response `403` `PatchOffersOfferIdErrorPagesErrorPageId403ErrorResponse`
     * @response `404` `PatchOffersOfferIdErrorPagesErrorPageId404ErrorResponse`
     */
    update: (
      offerId: string,
      errorPageId: string,
      data: UpdateOfferErrorPageRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateData, UpdateError>({
        path: `/bo/offers/${offerId}/error-pages/${errorPageId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferCodesController
     * @name UpdateCode
     * @summary Update code
     * @request PATCH:/bo/offers/{offerId}/codes/{codeId}
     * @secure
     * @response `200` `UpdateCodeData`
     * @response `400` `PatchOffersOfferIdCodesCodeId400ErrorResponse`
     * @response `403` `PatchOffersOfferIdCodesCodeId403ErrorResponse`
     * @response `404` `PatchOffersOfferIdCodesCodeId404ErrorResponse`
     */
    updateCode: (
      offerId: string,
      codeId: string,
      data: UpdateOfferCodeRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateCodeData, UpdateCodeError>({
        path: `/bo/offers/${offerId}/codes/${codeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OffersController
     * @name UpdateOffer
     * @summary Update offer
     * @request PATCH:/bo/offers/{offerId}
     * @secure
     * @response `200` `UpdateOfferData`
     * @response `400` `PatchOffersOfferId400ErrorResponse`
     * @response `403` `PatchOffersOfferId403ErrorResponse`
     * @response `404` `PatchOffersOfferId404ErrorResponse`
     */
    updateOffer: (offerId: string, data: UpdateOfferRequest, params: RequestParams = {}) =>
      this.request<UpdateOfferData, UpdateOfferError>({
        path: `/bo/offers/${offerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags offers, OfferErrorPagesController
     * @name Upload
     * @summary Upload new error page content
     * @request PUT:/bo/offers/{offerId}/error-pages/{errorPageId}/content
     * @secure
     * @response `200` `UploadResult`
     * @response `400` `PutOffersOfferIdErrorPagesErrorPageIdContent400ErrorResponse`
     * @response `403` `PutOffersOfferIdErrorPagesErrorPageIdContent403ErrorResponse`
     * @response `404` `PutOffersOfferIdErrorPagesErrorPageIdContent404ErrorResponse`
     */
    upload: (offerId: string, errorPageId: string, data: UploadBody, params: RequestParams = {}) =>
      this.request<UploadResult, UploadFail>({
        path: `/bo/offers/${offerId}/error-pages/${errorPageId}/content`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  partners = {
    /**
     * No description
     *
     * @tags partners, PartnersController
     * @name CreatePartner
     * @summary Create partner
     * @request POST:/bo/partners
     * @secure
     * @response `200` `CreatePartnerResult`
     * @response `400` `PostPartners400ErrorResponse`
     * @response `403` `PostPartners403ErrorResponse`
     */
    createPartner: (data: CreatePartnerRequest, params: RequestParams = {}) =>
      this.request<CreatePartnerResult, CreatePartnerFail>({
        path: `/bo/partners`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags partners, PartnersController
     * @name ListPartners
     * @summary List partners
     * @request GET:/bo/partners
     * @secure
     * @response `200` `ListPartnersResult`
     * @response `400` `GetPartners400ErrorResponse`
     * @response `403` `GetPartners403ErrorResponse`
     */
    listPartners: (query: ListPartnersParams2, params: RequestParams = {}) =>
      this.request<ListPartnersResult, ListPartnersFail>({
        path: `/bo/partners`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags partners, PartnersController
     * @name UpdatePartner
     * @summary Update partner
     * @request PATCH:/bo/partners/{partnerId}
     * @secure
     * @response `200` `UpdatePartnerResult`
     * @response `400` `PatchPartnersPartnerId400ErrorResponse`
     * @response `403` `PatchPartnersPartnerId403ErrorResponse`
     * @response `404` `PatchPartnersPartnerId404ErrorResponse`
     */
    updatePartner: (partnerId: string, data: UpdatePartnerRequest, params: RequestParams = {}) =>
      this.request<UpdatePartnerResult, UpdatePartnerFail>({
        path: `/bo/partners/${partnerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  payments = {
    /**
     * No description
     *
     * @tags payments, DisputesController
     * @name AddComment
     * @summary Add dispute comment
     * @request POST:/bo/payments/{paymentId}/disputes/{disputeId}/comment
     * @secure
     * @response `200` `AddCommentData`
     * @response `400` `PostPaymentsPaymentIdDisputesDisputeIdComment400ErrorResponse`
     * @response `403` `PostPaymentsPaymentIdDisputesDisputeIdComment403ErrorResponse`
     * @response `404` `PostPaymentsPaymentIdDisputesDisputeIdComment404ErrorResponse`
     */
    addComment: (
      paymentId: string,
      disputeId: string,
      data: DisputeAddCommentRequest,
      params: RequestParams = {},
    ) =>
      this.request<AddCommentData, AddCommentError>({
        path: `/bo/payments/${paymentId}/disputes/${disputeId}/comment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments, DisputesController
     * @name AddDispute
     * @summary Add dispute for payment, by default can add only unresolved disputes
     * @request POST:/bo/payments/{paymentId}/disputes
     * @secure
     * @response `200` `AddDisputeData`
     * @response `400` `PostPaymentsPaymentIdDisputes400ErrorResponse`
     * @response `403` `PostPaymentsPaymentIdDisputes403ErrorResponse`
     * @response `404` `PostPaymentsPaymentIdDisputes404ErrorResponse`
     */
    addDispute: (paymentId: string, data: DisputeAddRequest, params: RequestParams = {}) =>
      this.request<AddDisputeData, AddDisputeError>({
        path: `/bo/payments/${paymentId}/disputes`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments, GameItemsController
     * @name AddNewPaymentFieldValues
     * @summary Add values for the newly added payment fields
     * @request POST:/bo/payments/add-new-field-values
     * @secure
     * @response `200` `AddNewPaymentFieldValuesData`
     * @response `400` `PostPaymentsAddNewFieldValues400ErrorResponse`
     * @response `403` `PostPaymentsAddNewFieldValues403ErrorResponse`
     */
    addNewPaymentFieldValues: (query: AddNewPaymentFieldValuesParams, params: RequestParams = {}) =>
      this.request<AddNewPaymentFieldValuesData, AddNewPaymentFieldValuesError>({
        path: `/bo/payments/add-new-field-values`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments, DisputesController
     * @name ResolveDispute
     * @summary Resolve dispute for payment
     * @request PATCH:/bo/payments/{paymentId}/disputes/{disputeId}/resolve
     * @secure
     * @response `200` `ResolveDisputeData`
     * @response `400` `PatchPaymentsPaymentIdDisputesDisputeIdResolve400ErrorResponse`
     * @response `403` `PatchPaymentsPaymentIdDisputesDisputeIdResolve403ErrorResponse`
     * @response `404` `PatchPaymentsPaymentIdDisputesDisputeIdResolve404ErrorResponse`
     */
    resolveDispute: (
      paymentId: string,
      disputeId: string,
      data: DisputeResolveRequest,
      params: RequestParams = {},
    ) =>
      this.request<ResolveDisputeData, ResolveDisputeError>({
        path: `/bo/payments/${paymentId}/disputes/${disputeId}/resolve`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments, DisputesController
     * @name UpdateDispute
     * @summary Update dispute for payment
     * @request PATCH:/bo/payments/{paymentId}/disputes/{disputeId}
     * @secure
     * @response `200` `UpdateDisputeData`
     * @response `400` `PatchPaymentsPaymentIdDisputesDisputeId400ErrorResponse`
     * @response `403` `PatchPaymentsPaymentIdDisputesDisputeId403ErrorResponse`
     * @response `404` `PatchPaymentsPaymentIdDisputesDisputeId404ErrorResponse`
     */
    updateDispute: (
      paymentId: string,
      disputeId: string,
      data: DisputeEditRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateDisputeData, UpdateDisputeError>({
        path: `/bo/payments/${paymentId}/disputes/${disputeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  profile = {
    /**
     * No description
     *
     * @tags profile, ProfileController
     * @name GetProfile
     * @summary Get current back-office user's profile
     * @request GET:/bo/profile
     * @secure
     * @response `200` `GetProfileData`
     * @response `403` `GetProfile403ErrorResponse`
     */
    getProfile: (params: RequestParams = {}) =>
      this.request<GetProfileData, GetProfileError>({
        path: `/bo/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  promotedChallenges = {
    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name CreatePromotedChallenge
     * @summary Create (unpublished) promoted challenge
     * @request POST:/bo/promoted-challenges
     * @secure
     * @response `200` `CreatePromotedChallengeData`
     * @response `400` `PostPromotedChallenges400ErrorResponse`
     * @response `403` `PostPromotedChallenges403ErrorResponse`
     */
    createPromotedChallenge: (data: CreatePromotedChallengeRequest, params: RequestParams = {}) =>
      this.request<CreatePromotedChallengeData, CreatePromotedChallengeError>({
        path: `/bo/promoted-challenges`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name DeletePromotedChallenge
     * @summary Delete promoted challenge
     * @request DELETE:/bo/promoted-challenges/{promotedChallengeId}
     * @secure
     * @response `200` `DeletePromotedChallengeData`
     * @response `400` `DeletePromotedChallengesPromotedChallengeId400ErrorResponse`
     * @response `403` `DeletePromotedChallengesPromotedChallengeId403ErrorResponse`
     * @response `404` `DeletePromotedChallengesPromotedChallengeId404ErrorResponse`
     */
    deletePromotedChallenge: (promotedChallengeId: string, params: RequestParams = {}) =>
      this.request<DeletePromotedChallengeData, DeletePromotedChallengeError>({
        path: `/bo/promoted-challenges/${promotedChallengeId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name GetPromotedChallenge
     * @summary Get details of promoted challenge
     * @request GET:/bo/promoted-challenges/{promotedChallengeId}
     * @secure
     * @response `200` `GetPromotedChallengeData`
     * @response `403` `GetPromotedChallengesPromotedChallengeId403ErrorResponse`
     * @response `404` `GetPromotedChallengesPromotedChallengeId404ErrorResponse`
     */
    getPromotedChallenge: (promotedChallengeId: string, params: RequestParams = {}) =>
      this.request<GetPromotedChallengeData, GetPromotedChallengeError>({
        path: `/bo/promoted-challenges/${promotedChallengeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name GetPromotedChallenges
     * @summary Get list of promoted challenges
     * @request GET:/bo/promoted-challenges
     * @secure
     * @response `200` `GetPromotedChallengesData`
     * @response `403` `GetPromotedChallenges403ErrorResponse`
     * @response `404` `GetPromotedChallenges404ErrorResponse`
     */
    getPromotedChallenges: (query: GetPromotedChallengesParams, params: RequestParams = {}) =>
      this.request<GetPromotedChallengesData, GetPromotedChallengesError>({
        path: `/bo/promoted-challenges`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name GetScoreVerifications
     * @summary Get score verifications for top challenge scores
     * @request GET:/bo/promoted-challenges/{promotedChallengeId}/score-verifications
     * @secure
     * @response `200` `GetScoreVerificationsResult`
     * @response `400` `GetPromotedChallengesPromotedChallengeIdScoreVerifications400ErrorResponse`
     * @response `403` `GetPromotedChallengesPromotedChallengeIdScoreVerifications403ErrorResponse`
     * @response `404` `GetPromotedChallengesPromotedChallengeIdScoreVerifications404ErrorResponse`
     */
    getScoreVerifications: (
      { promotedChallengeId, ...query }: GetScoreVerificationsParams2,
      params: RequestParams = {},
    ) =>
      this.request<GetScoreVerificationsResult, GetScoreVerificationsFail>({
        path: `/bo/promoted-challenges/${promotedChallengeId}/score-verifications`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name GetUserScores
     * @summary Get scores for specific promoted challenge
     * @request GET:/bo/promoted-challenges/{promotedChallengeId}/scores
     * @secure
     * @response `200` `GetUserScoresData`
     * @response `400` `GetPromotedChallengesPromotedChallengeIdScores400ErrorResponse`
     * @response `403` `GetPromotedChallengesPromotedChallengeIdScores403ErrorResponse`
     * @response `404` `GetPromotedChallengesPromotedChallengeIdScores404ErrorResponse`
     */
    getUserScores: (
      { promotedChallengeId, ...query }: GetUserScoresParams,
      params: RequestParams = {},
    ) =>
      this.request<GetUserScoresData, GetUserScoresError>({
        path: `/bo/promoted-challenges/${promotedChallengeId}/scores`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name GetUserScoreVerifications
     * @summary Get score verifications for a specific user
     * @request GET:/bo/promoted-challenges/{promotedChallengeId}/user-scores/{userId}
     * @secure
     * @response `200` `GetUserScoreVerificationsResult`
     * @response `400` `GetPromotedChallengesPromotedChallengeIdUserScoresUserId400ErrorResponse`
     * @response `403` `GetPromotedChallengesPromotedChallengeIdUserScoresUserId403ErrorResponse`
     * @response `404` `GetPromotedChallengesPromotedChallengeIdUserScoresUserId404ErrorResponse`
     */
    getUserScoreVerifications: (
      { promotedChallengeId, userId, ...query }: GetUserScoreVerificationsParams2,
      params: RequestParams = {},
    ) =>
      this.request<GetUserScoreVerificationsResult, GetUserScoreVerificationsFail>({
        path: `/bo/promoted-challenges/${promotedChallengeId}/user-scores/${userId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name SubmitScoreReview
     * @summary Submit review of challenge score
     * @request PATCH:/bo/promoted-challenges/{promotedChallengeId}/score-verifications/{scoreId}
     * @secure
     * @response `200` `SubmitScoreReviewResult`
     * @response `400` `PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId400ErrorResponse`
     * @response `403` `PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId403ErrorResponse`
     * @response `404` `PatchPromotedChallengesPromotedChallengeIdScoreVerificationsScoreId404ErrorResponse`
     */
    submitScoreReview: (
      promotedChallengeId: string,
      scoreId: string,
      data: SubmitScoreReviewRequest,
      params: RequestParams = {},
    ) =>
      this.request<SubmitScoreReviewResult, SubmitScoreReviewFail>({
        path: `/bo/promoted-challenges/${promotedChallengeId}/score-verifications/${scoreId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags promoted-challenges, PromotedChallengeController
     * @name UpdatePromotedChallenge
     * @summary Update promoted challenge
     * @request PATCH:/bo/promoted-challenges/{promotedChallengeId}
     * @secure
     * @response `200` `UpdatePromotedChallengeData`
     * @response `400` `PatchPromotedChallengesPromotedChallengeId400ErrorResponse`
     * @response `403` `PatchPromotedChallengesPromotedChallengeId403ErrorResponse`
     * @response `404` `PatchPromotedChallengesPromotedChallengeId404ErrorResponse`
     */
    updatePromotedChallenge: (
      promotedChallengeId: string,
      data: UpdatePromotedChallengeRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdatePromotedChallengeData, UpdatePromotedChallengeError>({
        path: `/bo/promoted-challenges/${promotedChallengeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  publishers = {
    /**
     * No description
     *
     * @tags publishers, PublisherController
     * @name CreatePublisher
     * @summary Create a new publisher
     * @request POST:/bo/publishers
     * @secure
     * @response `200` `CreatePublisherData`
     * @response `400` `PostPublishers400ErrorResponse`
     * @response `403` `PostPublishers403ErrorResponse`
     */
    createPublisher: (data: CreatePublisherRequest, params: RequestParams = {}) =>
      this.request<CreatePublisherData, CreatePublisherError>({
        path: `/bo/publishers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags publishers, PublisherController
     * @name DeletePublisher
     * @summary Delete an existing publisher
     * @request DELETE:/bo/publishers/{publisherId}
     * @secure
     * @response `200` `DeletePublisherData`
     * @response `400` `DeletePublishersPublisherId400ErrorResponse`
     * @response `403` `DeletePublishersPublisherId403ErrorResponse`
     * @response `404` `DeletePublishersPublisherId404ErrorResponse`
     */
    deletePublisher: (publisherId: string, params: RequestParams = {}) =>
      this.request<DeletePublisherData, DeletePublisherError>({
        path: `/bo/publishers/${publisherId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags publishers, PublisherController
     * @name GetPublisherDetails
     * @summary Details about a specific publisher
     * @request GET:/bo/publishers/{publisherId}
     * @secure
     * @response `200` `GetPublisherDetailsData`
     * @response `403` `GetPublishersPublisherId403ErrorResponse`
     * @response `404` `GetPublishersPublisherId404ErrorResponse`
     */
    getPublisherDetails: (publisherId: string, params: RequestParams = {}) =>
      this.request<GetPublisherDetailsData, GetPublisherDetailsError>({
        path: `/bo/publishers/${publisherId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags publishers, PublisherController
     * @name GetPublishers
     * @summary List of publishers
     * @request GET:/bo/publishers
     * @secure
     * @response `200` `GetPublishersData`
     * @response `400` `GetPublishers400ErrorResponse`
     * @response `403` `GetPublishers403ErrorResponse`
     */
    getPublishers: (query: GetPublishersParams, params: RequestParams = {}) =>
      this.request<GetPublishersData, GetPublishersError>({
        path: `/bo/publishers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  purchases = {
    /**
     * No description
     *
     * @tags purchases, GameItemsController
     * @name AddNewPurchaseFieldValues
     * @summary Add values for the newly added purchase fields
     * @request POST:/bo/purchases/add-new-field-values
     * @secure
     * @response `200` `AddNewPurchaseFieldValuesData`
     * @response `400` `PostPurchasesAddNewFieldValues400ErrorResponse`
     * @response `403` `PostPurchasesAddNewFieldValues403ErrorResponse`
     */
    addNewPurchaseFieldValues: (
      query: AddNewPurchaseFieldValuesParams,
      params: RequestParams = {},
    ) =>
      this.request<AddNewPurchaseFieldValuesData, AddNewPurchaseFieldValuesError>({
        path: `/bo/purchases/add-new-field-values`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchases, GameItemsController
     * @name GetGamePurchaseDetails
     * @summary Get purchases details
     * @request GET:/bo/purchases/{purchaseId}
     * @secure
     * @response `200` `GetGamePurchaseDetailsData`
     * @response `403` `GetPurchasesPurchaseId403ErrorResponse`
     * @response `404` `GetPurchasesPurchaseId404ErrorResponse`
     */
    getGamePurchaseDetails: (purchaseId: string, params: RequestParams = {}) =>
      this.request<GetGamePurchaseDetailsData, GetGamePurchaseDetailsError>({
        path: `/bo/purchases/${purchaseId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchases, GameItemsController
     * @name GetGamePurchases
     * @summary Get a purchases list
     * @request GET:/bo/purchases
     * @secure
     * @response `200` `GetGamePurchasesData`
     * @response `400` `GetPurchases400ErrorResponse`
     * @response `403` `GetPurchases403ErrorResponse`
     * @response `404` `GetPurchases404ErrorResponse`
     */
    getGamePurchases: (query: GetGamePurchasesParams, params: RequestParams = {}) =>
      this.request<GetGamePurchasesData, GetGamePurchasesError>({
        path: `/bo/purchases`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags purchases, GameItemsController
     * @name SetTippingPurchaseType
     * @summary Set 'TIPPING' purchase type for tipping purchases
     * @request POST:/bo/purchases/set-tipping-purchase-type
     * @secure
     * @response `200` `SetTippingPurchaseTypeData`
     * @response `400` `PostPurchasesSetTippingPurchaseType400ErrorResponse`
     * @response `403` `PostPurchasesSetTippingPurchaseType403ErrorResponse`
     */
    setTippingPurchaseType: (query: SetTippingPurchaseTypeParams, params: RequestParams = {}) =>
      this.request<SetTippingPurchaseTypeData, SetTippingPurchaseTypeError>({
        path: `/bo/purchases/set-tipping-purchase-type`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  questCollections = {
    /**
     * No description
     *
     * @tags quest-collections, QuestCollectionController
     * @name CreateQuestCollection
     * @summary Create a quest collection
     * @request POST:/bo/quest-collections
     * @secure
     * @response `200` `CreateQuestCollectionData`
     * @response `400` `PostQuestCollections400ErrorResponse`
     * @response `403` `PostQuestCollections403ErrorResponse`
     */
    createQuestCollection: (data: CreateQuestCollectionRequest, params: RequestParams = {}) =>
      this.request<CreateQuestCollectionData, CreateQuestCollectionError>({
        path: `/bo/quest-collections`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quest-collections, QuestCollectionController
     * @name EditQuestCollection
     * @summary Update a quest collection
     * @request PATCH:/bo/quest-collections/{collectionId}
     * @secure
     * @response `200` `EditQuestCollectionData`
     * @response `400` `PatchQuestCollectionsCollectionId400ErrorResponse`
     * @response `403` `PatchQuestCollectionsCollectionId403ErrorResponse`
     * @response `404` `PatchQuestCollectionsCollectionId404ErrorResponse`
     */
    editQuestCollection: (
      collectionId: string,
      data: QuestCollectionUpdateRequest,
      params: RequestParams = {},
    ) =>
      this.request<EditQuestCollectionData, EditQuestCollectionError>({
        path: `/bo/quest-collections/${collectionId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quest-collections, QuestCollectionController
     * @name GetQuestCollections
     * @summary Get a paginated list of quest collections
     * @request GET:/bo/quest-collections
     * @secure
     * @response `200` `GetQuestCollectionsData`
     * @response `400` `GetQuestCollections400ErrorResponse`
     * @response `403` `GetQuestCollections403ErrorResponse`
     */
    getQuestCollections: (query: GetQuestCollectionsParams, params: RequestParams = {}) =>
      this.request<GetQuestCollectionsData, GetQuestCollectionsError>({
        path: `/bo/quest-collections`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quest-collections, QuestCollectionController
     * @name RotateQuestCollection
     * @summary Trigger custom rotation for a quest collection
     * @request POST:/bo/quest-collections/{collectionId}/rotate
     * @secure
     * @response `200` `RotateQuestCollectionData`
     * @response `400` `PostQuestCollectionsCollectionIdRotate400ErrorResponse`
     * @response `403` `PostQuestCollectionsCollectionIdRotate403ErrorResponse`
     * @response `404` `PostQuestCollectionsCollectionIdRotate404ErrorResponse`
     */
    rotateQuestCollection: (collectionId: string, params: RequestParams = {}) =>
      this.request<RotateQuestCollectionData, RotateQuestCollectionError>({
        path: `/bo/quest-collections/${collectionId}/rotate`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  quests = {
    /**
     * No description
     *
     * @tags quests, QuestDeprecatedController
     * @name CreateQuest
     * @summary Create a new quest
     * @request POST:/bo/quests
     * @secure
     * @response `200` `CreateQuestData`
     * @response `400` `PostQuests400ErrorResponse`
     * @response `403` `PostQuests403ErrorResponse`
     * @response `404` `PostQuests404ErrorResponse`
     */
    createQuest: (data: QuestCreationRequest, params: RequestParams = {}) =>
      this.request<CreateQuestData, CreateQuestError>({
        path: `/bo/quests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name CreateQuestV2
     * @summary Create a new quest
     * @request POST:/bo/v2/quests
     * @secure
     * @response `200` `CreateQuestV2Data`
     * @response `400` `PostV2Quests400ErrorResponse`
     * @response `403` `PostV2Quests403ErrorResponse`
     * @response `404` `PostV2Quests404ErrorResponse`
     */
    createQuestV2: (data: CreateQuestRequest, params: RequestParams = {}) =>
      this.request<CreateQuestV2Data, CreateQuestV2Error>({
        path: `/bo/v2/quests`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name DeleteQuest
     * @summary Delete a quest
     * @request DELETE:/bo/quests/{questId}
     * @secure
     * @response `200` `DeleteQuestData`
     * @response `400` `DeleteQuestsQuestId400ErrorResponse`
     * @response `403` `DeleteQuestsQuestId403ErrorResponse`
     * @response `404` `DeleteQuestsQuestId404ErrorResponse`
     */
    deleteQuest: (questId: string, params: RequestParams = {}) =>
      this.request<DeleteQuestData, DeleteQuestError>({
        path: `/bo/quests/${questId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name EditQuest
     * @summary Update a quest
     * @request PATCH:/bo/quests/{questId}
     * @secure
     * @response `200` `EditQuestData`
     * @response `400` `PatchQuestsQuestId400ErrorResponse`
     * @response `403` `PatchQuestsQuestId403ErrorResponse`
     * @response `404` `PatchQuestsQuestId404ErrorResponse`
     */
    editQuest: (questId: string, data: QuestUpdateRequest, params: RequestParams = {}) =>
      this.request<EditQuestData, EditQuestError>({
        path: `/bo/quests/${questId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name GetQuestDetails
     * @summary Get detailed information for a quest
     * @request GET:/bo/quests/{questId}
     * @secure
     * @response `200` `GetQuestDetailsData`
     * @response `403` `GetQuestsQuestId403ErrorResponse`
     * @response `404` `GetQuestsQuestId404ErrorResponse`
     */
    getQuestDetails: (questId: string, params: RequestParams = {}) =>
      this.request<GetQuestDetailsData, GetQuestDetailsError>({
        path: `/bo/quests/${questId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name GetQuests
     * @summary Get a paginated list of quests
     * @request GET:/bo/quests
     * @secure
     * @response `200` `GetQuestsData`
     * @response `400` `GetQuests400ErrorResponse`
     * @response `403` `GetQuests403ErrorResponse`
     * @response `404` `GetQuests404ErrorResponse`
     */
    getQuests: (query: GetQuestsParams, params: RequestParams = {}) =>
      this.request<GetQuestsData, GetQuestsError>({
        path: `/bo/quests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestDeprecatedController
     * @name GetUserQuestProgresses
     * @summary Get a user's progress for all their available quests
     * @request GET:/bo/quests/users/{userId}
     * @secure
     * @response `200` `GetUserQuestProgressesData`
     * @response `403` `GetQuestsUsersUserId403ErrorResponse`
     * @response `404` `GetQuestsUsersUserId404ErrorResponse`
     */
    getUserQuestProgresses: (userId: string, params: RequestParams = {}) =>
      this.request<GetUserQuestProgressesData, GetUserQuestProgressesError>({
        path: `/bo/quests/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name GetUserQuestProgressesV2
     * @summary Get a user's progress for all their available quests
     * @request GET:/bo/v2/quests/users/{userId}
     * @secure
     * @response `200` `GetUserQuestProgressesV2Data`
     * @response `403` `GetV2QuestsUsersUserId403ErrorResponse`
     * @response `404` `GetV2QuestsUsersUserId404ErrorResponse`
     */
    getUserQuestProgressesV2: (userId: string, params: RequestParams = {}) =>
      this.request<GetUserQuestProgressesV2Data, GetUserQuestProgressesV2Error>({
        path: `/bo/v2/quests/users/${userId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name ResetQuest
     * @summary Delete all progress for a quest, including any existing completion data
     * @request POST:/bo/quests/{questId}/reset
     * @secure
     * @response `200` `ResetQuestData`
     * @response `403` `PostQuestsQuestIdReset403ErrorResponse`
     * @response `404` `PostQuestsQuestIdReset404ErrorResponse`
     */
    resetQuest: (questId: string, params: RequestParams = {}) =>
      this.request<ResetQuestData, ResetQuestError>({
        path: `/bo/quests/${questId}/reset`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags quests, QuestController
     * @name UploadCover
     * @summary Add a cover to a quest
     * @request PUT:/bo/quests/{questId}/cover
     * @secure
     * @response `200` `UploadCoverData`
     * @response `400` `PutQuestsQuestIdCover400ErrorResponse`
     * @response `403` `PutQuestsQuestIdCover403ErrorResponse`
     * @response `404` `PutQuestsQuestIdCover404ErrorResponse`
     */
    uploadCover: (questId: string, data: UploadCoverPayload, params: RequestParams = {}) =>
      this.request<UploadCoverData, UploadCoverError>({
        path: `/bo/quests/${questId}/cover`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  refunds = {
    /**
     * No description
     *
     * @tags refunds, GameItemsController
     * @name AddNewRefundFieldValues
     * @summary Add values for the newly added refund fields
     * @request POST:/bo/refunds/add-new-field-values
     * @secure
     * @response `200` `AddNewRefundFieldValuesData`
     * @response `400` `PostRefundsAddNewFieldValues400ErrorResponse`
     * @response `403` `PostRefundsAddNewFieldValues403ErrorResponse`
     */
    addNewRefundFieldValues: (query: AddNewRefundFieldValuesParams, params: RequestParams = {}) =>
      this.request<AddNewRefundFieldValuesData, AddNewRefundFieldValuesError>({
        path: `/bo/refunds/add-new-field-values`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags refunds, RefundsController
     * @name GetRefundsDetails
     * @summary Get list of refunds
     * @request GET:/bo/refunds
     * @secure
     * @response `200` `GetRefundsDetailsData`
     * @response `400` `GetRefunds400ErrorResponse`
     * @response `403` `GetRefunds403ErrorResponse`
     * @response `404` `GetRefunds404ErrorResponse`
     */
    getRefundsDetails: (query: GetRefundsDetailsParams, params: RequestParams = {}) =>
      this.request<GetRefundsDetailsData, GetRefundsDetailsError>({
        path: `/bo/refunds`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  respectPayments = {
    /**
     * No description
     *
     * @tags respect-payments, RespectPaymentController
     * @name ListRespectPayments
     * @summary List respect payments
     * @request GET:/bo/respect-payments
     * @secure
     * @response `200` `ListRespectPaymentsData`
     * @response `400` `GetRespectPayments400ErrorResponse`
     * @response `403` `GetRespectPayments403ErrorResponse`
     * @response `404` `GetRespectPayments404ErrorResponse`
     */
    listRespectPayments: (query: ListRespectPaymentsParams, params: RequestParams = {}) =>
      this.request<ListRespectPaymentsData, ListRespectPaymentsError>({
        path: `/bo/respect-payments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  reviewRequests = {
    /**
     * No description
     *
     * @tags review-requests, ReviewController
     * @name GetMods
     * @summary Get all mods with pending review requests
     * @request GET:/bo/review-requests/mods
     * @secure
     * @response `200` `GetModsResult`
     * @response `400` `GetReviewRequestsMods400ErrorResponse`
     * @response `403` `GetReviewRequestsMods403ErrorResponse`
     */
    getMods: (query: GetModsParams2, params: RequestParams = {}) =>
      this.request<GetModsResult, GetModsFail>({
        path: `/bo/review-requests/mods`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  review = {
    /**
     * No description
     *
     * @tags review, GameReviewController
     * @name GetGameReviewRequests
     * @summary Get all review requests of a specific game
     * @request GET:/bo/review/games/{gameId}/review-requests
     * @secure
     * @response `200` `GetGameReviewRequestsData`
     * @response `400` `GetReviewGamesGameIdReviewRequests400ErrorResponse`
     * @response `403` `GetReviewGamesGameIdReviewRequests403ErrorResponse`
     * @response `404` `GetReviewGamesGameIdReviewRequests404ErrorResponse`
     */
    getGameReviewRequests: (
      { gameId, ...query }: GetGameReviewRequestsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetGameReviewRequestsData, GetGameReviewRequestsError>({
        path: `/bo/review/games/${gameId}/review-requests`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags review, GameReviewController
     * @name GetGameReviews
     * @summary Get all reviews of a specific game
     * @request GET:/bo/review/games/{gameId}/reviews
     * @secure
     * @response `200` `GetGameReviewsData`
     * @response `400` `GetReviewGamesGameIdReviews400ErrorResponse`
     * @response `403` `GetReviewGamesGameIdReviews403ErrorResponse`
     * @response `404` `GetReviewGamesGameIdReviews404ErrorResponse`
     */
    getGameReviews: ({ gameId, ...query }: GetGameReviewsParams, params: RequestParams = {}) =>
      this.request<GetGameReviewsData, GetGameReviewsError>({
        path: `/bo/review/games/${gameId}/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags review, GameReviewController
     * @name GetGames
     * @summary Get all games with pending review requests
     * @request GET:/bo/review/games
     * @secure
     * @response `200` `GetGamesResult`
     * @response `400` `GetReviewGames400ErrorResponse`
     * @response `403` `GetReviewGames403ErrorResponse`
     */
    getGames: (query: GetGamesParams2, params: RequestParams = {}) =>
      this.request<GetGamesResult, GetGamesFail>({
        path: `/bo/review/games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  runners = {
    /**
     * No description
     *
     * @tags runners, InternalController
     * @name RemoveGmsRunners
     * @summary Remove all deleted runners from data base, and all their files from S3
     * @request DELETE:/bo/runners/cleanup
     * @secure
     * @response `200` `RemoveGmsRunnersData`
     * @response `403` `DeleteRunnersCleanup403ErrorResponse`
     */
    removeGmsRunners: (params: RequestParams = {}) =>
      this.request<RemoveGmsRunnersData, RemoveGmsRunnersError>({
        path: `/bo/runners/cleanup`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  scheduledTasks = {
    /**
     * No description
     *
     * @tags scheduled-tasks, ScheduledTasksController
     * @name List
     * @summary List scheduled tasks
     * @request GET:/bo/scheduled-tasks
     * @secure
     * @response `200` `ListData1`
     * @response `403` `GetScheduledTasks403ErrorResponse`
     */
    list: (params: RequestParams = {}) =>
      this.request<ListData1, ListErrorData>({
        path: `/bo/scheduled-tasks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags scheduled-tasks, ScheduledTasksController
     * @name Reschedule
     * @summary Reschedule next execution of scheduled task to ASAP
     * @request POST:/bo/scheduled-tasks/reschedule
     * @secure
     * @response `200` `RescheduleData`
     * @response `400` `PostScheduledTasksReschedule400ErrorResponse`
     * @response `403` `PostScheduledTasksReschedule403ErrorResponse`
     * @response `404` `PostScheduledTasksReschedule404ErrorResponse`
     */
    reschedule: (query: RescheduleParams, params: RequestParams = {}) =>
      this.request<RescheduleData, RescheduleError>({
        path: `/bo/scheduled-tasks/reschedule`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  seasons = {
    /**
     * No description
     *
     * @tags seasons, StudioMetricsController
     * @name ActivatePointsTransactions
     * @summary Activate points transactions
     * @request GET:/bo/seasons/studio-metrics/activate-points-transactions
     * @secure
     * @response `200` `ActivatePointsTransactionsData`
     * @response `403` `GetSeasonsStudioMetricsActivatePointsTransactions403ErrorResponse`
     */
    activatePointsTransactions: (
      query: ActivatePointsTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<ActivatePointsTransactionsData, ActivatePointsTransactionsError>({
        path: `/bo/seasons/studio-metrics/activate-points-transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name AdjustPoints
     * @summary Adjust points in season
     * @request POST:/bo/seasons/{seasonId}/points-transactions
     * @secure
     * @response `200` `AdjustPointsData`
     * @response `400` `PostSeasonsSeasonIdPointsTransactions400ErrorResponse`
     * @response `403` `PostSeasonsSeasonIdPointsTransactions403ErrorResponse`
     * @response `404` `PostSeasonsSeasonIdPointsTransactions404ErrorResponse`
     */
    adjustPoints: (seasonId: string, data: PointsTransactionRequest, params: RequestParams = {}) =>
      this.request<AdjustPointsData, AdjustPointsError>({
        path: `/bo/seasons/${seasonId}/points-transactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name BlockGame
     * @summary Block game in season
     * @request PUT:/bo/seasons/{seasonId}/blocked-games/{gameId}
     * @secure
     * @response `200` `BlockGameData`
     * @response `400` `PutSeasonsSeasonIdBlockedGamesGameId400ErrorResponse`
     * @response `403` `PutSeasonsSeasonIdBlockedGamesGameId403ErrorResponse`
     * @response `404` `PutSeasonsSeasonIdBlockedGamesGameId404ErrorResponse`
     */
    blockGame: (
      seasonId: string,
      gameId: string,
      data: SeasonBlockRequest,
      params: RequestParams = {},
    ) =>
      this.request<BlockGameData, BlockGameError>({
        path: `/bo/seasons/${seasonId}/blocked-games/${gameId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name BlockStudio
     * @summary Block studio in season
     * @request PUT:/bo/seasons/{seasonId}/blocked-studios/{studioId}
     * @secure
     * @response `200` `BlockStudioData`
     * @response `400` `PutSeasonsSeasonIdBlockedStudiosStudioId400ErrorResponse`
     * @response `403` `PutSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse`
     * @response `404` `PutSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse`
     */
    blockStudio: (
      seasonId: string,
      studioId: string,
      data: SeasonBlockRequest,
      params: RequestParams = {},
    ) =>
      this.request<BlockStudioData, BlockStudioError>({
        path: `/bo/seasons/${seasonId}/blocked-studios/${studioId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name CreateSeasonV2
     * @summary Create new season
     * @request POST:/bo/v2/seasons
     * @secure
     * @response `200` `CreateSeasonV2Data`
     * @response `400` `PostV2Seasons400ErrorResponse`
     * @response `403` `PostV2Seasons403ErrorResponse`
     */
    createSeasonV2: (data: CreateSeasonRequest, params: RequestParams = {}) =>
      this.request<CreateSeasonV2Data, CreateSeasonV2Error>({
        path: `/bo/v2/seasons`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name DeleteSeason
     * @summary Delete season
     * @request DELETE:/bo/seasons/{seasonId}
     * @secure
     * @response `200` `DeleteSeasonData`
     * @response `403` `DeleteSeasonsSeasonId403ErrorResponse`
     * @response `404` `DeleteSeasonsSeasonId404ErrorResponse`
     */
    deleteSeason: (seasonId: string, params: RequestParams = {}) =>
      this.request<DeleteSeasonData, DeleteSeasonError>({
        path: `/bo/seasons/${seasonId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name EditSeason
     * @summary Edit season
     * @request PATCH:/bo/seasons/{seasonId}
     * @secure
     * @response `200` `EditSeasonData`
     * @response `400` `PatchSeasonsSeasonId400ErrorResponse`
     * @response `403` `PatchSeasonsSeasonId403ErrorResponse`
     * @response `404` `PatchSeasonsSeasonId404ErrorResponse`
     */
    editSeason: (seasonId: string, data: EditSeasonRequest, params: RequestParams = {}) =>
      this.request<EditSeasonData, EditSeasonError>({
        path: `/bo/seasons/${seasonId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name GetSeasonDetails
     * @summary Get season details
     * @request GET:/bo/seasons/{seasonId}
     * @secure
     * @response `200` `GetSeasonDetailsData`
     * @response `403` `GetSeasonsSeasonId403ErrorResponse`
     * @response `404` `GetSeasonsSeasonId404ErrorResponse`
     */
    getSeasonDetails: (seasonId: string, params: RequestParams = {}) =>
      this.request<GetSeasonDetailsData, GetSeasonDetailsError>({
        path: `/bo/seasons/${seasonId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name ListBlockedGames
     * @summary List blocked games
     * @request GET:/bo/seasons/{seasonId}/blocked-games
     * @secure
     * @response `200` `ListBlockedGamesData`
     * @response `400` `GetSeasonsSeasonIdBlockedGames400ErrorResponse`
     * @response `403` `GetSeasonsSeasonIdBlockedGames403ErrorResponse`
     * @response `404` `GetSeasonsSeasonIdBlockedGames404ErrorResponse`
     */
    listBlockedGames: (
      { seasonId, ...query }: ListBlockedGamesParams,
      params: RequestParams = {},
    ) =>
      this.request<ListBlockedGamesData, ListBlockedGamesError>({
        path: `/bo/seasons/${seasonId}/blocked-games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name ListBlockedStudios
     * @summary List blocked studios
     * @request GET:/bo/seasons/{seasonId}/blocked-studios
     * @secure
     * @response `200` `ListBlockedStudiosData`
     * @response `400` `GetSeasonsSeasonIdBlockedStudios400ErrorResponse`
     * @response `403` `GetSeasonsSeasonIdBlockedStudios403ErrorResponse`
     * @response `404` `GetSeasonsSeasonIdBlockedStudios404ErrorResponse`
     */
    listBlockedStudios: (
      { seasonId, ...query }: ListBlockedStudiosParams,
      params: RequestParams = {},
    ) =>
      this.request<ListBlockedStudiosData, ListBlockedStudiosError>({
        path: `/bo/seasons/${seasonId}/blocked-studios`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name ListPoints
     * @summary List season points transactions
     * @request GET:/bo/seasons/{seasonId}/points-transactions
     * @secure
     * @response `200` `ListPointsData`
     * @response `400` `GetSeasonsSeasonIdPointsTransactions400ErrorResponse`
     * @response `403` `GetSeasonsSeasonIdPointsTransactions403ErrorResponse`
     * @response `404` `GetSeasonsSeasonIdPointsTransactions404ErrorResponse`
     */
    listPoints: ({ seasonId, ...query }: ListPointsParams, params: RequestParams = {}) =>
      this.request<ListPointsData, ListPointsError>({
        path: `/bo/seasons/${seasonId}/points-transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name ListSeasons
     * @summary List seasons
     * @request GET:/bo/seasons
     * @secure
     * @response `200` `ListSeasonsData`
     * @response `400` `GetSeasons400ErrorResponse`
     * @response `403` `GetSeasons403ErrorResponse`
     */
    listSeasons: (query: ListSeasonsParams, params: RequestParams = {}) =>
      this.request<ListSeasonsData, ListSeasonsError>({
        path: `/bo/seasons`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, StudioMetricsController
     * @name TriggerHourlyRun
     * @summary Artificially trigger a daily metrics run
     * @request GET:/bo/seasons/studio-metrics/daily
     * @secure
     * @response `200` `TriggerHourlyRunData`
     * @response `403` `GetSeasonsStudioMetricsDaily403ErrorResponse`
     */
    triggerHourlyRun: (query: TriggerHourlyRunParams, params: RequestParams = {}) =>
      this.request<TriggerHourlyRunData, TriggerHourlyRunError>({
        path: `/bo/seasons/studio-metrics/daily`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, StudioMetricsController
     * @name TriggerHourlyRun2
     * @summary Artificially trigger an hourly metrics run
     * @request GET:/bo/seasons/studio-metrics/hourly
     * @originalName triggerHourlyRun
     * @duplicate
     * @secure
     * @response `200` `TriggerHourlyRun2Data`
     * @response `403` `GetSeasonsStudioMetricsHourly403ErrorResponse`
     */
    triggerHourlyRun2: (query: TriggerHourlyRun2Params, params: RequestParams = {}) =>
      this.request<TriggerHourlyRun2Data, TriggerHourlyRun2Error>({
        path: `/bo/seasons/studio-metrics/hourly`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, StudioMetricsController
     * @name TriggerSeasonStartRun
     * @summary Artificially trigger a "Season Start" metrics run
     * @request GET:/bo/seasons/studio-metrics/season-start
     * @secure
     * @response `200` `TriggerSeasonStartRunData`
     * @response `403` `GetSeasonsStudioMetricsSeasonStart403ErrorResponse`
     */
    triggerSeasonStartRun: (params: RequestParams = {}) =>
      this.request<TriggerSeasonStartRunData, TriggerSeasonStartRunError>({
        path: `/bo/seasons/studio-metrics/season-start`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name UnblockGame
     * @summary Unblock game in season
     * @request DELETE:/bo/seasons/{seasonId}/blocked-games/{gameId}
     * @secure
     * @response `200` `UnblockGameData`
     * @response `403` `DeleteSeasonsSeasonIdBlockedGamesGameId403ErrorResponse`
     * @response `404` `DeleteSeasonsSeasonIdBlockedGamesGameId404ErrorResponse`
     */
    unblockGame: (seasonId: string, gameId: string, params: RequestParams = {}) =>
      this.request<UnblockGameData, UnblockGameError>({
        path: `/bo/seasons/${seasonId}/blocked-games/${gameId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags seasons, SeasonController
     * @name UnblockStudio
     * @summary Unblock studio in season
     * @request DELETE:/bo/seasons/{seasonId}/blocked-studios/{studioId}
     * @secure
     * @response `200` `UnblockStudioData`
     * @response `403` `DeleteSeasonsSeasonIdBlockedStudiosStudioId403ErrorResponse`
     * @response `404` `DeleteSeasonsSeasonIdBlockedStudiosStudioId404ErrorResponse`
     */
    unblockStudio: (seasonId: string, studioId: string, params: RequestParams = {}) =>
      this.request<UnblockStudioData, UnblockStudioError>({
        path: `/bo/seasons/${seasonId}/blocked-studios/${studioId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  session = {
    /**
     * No description
     *
     * @tags session, SessionController
     * @name GetLogin
     * @summary Initiate back-office login by redirecting to SAML 2.0 IdP
     * @request GET:/bo/session/login
     * @secure
     * @response `200` `GetLoginData`
     */
    getLogin: (params: RequestParams = {}) =>
      this.request<GetLoginData, any>({
        path: `/bo/session/login`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session, SessionController
     * @name GetLogout
     * @summary Logs out of current session
     * @request POST:/bo/session/logout
     * @secure
     * @response `200` `GetLogoutData`
     * @response `403` `PostSessionLogout403ErrorResponse`
     */
    getLogout: (params: RequestParams = {}) =>
      this.request<GetLogoutData, GetLogoutError>({
        path: `/bo/session/logout`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags session, SessionController
     * @name GetRedirect
     * @summary Redirect to back-office front-end after successful login
     * @request GET:/bo/session/redirect
     * @secure
     * @response `200` `GetRedirectData`
     * @response `403` `GetSessionRedirect403ErrorResponse`
     */
    getRedirect: (params: RequestParams = {}) =>
      this.request<GetRedirectData, GetRedirectError>({
        path: `/bo/session/redirect`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  settings = {
    /**
     * No description
     *
     * @tags settings, SettingsController
     * @name CreateSettingOverrideTransaction
     * @summary Create a setting override transaction that updates one or more settings
     * @request POST:/bo/settings/transactions
     * @secure
     * @response `200` `CreateSettingOverrideTransactionData`
     * @response `400` `PostSettingsTransactions400ErrorResponse`
     * @response `403` `PostSettingsTransactions403ErrorResponse`
     * @response `404` `PostSettingsTransactions404ErrorResponse`
     */
    createSettingOverrideTransaction: (
      data: CreateSettingOverrideTransactionRequest,
      params: RequestParams = {},
    ) =>
      this.request<CreateSettingOverrideTransactionData, CreateSettingOverrideTransactionError>({
        path: `/bo/settings/transactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, SettingsController
     * @name GetSettingDetails
     * @summary Fetch details about a single setting
     * @request GET:/bo/settings/{settingId}
     * @secure
     * @response `200` `GetSettingDetailsData`
     * @response `400` `GetSettingsSettingId400ErrorResponse`
     * @response `403` `GetSettingsSettingId403ErrorResponse`
     * @response `404` `GetSettingsSettingId404ErrorResponse`
     */
    getSettingDetails: (
      { settingId, ...query }: GetSettingDetailsParams,
      params: RequestParams = {},
    ) =>
      this.request<GetSettingDetailsData, GetSettingDetailsError>({
        path: `/bo/settings/${settingId}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, LegacySettingsController
     * @name GetSettings
     * @summary List of all settings with current values and types
     * @request GET:/bo/settings
     * @secure
     * @response `200` `GetSettingsData`
     * @response `403` `GetSettings403ErrorResponse`
     */
    getSettings: (params: RequestParams = {}) =>
      this.request<GetSettingsData, GetSettingsError>({
        path: `/bo/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, SettingsController
     * @name ListSettingOverrideTransactions
     * @summary List setting override transactions
     * @request GET:/bo/settings/transactions
     * @secure
     * @response `200` `ListSettingOverrideTransactionsData`
     * @response `400` `GetSettingsTransactions400ErrorResponse`
     * @response `403` `GetSettingsTransactions403ErrorResponse`
     */
    listSettingOverrideTransactions: (
      query: ListSettingOverrideTransactionsParams,
      params: RequestParams = {},
    ) =>
      this.request<ListSettingOverrideTransactionsData, ListSettingOverrideTransactionsError>({
        path: `/bo/settings/transactions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, SettingsController
     * @name ListSettingsV2
     * @summary Fetch all settings grouped into namespaces
     * @request GET:/bo/v2/settings
     * @secure
     * @response `200` `ListSettingsV2Data`
     * @response `403` `GetV2Settings403ErrorResponse`
     */
    listSettingsV2: (query: ListSettingsV2Params, params: RequestParams = {}) =>
      this.request<ListSettingsV2Data, ListSettingsV2Error>({
        path: `/bo/v2/settings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, LegacySettingsController
     * @name UpdateGraphicSetting
     * @summary Update graphic setting
     * @request PUT:/bo/settings/{settingKey}
     * @secure
     * @response `200` `UpdateGraphicSettingData`
     * @response `400` `PutSettingsSettingKey400ErrorResponse`
     * @response `403` `PutSettingsSettingKey403ErrorResponse`
     * @response `404` `PutSettingsSettingKey404ErrorResponse`
     */
    updateGraphicSetting: (
      settingKey: string,
      data: UpdateGraphicSettingPayload,
      params: RequestParams = {},
    ) =>
      this.request<UpdateGraphicSettingData, UpdateGraphicSettingError>({
        path: `/bo/settings/${settingKey}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings, LegacySettingsController
     * @name UpdateSettings
     * @summary Update one or more settings
     * @request PATCH:/bo/settings
     * @secure
     * @response `200` `UpdateSettingsData`
     * @response `400` `PatchSettings400ErrorResponse`
     * @response `403` `PatchSettings403ErrorResponse`
     * @response `404` `PatchSettings404ErrorResponse`
     */
    updateSettings: (data: PatchLegacySettingsRequest, params: RequestParams = {}) =>
      this.request<UpdateSettingsData, UpdateSettingsError>({
        path: `/bo/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  statsApi = {
    /**
     * No description
     *
     * @tags stats-api, EntityVersionStatsEventController
     * @name RequestEvents
     * @summary Request re-sending of stats events for some versioned entities
     * @request POST:/bo/stats-api/request-events
     * @secure
     * @response `200` `RequestEventsData`
     * @response `403` `PostStatsApiRequestEvents403ErrorResponse`
     */
    requestEvents: (query: RequestEventsParams, params: RequestParams = {}) =>
      this.request<RequestEventsData, RequestEventsError>({
        path: `/bo/stats-api/request-events`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stats-api, EntityVersionStatsEventController
     * @name SendRequestedEvents
     * @summary Send previously requested stats events
     * @request POST:/bo/stats-api/send-requested-events
     * @secure
     * @response `200` `SendRequestedEventsData`
     * @response `400` `PostStatsApiSendRequestedEvents400ErrorResponse`
     * @response `403` `PostStatsApiSendRequestedEvents403ErrorResponse`
     */
    sendRequestedEvents: (query: SendRequestedEventsParams, params: RequestParams = {}) =>
      this.request<SendRequestedEventsData, SendRequestedEventsError>({
        path: `/bo/stats-api/send-requested-events`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  stickers = {
    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name CreateSticker
     * @summary Create a sticker
     * @request POST:/bo/stickers
     * @secure
     * @response `200` `CreateStickerData`
     * @response `400` `PostStickers400ErrorResponse`
     * @response `403` `PostStickers403ErrorResponse`
     */
    createSticker: (data: CreateStickerRequest, params: RequestParams = {}) =>
      this.request<CreateStickerData, CreateStickerError>({
        path: `/bo/stickers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name GetStickerDetails
     * @summary Get details about a sticker
     * @request GET:/bo/stickers/{stickerId}
     * @secure
     * @response `200` `GetStickerDetailsData`
     * @response `403` `GetStickersStickerId403ErrorResponse`
     * @response `404` `GetStickersStickerId404ErrorResponse`
     */
    getStickerDetails: (stickerId: string, params: RequestParams = {}) =>
      this.request<GetStickerDetailsData, GetStickerDetailsError>({
        path: `/bo/stickers/${stickerId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name ListStickers
     * @summary List all stickers
     * @request GET:/bo/stickers
     * @secure
     * @response `200` `ListStickersData`
     * @response `400` `GetStickers400ErrorResponse`
     * @response `403` `GetStickers403ErrorResponse`
     */
    listStickers: (query: ListStickersParams, params: RequestParams = {}) =>
      this.request<ListStickersData, ListStickersError>({
        path: `/bo/stickers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name ReorderStickers
     * @summary Reorder stickers
     * @request PATCH:/bo/stickers
     * @secure
     * @response `200` `ReorderStickersData`
     * @response `400` `PatchStickers400ErrorResponse`
     * @response `403` `PatchStickers403ErrorResponse`
     * @response `404` `PatchStickers404ErrorResponse`
     */
    reorderStickers: (data: ReorderStickersRequest, params: RequestParams = {}) =>
      this.request<ReorderStickersData, ReorderStickersError>({
        path: `/bo/stickers`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name UpdateSticker
     * @summary Update a sticker
     * @request PATCH:/bo/stickers/{stickerId}
     * @secure
     * @response `200` `UpdateStickerData`
     * @response `400` `PatchStickersStickerId400ErrorResponse`
     * @response `403` `PatchStickersStickerId403ErrorResponse`
     * @response `404` `PatchStickersStickerId404ErrorResponse`
     */
    updateSticker: (stickerId: string, data: UpdateStickerRequest, params: RequestParams = {}) =>
      this.request<UpdateStickerData, UpdateStickerError>({
        path: `/bo/stickers/${stickerId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stickers, StickerController
     * @name UploadCover
     * @summary Upload a sticker cover
     * @request PUT:/bo/stickers/{stickerId}/cover
     * @secure
     * @response `200` `UploadCoverResult`
     * @response `400` `PutStickersStickerIdCover400ErrorResponse`
     * @response `403` `PutStickersStickerIdCover403ErrorResponse`
     * @response `404` `PutStickersStickerIdCover404ErrorResponse`
     */
    uploadCover: (stickerId: string, data: UploadCoverBody, params: RequestParams = {}) =>
      this.request<UploadCoverResult, UploadCoverFail>({
        path: `/bo/stickers/${stickerId}/cover`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  strings = {
    /**
     * No description
     *
     * @tags strings, StringsController
     * @name GetLocales
     * @summary List supported locales
     * @request GET:/bo/strings/locales
     * @secure
     * @response `200` `GetLocalesData`
     * @response `403` `GetStringsLocales403ErrorResponse`
     */
    getLocales: (params: RequestParams = {}) =>
      this.request<GetLocalesData, GetLocalesError>({
        path: `/bo/strings/locales`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strings, StringsController
     * @name GetStringDetails
     * @summary Get details about a single localizable string
     * @request GET:/bo/strings/{stringId}
     * @secure
     * @response `200` `GetStringDetailsData`
     * @response `403` `GetStringsStringId403ErrorResponse`
     * @response `404` `GetStringsStringId404ErrorResponse`
     */
    getStringDetails: (stringId: string, params: RequestParams = {}) =>
      this.request<GetStringDetailsData, GetStringDetailsError>({
        path: `/bo/strings/${stringId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strings, StringsController
     * @name GetStrings
     * @summary List all localizable strings
     * @request GET:/bo/strings
     * @secure
     * @response `200` `GetStringsData`
     * @response `400` `GetStrings400ErrorResponse`
     * @response `403` `GetStrings403ErrorResponse`
     */
    getStrings: (query: GetStringsParams, params: RequestParams = {}) =>
      this.request<GetStringsData, GetStringsError>({
        path: `/bo/strings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags strings, StringsController
     * @name UpdateString
     * @summary Update localizable string
     * @request PATCH:/bo/strings/{stringId}
     * @secure
     * @response `200` `UpdateStringData`
     * @response `400` `PatchStringsStringId400ErrorResponse`
     * @response `403` `PatchStringsStringId403ErrorResponse`
     * @response `404` `PatchStringsStringId404ErrorResponse`
     */
    updateString: (stringId: string, data: UpdateStringRequest, params: RequestParams = {}) =>
      this.request<UpdateStringData, UpdateStringError>({
        path: `/bo/strings/${stringId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  studio = {
    /**
     * No description
     *
     * @tags studio, LegacyStudiosSettingsController
     * @name GetAvailableStudioSettings
     * @summary List of available studio settings with current values and types
     * @request GET:/bo/studio/settings
     * @secure
     * @response `200` `GetAvailableStudioSettingsData`
     * @response `403` `GetStudioSettings403ErrorResponse`
     */
    getAvailableStudioSettings: (params: RequestParams = {}) =>
      this.request<GetAvailableStudioSettingsData, GetAvailableStudioSettingsError>({
        path: `/bo/studio/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  studios = {
    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name CreateStudio
     * @summary Create a studio
     * @request POST:/bo/studios
     * @secure
     * @response `200` `CreateStudioData`
     * @response `400` `PostStudios400ErrorResponse`
     * @response `403` `PostStudios403ErrorResponse`
     * @response `404` `PostStudios404ErrorResponse`
     */
    createStudio: (data: CreateStudioRequest, params: RequestParams = {}) =>
      this.request<CreateStudioData, CreateStudioError>({
        path: `/bo/studios`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name DeleteStudio
     * @summary Delete a studio
     * @request DELETE:/bo/studios/{studioId}
     * @secure
     * @response `200` `DeleteStudioData`
     * @response `400` `DeleteStudiosStudioId400ErrorResponse`
     * @response `403` `DeleteStudiosStudioId403ErrorResponse`
     * @response `404` `DeleteStudiosStudioId404ErrorResponse`
     */
    deleteStudio: (studioId: string, params: RequestParams = {}) =>
      this.request<DeleteStudioData, DeleteStudioError>({
        path: `/bo/studios/${studioId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name GetStripeConnectAccountBalanceDetails
     * @summary Get studio Stripe Connect Account balance details
     * @request GET:/bo/studios/{studioId}/stripe-connect-account-balance
     * @secure
     * @response `200` `GetStripeConnectAccountBalanceDetailsData`
     * @response `400` `GetStudiosStudioIdStripeConnectAccountBalance400ErrorResponse`
     * @response `403` `GetStudiosStudioIdStripeConnectAccountBalance403ErrorResponse`
     * @response `404` `GetStudiosStudioIdStripeConnectAccountBalance404ErrorResponse`
     * @response `500` `GetStudiosStudioIdStripeConnectAccountBalance500ErrorResponse`
     */
    getStripeConnectAccountBalanceDetails: (studioId: string, params: RequestParams = {}) =>
      this.request<
        GetStripeConnectAccountBalanceDetailsData,
        GetStripeConnectAccountBalanceDetailsError
      >({
        path: `/bo/studios/${studioId}/stripe-connect-account-balance`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name GetStudio
     * @summary Get studio details
     * @request GET:/bo/studios/{studioId}
     * @secure
     * @response `200` `GetStudioData`
     * @response `403` `GetStudiosStudioId403ErrorResponse`
     * @response `404` `GetStudiosStudioId404ErrorResponse`
     */
    getStudio: (studioId: string, params: RequestParams = {}) =>
      this.request<GetStudioData, GetStudioError>({
        path: `/bo/studios/${studioId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name GetStudios
     * @summary Get all studios
     * @request GET:/bo/studios
     * @secure
     * @response `200` `GetStudiosData`
     * @response `400` `GetStudios400ErrorResponse`
     * @response `403` `GetStudios403ErrorResponse`
     */
    getStudios: (query: GetStudiosParams, params: RequestParams = {}) =>
      this.request<GetStudiosData, GetStudiosError>({
        path: `/bo/studios`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, LegacyStudiosSettingsController
     * @name GetStudioSettings
     * @summary Studio settings with current values and types
     * @request GET:/bo/studios/{studioId}/settings
     * @secure
     * @response `200` `GetStudioSettingsData`
     * @response `403` `GetStudiosStudioIdSettings403ErrorResponse`
     * @response `404` `GetStudiosStudioIdSettings404ErrorResponse`
     */
    getStudioSettings: (studioId: string, params: RequestParams = {}) =>
      this.request<GetStudioSettingsData, GetStudioSettingsError>({
        path: `/bo/studios/${studioId}/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosSettingsController
     * @name GetStudioSettingsV2
     * @summary Studio settings with current values and types
     * @request GET:/bo/v2/studios/{studioId}/settings
     * @secure
     * @response `200` `GetStudioSettingsV2Data`
     * @response `403` `GetV2StudiosStudioIdSettings403ErrorResponse`
     * @response `404` `GetV2StudiosStudioIdSettings404ErrorResponse`
     */
    getStudioSettingsV2: (studioId: string, params: RequestParams = {}) =>
      this.request<GetStudioSettingsV2Data, GetStudioSettingsV2Error>({
        path: `/bo/v2/studios/${studioId}/settings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, LegacyStudiosSettingsController
     * @name GetStudiosSettings
     * @summary List of all studios settings with current values and types
     * @request GET:/bo/studios/settings
     * @secure
     * @response `200` `GetStudiosSettingsData`
     * @response `403` `GetStudiosSettings403ErrorResponse`
     */
    getStudiosSettings: (query: GetStudiosSettingsParams, params: RequestParams = {}) =>
      this.request<GetStudiosSettingsData, GetStudiosSettingsError>({
        path: `/bo/studios/settings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosController
     * @name PatchStudio
     * @summary Edit a studio
     * @request PATCH:/bo/studios/{studioId}
     * @secure
     * @response `200` `PatchStudioData`
     * @response `400` `PatchStudiosStudioId400ErrorResponse`
     * @response `403` `PatchStudiosStudioId403ErrorResponse`
     * @response `404` `PatchStudiosStudioId404ErrorResponse`
     */
    patchStudio: (studioId: string, data: UpdateStudioRequest, params: RequestParams = {}) =>
      this.request<PatchStudioData, PatchStudioError>({
        path: `/bo/studios/${studioId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudioCoverController
     * @name RemoveCover
     * @summary Remove a studio's cover
     * @request DELETE:/bo/studios/{studioId}/covers/{coverId}
     * @secure
     * @response `200` `RemoveCoverData`
     * @response `400` `DeleteStudiosStudioIdCoversCoverId400ErrorResponse`
     * @response `403` `DeleteStudiosStudioIdCoversCoverId403ErrorResponse`
     * @response `404` `DeleteStudiosStudioIdCoversCoverId404ErrorResponse`
     */
    removeCover: (studioId: string, coverId: string, params: RequestParams = {}) =>
      this.request<RemoveCoverData, RemoveCoverError>({
        path: `/bo/studios/${studioId}/covers/${coverId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, LegacyStudiosSettingsController
     * @name UpdateStudioSettings
     * @summary Update studio settings
     * @request PATCH:/bo/studios/{studioId}/settings
     * @secure
     * @response `200` `UpdateStudioSettingsData`
     * @response `400` `PatchStudiosStudioIdSettings400ErrorResponse`
     * @response `403` `PatchStudiosStudioIdSettings403ErrorResponse`
     * @response `404` `PatchStudiosStudioIdSettings404ErrorResponse`
     */
    updateStudioSettings: (
      studioId: string,
      data: PatchLegacyStudioSettingsRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateStudioSettingsData, UpdateStudioSettingsError>({
        path: `/bo/studios/${studioId}/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudiosSettingsController
     * @name UpdateStudioSettingsV2
     * @summary Update studio settings
     * @request PATCH:/bo/v2/studios/{studioId}/settings
     * @secure
     * @response `200` `UpdateStudioSettingsV2Data`
     * @response `400` `PatchV2StudiosStudioIdSettings400ErrorResponse`
     * @response `403` `PatchV2StudiosStudioIdSettings403ErrorResponse`
     * @response `404` `PatchV2StudiosStudioIdSettings404ErrorResponse`
     */
    updateStudioSettingsV2: (
      studioId: string,
      data: PatchStudioSettingsRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateStudioSettingsV2Data, UpdateStudioSettingsV2Error>({
        path: `/bo/v2/studios/${studioId}/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags studios, StudioCoverController
     * @name UploadCover
     * @summary Upload a cover to a studio
     * @request POST:/bo/studios/{studioId}/covers
     * @secure
     * @response `200` `UploadCoverOutput`
     * @response `400` `PostStudiosStudioIdCovers400ErrorResponse`
     * @response `403` `PostStudiosStudioIdCovers403ErrorResponse`
     * @response `404` `PostStudiosStudioIdCovers404ErrorResponse`
     */
    uploadCover: (studioId: string, data: UploadCoverInput, params: RequestParams = {}) =>
      this.request<UploadCoverOutput, UploadCoverFails>({
        path: `/bo/studios/${studioId}/covers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  tags = {
    /**
     * No description
     *
     * @tags tags, TagsController
     * @name AddGame
     * @summary Add tag to game
     * @request PUT:/bo/tags/{tagId}/games/{gameId}
     * @secure
     * @response `200` `AddGameData`
     * @response `400` `PutTagsTagIdGamesGameId400ErrorResponse`
     * @response `403` `PutTagsTagIdGamesGameId403ErrorResponse`
     * @response `404` `PutTagsTagIdGamesGameId404ErrorResponse`
     */
    addGame: (tagId: string, gameId: string, params: RequestParams = {}) =>
      this.request<AddGameData, AddGameError>({
        path: `/bo/tags/${tagId}/games/${gameId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name AddMod
     * @summary Add tag to mod
     * @request PUT:/bo/tags/{tagId}/mods/{modId}
     * @secure
     * @response `200` `AddModData`
     * @response `400` `PutTagsTagIdModsModId400ErrorResponse`
     * @response `403` `PutTagsTagIdModsModId403ErrorResponse`
     * @response `404` `PutTagsTagIdModsModId404ErrorResponse`
     */
    addMod: (tagId: string, modId: string, params: RequestParams = {}) =>
      this.request<AddModData, AddModError>({
        path: `/bo/tags/${tagId}/mods/${modId}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name CreateModPlatformTags
     * @summary Create mod platform tags
     * @request POST:/bo/tags/create-mod-platform-tags
     * @secure
     * @response `200` `CreateModPlatformTagsData`
     * @response `403` `PostTagsCreateModPlatformTags403ErrorResponse`
     */
    createModPlatformTags: (params: RequestParams = {}) =>
      this.request<CreateModPlatformTagsData, CreateModPlatformTagsError>({
        path: `/bo/tags/create-mod-platform-tags`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name CreateModTypeTags
     * @summary Create mod type tags
     * @request POST:/bo/tags/create-mod-type-tags
     * @secure
     * @response `200` `CreateModTypeTagsData`
     * @response `403` `PostTagsCreateModTypeTags403ErrorResponse`
     */
    createModTypeTags: (params: RequestParams = {}) =>
      this.request<CreateModTypeTagsData, CreateModTypeTagsError>({
        path: `/bo/tags/create-mod-type-tags`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name CreateModWallpaperTypeTags
     * @summary Create mod wallpaper type tags
     * @request POST:/bo/tags/create-mod-wallpaper-type-tags
     * @secure
     * @response `200` `CreateModWallpaperTypeTagsData`
     * @response `403` `PostTagsCreateModWallpaperTypeTags403ErrorResponse`
     */
    createModWallpaperTypeTags: (params: RequestParams = {}) =>
      this.request<CreateModWallpaperTypeTagsData, CreateModWallpaperTypeTagsError>({
        path: `/bo/tags/create-mod-wallpaper-type-tags`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name CreateTagV2
     * @summary Create tag
     * @request POST:/bo/v2/tags
     * @secure
     * @response `200` `CreateTagV2Data`
     * @response `400` `PostV2Tags400ErrorResponse`
     * @response `403` `PostV2Tags403ErrorResponse`
     */
    createTagV2: (data: CreateTagRequest, params: RequestParams = {}) =>
      this.request<CreateTagV2Data, CreateTagV2Error>({
        path: `/bo/v2/tags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagIconController
     * @name DeleteIcon
     * @summary Delete icon for a tag.
     * @request DELETE:/bo/tags/{tagId}/icon
     * @secure
     * @response `200` `DeleteIconData`
     * @response `403` `DeleteTagsTagIdIcon403ErrorResponse`
     * @response `404` `DeleteTagsTagIdIcon404ErrorResponse`
     */
    deleteIcon: (tagId: string, params: RequestParams = {}) =>
      this.request<DeleteIconData, DeleteIconError>({
        path: `/bo/tags/${tagId}/icon`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name DeleteTag
     * @summary Delete tag
     * @request DELETE:/bo/tags/{tagId}
     * @secure
     * @response `200` `DeleteTagData`
     * @response `400` `DeleteTagsTagId400ErrorResponse`
     * @response `403` `DeleteTagsTagId403ErrorResponse`
     * @response `404` `DeleteTagsTagId404ErrorResponse`
     */
    deleteTag: (tagId: string, params: RequestParams = {}) =>
      this.request<DeleteTagData, DeleteTagError>({
        path: `/bo/tags/${tagId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name GetTagDetails
     * @summary Get tag details
     * @request GET:/bo/tags/{tagId}
     * @secure
     * @response `200` `GetTagDetailsData`
     * @response `403` `GetTagsTagId403ErrorResponse`
     * @response `404` `GetTagsTagId404ErrorResponse`
     */
    getTagDetails: (tagId: string, params: RequestParams = {}) =>
      this.request<GetTagDetailsData, GetTagDetailsError>({
        path: `/bo/tags/${tagId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name GetTags
     * @summary List tags
     * @request GET:/bo/tags
     * @secure
     * @response `200` `GetTagsData`
     * @response `400` `GetTags400ErrorResponse`
     * @response `403` `GetTags403ErrorResponse`
     * @response `404` `GetTags404ErrorResponse`
     */
    getTags: (query: GetTagsParams, params: RequestParams = {}) =>
      this.request<GetTagsData, GetTagsError>({
        path: `/bo/tags`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name ListGames
     * @summary List tagged games
     * @request GET:/bo/tags/{tagId}/games
     * @secure
     * @response `200` `ListGamesData`
     * @response `400` `GetTagsTagIdGames400ErrorResponse`
     * @response `403` `GetTagsTagIdGames403ErrorResponse`
     * @response `404` `GetTagsTagIdGames404ErrorResponse`
     */
    listGames: ({ tagId, ...query }: ListGamesParams, params: RequestParams = {}) =>
      this.request<ListGamesData, ListGamesError>({
        path: `/bo/tags/${tagId}/games`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagIconController
     * @name PutIcon
     * @summary Set icon image for a tag from an uploaded file.
     * @request PUT:/bo/tags/{tagId}/icon
     * @secure
     * @response `201` `PutIconData`
     * @response `400` `PutTagsTagIdIcon400ErrorResponse`
     * @response `403` `PutTagsTagIdIcon403ErrorResponse`
     * @response `404` `PutTagsTagIdIcon404ErrorResponse`
     * @response `500` `PutTagsTagIdIcon500ErrorResponse`
     */
    putIcon: (tagId: string, data: PutIconPayload, params: RequestParams = {}) =>
      this.request<PutIconData, PutIconError>({
        path: `/bo/tags/${tagId}/icon`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name RecalculateNumberOfEntities
     * @summary Recalculate number of entities for all tags
     * @request POST:/bo/tags/recalculate-number-of-entities
     * @secure
     * @response `200` `RecalculateNumberOfEntitiesData`
     * @response `403` `PostTagsRecalculateNumberOfEntities403ErrorResponse`
     */
    recalculateNumberOfEntities: (params: RequestParams = {}) =>
      this.request<RecalculateNumberOfEntitiesData, RecalculateNumberOfEntitiesError>({
        path: `/bo/tags/recalculate-number-of-entities`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name RemoveGame
     * @summary Remove tag from game
     * @request DELETE:/bo/tags/{tagId}/games/{gameId}
     * @secure
     * @response `200` `RemoveGameData`
     * @response `400` `DeleteTagsTagIdGamesGameId400ErrorResponse`
     * @response `403` `DeleteTagsTagIdGamesGameId403ErrorResponse`
     * @response `404` `DeleteTagsTagIdGamesGameId404ErrorResponse`
     */
    removeGame: (tagId: string, gameId: string, params: RequestParams = {}) =>
      this.request<RemoveGameData, RemoveGameError>({
        path: `/bo/tags/${tagId}/games/${gameId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name RemoveMod
     * @summary Remove tag from mod
     * @request DELETE:/bo/tags/{tagId}/mods/{modId}
     * @secure
     * @response `200` `RemoveModData`
     * @response `400` `DeleteTagsTagIdModsModId400ErrorResponse`
     * @response `403` `DeleteTagsTagIdModsModId403ErrorResponse`
     * @response `404` `DeleteTagsTagIdModsModId404ErrorResponse`
     */
    removeMod: (tagId: string, modId: string, params: RequestParams = {}) =>
      this.request<RemoveModData, RemoveModError>({
        path: `/bo/tags/${tagId}/mods/${modId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name UpdateRootTagsOrdering
     * @summary Update root tags ordering
     * @request PATCH:/bo/tags
     * @secure
     * @response `200` `UpdateRootTagsOrderingData`
     * @response `400` `PatchTags400ErrorResponse`
     * @response `403` `PatchTags403ErrorResponse`
     */
    updateRootTagsOrdering: (data: UpdateTagsRequest, params: RequestParams = {}) =>
      this.request<UpdateRootTagsOrderingData, UpdateRootTagsOrderingError>({
        path: `/bo/tags`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags tags, TagsController
     * @name UpdateTag
     * @summary Update tag
     * @request PATCH:/bo/tags/{tagId}
     * @secure
     * @response `200` `UpdateTagData`
     * @response `400` `PatchTagsTagId400ErrorResponse`
     * @response `403` `PatchTagsTagId403ErrorResponse`
     * @response `404` `PatchTagsTagId404ErrorResponse`
     */
    updateTag: (tagId: string, data: UpdateTagRequest, params: RequestParams = {}) =>
      this.request<UpdateTagData, UpdateTagError>({
        path: `/bo/tags/${tagId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  userChallengePlayStatistics = {
    /**
     * No description
     *
     * @tags user-challenge-play-statistics, GamePlayStatisticsController
     * @name RecalculateUserChallengePlayStatistics
     * @summary Recalculate overall user challenge statistics
     * @request PATCH:/bo/user-challenge-play-statistics
     * @secure
     * @response `200` `RecalculateUserChallengePlayStatisticsData`
     * @response `403` `PatchUserChallengePlayStatistics403ErrorResponse`
     */
    recalculateUserChallengePlayStatistics: (params: RequestParams = {}) =>
      this.request<
        RecalculateUserChallengePlayStatisticsData,
        RecalculateUserChallengePlayStatisticsError
      >({
        path: `/bo/user-challenge-play-statistics`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  userGamePlayStatistics = {
    /**
     * No description
     *
     * @tags user-game-play-statistics, GamePlayStatisticsController
     * @name RecalculateUserGamePlayStatistics
     * @summary Recalculate user game play statistics
     * @request PATCH:/bo/user-game-play-statistics
     * @secure
     * @response `200` `RecalculateUserGamePlayStatisticsData`
     * @response `403` `PatchUserGamePlayStatistics403ErrorResponse`
     */
    recalculateUserGamePlayStatistics: (params: RequestParams = {}) =>
      this.request<RecalculateUserGamePlayStatisticsData, RecalculateUserGamePlayStatisticsError>({
        path: `/bo/user-game-play-statistics`,
        method: 'PATCH',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  user = {
    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name CreateBioLink
     * @summary Create bio link
     * @request POST:/bo/user/{userId}/bio-links
     * @secure
     * @response `200` `CreateBioLinkData`
     * @response `400` `PostUserUserIdBioLinks400ErrorResponse`
     * @response `403` `PostUserUserIdBioLinks403ErrorResponse`
     * @response `404` `PostUserUserIdBioLinks404ErrorResponse`
     */
    createBioLink: (userId: string, data: CreateBioLinkRequest, params: RequestParams = {}) =>
      this.request<CreateBioLinkData, CreateBioLinkError>({
        path: `/bo/user/${userId}/bio-links`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name DeleteBioLink
     * @summary Delete bio link
     * @request DELETE:/bo/user/{userId}/bio-links/{linkId}
     * @secure
     * @response `200` `DeleteBioLinkData`
     * @response `403` `DeleteUserUserIdBioLinksLinkId403ErrorResponse`
     * @response `404` `DeleteUserUserIdBioLinksLinkId404ErrorResponse`
     */
    deleteBioLink: (userId: string, linkId: string, params: RequestParams = {}) =>
      this.request<DeleteBioLinkData, DeleteBioLinkError>({
        path: `/bo/user/${userId}/bio-links/${linkId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name DeleteIcon
     * @summary Delete icon for a bio link.
     * @request DELETE:/bo/user/{userId}/bio-links/{linkId}/icon
     * @secure
     * @response `200` `DeleteIconResult`
     * @response `403` `DeleteUserUserIdBioLinksLinkIdIcon403ErrorResponse`
     * @response `404` `DeleteUserUserIdBioLinksLinkIdIcon404ErrorResponse`
     * @response `500` `DeleteUserUserIdBioLinksLinkIdIcon500ErrorResponse`
     */
    deleteIcon: (userId: string, linkId: string, params: RequestParams = {}) =>
      this.request<DeleteIconResult, DeleteIconFail>({
        path: `/bo/user/${userId}/bio-links/${linkId}/icon`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name UpdateBioLink
     * @summary Update bio link
     * @request PATCH:/bo/user/{userId}/bio-links/{linkId}
     * @secure
     * @response `200` `UpdateBioLinkData`
     * @response `400` `PatchUserUserIdBioLinksLinkId400ErrorResponse`
     * @response `403` `PatchUserUserIdBioLinksLinkId403ErrorResponse`
     * @response `404` `PatchUserUserIdBioLinksLinkId404ErrorResponse`
     */
    updateBioLink: (
      userId: string,
      linkId: string,
      data: UpdateBioLinkRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateBioLinkData, UpdateBioLinkError>({
        path: `/bo/user/${userId}/bio-links/${linkId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name UpdateBioLinksOrder
     * @summary Update bio links order
     * @request PATCH:/bo/user/{userId}/bio-links
     * @secure
     * @response `200` `UpdateBioLinksOrderData`
     * @response `400` `PatchUserUserIdBioLinks400ErrorResponse`
     * @response `403` `PatchUserUserIdBioLinks403ErrorResponse`
     * @response `404` `PatchUserUserIdBioLinks404ErrorResponse`
     */
    updateBioLinksOrder: (
      userId: string,
      data: UpdateBioLinksOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<UpdateBioLinksOrderData, UpdateBioLinksOrderError>({
        path: `/bo/user/${userId}/bio-links`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user, BioLinkController
     * @name UploadBioLinkIcon
     * @summary Set image for a bio link from an uploaded file.
     * @request PUT:/bo/user/{userId}/bio-links/{linkId}/icon
     * @secure
     * @response `200` `UploadBioLinkIconData`
     * @response `400` `PutUserUserIdBioLinksLinkIdIcon400ErrorResponse`
     * @response `403` `PutUserUserIdBioLinksLinkIdIcon403ErrorResponse`
     * @response `404` `PutUserUserIdBioLinksLinkIdIcon404ErrorResponse`
     * @response `500` `PutUserUserIdBioLinksLinkIdIcon500ErrorResponse`
     */
    uploadBioLinkIcon: (
      userId: string,
      linkId: string,
      data: UploadBioLinkIconPayload,
      params: RequestParams = {},
    ) =>
      this.request<UploadBioLinkIconData, UploadBioLinkIconError>({
        path: `/bo/user/${userId}/bio-links/${linkId}/icon`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags users, UserController
     * @name EditUserSettings
     * @summary Update user settings
     * @request PATCH:/bo/users/{userId}/settings
     * @secure
     * @response `200` `EditUserSettingsData`
     * @response `400` `PatchUsersUserIdSettings400ErrorResponse`
     * @response `403` `PatchUsersUserIdSettings403ErrorResponse`
     * @response `404` `PatchUsersUserIdSettings404ErrorResponse`
     */
    editUserSettings: (userId: string, data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<EditUserSettingsData, EditUserSettingsError>({
        path: `/bo/users/${userId}/settings`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users, UserController
     * @name GetUsers
     * @summary Get all users
     * @request GET:/bo/users
     * @secure
     * @response `200` `GetUsersResult`
     * @response `400` `GetUsers400ErrorResponse`
     * @response `403` `GetUsers403ErrorResponse`
     */
    getUsers: (query: GetUsersParams2, params: RequestParams = {}) =>
      this.request<GetUsersResult, GetUsersFail>({
        path: `/bo/users`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags users, UserController
     * @name OverrideMaxGamesLimit
     * @summary Override the max games limit for a given user
     * @request PATCH:/bo/users/override-max-games-limit
     * @secure
     * @response `200` `OverrideMaxGamesLimitData`
     * @response `400` `PatchUsersOverrideMaxGamesLimit400ErrorResponse`
     * @response `403` `PatchUsersOverrideMaxGamesLimit403ErrorResponse`
     * @response `404` `PatchUsersOverrideMaxGamesLimit404ErrorResponse`
     */
    overrideMaxGamesLimit: (query: OverrideMaxGamesLimitParams, params: RequestParams = {}) =>
      this.request<OverrideMaxGamesLimitData, OverrideMaxGamesLimitError>({
        path: `/bo/users/override-max-games-limit`,
        method: 'PATCH',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  waitlists = {
    /**
     * No description
     *
     * @tags waitlists, WaitListsController
     * @name GetStatistics
     * @summary Get wait list statistics
     * @request GET:/bo/waitlists/{topic}
     * @secure
     * @response `200` `GetStatisticsData`
     * @response `403` `GetWaitlistsTopic403ErrorResponse`
     * @response `404` `GetWaitlistsTopic404ErrorResponse`
     */
    getStatistics: (topic: string, params: RequestParams = {}) =>
      this.request<GetStatisticsData, GetStatisticsError>({
        path: `/bo/waitlists/${topic}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags waitlists, WaitListsController
     * @name GetTopics
     * @summary Get wait list topics
     * @request GET:/bo/waitlists/topics
     * @secure
     * @response `200` `GetTopicsResult`
     * @response `403` `GetWaitlistsTopics403ErrorResponse`
     */
    getTopics: (params: RequestParams = {}) =>
      this.request<GetTopicsResult, GetTopicsFail>({
        path: `/bo/waitlists/topics`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  }
  wrappers = {
    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name AbortDeployment
     * @summary Abort an active wrapper deployment
     * @request POST:/bo/wrappers/{wrapperId}/deployments/{deploymentId}/abort
     * @secure
     * @response `200` `AbortDeploymentData`
     * @response `400` `PostWrappersWrapperIdDeploymentsDeploymentIdAbort400ErrorResponse`
     * @response `403` `PostWrappersWrapperIdDeploymentsDeploymentIdAbort403ErrorResponse`
     * @response `404` `PostWrappersWrapperIdDeploymentsDeploymentIdAbort404ErrorResponse`
     */
    abortDeployment: (wrapperId: string, deploymentId: string, params: RequestParams = {}) =>
      this.request<AbortDeploymentData, AbortDeploymentError>({
        path: `/bo/wrappers/${wrapperId}/deployments/${deploymentId}/abort`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name ApplyDefaultStorageTagsToModWrappers
     * @summary Apply default storage tags to mod wrappers
     * @request POST:/bo/wrappers/apply-default-storage-tags-to-mod-wrappers
     * @secure
     * @response `200` `ApplyDefaultStorageTagsToModWrappersData`
     * @response `400` `PostWrappersApplyDefaultStorageTagsToModWrappers400ErrorResponse`
     * @response `403` `PostWrappersApplyDefaultStorageTagsToModWrappers403ErrorResponse`
     */
    applyDefaultStorageTagsToModWrappers: (
      query: ApplyDefaultStorageTagsToModWrappersParams,
      params: RequestParams = {},
    ) =>
      this.request<
        ApplyDefaultStorageTagsToModWrappersData,
        ApplyDefaultStorageTagsToModWrappersError
      >({
        path: `/bo/wrappers/apply-default-storage-tags-to-mod-wrappers`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name Create
     * @summary Create new wrapper
     * @request POST:/bo/wrappers
     * @secure
     * @response `200` `CreateOutput`
     * @response `400` `PostWrappers400ErrorResponse`
     * @response `403` `PostWrappers403ErrorResponse`
     */
    create: (data: CreateWrapperRequest, params: RequestParams = {}) =>
      this.request<CreateOutput, CreateFails>({
        path: `/bo/wrappers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name CreateDeployment
     * @summary Initiate a deployment of a default wrapper
     * @request POST:/bo/wrappers/{wrapperId}/deployments
     * @secure
     * @response `200` `CreateDeploymentData`
     * @response `400` `PostWrappersWrapperIdDeployments400ErrorResponse`
     * @response `403` `PostWrappersWrapperIdDeployments403ErrorResponse`
     * @response `404` `PostWrappersWrapperIdDeployments404ErrorResponse`
     */
    createDeployment: (wrapperId: string, params: RequestParams = {}) =>
      this.request<CreateDeploymentData, CreateDeploymentError>({
        path: `/bo/wrappers/${wrapperId}/deployments`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name Delete
     * @summary Delete wrapper
     * @request DELETE:/bo/wrappers/{wrapperId}
     * @secure
     * @response `200` `DeleteResult`
     * @response `400` `DeleteWrappersWrapperId400ErrorResponse`
     * @response `403` `DeleteWrappersWrapperId403ErrorResponse`
     * @response `404` `DeleteWrappersWrapperId404ErrorResponse`
     */
    delete: (wrapperId: string, params: RequestParams = {}) =>
      this.request<DeleteResult, DeleteFail>({
        path: `/bo/wrappers/${wrapperId}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name Edit
     * @summary Edit wrapper
     * @request PATCH:/bo/wrappers/{wrapperId}
     * @secure
     * @response `200` `EditResult`
     * @response `400` `PatchWrappersWrapperId400ErrorResponse`
     * @response `403` `PatchWrappersWrapperId403ErrorResponse`
     * @response `404` `PatchWrappersWrapperId404ErrorResponse`
     */
    edit: (wrapperId: string, data: EditWrapperRequest, params: RequestParams = {}) =>
      this.request<EditResult, EditFail>({
        path: `/bo/wrappers/${wrapperId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name GetDeploymentDetails
     * @summary Get wrapper deployment details
     * @request GET:/bo/wrappers/{wrapperId}/deployments/{deploymentId}
     * @secure
     * @response `200` `GetDeploymentDetailsData`
     * @response `403` `GetWrappersWrapperIdDeploymentsDeploymentId403ErrorResponse`
     * @response `404` `GetWrappersWrapperIdDeploymentsDeploymentId404ErrorResponse`
     */
    getDeploymentDetails: (wrapperId: string, deploymentId: string, params: RequestParams = {}) =>
      this.request<GetDeploymentDetailsData, GetDeploymentDetailsError>({
        path: `/bo/wrappers/${wrapperId}/deployments/${deploymentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name List
     * @summary List wrappers
     * @request GET:/bo/wrappers
     * @secure
     * @response `200` `ListResult1`
     * @response `400` `GetWrappers400ErrorResponse`
     * @response `403` `GetWrappers403ErrorResponse`
     */
    list: (query: ListParams7, params: RequestParams = {}) =>
      this.request<ListResult1, ListHttpError>({
        path: `/bo/wrappers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name ListDeployments
     * @summary List deployments of a wrapper, ordered from most recently initiated to least recently initiated
     * @request GET:/bo/wrappers/{wrapperId}/deployments
     * @secure
     * @response `200` `ListDeploymentsData`
     * @response `400` `GetWrappersWrapperIdDeployments400ErrorResponse`
     * @response `403` `GetWrappersWrapperIdDeployments403ErrorResponse`
     * @response `404` `GetWrappersWrapperIdDeployments404ErrorResponse`
     */
    listDeployments: ({ wrapperId, ...query }: ListDeploymentsParams, params: RequestParams = {}) =>
      this.request<ListDeploymentsData, ListDeploymentsError>({
        path: `/bo/wrappers/${wrapperId}/deployments`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name ListProcessedReleases
     * @summary List releases processed by deployment
     * @request GET:/bo/wrappers/{wrapperId}/deployments/{deploymentId}/releases
     * @secure
     * @response `200` `ListProcessedReleasesData`
     * @response `400` `GetWrappersWrapperIdDeploymentsDeploymentIdReleases400ErrorResponse`
     * @response `403` `GetWrappersWrapperIdDeploymentsDeploymentIdReleases403ErrorResponse`
     * @response `404` `GetWrappersWrapperIdDeploymentsDeploymentIdReleases404ErrorResponse`
     */
    listProcessedReleases: (
      { wrapperId, deploymentId, ...query }: ListProcessedReleasesParams,
      params: RequestParams = {},
    ) =>
      this.request<ListProcessedReleasesData, ListProcessedReleasesError>({
        path: `/bo/wrappers/${wrapperId}/deployments/${deploymentId}/releases`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name ReprocessModWrappers
     * @summary Schedule reprocessing of mod wrappers
     * @request POST:/bo/wrappers/reprocess-mod-wrappers
     * @secure
     * @response `200` `ReprocessModWrappersData`
     * @response `403` `PostWrappersReprocessModWrappers403ErrorResponse`
     */
    reprocessModWrappers: (query: ReprocessModWrappersParams, params: RequestParams = {}) =>
      this.request<ReprocessModWrappersData, ReprocessModWrappersError>({
        path: `/bo/wrappers/reprocess-mod-wrappers`,
        method: 'POST',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperDeploymentsController
     * @name ResumeDeployment
     * @summary Resume an active wrapper deployment
     * @request POST:/bo/wrappers/{wrapperId}/deployments/{deploymentId}/resume
     * @secure
     * @response `200` `ResumeDeploymentData`
     * @response `400` `PostWrappersWrapperIdDeploymentsDeploymentIdResume400ErrorResponse`
     * @response `403` `PostWrappersWrapperIdDeploymentsDeploymentIdResume403ErrorResponse`
     * @response `404` `PostWrappersWrapperIdDeploymentsDeploymentIdResume404ErrorResponse`
     */
    resumeDeployment: (wrapperId: string, deploymentId: string, params: RequestParams = {}) =>
      this.request<ResumeDeploymentData, ResumeDeploymentError>({
        path: `/bo/wrappers/${wrapperId}/deployments/${deploymentId}/resume`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags wrappers, WrapperController
     * @name Upload
     * @summary Upload new wrapper
     * @request PUT:/bo/wrappers/{wrapperId}/upload
     * @secure
     * @response `200` `UploadOutput`
     * @response `400` `PutWrappersWrapperIdUpload400ErrorResponse`
     * @response `403` `PutWrappersWrapperIdUpload403ErrorResponse`
     * @response `404` `PutWrappersWrapperIdUpload404ErrorResponse`
     * @response `500` `PutWrappersWrapperIdUpload500ErrorResponse`
     */
    upload: (wrapperId: string, data: UploadInput, params: RequestParams = {}) =>
      this.request<UploadOutput, UploadFails>({
        path: `/bo/wrappers/${wrapperId}/upload`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
}
